.facts{
    margin-bottom: 30px !important;
    @mixin two-col-facts{
        .fact{
            &-title{
                letter-spacing:1.5px;
            }

            &-description{
                margin-top:10px;
                font-size:12px;
                letter-spacing:1.1px;
            }
        }
    }

    @mixin one-col-facts{
        flex-direction:column;

        .fact{
            flex:1 1 auto;
            overflow:hidden;
            margin:5px 0;

            &-title{
                letter-spacing:1.5px;
            }

            &-description{
                margin-top:10px;
                font-size:12px;
                letter-spacing:1.1px;
            }
        }
    }

    &.one-col{
        @include one-col-facts();
    }

    &.two-col{
        @include two-col-facts();

        @include breakpoint(tablet,max){
            @include one-col-facts();
        }
    }

    &.four-col{
        @include breakpoint(desktop,max){
            @include two-col-facts();
        }

        @include breakpoint(tablet,max){
            @include one-col-facts();
        }
    }

    display:flex;
    @include gotham-book();
    color:$wim-green;
    text-align:center;

    .fact{
        flex:1 1 0;
        margin:0 7px;

        &-title{
            font-size:30px;
            letter-spacing:2px;
            line-height:1em;
            text-transform:uppercase;
        }

        &-description{
            font-size:16px;
            letter-spacing:1.5px;
            line-height:1.5em;
            margin-top:15px;
        }
    }
}