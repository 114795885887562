/* dimensions */
$small-si-width: 320px;
// $small-si-width: 90%;
$small_si_height: 225px;

$large-si-width: 728px;
$large-si-height: 420px;

/* this needs to included first */
@mixin stage-common {
	.clearfix:before,
	.clearfix:after,
	.container:before,
	.container:after,
	.container-fluid:before,
	.container-fluid:after,
	.si-wrapper .row:before,
	.si-wrapper .row:after {
	  	content: " ";
	  	display: table;
	}

	.clearfix:after,
	.container:after,
	.container-fluid:after,
	.si-wrapper .row:after {
	  	clear: both;
    }
    
    .stage-item-container {
		margin-top: 10px;
		display: flex;
    	justify-content: center;
        .slick-dots {
			bottom: -15px;
		}
		.slick-dots li {
			width: auto;
			height: auto;
			margin: 0;
		}
    	/* slick slider overrides */
		.slick-dots li button {
			font-size: 0;
			line-height: 0;
			width: 0;
    		height: 0;
			background-color: transparent;
			padding: 3.5px !important;
			margin: 0 5px;
			opacity: 1;
			border: 1px solid $white;
			box-shadow: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			background-color: transparent;
			border: 1px solid $white;
			border-radius: 50%;
			cursor: pointer;
			display: inline-block;
			outline: none;

			&:before {
				content: '';
			}
		}
		.slick-dots li.slick-active button {
			background-color: $white;

			&:before {
				color: transparent;
				opacity: 1;
			}
		}
	}
	.si-wrapper {
		padding: 0px 15px 25px;
		margin: 0 auto;
	  	text-align: center;
	  	clear: both;
	  	color: $stage-item-color;
	  	@include si-text-font;

	  	small, sub, sup { font-size: .33em; position: relative; right: 1px; top: 6px;}
		sub             { vertical-align: sub }
		sup             { vertical-align: text-top; }

		.si-inner-wrapper {
			.chart-value {
				letter-spacing: 0em;
				position: relative;
			}
			.ct-fill-donut-label {
				height: 100%;
				left: 0px !important;
				right: 0px !important;
			}
			// .ct-chart {
				.team1 {
					.ct-chart-donut .ct-series-a .ct-slice-donut {
						stroke: $team1_color; 
						stroke-width: 5px!important;
					}
					.ct-chart-donut .ct-fill-donut .ct-slice-donut {
				        stroke: $donut-fill-color;
				        opacity: 1;
				        stroke-width: 7px!important;
				    } 						
				}
				.team2 {
					.ct-chart-donut .ct-series-a .ct-slice-donut {
						stroke: $team2_color;
						stroke-width: 5px!important; 
					}
					.ct-chart-donut .ct-fill-donut .ct-slice-donut {
				        stroke: $donut-fill-color;
				        opacity: 1;
				        stroke-width: 7px!important;
				    } 		
				}
				.ct-chart-donut .ct-series-b .ct-slice-donut {
					stroke: $white; 
					stroke-width: 7px!important;						
				}				
			// }
		}

		svg:not(:root) {overflow: visible !important;}
		svg { width: 100%; }

		.si-table {
			display: table;
			table-layout: fixed;
			vertical-align: middle;
		}
		.si-row {
			display: table-row;
		}
		.si-cell {
			display: table-cell;
		}
		.si-caption {
			display: table-caption;
		}
		.si-thead {
			display: table-header-group;
		}
	}
	.stage-error {
		width: 100%;
		height: 100%;
		color: $white;
		font-size: 1.2em;
		font-weight: bold;
		text-align: center;
		text-transform: uppercase;
		margin-top: 25%;
	}
}

/* serve placement outer wrapper */
.si-outer-wrapper  {
  &.serve-placement {
    .subheading-title {
      width: 100%;
      @include gotham(medium);
      font-size: px2rem(12);
      line-height: 18px;
      text-align: center;
      padding: 10px 0;
    }
  }
}

@mixin single-donut-small {
	.chart-container {
		@include si-donut-font;
		position: relative;

		.ct-chart {
			position: absolute!important;
			top: 0px;
			left: 0px;
			right: 0px;
			width: 100%;
			
			.ct-fill-donut-label { top: 10px !important; }
			.chart-value {
				position: absolute;
				left: 4px;
				bottom: 40px;
				width: 100%;
				font-size: 42px;
			}
			.serve-info {
				position: absolute;
				left: 0px;
				bottom: 0px;
				width: 100%;
				font-size: 14px;
				text-transform: none;
				@include si-title-font;

				.set-info {
					&::before{
						content: "- ";
						position: relative;
						left: 0px;
						color: $white;
					}
				}
			}
		}
	}
}

@mixin single-donut-large {
	.chart-container {
        height: 100%;
        
		.ct-chart {
			width: 100%;
            height: 100%;
            
			.ct-fill-donut-label {
				top: -140px !important;
			}
			.chart-value {
				position: absolute;
				width: 100%;
				left: 6px;
				bottom: -10px;
				font-size: 65px;
			}
			.serve-info {
				top: 310px;
				font-size: 16px;					    	
			}
		}
	}
}

@mixin double-donut-small {
	.chart-container {
		position: relative;
		height: 160px;
		
        .chart-content {
            width: 50%;
            height: 100%;

            &.team1 { 
                float: left; 
            }
            &.team2 { 
                float: right; 
            }
        }
        .ct-chart {
			width: 100%;
            height: 100%;
			bottom: 0px;
			@include si-title-font;

            .ct-chart-donut {
				position: absolute;
				left: 0px;
				right: 0px;
				bottom: 55px;
				margin: 0px auto;
            }
            .serve-info {
				position: absolute;
				width: 100%;
				left: 0;
				right: 0px;
				text-align: center;
				bottom: 20px;
				font-size: 12px;

				.serves {
					font-size: 14px;
				}					
				.player, .court {
					font-size: 14px;
				}			
			}
			.chart-value {
				position: absolute;
				width: 100%;
				left: 4px;
				bottom: 80px;
				font-size: 32px;						
				
				&.winning {
					bottom: 85px;
					font-size: 36px;
					left: 4px;
				}									
            }							
			&.doubles {
				.chart-value {								
					&.winning {
					}									
				}
			}							
        }		
	}
}

@mixin double-donut-large {
	.chart-container {
		position: absolute;
		left: 0px;
		top: 45px;
		width: 100%;
		height: 170px;
		@include si-title-font;
								
		.ct-chart {
			width: 50%;

			.ct-chart-donut {
				bottom: 0px;
			}
			.serve-info {
				position: absolute;
				left: 0px;
				bottom: -30px;
				width: 100%;
				font-size: 12px;
			}
			.chart-value {
				position: absolute;
				left: 6px;
				bottom: 25px;
				width: 100%;
				font-size: 40px;
				
				&.winning {
					bottom: 35px;
					font-size: 50px;
					left: 8px;
				}									
			}								
			&.doubles {
				.chart-value {
					&.winning {}									
				}
			}							
		}
	}	
}

@mixin double-number-small {
	.total-diagonal-wrapper {
		float: left;
		width: 50%;
		margin-top: 20px;
		@include si-title-font;

		.player-info-wrapper {
			position: relative;
		}
		.count-wrapper {
			width: 110px;
			height: 90px;
			font-size: 54px;
			background: rgba(255,255,255,1);
			border: 1px solid $md-gray;
			line-height: 90px;
			position: relative;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
		.total-diagonal {
			display: none;
		}
		&.team1 {
			.count-wrapper {
				// right: 20px;
				
				&:before {
					width: 10px;
					height: 90px;
					content: "";
					position: absolute;
					left: -10px;
					top: -1px;
					border: 1px solid $md-gray;
					background: $team1_color_rgb;
				}
			}
		}
		&.team2 {
			.count-wrapper {
				// left: 20px;
				
				&:before {
					width: 10px;
					height: 90px;
					content: "";
					position: absolute;
					left: -10px;
					top: -1px;
					border: 1px solid $md-gray;
					background: $team2_color_rgb;
				}								
			}
		}
		.player {
			padding-bottom: 10px;
			font-size: 14px;
		}
		.jsx-parser { display: inline; }						
	}
}

/* at each breakpoint add the appropriate sized stage item */

/* xsmall for handling no margin on small phones */
@mixin stageitem-xsmall {
	.si-wrapper {
		width: $small-si-width;
		height: $small-si-height;
		// margin: 0 0 10px -10px;
		position: relative;
		overflow: hidden;
		padding: 0;

		.heading-wrapper {  
			.heading {
        display: flex;     
        flex-direction: column;     
				width: 100%;
				padding: 8px 5px;
        text-transform: uppercase;	
        background-color: rgba(0, 0, 0, 0.4);
      }
      .heading-title {
        font-size: 14px;
				@include gotham(medium);
        line-height: 17px;
      }
			.subheading-title {
				font-size: 10px;
				@include gotham(light);
				// padding: 0px 8px;
        text-transform: uppercase;
        line-height: 12px;
        color:rgba(255, 255, 255, 1);
			}
		}
		.stage-container {
			position: relative;
      height: 100%;
      width: 100%;
		}
		.si-inner-wrapper {
			width: 100%;
			height:100%;
			margin-left: auto;
			margin-right: auto;
			position: relative;
			top: 0px;
			bottom: 0px;

			.item {
				width: 100%;
				// padding: 0px 15px;

				.heading {
					font-size: 16px;
					@include si-heading-font;
				}
				.subheading {
					font-size: 11px;
					margin-bottom: 10px;
					@include si-subheading-font;
				}
			}
        }
        
		/* stage layouts */
    &.depth-of-return {
        .heading-wrapper {
          position: absolute;
          top: 0px;
          width: 100%;
        }
      .si-wrapper-inner {
        height: 100%;
        margin: 0px 40px;
        border-left: 1px solid rgba(255, 255, 255, 0.4);
        border-right: 1px solid rgba(255, 255, 255, 0.4);

        .court-wrapper{
        .court-position {
          .depth-wrapper {
            .depth-items { 
              display: flex;
              &.shallow {
                height: 146.25px; 
                position: relative;
                .pct-returns { 
                  border-right: 0.5px solid rgba(255, 255, 255, 0.4); 
                }
                .pct-returns-container{
                  width: 50%;
                }
                .pct-returns, .pct_winning {
                  height: 100%;
                  width: 100%;

                  &.inner {
                    padding-top: 44px;
                  }
                  .label-returns, .label-winning {
                    @include gotham(medium);
                    background-color:rgba(255, 255, 255, 0.2);
                    font-size: px2rem(12);
                    padding: 6px;
                  }
                  .perc-container {
                    @include gotham(medium);
                    line-height: 28.8px;
                    font-size: 24px;
                    height: 85%;
                    align-items: center;
                    display: inline-flex
                  }
                }
              }

              &.deep {
                .returns {
                  display: flex;
                  align-items: center;
                  width: 100%;
                  height: 67.5px;

                  .returns-perc, .winning-perc {
                    width: 50%;
                    @include gotham(medium);
                    line-height: 28.8px;
                    font-size: 24px;
                  }
                }
              }
            }
            &.depth-wrapper {
              border-top: 1px solid rgba(255, 255, 255, 0.4); 
            } 
           }
        }
        }
      }
    }
		&.donut-number {
			.si-inner-wrapper {	
				.item {
					height: 250px;
					padding: 0px 5px;

					&.stage1 {
                        .heading {
							display: none;
						}
						/* include double donut layout */
						@include double-donut-small;
					}
					&.stage2 {
                        /* include double number layout */
						@include double-number-small;
					}
				}
			}	
		}

		&.kick-height {
			.heading-wrapper {
				.subheading {
					text-transform: capitalize;
				}
			}
			.si-inner-wrapper {	
				.item {
					height: 250px;

					&.stage1 {
						width: 100%;
						white-space: normal;
						font-size: 14px;

						.kh-description {
							float: left;
							text-align: left;
							width: 100%;
							margin-top: 30px;

							.metric, .imperial {
								font-weight: bold;
							}
							.jsx-parser { display: inline; }
						}
						.kh-progress {
							float: left;
							text-align: left;
							width: 100%;
							margin-top: 20px;

							.metric, .imperial {
								font-weight: bold;
							}
						}
					}
					&.stage2 {
						width: 100%;
						padding: 0px 5px;

						.labels {
							float: left;
							width: calc(100% - 232px);
							margin-top: 30px;
							position: relative;
							@include gotham(medium);

							.kh-current {
								position: absolute;

								.number { 
									font-size: 34px; 
									line-height: 33px;
								}
							}
							.kh-target {
								position: absolute;

								.number { 
									font-size: 26px; 
									line-height: 26px;
								}
							}
							.kh-current, .kh-target {
								.number {
									.imperial {
										&:after {
											content: 'ft';
											font-size: 12px;
											position: relative;
											bottom: 0px;
											right: -2px;
										}
									}
									.metric {
										&:after {
											content: 'm';
											font-size: 12px;
											position: relative;
											bottom: 0px;
											right: -2px;
										}
									}
								}
							}
							.label {
								text-transform: uppercase;
								font-size: 11px
							}
							&.team1 {
								.kh-current { 
									color: $team1_color; 
								}
							}
							&.team2 {
								.kh-current { 
									color: $team2_color; 
								}
							}
							&.above {
								.kh-current { top: -5px; }
								.kh-target { top: 56px; }
							}
							&.below {
								.kh-current { top: 65px; }
								.kh-target { top: 5px; }
							}
							&.target {
								.kh-current { top: 8px; }
								.kh-target { top: 69px; }
							}
						}
						.graphic {
							float: left;
							width: 222px;
							margin-top: 30px;

							img {
								width: 222px;
								height: 170px;
							}
						}
					}
				}
			}	
		}

		&.number-donut {
			.si-inner-wrapper {
				.item {
					height: 250px;
					padding: 0px 5px;

					&.stage1 {
						.heading {
							display:none;
						}
						/* include double number layout */
						@include double-number-small;
					}
					&.stage2 {
						.heading {
							display:none;
						}
						/* include double donut layout */
						@include double-donut-small;
					}
				}
			}			
		}	

		&.player-movement {
			.heading-wrapper {
				.heading {
					height: 40px;
					padding-top: 15px;
					line-height: 25px;
					text-transform: none;
					@include si-heading-font-bold;
				}
				.subheading.metric, .subheading.imperial {
					visibility: hidden;
				}
			}
			.si-inner-wrapper {		
				.subheading {
					height: 22px;
					line-height: 22px;
					
					&.totals {
						text-transform: none;
					}
				}
				&.totals {
					margin-top: 12px;
					text-transform: lowercase;
				}
				.item {
					// padding: 0px 5px;

					&.stage1 {
						height: 230px;

						.team1, .team2 {
							float: left;
							width: 100%;
						}
						.team1 {
							.player-image {
								border: 6px solid $team1_color_rgb;
								background-color: $team1_color_rgb;
							}
						}
						.team2 {
							.player-image {
								border: 6px solid $team2_color_rgb;
								background-color: $team2_color_rgb;
							}
						}
						.player-data {
							margin-left: 0px;
							margin-bottom: 10px;
							position: relative;
							float: left;
							padding-right: 37px;
							
							.name {
								font-size: 14px;
								text-transform: none;
								@include si-title-font;
							}
							.serve-speed-wrapper {
								font-size: 20px;
								text-transform: uppercase;
								display: inline-block;
								vertical-align: top;
								height: 42px;
								background: rgba(255,255,255,1);
								margin-top: 6px;
								margin-left: -20px;
								border-top: 1px solid $md-gray2;
								border-bottom: 1px solid $md-gray2;
								line-height: 42px;
								width: 119px;
								z-index: 1;
								position: relative;
								@include si-text-font;

								.serve-speed-value {
									position: absolute;
									right: 0px;
								}
								&::after {		
									content: '';
									height: 42px;
									width: 24px;
									background: url("/assets/images/scores/slamtracker/backgrounds/arrow_chevron.png");
									position: absolute;
									right: -24px;
									background-size: 24px 42px;
									margin-top: -1px;
								}
							}
							.player-image {
								width: 50px;
								height: 50px;
								border-radius: 40px;
								display: inline-block;
								position: relative;
								box-shadow: 0 0 0 2px $white;	
								z-index: 2;		
								left: 2px;
								top: 2px; 
								background-size: contain; 
								background-position: center;
								background-repeat: no-repeat;											
							}
						}
						.distance-per-point {
							float: left;
							text-align: left;
							
							.value {
								font-size: 22px;
								margin-top: 30px;
								text-align: center;
							}
							.unit {
								font-size: 9px;
								text-transform: uppercase;
								text-align: center;
							}						
						}
						.winning {
							.player-data {
								.serve-speed-wrapper {
									font-size: 22px;
									height: 55px;
									margin-left: -20px;
									line-height: 55px;
									width: 130px;
									@include si-winning-font;

									.serve-speed-value {
										position: absolute;
										right: 0px;
									}
									&::after {		
										content: '';
										height: 56px;
										width: 30px;
										background: url("/assets/images/scores/slamtracker/backgrounds/arrow_chevron.png");
										position: absolute;
										right: -30px;
										background-size: 30px 56px;
										margin-top: -1px;
									}							
								}
								.player-image {
									width: 63px;
									height: 63px;
								}	
                            }
                            .distance-per-point {
                                .value {
                                    @include si-winning-font;
                                }				
                            }
                        }
                        .totals {
                            clear: left;
                        }
					}
					&.stage2 {
                        height: 230px;

						.si-table {
							margin-top: 20px;
							width: 100%;
						}
						.si-thead {
							display: table-header-group;
							text-transform: uppercase;
                            @include si-title-font;

						 	.si-cell {
							    &.field {
									font-size: 11px;
								}
								&.distance {
									font-size: 11px;
								}
								&.average {
									font-size: 11px;
								}
							}
						}
						.si-row {
							margin-bottom: 5px;
						}
						.si-cell {
							&.field {
								width: 25%;
								padding-bottom: 15px;
							}
							&.distance {
								width: 48%;
								font-size: 20px;
								padding-bottom: 15px;
								
							}
							&.average {
								width: 27%;
								font-size: 20px;
								padding-bottom: 15px;
							}
							.total-distance, .distance-point {
								@include si-playermovement-font;
							}
						}
						.circle {
							display: table-cell;
							width: 63px;
							height: 63px;
							border-radius: 40px;
							color: $wim-black;
							background: white;
							text-align: center;
							line-height: 63px;
							margin-left: auto;
							margin-right: auto;
							font-size: 12px;
							text-transform: uppercase;
                            display: table;
                            white-space: normal;
							
							.circle-content {
								line-height: 16px;
								display: inline-block;
								position: relative;
								vertical-align: middle;
								display: table-cell;
								@include si-title-font;
							}
						}
					}
				}
			}
		}

		&.progress-keys {
			/* stage container helps vertically postion the stage item */
			.heading {
				min-height: 31px;
				padding: 20px 5px 8px;
				height: 60px;
				text-transform: none;
			}
			.subheading {
				min-height: 25px;
				font-size: 15px;
				line-height: 18px;
				padding: 8px;
				text-transform: none;

				.jsx-parser {
					display: inline;
					text-transform: lowercase;
				}
			}
			.stage-container {
				top: 114px;
			}
			.si-inner-wrapper {
				.item {
					float: left;
					position: relative;
					width: 100%;
					padding-left: 15px;
					padding-right: 15px;

					&.stage1 {
						height: 190px;
						padding-top: 3px;
						@include single-donut-small;
					}
					&.stage2 {
						height: 190px;
                        margin-top: 0px;
                        
						.key-description {
							font-size: 12px;
							width: 100%;
							margin-left: auto;
							margin-right: auto;
							border-top: 1px solid $white;
							padding-top: 10px;		
							padding-bottom: 10px;
							text-align: left;
							text-transform: none;
							@include si-title-font;	
							white-space: normal;		
						}
						.winning-chance {
							float: left;
							margin-left: auto;
							margin-right: auto;
							text-transform: none;
							@include si-title-font;
							
							.circle {
								width: 40px;
								height: 40px;
								border-radius: 40px;
								color: $md-gray;
								background: white;
								border: 1px solid $md-gray2;
								text-align: center;
								margin-left: auto;
								margin-right: auto;
								font-size: 14px;
								text-transform: uppercase;
								float: left;
								margin-right: 8px;
								display:table;

								.circle-content {
									font-size: 20px;
									display: inline-block;
									position: relative;
									vertical-align: middle;
									display: table-cell;
									
									sup{
										//font-size: 7px;
									}
									&::after {
										content: "%";
										font-size: .33em; 
										position:relative;
										vertical-align: super;
									}
								}
							}
							.winning-description {
								text-align: left;
								@include si-title-font;
								font-size: 14px;
								white-space: normal;
							}						
						}
					}
				}
			}
		}
		&.serve-placement {
      .si-wrapper-inner {
        height: 100%;
        margin: 0px 20px;
        border-left: 1px solid rgba(255, 255, 255, 1);
        border-right: 1px solid rgba(255, 255, 255, 1);

      }
			.heading-wrapper {
        position: absolute;
        top: 0px;
        width: 100%;
				.heading {
					min-height: 34px;
					@include gotham(medium);
          font-size: px2rem(12);
          text-align: center;
          line-height: 11.5px;
          color: rgba(255, 255, 255, 1);
				}
			}
      .court-position-empty {
        position: absolute;
        top: 34px;
        width: 100%;
        display: flex;
        background-color: rgba(0, 0, 0, 0.2);
        height: 22px;
      }

			.subheading {
				width: 100%;
				height: 25px;
				line-height: 22px;
			}
			.si-inner-wrapper {
				.item {
					position: relative;
          top: 34px;
          height: 100%;
					&.stage1 {
						.court-flat-wrapper {
              height: 100%;
							.results {
                display: flex;
								height: calc(100% - 34px);
                .results-inner {
                  // width: 50%;
                  // margin: 0 auto;
                  &.left{
                    border-right: 1px solid rgba(255, 255, 255, 1);
                  }
                  .results-wrapper, .position-label {
                    display: flex;
                  }
                  &.left, &.right {
                    width: 100%;
                  }

                  .court-deuce, .court-advantage { 
                    @include gotham(medium);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 22px;
                    font-size: 8px;
                    text-transform: uppercase;
                  }

                  .results-wrapper {
										&.advantage, &.deuce {
                      color: rgba(255, 245, 0, 1);
                      font-size: px2rem(12px);
                      font-weight: 700;
                      @include gotham();
                      align-items: center;
                    }
									}
                }
								
								.set-result {
                  width: 27.4px;
                  padding-left: 3px;
								}							
								.team1, .team2 {
                  display: flex;
                  height: 30px;
								}

								.position-label {
									position: relative;
									font-size: 10px;
									text-transform: capitalize;
									margin-top: 2px;
                  border-bottom: 1px solid rgba(255, 255, 255, 1);
                  // height: 136px;
                  text-wrap: nowrap;

                  .position-label-items {
                    display: flex;
                    transform: rotate(-90deg);
                    flex-direction: column;
                    width: 100%;
                    // position: absolute;
                    // bottom: 10px;
                  }

									.label {
                    display: flex;
                    padding-left: 10px;
										&.left {
                      height: 27.4px;
                      border-top: 2px solid rgba(255, 255, 255, 0.5);
                      border-top-style: dotted;
                      div {
                        align-self: center;
                      }
										}

                    &.left:first-child {
                      border-top: none;
                    }
                    &.left:last-child {
                      border-bottom: none;
                    }

										&.right {
                      height: 27.4px;
                      border-top: 2px solid rgba(255, 255, 255, 0.5);
                      border-top-style: dotted;
                      div {
                        align-self: center;
                      }
										}	
                    
                    &.right:first-child {
                      border-top: none;
                    }
                    &.right:last-child {
                      border-bottom: none;
                    }
									}								
								}							
							}						
						}
					}
				}
			}
		}

		&.serve-speed {		
			.stage-container {
				top: 80px;
			}
			.si-inner-wrapper {
        border: 1px solid #828282;
        .stageItemSlide{
          .slick-dots{
            bottom: 12px !important; 
            li button:before {
              content: "";
              width: 10px;
              height: 10px;
              border-radius: 10px;
              background: #828282;
            }
          }
        }
        .team-container {
          color: #000;
          width: 224px;
          margin: 0 auto;
        }	
        .heading-wrapper{
          padding-bottom: 20px;
        }
        .heading{
          @include gotham(medium);
          font-size: px2rem(14);
          background-color: white;
          margin: 0 auto;
        }
				.item {
					width: 100%;
					height: 225px;
					padding: 0px 5px;

					&.stage1 {
						.team1 {
							.player-image {
								border: 2px solid $team1_color_rgb;
								background-color: $team1_color_rgb;
                float: inline-start;
							}
              .name {
                border-bottom: 2px solid $team1_color_rgb; 
              }
              .serve-speed-wrapper {
                background-color: $team1_color_rgb;
              }
              .serve-speed-wrapper:after {
                color: $team1_color_rgb;
              }
						}
						.team2 {
							.player-image {
								border: 2px solid $team2_color_rgb;
								background-color: $team2_color_rgb;
                float: inline-start;
							}
              .name {
                border-bottom: 2px solid $team2_color_rgb; 
              }
              .serve-speed-wrapper {
                background-color: $team2_color_rgb;
              }
              .serve-speed-wrapper:after {
                color: $team2_color_rgb;
              }
						}

						.player-data {
              @include gotham(medium);
              text-transform: uppercase;
							position: relative;
							.name {
								font-size: px2rem(10);
                width: 100%; 
                text-align: center; 
                line-height: 0.1em;
                margin: 10px 0 15px; 
                span{
                  background:#fff; 
                  padding:0 10px; 
                }
							}
							.serve-speed-wrapper {
								text-transform: uppercase;
								display: inline-block;
								vertical-align: top;

                font-size: px2rem(12);
									height: 16px;
									line-height: 15.48px;
									width: 190px;
									@include gotham(medium);

								margin-top: 8px;
								margin-left: -20px;
								z-index: 1;
								position: relative;
								.serve-speed-value {
									right: 0px;
                  color: #fff;
								}
								&::after {		
                  border-left: 8px solid;
                  border-top: 8px solid transparent;
                  border-bottom: 8px solid transparent;
                  display: inline-block;
                  content: '';
                  position: absolute;
                  right: -8px;
                  top: 0;
								}
							}
							.player-image {
								width: 33px;
								height: 33px;
								border-radius: 40px;
								display: inline-block;
								position: relative;
								// box-shadow: 0 0 0 2px $white;
								margin-left: 2px;	
								z-index: 2;
								background-size: contain; 
								background-position: center;
								background-repeat: no-repeat;				
							}		
              .img-serve{
                margin-bottom: 20px;
              }
              .serve-stat {
                display: flex;
                align-items: baseline;
                .si-cell-last, .si-cell-fastest{
                  .value {
                    font-size: px2rem(10);
                  }
                }
              }
               .si-row {
                width: 100%;
                .si-cell.category.last, .si-cell.category.fastest {
                  width: 172px;
                  text-align: left;
                  font-size: px2rem(8);

                }
                .last{
                  padding-bottom: 10px;
                  font-size: px2rem(10);
                }
              }				
						}
						.winning {
							.player-data {
								.serve-speed-wrapper {
									font-size: px2rem(12);
									height: 11px;
									line-height: 11.48px;
									width: 190px;
									@include gotham(medium);

									.serve-speed-value {
										position: absolute;
										right: 0px;
									}
									&::after {		
										content: '';
										height: 56px;
										width: 30px;
										background: url("/assets/images/scores/slamtracker/backgrounds/arrow_chevron.png");
										position: absolute;
										right: -30px;
										background-size: 30px 56px;
										margin-top: -1px;
									}							
								}
								.player-image {
									width: 63px;
									height: 63px;	
								}	
							}
						}
					}
					&.stage2 {
						.si-table {
							width:95%;
							margin:0px auto;
						}
						.si-cell {
							width:50%;
						}

						.player-info-wrapper {
							.player {
								font-size: 17px;
								display:table-caption;
								@include si-title-font;
							}						
							.category {
								font-size: 12px;
								text-transform: uppercase;
								text-align: left;
								@include si-text-font;
							}
							.value{
								font-size: 24px;
								text-transform: uppercase;
								text-align: right;
							}
							&.team2 {
								margin-top: 12px;
							}
						}
					}
				}
			}	 
		}

		/* redesigned */
		&.situational-analysis{
			.heading-wrapper {
				.heading {
					padding-bottom:0px;
					.sa_type {
						display:none;
					}
				}
				.subheading {
					display:block;
				}
			}
			.si-inner-wrapper {
				.item {
					.heading {
						display:none;
					}
					.subheading {
						display:none;
					}

					&.stage1 {
						.sa-description {
							font-size: 13px;
							width: 90%;
							margin:0px auto;
							text-align: left;
							padding:10px 0px;
							@include si-title-font;			
						}
						@include single-donut-small;
					}

					&.stage2 {
						.field-description {
							font-size: 13px;
							width: 90%;
							margin:0px auto;
							text-align: left;
							padding:10px 0px;
							border-bottom: 1px solid $white;
							@include si-title-font;			
						}
						.field {
							float: left;
							width: 90%;
							margin-left: 10%;
							margin-top: 10px;
							
							.circle {
								width: 40px;
								height: 40px;
								border-radius: 40px;
								color: $md-gray;
								background: white;
								border: 1px solid $md-gray2;
								text-align: center;
								margin-left: auto;
								margin-right: auto;
								font-size: 14px;
								text-transform: uppercase;
								display:table;
								float: left;
								margin-right: 8px;

								.circle-content {
									//line-height: 22px;
									font-size: 22px;
									display: inline-block;
									position: relative;
									vertical-align: middle;
									display: table-cell;
									@include si-title-font;
									
									sup{
										//font-size: 7px;
									}
									&::after {
										content: "%";
										font-size: .33em; position:relative;
										vertical-align: super;
									}
								}
							}
							.text {
								text-align: left;
								text-transform: uppercase;
								line-height: 40px;
								@include si-title-font;
								font-size: 14px;
							}						
						}
					}
				}
			}
		}	
	}
}

/* small sized stage items */
@mixin stageitem-small {
	.si-wrapper {
		width: $small-si-width;
		height: $small-si-height;
		margin: auto;
		// margin-bottom: 10px;
		position: relative;
	}
}

/* large sized stage item */
@mixin stageitem-large {
	.si-wrapper {
		width: $large-si-width;
		height: $large-si-height;
		.si-inner-wrapper {
			.item {
				.heading {
					text-transform: uppercase;
					@include si-heading-font;
					font-size: 14px;
					display: none;
				}
				.subheading {
					text-transform: uppercase;
					@include si-subheading-font;
					font-size: 14px;
					display: none; 
				}
			}
		}
		&.donut-number {
			.si-inner-wrapper {
				.item {
					height: 300px;
					float: left;
					position: relative;
					
					&.stage1 {
						width: 55%;

						&::after {
							content: "";
							border-right: 1px solid $panel-separator;
							height: 250px;
							width: 1px;
							position: absolute;
							top: 42px;
							right: 0px;
						}
						.subheading {
							display:block;
						}
						/* include double donut layout. builds from double-donut-small
					  	so make sure that is used in a smaller breakpoint */
						@include double-donut-large;
					}
					&.stage2 {
						width: 45%;
						.heading {
							display: block;
						}
						.total-diagonal-wrapper {
							margin-top: 80px;
						}	
					}	
				}
			}	
		}

		&.number-donut {
			.si-inner-wrapper{
				.item {
					height: 300px;
					float: left;
					position: relative;
					
					&.stage1 {
						width: 55%;
						padding-left: 15px !important;
						padding-right: 15px !important;
						
						&::after{
							content: "";
							border-right: 1px solid $panel-separator;
							height: 239px;
							width: 1px;
							position: absolute;
							top: 43px;
							right: 0px;
						}
						.subheading {
							display:block;
						}

						/* this is for the tennis balls on a stick */
						.total-diagonal-wrapper {
							height: 258px;
							font-size: 14px;
							margin-top: 0px;
							position: relative;

							&:after {
								content: "";
								width: 100%;
								height: 10px;
								position: absolute;
								top: 214px;
								right: 0px;
								display: block;
								background: $white;
							}
							.total-diagonal {
								position: absolute;
								display: block;
								right: 60%;
								top: 56px;

								.tennis-ball {
									background-size: contain;
									width: 50px;
									height: 50px;
									border-radius: 90%;	
									border: 8px solid $white;
									display: block;
									position: relative;
									
									&:after {
										content: "";
										background: $white;
										width: 7px;
										height: 118px;
										position: absolute;
										bottom: -118px;
										left: 13px;
									}
								}
							}
							&.winning {
								.total-diagonal {
									top: 0px;

									.tennis-ball {
										&:after {
											height: 172px;
											position: absolute;
											bottom: -172px;
										}									
									}
								}		
								.player-info-wrapper {
									top: 12px;
								}														
							}	

							&.team1 { 
								.total-diagonal {
									.tennis-ball {
										background-image: url("/assets  /images/scores/slamtracker/backgrounds/tennis_ball_stripe.png"),
											linear-gradient($team1_color_rgb, $team1_color_rgb),
											url("/assets/images/scores/slamtracker/backgrounds/tennis_ball_bkg.png");			
									}								
								}	
							}
							&.team2 {
								.total-diagonal {
									.tennis-ball {
										background-image: url("/assets/images/scores/slamtracker/backgrounds/tennis_ball_stripe.png"),
											linear-gradient($team2_color_rgb, $team2_color_rgb),
											url("/assets/images/scores/slamtracker/backgrounds/tennis_ball_bkg.png");
									}								
								}	
							}
							.player-info-wrapper {
								position: absolute;
								top: 69px;
								left: 44%;

								.count-wrapper {
									font-size: 48px;
									line-height: 48px;
									right: auto;
									left: auto;
									width: auto;
									height: auto;
									background: none;
									border: none;
                                    position: relative;
                                    
									&:before {
										display:none;
									}
								}
								.player {
									padding-bottom: 5px;
								}
							}
						}
					}
					&.stage2 {
                        width: 45%;
                        
						.heading {
							display:block;
						}
						/* include double donut layout. builds from double-donut-small
						  so make sure that is used in a smaller breakpoint */
						@include double-donut-large;
					}
				}
			}
		}

		&.kick-height {
			.si-inner-wrapper {	
				.item {
					height: 300px;
					float: left;
					position: relative;

					&.stage1 {
						width: 45%;

						.description {
							margin-top: 45px;
						}
					}
					&.stage2 {
						width: 55%;

						.labels {
							width: calc(100% - 284px);
							margin-top: 45px;
							
							.current {
								.number { 
									font-size: 48px; 
									line-height: 48px;
								}
							}
							.target {
								.number { 
									font-size: 36px; 
									line-height: 36px;
								}
							}
							.label {
								text-transform: uppercase;
								font-size: 11px
							}
							&.above {
								.current { top: -5px; }
								.target { top: 80px; }
							}
							&.below {
								.current { top: 77px; }
								.target { top: 3px; }
							}
							&.target {
								.current { top: 8px; }
								.target { top: 80px; }
							}
						}
						.graphic {
							float: left;
							width: 284px;
							margin-top: 45px;

							img {
								width: 284px;
								height: 217px;
							}
						}
					}
				}
			}	
		}

		&.player-movement {
			.si-inner-wrapper {
				.subheading {
					&.totals {
						font-size: 18px!important;
						margin-top: 0px;
						text-transform: lowercase;
					}
				}
				.item {
					float: left;
					height: 257px;
					position: relative;
					padding: 0px 15px;

					&.stage1 {
						width: 55%;

						&::after{
							content: "";
							border-right: 1px solid $panel-separator;
							height: 239px;
							width: 1px;
							position: absolute;
							top: 4px;
							right: 10px;
						}
						.subheading { 
							display: none; 

							&.totals { display: block; }
						}
						.team1{
							.player-image {
								border: 6px solid $team1_color_rgb;
								background-color: $team1_color_rgb;
							}
						}
						.team2{
							.player-image {
								border: 6px solid $team2_color_rgb;
								background-color: $team2_color_rgb;
							}
						}
						.player-data {
							.name {
								font-size: 16px;
							}
							.player-image {
								width: 55px;
								height: 55px;
								border-radius: 40px;
							}
							.serve-speed-wrapper {
								font-size: 28px;
								height: 48px;
								margin-top: 10px;
								margin-left: -40px;
								line-height: 50px;
								width: 172px;
							
								.serve-speed-value {
									font-size: 28px;
									top: -2px;
								}
								&:after {
									height: 48px;
									width: 28px;
									right: -28px;
									background-size: 28px 48px;
									margin-top: -1px;
								}
							}								
						}
						.distance-per-point {
							float: left;
							text-align: left;
							margin-top: 10px;
							@include si-text-font;

							.value{
								font-size: 28px;
								margin-top: 25px;
							}
							.unit{
								font-size: 9px;
								text-transform: uppercase;
							}						
						}
						.winning {
							.player-data {
                                margin-bottom: 15px;
                                
								.serve-speed-wrapper {
									font-size: 32px;
									height: 60px;
									margin-top: 10px;
									line-height: 60px;
									width: 175px;
									@include si-winning-font;

									&::after {		
										height: 60px;
										width: 30px;
										right: -30px;
										background-size: 30px 60px;
									}
									.serve-speed-value {
										font-size: 32px;
									}
								}
								.player-image {
									width: 70px;
									height: 70px;					
								}	
							}
							.distance-per-point {
								margin-top: 10px;
								margin-left: 0px;
								@include si-winning-font;

								.value{
									font-size: 32px;
									margin-top: 30px;
								}
								.unit{
									font-size: 9px;
								}
							}
						}
					}
					&.stage2 {
						width: 45%;

						.si-table {
							margin-top: 40px;
						}
					}
				}
			}
		}	

		/* redesigned */
		&.progress-keys {
			.stage-container {
				top:100px;
			}
			.si-inner-wrapper {
				.item {
					height: 258px;
					position: relative;

					&.stage1 {
						width: 48%;
						height: 258px;

						&:after {
							content: "";
							border-right: 1px solid #FFFFFF;
							height: 239px;
							width: 1px;
							position: absolute;
							top: 0px;
							right: 0px;
						}
						@include single-donut-large;
					}

					/* right side */
					&.stage2 {
						width: 52%;
						height: 258px;
						.key-description {
							font-size: 15px;
							padding-top: 20px;
							padding-bottom: 30px;
							border-top:none;
							border-bottom:solid 1px #FFFFFF;
						}
						.winning-chance {
							margin-left: 10%;
							margin-top: 25px;
							
							.circle {
								width: 42px;
								height: 42px;
								border-radius: 42px;
								
								.content {
									font-size: 25px;
								}
							}
							.winning-description {
								width: 300px;
								font-size: 17px;
								@include si-title-font;
								text-align: left;
							}											
						}
					}
				}
			}
		}
		&.situational-analysis {
			.heading-wrapper { 
				.heading {
					.sa_type {
						&:before {
							content: " - ";
						}
					}
				}
				.subheading {
					display:none;
				}
			}
			.si-inner-wrapper {
				.item {
					float: left;
					height: 258px;
					position: relative;

					.heading, .subheading { display: none; }

					&.stage1 {
						width: 48%;

						&:after {
							content: "";
							border-right: 1px solid #FFFFFF;
							height: 239px;
							width: 1px;
							position: absolute;
							top: 0px;
							right: 0px;
						}

						@include single-donut-large;
					}
					&.stage2 {
						width: 52%;

						.sa-description, .field-description {
							font-size: 15px;
							width: 80%;
							margin-left: auto;
							margin-right: auto;
							padding-top: 0px;		
							padding-bottom: 20px;
							text-align: left;	
							@include si-title-font;			
						}
						.field-description {
							border-bottom: 1px solid $white;
						}
						.field {
							float: left;
							margin-left: 10%;
							margin-top: 15px;
							
							.circle {
								width: 42px;
								height: 42px;
								border-radius: 42px;
								color: $black;
								background: white;
								text-align: center;
								margin-left: auto;
								margin-right: auto;
								font-size: 14px;
								text-transform: uppercase;
								float: left;
								margin-right: 8px;
								display:table;
								
								.circle-content {
									font-size: 25px;
									display: inline-block;
									position: relative;
									vertical-align: middle;
									text-align: center;
									@include si-title-font;
									display:table-cell;
									
									sup {
										//font-size: 7px;
									}
									&::after {
										content: "%";
										font-size: .33em; 
										position:relative;
										vertical-align: super;
									}
								}
							}
							.text {
								width: 300px;
								font-size: 17px;
								line-height: 40px;
								text-transform: uppercase;
								@include si-title-font;
								text-align: left;
							}											
						}
					}
				}
			}
		}		
	}
}