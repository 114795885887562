
// size for small catchup carousel
//   with card width of 330px

$slickButtonWidth: 35px;

$slickDisplayWidth: 340px;

$slickDisplayWidthSmallTablet: 360px;
$slickDisplayWidthLargeTablet: 730px;
$slickDisplayWidthLargeDesktop: 1100px;

$slickRolesButtonWidth: 20px;
$slickRolesDisplayWidth: 100px;

.generic-carousel {

    //width: 400px;
    margin: auto;
	position: relative;
    height: 100%;
	width: 100%;
	
	.slick-slider {
		position: relative;
		width: 100%;
		height: 100%;
    	margin: auto;
    	
		.slick-list {
			position: absolute;
			display: inline-block;
			width: $slickDisplayWidth;
       	 	left: calc((100% - $slickDisplayWidth) / 2);
		}

		.slickPrevArrow {
			position: absolute;
			width: calc((100% - $slickDisplayWidth) / 2);
			height: 100%;
			left: 0px;	
		}
		.slickNextArrow {
			position: absolute;
			width: calc((100% - $slickDisplayWidth) / 2);
			height: 100%;
			right: 0px;
		}
	}

    /*
    * use .lastInView in conjunction with an intersection observer to detect if the last item is in view
    * see the roleNav component for an example
    */
    &.lastInView{
        // .slick-next{
        //     display: none !important;
        // }
    }

    /** avoid slides become two rows when screen size gets smaller */
    .slick-track {
        display: flex;
    }

    .slick-arrow {
        font-family: "Icons" !important;
		font-size: 20px;
		color: $white;
		display: block;
		position: absolute;
    	width: $slickButtonWidth;
    	height: 100%;
		left: unset;
    	right: unset;

        &.slick-prev{
			right: 0px;
			&::before{
				content: "\e928";
				position: absolute;
				right: -7px;
				width: $slickButtonWidth;
				vertical-align: top;
    			line-height: 0;
			}
        }
        &.slick-next{
			left: 0px;
            &::before{
				content: "\e929";
				position: absolute;
				left: -7px;
				width: $slickButtonWidth;
				vertical-align: top;
    			line-height: 0;
			}
        }
		&.slick-disabled {
			display: none !important;
		}
    }
    .slick-dots {
        li {
            width: 6px;
            height: 6px;
            border-radius: 50%;		
            background: rgba(255,255,255,0.5);

            button {
                &:before {
                    content: '';
                    width: 100%;
                    height: 100%;
                }
            }
            &.slick-active {
                background: $white;
            }
        }
    }

	

	// ai catchup changes
    &.ai-catchup {
		width: 340px;
        height: 157px;
		
		.slick-prev{
			&:before {
				//font-size: 38px;
				//color: $carousel-text-color;
				right: 0px;
			}
		}
		.slick-next {
			&:before {
				//font-size: 38px;
				//color: $carousel-text-color;
				left: 0px;
			}
		}

		.slick-list {
			width:100%;
			left:0px;
		}
		.slickPrevArrow {
			width: 30px;
			left: -25px;
		}
		.slickNextArrow {
			width: 30px;
			right: -25px;
		}

		// 390px
		@include breakpoint(extraLargeMobile, min){

			.slickPrevArrow {
				width: 40px;
				left: -40px;
			}
			.slickNextArrow {
				width: 40px;
				right: -40px;
			}
		}

		// 500px breakpt, when cards go to 350px wide
		@include breakpoint(extraSmallTablet, min){
			width: 360px;
		}

		//820 px breakpt
		@include breakpoint(largeTablet, min){
			width: 720px;
		}

		//1200 px breakpt
		@include breakpoint(medDesktop, min){
			width: 1080px;
		}

    }

	&.ai-card-carousel{
        width: 100%;
        
		.slick-slider {
			.slick-list {
				width:100%;
				left:0px;
				// height: 640px;
				height: 100%;
			}
		}
    }

	// role nav changes
	&.roleNav-carousel {
		width: calc(100% - 10px);

		.roleWrap {
			// width: 100%;
			// min-width: 100px;
			// max-width: 100px;
			// margin: auto;
			// padding: 0px 10px;
		}

		.slick-slider {
    	
			.slick-list {
				position: absolute;
				display: inline-block;
				width: calc(100% - (($slickRolesButtonWidth * 2) + 10px));
				left: $slickRolesButtonWidth;
			}

			.slickPrevArrow,
			.slickNextArrow {
				width: $slickRolesButtonWidth;
			}
			 
			.slick-prev, .slick-next {
				&:before {
					color: $carousel-text-color;
				}
			}
		}
	}

	// related content carousel in player profile
	&.content-carousel {
		width: calc(100% - 80px);
		height: 285px;
		overflow: visible;

		.content-wrap {
			width: 100%;
			min-width: 225px;
			max-width: 300px;
			margin: auto;
			padding: 0px 10px 10px 10px;

			.news-tile {
				top: 10px;
			}

			span.content {
				display: block;
  				position: relative;
				overflow: visible;
			}
		}

		.slick-prev{
			&:before {
				font-size: 38px;
				color: $carousel-text-color;
				right: 0px;
			}
		}
		.slick-next {
			&:before {
				font-size: 38px;
				color: $carousel-text-color;
				left: 0px;
			}
		}

		.slick-list {
			width:100%;
			left:0px;
		}
		.slickPrevArrow {
			width: 40px;
			left: -40px;
		}
		.slickNextArrow {
			width: 40px;
			right: -40px;
		}

		//above extraSmallTablet - 500px
		@include breakpoint(extraSmallTablet, min) {	
			height: 285px;	
			.content-wrap {
				width: 300px;
			}
		}

		//above smallTablet - 692px
		@include breakpoint(smallTablet, min) {

		}

		//above tablet - 768px
		@include breakpoint(tablet, min) {
			//width: calc(100% - 20px);
			width: 90%;
			overflow: visible;

			&.sidepanel {
				width: calc(100% - 80px);
				right: unset !important; 
			}

			@supports (-moz-appearance:none) {
				&.sidepanel {
					width: calc(100% - 60px); //offset because FF won't let you hide scrollbars
				}
			}

			.content-wrap {
				width: 300px;
			}			
	
			.slick-list {
				width:100%;
				left:0px;
			}
			.slickPrevArrow {
				width: 5%;
				left: -5%;
			}
			.slickNextArrow {
				width: 5%;
				right: -5%;
			}
		}
	}

	&.usePeek {
		width: 100%;

		@include breakpoint(extraLargeMobile, min){
			width: 100%;
			.slick-list {
				width: 100%;
				left: 0px;
			}
		}

		.slick-list {
			width: 100%;
			left: 0px;
		}
	} 


	// &.dark-arrow {
    //     .slick-prev, .slick-next {
    //         color: $carousel-text-color;

    //         &:before {
    //             color: $carousel-text-color;
    //         }
    //     }
    // }

    // &.light-arrow {
    //     .slick-prev, .slick-next {
    //         color: $white;

    //         &:before {
    //             color: $white;
    //         }
    //     }
    // }

} // .generic-carousel


.page.expanded {
    .generic-carousel {
		&.ai-catchup {
			//1365px
			@include breakpoint(desktopSidePanel, min){
				width: 720px;
			}
			//1601px
			@include breakpoint(largeDesktop, min){
				width: 1080px;
			}
			//1964px
			@include breakpoint(extraLargeDesktop, min){
				width: 1440px;
			}
		}

		&.content-carousel {
			overflow: visible;

			@include breakpoint(desktopSidePanel, min){
				width: 90%;
				.slick-list {
					width:100%;
					left:0px;
				}
				.slickPrevArrow {
					width: 5%;
					left: -5%;
				}
				.slickNextArrow {
					width: 5%;
					right: -5%;
				}
			}

			@include breakpoint(largeDesktop, min){
				width: 90%;
				.slick-list {
					width:100%;
					left:0px;
				}
				.slickPrevArrow {
					width: 5%;
					left: -5%;
				}
				.slickNextArrow {
					width: 5%;
					right: -5%;
				}
			}
		}
	}
}

/** hide arrows when overlay is displayed */
.modal-on-noscroll-nofixed {
    .slick-arrow {
        display: none !important;
    }
}