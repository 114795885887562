.ticket-action-modal-close-btn {
    @media (width:320px) {
        top: 8px;
    }
    border: none;
    background: none;
    z-index: 3;
    cursor: pointer;
    position: absolute;
    right: 16px;
}

.show-full-tix-close-btn {
    right: 8px;
    top: 8px;
    border: none;
    background: none;
    cursor: pointer;
    position: absolute;
    @include breakpoint(desktop) { 
        right: 24px;
        top: 16px;
    }
    
}


.ticket-action-modal-title {
    @media (width: 320px) {
        padding: 2px;
    }
    margin-top: 32px;
    text-transform: uppercase;
    color: #54008B;
    font-size: 22px;
    text-align: center;
    margin-bottom: 8px;
    font-weight: bold;
    @include breakpoint(tablet) { 
        margin-top: 32px;
    }
}

.show-ticket-type-header-logo-img {
    width: 38px;
    margin-top: 16px;
    margin-bottom: 18px;
}

.ticket-action-modal-divider-container {
    width: 100%;
    min-height: 8px;
    height: auto;
    position: relative;
}

.ticket-action-modal-title-divider {
    height: auto;
    margin: 0 auto;
    padding: 10px;
    position: relative;
    border-top: 2px solid #4F2683;
    width: 24px;
}

.ticket-action-errorMsg {
    color: #cc0000;
    margin-bottom: 12px;
    @include breakpoint(tablet) { 
        position: absolute;
        margin-top: -16px;
        margin-left: 10px;
    }
}

.webview-ticket-action-errorMsg {
    color: #cc0000;
    margin-bottom: 12px;
}

.ticket-action-modal-input-label {
    @media (width:320px) {
        margin: 0px 4px 0px 4px;
    }
    font-size: 14px;
    text-align: left;
    color: #54018A;
    letter-spacing: 0.5px;
    background: #FFFFFF;
    @include breakpoint(tablet, min, true) {
        width: 400px;
        margin: 8px;
    }
    @include breakpoint(desktop) {
        width: 100%;
    }
    
}

.ticket-transfer-emails-cntr {
    width: 100%;
    align-items: center;
    justify-content: center;
    @include breakpoint(tablet) { 
        display: flex;
    }
}

.webview-ticket-transfer-emails-cntr {
    width: 100%;
    align-items: center;
    justify-content: center;
    @include breakpoint(tablet, min, true) {
        display: inline-flex;
    }
}

.webview-ticket-stub-actions-btns-cntr {
    @include breakpoint(tablet, min, true) {
        display: inline-flex;
    }
}


.ticket-action-modal-input-label-left {
    font-size: 14px;
    // text-align: left;
    color: #54018A;
    letter-spacing: 1px;
    background: #FFFFFF;
    font-weight: bold;
    input {
        border-radius: 8px;
    }
    @include breakpoint(tablet) { 
        width: 300px;
        margin-right: 8px;
        // float: left;
        margin-bottom: 16px;
    }
    @include breakpoint(desktop) { 
        width: 400px;
    }
}

.ticket-action-modal-email-label {
    text-align: center;
}

.ticket-action-modal-email {
    color: black;
    margin-left: 8px;
    font-weight: bold;
    word-wrap: break-word;
}

.ticket-action-modal-bottom-margin {
    margin-bottom: 24px;
}

.ticket-action-modal-input-label-right {
    font-size: 14px;
    // text-align: left;
    color: #54018A;
    letter-spacing: 1px;
    font-weight: bold;
    background: #FFFFFF;
    input {
        border-radius: 8px;
    }
    @include breakpoint(tablet) { 
        width: 300px;
        margin-left: 8px;
        margin-bottom: 16px;
    }
    @include breakpoint(desktop) { 
        width: 400px;
    }
}

.ticket-action-modal-input {
    border: 1px solid #979797;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    border-radius: 4px;
    margin-bottom: 8px;
}


.ticket-action-modal-conf-help {
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 24px;
}

.ticket-action-modal-conf-help-wide {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 24px;
    margin-left: 48px;
    margin-top: 24px;
}

.ticket-stub-return-to-list-btn {
    margin-bottom: 24px;
    @include breakpoint(desktop) { 
        width: fit-content;
    }
}

.ticket-stub-swap-return {
    height: 36px;
    width: 100%;
    color: white;
    text-align: center;
    line-height: 32px;
    margin-top: 4px;
    border: 1px solid $wim-green;
    border-radius: 8px;
    text-transform: uppercase;
    background: $wim-green;
    cursor: pointer;
    font-size: 16px;
    @include gotham-book();
    font-weight: bolder;
    @include breakpoint(tablet) { 
        width: 260px;
    }
}

.ticket-action-modal-header {
    font-size: 14px;
    color: #333333;
    letter-spacing: 0;
    text-align: center;
    line-height: 17px;
    margin-bottom: 24px;
    @include breakpoint(desktop) { 
        margin-right: 25%;
        margin-left: 25%;
    }
}

.webview-ticket-action-modal-header {
    font-size: 14px;
    color: #333333;
    letter-spacing: 0;
    text-align: center;
    line-height: 17px;
    margin-bottom: 24px;
}

.ticket-action-modal-button-green {
    @media(width: 320px) {
        width: 98%;
    }
    background: #097542;
    color: white;
    height: 42px;
    text-align: center;
    border: 1px solid #BBBBBB;
    border-radius: 8px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: bolder;
    @include breakpoint(tablet) {
        font-size: 16px;
        width: 230px;
        margin: 8px;
    }
    @include breakpoint(desktop) { 
        width: 200px;
    }
}

.webview-ticket-action-modal-button-green {
    @media(width: 320px) {
        width: 98%;
    }
    background: #097542;
    color: white;
    height: 36px;
    text-align: center;
    border: 1px solid #BBBBBB;
    border-radius: 8px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: bolder;
}

.ticket-action-modal-form {
    background: white;
    margin-top: 14px;
    display: block;
    align-items: center;
}

.ticket-action-modal-general-label {
    @media(width:320px) {
        margin-left: 4px;
    }
    color: $wim-search-text-purple;
    font-size: 14px;
    width: 100%;
    font-weight: bold;
    color: $wim-search-text-purple;
    letter-spacing: 1px;
    line-height: 14px;
    display: flex;
    margin-top: 32px;
    justify-content: left;


    @include breakpoint(tablet) {
        color: $wim-black;
        margin-top: 0px;
        font-size: 16px;
        margin-bottom: 32px;
    }
}

.ticket-action-modal-general-label-single-tix {
    @media(width:320px) {
        margin-left: 4px;
    }
    color: $wim-search-text-purple;
    font-size: 14px;
    width: 100%;
    font-weight: bold;
    color: $wim-search-text-purple;
    letter-spacing: 1px;
    line-height: 14px;
    display: flex;
    margin-top: 32px;
    justify-content: left;


    @include breakpoint(tablet, min, true) {
        color: $wim-black;
        margin-top: 0px;
        font-size: 16px;
        margin-bottom: 32px;
        justify-content: center;
    }
}

.ticket-action-modal-section {
    @media (width:320px){
        margin-bottom: 0px;
    }
    margin-bottom: 24px;

    @include breakpoint(tablet) {
        margin: 24px 48px;
    }
    
}

.ticket-action-modal-loader {
    position: relative;
    width: 100%;
    height: 100px;
}

.ticket-action-modal-information-text {
    font-size: 14px;
    color: #333333;
    letter-spacing: 0.44px;
    text-align: center;
    line-height: 18px;
    margin-bottom: 24px;
    padding: 0 15px;
    font-weight: 500;
    overflow: hidden;
    word-break: break-word;
}

.show-full-ticket-action-container {
    width: 280px;
}

.show-ticket-type-header-image {
    height: 112px;
    width: 100%;
    position: relative;
}

.show-ticket-type-img {
    position: absolute;
    bottom: 16px;
    z-index: 0;
    left: -20px;
}

.ticket-padding-top {
    padding-top: 32px;
}

.ticket-action-modal-ticket-stub {
    @media (width:320px){
        margin-top: 8px;
    }
    cursor: auto;
    margin-top: 16px;
    @include breakpoint(tablet) {
        margin-top: auto;
    }
}

.webview-ticket-action-modal-ticket-stub {
    cursor: auto;
}

.show-ticket-type-header-txt-container {
    position: absolute;
    bottom: 24px;
    width: 100%;
    height: 100%;
    text-align: center;
}

.show-ticket-type-header-txt {
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0.8px;
    text-align: center;
    text-shadow: 0 2px 4px rgba(0,0,0,0.50);
}

.show-ticket-type-qr-code-container {
    text-align: center;
    margin-bottom: 24px;
}

.show-ticket-type-qr-code-img {
    height: 178px;
}

.show-full-ticket-action-detail-txt-left {
    width: 50%;
    font-size: 10px;
    float: left;
    margin-bottom: 8px;
    height: 48px;
}

.show-full-ticket-action-detail-txt-right {
    width: 50%;
    font-size: 10px;
    float: right;
    margin-right: -8px;
    margin-bottom: 8px;
    height: 48px;
}

.show-full-ticket-action-detail-txt-label {
    font-size: 12px;
    color: #54018A;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

.show-full-ticket-action-detail-txt {
    font-size: 22px;
    color: #333333;
    letter-spacing: 0;
    margin-bottom: 8px;
    height: 48px;
}

.show-full-ticket-action-detail-txt-left-small {
    width: 30%;
    font-size: 10px;
    float: left;
    margin-bottom: 8px;
    height: 48px;
}

.show-full-ticket-action-detail-txt-right-small {
    width: 20%;
    font-size: 10px;
    float: right;
    margin-right: -8px;
    margin-bottom: 8px;
    height: 48px;
}

.show-full-ticket-action-detail-txt-middle-small {
    width: 25%;
    display: inline-block;
    margin-bottom: 8px;
    height: 48px;
}

.show-full-ticket-court-img {
    margin-top: 8px;
}


.show-full-ticket-action-details-bottom-section-container {
    background: #F6F4FF;
    display: grid;
    padding: 8px;
}

.show-full-ticket-action-detail-small-txt-details {
    font-size: 14px;
    color: #333333;
    letter-spacing: 0;
    text-transform: capitalize;
}

.ticket-action-dropdown-label {
    padding-bottom: 48px;
    text-align: left;
}

.ticket-modal-window-open {
    @media (width:320px){
        padding: 0px;
        min-height: 262px;
    }
    visibility: visible;
    pointer-events: auto;
    width: 100%;
    height: 100vh;
    display: contents;
    top: 0px;
    bottom: 0px;
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    padding: 16px;
    align-items: center;
    @include breakpoint(tablet) {
        width: 546px;
    }
    z-index: 100;
    @include breakpoint(desktop) {
        width: 546px;
    }
}

.ticket-modal-window-open-large {
    visibility: visible !important;
    opacity: 1 !important;
    pointer-events: auto !important;
    min-height: 248px;

    max-height: 100vh;
    position: absolute;
    inset: 50% auto auto 50%;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 16px;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    align-items: center;
    max-width: 100vw;
    /* iPads (landscape) ----------- */
    @media only screen 
        and (min-device-width : 768px) 
        and (max-device-width : 1024px) 
        and (orientation : landscape) {
        /* Styles */
        margin-right: 0%;
    }
    @include breakpoint(desktop) {
        width: 700px;
    }
    z-index: 100;
}

// overlay
.ticket-modal-overlay {
    position: fixed;
    inset: 0px;
    background-color: rgb(255, 255, 255);
    z-index: 100;
    padding: 24px;
    overflow: scroll;
}

// overlay not full height 
.ticket-modal-overlay-not-full {
    inset: 0px;
    margin-top: 50%;
    background-color: rgb(255, 255, 255);
    z-index: 100;
    padding: 24px;
    overflow: scroll;
    @include breakpoint(tablet) {
        margin-top: 25%;
    }
}


.hide-ticket-modal {
    visibility: hidden;
    display: none;
}


.ticket-swap-container {
    text-align: center;
    background-color: transparent;
    color: white;
}

.ticket-swap-informative-txt {
    font-style: italic;
    padding: 24px;
    @include breakpoint(tablet) {
        width: 400px;
        display: inline-flex;
    }
}

.ticket-swap-qr-code {
    @media (width: 320px) {
        width: 312px;
		height: 312px;
    }
    //border: 16px solid white;
    width: 344px;
	height: 344px;
    margin: auto;
    margin-top: 24px;
	position: relative;
	background-color: white;

    img {
		position: absolute;
		top: 16px;
		left: 16px;
    }
	canvas {
		position: absolute;
		top: 16px;
		left: 16px;
	}
}

.ticket-swap-action-btns-cntr {
    margin: 16px;
    justify-content: center;
    align-items: center;
    @include breakpoint(tablet) {
        display: grid;
    }
}


.ticket-swap-error-msg {
    text-align: left;
    background-color: white;
    color: black;
    padding: 24px;
    margin: 16px;
}


.ticket-swap-action-btns {
    margin-bottom: 16px;
    margin-top: 16px;
    border: 1px solid white;
    font-family: 'Gotham 4r', Helvetica, Arial, sans-serif;
}

.ticket-swap-back-btn {
    width: 100%;
    display: block;
    font-style: normal;
    background-color: $wim-green;
    border-radius: 8px;
    color: white !important;
    text-transform: uppercase;
    font-weight: 600;
    height: 42px;
    padding: 2px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    border: 1px solid $wim-green;
    font-size: 16px;
    margin-bottom: 16px;

    img {
        padding-right: 8px;
    }
}

.swap-ticket-action-container {
    background-color: white;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin-top: 0%;
    padding: 16px;

    @include breakpoint(extraLargeMobile) { 
        height: 100%;
    }
}


.ticket-swap-confirmation {
    background: white;
    color: black;
    padding: 48px;
    font-size: 14px;
    height: 100%;

    .close-btn-container {
        float: right;
    }

    @include breakpoint(tablet) { 
        width: 400px;
        display: inline-block;
    }
}

.qr-code-reader {
    width: 100%;
    video {
        max-height: 90vh;
    }
}