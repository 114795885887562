.slamtracker {
    .unit-toggle, .serve-selection {
        position: relative;
        width: 100%;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F5F5F5;
        
        label {
            color: $wim-black;
            @include gotham(medium);
            font-size: 12px;
            display: inline-block;
        }
        input[type="radio"] {
            vertical-align: middle;
            outline: none;
            margin: 0;
                    
            &:checked+label {
                @include gotham(bold);
            }
        }
        .radioBtn {
            margin: 0 6px;

            .checkmark {
                height: 13px;
                width: 13px;
                border-color: $black;

                &:after {
                    background: $black;
                    width: 5px;
                    height: 5px;
                }
            }
            label {
                padding-left: 18px;                
            }
        }
        a {
            cursor: pointer;
            color: $wim-black;
            font-size: 12px;
            text-decoration: none;
            position: relative;
            padding-left: 25px;
            height: 20px;
            display: flex;
            align-items: center;

            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 20px;
                width: 20px;
                background-color: #fff;
                border-radius: 50%;
                border: 1px solid $wim-text-purple;
                
                &:hover {
                    box-shadow: inset 0 0 0 1px $wim-text-purple;
                }
                &:after {
                    top: 3px;
                    left: 3px;
                    width: 11px;
                    height: 11px;
                    border-radius: 50%;
                    background: $wim-text-purple;
                    content: "";
                    position: absolute;
                    display:none;
                }
            }
            // input {
            //     position: absolute;
            //     opacity: 0;
            //     cursor: pointer;
            //     z-index: 1;
            // }
            // input:checked ~ .checkmark:after {
            //     display: block;
            // }

            i {
                margin-right: 5px;
                font-size: 12px;
            }
            .wim-icon-radio-button {
                display: inline;
            }
            .wim-icon-radio-selected {
                display: none;
            }
            &.selected {
                .wim-icon-radio-button {
                    display: none;
                }
                .wim-icon-radio-selected {
                    display: inline;
                }
                .checkmark:after {
                    display: block;
                }
            }
            &:hover {
                color: $wim-black;
            }
        }
        .unit-imperial { margin-left: 10px; }
    }
    .fixed {
        .unit-toggle {
            display: none;
        }
    }

    // .slamtracker-stats.expanded {
    //     .unit-toggle, .serve-selection {
    //         display:none;
    //     }
    // }
}

// @include general-breakpoint(smtablet) {
//     .slamtracker {
//         .unit-toggle {
//             width: 20%;
//             text-align: center;
//             margin: -30px 40% 0px;
//             display: block;
//         }
//     }
// }