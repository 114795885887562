.page {       
    .wrapper {
        .content-main {
            &.thehill {
				padding: 0px;

				.column-layout {
					padding: 0;
				}

				.hill-links {
					margin: 10px 10px;
					line-height: 30px;
					font-weight: bold;
				}
            }
        }
    }
}
