.wrapper.webview{
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
}

.webview .ai-catchup {
    background: transparent;
    &.fullview{
        width: 100vw;
        // height:660px;
        height: calc(100vh - 14px);
        margin: 0px auto;
        background: transparent;

        .cardCount{
            color: $white; 
            width: 100%; 
            text-align: center; font-size: 14px; line-height: 13.4px;
            @include gotham(medium); 
            span{
                @include gotham(bold);
            }
        }
    
        .ai-catchup-cards{ 
            display: flex;
            justify-content: center;
            margin: auto;

            .player-card{
                background-color: $white;
                border-radius: 10px;
                border: 1px solid $gray_bb;
                width: calc(100% - 15px);
                // height: 600px;
                height: calc(100vh - 20px - 45px - 16px); // - slide number height - written by height - margin-bottom
                min-height: 500px;
                margin: 8px 0px 16px 0px;
                padding: 12px 12px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .player-info{
                //   height: 520px; 
                height: calc(100% - 40px);
                  display: flex;
                  flex-direction: column;
                  flex-wrap: nowrap;
                  .wrapper-player-info{ display: none;}  //hides non-webview
                  
                  .ai-recommends{
                    position: relative;

                    .ai-type{
                        color: #888888;
                        @include gotham;
                        font-size: 12px; line-height: 14.4px; letter-spacing: 0.3px;
                        text-transform: uppercase;
                        padding-top: 6px;
                    }
                    .new-tag{
                        position: relative;
                        margin: -12px auto 0px;
                        background-color: #bde403;
                        width: 45px;
                        height: 21.5px;
                        color: #333;
                        font-family: "Gotham 5r",Helvetica,Arial,sans-serif;
                        font-size: 10px;
                        line-height: 16px;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                        text-align: center;
                        display: block;
                    }
                  }

                  .player{
                    margin: 12px 0px;
                    display: flex; justify-content: space-between;
                    .flag{ width: 55px; height: 37px; margin-right: 15px; }
                    .details{
                        flex-grow: 2; display: flex; flex-direction: column;
                        @include gotham(medium); line-height: 18px; letter-spacing: 1.29px;
                        .fname{ font-size: 14px; display: block;}
                        .lname{ font-size: 18px;}
                    }
                    .fav-container{
                        float: right;
                        margin-top: 0px; height: 100%; width: auto;
                        display: flex; justify-content: space-evenly;
                        .icon-settings{
                            margin-top: 10px;
                            margin-right: 10px;
                            color: $wim-text-purple;
                            font-size: 15px;
                        }
                        .favorite {
                            position: relative;
                            margin-right: 20px;
                            font-size: 24px;
                            cursor: pointer;
                            &.purple{ color: $wim-text-purple; }
                            &:after {
                                position: absolute;
                                left: 0;
                                content: "\2606";
                            }
                            &.favorited {
                                font-size: 24px;
                                &:after{
                                    font-size: 24px;
                                    content: "\2605";
                                    color: $wim-text-purple;
                                }
                            }
                        }
                    }
                  }
                  .ranking{ 
                    height: auto;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .player-img{
                        width: 100%;
                        height: 154px;
                        background-size: cover;
                        display: flex;
                        justify-content: center;
                    }
                    .wrapper-ranking{ display: flex; justify-content: space-between; height: 40px;}
                    .singles-rank, .doubles-rank{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 50%; 
                        .rank-no{
                            font-size:32px; line-height: 11px; letter-spacing: 1px;
                            @include gotham(medium);
                        }
                        .title{ 
                            text-transform: uppercase;
                            @include gotham(medium); font-size: 12px;
                            margin-left: 12px; letter-spacing: 1px;
                        }
                    }
                  }

                    .ai-summary{
                        @include gotham; color: $wim-black;
                        font-size: 16px; line-height: 20px; letter-spacing: 0.25px;
                        overflow-y: scroll;
                        border-top: 1px solid $tourrank-color;
                        margin-left: -12px;
                        margin-right: -12px;
                        flex-grow: 1; // make the height flexible

                        &.full{ 
                            overflow-y: auto; 
                            scrollbar-width: none; //firefox
                            -ms-overflow-style: none; //edge
                            &::-webkit-scrollbar {display: none;} //chrome - ios
                        }
                        p{ margin: 14px 12px 10px; height: auto;}
                    }
                    .updated{
                        display: inline-flex;
                        color: #999999; text-transform: uppercase;
                        font-size: 12px; line-height: 14px;
                        margin-bottom: 28px;
                        position: relative;
                        margin-left: 12px;
                    } 

                    /** make player card flexible height */
                .scrollable {
                    overflow: auto;
                  }
                .player-info > :not(.scrollable):not(.non-scrollable) {
                    flex-shrink: 0;
                  }
                  
                  .player-info > .scrollable  {
                    flex-grow: 1;
                  }
                }

                .button-wrapper{
                    .generic-button, .content:hover{ border-radius: 6px;}
                    .generic-button_wrapper{margin-bottom: 0px;}
                }
                
            }
        }
        
        .ai-catchup-summary{
            margin: 0px auto!important;
            padding-top: 0px!important;
            display: block;

            .summary-card{
                background-color: $white;
                border-radius: 10px;
                border: 1px solid $gray_bb;
                padding: 12px 12px;
                position: relative;
                // margin: 16px auto;
                margin: 8px auto 16px auto;
                width: calc(100% - 15px);
                // height: 600px;
                height: calc(100vh - 20px - 45px - 16px); // - slide number height - written by height - margin-bottom
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                
                .new-tag{
                    margin-top: -13px;
                    left: 43%;
                }

                .image-container{
                    width: 228px; height:130px; margin: 10px auto;
                    img{ width: 100%; height: 100%; border-radius: 6.95px;}
                }
                .title{
                    @include gotham(medium);
                    font-size: 18px;
                    display: inline-flex;
                    padding-bottom: 7px;
                    justify-content: center;
                }
                .summary-info{
                    height: 100%; overflow: hidden;
                    .text{ 
                        @include gotham;
                        font-size: 14px;
                        line-height: 20px;
                        // height: 395px; 
                        height: 100%;
                        overflow-y: scroll;
                        p{ padding-bottom: 10px;}
                    }
                }
                .signup{
                    &:hide{visibility: hidden;} 
                    .generic-button_wrapper{ margin-bottom: 7px;}
                    .generic-button{ border-radius: 6px;
                        .content{ 
                           @include gotham(medium);
                            font-size: 14px; line-height: 13.4px; letter-spacing: 0.5px;
                            padding-top: 10px;
                            &:hover{ border-radius: 6px;;}
                        }
                    }
                }
            }
        }

        .ai-subtext{
            background-color: transparent;
            color: $white;
            display: inline-flex; justify-content: center;
            @include gotham-book; 
            font-size: 11px; line-height: 15px; letter-spacing: 0.25px;
            width: 100%;
            margin: auto;
            span{ font-weight: bold; font-size: 12px;}
            .question{ 
                .wim-icon-help{ font-size: 16px;}
            }

            .wim-icon-watsonx {
                font-size: px2rem(26);
                margin-left: 5px;
                margin-top: -5px;
            }
        }
    }
    &.home {
        .ai-catchup-cards-container {
            .ai-catchup-cards {
            .summary-card, .player-card {
                .new-tag{
                    margin: -12px auto 0px;
                    background-color: #bde403;
                    width: 45px;
                    height: 21.5px;
                    color: #333;
                    font-family: "Gotham 5r",Helvetica,Arial,sans-serif;
                    font-size: 10px;
                    line-height: 18px;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                    text-align: center;
                    position: absolute;
                }
            }

            }
        }
    }
}