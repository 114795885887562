/** schedule styles **/
#schedule {
    .content-main { 
		@include gotham(medium);

		padding-top: 0px;
		z-index: 15;

		.column-layout {
			&.no-padding {
				padding: 0px;
			}

			.header {
				max-width: calc(100% - 20px);

				@include breakpoint(extraLargeMobile){
					max-width: 600px;
					margin: auto;
				}
			}

		}
	}

    .schedule-clock {
        width: 250px;
        float: right;
        display:none;
    }
    .schedule-info {
        text-transform: uppercase;
        color: #FFFFFF;
        text-align: left;
        .day {font-size:14px;}
        .revised{font-size:11px; @include gotham}
    }
    .schedule-court {
        display: table;
        table-layout: fixed;
        width:100%;
        border-top:9px solid $wim-green;
        border-bottom:9px solid $wim-purple;
        margin-bottom: 20px;
        //@include border-radius(9px);
        // z-index: 1;

        div {
            padding: 0;
            margin: 0;
        }
        .courtName {
            color: #FFFFFF;
            text-align: center;
            vertical-align: middle;
            text-transform: uppercase;
            border: 2px solid $gray_bb;
            border-top:none;
            background:rgba(255,255,255,0.3);
            font-size:14px;
            line-height:21px;
            //@include gotham_ssm_medium;
            //@include background-rgba(#FFFFFF,.5);
            &:hover{ cursor: pointer;}
        }
        .schedule-content {
            border: 2px solid $gray_bb; border-top:none;

            .row {
                float: none;
                display: table;
                table-layout:fixed;
                min-width: 100%;
                width:auto;

                &.mobile { 
                    height:24px;
                    line-height: 24px;
                    padding:0px 10px;
                }
                &.teams {
                    padding:0px 10px; 
                    padding-top:5px;
                    text-align: left;
                    position: relative;

                    .match-favorite {
                        position: absolute;
                        right:6px;
                        top:6px;
                        padding-left:10px;
                        display:flex;
                        &.highlight-removed {
                            a {
                                display:block;
                                background: #004024;
                                padding:0px 5px;
                                &:before {
                                    content:"Removed from watchlist";
                                    color:$white;
                                    @include gotham;
                                    font-size:10px;
                                    text-transform:uppercase;
                                    position:relative;
                                    bottom:4px;
                                    padding-left:5px;
                                    padding-right:5px;
                                }
                            }
                        }
                        &.highlight-added {
                            a {
                                display:block;
                                background: #004024;
                                &:before {
                                    content:"Added to watchlist";
                                    color:$white;
                                    @include gotham;
                                    font-size:10px;
                                    text-transform:uppercase;
                                    position:relative;
                                    bottom:4px;
                                    padding-left:5px;
                                    padding-right:5px;

                                }
                            }
                        }
                        i {
                            color:$wim-green;
                            font-size: px2rem(21);
                        }
                    }
                }
            }
        
        }
        .match {
            width: 100%;
            background-color: #FFFFFF;

            .header {
                background-color: $green_1f;
                color: #FFFFFF;
                //height: 32px;
                //line-height:32px;
                text-transform: uppercase;
                font-size: 10px;
                &.scores {display:none;}
            }
            .content {
                background-color: #FFFFFF;
                color: #333333;
                vertical-align: middle;
                font-size: 12px;
                padding:0px 0px 0 5px;
                line-height:20px;
                &.status {display:none;}
            }
            .schedule-player {
                padding-left:5px;
                padding-right:5px;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                &.favorite {
                    background-color:$wim-purple;
                    color:$white;
                    position:relative;
                    top:auto;
                    left:auto;
                    cursor:default;
                    &:after {display:none;}
                    a {color:$white;}
                }
                &.search {
                    background-color: $wim-yellow !important;
                    color: $wim-green !important;
                    a {
                        color: $wim-green !important;
                    }
                    &.favorite {
                        a {
                            color: $white;
                        }
                    }
                }
            }
            .match-info {
				padding: 10px 15px;
				text-align: left;

				.match-info-notBefore{
					font-style: italic;
					font-size: 10px;
					padding-bottom: 4px;
				}

				.event, .round {
					// margin: 5px 0px;
				}

				&.header {
					max-width: unset;
				}
			}
            .scores {float:right; font-size:12px; word-spacing:5px; white-space: nowrap; text-align: right;}
            .status {float:left; font-size:10px; @include gotham; padding-left:5px;}
            .nation,.seed {@include gotham;}
            .schedule-team {padding-left:0px;
                a {
                    color: $wim-green;
                    text-decoration: none;
				}
				
				.winner-of-holder {
					display: flex;
					flex-wrap: wrap;

					.winner-of {
						flex: 0 0 100%;
					}
					.winner-of1 {
						flex: 1 0 20px;
					}
					.winner-of2 {
						flex: 1 0 20px;
					}

					.winner-versus {
						flex: 0 0 50px;
					}
				}

            }
            .versus {height:26px; line-height:26px; @include gotham;}
        }
    }
    //mobile dropdown styling for order of play schedule page
    
    .select-menu-dropdown.mobile {
        top: 0px;
    }
    .daynav-wrapper {
        .animation-container {
            max-height: 303px !important;
        }
    }
}

@include breakpoint(tablet) {
    #schedule {
        //width: 90%;
       // margin: 0px auto;

        #scheduleDropdown { display: none; }
        #scheduleNav { display: block; }
 
        .schedule-clock {
            display: block;
        }
        .schedule-info {
            float:left;
            width: calc(100% - 250px);
            position: relative;
            top:40px;
        }
        .schedule-court {
            border-top:none; 
            border-bottom:none;
            border-left:9px solid $wim-green; 
            border-right:9px solid $wim-purple;

            .courtName {
                float: none;
                display: table-cell;
                width: 20%;
                height: auto !important;
                border:none;
                
                border-top: 2px solid $gray_bb;
                border-bottom: 2px solid $gray_bb;
            }
            .schedule-content {
                float: none;
                display: table-cell;
                width: 80%;
                border-top: 2px solid $gray_bb;
                border-right:none;

                .row {
                    float: none;
                    display: table;
                    table-layout:fixed;
                    width: 100%;
                    &.teams {
                        height:60px; 
                        padding-top:0px;
                        .match-favorite {
                            display: table-cell;
                            position: relative;
                            vertical-align: middle;
                            top: auto;
                            right: auto;
                            text-align: right;

                            &.highlight-added, &.highlight-removed {
                                a {
                                    display:flex;
                                    &:before {
                                        bottom:auto;
                                    }
                                }
                            }
                        }
                    }
                    &.mobile { display: none; }
				}
            }

            .match {
                float: none;
                display: table;
                table-layout:fixed;
                .header {
                    float: none;
                    display: table-cell;
                    vertical-align: middle;
                    &.match-info{width:auto; white-space: nowrap; }
                    &.scores { display: table-cell; width:auto; text-align: right; white-space: nowrap; }
                }
                .content {
                    float: none;
                    display: table-cell;
                    vertical-align: middle;
                    width: calc(100% - 10px);

                    &.status { display: table-cell; }
                }
                .match-info {
                    width: calc(80% - 10px);
					font-size: 12px;
					
					.match-info-notBefore{
						font-size: 12px;
					}
                }
				.scores {font-size:14px;width: 20%; padding-right:10px;}


                .schedule-team {
                    width: 35%;
                    vertical-align: middle;

                    &:first-child {
                        width:auto;
                        min-width: calc(35% - 10px);
					}
					
					.winner-of-holder {
						display: flex;
						flex-wrap: wrap;
	
						.winner-of {
							flex: 0 0 100%;
						}
						.winner-of1 {
							flex: 0 0 100%;
						}
						.winner-of2 {
							flex: 0 0 100%;
						}
	
						.winner-versus {
							flex: 0 0 100%;
						}
					}
                }
                .name,.nation {font-size:12px;}
                .seed{font-size:10px;}
                .versus {
                    width: 12%;
                    text-align: center;
                    vertical-align: middle;
                    padding-left: 0px;
                    font-size:12px;
                }
                .status {
                    width: 20%;
                    text-align: right;
                    font-size:12px;
                }
            }
        }
    }
}

@include breakpoint(desktop) {
    #schedule {
        .schedule-court {
            .match {
                .schedule-team {
                    width: 39%;
					&:first-child { width:auto; min-width: calc(39% - 10px); }
					
					.winner-of-holder {
						display: flex;
						flex-wrap: wrap;

						.winner-of {
							flex: 0 0 100%;
						}
						.winner-of1 {
							flex: 1 0 20px;
						}
						.winner-of2 {
							flex: 1 0 20px;
						}
	
						.winner-versus {
							flex: 0 0 50px;
						}
					}
					
                }
                .status { width: 12%; }
            }
        }
    }
}

.oop_pdfs {
	padding: 10px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;

	.title {
		font-size: 18px;
		font-weight: bold;
		padding-top: 15px;
		flex: 0 0 100%;
	}
	.item {
		flex: 0 1 25%;
	}
}