.newsletter-why-sign-up{
    @include gotham-book();
    width:100%;

    .why-sign-up{
        &-title{
            color:$gray-650;
            font-size:18px;
            line-height:1.1em;
            padding-bottom:10px;
        }

        &-image-bar{
            display:flex;

            @include breakpoint(tablet,max){
                flex-direction:column;

                .image-bar-item{
                    margin-bottom:20px;
                }
            }

            .image-bar-item{
                flex:1 1 25%;
                display:flex;
                flex-direction:column;
                text-align:center;
                color:$wim-black;
                font-size:14px;
                line-height:1.5em;

                &.news{
                    img{
                      width: 121px;
                    }
                }

                img{
                    width:80px;
                    height:80px;
                    align-self:center;
                    margin-bottom:10px;
                }

                .item-text{
                    max-width:200px;
                    align-self:center;
                    margin:.5em 0;
                }
            }
        }
    }

    hr{
        height:1px;
        margin:40px 0;
        background-color:$gray-light;
        border:none;
    }
}