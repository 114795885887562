@mixin helveticaneue( $weight: null ) {
    $font: 'HelveticaNeue';
    $font2: 'Helvetica Neue LT';
    $font3: 'Helvetica Neue';
    $fallback_fonts: '"Helvetica Neue", Helvetica, Arial, sans-serif';
    
    @if($weight == bold) {
        font-family: $font + '-Medium', "#{$font2 + ' Medium'}", "#{$font3 + ' Medium'}", #{$fallback_fonts};
        font-weight: 700;
    }
    @else if($weight == medium) {
      font-family: $font + '-Medium', "#{$font2 + ' Medium'}", "#{$font3 + ' Medium'}", #{$fallback_fonts};
      font-weight: 500;
    }
    @else if($weight == light) {
      font-family: $font + '-Light', "#{$font2 + ' Light'}", "#{$font3 + ' Light'}", #{$fallback_fonts};
      font-weight: 300;
    }
    @else {
      font-family: $font, $font2, $font3, #{$fallback_fonts};
      font-weight: normal;
    }
}

@mixin helvetica( $weight: null ) {
    $fallback_fonts: 'Helvetica, Arial, sans-serif';
    
    @if($weight == bold) {
        font-family: #{$fallback_fonts};
        font-weight: 700;
    }
    @else if($weight == medium) {
      font-family: #{$fallback_fonts};
      font-weight: 500;
    }
    @else if($weight == light) {
      font-family: #{$fallback_fonts};
      font-weight: 300;
    }
    @else {
      font-family: #{$fallback_fonts};
      font-weight: normal;
    }
}