.page {
    .wrapper.visitwebview{
		overflow: auto;
	}
}

.visitContainer {
    height: 100vh;

    .todayDate {
        display: none;
        font-size: 12px;
        height: 20px;
        padding-right: 46px;
    }

    ul {
        margin: 0;
        padding: 0;
        clear: right;
        height: 100%;

        li {
            margin: 0;
            padding: 0;
            list-style: none;
            height: 20%;
            min-height: 120px;
            border-top: 1px solid $white;
            box-sizing: border-box;
            &:first-child { border-top: 0; }
            > a { display: none; }
        }
    }

    img.scale { 
        /* corrects small inline gap at bottom of containing div */ 
        display: block; 
        width: 100%; 
        /* corrects obscure Firefox bug */ 
        max-width: 100%; 
        /* just in case, to force correct aspect ratio */ 
        height: auto !important; 
        -ms-interpolation-mode: bicubic; /* optionally force a minimum size if img src size is known: */ 
    }

    .timelineNews{
        display:block;
        overflow: hidden;
        position: relative;
        height: 100%;

        > a {
            &:after {
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 36%, rgba(0, 0, 0, 0.64) 99%, rgba(0, 0, 0, 0.65) 100%);
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        img{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto 0;
            display: block;
            width: 100%;
        }

        .text {
            position: absolute;
            bottom: 10px;
            left: 10px;
            right: auto;
            top: auto;
            font-family: 'Gotham 5r';
        }
        .title {
            display: block;
            font-size:20px;

            a { color: $white; }
        }
    }
}