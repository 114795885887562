.contact-header {
    a {
        // font-weight: bold;
    }
}
.contact-form{
    .column-layout {
        padding: 0px;
    }
	.one-third {
		width: calc(100% - 7px * 2);
		@include breakpoint(desktop, min) {
			width: calc(33.33% - 7px * 2);
		}
    }
    .four-col {
        // margin: 0;
    }
    .two-col {
        width: calc(100% - 7px * 2);
        // margin: 0;
        @include breakpoint(desktop, min) {
            width: calc(50% - 7px * 2);
            // margin: 7px;
        }
    }
    //max-width:660px;
    margin: 0 auto;
    color:$wim-black;
    font-size: 0.8em;
    @include gotham(normal);

    .field {
        display: block;
        // margin: 5px 5px 5px 0;

        input {
            width: 100%;
        }
    }

    form{
        //display:flex;
        flex-direction:column;
        width:100%;

        .DOB:hover .tooltip{
            visibility: visible;
        }

        .tooltip{
            visibility: hidden;
            @include gotham(normal);
            color: $wim-black;
            position: absolute;
            background-color: white;
            z-index: 9000;
            border: 1px solid #979797;
            padding: 10px;
            transform: translateY(-100%);
        }

        textarea{
            line-height: 1.3em;
        }

        // select{
        //     border-radius: 0px;
        // }

        input[type='checkbox']{
            display: none;
        }

        // input[type='checkbox'] ~ label{
        //     //-webkit-appearance: none;
        //     //-moz-appearance: none;
        //     //appearance: none;
        //     border: 2px solid $wim-text-purple;
        //     width: 26px !important;
        //     height: 19px;
        //     padding: 9px;
        //     border-radius: 0px;
        //     cursor: pointer;
        //     margin: 11px 8px 0 0;
        //     flex: 1 1 0;
        // }

        // input[type='checkbox']:checked ~ label{
        //     background: url('/assets/images/contact-form/Winner_Checkmark.png');
        //     background-position: center;
        //     background-repeat: no-repeat;
        // }

		.salutation-bar,
		.name-bar,
		.email-bar,
		.bd-adr-bar,
		// .form-opt-in,
		.contact-form-submit,
		.data-privacy-notice,
		.topic-subj-bar,
        .date-of-visit-bar,
		.subject-bar,
		.message-bar{
            padding: 0px;
            flex:1 1 85px;
            .four-col {
                width: calc(100% - 7px * 2);
            }
            label{
                // flex:1 1 0;
                // margin:10px 5px 0 0;
                //font-size: 1em;
            }

            @include breakpoint(tablet,max){
				padding: 0 0 10px 0;
                flex-direction:column;
                flex: 1 1 auto;

                label{
					flex:1 1 85px;
					margin-top: 10px;
                }
            }
            .ticketing-msg{
                font-size: 16px;
                padding: 6px 0;
                font-weight: bold;
            }
		}
		
		.message-bar {
			@include breakpoint(tablet){
				padding-bottom: 10px;
			}
		}

        @include breakpoint(tablet){
            // .form-opt-in{
            //     flex: 1 1 0 !important;
            // }
        }

        .contact-form-submit,.data-privacy-notice{
            align-items:center; 
        }

        // .form-opt-in,
        .contact-form-submit,.data-privacy-notice{
            flex:1 1 60px;
            align-self:center;
            text-align:center;

            input{
                width:25px;
            }

            @include breakpoint(tablet,max){
                flex-direction:row;
            }

            a{
                color:$wim-green;
                // text-decoration:none;
                display:inline-block;
            }
        }

        .form-opt-in{
            text-align:left;
            // flex: 1 1 80px;
			margin: 20px 5px 10px 5px;
            padding: 0px;
            position: relative;
            
            .checkbox-option {
                width: 25px !important;
                margin-right: 7px;
            }

            .opt-in-text{
                top: -6px;
                position: absolute;
                left: 32px;
                @include breakpoint(tablet){
                    top: 6px;
                }
            }
        }

        .message-bar{
            flex-basis:auto;
        }

        .data-privacy-notice{
            display:inline-block;
        }

        .contact-disclaimer{
            text-align: left;
            padding-right: 25px;
        }

        /*.topic-subj-bar{
            select{
                text-align-last: center;
                color: $wim-green;
                border-radius: 0px;
                border: 2px solid $wim-green;
            }
        }*/

        .contact-form-submit{
            .button{
                width: 200px;
                margin-top: 10px;
            }
        }

        @include breakpoint(tablet, max){
            .contact-form-submit{
                display: block;
                width: 100%;
                padding: 0 5px;
            }
        }
    }
}