/**
 * column layout 
 */

.page {
    .wrapper .content-main {
        &.player-stats {
            .scores-select-menu {
                margin: auto;
				width: 300px;

                .select-menu {
                    button {
                        text-align: center;
                    }
                }
            }
            .table-title {
                margin-bottom: 15px;
                margin-top: 15px;
                .left-nav-dot,
                .right-nav-dot {
                    color: $white;
                    opacity: .5;
                    &.selected {
                        opacity: 1;
                    }
                }
                i {
                    color: $gray-9b;
                }
            }
        }
    }
}

@media only screen and (max-width: $column-break-1) {
    .page {
        .wrapper .content-main {
            &.player-stats {
                .datatable {
                    .cell {
                        display:none;
                        &.selected {
                            display: table-cell !important;
                        }
                    }
                }
            }
        }
    }

}