.privacy-content{
    @include gotham(normal);
    color:$select-menu-text;
    font-size:16px;

    div{
        line-height:2em;
        padding:8px 0;
    }

    #page-heading{
        font-weight:bold;
        font-size:18px;
        text-align:left;
    }

    #privacy-warning{
        text-transform:uppercase;
        font-weight:bold;
        text-align:center;
    }

    #update-date{
        font-style:italic;
        text-align:left;
    }

    .content-header{
        font-weight:bold;
    }

    .content-section{
        text-align:left;

        .keyword{
            font-weight:bold;
            display:inline;
        }

        a{
            color:$wim-green;
        }

        ul{
            margin:0;

            li{
                line-height:1.5em;
                list-style:disc;
            }
        }
    }
}