
.ticket-resale-webview, .virtual-queue-webview {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
}

.ticket-resale-checkin-container {
    background-color: white;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 16px; // make sure there's space for the enter button

    .ticket-resale-checkin-form {
        flex: 1; // let form grow and take available space
        background-color: white;
        form {
            height: auto !important;
            background-color: white;
        }
        label {
            margin-bottom: 16px;
        }
    }

    .ticket-resale-checkin-cms {
        font-family: 'Gotham 5r';
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.25px;
        text-align: center;
        border-bottom: 1px solid rgba(151, 151, 151, 1);
        padding: 8px;
        margin: 0px 8px;
    }
}

.no-resale-selection {
    color: rgba(151, 151, 151, 1);
}

.resale-selection {
    color: black;
}

.ticket-resale-checkin-txt {
    font-family: 'Gotham 4r';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    margin: 8px 24px;
}

.ticket-resale-checkin-dropdown {
    width: 100%;
    .menu-text {
        width: 90%;
        display: inline-block;
    }
    .select-menu {
        border: 1px solid $md-gray;
        border-radius: 4px;
        .animation-container {
            width: 100%;
        }
    }
}

.ticket-resale-terms-and-conditions {
    font-family: 'Gotham 4r';
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    margin: 16px 0px 24px 0px;
    a {
        font-family: 'Gotham 5r';
        font-weight: 500;
    }
}

.ticket-resale-enter-queue-btn {
    width: 100%;
    text-align: center;
    .generic-button_wrapper {
        margin: 0px;
        .generic-button.solid {
            width: 100%;
            height: 40px;
            line-height: 25px;
            .content {
                width: 100%;
                &:hover {
                    background-color: transparent;
                }
            }
        }
    }
    .disabled_generic-button_wrapper {
        .generic-button.solid {
            border: 1px solid rgba(187, 187, 187, 1);
            background: rgba(220, 220, 220, 1);
            width: 100%;
            .content {
                color: rgba(174, 174, 174, 1);
            }
        }
    }
    .generic-button {
        border-radius: 8px;
    }
}

.iti__flag-container {
    //display: none;
}

.ticket-resale-checkin-phone-number {
    margin: 16px 0px;
    // .ticket-resale-checkin-number-input {

    // }
    
    .iti__country-container {
        .iti__selected-country {
            .iti__flag {
                //display: none;
            }
        }
    }
    .iti--fullscreen-popup.iti--container.iti--country-search {
        display: none;
    }
   
    .iti {
        width: 100%;
        margin-top: 6px;
        .iti__tel-input {
            //padding-left: 32px !important;
        }
    }
}

.iti--fullscreen-popup.iti--container.iti--country-search {
    .iti__flag-box {
        //display: none;
    }
}

.ticket-resale-labels {
    margin: 16px;
}

.intl-tel-notice {
    font-size: 12px;
    color: red;
    position: absolute;
}

.webview-ticket-queue {
    text-align: center;
    color: white;
    padding-left: 16px;
    padding-right: 16px;

    @media (width: 320px) {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.webview-ticket-queue-closed-cntr {
    margin-top: 25%;
    margin-bottom: 48px;
    .webview-ticket-queue-court {
        margin-top: 24px;
        @media (width: 320px) {
            margin-top: 12px;
        }
    }
}

.webview-ticket-queue-court {
    font-family: 'Gotham 5r';
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.25px;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 16px;
    text-transform: uppercase;
    @media (width: 320px) {
        margin-top: 16px;
    }
}

.webview-ticket-queue-cms-text {
    font-family: 'Gotham 5r';
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    text-align: center;
    max-height: 180px;
    overflow-y: scroll;
    margin: 8px
}

.webview-ticket-queue-position-header {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: center;
}

.webview-ticket-queue-position-header-closed {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: center;
}

.webview-ticket-queue-position-number {
    font-size: 115px;
    font-weight: 400;
    line-height: 110px;
    letter-spacing: 1.100000023841858px;
    text-align: center;
    margin: 12px auto;
}

.webview-virtual-queue-position-number {
    font-size: 100px;
    font-weight: 400;
    line-height: 110px;
    letter-spacing: 1.100000023841858px;
    text-align: center;
    margin: 12px auto;
    @include breakpoint(largeMobile, min){
        font-size: 110px;
    }
    @include breakpoint(extraLargeMobile, min){
        font-size: 115px;
    }
}

// force show scrollbar
.webview-ticket-queue-cms-stub::-webkit-scrollbar {
    -webkit-appearance: none;
}

.webview-ticket-queue-cms-stub::-webkit-scrollbar:vertical {
    width: 6px;
}

.webview-ticket-queue-cms-stub::-webkit-scrollbar:horizontal {
    height: 12px;
}

.webview-ticket-queue-cms-stub::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .5);
}

.webview-ticket-queue-cms-stub {
    height: calc(100dvh - 442px); // subtract all other content from the height of device -- NOTE: update if CMS text at the top is not as long as Jeffs test data
    overflow-y: scroll;
    font-size: 12px;

    div:last-child {
        border-bottom: 0px;
    }
    .webview-ticket-queue-cms-stub-updates {
        text-align: left;
        width: 100%;
        display: inline-block;
        height: fit-content;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        
        margin-bottom: 8px;
        padding-bottom: 8px;
        .webview-ticket-queue-cms-stub-updates-img {
            img {
                width: 120px;
                float: left;
                margin-right: 8px;
            }
        }
        .webview-ticket-queue-cms-stub-updates-txt {
            display: grid;
        }
        .webview-ticket-queue-cms-stub-updates-time {
            font-size: 9px;
        }
        .webview-ticket-queue-cms-stub-updates-title {
            font-weight: bold;
            font-size: 14px;
        }
    }
}

.webview-ticket-queue-last-updated {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: 12px;
}

.webview-ticket-queue-bottom-txt {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
}

.webview-ticket-queue-exit-queue-cntr, .webview-ticket-queue-exit-queue-cntr-virtual {
    bottom: 4px;
    position: absolute;
    right: 0;
    left: 0;
    .webview-ticket-queue-exit-queue-txt {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: center;
        margin-bottom: 8px;
    }
    margin: auto 16px;
    .generic-button {
        width: 100%;
        background-color: white;   
        height: 40px;  
        line-height: 25px;
        border-radius: 8px;
        .border {
            border: none;
        }
    }
}

.webview-ticket-queue-exit-queue-cntr-relative {
    position: relative;
    margin-top: 8px;
    padding-bottom: 16px;
    width: 100%;
    .webview-ticket-queue-exit-queue-txt {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: center;
        margin-bottom: 8px;
    }
    .generic-button {
        width: 100%;
        background-color: white;   
        height: 40px;  
        line-height: 25px;
        border-radius: 8px;
        .border {
            border: none;
        }
    }
} 

.webview-ticket-view-map-cntr-virtual {
    bottom: 48px;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto 16px;
    i {
        margin-right: 8px;
    }
    .generic-button_wrapper>.generic-button.solid .content:hover, a>.generic-button.solid .content:hover {
        background-color: unset;
    }
    .generic-button {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 8px;
        .content {
            color: white;
        }
        .border {
            border: 1px solid $md-gray;
            border-radius: 8px;
        }
    }
}

.webview-ticket-queue-queue-guide-link {
    bottom: 56px;
    position: absolute;
    right: 0;
    left: 16px;
    text-align: left;
    font-family: 'Gotham 5r';
    a {
        color: white;
        font-size: 14px;
        font-weight: 500;
        line-height: 12px;
    }
}

.webview-ticket-queue-multiple-btn-cntr {
    :first-child {
        margin-right: 4px;
    }
}

.webview-ticket-queue-multiple-btn-cntr, .webview-ticket-queue-refresh-btn-grey {
    .generic-button_wrapper>.generic-button .border:hover, a>.generic-button .border:hover {
        border: none;
    }
    .generic-button {
        width: 100%;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 8px;
        height: 40px;
        line-height: 25px;
        .content {
            color: white;
        }
        .border {
            border: none;
            border-radius: 8px;
        }
    }

    .webview-ticket-queue-multiple-btn {
        width: 48%;
        display: inline-block;
        .generic-button_wrapper>.generic-button .border:hover {
            border: none;
        }
    }
}

.ticket-resale-header-txt {
    margin-top: 16px;
    font-family: 'Gotham 5r';
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;

}

.ticket-queue-confirmation-popup {
    height: 50%;
    // background-color: white;
    .close-btn-container {
        // float: right;
        position: absolute;
        right: 0px;
        top: 0px;
    }

    .ticket-action-modal-information-text {
        margin-top: 24px;
    }
}

.webview-ticket-queue-qr-code {
    width: 100%;
    max-height: 430px;
    top: 382px;
    border-radius: 10px;
    background-color: white;
    color: black;
    margin: 8px 0px 16px 0px;
    text-align: center;
    align-items: center;
    display: grid;

    .webview-resale-qr-code {
        position: relative;
        margin: 16px;
        margin-bottom: 32px;
        img:not(.webview-resale-qr-code-animation-img) {
            width: 100%;
            max-width: 178px;
            display: unset !important;
            margin: auto;
            @include breakpoint(tablet, min){
                max-width: 300px;
            }
        }
        .webview-resale-qr-code-animation-img {
            width: 34px;
            @media (width: 320px) {
                width: 30px;
            }
            @include breakpoint(tablet, min){
                width: 48px;
            }
        }

        .webview-resale-qr-code-timestamp {
            font-family: 'Gotham 4r';
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            color: $wim-green;
            margin-bottom: 8px;
        }


        .webview-resale-qr-code-animation {
            position: absolute;
            right: 6px;
            bottom: 0px;
            @media (width: 320px) {
                right: 2px;
            }
        }
    }

    .webview-resale-qr-code-bookingID {
        position: absolute;
        bottom: -24px;
        width: 100%;
        color: $wim-green;
    }

}


.ticket-resale-header-txt-expired {
    margin-top: 25%;
}

.queue-stub-general-message {
    text-align: center;
    font-size: 18px;
    color: white;
    font-weight: bold;
    margin: auto;
    // display: flex; // note: keeping commented out in case need to re-add in future
    align-items: center;
}

.queue-general-message {
    width: 100%;
    margin-top: 88px;
}

.queue-general-message-title {
    font-family: 'Gotham 5r';
    font-size: 18px;
    font-weight: 500;
    line-height: 17.23px;
    letter-spacing: 0.25px;
    text-align: center;
    margin-bottom: 12px;
}

.queue-general-message-msg {
    font-family: 'Gotham 4r';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: center;
}

.webview-queue-view-guide-cntr {
    .generic-button_wrapper.cms {
        .generic-button {
            width: 100%;
            background-color: rgba(255, 255, 255, 0.3);            ;
            height: 40px;

        }
    }
}

.webview-virtual-queue-barclays {
    .webview-virtual-queue-barclays-text {
        font-family: 'Gotham 4r';
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        @media (width: 320px) {
            font-size: 10px;
            line-height: 18px;
        }
    }
    img {
        width: 100%;
        margin-top: 10px;
        @include breakpoint(tablet, min){
            width: 50%;
        }
        @media (width: 320px) {
            width: 90%;
        }
    }
}

/* Modal.css */
.resale-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .resale-modal-content {
    background: #fff;
    padding: 20px;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .qr-code-modal-content {
    background: #fff;
    padding: 20px;
    width: 100vw;
    max-height: 90%;
    overflow-y: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .resale-modal-close-btn {
    border: none;
    background: none;
    float: right;
    z-index: 3;
    cursor: pointer;
  }

  .queue-error-msg {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 28px;
    color: white;
    font-weight: bold;
    div {
        margin: auto 8px;
    }
  }