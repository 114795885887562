.modal-content-overlay {
    z-index: 200;
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    margin: auto;
    height: 100%;
    overflow: auto;
    background: rgba(0,0,0,.65);
    padding: 10% 0;

    .modal-content {
        position: absolute;
        width: 80%;
        background: white;
        //overflow: auto;
        z-index: 9999;
        //height: inherit;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 1200px;
        display: flex;
        flex-direction: column;

        img {
			flex: 0 0 auto;
            height: auto;
            padding: 0px;
            position: relative;
            float: left;
            left: 0px;
            top: 0px;
            width: 50%;
            border: 3px solid white;
        }

        .modal-content-text {
			position: absolute;
            margin-left: 50%;
			width: 50%;



            top: 0;
            //text-align: center;
            bottom: 0;
            overflow-y: auto;
            //width: 100%;
            padding: 20px 35px 10px 35px;

            h2 {
                margin: 0;
                // font-weight: normal;
                // font-family: "Gotham 4r", Helvetica, Arial, sans-serif;
                // font-size: 22px;
                color: $wim-text-purple;
                text-transform: uppercase;
                text-align: center;
            }

            hr {
                display: block;
				width: 20px;
				border: none;
				height: 2px;
				background-color: $wim-text-purple;
                margin:8px auto 15px;
            }

            .title{
                color:$wim-green;
                font-size:16px;
                @include gotham(medium);
            }

            .description {
                // max-height: 270px;
                height: auto;
                font-size:16px;
                line-height:24px;
                overflow-y: auto;
                @include gotham-book();

                p {
                    margin-top: 1em;
                    margin-bottom: 1em;
                }
            }
        }

        .wim-icon-close{
            font-size:22px;
            position:absolute;
            top:9px;
            right:7px;
            color: $wim-green;
            cursor: pointer;
        }

        @include breakpoint(tablet,max, true){
			height: 80%;

            img {
                width: 100%;
            }

			.scrollable {
				// position: fixed;
				top: 160px;
				bottom: 0;
                width: 100%;
                overflow-y: auto;
            }

            .modal-content-text {
				position: relative;
                width: 100%;
				height: 100%;
                margin-left: 0px;
				overflow-y: auto;
				top: 10px;
            }

            .wim-icon-close{
                color: $gray-light;
            }

			&.wechat {
                height: auto;

				img {
	                top: 20px;
	            }

				.scrollable {
					position: relative;
                    bottom: 0;
                    top: 0;
	                width: 100%;
                    overflow: hidden;
	            }

				.wim-icon-close{
	                color: $wim-green;
                }
                
                .modal-content-text {
                    padding-top: 0;
                    top: 0px;

                    h2 {
                        font-size: 18px;
                    }

                    .description {
                        font-size: 14px;
                        line-height: 20px;
                    }

                }
			}
        }
    }


}

.modal-image {
	cursor: pointer;
	.title {
		color: $wim-black;
        @include gotham(medium);
        font-size: 18px;
        margin-bottom: 5px;
        line-height: 1.3em;
        letter-spacing: 1.5px;
	}
}
