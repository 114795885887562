$md-gray: #888B8D;
$dk-gray: #4A4A4A;
$gray-4a: #4a4a4a;

$stage-item-color: #FFFFFF;

$page_header_height_mobile: 56px;
$slamtracker_tabs_height: 37px;
$momentum_toolbar_height: 56px;
$pinned_scoreboard_height: 131px;

/* breakpoints */
@mixin embed_breakpoint($break, $type : min) {
  @if $type == min {
    @if $break == xsmall {        // 320px
      @media only screen and (min-width: 320px) { @content; }
    } @else if $break == small {  // 640px
      @media only screen and (min-width: 640px) { @content; }
    } @else if $break == medium { // 768px
      @media only screen and (min-width: 768px) { @content; }
    } @else if $break == large {  // 1024px
      @media only screen and (min-width: 1024px) { @content; }
    } @else if $break == mxlarge {  // 1240px
      @media only screen and (min-width: 1240px) { @content; } //robin made this up for draws text overlap (medium-extra-large)
    } @else if $break == xlarge { // 1601px
      @media only screen and (min-width: 1601px) { @content; }
    } @else if $break == xxlarge { // 1601px + sidepanel_width
      @media only screen and (min-width: #{1601px + $sidepanel_width}) { @content; }
    } @else if $break == sidepanel { // 1366px
      @media only screen and (min-width: #{1024px + $sidepanel_width}) { @content; } // specifically for minimum width at which optional sidepanel is visible
    } @else {
      @media only screen and (min-width: $break) { @content; }
    }
  } @else if $type == max {
    @if $break == xsmall {        // 319px
      @media only screen and (max-width: 319px) { @content; }
    } @else if $break == mxsmall {  // 399px
      @media only screen and (max-width: 399px) { @content; } //robin made this up for stats text overlap (medium-extra-small)
    } @else if $break == small {  // 639px
      @media only screen and (max-width: 639px) { @content; }
    } @else if $break == medium { // 767px
      @media only screen and (max-width: 767px) { @content; }
    } @else if $break == large {  // 1023px
      @media only screen and (max-width: 1023px) { @content; }
    } @else if $break == xlarge { // 1600px
      @media only screen and (max-width: 1600px) { @content; }
    } @else {
      @media only screen and (max-width: $break) { @content; }
    }
  }
}

/* wimbledon breakpoints */
@mixin stage_item_breakpoint($break) {
  @if $break == small {  
    @media only screen and (max-width: 767px) { @content; }
  } 

  @else if $break == medium { 
    @media only screen and (min-width: 768px) { @content; } //and (max-width: 1299px) 
  } 

  @else if $break == large { 
    //@media only screen and (min-width: 1300px) { @content; }
  }
}

/* momentum vars */
$momentum_toolbar_border_color: $wim-green;
$momentum_setlabel_bg_color: #F5F5F5;
$momentum_setlabel_color: #000000;
$momentum_setlabel_border_color: #979797;
$momentum_game_separator_color: #F3F3F3;
$momentum_game_color: #888B8D;
$momentum_notification_border_color: #ADACAC;
$momentum_notification_duration_color: #636161;
$momentum_notification_score_color: #000000;
$momentum_notification_sentence_color: #000000; 