.sitemap{
    @include gotham(normal);
    font-size:16px;
    column-count:2;
    column-width:300px;
	padding-top: 20px;
	
    ul{
        margin:0;
        li{
            list-style-type:disc;
            break-inside: avoid-column;
			margin: 0 !important;
            a{
                color:$wim-green;
            }
        }
    }
}