.radio-station{
    position:relative;
    @include gotham(medium);
    color:$white;
    width:33%;
    text-align:center;
    overflow:hidden;
    margin:10px;


    .live-station,.not-live-station{
        padding: 0 10px;
        //padding-bottom: 436px;
		width: 100%;
		align-self: flex-start;
    }

    img{
        height:436px;
        width:100%;
        //position:absolute;
        left:0;
        top:0;
    }

    .station-text{
        display:flex;
        flex-direction:column;
        position:absolute;
        padding:0 15%;
        width:100%;
        left:0;
        top:100px;

        .station-title{
            text-transform:uppercase;
            font-size:24px;
            color:$white;
        }

        .station-description{
            font-size:16px;
            text-transform:uppercase;
            margin-top:20px;
            color:$white;
        }

        hr{
            width: 60%;
            margin:20px 20% 0;
            height:1px;
            border:none;
            background-color:$white;
        }

        .wim-icon-video{
            font-size:55px;
            margin-top:25px;
            color:$white;
        }

        @include breakpoint (desktop,max){
            top:80px;

            .station-title{
                font-size:20px;
            }

            .station-description{
                margin-top:10px;
                font-size:12px;
            }
        }
    }

    @include breakpoint(tablet,max){
        width:100%;
        margin:5px 0;

        .live-station,.not-live-station{
            //padding-bottom:246px;
        }

        img{
            height:246px;
        }

        .station-text{
            top:60px;

            .station-title{
                font-size:20px;
            }

            .station-description{
                font-size:14px;
            }

            hr{
                margin-top:12px;
            }

            .wim-icon-video{
                margin-top:15px;
                font-size:40px;
            }
        }
    }

    &.on-air{
        .not-live-station{
            display:none;
        }
    }

    &.off-air{
        .live-station{
            display:none;
        }
    }
}