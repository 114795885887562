/** player-image styles **/
img.player-image {
    &.out {
        filter: grayscale(100%);
    }
	border-radius: 50%;
	
    &.regular {
		width: 100%;
		height: 100%;
    }
    &.small {
        width: 155px;
        height: 155px;
    }
    &.xsmall {
        width:75px;
        height:75px;
    }
    &.xxsmall {
        width:35px;
        height:35px;
	}
	
	&.archive {
		border-radius: unset;
		height: 100%;
		width: auto;
		margin: auto;
	}
}

