.draws{
    display: flex;

    .column-one,.column-two{
        display: flex;
        flex: none;
        flex-direction: column;
        margin-right: 2%;
        max-width:50%;

        .column-title{
            height: 15px;
            font-size: 14px;
            flex: 1 1 auto;
            text-transform:uppercase;
            color:$select-menu-text;
            text-align:center;
        }

        // .match-box:last-child{
        //     margin-bottom:0;
        // }

        .match-box{
            flex: 1 1 auto;
            margin-bottom:10px;

            .match-location{
                display :none;
            }

            .match-status{
                height: 97px;
                padding-top: 0;

                .team-info{
                    .team-name{
                        max-width:100%;
                        width: 90%;

                        @include breakpoint(tablet) {
                            width: calc(100% - (168px + 5px));
                        }
                    }
                }
                .green-border{
                    height: 97px;
                }

                .match-results{
                    // display:none;

                    @include breakpoint(tablet) {
                        display: block;
                    }

                    .results-wrapper{
                        .set,.pts{
                            display: none;

                            @include breakpoint(tablet) {
                                display: block;
                            }
                        }
                    }

                    .crticon{
                        .wim-icon-ballSolid{
                            display: none;
                        }
                    }
                }

                .results-info{
                    display:none;
                    top: 45px;

                    @include breakpoint(tablet) {
                        display: block;
                    }
                }

                .event-notes,.event-info{
                    display: none;
                }
            }

            .match-box-buttons{
                .generic-button_wrapper {
                    width: 100%;
                    &:first-child {
                        display: none;
                    }
                    &:last-child {
                        margin-left: 0px;
                    }
                }
                .watch-and-read{
                    display: none;
                }
                .slamtracker-stats{
                    margin-left: 0;
                }
            }
        }
    }

    .column-two {
        .column-title {
            flex: none;
        }
        .match-box-container {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
        }
    }
}
