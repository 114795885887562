/** featured-players styles **/
#featuredPlayers {
    clear: both;
    display: none;
}
@include breakpoint(desktop) {
    #featuredPlayers {
        display: table;
        table-layout: fixed;
        width: 100%;
        padding: 15px 25px 0 25px;
        margin-bottom: 15px;

        a { text-decoration: none; color: $black; }
        .fplayer {
            display: table-cell;
            padding: 0px 10px;
        }
        .fphoto {
            min-width: 100%;
            max-width: 100%;
            border:none;
        }
        .ftitle {
            .flag {
                border: none;
                width: 26px;
                margin-right: 5px;
            }
            .fname {
                display: inline-block;
                font-size: 18px;
                @include gotham($weight: medium);
            }
        }
    }
}