/** wimbeldon-icons styles **/

@font-face {
    font-family    : 'Icons';
    font-weight    : normal;
    font-style     : normal;
    src:  url('#{$font-dir}wim-icons.ttf?ox6j0u') format('truetype'),
          url('#{$font-dir}wim-icons.woff?ox6j0u') format('woff'),
          url('#{$font-dir}wim-icons.svg?ox6j0u#wim-icons') format('svg');
}

.icon,
i[class^=wim-icon-] {
    @include icon('Icons');
}

@each $p, $c in $icons {
    $i: index($icons, ($p $c));
    .#{$p}:before {
        content: $c;
    }
}
