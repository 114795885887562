/** 
 * These styles are used for the webview, not the panel 
 **/

.page {
    .wrapper {
        &.slamtracker {
            .error-indicator {
                display: none;       // errors will be printed in the console
            }
            background: none; 

            .content-main {
                background-color: transparent;
                padding-bottom: 0px;
                
                .content--filters-scroll {
                    margin-top: 0px;
                }
                .content-filters-spacer {
                    width: 100%;
                    height: 85px;
                    display: none;
                }
                &.fixed {
                    .content-filters-spacer {
                        display: block;
                    }
                }
                .connection-type {
                    position: absolute;
                    bottom:-3px;
                    left:0px;
                    text-align: left;
                    color:$gray_9b;
                }
            }

            @include breakpoint(tablet) {
                .content-main {
                    height: 100vh;
                    position: relative;
                    .connection-type {
                        color:$tennis-court-green;
                    }
                }
            }
        }
        &.hideHeader {
            .header-global {display:none;}
            .mainNav {display:none;}
            .page-header {display:none;}
        }
    }
}
/**
 * These styles are used for the webview and the panel 
 **/
.slamtracker {
    .slamtracker-content {
        width: 100%;
        height: calc(100vh - #{$slamtrackerPanelHeader});
        margin: 0px;
        position: relative;
        display: flex;
        flex-direction: column;
        
	   .content-filters-scroll {
			margin-top: 1px;
		}
        /** override page-nav-container styles for tabs */
        .page-nav-container {
            .nav-container {
                .page-nav-list {
                    li {
                         @include gotham-book();
                        font-weight: normal;

                        &.active {
                             @include gotham(medium);
                        }
                    }
                    li.divider{
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        
                        &:after {
                            height: 11px;
                            width: 2px;
                            background: $lines-gray;
                            top: 4px;
                        }
                    }
                    &.slamtracker-headtohead-tabs {
                        li.divider{
                            margin-left: 16px;
                            margin-right: 16px;
                        }
                    }
                    &.slamtracker-matchstats-tabs {
                        li.divider{
                            margin-left: 10px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
    .featured-match {
        width: 100%;
        height: 150px;
        // margin: 0px 0px 20px;
        padding: 0px 2.5%;
        position: relative;
    }
    .content-tabs {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 37px;
        background-color: rgba(255, 255, 255, .2);

        a {
            text-transform: uppercase;
            @include gotham;
            font-size: 14px;
            display: flex;
            align-items: center;
            height: 37px;
            letter-spacing: 0.05em;
            margin: 0 21px;
            color: $white;
            cursor: pointer;

            &.selected{
                @include gotham(medium);
                border-bottom: 3px solid $white;
                cursor: default;
            }
            &.disabled{
                cursor: default;
                opacity: .4;
            }
            &:hover{
                text-decoration: none;
            }
        }
    }
    .content-area {
        width: 100%;
        min-height: calc(100vh - 279px);
        background-color: $white;
        z-index: 0;
    }
    .view-more-matches-container {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #F5F5F5;

        .view-more-matches button {
            min-width: 100px;
            width: auto;
            height: 35px;
            line-height: 20px;
            margin: auto;
            @include gotham(medium);
            font-size: 14px;
            text-transform: uppercase;
            cursor: pointer;
        }
    }
    .panel {
        width: 100%;
        // height: 100%;
        background-color: $white;
        display: none;
        min-height: 780px; //6-11-24 this is a random number determined by Robin to allow for the fixed header to not cause scrolling issues

        &.overview-panel{
            .content{
                height: unset;
            }
        }
        &.selected {
            display: block;
        }
        .content {
            width: 100%;
            height: 100%;
            position: relative;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;

            &.stub { 
                padding-top: 50%;
                
                .stub-text {
                    display: table-cell;
                    vertical-align: middle;
                    color: $wim-green;
                    border-top: 1px solid $wim-green;
                    border-bottom: 1px solid $wim-green;
                    height: 65px;
                    padding-left: 20px;
                    padding-right: 20px;
                    text-transform: uppercase;
                    font-size: 16px;
                    text-align: center;
                    @include gotham(medium);            
                }
            }
        }
        .error-indicator {
            text-align: center;
            @include gotham(medium);
            font-size: px2rem(16);
            padding: 27px 15px 0;
        }
        .video-wrapper {
          margin: 0;
          width: 100%;
          left: 0px;
          top: 0px;
          position: absolute;
          z-index: unset;
          border: unset;

          i.wim-icon-close {
            position: fixed;
            color: #fff;
            font-size: 20px;
            right: 8px;
            transform: none;
            top: 8px;
            left: auto;
            font-weight: bold;
            z-index: 205;
            cursor: pointer;
            display: block;
          }
          // &.modal {
          //   i.wim-icon-close {
          //     right: calc(374px + 8px);
          //   }
          // }
          .video-player {
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100vw;
            height: 100%;
            z-index: 200;
            padding-bottom: unset;
          }
        }
    }
    .fixed {
        .featured-match {
            position: fixed;
            top: 73px;
            height: 113px;
            z-index: 3;
            left: 0px;
            right: 0px;
            padding-top: 15px;
            background-color:#354918;
            
            
            .match-box-container {
                .match-box {
                    .match-location {
                        display: none;
                    }
                    .match-status {
                        height: 85px;
                        padding-top: 0px;
                        
                        .green-border { height: 85px; }
                        .team-info.team-one { margin-top: 0px; }
                        .results-info { top: 40px; }
                        .event-info, .event-notes {
                            display: none;
                        }
                    }
                }
            }
        }
        .content-container {
            position: fixed;
            top: 160px;
            width: 100%;
            height: calc(100vh - 311px);
        }
    }
    &.webview {
        .slamtracker-content {
            height: 100% !important;
            width: 100% !important;
        }
        .match-box.sidepanel-box{
            .match-location{
                color: $white;
            }
        }
        .content-area {
            height: 100%;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            background-color: $white;

            .watch-panel{
                .sidepanel-video-content{
                    background-color: white;
                }
                .errorMessage{
                    margin-top: 20px;
                }
            }
        }
    }

    @include breakpoint(tablet, min, true) {
        .slamtracker-content {
            margin: 0px auto;
        }
        .featured-match {
            width: 100%;
        }
        .content-tabs {
            width: 100%;
        }
        .content-area {
            width: 100%;
            position: relative;
        }
        .panel ~ .loading-indicator.small {
            width: 100%;
            text-align: center;
            position: relative;
            display: block;
        }
        .panel.selected ~ .loading-indicator.small {
            display:none;
        }
        footer {
            display: none;
        }
    }
    .related-content-container {
        &.hide{
            display: none;
        }
      //margin: 0 10px;
      .title {
        text-align: center;
        @include gotham(medium);
        margin: 20px;
        text-transform: uppercase;
      }
      .related-content {
        .related-cnt {
          .related-content-tile {
            width:236px;
            // height:197px;
            .click-wrapper {
              flex-direction: column;
              .image-container {
                // width: 236px;
                min-height: 132.11px;
                flex: 0;
              }
            }
            .content-text {
              .type-tag{ display: none; }
              flex: 0;
              margin: 0;

              .content-timestamp {
                color: #00703C;
                @include gotham-book;
                margin-top: 5px;
              }
              .content-title {
                color: #54008B;
                // font-size: px2rem(12);
                line-height: 1em;
              }
              .content-description {display: none;}
            }
          }
        }
        
      }
    }
}

.webview {
    .wrapper {
        min-height: 100vh;
        &.slamtracker {
            .content-main {
                height: 100vh;
                // padding-top: 20px;

                .slamtracker-content{
                    .featured-match{
                        margin-bottom: 20px;
                    }
                    .slamtracker-header{
                        height: 314px;
                        color: #fff;
                        text-align: center;
                        width: 100%;
                        // max-width: 768px;
                        margin: 0 auto;

                        @include gotham(medium);
                        .slamtracker-header-title{
                            text-transform: uppercase;
                                    font-size: 18px;
                            letter-spacing: .5px;
                            padding-top: 49px;
                            padding-bottom: 20px;
                        }
                        .slamtracker-match-name{
                            height: 30px;
                            background-color: rgba(255, 255, 255, .2);
                            text-transform: uppercase;
                            font-size: px2rem(12);
                            // align-content: center;
                            margin-bottom: 4px;
                            letter-spacing: .5px;
                        }
                        &.live {
                          .slamtracker-scoreboard {
                            .team-img-container {
                              visibility: hidden;
                            }
                          }
                        }
                        .slamtracker-match-info {
                          margin: 34px auto;
                          max-width: 373px;
                        }
                    }
                    .slamtracker-scoreboard {
                      @include breakpoint(tablet, min, true) {
                        justify-content: center;
                        max-width: 768px;
                      }
                      margin: 0 auto;
                        .match-box-container {
                            .event-info {
                                text-align: left;
                            }
                        }
                    }
                    .panel-content {
                        display: flex;
                        flex-direction: column;
                        margin: 0 auto;

                        @include breakpoint(tablet, min, true) {
                            justify-content: center;
                            // max-width: 768px;
                        }
                        .slamtracker-stage {
                          margin: 10px 12px 0px;
                        }
                    }
                    .content-area {
                      .related-content-container {
                        .generic-carousel {
                          // left: 0 !important;
                        }
                      }
                      .catch-up-article {
                        .ai-catch-up-box {
                          max-width: unset;
                          margin: 0 auto;
                        }
                      }
                      .slamtracker-powerranking {
                        margin-bottom: 0;
                        .LikelihoodToWinBanner {
                          .likelihood-win-btn {
                            &:after {
                              left: 8.5%;
                            }
                          }
                        }
                        .mi-section {
                          .mi-section-header {
                            display: flex;
                            flex-direction: unset;
                            justify-content: unset; 
                            padding: 10px 0px;
                          }
                          .mi-last-updated {
                            margin-left: unset;
                            margin-top: unset;
                            margin-bottom: unset;
                            color: #999999;
                          }
                        }
                        .mobile-only, .above-tablet, .embedded-wrapper {
                         max-width: 371px;
                          margin: 0px auto;
                        }
                        
                        .embedded-wrapper {
                          .mi-pr-player-info-wrapper {
                           padding-top: 10px;
                            .player-name {
                              width: unset;
                            }
                            .mi-pr-player-info {
                              width: 70px;
                              &.player1, &.player2 {
                                .favorite {
                                  &:after {
                                    font-size: 20px !important;
                                    top: -8px !important;
                                  }
                                }
                                .mi-pr-player-country-name {
                                  font-size: px2rem(12) !important;
                                  white-space: normal !important;
                                  margin-top: 5px;                              
                                }
                              }
                              &.player2 {
                                // text-align: right;
                                .favorite {
                                  right: 20px !important;
                                }
                              }
                            }
                            .probability {
                              .player-name {
                                display: none;
                              }
                            }
                          }
                        }
                      }
                    }
                }
            }
        }
    }
}