.statistics-page {
	.player-stats {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		// background-color: orange;
		.stat-line {
			display: flex;
			justify-content: space-between;
			color: $wim-black;
			font-size: px2rem(12px);
			line-height: 18px;
			width: 325px;
			@include breakpoint(tablet) {
				width: 535px;
				font-size: px2rem(16px);
				line-height: 24px;
			}
			.stat-field {
				width: 60%;
				@include gotham-book();
			}
			.stat-value {
				width: 25%;
				@include gotham(medium);
			}
		}
		.tourn-stats-ast {
			@include gotham(medium);
			font-size: px2rem(11px);
			margin-top: 18px;
			margin-bottom: 17px;
		}
	}
}
.stats-tab-no-stats {
	@include gotham(medium);
	font-size: px2rem(16px);
	display: flex;
	justify-content: center;
	padding: 10px 0 20px;
}
