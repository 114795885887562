.system-error-wrapper {
	margin-bottom: 40px;

	h3 {
		i {
			font-size: px2rem(8); 
			margin-left: 10px;
		}
		cursor: pointer;
		display:flex;
		flex-direction: row;
		align-items: center;
	}

	section {
		margin-bottom: 20px;
	}

	.error-section {
		max-height: 0;
		transition: max-height 0.15s ease-out;
		overflow: hidden;
		display:flex;
		flex-direction: column;
		margin-top: 5px;

		&.open {
			max-height: 3000px;
			transition: max-height 0.25s ease-in;
		}

		.label {
			font-weight: bold;
			margin-bottom: 10px;
		}

		.value {
			font-weight: normal;
			margin-bottom: 20px;

		}
	}
	

	.section-title-wrapper, .page-grid {
		position: relative;
		display:flex;
		width:100%;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		padding:0px 10px;
		margin-bottom: 10px;
		margin:0px auto;
		
	}
}
