
.wrapper.iframe-component{
	.content-main {
		padding-top: 0px;

		.embed-responsive{
			height: 100%;
			position: absolute;
			width: 100%;

			&.fullview {
				position: unset;
				iframe {
					height: calc(100vh - 100px);
				}
			}
		}

		
	}
}

.content-main {
	.pdfreader {
		.embed-responsive{
			height: 100%;
			position: absolute;
			width: 100%;
		}
	}
}








