.sidepanel {
	color: #000000;
	z-index: 110;
	position: absolute;
	right: 0px;
	top: 0px;

	// sidepanel always open with centered max width content
	@include breakpoint(extraLargeDesktop) {
		right: calc(((100vw - #{$maxWidth}) / 2));
	}

	a.skip-nav {
		left: -999px;
		position: absolute;
		top: 0;
		width: 1px;
		height: 1px;
		overflow: hidden;
		z-index: -999;
	}

	a.skip-nav:focus,
	a.skip-nav:active {
		color: $white;
		background-color: $wim-green;
		left: 0;
		top: 0;
		width: 180px;
		height: auto;
		overflow: auto;
		margin: 5px;
		padding: 5px;
		text-align: center;
		font-size: px2rem(14);
		z-index: 999;
	}
	

	@include breakpoint(largeDesktop) {
		width: $sidebarWidth;
	}

	@include breakpoint(extraLargeDesktop) {
		width: $sidebarWidth;
	}

	@include breakpoint(desktopSidePanel, mid, true) {
		&-content-channel {
			display: none;

			.show & {
				display: block;
			}
		}

		&-content-scores {
			display: none;

			.show & {
				display: flex;
			}
		}

		&-video-panel {
			display: none;

			.show & {
				display: block;
			}
		}

		&.show {
			width: $sidebarWidth;
			min-width: $sidebarWidth;
		}
	}

	@include breakpoint(desktopSidePanel, max, true) {
		&-content-channel {
			display: none;
		}

		&-content-scores {
			display: none;
		}

		&-video-panel {
			display: none;
		}
	}

	&-content {
		width: $sidebarWidth;
		height: 100vh;
		padding: 0 5px;
		background-color: $white;
		color: $black;
		position: fixed;
		border-left: 1px solid $select-menu-border;
		display: flex;
		flex-direction: column;


		&-channel {

			.title {
				@include gotham($weight: medium);
				font-size: 18px;
				padding-top: 19px;
				margin: 0 68px;
				display: block;
				text-align: center;
				text-transform: uppercase;

				hr {
					display: block;
					width: 32px;
					border: none;
					height: 2px;
					background-color: $wim-green;
					margin: 10px auto;
				}
			}
		}

		&-pre {
			color: $wim-green;
			border: 1px solid $wim-green;
			width: 90%;
			left: 5%;
			top: 40px;
			position: relative;
			@include gotham(medium);
			padding-bottom: 10px;
			margin-bottom: 10px;

			.title {
				font-size: 18px;
				letter-spacing: 2px;
				padding-top: 10px;
				padding-bottom: 10px;
				margin-right: auto;
				margin-left: auto;
				width: 60%;
				text-align: center;
			}

			p {
				text-align: center;
				font-size: 16px;
				margin: 10px 0 0 0;
				width: 84%;
				margin-right: auto;
				margin-left: auto;
			}
		}

		&-video {
			margin: 0;
			position: absolute;
			top: 40px;
			left: 16px;
			width: 340px;
			height: 0px;
			background-color: #000;

			.video-player {
				top: 0px;
				width: 100%;
				height: 0px;
				position: absolute;
				z-index: 4;
				margin: 0;
				background-color: #000;
				background-size: cover;
				overflow: hidden;

				&.wide {
					padding-bottom: 56.25%;
				}

			}

			&-pre {
				margin: 0;
				top: 10px;
				left: 16px;
				width: 340px;
				height: 190px;
				background-color: #000;
				position: relative;
				margin-bottom: 15px;

				//to set hold position of vod before slate loads
				.video-wrapper {
					padding-bottom: 56.25%;
					.video-thumb {
						position: absolute;
					}
				}
			}


		}

		&-scores {
			position: relative;
			margin-top: 10px;
			padding: 0px 10px 50px;
			//display:flex;
			flex-flow: column;
			flex-grow: 1;
			min-height: 0px;

			.sidepanel-column {
				position: relative;
				//height:calc(100% - 48px);
				height: 100%;
				overflow-y: auto;

				.match-box {
					margin-bottom: 9px;
				}

				.message {
					display: none;

					&.show {
						display: block;
						padding: 30px 0px;
						text-align: center;
					}
				}

				.hide+.message {
					padding: 30px 0px;
					text-align: center;
					display: block;
				}

				.error-indicator {
					padding: 30px 0px;
					text-align: center;
					display: block;
				}

				@include breakpoint(largeDesktop) {
					padding-bottom: 30px;
				}
			}

			.scores-button-container {
				position: absolute;
				bottom: 0px;
				left: 0px;
				right: 0px;
				padding: 9px 0;
				width: 100%;
				background-color: $white;
				z-index: 111;
			}

			.scores-button {
				// position: absolute;
				// bottom:0px;
				// left:0px;
				// right:0px;
				// margin:9px auto;
				display: block;
				margin: 0 auto;
				height: 30px;
				width: 340px;
				border-radius: 3px;
				border: solid 2px $gray_9b;
				color: $wim-purple;
				z-index: 1;
				text-align: center;
				background-color: $white;
				line-height: 27px;
				font-size: 14px;
				cursor: pointer;
				text-transform: uppercase;
				@include gotham(medium);

				i {
					font-size: 9px;
					position: relative;
					bottom: 1px;
				}
			}

			.live-indicator {
				color: $live-green;
				margin-right: 3px;
				position: relative;

			}

			.show {
				display: block;
			}

			.hide {
				display: none;
			}
		}

		&.stub {
			//overflow: auto;
		}
	}

	&-button {
		display: none;
		float: left;
		position: absolute;
		left: -35px;
		top: 61px;
		width: 34px;
		height: 42px;
		border-radius: 5px 0 0 5px;
		z-index: 2;
		cursor: pointer;
		background: url(/assets/images/misc/panel_tab_arrow.png) 0 0 no-repeat #006633;

		@include breakpoint(desktopSidePanel, mid, true) {
			display: block;

			&.hide {
				display: none;
			}
		}
	}

	&-close {
		display: none;
		position: absolute;
		top: 10px;
		right: 18px;
		width: 18px;
		height: 18px;
		color: $black;
		font-size: 18px;
		z-index: 1;
		cursor: pointer;

		a:link,
		a:visited,
		a:hover {
			text-decoration: none;
			color: $black;
		}

		@include breakpoint(desktopSidePanel, mid, true) {
			display: block;
		}
	}

	&-video-wrapper {
		width: 90%;
	}

	&-tabs {
		height: 40px;
		line-height: 40px;
		font-size: 18px;
		text-align: center;
		color: $wim-black;
		text-transform: uppercase;

		//cursor: pointer;
		>span {
			cursor: pointer;
		}

		.selected {
			@include gotham(medium);
			cursor: default;
		}
	}


	.sidepanel-video-panel {
		position: relative;
		overflow-y: auto;
		@include hideScrollbar;

		.sidepanel-video-content {
			height: 100%;

			.column-layout {
				margin-top: 45px;
			}

			.video-button {
				position: relative;
				display: inline-block;

				bottom: 0px;
				left: 0px;
				right: 0px;
				margin: 0px 12px;
				height: 30px;
				width: 340px;
				border-radius: 3px;
				border: solid 2px $gray_9b;
				color: $wim-purple;
				z-index: 1;
				text-align: center;
				background-color: $white;
				line-height: 30px;
				font-size: 14px;
				cursor: pointer;
				text-transform: uppercase;
				@include gotham(medium);

				i {
					font-size: 9px;
					position: relative;
					bottom: 2px;
				}
			}
		}

		.sidepanel-video-error {
			color: $wim-green;
			border: 1px solid $wim-green;
			width: 90%;
			@include gotham(medium);
			margin: 100% auto;

			.title {
				font-size: 18px;
				letter-spacing: 2px;
				padding-top: 10px;
				padding-bottom: 10px;
				margin-right: auto;
				margin-left: auto;
				width: 60%;
				text-align: center;
			}
		}
	}
}


// IE11
_:-ms-fullscreen,
:root .sidepanel-content-scores,
:root .sidepanel-video-content {
	overflow-y: auto;
}