/** next up video display **/

.nextup {
	height: 102px;
    width: calc(100% - 6px);
	bottom: 1px;
	left: 3px;
    position: absolute;
    background-color: #ffffff;
    z-index: 205;
	cursor: pointer;
	
	@include breakpoint(tablet, max) {
        display: none;
	}

    .next_banner {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		height: 100%;
		width: 100%;
		position: absolute;

        .photo {
			flex: 0 0 175px;
			margin: 2px 20px 2px 2px;
			
			.photo-holder {
				width: 100%;
				padding-bottom: 56.25%;

				img {
					width: 100%;
					height: auto;
					position: absolute;
					transform: none;
					top: 0px;
				}
			}
		}
		
        
        .next-content {
			flex: 0 1 100%;
			min-width: 100px;
			text-overflow: ellipsis;
			overflow: hidden;
            display: block;
			color: #000000;
			
            .prompt {
                font-size: 18px;
                font-weight: 900;
                text-transform: uppercase;
                padding-top: 18px;
				line-height: initial;
			}
			
            .title {
                font-size: 26px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
				line-height: initial;
            }
		}
		
        .next {
			flex: 0 0 100px;
            margin: 6px 0 10px;
            border-left: 1px solid #999999;
            position: relative;
		
			
            .play-button {
				border-style: solid;
				box-sizing: border-box;
				width: 40px;
				height: 20px;
				border-width: 25px 0px 25px 40px;
				border-color: transparent transparent transparent #999999;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			}
		}
		
        .countdown {
			flex: 0 0 140px;			
			display: flex;
			flex-direction: column;
			text-align: center;
			
            .label {
				flex: 0 0 10%;
                padding-top: 2px;
				font-size: 14px;
				line-height: initial;
			}
			
            .count {
				flex: 0 1 80%;
				line-height: initial;
				font-size: 45px;
				margin-top: 7px;
			}
			
            .allow {
				flex: 1 0 10%;
                font-size: 12px;
                text-transform: uppercase;
				width: 100%;
				margin: 0 0 4px 0;
				color: $wim-green;
				line-height: initial;
				span {
					top: calc(100% - 14px);
					position: relative;
				}
            }
        }
    }
}