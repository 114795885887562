.news-tile {
    color: black;
    width: 100%; //height:275px;
    position: relative;
	cursor: pointer;    
    @at-root .generic-carousel #{&}{
        display: block;
    }
    .news-tile-inner{
        position: relative;
    }
    a {
        @at-root .LazyLoad #{&}{
            display: block;
            position: relative;
        }
    }

    &.one-col {
        @at-root .LazyLoad #{&}{
            margin: 0;
        }
    }
	
    .favorite {
        position: absolute;
        top: 10px;
        cursor: pointer;
        z-index: 2;
        right: 20px;
        left: unset;
		width: 20px;
		
        &:after {
            position: absolute;
            content: "\2606";
            font-size: 33px;
			color: #fff;
			text-shadow: 1px 1px 2px #000000;
		}
		
        &.favorited:after {
			content: "\2605";
			text-shadow: 1px 1px 2px #000000;
        }
    }
    i {
        display: none;
        background-color: rgba(0, 0, 0, .3);
        border-radius: 50px;
    }

    &.video-tag {
		user-select: none;
		-webkit-tap-highlight-color: transparent;
	
        i {
            display: block;
        }
    }

    /*@include breakpoint(tablet){
            width:350px;
            height:315px;
        }

        @include breakpoint (desktop){
            width:300px;
            height:285px;
        }*/
    .new-tag {
        text-transform: uppercase;
        background: $live-green2;
        width: 50px;
        height: 15px;
        color: $black;
        text-align: center;
        @include gotham(medium);
        font-size: 10px;
        position: absolute;
        top: 0px;
        padding-top: 1.5px;
    }

    .type-tag {
        color: $white;
        text-transform: uppercase;
        background: $wim-green;
        height: 15px;
        @include gotham(medium);
        line-height:15px;
        font-size: 10px;
        padding: 0 10px;
        text-align: center;
        position: absolute;
        right: 0px;
        top: -7px;
        z-index: 5;
		white-space: nowrap;
    }

    .duration-tag {
        padding: 0 5px;
        height: 15px;
        position: absolute;
        right: 0px;
        background-color: $wim-black;
        color: $white;
        margin-top: -15px;

        @include gotham(medium);
        font-size: 10px;
        text-align: center;
        line-height: 14px;
    }

    i {
        color: $white;
        font-size: 56px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
    }

    img {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .image-container {
        position: relative;
        width: 100%;
        padding-bottom: 66.66%;
        height: 0px;
        background-color: $white;
        background-size: cover;
		overflow: hidden;

        &.wide {
            padding-bottom: 56.25%;
        }
	}
	
	&.video-tag {
		.image-container {
			box-shadow: inset 0 0 0 1px black;
		}
	}

    .news-timestamp {
        margin-top: 7px;
        color: $wim-green;
    }
    
    .news-title {
        margin-top: 5px;        
    }

    a:hover, a:active, a:visited:hover, a:visited:active {
        text-decoration: none;
    }

	&.carousel {
		.news-title {
			font-size: 16px;  
			div {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box; 
				-webkit-box-orient: vertical; 
				-webkit-line-clamp: 2; 
				height: 40px;
			}
		}
		.news-timestamp {
			h6 {
				font-size: 11px;
			}
		}
	}
}

.news-tile-divider {
    background-color: $wim-text-purple;
    width: 20px !important;
    height: 2px;
    margin: 10px 0 15px;
}

.lazy-placeholder {
    .image-container {
        //background-color: $gray-300;
    }
}