.match-box-draws{
    .match-location{
        display:none;
    }

    &.extraSpace {
        margin-top: 15px;
    }

    .match-status{
        padding-top:0;
        height:91px;

        .event-info{
            display:none;
        }

        .green-border{
            height:91px;
        }
        .name a {
            display: initial;
            flex: 0;
        }

        .team-info{
            height:44px;

            &.team-one{
                margin:0;
            }

            .team-name{
                // height:44px;
                padding-top:5px;
                max-width: calc(100% - calc(169px + 5px));
                width: calc(100% - calc(137px + 5px));
                .member-one, .member-two {
                    &.searchedPlayer {
                        background-color: $wim-yellow !important;
                        color: $wim-green !important;
                        a {
                            color: $wim-green !important;
                        }
                        .seed {
                            color: $wim-green !important;
                        }
                    }
                    &.favorite {
                        .name {
                            a {
                                color: white;
                            }
                        }

                    }
                }
            }

            .match-results{
                align-self:center;
                width: 155px;
                flex: 0 0 155px;

                .results-wrapper {
                    // float: right;
                    padding-left: 27px;
                }
            }
        }

        .results-info{
            // right: 0px;
            top: 44px;
            background: none;
        }

        .event-notes{
            display:none;
        }
    }

    .match-box-buttons{
        .watch-and-read{
            display:none;
        }

        .slamtracker-stats{
            margin-left:0;

            .white {
                .slamtracker-stats_text {
                    transform: none;
                }
            }
        }

        .generic-button_wrapper {
            //width: calc(50% - 4px); when there are 2 buttons
            width: 100%;
            margin: 7px 0 0;
            cursor: pointer;

            &:first-child {
                display: none; // hide watch-and-read button
            }
            &:last-child {
                margin-left: 0; // remove if using watch and read and slamtracker buttons
            }
        }
    }
    &.winner {
        height: 48px;
        margin-top: 25px;

        .match-status {
            height: 48px;

            .green-border{
                height:48px;
            }
        }
    }
}
