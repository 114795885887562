.homepage-photo{
    .homepage-element-info{

        @include breakpoint(tablet,max){
            transform:translate(-50%,-26px);

            .wim-icon-gallery{
                font-size:32px;
                position:static;
                display:inline-block;
                transform:none;
            }
        }
    }

    &.standard{
        @include breakpoint(tablet){
            .homepage-element-info{
                width:34%;

                .wim-icon-video{
                    display:inline-block;
                }
            }
        }
    }
}