.page, .webview {

    $draw-path-side-margin-tablet: 20px;
    $draw-path-side-margin-desktop: 38px;

.path-to-the-final-wrapper {
    position: absolute;
	background-color: white;
	height: 100%;
	width: 100vw;
	z-index: 2;
	top: 0;
	left: 0;


} // path-to-the-final-wrapper
    
.path-to-the-final {
    background-color: white;
    position: fixed;
    // min-height: 100vh;
    width: 100vw;
	height: 100%;

    .close-btn-container {
        position: absolute;
        right: 20px;
        top: 20px;
    }

    .no-data {
        margin-left: 38px;
    }

    h3 {
        color: $wim-green;
        font-size: px2rem(14);
        padding-top: 13px;
        text-transform: uppercase;
        padding-left: 20px;
        padding-right: 20px;

        &.black {
            color: $wim-black;
            padding-bottom: 10px;
        }
    }

    .player-wrapper {
        display: flex;
        padding: 20px;
        flex-wrap: wrap;
        padding-top: 19px;
        padding-bottom: 19px;
        width: 100%;

        .favorite {
            position: relative;
            cursor: pointer;
    
            @include breakpoint(tablet) {
                display: block;
            }
            
            &::after {
                position: absolute;
                left: 0;
                content: "\2606";
                font-size: 23px;
                color: $wim-purple;
    
                @include breakpoint(tablet) {
                    font-size: 33px;
                }
            }
            
            &.favorited {
                &::after {
                    content: "\2605";
                    font-size: 23px;
                    color: $wim-purple;
                    @include breakpoint(tablet) {
                        font-size: 33px;
                    }
                }
            }
        }

        .player-img-container {
            display: inline-block;
            margin-right: 7px;
            margin-left: 20px;

            img {
                width: 80px;
                height: 80px;
            }
        }

        .rank {
            @include gotham(medium);
            align-items: center;
            display: flex;
            font-size: px2rem(14);
            text-transform: uppercase;

            .num {
                font-size: px2rem(24);
                margin-right: 9px;
                min-width: 26px;
                text-align: center;
            }
        }

        /** override .draw-analysis.scss */
        .draw-analysis-wrapper {
            margin: auto;
            border-radius: 10px;
            margin-top: 10px;
            width: 350px;
            transform: translateX(-8px);

            .draw-analysis-help-overlay {
                top: 30%;
            }

            .updated {
                &.mobile-show {
                    transform: translateY(-20px);
                    margin-bottom: -20px;
                }
            }
        }

        .lower-opacity {
            opacity: .5;
        }
    } // .player-wrapper

    .name {
        @include gotham-book();
        color: $wim-green;
        display: flex;
        font-size: px2rem(13);
        font-weight: 600;

        span { /** seed */
            font-size: px2rem(11);
            padding-left: 2px;
            padding-top: 2px;
        }

        .flag {
            width: 35px;
        }

        img { /** flag image */
            padding-right: 9px;
            height: auto;
        }

        &.with {
            padding-top: 7px;
        }
    }

    .draws-path-wrapper {
        height: calc(100vh - #{$pathToTheFinalTopHeightLarge});
        overflow-y: auto;
        padding-bottom: 0px;

        &.lower-zindex {
            z-index: -1;
        }

        &.with-draw-analysis {
            height: calc(100% - 298px);
        }
    }

    .draws-path-inner-wrapper {
        width: 100%;
        padding-bottom: 50px;
    }

    .fade-background {
        display: none;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: 2;

        &.show {
            display: block;
        }
    }

    .draws-path {
        .round-header {
            background-color: $gray-e3;
            position: relative;
            padding-left: 20px;
            padding-right: 20px;

            h5 {
                color: $gray-6b;
                font-size: px2rem(10);
                text-transform: uppercase;
                padding-top: 2px;
                padding-bottom: 2px;

                &::before { /** add a bullet */
                    @include bulletBeforeText($wim-green, 16px, 7px); // color, size, paddingRight
                }

                &::after { /** add a virtical line */
                    position: absolute;
                    left: 18px;
                    top: 0;
                    content: '';
                    border-left: 1px solid $wim-green;
                    margin-left: 5px;
                    height: 100%;
                }

                /** mobile - make the top half solid line from completed match to the future match */
                .half-solid {
                    &::after {
                        position: absolute;
                        left: 18px;
                        top: 0;
                        content: '';
                        border-left: 1px solid $wim-green;
                        margin-left: 5px;
                        height: 50%;
                    }
                }
            } // h5

            /** the first element - don't draw a strikethrough virtical line */
            &.first {
                h5::after {
                    top: 50%;
                }
            } 

            /** the last element - end the virtical line at the bullet position */
            &.last  {
                h5::after {
                    height: 50%;

                }
                /** safenet to remove the top half border when there is only one entry
                .last class is applied but it's also the .first element 
                */
                &:first-of-type {
                    h5 {
                        &::after {
                            border: none;
                        }
                    }
                }
            }

            &.dashed:not(.last) {
                h5::after {
                    border-left: 1px dashed $wim-green;
                }
            } 

            &.no-line {
                h5::after {
                    content: unset;
                }
            }

            .draw-label {
                @include ai-draw-label;
                font-size: px2rem(12);
                display: unset;
                position: absolute;
                text-transform: capitalize;
                right: 10px;
                top: 0px;
            }

            
        } // .round-header

        .round-block {
            position: relative;
            padding-left: 20px;
            padding-right: 20px;

            &::after {
                position: absolute;
                left: 18px;
                top: 0;
                content: '';
                border-left: 1px solid $wim-green;
                margin-left: 5px;
                height: 100%;
            }

            /** the last element - don't draw a border */
            &.no-line, &.last, &.last.dashed {
                &::after {
                    border: none;
                }
            }

            &.dashed {
                &::after {
                    border-left: 1px dashed $wim-green;
                }
            }
        }

        .opponent {
            padding-top: 13px;
            padding-bottom: 13px;
            display: flex;

            label {
                color: $wim-black;
                display: inline-block;
                margin-right: 9px;
                white-space: nowrap;

                &.Projected {
                    display: none;
                }
            }

            .name {
                width: 100%;

                a { /** player name - ellipseis */
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            &::before {
                /** to align perfectly, put a invisible same size bullet */
                @include bulletBeforeText(transparent, 16px, 7px); // color, size, paddingRight
            }
            
            &.hide-more-opponent {
                display: none;
            }
        }

        .opponent-doubles {
            padding-top: 13px;
            padding-bottom: 13px;
            display: block;

            &:not(:first-of-type) { 
                /** more than 1 opponents box, put a line above the box to separate */
                border-top: solid 0.5px $gray_bb;
            }
            
            &.hide-more-opponent {
                display: none;
            }

            label {
                color: $wim-black;
                display: block;

                &::before {
                    /** to align perfectly, put a invisible same size bullet */
                    @include bulletBeforeText(transparent, 16px, 7px); // color, size, paddingRight
                }

                &.Projected {
                    display: none;
                }
            }

            .name {
                padding-top: 7px;

                &::before {
                    /** to align perfectly, put a invisible same size bullet */
                    @include bulletBeforeText(transparent, 16px, 7px); // color, size, paddingRight
                }
            }
        }

        .generic-button_wrapper { /** slamtracker button */
            margin-top: 24px;

            &:hover {
                cursor: pointer;
            }
        }

        .button-text-link {
            &.load-more {
                @include gotham(medium);
                font-size: px2rem(14);
                margin-top: 8px;
                padding-bottom: 12px;
                margin-left: 15px;
            }
        }

        /** only for mobile size, put some space next to the left border */
        .slamtracker-button {
            margin-left: 13px;
            max-width: 180px;

            .generic-button_wrapper {
                margin-top: unset;
                margin-bottom: unset;
                padding-bottom: 10px;
            }
        }

    } // .draws-path

    /** make the lines and bullets for future matches in purple - only for MS and LS */
    &.ai-draws {
        .round-header {
            &.dashed {
                h5 {
                    &::before {
                        // @include bulletBeforeText($likelytoplay-purple, 16px, 2px); // color, size, paddingRight
                        color: $likelytoplay-purple;
                    }
                }
            }

            &.dashed,
            &.dashed:not(.last) {
                h5 {
                    &::after { 
                        border-left: 1px dashed $likelytoplay-purple;
                    }
                }
            }
        } // .round-header

        .round-block { /** for ai draws future match, put a border under the most likely to play opponent name */
            // &.likely-to-play:nth-child(1) {
            //     padding-bottom: 4px;
            // }

            // &.likely-to-play:nth-child(2) {
            //     border-top: solid 0.5px $gray_bb;
            //     margin-right: 10px;
            //     padding-top: 3.5px;
            // } 
            
            &.dashed {
                &::after {
                    border-left: 1px dashed $likelytoplay-purple;
                }
            }
        } // .round-block
    } // .ai-draws


    @include breakpoint(tablet) {

        .player-wrapper {
            padding-left: $draw-path-side-margin-tablet;
            padding-top: 19px;
            padding-bottom: 19px;
            flex-wrap: unset;

            .draw-analysis-wrapper {
                margin-left: 27px;
                margin-top: 0;
                display: inline-block;
                padding: 0;
                border: none;

                .updated {
                    &.mobile-show {
                        transform: unset;
                        margin-bottom: unset;
                    }
                }
            }
        }

        .player-img-container {
            img {
                width: 155px;
                height: 155px;
            }
        }

        .draws-path-wrapper {
            position: relative;
            overflow: auto;
            width: 100%;
        }

        .draws-path-inner-wrapper {
            display: flex;
            width: 100%;
            white-space: nowrap;

            /** add background color to the before element 100vw
            *   so the background color in the round number header
            *   goes to the edge when window is resized to be larger */
            &::before {
                background-color: $gray-e3;
                content: "";
                width: 100%;
                position: absolute;
                height: 25px;
            }
        }

        .draws-path {
            flex: 0 0 180px;

            .round-header {
                // background-color: $gray-e3;
                padding-left: unset;
                padding-right: unset;

                h5 {
                    display: flex;
                    flex-direction: row;
                    height: 25px;
                    align-items: center;

                    &::before {
                        padding-right: 1px;
                    }

                    &::after { /** add a horizontal line */
                        content: "";
                        flex: 1 1;
                        border-bottom: 1px solid $wim-green;
                        margin: auto;
                        margin-left: 10px;
                        border-left: none;
                        position: relative;
                        transform: translate(-15.5px, -10.5px);
                    }

                     /** mobile - make the top half solid line from completed match to the future match */
                    .half-solid {
                        &::after {
                           border-left: none;
                        }
                    }
                }

                &.dashed:not(.last) {  /** for future matches, make the line in dashed */
                    h5 {
                        &::after {
                            border-bottom: 1px dashed $wim-green;
                            border-left: none;
                        }
                    }
                }
            
                &.first {
                    h5::after {
                        top: unset;
                        transform: translate(-5px, -10.5px)
                    }
                }

                &.last, &.dashed.last {  /** don't put a border if it's last */
                    h5 {
                        &::after {
                            border: none;
                        }
                    }
                }

                .draw-label {
                    display: none;
                }
            } 

            .opponent {
                display: block;
                padding-bottom: 0;

                &::before {
                    /** unset content */
                    @include bulletBeforeText(transparent, 0px, 0px); // color, size, paddingRight
                    content: "";
                }
                
                label {
                    color: $wim-black;
                    display: block;

                    &::before {
                        /** to align perfectly, put a invisible same size bullet */
                        @include bulletBeforeText(transparent, 16px, 2px); // color, size, paddingRight
                    }

                    &.Projected {
                        display: none;
                    }
                }

                .name {
                    padding-top: 7px;

                    &::before {
                        /** to align perfectly, put a invisible same size bullet */
                        @include bulletBeforeText(transparent, 16px, 2px); // color, size, paddingRight
                    }

                    a { 
                        max-width: 100px;
                    }
                }

                &:not(:first-child) {
                    padding-top: 7px;
                }

                &.hide-more-opponent { /** only mobile hhide this - undo hiding */
                    display: block;
                }

                &:first-of-type {
                    /** show Projected label only at the top once */
                    label {
                        &.Projected {
                            display: block;
                        }
                    }
                }
            } // .opponent

            .opponent-doubles {
                &.first { /** add margin to align the round label in the header */
                    margin-left: calc(#{$draw-path-side-margin-tablet} - 7px);
                }

                &.hide-more-opponent { /** only mobile hhide this - undo hiding */
                    display: block;
                }

                label {
                    &.Projected {
                        display: none;
                    }
                }

                &:first-of-type {
                    /** show Projected label only at the top once */
                    label {
                        &.Projected {
                            display: block;
                        }
                    }
                }
            }

            .round-block {
                padding-left: unset;
                padding-right: unset;

                &::after {
                    border-left: none;
                }

                &.dashed {
                    &::after {
                        border-left: none;
                    }
                }
                
                .slamtracker-button {
                    margin-left: 10px;
                    margin-right: 10px;
                    margin-top: 10px;
                    max-width: unset;
                }
            }

            /** put some margin to the first and last .draws-path elements */
            &:first-of-type {
                flex: 0 0 200px;
                
                .round-header {
                    h5 {
                        margin-left: $draw-path-side-margin-tablet;
                    }
                }

                .opponent { 
                    margin-left: $draw-path-side-margin-tablet;
                }
            }

            &:last-of-type {
                flex: 0 0 200px;

                .opponent { 
                    margin-right: $draw-path-side-margin-tablet;
                }
            }

            .button-text-link {
                &.load-more {
                    display: none;
                }
            }
        } // .draws-path

        /** make the lines and bullets for future matches in purple - only for MS and LS */
        &.ai-draws {
            .round-header {
                &.dashed {
                    h5 {
                        &::before {
                            // @include bulletBeforeText($likelytoplay-purple, 16px, 2px); // color, size, paddingRight
                            color: $likelytoplay-purple;
                        }
                    }
                }

                &.dashed,
                &.dashed:not(.last) {
                    h5 {
                        &::after { /** add a horizontal line */
                            border-bottom: 1px dashed $likelytoplay-purple;
                            border-left: none;
                        }
                    }
                }
            } // .round-header

            .round-block {
                &.dashed {
                    &::after {
                        border-left: none;
                    }
                }
            }

            .opponent {  /** for ai draws future match, put a border under the most likely to play opponent name */
                // &.likely-to-play:nth-child(1) {
                //     padding-bottom: 4px;
                // }

                // &.likely-to-play:nth-child(2) {
                //     border-top: solid 0.5px $gray_bb;
                //     margin-right: 10px;
                //     padding-top: 3.5px;
                // }

                &.hide-more-opponent {
                    display: block;
                }
            } // .opponent
        } // .ai-draws
    }  // breakpoint tablet

    @include breakpoint(desktop) {
        h3,
        .player-wrapper {
            padding-left: $draw-path-side-margin-desktop;

            .player-info {
                display: inline-block;
                vertical-align: top;
            }
        }

        .draws-path {

            /** put some margin to the first and last .draws-path elements */
            &:first-of-type {
                flex: 0 0 218px;
                
                .round-header {
                    h5 {
                        margin-left: $draw-path-side-margin-desktop;
                    }
                }

                .opponent, .opponent-doubles { 
                    margin-left: $draw-path-side-margin-desktop;
                }
            }

            &:last-of-type {
                flex: 0 0 218px;

                .opponent { 
                    margin-right: $draw-path-side-margin-desktop;
                }
            }
        }
    } // breakpoint desktop
} // .path-to-the-final


/** standalone override */
.path-to-the-final-wrapper {
    &.standalone {
        position: relative;
        height: unset;
    
        .path-to-the-final {
            &.standalone {
                position: unset;
                height: unset;
                min-height: 180px;
                
                /** lf it's not on full P2F overlay, 
                    don't apply the height restrictions on 
                    .draws-path-wrapper
                    - causes scrolling issues */
                .draws-path-wrapper:not(.full) {
                    height: unset;
                }
            }
        }
    } 
} // end standalone override

    // sidepanel always open
    @include breakpoint(largeDesktop) {
        .path-to-the-final-wrapper, 
        .path-to-the-final  {
            width: calc(100vw - #{$sidebarWidth});
        }
    }
    // sidepanel always open with centered max width content
    @include breakpoint(extraLargeDesktop) {
        .path-to-the-final-wrapper, 
        .path-to-the-final {
            width: calc(#{$maxWidth} - #{$sidebarWidth});
        }
    }

    &.expanded {
        .content-main.draws {
            .path-to-the-final-wrapper,
            .path-to-the-final {
                width: calc(100vw - #{$sidebarWidth});
                
                @include breakpoint(extraLargeDesktop) {
                        width: calc(#{$maxWidth} - #{$sidebarWidth});
                }
            }
        }
        
        .path-to-the-final-wrapper,
        .path-to-the-final {
            width: calc(100vw - #{$sidebarWidth});
            
            @include breakpoint(extraLargeDesktop) {
                    width: calc(#{$maxWidth} - #{$sidebarWidth});
            }

            /** so the fade in the draw analysis box won't expand to bleed out,
                set the width the same size as the draw analysis box itself
            */
            .draw-analysis-help-overlay {
                width: 350px;
            
                @include breakpoint(extraLargeDesktop) {
                        width: 350px;
                }
            }
        }
    } // .expanded

    &.compressed { /** slamtracker opened under the side panel size */
        .content-main.draws {
            .path-to-the-final-wrapper,
            .path-to-the-final {
                width: calc(100% - #{$sidebarWidth});

                &.standalone {
                    width: 100%;
                }
            }
        }

        .path-to-the-final-wrapper,
        .path-to-the-final {
            width: calc(100% - #{$sidebarWidth});

            &.standalone {
                width: 100%;
            }
        }
	} // .compressed
} // .page

/** draws mobile page special override
    It has higher style set up that goes above .path-to-the-final-wrapper */
@include breakpoint(tablet, max) {
    .default .content-main.draws .column-layout.draws-page {
        &.path-to-the-final-wrapper {
            position: unset;
        }
    }
}

.webview {
    .path-to-the-final-wrapper, 
    .path-to-the-final {
        max-width: 1260px;

        .draws-path-wrapper {
            height: calc(100% - 100px);

            &.with-draw-analysis {
                height: calc(100% - 298px);
            }
        }
    }
    .draws-path {
        .slamtracker-button {

            .generic-button_wrapper {
                margin-top: 10px;
            }
        }
    }

    .path-to-the-final-wrapper {
        position: fixed;
    }
}

/** tablet size and above for override for side border  
    some weird breakpoint 1161px max (couldn't find where this is from)
    is preventing webview over tablet to draw proper lines, 
    thus overriding it here
*/
@include breakpoint(tablet) {
    .compressed .page { 
        .path-to-the-final {
            .draws-path {
                .round-header {
                    padding-left: unset;
                    padding-right: unset;

                    h5 {
                        &::after {
                            border-left: none;
                            position: relative;
                            transform: translate(-15.5px, -10.5px);
                        } 

                        .half-solid {
                            &::after {
                                border-left: none;
                            }
                        }
                    }

                    &.first h5 {
                        &::after {
                            top: unset;
                        }
                    }
                } // round-header

                .round-block {
                    &::after {
                        border-left: none;
                    }

                    &.dashed {
                        &::after {
                            border-left: none;
                        }
                    }
                } // round-block
            }

            .draw-analysis-wrapper {
                padding: 0;
                border: none;
            }

            &.ai-draws {
                .round-block.dashed::after, 
                .round-header.dashed h5::after,
                .round-header.dashed:not(.last) h5::after {
                    border-left: none;
                }
            }
        } // path-to-the-final
    }
} // breakpoint tablet  