

.byline{
    min-height: 17px;

    &--attribution {
        float: left;
        @include helveticaneue(medium);
        text-transform:uppercase;
        font-size:12px;
        color: $wim-text-purple;
    }

    &--share {
        float: right;
        width: 100px;
        text-align: right;
        position:relative;
        color:rgba($select-menu-text,.5);
        background:$white;
        @include gotham(medium);
        font-size: 12px;

        .wim-icon-share{
            margin-right:5px;
        }

        &-button {
            cursor: pointer;
        }

        &-wrapper {
            display: block;
            position: absolute;
            width: 156px;
            top: 25px;
            right: 0px;

            &.threecol {
                width: 120px;
            }
        }
    }
    &--favorite {
        float:right;
        font-size: px2rem(21);
    }

    &.left {
        @include breakpoint(desktop, min){
            width: calc(25% - #{$item-h-margin} * 2);

            .byline--attribution {
                float: right;
            }

            .byline--share {
                clear: right;
                margin-top: 8px;
            }
        }
    }
    
}

   