.divider-header {
    width: 100%;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $wim-black;
    background-color: #F0F0F0;
    @include gotham(medium);
    font-size: px2rem(14);
    text-transform: uppercase;

    i {
        margin-left: 3px;

        &.with-tooltip {
            cursor: pointer;
        }
    }
    &.with-tooltip {
        position: relative;
    }
}

/** tooltips from divider header component **/
.tooltip-shotquality {
    position: absolute;
    width: 240px;
    height: 49px;
    @include gotham(medium);
	font-size: px2rem(12);
	color: $white;
	right: 58px;
	top: 35px;
    cursor: pointer;
	padding: 8px 6px;
	background: $wim-text-purple;
	box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    text-transform: none;
    z-index: 5;

	&::before {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
		top: -8px;
		right: 60px;
		box-sizing: border-box;

		border-left: 8px solid transparent;
        border-right: 8px solid transparent;
		border-bottom: 8px solid $wim-text-purple;
	}
}