.search-box.search-box-container {
    // @include breakpoint(desktop) {
    //     flex: 0 0 260px;
    // }
    
    .search-wrapper {
        z-index: 9;
        .autosuggest-not-found {
            background: $white;
            color: $wim-search-text-purple;
            border-radius: 10px;
            width: 246px;
            overflow: hidden;
            margin: auto;
            padding: 10px 5px;
            text-align: center;
        }
        i.wim-icon-close {
            position: absolute;
            right: 20px;
            top: 11px;
            cursor: pointer;
        }
        i.search-icon {
            font-size: 15px;
            left: 14px;
        }
        input.react-autosuggest__input {
            background: rgba(255,255, 255,.15) !important;
        }
    }
    .react-autosuggest__suggestions-container {
        background: $white;
        border-radius: 10px;
        width: 100%;
        overflow: hidden;
        margin: auto;
        ul.react-autosuggest__suggestions-list {
            margin-top: 0px;
            margin-bottom: 5px;
            list-style-type: none;
            padding-left: 0px;
            li.react-autosuggest__suggestion {
                padding: 10px 5px 5px 10px;
                color: $wim-search-text-purple;
            }
            li.react-autosuggest__suggestion--highlighted {
                background: $gray-light;
            }
            .result-article-title {
                span.bold-text {
                    font-weight: bold;
                }
            }
        }
    }
}