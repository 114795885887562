.player-media {
	.player-media-tabs {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		list-style: none;
		margin-bottom: 10px;
		width: 100%;
		padding: 7px;
		margin-top: 0px;
		border-bottom: 1px solid $gray_bb;

		.player-media-tab {
			display: block;
			font-size: 14px;
			color: $wim-green;
			cursor: pointer;
			text-transform: uppercase;
			@include gotham-book();
			font-weight: 700;
			@include breakpoint(medDesktop) {
				font-size: 16px;
			}

			&.active {
				text-decoration: underline;
				text-underline-offset: 7px;
				text-decoration-color: $wim-green;
				text-decoration-thickness: 3px;
			}
		}

		.divider {
			position: relative;
			padding: 0px 20px;
			margin-bottom: 6px;
			// remove last divider
			&:last-of-type {
				display: none;
			}
			&::after {
				position: absolute;
				top: 0;
				height: 20px;
				width: 1px;
				content: '';
				background: gray;
			}
		}

		// .select-menu {
		// 	.animation-container.show {
		// 		width: 120px;
		// 		margin-left: 45%;
		// 		.select-menu-dropdown.show {
		// 			.option {
		// 				margin: 0 auto;
		// 				padding: 4px 0;
		// 				.wim-icon-check {
		// 					top: 8px;
		// 				}
		// 			}
		// 		}
		// 	}
		// }
	}

	.slick-list {
		margin: 0 auto;
		&.video-open {
			transform: none !important;
			.slick-track {
				transform: none !important;
			}
		}
	}

	.slick-slide > div {
		padding: 0 5px;
	}

	.news-tile {
		.type-tag {
			display: none;
		}
	}

	.no-media-content {
		display: flex;
		justify-content: center;
		align-items: center;
		@include gotham(medium);
		font-size: px2rem(16px);
		padding: 20px 0 34px;
	}
}
