.video-test-page{
    display: flex;
    justify-content: center;
    .video-wrapper{
        max-width: 80%;
        position: relative;
        .video-player{
            position: relative;
        }
    }
    .error{
        width: 100%;
        padding: 20px 0;
        text-align: center;
    }
}

.draws-test-page {
    a {
        &.selected {
            font-weight: 900;
        }
    }

    ul.list {
        li {
            margin-bottom: 5px;
        }
    }
}