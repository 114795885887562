/** player-professional-info styles **/
.player-extra-info {
    align-items: center;
    display: flex;
    margin: 0px; 
    
    .extra-info {
        display: flex;
        flex-flow: column;
        margin-top: 20px;
        font-size: px2rem(14);
        div {
            margin-bottom: 10px;
        }
    }
}
