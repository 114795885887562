.court-availability {
	column-count: 2;
	gap: 10px;

	@media only screen and (max-width: 767px) {
		column-count: 1;
	}

	&__court-box {
		border: 1px solid;
		width: 100%;
		break-inside: avoid-column;
		margin-bottom: 10px;
		border-radius: 3px;
		text-decoration: none;
		color: black;
		&-header {
			width: 100%;
			justify-content: space-between;
			display: flex;
			background-color: rgb(28, 68, 28);
			color: white;
			padding: 8px;
			scroll-margin-top: 115px;

			@media only screen and (max-width: 767px) {
				scroll-margin-top: 95px;
			}
			&--active {
				background-color: rgb(201, 32, 52);
				width: 100%;
				justify-content: space-between;
				display: flex;
				color: white;
				padding: 8px;
			}
			&--name {
				font-size: 14px;
			}
			&--count {
				font-size: 11px;
			}
		}

		&-row {
			display: flex;
			justify-content: space-between;
			min-height: 40px;
			padding: 8px;
			font-size: 12px;
			&:nth-child(even) {
				background-color: rgb(252, 252, 252);
			}
			&:not(:last-child) {
				border-bottom: 1px solid rgb(237, 237, 237);
			}
			.Unavailable {
				color: red;
			}
			.Open {
				color: $green;
			}
			.TemporarilyUnavailable {
				color: rgb(253, 195, 72);
			}
		}
	}
}

.status-device {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-right: 10px;
	text-decoration: none;
	color: inherit !important;
	&__not-clickable {
		cursor: default;
	}
	&__circle {
		position: relative;
		&--overlapping-outer {
			border: 4px solid white;
			border-radius: 50%;
			width: 50px;
			height: 50px;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			&-number {
				font-size: 16px;
			}
		}
		&--overlapping-inner {
			position: absolute;
			top: calc(25% / 2);
			right: calc(25% / 2);
			transform: translateY(-25%) translateX(60%);
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: red;
			height: 25px;
			width: 25px;
			border-radius: 50%;
			border: 2px solid white;

			&-number {
				color: white;
				font-size: 12px;
			}
		}
	}
}

.summary-box {
	width: 100%;
	border: 1px solid;
	padding-top: 20px;
	border-radius: 3px;
	margin-bottom: 10px;
	&__court-names {
		display: flex;
		width: 100%;
		justify-content: space-around;

		@media only screen and (max-width: 767px) {
			font-size: 12px;
			text-align: center;
		}
	}
	&__description {
		text-align: center;
		margin-top: 20px;
		margin-bottom: 20px;
		@media only screen and (max-width: 767px) {
			font-size: 12px;
		}
	}

	&__published-section {
		border-top: 1px solid rgb(230, 230, 230);
		background-color: rgb(245, 245, 245);
	}
	&__published-date {
		padding: 8px;
		margin-right: 5px;
		font-size: 10px;
		text-align: right;
		clear: both;
		width: auto;
		color: $green;
	}
}

.temp-unavailable {
	background-color: rgb(211, 94, 48);
}
