.address-book {
	margin-top: 20px;
	
	&-form {
		display: grid;
		justify-content: space-around;
		border-bottom: 1px solid #efefef;
		padding-bottom: 20px;

		@include breakpoint(tablet) {
			display: flex;
		}	

		& > div {
			flex: 0.2;
			align-items: center;
		}

		&__membership-type {
			margin: auto;
			width: auto;
		}

		&__name-input {
			@include breakpoint(tablet) {
				margin-left: 10px;
				width: 172px !important;
				input {
					height: 42px;
				}
			}		
			@include breakpoint(desktop) {
				width: 200px;
			}	
		}

		&__buttons {
			display: inline-flex;
			margin-top: 10px;
			justify-content: center;

			@include breakpoint(tablet) {
				display: flex;
				justify-content: flex-end;
			}	

			.generic-button_wrapper {
				&:not(:last-child) {
					margin-right: 16px;
				}
			}
		}

		.select-wrapper {
			position: relative;
		}

		.select-wrapper::after {
			content: '∨';
			font-size: 1rem;
			top: 6px;
			right: 10px;
			position: absolute;
		}
	}

	&-entries {
		list-style: none;
		padding-left: 0;
	}

	&-entry {
		justify-content: space-between;

		&:not(:last-child) {
			border-bottom: 1px solid #efefefef;
		}

		&:nth-child(even) {
			background-color: #f5f5f5;
		}

		&__left {
			display: flex;

			&--info-container {
				margin-left: 10px;
				margin-top: 10px;
				float: left;
				word-break: break-word;
				width: 100%;
			}

			&--info-text {
				display: inline-block;
			}

			&--picture {
				width: 88px;
				height: auto;
				padding: 10px;
				display: table;
				& > img {
					width: 70px;
				}
				@include breakpoint(tablet) {
					width: 104px;
					& > img {
						width: 96px;
					}
				}
			}

			&--name {
				font-size: 18px;
				font-weight: 800;
				color: #54008b;
				margin-bottom: 15px;
			}

			&--purple-line {
				background-color: #54008b;
				width: 25px;
				height: 2px;
				margin-bottom: 10px;
			}

			&--address {
				font-size: 14px;
				color: rgb(68, 68, 68);
				margin-bottom: 5px;
			}

			&--email {
				font-size: 14px;
				color: #54008b;
			}
		}

		&__right {
			font-size: 14px;
			padding: 4px 0px 4px 0px; // top right bottom left
			@include breakpoint(largeTablet) {
				display: flex;
				padding: 0px;
				flex-direction: column;
				justify-content: space-between;
				padding: 15px 10px 10px 0px;
				text-align: right;
				float: right;
			}
			
		}
	}
}

.input-container {
	width: 100%;
	margin-bottom: 16px;

	@include breakpoint(tablet) {
		width: 90%;
		margin-bottom: auto;
	}
	@include breakpoint(desktop) {
		width: 100%;
	}

	input,
	select {
		width: 100%;
		height: 40px;
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		padding-left: 8px;
	}
	input {
		margin-top: 0;
	}
}

.not-found {
	text-align: center;
	font-weight: bold;
	margin-top: 20px;
	font-size: 20px;
}

.address-book-select-menu {
	.select-menu {
		// width: 305px;
		position: relative;
		display: inline-block;

		button{
			position:  relative;
			text-decoration:  none;
			padding:  0 44px 0 8px;
			cursor:  pointer;
			height: 40px;
			margin: 1px 0px !important;
			font-size: 14px;
			color: $green;
			@include gotham(medium);
			&.title {
				border: solid 1px #bbb;
				background: rgba(255,255,255,0.33);
				text-transform: uppercase;
				text-align: left;
				display: inline-block;
				overflow:  hidden;
				white-space:  nowrap;
				text-overflow: ellipsis;
				line-height:  20px;

				//position arrow to right of box around scores select menu style
				.menu-arrow {
					position: absolute;
					right: 5px;
					top: 13px;
				}

			}
		}
	}
}

#select-menu {
	.scores-select-menu {
		.select-menu {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			border: 1px solid #bbb;
			height: 42px;
			width: 100%;
			@include breakpoint(desktop) {
				width: 90%;
				margin: 0px;
			}
			@include breakpoint(desktop) {
				width: 100%;
				margin: auto;
			}
			button {
				color: $green;
				padding-left: 5px;
			}

			.animation-container {
				overflow-y: scroll;
				top: 40px;
				height: auto;
				overflow: hidden;
			}
		}
		color: black;
	}
}
