// event specific item colors
$team1_color:#006633;
$team1_color_rgb:rgba(0, 102, 51, 1);
$team2_color:#4C2178;
$team2_color_rgb:rgba(76, 33, 120, 1);
$donut-fill-color: #E2E2E2;

// slamtracker breakpoints
@mixin stage_item_breakpoint($break) {
    @if $break == small {  
      @media only screen and (max-width: 767px) { @content; }
    } 

    @else if $break == medium { 
      @media only screen and (min-width: 768px) { @content; } //and (max-width: 1299px) 
    } 

    @else if $break == large { 
      //@media only screen and (min-width: 1300px) { @content; }
    }
}

// fonts
@mixin si-text-font { font-family: 'Gotham 4r', Helvetica, Arial, sans-serif; }
@mixin si-title-font { font-family: 'Gotham 5r', Helvetica, Arial, sans-serif; }
@mixin si-donut-font { font-family: 'Gotham 5r', Helvetica, Arial, sans-serif; }
@mixin si-heading-font { font-family: 'Gotham 5r', Helvetica, Arial, sans-serif; }
@mixin si-heading-font-bold { font-family: 'Gotham 7r', Helvetica, Arial, sans-serif; }
@mixin si-subheading-font { font-family: 'Gotham 5r', Helvetica, Arial, sans-serif; }
@mixin si-numberbox-font { font-family: 'Gotham 5r', Helvetica, Arial, sans-serif; }
@mixin si-courtplacement-font { font-family: 'Gotham 5r', Helvetica, Arial, sans-serif; }
@mixin si-playermovement-font { font-family: 'Gotham 5r', Helvetica, Arial, sans-serif; }
@mixin si-servespeed-font { font-family: 'Gotham 4r', Helvetica, Arial, sans-serif; }
@mixin si-servespeed-winning-font { font-family: 'Gotham 7r', Helvetica, Arial, sans-serif; }
@mixin si-distancerun-font { font-family: 'Gotham 5r', Helvetica, Arial, sans-serif; }
@mixin si-keys-font {font-family: 'Gotham 5r', Helvetica, Arial, sans-serif; }
@mixin si-situational-font {font-family: 'Gotham 5r', Helvetica, Arial, sans-serif; }
@mixin si-social-font {font-family: 'Gotham 5r', Helvetica, Arial, sans-serif; }
@mixin si-winning-font {font-family: 'Gotham 5r', Helvetica, Arial, sans-serif;}

//stage item backgrounds
$si_bg_1_small: '/assets/images/scores/slamtracker/backgrounds/Bkg1_320.jpg'; //Pressure Situations
$si_bg_2_small: '/assets/images/scores/slamtracker/backgrounds/Bkg2_320.jpg';
$si_bg_3_small: '/assets/images/scores/slamtracker/backgrounds/Bkg3_320.jpg'; //Player Movement
$si_bg_4_small: '/assets/images/scores/slamtracker/backgrounds/Bkg4_320.jpg';
$si_bg_5_small: '/assets/images/scores/slamtracker/backgrounds/Bkg5_320.jpg';
// $si_bg_6_small: '/assets/images/scores/slamtracker/backgrounds/Bkg6_320.jpg'; //Serve Placement
$si_bg_6_small: '/assets/images/scores/slamtracker/backgrounds/mask_group.png'; //Serve Placement
// $si_bg_7_small: '/assets/images/scores/slamtracker/backgrounds/Bkg7_320.jpg'; //Depth of Return
$si_bg_7_small: '/assets/images/scores/slamtracker/backgrounds/mask_group.png'; //Depth of Return

$si_bg_1_large: '/assets/images/scores/slamtracker/backgrounds/Bkg1_768.jpg'; //Pressure Situations
$si_bg_2_large: '/assets/images/scores/slamtracker/backgrounds/Bkg2_768.jpg';
$si_bg_3_large: '/assets/images/scores/slamtracker/backgrounds/Bkg3_768.jpg'; //Player Movement
$si_bg_4_large: '/assets/images/scores/slamtracker/backgrounds/Bkg4_768.jpg';
$si_bg_5_large: '/assets/images/scores/slamtracker/backgrounds/Bkg5_768.jpg';
// $si_bg_6_large: '/assets/images/scores/slamtracker/backgrounds/Bkg6_768.jpg'; //Serve Placement
// $si_bg_7_large: '/assets/images/scores/slamtracker/backgrounds/Bkg7_768.jpg'; //Depth of Return
$si_bg_6_large: '/assets/images/scores/slamtracker/backgrounds/mask_group.png'; //Serve Placement
$si_bg_7_large: '/assets/images/scores/slamtracker/backgrounds/mask_group.png'; //Depth of Return

.stage-item {
    &.depth-of-return {
        background: url($si_bg_7_small) no-repeat;
        background-size: cover;
    }
    &.player-movement {
        background: url($si_bg_3_small) no-repeat;
        background-size: cover;
    }
    &.si-wrapper {
        &.player-movement .si-inner-wrapper .item.stage1 .player-data .serve-speed-wrapper {
            background:rgba(255,255,255,0.2);
        }
    }
    &.progress-keys {
        background: url($si_bg_2_small) no-repeat;
        background-size: cover;
    }
    &.serve-placement {
        background: url($si_bg_6_small) no-repeat;
        background-size: cover;
    }
    &.situational-analysis{
        background: url($si_bg_1_small) no-repeat;
        background-size: cover;
    }
}

.slamtracker {
    .featured-match {
        .more {
            display:none;
        }
    }
    .slamtracker-overview {
        .bio-data-table {
            position:relative;
        }
    }

    .content-tabs a {
        text-decoration: none;
    }
}

/*  stage item includes */

@include stage-common;

@include stage_item_breakpoint(small) {
    @include stageitem-xsmall;
}

@include stage_item_breakpoint(medium) {
    @include stageitem-xsmall;
    @include stageitem-small;   // include the small stage item css
}

@include stage_item_breakpoint(large) {
    .embedded {
        @include stageitem-large;
    }
}