

.search--header-wrapper {
    bottom: 100%;
    color: $white;
    width: 100%;

    position: fixed;
    top: 100px;
    left: 0px;
    z-index: 0;
    &.show-results {
        z-index: 3;
    }
	
	.wrapper.fixed & {
		z-index: 5;
	}

    .search--header {
        width: 100%;
        //margin-left: 10%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        opacity: 1;
		z-index: 2;
		pointer-events: none;
		
        #wimbledon-search-text {
            &:focus {
                outline: none !important; 
            }
        }
        .wim-search-results {
            &:focus {
                outline: none !important;
            }
            z-index: 99;
            border-radius: 8px;
            border: 1px solid #dddddd;
            background: #ffffff;
            color: #333333;
            padding: 2px;
            font-family: 'Gotham 4r', Helvetica, Arial, sans-serif;
            font-size: .9em;
            list-style: none;
			padding: 10px;
			width: 180%;
			margin-left: -40%;

			@include breakpoint(tablet, max){
				display: none;
			}

            .bold-text {
                font-weight: bold;
            }
            // -webkit-margin-before: 0px;
            // -webkit-padding-start: 0px;
            .wim-search-results-header {
                font-family: 'Gotham 7r', Helvetica, Arial, sans-serif;
                color: $wim-green;
                border-bottom: 1px solid #bebebe;
                padding-bottom: 5px;
            }
            .wim-results-more {
                border-top: 1px solid #bebebe;
                padding-top: 5px;
                color: $wim-green;
                position: relative;
                font-weight: bold;
                cursor: pointer;
                .more-results-text {
                    margin-left: 30px;
                }
            }
            .result-article-title {
                color: $wim-green;
                padding: 4px 10px 4px 10px;
                border-radius: 5px;
                cursor: pointer;
                &:hover {
                    background-color: #cccccc;
                }
                &.highlight {
                    background-color: #cccccc; 
                }
            }
        }
    }

    &::before{
        content: '';
        height: 180%;
        width: 100%;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3), transparent);
        position: absolute;
        bottom: 0px;
    }

    @include breakpoint(largeDesktop){
        .search--header {
            width: calc(100vw - #{$sidebarWidth});
        }
    }

    // sidepanel always open with centered max width content
    //amb - defect 761
    // @include breakpoint(extraLargeDesktop){
    //     .search--header {
    //         width: calc(#{$maxWidth} - #{$sidebarWidth});
    //     }
    // }
}

.content-main.search {
	.search-results-count {
		width: 100%;
		text-align: center;
	}

    .search-results-count-role {
		width: 100%;
        text-transform: uppercase;
        color: $wim-green;
        text-align: center;
        height: auto;
        font-weight: bolder;
        border-bottom: 1px solid lightgray;
        padding: 6px;
        letter-spacing: 1px;
	}

	.no-results {
		margin-top: 10px;
	}
}



.page.expanded .wrapper {
    .search--header-wrapper {

        @include breakpoint(desktopSidePanel, mid){
            .search--header {
                width: calc(100vw - #{$sidebarWidth});
            }
        }


    }
}

.content-main-role-results {
    min-height: auto !important; // need important to override the content-main setting of min-height (otherwise causes huge unnecessary whitespace, but content-main className is necessary)
}
  
.role-display {
    background-color: white;
    .section-title {
        text-transform: uppercase;
        color: $wim-green;
        text-align: center;
        height: auto;
        font-weight: bolder;
        border-bottom: 1px solid lightgray;
        padding: 6px;
        letter-spacing: 1px;

        margin: 0px 16px 16px 16px; // top right bottom left
        @include breakpoint(tablet){
            margin: 0px 24px 16px 24px; 
        }
        @include breakpoint(desktop){
            margin: 0px 46px 16px 46px;
        }
    }

    .role-display-btn {
        margin-left: 16px;
        @include breakpoint(tablet){
            margin-left: 24px;
        }
        @include breakpoint(desktop){
            margin-left: 46px;
        }
    }
}


