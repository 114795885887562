.BrainhubCarousel__container {
    width: 100%;
    overflow: hidden;
  }
  
  .BrainhubCarousel {
    overflow: hidden;
    display: flex;
    align-items: center;
  
    &.BrainhubCarousel--isRTL {
      direction: rtl;
  
      .BrainhubCarousel__trackContainer {
        .BrainhubCarousel__track {
          direction: rtl;
        }
      }
    }
  
    .BrainhubCarousel__trackContainer {
      overflow: hidden;
      .BrainhubCarousel__track {
        display: flex;
        overflow: hidden;
        list-style: none;
        margin: 0;
        padding: 0;
  
        &.BrainhubCarousel__track--transition {
          transition: transform;
        }
      }
    }
  }
  
  /* arrows */
  .BrainhubCarousel__arrows {
    cursor: pointer;
  }

  .BrainhubCarouselItem {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &.BrainhubCarouselItem--clickable {
      cursor: pointer;
    }
    &.BrainhubCarouselItem--active {
  
    }
    .debug-number {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      font-size: 2em;
      text-shadow: 0px 0px 9px white;
    }
  }
  
/* variables */
$dots-color: black;

.BrainhubCarousel__dots {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;

  &.BrainhubCarousel__dots--isRTL {
    direction: rtl;
  }

  .BrainhubCarousel__dot {
    outline: 0;
    padding: 10px;
    border: none;
    opacity: 0.5;
    font-size: 0;
    cursor: pointer;
    -webkit-appearance: none;

    &.BrainhubCarousel__dot--selected {
      opacity: 1 !important;
    }

    &:hover {
      opacity: 1;
    }

    &:before {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      padding: 0;
      border: none;
      background: $dots-color;
    }
  }

  .BrainhubCarousel__thumbnail {
    outline: 0;
    padding: 10px;
    border: none;
    opacity: 0.5;
    font-size: 0;
    cursor: pointer;

    &.BrainhubCarousel__thumbnail--selected {
      opacity: 1 !important;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.BrainhubCarousel__thumbnail[type=button] {
  -webkit-appearance: none;
}

.BrainhubCarousel--isRTL {
  + .BrainhubCarousel__dots {
    direction: rtl;
  }
}

$clickableAreaSize: 42px;
$clickableAreaColor: #7b59ff;
$clickableAreaColor__hover: lighten(#7b59ff, 3%);
$disabledButtonColor: #cccccc;

$arrowColor: #fff;
$arrowColor__hover: #fff;
$arrowSize: 10px;
$arrowWeight: 3px;

.BrainhubCarousel__arrows {
  position: relative;
  padding:calc($clickableAreaSize/2);
  border: none;
  overflow: hidden;
  outline: 0;
  font-size: 0;
  line-height: 0;
  background-color: $clickableAreaColor;

  span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    border-style: solid;
    border-color: $arrowColor;
    border-width: $arrowWeight $arrowWeight 0 0;
    padding: calc($arrowSize/2);
    transition: 0.3s;
    font-size: 0;
  }

  &:hover {
    background-color: $clickableAreaColor__hover;
    span {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        border-style: solid;
        border-color: $arrowColor;
        border-width: $arrowWeight $arrowWeight 0 0;
        padding: calc($arrowSize/2);
        transition: 0.3s;
        font-size: 0;
    }

    &:enabled {
        background-color: $clickableAreaColor__hover;
        span {
            border-color: $arrowColor__hover;
            margin: 0;
        }
    }
  }
  
  &:disabled {
    background-color: $disabledButtonColor;
  }
}

.BrainhubCarousel__arrowLeft {
  span {
    transform: translate(-50%, -50%) rotate(-135deg);
    margin-left: 0.35 * ($arrowSize - $arrowWeight);
  }
}

.BrainhubCarousel__arrowRight {
  span {
    transform: translate(-50%, -50%) rotate(45deg);
    margin-left: -0.35 * ($arrowSize - $arrowWeight);
  }
}

.BrainhubCarousel--isRTL {
  .BrainhubCarousel__arrowLeft {
    span {
      transform: translate(-50%, -50%) rotate(45deg);
      margin-left: -0.35 * ($arrowSize - $arrowWeight);
    }
  }

  .BrainhubCarousel__custom-arrowLeft {
    span {
      transform: rotate(180deg);
    }
  }

  .BrainhubCarousel__arrowRight {
    span {
      transform: translate(-50%, -50%) rotate(-135deg);
      margin-left: 0.35 * ($arrowSize - $arrowWeight);
    }
  }

  .BrainhubCarousel__custom-arrowRight {
    span {
      transform: rotate(-180deg);
    }
  }

  .BrainhubCarousel__arrows {
    &:hover {
      span {
        margin: 0;
      }
    }
  }
}

.BrainhubCarousel__arrow--disable {
    pointer-events: none;
}
