
.promo-feature-overlay {
    padding: 48px 0px;
    background: url('https://assets.wimbledon.com/images/promo/v2/background_mobile.jpeg') no-repeat 50% 50%;
    @include breakpoint(tablet) {
        background: url('https://assets.wimbledon.com/images/promo/v2/background_tablet.jpeg') no-repeat 50% 50%;
    }
    @include breakpoint(desktop) {
        background: url('https://assets.wimbledon.com/images/promo/v2/background_desktop.jpeg') no-repeat 50% 50%;
    } 
}
.promo-feature-overlay, .modal-content-overlay {
    padding: 32px 0px 0px 0px;
    
    .logotitle {
        display: contents;
        width: 100%;
    }

    .wim-logo {
        margin: auto;
        width: 60px;
        height: 60px;
        @include background-2x('/assets/images/logos/Wimbledon_logo_small', 'png', 60px, 60px, top left );

        @include breakpoint(tablet) {
			margin-top: 0;
			width: 70px;
			height: 70px;
			background-image: url(/assets/images/logos/Wimbledon_logo@2x.png);
			background-size: 70px 70px;
			background-position: top left;
			background-repeat: no-repeat;
		}
    }
}

.onboard-content-container {
    width: 100%;
    margin: 0px;
}

.promo-image {
    max-width: 100%;
    max-height: 100%;
}

.onboard-image-content-container {
    width: 100%;
    margin: 0px;
    vertical-align: middle;
}

.player-profile-promo-img {
    @include breakpoint(tablet) {
        content:url("https://assets.wimbledon.com/images/promo/2024/PlayerProfiles_Tablet.png")
    }
    @include breakpoint(desktop) {
        content:url("https://assets.wimbledon.com/images/promo/2024/PlayerProfiles_Desktop.png")
    }
    content:url("https://assets.wimbledon.com/images/promo/2024/PlayerProfiles_Mobile.png")
}


.catch-me-up-promo-img {
    @include breakpoint(tablet) {
        max-width: 100%;
        content:url("https://assets.wimbledon.com/images/promo/2024/CatchMeUp_Tablet.png")
    }
    @include breakpoint(desktop) {
        content:url("https://assets.wimbledon.com/images/promo/2024/CatchMeUp_Desktop.png")
    }
    max-width: 90%;
    content:url("https://assets.wimbledon.com/images/promo/2024/CatchMeUp_Mobile1.png")
}

.catch-me-up-promo-img2 {
    @include breakpoint(tablet) {
        display: none;
    }
    @media (width: 320px) {
        max-width: 60%;
	}
    max-width: 65%;
    content:url("https://assets.wimbledon.com/images/promo/2024/CatchMeUp_Mobile2.png")
}

.catch-up-promo-watsonX_AI {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    div {
        margin-top: 2px;
    }
    img {
        margin-left: -14px;
    }
}


.promo-feature-container {
    padding: 0px;
    display: table;
    margin: auto;
    margin: 0px;
    width: 100%;
    height: calc(100% - 64px);

    .promo-feature-title-txt {
        background-color: white;
        height: 55px;
        width: 100%;
        display: inline-grid;
        justify-content: center;
        align-items: center;
        margin-top: 24px;
        padding: 8px;
        text-align: center;
        span {
            color: $wim-green;
            font-weight: 600;
            margin-right: 16px;
        }
        @include breakpoint(tablet) {
            display: inline-flex;
            text-align: left;
        }
    }

    @include breakpoint(tablet) {
        // padding: 8px;
        position: sticky;
        width: auto;
        margin: auto;
    }

}

.promo-feature-close-btn {
    float: right;
    color: white;
    position: absolute;
    top: 8px;
    right: 8px;
    filter: brightness(0) invert(1);
}


.promo-feature-mywimbledon {
    height: 70px;
    text-align: center;
    width: 100%;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.528926px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @include breakpoint(tablet) {
        height: 88px;
    }
    span {
        font-style: italic;
        font-family: initial;
        font-weight: 100;
    }
    .promo-feature-mywimbledon-divider {
        width: 32px;
        height: 1px;
        color: white;
        position: absolute;
        bottom: 13px;
        border-bottom: 2px solid white;
        @include breakpoint(tablet) {
            bottom: 16px;
        }
    }
}

.promo-feature-subCustomTitleTxt {
    text-align: center;
    width: 100%;
    font-family: 'Gotham 4r';
    font-size: 22px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 1px;
    text-align: center;
    color: white;

}

.promo-feature-subCustomTxt {
    text-align: center;
    width: 100%;
    color: white;
    font-family: 'Gotham 4r';
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.25px;
    padding-top: 12px;    
}

.promos-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100vw;
}

.catch-me-up-image-container {
    display: flex;
    justify-content: center;
    margin: auto;
    overflow: hidden;
    width: 80vw;
    @media (width: 320px) {
        width: 72vw;
	}
    @include breakpoint(tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 100vw;
    }
}

.promo-feature-next-btn-container {
    width: 100%;
    background-color: white;
    padding: 8px 0px;
}


.promo-feature-box-shadow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -5px 3px -3px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px -5px 3px -3px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px -5px 3px -3px rgba(0,0,0,0.2);
}

.promo-feature-next-btn {
    margin-top: 8px;
    background: $wim-green;
    color: white;
    border-radius: 8px;
    height: 40px;
    border: none;
    font-family: 'Gotham 5r';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.727273px;
    text-transform: uppercase;
    padding: 8px;
    cursor: pointer;
    &:disabled {
        cursor: not-allowed;
        background: $gray_bb;
        border-radius: 8px;
    }
}


.fixed-bottom-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
}