/** player-matchup-overview styles **/
.player-profile {
	.completed-matches-wrapper {
		display: flex;
		flex-wrap: wrap;
		margin: 0 25px;
		justify-content: center;
		@include breakpoint(tablet) {
			justify-content: space-between;
		}
		@include breakpoint(desktop) {
			gap: 2%;
		}
		@include breakpoint(1200px) {
			margin: 0 25px;
			justify-content: flex-start;
			gap: 4%;
		}
		.featuredtable {
			position: relative;
			width: 351px;
			@include breakpoint(tablet) {
				width: calc((95% - 50px) / 2);
			}
			@include breakpoint(desktop) {
				width: 351px;
			}
			@include breakpoint(1200px) {
				width: calc((95% - 36px) / 3);
				max-width: 400px;
			}
			margin: 0;
			.roundinfo,
			.event-title {
				width: 100%;
				text-transform: uppercase;
				text-align: center;
			}
			.view-more-btn {
				margin-top: 20px;
			}
			.player-profile-read-more {
				margin: auto;
			}
			.match-details {
				overflow-y: hidden;
				transition-property: all;
				transition-duration: 0.5s;
				transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
			}

			.matchup-content {
				display: block;
				.match-details {
					width: 100%;
				}
			}

			.match-box {
				.match-location {
					@include gotham(medium);
					color: $wim-black;
					margin-top: 20px;
					margin-bottom: 4px;
				}
			}
			@include breakpoint(desktop) {
				.matchup-content {
					display: flex;
					flex-flow: row;
					justify-content: space-between;
					.match-details {
						width: calc(50% - 20px);
					}
				}
			}

			@include breakpoint(mobile) {
				.matchup-content {
					.match-details {
						.related-cnt {
							.news-tile {
								width: 100%;
								margin: 7px 0 0 0;
							}
						}
					}
				}
			}
		}
	}
}
