/** chinese-scoreboard styles **/

.chinese {
    background:transparent;
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    .site-wrap {width:100%; height:100%;}
    
    .router {width:100%; height:100%;
        > div {
            width:100%; height:100%;
            .page {width:100%; height:100%; display:table;}
        }
    }
    
}
.page-container {
    width:375px;
    height:160px;
    transition:all 10ms ease-in-out !important;
    position: relative;
    top: 0;
    display:table-cell;
    vertical-align: middle;
    //text-align: right;
    margin:"0px auto";
}
#scoreboard-container {
	position: relative;
    width:375px;
    height:160px;
    background: url('/assets/images/zh_CN/scoreboard/background.jpg') no-repeat;
    background-position: 0 bottom;
    background-size: cover;
    border-left:solid 5px $wim-green;
    margin:0px auto;

    #mipFeatured {
        width: 100%;
        height:80%;
        padding: 0px 0px;
        position: relative;
    
        .eventinfo {
            width: 100%;
            height:25%;
            line-height: 2em;
            text-align: center;
            color: #FFFFFF;
            font-weight: bold; 
            text-transform: uppercase;
            //background:rgba(0,0,0,0.7);
            white-space: nowrap;
            overflow:hidden;
            text-overflow: ellipsis;
            padding-left:30px;
            padding-right:5px;
            .titleSup {
                text-transform: none;
            }
        }
        
        
    
        .teaminfo {
            width: 100%;
            height:30%;
            color: $wim-purple;
            display:flex;
            padding:0px 1.4%;
            flex-flow: row nowrap;
            &.teamOne {background:rgba(255,255,255,0.5);}
            &.teamTwo {background:rgba(255,255,255,0.5);}
            .name {
                //color: #FFFFFF;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width:calc(60% - 5px);
                position:relative;
                
                &.singles {
                    line-height:2.4em;
                    
                }
                &.doubles {
                    position:relative;
                    top: 0%;
                    font-size:.8em;
                    line-height:1.5em;
                }
        
                a {
                    //color: #FFFFFF;
                    text-decoration: none;
                }
            }
            .scoreContainer {
                display:flex;
                flex-flow: row nowrap;
                position:relative;
                width:calc(40% + 5px);
                line-height:2.4em;
                .courtIcon {
                    float:left;
                    width: 20%;
                    text-align: center;
                    i {display:none; transform:translateY(65%)}
                    &.currentServer {
                        color:$yellow-tennisball-color;
                        .wim-icon-ballSolid{display:block;} 
                    }
                    &.winner {
                        color:$wim-green;
                        .wim-icon-check{display:block;}
                    }
                }
        
                .pts {
                    float:left;
                    position: relative;
                    width: 20%;
                    //color: #FFFFFF;
                    text-align: center;
                    overflow: hidden;
                }
                .latestScore {
                    border-radius:3px;
                    //border:solid 2px transparent;
                    background:rgba(78, 38, 131, .2);
                    padding:2px 0px;
                    top:2px;
                    height:calc(100% - 4px);
                    .scores {
                        top:-2px;
                        //border-radius:5px;
                        //background:rgba(255,255,255,0.5);
                    }
                }
        
                .set1,.set2,.set3,.set4,.set5 {
                    float:left;
                    position: relative;
                    width: calc(60% / 5);
                    //color: #FFFFFF;
                    text-align: center;
                }
        
                .scores {
                    position: absolute;
                    font-size: 1.3em;
                    left: 0px;
                    width: 100%;
                }
            }
            
        }
    
        .info {
            width:100%;
            display:flex;
            flex-flow: row nowrap;
            clear:both;
            height:15%;
            padding:0px 1.4%;
            span {position:relative; top:0px}
            #status {
                width: calc(60% - 5px);
                margin: 0px;
                text-align: left;
                color: #FFFFFF;
                font-size: .8em;
                text-transform: uppercase;
                position:relative;                
            }
           
            .scoreContainer {
                display:flex;
                flex-flow: row nowrap;
                position:relative;
                width:calc(40% + 5px);
                
                .pts {
                    float:left;
                    width: 40%;
                    color: #FFFFFF;
                    text-align: center;
                    font-size: .9em; 
                    span {width:50%; display:block; float:right;}
                }
                .set1, .set2, .set3, .set4, .set5 {
                    position: relative;
                    float:left;
                    width: calc(60% / 5);
                    color: #FFFFFF;
                    text-align: center;
                    font-size: .9em;
                    padding-top:1%;
                }
            }
            
        }
        sup, sup.alt {
            font-size: .6em;
            color: #FFFFFF;
        }
        
    }
    
    #mipFeaturedNav {
        position: relative;
        z-index: 11;
        width:100%;
        height:20%;
        background:rgba(255,255,255,0);
        display:table;
        table-layout: fixed;
        .nav {
            //width: 80px;
            height: 100%;
            //line-height: 2em;
            margin: 0px auto;
            display:table-row;
        }
        .mip_prev {
            //float: left;
            //width: 10px;
            height: 100%;
            background: url('/assets/images/zh_CN/scoreboard/arrow_left.png') no-repeat 50% 50%;
            background-position: right;
            cursor: pointer;
            display:table-cell;
        }
    
        .counter {
            //float: left;
            width: 60px;
            height: 100%;
            text-align: center;
            font-weight: bold;
            font-size: 1.1em;
            color: #FFFFFF;
            display:table-cell;
            vertical-align: middle;
        }
    
        .mip_next {
            //float: left;
            //width: 10px;
            height: 100%;
            background: url('/assets/images/zh_CN/scoreboard/arrow_right.png') no-repeat 50% 50%;
            background-position:left;
            cursor: pointer;
            display:table-cell;
        }
    }
    
    /** featured match **/
    
    .wimLogo {
        position: absolute;
        top: 3px;
        left: 4px;
        width: 25px;
        height: 25px;
        background-image: url(/assets/images/logos/Wimbledon_logo.png);
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        z-index: 1;
    }
    
    .ibmLogo {
        position: absolute;
        right: 1%;
        bottom: 24%;
        width: 50px;
        height: 17px;
        background-repeat: no-repeat;
        background-image: url('/assets/images/zh_CN/scoreboard/ibmLogo.png');
        background-position: right;
        background-size:contain;
    }
    
    #working {
        float: left;
        width: 325px !important;
        height: 142px !important;
        padding: 9px 25px;
        background: url('/assets/images/zh_CN/scoreboard/message_background.png') no-repeat;
        overflow: hidden;
        position: relative;
        display: none;
        
        #mipMessageDiv {
            color: #ffffff;
            font-size: 2.5em;
            text-align: center;
            padding: 20px;
            display: block;
        }
    }


}



