.homepage-social-media{
    min-height:300px;

    @include breakpoint(tablet,max){
        img{
            bottom: 87px;
            top: unset;
        }
    }

    .homepage-element-info{

        .text{
            @include gotham(medium);
        }

        .wim-icon-twitter,.wim-icon-facebook,.wim-icon-instagram{
            font-size:31px;
        }

        .title{
            text-transform:uppercase;
        }

        .social-media-icons{
            margin-top:10px;
            color:$gray-650;
            font-size:14px;
            align-items: center;
            //display: flex;
            //width: 180px;
            //margin-left: calc(50% - 90px);
            position:relative;
            height:21px;
            width:100%;

            .icon-wrapper{
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                display: flex;
                align-items: center;
            }

            i{
                color:$gray-650;
                display:inline-block;
                margin:0 2px 0 10px;
                align-self:center;
                flex:1 1 0;
            }

            .wim-icon-fb-shares{
                font-size:11px;
            }

            .wim-icon-likes{
                font-size:21px;
                margin-bottom:5px;
            }

            .wim-icon-heart{
                font-size:19px;
            }

            .wim-icon-replies{
                font-size:17px;
            }

            .wim-icon-retweets{
                font-size:16px;
            }
        }

        @include breakpoint(tablet,max){
            height:185px;
            padding-bottom:5px;
            bottom:0px;
            top:unset;
            transform:translateX(-50%);

            .title{
                margin-bottom:5px;
            }

            .text{
                display:inline-block;
                color:$wim-black;
                padding-bottom:15px;
                height:55px;
                overflow:hidden;
            }

            .social-media-icons{
                margin-top:0;
            }
        }
    }
}