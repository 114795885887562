.match-stats{
    @include gotham(medium);

    /*** DIV TableComponent Styling ***/
   .title {
       background-color: $wim-purple;
       font-size:20px;
   }

   .headers {
       color: $wim-purple;
       .cell {
           padding: 5px;
       }
       .data2{
           width:50%;
       }
   }

   .row {
       color: $wim-purple;
       height:31px;
       span.not-better {
           color: $gray-bb;
       } 
       .cell {
            border-top: 1px solid #ECECEC;
       }
       .data1,.data3{
           font-size:16px;
       }

       .data2{
           padding:8px 0;
       }
   }
   .datatable {
       border: 2px solid $wim-purple;
   }

   @mixin one-col-match-stats{
        .data1{
            text-align:left;
            padding-right:0;
        }
        .player1_wrapper{
            font-size:10px;
            width:22%;
        }
        .data3{
            text-align:right;
            padding-left:0;

            span{
                right:5px;
            }
        }
        .player2_wrapper{
            font-size:10px;
            width:22%;
        }
        .metric_wrapper{
            font-size:11px;
            width:55%;
        }
        .headers{
            font-size:16px;
            .data2{
                width:55%;
            }
        }
        .title{
            font-size:18px;
        }

        .data1,.data3{
            position:relative;
           
           span{
               position:absolute;
               top:50%;
               transform:translateY(-50%);
           }
        }
    }
    

   &.two-col,&.three-col,&.four-col{
       @include breakpoint(tablet,max){
           @include one-col-match-stats;
       }
   }

   &.one-col{
        @include one-col-match-stats
    }

   /*** Table Element Styling ***/
   
}
   