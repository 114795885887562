.content-main{
    &.column-layout{
        &.privacy{
            display:flex;
            .privacy-share {
                position:absolute;
                top: calc(#{$item-v-margin} * 2);
                right:$page-margin-large;
                width: 100px;
                text-align: right;
                color:rgba($select-menu-text,.5);
                background:$white;
                @include gotham(medium);
                font-size: 12px;

                @include breakpoint(desktop,max){
                    right:$page-margin-med;
                }

                @include breakpoint(tablet,max){
                    right:$page-margin-small;
                }
        
                .wim-icon-share{
                    margin-right:5px;
                }
        
                .share-popup-wrapper {
                    display: block;
                    position: absolute;
                    width: 156px;
                    top: 25px;
                    right: 0px;
                }
            }
        }
    }
}