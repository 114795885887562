/** Date Divder SCSS **/
.date-divider-container {
    position: relative;
    text-align: center;
    margin: 35px 0;
    @include gotham(medium);
    hr {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 2px;
        margin: 0;
        z-index: -1;
        background: $wim-purple;
        border: none;
        width: 50%;
        &.left-side {
            left: 3%;
        }
        &.right-side {
            right: 3%;
        }
    }
    .date-text {
        display: inline-block;
        background: $white;
        color: $wim-green;
        font-size: 24px;
        padding: 0 10px;
        letter-spacing: 1.5px;
        &:after {
            content: '';
            width: 200px;
            height: 2px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            background: #00703C;
            bottom: -14px;
        }
    }
}