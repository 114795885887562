


.share-popup{
    display:none;
    position:absolute;
    width: inherit;
    height: 88px;
    border: 1px solid $select-menu-border;
    background: $white;
    opacity:1;
    z-index:2;

    &.show {
        display: block;
    }

    .share-box-arrow{
        display: none;
        position:absolute;
        width:10px;
        height:10px;
        border-left:1px solid $select-menu-border;
        border-top:1px solid $select-menu-border;
        background:$white;
        z-index:-1;

        &.top {
            display: block;
            transform: rotate(45deg);
            top: -5px;
            right: 15%;
        }

        &.bottom {
            display: block;
            transform: rotate(225deg);
            bottom: -6px;
            left: 25%;
        }
    }

    .share-icons{
        display:flex;
        flex-wrap:wrap;
        width:100%;
        height:100%;
        text-align:center;
        padding: 5px;
        align-items: center;

        i{
            width: 36px;
            flex: 0 0 auto;
            font-size: 22px;
            color: $black;
            cursor: pointer;
        }
    }
}

.share-bar-icons-wimlink {
    justify-content: left;
    display: flex;
    width: auto;
    flex-flow: row;
    span {
        margin: 0px 11px -4px 10px;
        cursor: pointer;
    }
}

.share-bar {
    width: 100%;
    font-size: 22px;
    &.gallery {
        width: auto;
        position: absolute;
        right: 15px;
        bottom: 130px;
        display: flex;
        .share-bar-icons {
			flex-flow: column;
			
			i {
				margin-bottom: 5px;
			}
        }        
    }
    &.black {
        color: $black;
    }
    &.white {
        color: $white;
    }

    &-icons {
        display: flex;
        justify-content: center;
        width: auto;
        flex-flow: row;

        i {
            flex: 0 1 100%;
            margin: 0 7px 0 7px;
            cursor: pointer;
        }
    }
}

