.user-shadow-container {
    .role-name {
        //border-bottom: 2px solid $wim-green;
        color: $wim-green;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
		margin-top: 20px;
        padding-bottom: 5px;
    }
    .shadow-player {
        margin: 0;
    }

    .player-search {
        display: flex;
        .generic-button_wrapper {
            margin-top: 3px;
        }
    }

    .player-searched {
        .player-searched-container {
            display: flex;
            width: 80%;
            .generic-button_wrapper {
                margin-right: 20px;
            }
        }
        .shadowing-bold {
            font-weight: bold;
        }
        .player-text {
            margin-top: 10px;
            margin-right: 10px;
            width: 300px;
        }
        .shadow-bold {
            font-weight: bold;
        }
        .wim-icon-close {
            cursor: pointer;
            color: $wim-green;
            font-size: 12px;
            margin-top: 4px;
        }
    }
    .search-box.search-box-container {
        margin-right: 10px;
        .react-autosuggest__suggestions-container {
            margin: 0;
            border-radius: 0px;
            width: 100%;
            max-height: 200px;
            overflow: auto;
            border-right: 1px solid $lines-gray;
            border-left: 1px solid $lines-gray;
            border-bottom: 1px solid $lines-gray;
        }    
    }



    .manual-search {
        margin-top: 20px;
        display: flex;
        input {
            width: 300px;
            margin-right: 10px;
            padding-left: 36px;
            &::placeholder {
                color: $wim-black;
            }
            &:hover {
                border: 1px solid #54008B;
                box-shadow: inset 0 0 0 1px #54008b;
            }
        }
        .generic-button_wrapper {
            margin-top: 7px;
        }
    }
}
