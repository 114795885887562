.webview .wrapper.upcoming{
    background: transparent;
}

.upcoming{
    @include breakpoint(mobile) {
        .stub_box {
            top: calc(40% + 90px);;
        }
    }
    .upcoming-nav{
        display: flex;
        justify-content: center;
        .favorites-toggle {
            margin-top: -5px;
            margin-left: 7px;
            &::before{
                font-size: 24px;
            }
        }
    }
    .order-of-play-link{
        display: flex;
        justify-content: center;
        padding-bottom: 13px;
        margin: 0 auto !important;
        a{
            display: flex;
            justify-content: center;
            align-items: center;
            @include gotham(medium);
            width: 288px;
            height: 35px;
            background: rgba(255, 255, 255, 0.25);
            border-radius: 8px;
            font-weight: 500;
            font-size: 14px;
            line-height: 13px;
            text-transform: uppercase;
            color: $white;
            &:hover{
                text-decoration: none;
                border: 1px solid $white;
            }
        }
        .favorites-toggle{
            margin-left: 7px;
            &::before, &::after{
                font-size: 28px;
            }
        }
    }
    .displayDate{
        @include gotham(medium);
        font-weight: 500;
        font-size: 16px;
        line-height: 15px;
        letter-spacing: 0.5px;

        height: 35px;
        width: 100%;
        color: $white;
        background: rgba(255, 255, 255, 0.25);
        display: flex;
        align-items: center;
        justify-content: center;
        .day{
            // this is pretty much just for the webview
            margin-right: 6px;
        }

    }
    .upcoming-court{
        .court-title-divider{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 30px;
            .match-location, .match-time{
                text-align: center;
                color: $white;
            }
            .match-location{
                @include gotham(medium);
                // font-style: normal;
                // font-weight: 500;
                font-size: 20px;
                line-height: 19px;
                letter-spacing: 0.05em;
                margin-bottom: 7px;
                white-space: nowrap;
                width: unset;
            }
            .match-time{
                .time-string{
                    font-size: 16px;
                }
                line-height: 15px;
                @include gotham-book();
                font-weight: 400;
                margin-bottom: 14px;
            }
        }
        .match-card{
            position: relative;
            &:last-child{
                &::after{
                    display: none;
                }
            }
            &::after{
                position: absolute;
                content: " ";
                border-right: 1px solid white;
                height: 50px;
                right: 50%;
                transform: translateX(-50%);
            }
        }
    }
}