

.mobile-web-footer {
    background-color: white;
    position: fixed;
    right: 0px;
    bottom: 0px;
    left: 0px;
    height: 64px;
    z-index: 100;
    justify-content: space-evenly;
    display: flex;
    border-top: 1px solid $wim-green;
}

.web-footer-item {
    text-transform: uppercase;
    color: $wim-green;
    margin-top: auto;
    margin-bottom: auto;
    font-weight: bold;
    font-size: 12px;

    i {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        padding-bottom: 6px;
    }
    a, a.visited, a:hover, a:visited:hover {
        text-decoration: none;
    }
}

.web-footer-item-live-indicator {
    background: $live-green;
    display: inline-block;
    border-radius: 50px;
    margin-right: 2px;
    height: 8px;
    width: 8px;
}
