.match-nav-parent {
    z-index: 2000;
}
.match-nav-wrapper {
    .round-nav {
        margin: 0 auto 10px;
    }
    .matchnav {
        width: 100%;
        height: 54px;
        padding-bottom: 11px;
        border-bottom: 1px solid rgba(255, 255, 255, .4);
        margin-bottom: 12px;
        overflow: visible;

        .match-archive-box {
            &.small {
                width: 240px;
                height: 45px;
                margin: 0 2px;
                display: block;
                overflow: hidden;
                // opacity: .6;

                .match-archive-box-content {
                    .front {
                        padding: 3px;
                        height: 45px;
                        position: relative;

                        .ma-team {
                            width: 35%;
                            height: 100%;
                            flex-direction: column;
                            text-align: center;

                            .name {
                                font-size: 10px;
                                width: 100%;
                                color: #999999;
                            }
                            .photo {
                                opacity: .6;
                                margin-left: 0;
                                margin-right: 0;
                                
                                img {
                                    width: 26px;
                                    height: 26px;
                                }
                            }
                            &.p1, &.p2 {
                                justify-content: center;
                            }
                        }
                        .year {
                            @include gotham(medium);
                            margin: 8px 0 2px 0;
                            font-size: 10px;
                        }
                        .date {
                            @include gotham(medium);
                            font-size: 8px;
                        }
                        .action {
                            display: block;
                            font-size: 14px;
                        }
                        .info {
                            margin-top: -9px;
                        }
                    }
                }
            }
            &.active {
                .match-archive-box-content {
                    .front {
                        .ma-team {
                            width: 44%;

                            &.p1, &.p2 {
                                justify-content: flex-start;
                            }
                            .name { color: #176B3D; }
                            .photo {
                                opacity: 1;
                            }
                        }
                        .info {
                            width: 12%;
                            margin-top: 0;
                        }
                    }
                }
                &:hover {
                    .back {
                        background-color: $white;
                        opacity: 1;
                    }
                }
            }
            &.available {
                .match-archive-box-content {
                    .front {
                        position: relative;

                        .ma-team {
                            width: 44%;
                        }
                        .info {
                            position: absolute;
                            width: 32%;
                            left: 34%;
                            top: 8px;
                            margin-top: 0;
                        }
                    }
                    .back {
                        background-color: $gray-light;
                    }
                }
                &:hover {
                    // opacity: 1;
                    .front, .back {
                        opacity: 1;
                    }
                }
            }
            &:hover {
                text-decoration: none;
            }
            &.current {
                .match-archive-box-content {
                    .front {
                        &:after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 3px;
                            left: 0;
                            bottom: 0;
                            background-color: $live-green;
                        }
                    }
                }
            }
            .back {
                // opacity: 1;
            }
        }
        .matchnav-container {
            width: 100%;
            max-width: 100%;
        }
        .matchnav-arrow {
            width: 26px;
            height: 100%;
            position: absolute;
            top: 0;
            background: transparent;
            border: none;
            z-index: 10;
            padding: 0;
            cursor: pointer;

            &.disabled {
                display: none;
            }
            &.prev-button {
                left: 0px;
                text-align: left;
            }
            &.next-button {
                right: 0px;
                text-align: right;
            }
            i {
                color: $white;
                font-size: 16px;
                position: relative;
                top: 16px;
            }
        }
        // override carousel styles
        .BrainhubCarousel {
            position: relative;
            padding: 0 26px;
            width: 100%;
        }
        .BrainhubCarousel__track {
            align-items: flex-start;
        }
        .BrainhubCarouselItem--active {
            .match-archive-box {
                &.small {
                    // opacity: 1;
                }
            }
        }
    }
    .quote-card-back {
        width: 100%;
        height: 110px;
        padding: 8px;
        text-align: center;
        // background-color: $white;
        font-size: 10px;
        margin-top: 1px;
        color: $wim-black;

        .quote-text {
            @include gotham-book();            
        }
        .quote-credit {
            @include gotham(medium);
        }
    }
    .quote-card {
        &:hover {
            height: 155px !important;

            .quote-card-back {
                display: block;
            }
        }
    }
}