.app-promo-outer-cntr {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90vw;
    padding-top: 16px;
    @include breakpoint(extraSmallTablet) {
        width: 500px;
        padding-top: auto;
    }
}

.app-promo-container {
    text-align: center;
    width: 100%;
    margin-right: 0px !important;
    height: 570px;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0.5px;
    position: relative;
    @include breakpoint(extraSmallTablet) {
        text-align: revert;
        width: 500px;
    }
}

.app-promo-container-border {
    text-align: center;
    width: 100%;
    margin-right: 0px !important;
    height: 570px;
    border: 1px solid #979797;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0.5px;
    position: relative;
    @include breakpoint(extraSmallTablet) {
        text-align: revert;
        width: 500px;
    }
}


.app-promo-txt-popup {
    font-size: 12px;
    text-align: center;
    margin: 8px;
    color: #333333;
    letter-spacing: 0.5px;
    line-height: 23px;
    
    @include breakpoint(extraSmallTablet) {
        display: inline-block;
        position: absolute;
        top: 200px;
        font-size: 16px;
        width: 175px;
        right: 40px;
    }
}

.app-promo-txt {
    font-size: 12px;
    text-align: center;
    margin: 8px;
    color: #333333;
    letter-spacing: 0.5px;
    line-height: 23px;
  
    @include breakpoint(extraSmallTablet) {
        display: inline-block;
        position: absolute;
        top: 200px;
        text-align: center;
        font-size: 16px;
        width: 175px;
        right: 40px;
    }
    @include breakpoint(desktopSidePanel, mid) {
        right: 16px;
    }
    right: 40px;
}

.app-promo-img {
    width: 154px;
    margin-bottom: 8px;
    @include breakpoint(extraSmallTablet) {
        width: 192px;
        margin-bottom: 0px;
        margin-top: 30px;
        margin-left: 40px;
    }
}

.app-promo-background-shade {
    visibility: hidden;
    @include breakpoint(extraSmallTablet) {
        visibility: visible;
        background: url('/assets/images/tickets/appPromoBackgroundShade.jpg') no-repeat;
        height: 240px;
        position: absolute;
        z-index: -1;
        bottom: 0px;
        right: 0px;
        left: 0px;
    }
    
}

.app-promo-apple-download-popup {
    text-align: center;
    @include breakpoint(extraSmallTablet) {
        display: inline-block;
        position: absolute;
        top: 370px;        
        right: 40px;
        width: 175px;
    }
}

.app-promo-apple-download {
    text-align: center;
    @include breakpoint(extraSmallTablet) {
        display: inline-block;
        position: absolute;
        top: 370px;
        right: 40px;
        width: 175px;
    }
    @include breakpoint(desktopSidePanel, mid) {
        right: 16px;
    }
    right: 40px;
}


.app-promo-android-download-popup {
    text-align: center;
    @include breakpoint(extraSmallTablet) {
        display: inline-block;
        position: absolute;
        top: 430px;
        right: 40px;
        width: 175px;
    }
}

.app-promo-android-download {
    text-align: center;
    @include breakpoint(extraSmallTablet) {
        display: inline-block;
        position: absolute;
        top: 430px;
        right: 40px;
        width: 175px;
    }
    @include breakpoint(desktopSidePanel, mid) {
        right: 16px;
    }
    right: 40px;
}

.app-link {
	text-align: center;
	 @include breakpoint(extraSmallTablet) {
        display: inline-block;
		position: absolute;
		top: 370px;
		right: 40px;
		width: 175px;
    }
    @include breakpoint(desktopSidePanel, mid) {
        right: 16px;
    }
	
}