label{    
    select, .react-select__control{
        position:relative;
        @include gotham-book;
        -webkit-appearance: none;
        -moz-appearance:none;
        border: 1px solid $lines-gray;
        height:40px;
        width:100%;
        margin: 0;
        background:transparent;
        margin-top:5px;
        font-size:16px;
        padding-left:10px;
        z-index:2;
        border-radius: 0;
    }
    select::-ms-expand {
        display: none;
    }
    .wim-icon-down-arrow {
        position:absolute;
        right:13px;
        top:43px;
        transform:translateY(-50%);
        font-size:7px;
        z-index:0;
    }
    select:hover, .react-select__control:hover{
        border: 1px solid $wim-text-purple;
        box-shadow: inset 0 0 0 1px $wim-text-purple;
    }
    select:disabled{
        background-color: #d8d8d8;
    }
}

label{
    .react-select-container {
        color: $black;
        text-transform: none;

        .react-select__control--is-focused {
            border: 1px solid $wim-text-purple;
            border-bottom: none;
            box-shadow: inset 0 0 0 1px $wim-text-purple;
        }
        input {
            margin-top: 0;
        }
        .react-select__value-container {
            padding: 0;
        }
        .react-select__placeholder {
            margin: 0;
        }
        .css-b8ldur-Input {
            margin: 0;
            padding: 0;
        }
        .react-select__indicators {
            display: none;
        }
        .react-select__menu {
            margin-top: 0;
        }
        .react-select__menu-list {
            border: 1px solid $wim-text-purple;
            box-shadow: inset 0 0 0 1px $wim-text-purple;
            @include gotham-book;
        }
    }
}