.oidc_message {
	margin-top: 20px;
	color: $gray;
}

.sso_message {
	margin-top: 20px;
	color: $gray;
}

.myw-shelf {
	flex: 1 0 120px;
	padding: 0 10px 0 10px;

	margin-bottom: 10px;
	@include breakpoint(mobile, min) {
		flex: 0 0 150px;
	}
}

.services-list {
	display: flex;
	border: 1px solid;
}
