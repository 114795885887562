/** atoznav styles **/
#atoznav {
    clear: both;
    a {text-decoration: none;}
    text-align: center;
    margin-top: 0px;
    .dropdown_nav{
        width: 70%;
        height: 30px;
        color: $black;
        margin: 0px auto 10px auto;
        vertical-align: middle;
        border-radius: 4px;
        background-color: $white;
    }
    .text_nav {
        display: none;
    }
    .animation-container {
        overflow-y: scroll;
        margin: 0;
        transform: none;
        width: 100%;
    }
    .select-menu-dropdown {
        width: 100%;
        padding-left: 10px !important;
    }
    .select-menu-dropdown button {
        background-color: $white;
    }
    .select-menu .title {
        background-color: $white;
    }
}
@include breakpoint(tablet) {
    #atoznav {
        .dropdown_nav {
            max-width: 330px;
        }
    }
}
@include breakpoint(desktop) {
    #atoznav {
        .dropdown_nav {
            display: none;
        }
        .text_nav {
            display: block;
            background-color: #fff;
            clear: both;
            position: relative;
            width: 100%;
            text-align: center;
            left: 0;
            right: 0;
            top: 0;
            margin-top: 0px;
            margin-bottom: 0px;
            padding: 10px 0 15px 0;

            ul {
                margin: 0;
                padding: 0;
            
                li {
                    display: inline-block;
                }
            }
            a {
                padding: 0 5px 5px;
                display: block;
                color: #9b9b9b;
                font-size:16px;
                //@include gotham_medium;
                border-bottom: 3px solid #d4d4d4;
            
                &.selected, &:hover {
                    color: $link-green;
                    border-bottom-color: $link-green;
                }
            }
            &.fix-nav {
                position: absolute;
                z-index: 2;
            }
        }
    }
}


