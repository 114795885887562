@mixin aspect-ratio($width, $height) {
	position: relative;
	&:before {
	  display: block;
	  content: "";
	  width: 100%;
	  padding-top: calc(($height / $width) * 100%);
	}
	> .player-image {
	  position: absolute;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
	}
  }


/** player-general-info styles **/
.player-gen-info-da {
    display: flex;
    flex-flow: wrap;
    // margin: 16px 10px 10px 10px !important;
    // margin-top: 16px !important;
    border-bottom: 1px solid $gray-bb; 
    .general-info-item {
		position: relative;
		width: calc(50% - 20px);
        margin: 0px;
        //amb - defect 618
		// @include breakpoint(tablet){
  //           margin: 10px 10px 10px 10px;
  //       }

		.player-img-container {
			position: absolute;
			width: 100%;
			@include aspect-ratio(1, 1);
            @include breakpoint(tablet, max){ 
                margin-bottom: 25px;
            }
			.player-image {
				border: 10px solid transparent;
			}
		}

        .mobile-hide {
            .general-info-field {
				text-transform: uppercase;
				white-space: nowrap;
				width: 100px;
            }
            .general-info-value {
                font-size: 15px;
                height: 30px;
                width: 70px;
                text-align: center;
                margin-right: 5px; 
            }
		}    
		    
        .mobile-display {
            display:none;
		}
		
        .favorite {
			position: absolute;
			top: -10px;
			cursor: pointer;
			z-index: 2;
            @include breakpoint(tablet, max){
                left: 10px; 
            }
			
            &:after {
                position: absolute;
                left: 0;
                content: "\2606";
                font-size: 33px;
                color: $wim-green;
			}
			
            &.favorited {
                &:after {
                    content: "\2605";
                    font-size: 33px;
                    color: #F9E300;
                }
            }
        }
    }

    .player-flag {
        float: left;
        margin-right: 10px;
    }

    .player-name {
        color: $wim-green;
        font-size: 18px;
        margin-top: 4px;
    }
    
    .general-info {
        margin-top: 20px;
        font-size: 14px;
        div {
            // height: 30px;
            margin-bottom: 10px;
        }
    }
    
    .general-info-field {
        color: $wim-black;
        width: 75px;
		display: inline-block;
		white-space: nowrap;
    }
    
    .general-info-value {
        display: inline-block;
		color: $wim-purple;
		font-size: 12px;
    }

    @media only screen and (max-width: $column-break-1) {
        // flex-flow: column;
		border-bottom : none;
		
        .general-info-item {
            margin-bottom: 0px;
            border-bottom: 1px solid $gray-bb;
            width: 100%;
            span.favorite {
                &::after {
                    top: -10px;
                    left:-15px;
                }
            }

            .mobile-hide {
                display: none;
            }
            
            .player-img-container {
                float: left;
                width: 50%;
				//padding-left: 20px;
				
                .player-image {
                    //width: 150px;
					//height: 150px;
					//padding: 10px;
                }
            }

            .mobile-display {
                display: block !important;
                .player-flag {
                    float:none;
                }
                .player-name {
                    margin: 10px 0 10px 0;
                    
                }
                .ranking {
                    width: 50px;
                    font-size: 22px;
                    text-transform: uppercase;
                }

            }
        }
    }
}
