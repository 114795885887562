.round-nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    margin-bottom: 25px;
    margin-left: auto !important;
    margin-right: auto !important;

    .round-nav-link {
        @include gotham-book;
        width: calc(100%/7);
        font-size: 12px;
        color: $white;
        letter-spacing: 1.69px;
        text-align: center;
        line-height: 13px;
        padding-bottom: 11px;
        border-bottom: 1px solid rgba(216, 216, 216, .4);
        cursor: pointer;

        &.selected {
            @include gotham(bold);
            // padding-bottom: 1px;
            // border-bottom: 4px solid $white;
            position: relative;

            &:after {
                content: '';
                background-color: $white;
                width: 100%; 
                height: 3px;
                bottom: -2px;
                position: absolute;
                left: 0;
            }
        }
        &.current {
            @include gotham(bold);
            color: $live-green;
        }
    }
}