.insights-index, .insights-style{
    .idx-header{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .filters{
            display: flex;
            right: 0;
            @include breakpoint(tablet){
                .search-box{
                    width: 478px;
                }
                .players-container{
                    margin-right: 0px;
                }
            }
            @include breakpoint(tablet, max){
                .search-box{
                    width: 320px;
                }
            }
        }
        .daynav{
            margin: 11px auto;
            width: 350px;

            @include breakpoint(tablet){
                width: 512px;
            }

            .select-menu-dropdown.mobile{
                top: 0 !important;
                max-height: unset !important;
            }
        }
        .idx-header-inner{
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 25px;
            .headtext{            
                // font-family: IBMPlexSans;
                font-size: 24px;
                color: #FFFFFF;
                position: relative;

                @include breakpoint(smallTablet) {
                    font-size: 28px;
                }

                .info{
                    display: inline-block;
                    font-size: initial;
                    cursor: pointer;
                    padding-left: 5px;
                    font-size: 16px;
                }
            }
            .spacerLine{
                width: 22px;
                border-top: 2px solid white;
            }
            .subtext{
                max-width: 390px;
                color: #FFFFFF;
            }
        }
        .match-type-select{
            color: white;
            button{
                background: none;
                border: none;
                color: white;
                text-transform: uppercase;
                cursor: pointer;
                &.selected{
                    font-weight: bold;
                }
            }
        }
    }
    .insights-cards{
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        @include breakpoint(tablet, max){
            justify-content: center;
        }
        .no-matches, .no-matches-error{
            color: white;
            text-align: center;
            text-transform: uppercase;
        }
        a{
            text-decoration: none;
        }
        .match-card{
            margin-bottom: 14px;
            margin-right: 7px;
            margin-left: 7px;
            
            @include breakpoint(mobile, max){
                margin-right: 0px;
                margin-left: 0px;
            }
            &.no-margin{
                margin-right: 0px;
                margin-left: 0px;
            }
            .generic-button_wrapper {
                width: calc(100% - 12px);
                margin-bottom: 0;
                margin-left: auto;
                margin-right: auto;
            }
            &.doubles{
                .LikelihoodToWinBanner .likelihood-win-btn{
                    margin-bottom: 7px;
                }
            }

            .teams, .doubles-team-names{
                .generic-button{
                    padding: 0;
                    height: unset;
                    line-height: unset;
                    margin: 0 auto;
                    display: block;
                    min-width: unset;
                    width: unset;
                    font-family: unset;
                    font-size: unset;
                    &:active{
                        background-color: transparent;
                    }
                    .content{
                        color: unset;
                        text-transform: unset;
                        text-align: unset;
                        white-space: unset;
                        padding: unset;
                    }
                    .border{
                        display: none;
                    }
                }
            }

            .doubles-team-names{
                .generic-button{
                    margin: 0;
                }
            }

            .card-cta{
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin-top: 5px;
                margin-left: 5px;
                margin-right: 5px;
                text-transform: uppercase;

                .border {
                    border-radius: 7px;
                }
            }
            .card{
                background-color: white;
                border-radius: 7px;
                width: 405px;
                height: 245px;                
                padding-top: 10px;
                @include breakpoint(desktop, max){
                    width: 354px;
                }
                &.no-margin{
                    margin-right: 0px;
                }
                .card-head{
                    text-align: center;
                    margin-bottom: 7px;
                    color: #176B3D;

                    .courtName{
                        @include gotham(medium);
                        text-transform: uppercase;
                        font-size: 14px;
                        line-height: 13.4px;
                        @include gotham(medium);
                        margin-bottom: 5px;
                    }
                    .eventName{
                        text-transform: uppercase;
                        font-size: 12px;
                        line-height: 14.4px;
                        letter-spacing: 0.86px;
                    }
                }
                .teams{
                    width: calc(100% - 12px);
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 7px;
                    margin-left: auto;
                    margin-right: auto;
                    
                    .team1-wrapper {
                        align-items: flex-start;

                        .generic-button_wrapper {
                            .generic-button {
                                margin-left: unset;
                            }
                        }
                    }
                    .team2-wrapper {
                        align-items: flex-end;

                        .generic-button_wrapper {
                            .generic-button {
                                margin-right: unset;
                            }
                        }
                    }
                    .team-wrapper{
                        width: 108px;
                        position: relative;
                        z-index: 1;
                        display: flex;
                        flex-direction: column;
                        .player-img-container{
                            width: 70px;
                            height: 70px;
                            margin: auto;
                        }
                        &.team2 {
                            // margin-right: 7px;
                        }
                        .generic-button_wrapper {
                            width: 100%;
                        }
                    }
                    .v{
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                        padding-top: 7px;
                        position: relative;
                        &.doubles{
                            // align-items: center;
                            padding-top: 3px;
                        }
                        max-width: 144px;
                        @include breakpoint(desktop, max){
                            max-width: 114px;
                        }
                    }
                    &.doubles{
                        margin-bottom: 0px;
                        .team1-wrapper, .team2-wrapper{
                            align-items: center;

                            .team-wrapper {
                                width: 78px;
                                .player-img-container{
                                    width: 35px;
                                    height: 35px;
                                    margin-bottom: 2px
                                }
                                .generic-button_wrapper {
                                    .generic-button {
                                        margin-right: auto;
                                        margin-left: auto;
                                    }
                                }
                            }
                        }
                    }                                 
                }
                .doubles-team-names{
                    display: flex;
                    justify-content: space-between;
                    width: calc(100% - 12px);
                    margin: 2px auto 5px;
                    .team-name{
                        .team1, .team2{
                            width: unset;
                        }
                        .team1{
                            margin-left: 0px;
                        }
                        .team2{
                            margin-right: 0px;
                        }
                    }
                }
                .team-name{
                    display: flex;

                    &.t1{
                        justify-content: flex-start;
                        .playerName{
                            // padding-left: 7px;
                        }
                    }
                    &.t2{
                        justify-content: flex-end;
                        text-align: right;
                    }
                    .team1, .team2{
                        @include gotham-book;
                        display: flex;
                        // justify-content: center;
                        align-items: center;
                        // width: 165px;
                        // width: 90px;
                        height: 25px;
                        .name{
                            max-width: 160px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            padding: 2px 5px;
                        }
                        &.doubles{
                            height: unset;
                        }
                        overflow: visible;
                       
                        &.yellow{
                            background-color: #FDEC2D;
                        }
                        &.purple{
                            background-color: #54008B;
                            .name{
                                color: white !important;
                            }
                        }
                    }
                    .team1{
                        .player-flag{
                            margin-left: -8px;
                        }
                        .playerName {
                            &.doubles {
                                align-items: flex-start;
                            }
                        }
                    }
                    .team2{
                        .name{
                            // margin-right: 5px;
                        }
                        .playerName {
                            &.doubles {
                                align-items: flex-end;
                            }
                        }
                    }
                    .playerName{
                        display: flex;
                        justify-content: center;
                        font-size: 12px;
                        // text-transform: uppercase;
                        color: #00703C;
                        letter-spacing: 0;
                        position: relative;        
                        .flag{
                            width: 17px;
                            height: 10px;
                            display: inline-block;
                            margin-right: 4px;
                            img{
                                width: 100%;
                            }
                        }
                        .name{
                            white-space: nowrap;
                        }
                        &.doubles{
                            display: flex;
                            flex-direction: column;

                            .generic-button_wrapper {
                                width: unset;
                                margin: unset;

                                .generic-button {
                                    margin-right: unset;
                                }
                            }
                            .name{
                                padding: 2px 5px;

                                &.yellow{
                                    background-color: #FDEC2D;
                                }
                                &.purple{
                                    background-color: #54008B;
                                    color: white !important;
                                }
                            }
                        }                
                        .seed{
                            position: absolute;
                            bottom: 0; 
                            margin-left: 3px;
                            font-size: 10px;
                        }
                        .player-flag{
                            img{
                                width: 17px;
                            }
                            margin-right: 9px;
                        }
                    }
                }    
                .button-wrapper{
                    // padding: 0 7px 0 6px;
                }
            }
        }
    }
}

.insights-style{
    .insights-cards{
        .match-card{
            .card{
                height: unset;
                padding-bottom: 5px;;
            }
        }
    }
}

/** webview override */
.webview {
    .insights-index {
        .insights-cards {
            .match-card {
                .card {
                    min-height: 245px;
                    height: auto;
                }
            }
        }

        input {
            color: $white;

            &::placeholder {
                color: $white;
            }

        }

        .wim-icon-search {
            color: $white;
        }

        ::placeholder {
            color: $white;
        }
    }
}