/**Fullscreen check for **/
section.wrapper{
    &.fullwindow {
        .image-gallery-slide.center {
            transform: unset !important;
        }
    }
} 
.homepage-favorites {    
    min-height: 240px;
    .loading {
        height: 204px;
        position: relative;
    }

    .login-text {
        text-decoration: underline;
        cursor: pointer;
    }

    .myWimbledon {
        margin-left: 5px;
    }

    .highlight-content-placeholder {
        // height: 150px;
        min-height:204px;
        .no-content-text {
            color: $wim-green;
            text-align: center;
        }
    }

    .myWimbledon {
        
    }
    .highlights-container {
        justify-content: space-evenly;
        padding-top: 13px !important;
        .news-title {
            font-size: 16px;
            line-height: 16px;
            white-space: break-spaces;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .news-timestamp {
            line-height: 12px;
            font-size: 12px;
        }

        .news-tile {
            .new-tag {
                padding-top: 7px !important;
            }
            .image-container{
                padding-bottom: 56.66%;
            }
            max-width: 300px;
            height: auto;
            width: 212px;
            // width: calc(100% - 75px);
            // @include breakpoint(tablet) {
            //     width: calc(35% - 125px);
            // }            
            span.favorite {
                display: none;
            }
        }
    }
    .favorite {
        position: absolute;
        display: block;
        top: 15px;
        // left: calc(50% - 87px);
        left: -15px;
        cursor: pointer;
        z-index: 2;
        &:after {
            position: absolute;
            left: 0;
            content: "\2606";
            font-size: 30px;
            color: $wim-purple;
        }
        &.favorited:after {
            content: "\2605";
        }
    }
    .slamtracker-stats.white{
        border-radius: 0;
        .content{
            line-height: unset;
        }
    }
    .generic-button_wrapper  {
        // text-align: center;
        .generic-button {
            font-size: 12px;
            height: 30px;
            width: 180px;
            .content{
                padding: 5px 8px;
            }
        }
        .disabled {
            cursor: default;
            &:active {
                background-color: #fff;
                .content {
                    color: $wim-green;
                }
                .border {
                    border: 1px solid $wim-green;
                }
            }
            
            .border:hover {
                border: 1px solid $wim-green;
            }
            .border {
                cursor: default;
            }
        }
        .compact {
            line-height: 20px;
            background-color: $white;
            width: 215px !important;
            .content {
                color: $wim-green;
                font-weight: 100;
            }
            // .border {
            //     display: unset;
            // }
        }
    }
    .power-ranking-placeholder {
        // visibility: hidden;
        // color: #888888;
        color: black; // needs to be a darker color for ADA requirements
    }
    .player-ranking {
        display: flex;
        // justify-content: center;
        color: $wim-purple;
        font-weight: bold;

        a {
            display:flex; 
            color:$wim-purple; 
            text-decoration: none;
        }
        .powerrank-movement {
            align-items: initial;
            .rank {
                font-size: 14px;
            }
            .movement {
                font-size: 14px;
                i {
                    line-height: 12px;
                    font-size: 9px;
                }
				//display: none;
            }
        }
    }
    .homepage-favorites-text {
        display: flex;
        .page-nav-container {
            .nav-container {
                border-bottom: unset;
                .select-menu {
                    margin-bottom: 20px;
                    .title {
                        padding-left: 0px;
                        padding-right: 0px;
                        color: $wim-purple;
                    }
                }
                .page-nav-list {
                    width: 70%;
                    margin-top: 5px;
                    // margin-bottom: 20px;
                    padding-left: 0px;
                    &.center {
                        justify-content: center;
                    }
                    .page-nav-items {
                        color: $wim-purple;
                        &.active {
                            border-bottom: 2px solid $wim-purple;
                        }
                    }
                }
            }
            flex: 1 0 100%;
            @include breakpoint(tablet) {
                flex: 0 0 100%;
            }
            
            justify-content: center;
        }
        a.set-favorites {
            flex: 0 1 10%;
            position: relative;
            .set-favorites-container {
                position: absolute;
                top: 15px;
                right: 40px;
                @include breakpoint(tablet) {
                    right: -27px;
                    top: 0px;
                    .wim-icon-gear {
                        font-size: 23px;
                    }
                    .set-favorites {
                        font-size: 10px;
                    }
                }
                @include breakpoint(desktop) {
                    top: 0px;
                    right: 0px;
                    .set-favorites {
                        font-size: 12px;
                    }
                }
            }
        }
        .homepage-favorites-title {
            text-align: center;
            margin-bottom: 10px;
            text-transform: uppercase;
            color: $wim-purple;
            font-size: 20px;
            margin-bottom: 20px;
            position: relative;
            @include breakpoint(tablet, min) {
                &:after {
                    content: '';
                    position: absolute;
                    height: 1.75px;
                    width: 40px;
                    background: $wim-purple;
                    bottom: -9px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
        .set-favorites-container {
            cursor: pointer;
            position: absolute;
            right: 25px;
            top: 12px;
            width: 130px;
            i {
                font-size: 22px;
                @include breakpoint(desktop) {
                    font-size: 25px;
                }

                color: $wim-green;
            }
            .set-favorites {
                width: 65%;
                display: inline-block;
                text-transform: uppercase;
                font-size: 12px;
                padding-left: 5px;
                color: $wim-green;
            }
        }
    
    }

    .carousel-wrapper {
        // padding-top: 4px;
        min-height:204px;
        position: relative;
		width: 90%;
    	left: 5%;

        .nav-wrapper {
            top: 35%;
            transform: translateY(-50%);
            .arrow-wrapper-left, .arrow-wrapper-right {
                color: $gray-disable;
                display: block !important;
                position: absolute;
                width: 100px;
                height: 50px;
                i {
                    font-size: 42px;
                }
            }
            .arrow-wrapper-left {
                left: -30px;
            }
            .arrow-wrapper-right {
                right: -30px;
            }            
        }
        .image-gallery {
            overflow: hidden;
        }
    }

    .homepage-favorite-container {
        width: 90%;
        margin: auto;
        display: flex;
        justify-content: space-around;
        flex-grow: 1;
        padding-top:20px;
        padding-bottom: 30px;
        .fav-player-container {
            width: 100%;
            display: flex;
            justify-content: center;
            // &.no-status {
            //     display: unset;
            // }
            position: relative;
            // @include breakpoint(tablet) {
            //     width: calc(33% - 10px);
            // }
            white-space: pre-wrap;
            .player-info-container{
                min-height: 129px;
            }
            .reason {
                color: $gray;
                font-size: 14px;
                text-transform: uppercase;
                line-height: 14px;
                margin-bottom: 8px;
                text-align: left;
                &.no-data {
                    visibility: hidden;
                }
            }
            .player-info, .player-match-status, .player-oponent, .event-name, .round-name {
                margin-bottom: 5px;
                // text-align: center;
                text-align: left;
                font-size: 12px;
                line-height: normal;
            }
            .player-oponent {
                color: $wim-purple;
                text-transform: uppercase;
                span.versus-text {
                    text-transform: lowercase;
                }
            }
            .player-img-link{
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;
            }
            .player-info {
                color: $wim-green;
                text-transform: uppercase;
				margin-bottom: 5px;
				line-height: 19px;
                .player-name {
                    margin-bottom: 5px;
                }
                .power-ranking-stripe {
                    width: 17px;
                    margin-top: 8px;
                    height: 2px;
                    border-style: none;
                    background-color: $wim-purple;
                    margin-left: 0px;
                    &.hidden {
                        visibility: hidden;
                    }
                }
            }
            .player-match-status {
                color: $wim-purple;
            }
            .generic-button_wrapper{
                margin-bottom: 0px;
            }

            .player-info {
                i.wim-icon-check {
                    color: $wim-green;
                }
            }
            .player-img-container {
                margin-right: 10px;
                cursor: pointer;
                .player-image{
                    &.small {
                        width: 80px;
                        height: 80px;
                    }
                }
                img {
                    // width: 38%;
                    height: auto;
                    display: block;
                    margin: auto;
                    max-width: 130px;
                }
            }
        }
        &.default {
            &.mobile {
                a {
                    width: 80%;
                    .fav-player-container {
                        width: 100%;
                    }
                }
            }
            a {
                width: 25%;
                .fav-player-container {
                    width: 100%;
                }
            }
        }
    }

    @include breakpoint(tablet) {        
        .homepage-favorites-text {
            .homepage-favorites-title {
                font-size: 24px;
            }
            .set-favorites-container {
                top: 10px;
                i {
                    font-size: 32px;
                    color: $wim-green;
                }
            }
        }
    }
    @media only screen and (max-width: 510px) {
        .set-favorites-container {
            text-align: right !important;
            .set-favorites {
                display: none !important;
            }
        }
    }
}