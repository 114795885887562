/** scorebox-grid styles **/
.column-layout.scoresgrid {

	.one-col {
        width: calc(100% - #{$item-h-margin} * 2);
    }

	// 768 and larger
    @include breakpoint(tablet, min){
        .one-col {
            width: calc(50% - #{$item-h-margin} * 2);
        }
	}

	@include breakpoint(desktop, min) {
		padding: 0 20px;

        .one-col {
            width: calc(33.3% - #{$item-h-margin} * 2);
        }
    }
}