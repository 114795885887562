// In the Media section
.mi-section {
    .column-layout {
        &.mi-media {
             flex-wrap: wrap;

            .col {
                    width: 100%;
                    padding-left: 30px;
                    padding-right: 30px;
                    
                    &:first-of-type {
                        border-right: none;
                        padding-bottom: 40px;
                    }

                    &:last-of-type {
                        padding-left: 40px;
                    }

                    @at-root .slamtrackerPanel #{&}{
                        width: 100% !important;
                        padding-left: 30px !important;
                        padding-right: 30px !important;
                        
                        &:first-of-type {
                            border-right: none !important;
                            padding-bottom: 40px !important;
                        }

                        &:last-of-type {
                            padding-left: 40px !important;
                        }
                    }

                    @include breakpoint(tablet) {

                        &:first-of-type {
                            // padding-right: 20px;
                            padding-bottom: 10px;
                            border-bottom: none;
                            border-right: solid 1px $gray_9b;
                            width: 50%;
                        }
    
                        &:last-of-type {
                            // padding-left: 20px;
                            padding-bottom: 10px;
                            width: 50%;
                        }
                    }
                }
            }
    }
}

.mi-media {
    .player-name {
        @include gotham();
        color: $wim-green;
        font-size: px2rem(12);
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        padding-bottom: 10px;

        @include breakpoint(tablet) {
            display: none;
        }
    }

    .slick-slider {
        .slick-arrow{
            font-family: "Icons" !important;
            &.slick-prev {
                left: -35px;
                height: 100%;
                background: rgba(255,255,255,.75);
                z-index: 1;
                &::before{
                    content: "\e928";
                    color: #A1A1A1;
                    height: 100%;
                }
            }
            &.slick-next{
                right: -20px;
                height: 100%;
                background: rgba(255,255,255,.75);
                &::before{
                    content: "\e929";
                    color: #A1A1A1;
                    height: 100%;
                }
            }
            &.slick-disabled {
                &:hover {
                    cursor: default;
                }
            }
        }

        .slick-dots {
            bottom: -10px;

            li {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                border: solid 1px $gray-dark;
                background: $white;

                button {
                    width: 100%;
                    height: 100%;
                    margin-top: -1px;

                    &:before {
                        content: '';
                        width: 100%;
                        height: 100%;
                    }
                }
                &.slick-active {
                    width: 8px;
                    height: 8px;
                    background: $gray-dark;

                    button {
                        margin-top: 0;
                    }
                }
            }
        }

        // make the slider dots fixed to the bottom
        @include breakpoint(tablet) {
            height:100%;
            .slick-list, .slick-track, .slick-slide {
                height:100%;
            }

            .slick-dots {
                bottom:-15px;
            }
        }
    }
}

.mi-media-content {
    width: 100%;
    // font-size: 16px;
    position: relative;

    @include gotham(medium);
    font-size: px2rem(14);
    font-style: italic;

    @at-root .slamtrackerPanel #{&}{
        font-size: px2rem(14) !important;
    }

    @include breakpoint(tablet) {
        font-size: px2rem(18);
    }
    
    .media-item {
        margin-bottom: 15px;
    }
    .media-item-text {
        line-height: px2rem(22);
        @at-root .slamtrackerPanel #{&}{
            line-height: px2rem(22) !important;
        }
        @include breakpoint(tablet) {
            line-height: px2rem(30);
        }
    }
    .media-item-host, .media-item-year {
        @include gotham();
        font-size: px2rem(12);
        font-style: normal;
        color: $wim-text-purple;
    }

    .media-item-year {
        div {
            display: inline;
        }
    }

    .media-item-info {
        color: $md-gray;
        font-size: 14px;
    }

    .media-item-link {
        color: $wim-text-purple;
        font-size: 15px;
        margin-left: 8px;

        a {
            text-decoration: none;
            color: $wim-text-purple;
    
            &:hover {
                color: $wim-green;
            }
        }
    }
}

// slamtracker styles
.slamtracker {
    .mi-media-content {
        font-size: px2rem(12);
        line-height: 22px;
    }
}

// webview override for slider height
.webview {
    .mi-media {
        .slick-slider {
            @include breakpoint(tablet) {
                height: auto;

                .slick-list, .slick-track, .slick-slide {
                    height:auto;
                }
            }
        }
    }
}