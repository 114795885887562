.order-of-play-heading{
    @include gotham(medium);
    .match-date{
        color: $wim-green;
        text-transform: uppercase;
        text-align: center;
        font-size:15px
    }
    .match-revised{
        color:$gray-650;
        text-transform:uppercase;
        text-align:center;
        font-size:10px;
    }
    .sidepanel-column.upcoming & {
        display:none;
    }

    .sidepanel-column.schedule .match-information span & {
        display:none;
    }
    .sidepanel-column.schedule .match-information span:first-child & {
        display:block;
    }
}