
.debenture-content {
	//min-height: 157px;
}

.gray-line {
	height: 1px;
	background: $lines-gray;
	width: 100%;
}

.debenture-cms {
	// min-height: 157px;
	margin-bottom: 20px;
	
	
    
	
    .item {
        width: 100%;
		position: relative;
		margin-bottom: 15px;
		
        &.title {
            .debenture-row-title {
				position: relative;
				
                .welcome-text {
                    font-weight: bold;
                }
                
            }
		}
		
		@include breakpoint(tablet) {
			width: 25%;
			// margin-bottom: 0px;

			&.title {
				width: 100%;
				margin-bottom: 10px;
			}
		}

		@include breakpoint(desktop) {
			width: 16.6%;

			&.title {
				width: 16.6%;
				margin-bottom: 0px;

				&.full-width {
					width: 100%;

					.debenture-row-title {
						margin: 0 auto 15px;
					}
				}
			}
		}
	}
	
	.full-width {
		width: 100%;
		margin-bottom: 10px;
		text-align: center;

		a {
			display: inline-block;
		}
	}

	.bold-text {
		font-weight: bold;
	}
}

