.reservation-element {
	.modal-overlay {
		overflow-y: scroll;
	}

	.menus {
		background-color: rgb(246, 246, 246);
		padding: 16px;
		margin: 20px auto;
	}
	.menu-text {
		text-align: center;
		color: $green2;
		margin-top: 16px;
		font-weight: bold;
	}
	.text {
		text-align: center;
		margin-bottom: 16px;
	}
	.search-btn {
		margin-top: 18px;
		align-self: flex-end;
		width: 140px;
		margin-left: auto;
	}
	.reservation-submit-btn {
		width: 140px;
	}
	.notes {
		width: 100%;
		height: 150px;
		padding: 4px 8px;
		@include gotham();
	}
	.times-wrapper {
		display: flex;
		list-style: none;
		justify-content: center;
		margin-bottom: 16px;
		flex-wrap: wrap;
		row-gap: 16px;

		.time-box {
			border: 2px solid $green2;
			padding: 4px;
			width: 58px;
			text-align: center;
			margin: 0 10px;
			cursor: pointer;
			.time {
				color: $green2;
				font-weight: bolder;
			}
		}
		.inverted-time-box {
			border: 2px solid $green2;
			cursor: pointer;
			padding: 4px;
			width: 58px;
			text-align: center;
			background-color: $green2;
			margin: 0 10px;

			.inverted-time {
				color: #ffffff;
				font-weight: bolder;
			}
		}
	}
	.reservation-title {
		text-align: center;
		color: #54008b;
		margin-bottom: 5px;
		font-weight: bolder;
		margin-top: 20px;
		text-transform: uppercase;
		p {
			margin: 8px;
		}
	}
	.reservation-description {
		text-align: center;
	}
	.dining-reservation-wrapper {
		display: flex;
		flex-wrap: wrap;
		.entry-wrapper {
			display: flex;
			flex-direction: column;
			height: 60px;
			.generic-button_wrapper {
				margin-bottom: 0px;
			}
			.input-wrapper {
				position: relative;
				width: 100%;
				margin: 0 auto;
				height: 42px;
				.input-icon {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: 10px;
					z-index: 2;
				}
				.react-datepicker {
					height: 42px;
					&-wrapper {
						width: 100%;

						height: 100%;
					}
					&__month-container {
						background-color: white;
						border: 1px solid $gray;
					}
					&__input-container {
						width: 100%;
						height: 100%;
						margin-top: 0;
						input {
							margin-top: 0;
							height: 42px;
							font-weight: bold;
							font-size: 14px;
							color: $green2;
							font-family: inherit;
						}
					}

					// &-ignore-onclickoutside {
					//   maregin
					// }
				}
			}
		}
	}

	.unavailable {
		text-align: center;
		line-height: 200px;
	}

	.dining-reservation-link {
		text-align: center;
		text-transform: uppercase;
		margin-top: 16px;
		font-weight: bold;
	}
}

.booking-confirmation {
	@media only screen and (max-width: 768px) {
		font-size: 80%;
	}
	&__header {
		&--title {
			font-size: 20px;
			color: #54008b;
			font-weight: 700;
			margin-top: 24px;
		}

		&--purple-line {
			width: 25px;
			height: 2px;
			background-color: #54008b;
			margin: 16px auto;
		}

		&--sub-text {
			font-size: 16px;
			margin-bottom: 20px;
			margin-top: 16px;
			p {
				font-weight: bold;
			}
		}
	}

	&__details {
		display: flex;
		border: 1px solid #e1e1e1;
		border-radius: 2px;
		justify-content: space-between;
		padding: 16px;
		@media (max-width: 768px) {
			flex-direction: column;
			align-items: flex-start;
		}
		p {
			&:not(:first-child) {
				@media (max-width: 768px) {
					margin-top: 10px;
				}
			}
			font-size: 14px;
		}

		&--shift {
			color: #00703c;
			font-weight: bold;
		}
	}

	&__notes {
		text-align: left;
		margin-top: 20px;
		margin-bottom: 20px;
		textarea {
			width: 100%;
			border: 1px solid #e1e1e1;
			resize: none;
			height: 130px;
			margin-top: 4px;
			padding: 4px 8px;
			font-family: gotham();
		}
	}

	&__further-info {
		p {
			margin-bottom: 20px;
			word-break: break-word;
			width: 100%;
			display: inline-block;
			span {
				color: #00703c;
				font-weight: bold;
			}
		}
	}

	&__buttons {
		display: flex;
		justify-content: center;
		&__initial-confirmation-buttons {
			float: left;
		}
		@media only screen and (max-width: 900px) {
			flex-direction: column;
		}
		.generic-button_wrapper {
			@media only screen and (max-width: 900px) {
				width: 100%;
			}
			.generic-button {
				@media only screen and (max-width: 900px) {
					width: 100%;

					margin-bottom: 10px;
				}
			}
			&:not(:first-child) {
				margin-left: 10px;

				@media only screen and (max-width: 900px) {
					margin-left: 0;
				}
			}
		}
	}

	&__viewAll {
		text-decoration: none;
		text-transform: uppercase;
		font-weight: bold;
		color: white !important; // override <a/> wimlink style setting it to green
		background: #00703C;
		text-align: center;
		position: relative;
		white-space: nowrap;
		padding: 8px 12px 0 12px;
		width: 100%;
		height: 35px;
		line-height: 20px;
		font-size: 16px;
		@include breakpoint(tablet) { 
			margin-left: 8px;
		}
	}
}

.dining-reservation {
	&__select-menu {
		#select-menu {
			.scores-select-menu {
				.select-menu {
					width: 100%;
					button.title{
						text-align: left;
					}
					.menu-arrow{
						right: 5px;
					}
					.select-menu-dropdown{
						padding-left: 18px !important;
						border-radius: 0;
					}
					.animation-container {
						overflow-y: scroll;
					}
					.animation-container button.clicked i{
						left: -12px;
					}
				}
			}
		}
	}
}
