/** watch and read styles **/
.watchread {
    .match-box {
        .match-location {
            color: #333333;
            text-align: center;
        }
    }
    .autoupdate-message {
        text-align: center;
    }
}
@media only screen and (max-width: $column-break-1) {
    .wachread {
        .player-img-container {
            display: none;
        }
    }
}