/** global-header styles **/
a.skip-main {
    left:-999px;
    position:absolute;
    top:0;
    width:1px;
    height:1px;
    overflow:hidden;
    z-index:-999;
}
a.skip-main:focus, a.skip-main:active {
    color: $white;
    background-color:$wim-green;
    left: 0;
    top: 0;
    width: 180px;
    height: auto;
    overflow:auto;
    margin: 5px;
    padding:5px;
    text-align:center;
    font-size:px2rem(14);
    z-index:999;
}
.header-global {
    min-width: 320px;
    font-size: 12px;
    margin: 0;
    padding: 0;
    top: 0px;
    left: 0px;
    z-index: 20;
    display: block;
	position: fixed;
	// to move pribacy banner above page
	//position: relative;
    width: inherit;
    height: $headerHeightSmall;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), 35%, transparent);
    text-shadow: $textShadow;

    &.home24{
        background: white;
        text-shadow: none;
        color: black;
        height: 82px;
        &.roleNavShown{
            top: 42px !important;
            &.scrolled{
                top: 0px !important;
            }
        }
    
        .mainnav {
            &[display-state=visible]{
                .wim-icon-hamburger, .burger-menu-label{
                    display: none;
                }
            }
            button {
                i, div{
                    color: black;
                }
            }
        }
        .logotitle{
            .logotitle-titles{
                color: black;
            }
        }
        .logosearch .login .name a, .logosearch a *{
            color: black;
        }
        .login .name .text{
            .wim-icon-userAvatar {
                @include breakpoint(tablet, max){
                    display: none;
                }
                font-size: 24px;
                position: absolute;
                left: -35px;
                top: -1px;
                color: $wim-green;
            }
        }
    }

    //shadow in header
    .shadow-title {
        margin-right: 10px;
		font-weight: bold;
    }
    .shadow-header {
        width: 25%;
        text-align: left;
        background-color: $live-green;
        min-width: 300px;
        color: $wim-black;
        font-size: 16px;
        display: flex;
        padding: 7px;
        z-index: 9999999999;
        position: fixed;
		text-shadow: none;
     
    }
    &.tgc {
        // background: unset;
    }

	&.player-profile {
		background-image: url(/assets/images/player-profile/playerProfile_header.jpg);
		background-size: cover;
        @media only screen and (max-width: 615px) {
            background-size: 615px;
        }
	}
    

    @include breakpoint(tablet) {
        height: $headerHeightLarge;
    }

    .logotitle {
        width: 100%;
        z-index: 100;
        margin: 5px 0 0 ;


        @include breakpoint(tablet) {
            margin: 17px 0 0 0;
		}
		
		&.left {
			a {
				left: 64px;
				position: relative;
				float: left;
			}

			.logotitle-txt {
				@include clearfix;
				position: relative;
				float: left;
				clear: both;
			}
		}
    }

    &.fixed {
        //border-bottom: 1px solid $white;
    }

    &.fixedBak .logotitle {
        margin: 6px 0 0 68px;

        @include breakpoint(tablet) {
            margin: 6px 0 0 68px;
        }
    }

    .logotitle-txt {
        display: none;

        &.show {
			display: block;
            position: relative;
            top: -12px;
            margin: auto;
            width: 200px;
            @include breakpoint(tablet) {
				top: 0px;
            }
        }
    }

    .title-txt {
        display: none;

        &.show {
            display: block;
            //defect 615 - amb
            // @include breakpoint(tablet, max) {
            //     display: none;
            // }
        }
    }

    // sidepanel always open with centered max width content
    @include breakpoint(extraLargeDesktop){
        margin-left: calc((100vw - #{$maxWidth}) / 2);
    }

}

/** to prevent header shows up above the Help Modal
    for AI Catch Up on home page, lower z-index */
body.modal-on-noscroll-nofixed {
    .header-global {
        z-index: 2;
    }
}

.page.compressed,
.page-expanded {
    .wrapper {
        .page-header {
            &.player-profile {
                .backgroundImage {
                    width: calc(100% - #{$sidebarWidth});
                }
            }
        }
    }
}

.page {
    .wrapper {
        &.fullwindow {
            .header-global, .mainnav, .page-header, .drawsArchiveHdrContent {
                visibility: hidden;
            }
		}
		&.fullwindowheader {
            .header-global, .mainnav, .page-header {
                visibility: hidden;
			}
			.content-filters.home {
				visibility: hidden;
			}
			.content-main, .video-wrapper {
				visibility: hidden;
			}

			.video-wrapper-header {
				.video-wrapper {
					visibility: visible;
				}
			}
        }

        .page-header {

            //******************************************
            // default page-header settings
            //  - used by default for ContentPage items
            //  - header images, header video
            //******************************************
            position: relative;

            &.player-profile {
				//z-index: 10;
                .backgroundSpace{
                    max-height: 430px !important;
                    @include breakpoint(extraLargeMobile) {
                        max-height: 475px !important;
                    }
                    @include breakpoint(tablet) {
                        max-height: 545px !important;
                    }
                    // @include breakpoint(desktop) {
                    //     max-height: 545px !important;
                    // }
                }
				.backgroundSpace {
					img{
						//width: auto;
						//height: 1006px;
					}
				}
				.backgroundImage {
                    &.mobile {
                        img{
                        @media only screen and (max-width: 615px) {
                            margin-left: 0;
                        }
                    }
					
						//width: auto;
						//height: 1006px;
					}
				}
            }

            .backgroundSpace, .backgroundImage {
                max-height: unset;
                border-bottom: 1px solid white;

                @include breakpoint(desktop){
                    max-height: calc(100vw * #{$contentHeaderAspect});
                }

                @include breakpoint(largeDesktop){
                    width: calc(100vw - #{$sidebarWidth});
                    max-height: calc((100vw - #{$sidebarWidth}) * #{$contentHeaderAspect});
                }

                // sidepanel always open with centered max width content
                @include breakpoint(extraLargeDesktop){
                    width: calc(#{$maxWidth} - #{$sidebarWidth});
                    max-height: calc((#{$maxWidth} - #{$sidebarWidth}) * #{$contentHeaderAspect});
                }
            }

            .backgroundSpace {
                width: inherit;
                min-width: 615px;
                min-height: $headerHeightSmall;

                @include breakpoint(tablet){
                    min-height: $headerHeightLarge;
                }
                @include breakpoint(extraLargeDesktop){
                    width: calc(#{$maxWidth} - #{$sidebarWidth});
                    max-height: 100vh;
                }

                img {
                    width: 100%;
                    height: auto;
                    visibility: hidden;
                    display: block;
                }
            }

            &.home24{
                border-bottom: 1px solid #D8D8D8;
                .backgroundImage{
                    display: none;
                }
            }

            .backgroundImage {
                width: 100%;
                min-width: 615px;
                position: fixed;
                top: 0;
                overflow: hidden;

                // for centering bg image
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 50% 0;

                // sidepanel always open
                @include breakpoint(largeDesktop){
                    width: calc(100vw - #{$sidebarWidth});
                }
                // sidepanel always open with centered max width content
                @include breakpoint(extraLargeDesktop){
                    width: calc(#{$maxWidth} - #{$sidebarWidth});
                    max-height: 100vh;
                }

                img {
                    width: 100%;
                    height: auto;
                    display: block;

                    @include breakpoint(tablet){
                        width: 100%;
                        height: auto;
                        margin-left: 0px;
                    }

                    // below minimum width of image, start moving image to neg left margin
                    //  minimum of 615 for minimum 328 height of largest image
                    @media only screen and (max-width: 615px) {
                        margin-left: calc((100vw - 615px) / 2);
                    }
                }
			}

			.backgroundImage {
				&.video {
					video {
						height: 100%;
						width: auto;
						margin-left: 0;
						position: absolute;
						top: 0px;
						left: 50%;
						transform: translateX(-50%);
						z-index: 2;
                        @at-root .compressed #{&}{
                            left: 0px;
                            transform: none;
                        }

						&.fit-width {
							width: 100%;
							height: auto;
						}
						&.fit-height {
							width: auto;
							height: 100vh;
						}
					}
				}
			}

            //******************************************
            // noscroll specific settings
            //  - used for default (no scroll) header
            //******************************************
            &.noscroll {
                .backgroundSpace, .backgroundImage {
                    height: $headerHeightSmall;
                    min-width: unset;
                    z-index: 10;
                    @include breakpoint(tablet){
                        height: $headerHeightLarge;
                    }
                }
            }

            //******************************************
            // adjustments for fixed
            //******************************************
            &.fixed {
                .backgroundImage {
                    overflow: hidden;
                    height: $headerHeightSmall;
                    //transition: height 1.5s ease;  can't get to work in news style header (change to bg image)
                    z-index: 5;

                    .border {
                        position: absolute;
                        //top: calc(#{$headerHeightSmall} - 1px);
                        bottom: 0px;
                        height: 1px;
                        background-color: $white;
                        width: 100%;
						display: none;
                    }

                    @include breakpoint(tablet){
                        overflow: hidden;
                        height: $headerHeightLarge;
                    }
                }

            }

            // //******************************************
            // // news index page
            // //  - adds video
            // //  - can use default?
            // //******************************************
            // &.news {
            //     .backgroundImage {
            //         img, video {
            //             width: 100%;
            //             height: auto;
            //             display: block;

            //             @include breakpoint(tablet){
            //                 width: 100%;
            //                 height: auto;
            //                 margin-left: 0px;
            //             }

            //             // below minimum width of image, start moving image to neg left margin
            //             //  minimum of 615 for minimum 328 height of largest image
            //             @media only screen and (max-width: 615px) {
            //                 margin-left: calc((100vw - 615px) / 2);
            //             }
            //         }

            //         video {
            //             position: absolute;
            //             top: 0px;
            //             left: 50%;
            //             transform: translateX(-50%);
            //             z-index: 2;
            //         }

            //         img.curtain {
            //             filter: blur(5px);

            //             //image needs to be in div with this after if want to apply by class?
            //             // &::after{
            //             //     content: '';
            //             //     height: 100%;
            //             //     width: 100%;
            //             //     background-color:  rgba(0, 0, 0, 0.4);
            //             //     position: absolute;
            //             //     top: 0px;
            //             // }
            //         }

            //     }

            //     &.fit-width {
            //         .backgroundSpace, .backgroundImage {
            //             video {
            //                 width: 100%;
            //                 height: auto;
            //             }
            //         }
            //     }

            //     &.fit-height {
            //         .backgroundSpace, .backgroundImage {
            //             video {
            //                 width: auto;
            //                 height: 100%;
            //             }
            //         }
            //     }
            // }

            //******************************************
            // news archive index page
            //  - maintain min height of news archive header as resize down
            //******************************************
            &.newsArchive {
                @include breakpoint(largeDesktop){
                    width: calc(100vw - #{$sidebarWidth});
                }

                .backgroundSpace, .backgroundImage {
                    min-width: 1346px;
                }

                .backgroundImage {
                    img {
                        width: 100%;

                        // below minimum width of image, start moving image to neg left margin
                        //  minimum of 1346 for minimum 301 height of largest image
                        @media only screen and (max-width: 1346px) {
                            margin-left: calc((100vw - 1346px) / 2);
                        }
                    }
                }

                .backgroundImage::after {
                    position: absolute;
                    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);
                    height: 100%;
                    width: 100%;
                    content: '';
                    top: 0px;
                }

                &::after{
                    content: '';
                    height: 100%;
                    width: 100%;
                    //background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);
                    position: absolute;
                    top: 0px;
                }
            }



            //******************************************
            // draws archive specific settubg
            //  - similar to news archive
            //******************************************
            &.drawsArchive {
                &.noscroll {
                    .backgroundSpace, .backgroundImage {

                        height: 219px;

                        @include breakpoint(tablet){
                            //min-width: 1346px;
                        }
                        z-index: 10;


                    }
                }

                .backgroundImage::after {
                    // position: absolute;
                    // background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);
                    // height: 100%;
                    // width: 100%;
                    // content: '';
                    // top: 0px;
                }

            }

            //******************************************
            // homepage specific settubg
            //  - maintain min height of weather header as resize down
            //  - 1575 translates to height of 352
            //  - 1346 (image width) translates to height of 301
            //******************************************
            &.weather {
                @include breakpoint(largeDesktop){
                    width: calc(100vw - #{$sidebarWidth});
                }

                .backgroundSpace, .backgroundImage {
                    min-width: 1575px;

                    @include breakpoint(tablet){
                        min-width: 1346px;
                    }
                }

                .backgroundImage {
                    img {
                        width: 100%;

                        // below minimum width of image, start moving image to neg left margin
                        //  minimum of 1346 for minimum 301 height of largest image
                        @media only screen and (max-width: 1346px) {
                            margin-left: calc((100vw - 1346px) / 2);
                        }
                    }
                }



                &::after{
                    // content: '';
                    // height: 100%;
                    // width: 100%;
                    // background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 80%, transparent);
                    // position: absolute;
                    // top: 0px;
                }
            }

            //******************************************
            // scores specific settings
            //
            //******************************************
            &.scores, &.live_comment {
                .backgroundSpace {
                    //max-height: unset;
                    border-bottom: 0;
                }

                .backgroundImage {
                    z-index: 15;
                    overflow: hidden;
                    height: $headerHeightSmall;
                    max-height: none;
                    border-bottom: 0;

					span {
						width: 100vw;
						height: 100vh;
						border-bottom: 0;
						background-size: cover;
						background-image: url(https://assets.wimbledon.com/images/backgrounds/scores.jpg);
						background-position: 0 0;
						background-repeat: no-repeat;
						position: absolute;

						 @include breakpoint(largeDesktop){
                            width: calc(100vw - #{$sidebarWidth});
                        }

                        // sidepanel always open with centered max width content
                        @include breakpoint(extraLargeDesktop){
                            width: calc(#{$maxWidth} - #{$sidebarWidth});
                        }

						img {
							display: none;
						}
					}
					

					&.scrolled {
						z-index: 17;
					}

                    .bg-holder {
                        width: 100%;
                        height: 100vh;
                        background-size: cover;
                        @include background-2x('https://assets.wimbledon.com/images/backgrounds/scores', 'jpg', auto, auto, 0 0);
                        background-size: cover;

                        @include breakpoint(largeDesktop){
                            width: calc(100vw - #{$sidebarWidth});
                        }

                        // sidepanel always open with centered max width content
                        @include breakpoint(extraLargeDesktop){
                            width: calc(#{$maxWidth} - #{$sidebarWidth});
                        }
                    }

                    @include breakpoint(tablet){
                        height: $headerHeightLarge;
                    }
                }
			}
			
			

            //******************************************
            // schedule specific settings
            //
            //******************************************
            &.schedule {
                .backgroundSpace {
                    border-bottom: 0;
                }

                .backgroundImage {
                    z-index: 15;
                    overflow: hidden;
                    height: $headerHeightSmall;
                    max-height: none;
					border-bottom: 0;
					
					&.scrolled {
						z-index: 17;
					}

                    .bg-holder {
                        width: 100%;
                        height: 100vh;
                        background-size: cover;
                        @include background-2x('https://assets.wimbledon.com/images/backgrounds/schedule', 'jpg', auto, auto, 0 0);
                        background-size: cover;

                        @include breakpoint(largeDesktop){
                            width: calc(100vw - #{$sidebarWidth});
                        }

                        // sidepanel always open with centered max width content
                        @include breakpoint(extraLargeDesktop){
                            width: calc(#{$maxWidth} - #{$sidebarWidth});
                        }
                    }

                    @include breakpoint(tablet){
                        height: $headerHeightLarge;
                    }
                }
            }

            //******************************************
            // powerindex specific settings
            //
            //******************************************
            &.powerindex {
                .backgroundSpace {
                    border-bottom: 0;
                }

                .backgroundImage {
                    z-index: 15;
                    overflow: hidden;
                    height: $headerHeightSmall;
                    max-height: none;
					border-bottom: 0;
					
					&.scrolled {
						z-index: 17;
					}

                    .bg-holder {
                        width: 100%;
                        height: 100vh;
                        background-size: cover;
                        @include background-2x('https://assets.wimbledon.com/images/backgrounds/schedule', 'jpg', auto, auto, 0 0);
                        background-size: cover;

                        @include breakpoint(largeDesktop){
                            width: calc(100vw - #{$sidebarWidth});
                        }

                        // sidepanel always open with centered max width content
                        @include breakpoint(extraLargeDesktop){
                            width: calc(#{$maxWidth} - #{$sidebarWidth});
                        }
                    }

                    @include breakpoint(tablet){
                        height: $headerHeightLarge;
                    }
                }
            }

            //******************************************
            // match-insights settings
            //
            //******************************************
            &.match-insights {
                .backgroundSpace {
                    border-bottom: 0;
                    min-height: 227px;

                    @include breakpoint(tablet) {
                        min-height: 300px;
                    }

                    @include breakpoint(desktop) {
                        min-height: 344px;
                    }
                }

                .backgroundImage {
                    z-index: 15;
                    overflow: hidden;
                    height: $headerHeightSmall;
                    max-height: none;
					border-bottom: 0;
					
					&.scrolled {
						z-index: 17;
					}

                    .bg-holder {
                        width: 100%;
                        height: 100vh;
                        background-size: cover;
                        @include background-2x('https://assets.wimbledon.com/images/backgrounds/scores', 'jpg', auto, auto, 0 0);
                        background-size: cover;

                        @include breakpoint(largeDesktop){
                            width: calc(100vw - #{$sidebarWidth});
                        }

                        // sidepanel always open with centered max width content
                        @include breakpoint(extraLargeDesktop){
                            width: calc(#{$maxWidth} - #{$sidebarWidth});
                        }
                    }

                    @include breakpoint(tablet){
                        height: $headerHeightLarge;
                    }
                }
            }

            //******************************************
            // search specific settings
            //
            //******************************************
            &.search {
                &.fixed {
                    .backgroundImage {
                        //clip-path: rect(0px, calc(#{$maxWidth} - #{$sidebarWidth}), 190px, 0px);
                        overflow: hidden;
                        height: 190px;
                        //transition: height 1.5s ease;  can't get to work in news style header (change to bg image)
                        z-index: 5;

                        .border {
                            top: calc(190px - 1px);
                        }

                        @include breakpoint(tablet){
                            //clip-path: rect(0px, calc(#{$maxWidth} - #{$sidebarWidth}), 190px, 0px);
                            overflow: hidden;
                            height: 190px;
                        }
                    }
				}

				.backgroundSpace {
					min-width: 1050px;
				}

				.backgroundImage {
					overflow: hidden;
					//height: 190px;

					min-width: 1050px;
					margin-left: calc((100vw - 1050px) / 2);

					@include breakpoint(tablet){
						//clip-path: rect(0px, calc(#{$maxWidth} - #{$sidebarWidth}), 190px, 0px);
						overflow: hidden;
						//height: 190px;

						//min-width: unset;
						// margin-left: unset;
						margin-left: auto;
					}
				}
            }


			//******************************************
            // score style bg with override for particular image
            //
            //******************************************
			&.scoresStyle  {
                .backgroundSpace {
                    border-bottom: 0;
					max-height: 106px;
                }

                .backgroundImage {
                    z-index: 15;
                    overflow: hidden;
                    height: $headerHeightSmall;
                    max-height: none;
					border-bottom: 0;
					
					&.scrolled {
						z-index: 17;
					}

                    .bg-holder {
                        width: 100%;
                        height: 100vh;

                        @include breakpoint(largeDesktop){
                            width: calc(100vw - #{$sidebarWidth});
                        }

                        // sidepanel always open with centered max width content
                        @include breakpoint(extraLargeDesktop){
                            width: calc(#{$maxWidth} - #{$sidebarWidth});
                        }
                    }

                    @include breakpoint(tablet){
                        height: $headerHeightLarge;
                    }
                }
			}

			//******************************************
            // player index style
            //
            //******************************************
			&.players  {
                .backgroundSpace {
                    border-bottom: 0;
					max-height: 106px;
                }

                .backgroundImage {
                    z-index: 15;
                    overflow: hidden;
                    height: $headerHeightSmall;
                    max-height: none;
					border-bottom: 0;


                    @include breakpoint(tablet){
                        height: $headerHeightLarge;
                    }
                }
			}
			
			&.scoresStyle.match-archive {
				.backgroundImage {
					.bg-holder {
						@include background-2x('https://assets.wimbledon.com/images/backgrounds/matcharchive', 'jpg', auto, auto, 0 0);
						background-size: cover;
					}
				}
			}
			&.scoresStyle.match-archive-landing {
				.backgroundImage {
					.bg-holder {
						@include background-2x('https://assets.wimbledon.com/images/backgrounds/matcharchive_landing', 'jpg', auto, auto, 0 0);
						background-size: cover;
					}
				}
			}
        }
    }

    //******************************************
    // expanded adjustments
    //******************************************
    &.expanded .wrapper .page-header{
        .backgroundSpace, .backgroundImage {
            width: 100vw;
            max-height: unset;

            @include contentMainWidth();

            @include breakpoint(desktop){
                max-height: calc(100vw * #{$contentHeaderAspect});
            }

            @include breakpoint(desktopSidePanel, mid){
                max-height: calc((100vw - #{$sidebarWidth}) * #{$contentHeaderAspect});
            }

            @include breakpoint(largeDesktop){
                max-height: calc((100vw - #{$sidebarWidth}) * #{$contentHeaderAspect});
            }

            @include breakpoint(extraLargeDesktop){
                max-height: calc((#{$maxWidth} - #{$sidebarWidth}) * #{$contentHeaderAspect});
            }
        }
    }

    //******************************************
    // expanded adjustments for scores, schedule, home
    //******************************************
    &.expanded .wrapper {
		.page-header.home {
            .backgroundSpace, .backgroundImage {

                @include breakpoint(desktop){
                    max-height: unset;
                }

                @include breakpoint(desktopSidePanel, mid){
                    img{
                        width: calc(100vw - #{$sidebarWidth});
                    }
                }

                @include breakpoint(largeDesktop){
                    img{
                        width: calc(100vw - #{$sidebarWidth});
                    }
                }

                // sidepanel always open with centered max width content
                @include breakpoint(extraLargeDesktop){
                    img{
                        width: calc(#{$maxWidth} - #{$sidebarWidth});
                    }
                }
            }
        }

        .page-header.player-profile {
            .backgroundSpace {
                @include breakpoint(desktopSidePanel) {
                    height: 440px;
                }
            }
        }

		.page-header.schedule, 
		.page-header.scores, 
		.page-header.scoresStyle,
		.page-header.players 
		{
			.backgroundSpace, .backgroundImage {
				max-height: 106px;
				@include breakpoint(desktop){
                    max-height: 106px;
                }
			}

		}
    }
}



.logosearch {
	position: absolute;
    z-index: 100;
    top: 0px;
	right: 0;

    height: 100%;
    display: flex;
    align-items: center;
	
    text-align: right;
    margin: 0 15px 0 0;
    color: $white;
    z-index: 120;

    .black {
        color: black;
    }

    i {
        font-size: 20px;
        margin-right: 10px;
    }

    a * {
        color: $white;
    }
    a.myWimbledonLink, a.myWimbledonLink:visited {
        &:hover, &:active {
            text-decoration: none;
            border-bottom: 1px solid $white;
        }
    }
    a.white {
        &:hover {
            text-decoration: none;
        }
    }
    .logo-small{
		display: inline-block;

        .ibm-logo {
            height: 15px;
            width: auto;
            border-style: none;
        }
    }
    .logo-standard{
		display: none;

        .ibm-logo {
            height: 23px;
            width: auto;
            border-style: none;
        }
    }

    @include breakpoint(tablet, min, true) {
        margin: 0 49px 0 0;

        i {
            font-size: 27px;
            margin-right: 17px;
        }

        .logo-small{ display: none; }
        .logo-standard{ display: inline-block; }

        .header-global.fixedBak & {
            //margin: 23px 49px 0 0;
            transition: all .1s ease;
        }
	}
	

	.login {
		position: relative;
		float: left;

		@include breakpoint(tablet){
			margin: 3px 0 0 0;
		}
		@media only screen and (max-width: 375px){
			display: none;
		}
        
        @at-root .compressed #{&}{
            @media only screen and (max-width: 845px){
                display: none;
            }
        } 

        .name {
            @include gotham-book();
            font-size: 12px;
            letter-spacing: 1px;
            color: $white;
            float: right;
            right: 0px;
			white-space: nowrap;
			cursor: pointer;

			position: relative;
			display: inline-block;
			
			-webkit-user-select: none;  /* Chrome all / Safari all */
			-moz-user-select: none;     /* Firefox all */
			-ms-user-select: none;      /* IE 10+ */
			user-select: none; 

            @include breakpoint(tablet){
                font-size: 14px;
			}

			.vertical_divide {
				width: 1px;
				height: 20px;
				background-color: $lines-gray;
				display: inline-block;
				margin: 0 12px 0 10px;
			}
	
			.text {
				margin-top: 3px;
				float: left;

				a {
					color: $white;
				}
			}

			.tip {
				width: 125px;
				height: 50px;
				position: absolute;
				right: -14px;
				top: 21px;
				margin: -5px;	
				visibility: hidden;
				cursor: initial;

				font-size: 12px;
				letter-spacing: 1px;
				color: $wim-purple;
				text-shadow: none;
			
				.logoutWrapper {
					position: absolute;
					width: 100%;
					height: 100%;
					background-color: $white;
					border-radius: 3px;
					top: 10px;
					padding-top: 4px;
					border: 1px solid $lines-gray;
					cursor: initial;
					
					&::after {
						content: "";
						position: absolute;
						bottom: 100%;
						left: 50%;
						margin-left: -5px;
						border-width: 5px;
						border-style: solid;
						border-color:  transparent transparent #fff transparent;
					}

					hr {
						width: 32px;
						border: none;
						height: 1px;
						background-color: $wim-purple;
						margin: 6px auto 3px auto;
					}

					.mywim-link {
						text-align: left;
                        .mywim-span,
						a,
                        a:hover,
                        a:active,
                        a:visited {
							color: $wim-purple;
						}
					}

					div {
						margin-left: 5px;
					}
				}

				.logoutButton {
					
					/* float: left; */
					right: 0px;
					white-space: nowrap;
					cursor: pointer;
					text-align: left;
					line-height: 18px;
		
					@include breakpoint(tablet){
						font-size: 12px;
					}
					
					cursor: pointer;

					
				}

				
			}
			
			&:hover {
				.tip{
					visibility: visible;
				}
			}
		}
	

		
    }

}