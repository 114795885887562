/** mobile-apps styles **/
.mobile-promos {
    margin-left: 15%;
	.promo {
        .copy { margin-bottom:20px; }
        display: block;;
		ul li {list-style: disc;}
		h2 {padding: 0; margin: 0 0 25px 0; text-transform: uppercase; font-weight: normal; font-size: 22px; }
		@include breakpoint(desktop,medium) {
			float: left;
			width: 50%;
		}
		@include breakpoint(desktop,min) {
			float: left;
			width: 33%;
		}
		.image-wrapper img { width: 100%;}
		&.iphone {
            padding-top: 30px;
			// margin-left: 15%;
			.image-wrapper { width: 54%; max-width: 143px; }
		}
		&.android {
			padding-top: 30px;
			// margin-left: 15%;

			h2 {
				white-space: normal;
			}

			@include breakpoint(max) {
				padding-top: 0px;
				margin-left: 5%;
				h2 {
					white-space: nowrap;
				}
			}

			.image-wrapper {
				width: 54%; max-width: 143px;
			}
        }
        
		&.ipad .image-wrapper { width: 90%; max-width: 351px;
			@include breakpoint(medium) {
				padding-bottom: 30px;
			}
		}
	}
}