#schedule {
    &.webview {
        background: transparent;
		&.debug {
			background-color: #CCCCCC;
		}

        .schedule-info {
            text-align: center;
            margin: 10px auto;
            position: initial;
        }
        .schedule-court {
            border-top: none;
            border-bottom: none;

            .courtName {                
                border-top: 2px solid $gray_bb;
                padding-top: 15px;
                padding-bottom: 15px;
                
                .localTime {
                    border-left: 1px solid #CCCCCC;
                }
                .time-display {
                    position: relative;
                    display: inline-block;
                    padding:  0px 20px 0px 3px;

                    .suffix {
                        position: absolute;
                        width: 25px;
                        line-height:.7em;

                        * { float: left; }
                    }
                }
                .meridiem, .timezone {
                    font-size: 7px;
                }
                .timezone {
                    clear: left;
                }
            }
            .match {
                .schedule-player {
                    &.favorite {
                        padding-top: 2px;
                        padding-bottom: 2px;
                    }
                }
                .match-info {
                    // .round, .dash {
                    //     margin: 5px 0;
                    // }
                }
            }
        }

        @include breakpoint(tablet) {
            .schedule-court .schedule-content .row.teams .status.content  {
                vertical-align: middle;
                line-height:19px;
                .match-favorite {
                    display:block;
                    position: relative;
                    right:auto;
                    top:auto;
                    padding:0px; 
                    height:50%;
                    line-height:19px;
                    overflow:visible;
                    padding-top:2px;
                    a {
                        &:before {
                            font-size:8px;
                            line-height:14px;
                        }
                    }
                }
                .status-text {
                    // height:50%;
                    line-height:14px;
                }
                + .match-favorite {
                    display:none;
                }
            }
    }
}
}
