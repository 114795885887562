.homepage-video{
    .homepage-element-info{
        z-index: 1;
        .wim-icon-video{
            // display:none;
        }

        @include breakpoint(tablet,max){
            transform:translate(-50%,-26px);

            .wim-icon-video{
                // font-size:56px;
                position:static;
                display:inline-block;
                left: 50%;
                transform:none;
                color:$white;
                font-size:56px;
                cursor: pointer;
                background-color: rgba(0, 0, 0, .3);
                border-radius: 50px;
            }
        }
    }

    // .image-wrapper {
    //     @include breakpoint(tablet,max){
    //         .wim-icon-video {
    //             display: none;
    //         }
    //     }
    // }


    &.standard{
        @include breakpoint(tablet){

            .homepage-element-info{
                width:34%;

                
                .wim-icon-video, .wim-icon-videoPlay, i{
                    // display:inline-block;
                    display: none;
                }
            }

            &.right{
                .video-icon-wrapper{
                    .wim-icon-video{
                        right:unset;
                        left:33%;
                        transform:translate(-50%,-50%);
                    }
                }
            }
        }
    }
}