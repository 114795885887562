.video-wrapper {
	position: absolute;
	cursor: pointer;
	width: 100%;
    visibility: visible;
	.video-thumb {
        width: 100%;
        height:100%;
		vertical-align:bottom;
		margin: unset;
	}

	.homepage-video &{
		position: relative;
	}

	.wim-icon-video{
		position:absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color:$white;
		font-size:56px;
		cursor: pointer;
		background-color: rgba(0, 0, 0, .3);
        border-radius: 50px;
	}

	.video-player {
		margin: 0;
        position: absolute;
        top: 0px;
        width: 100%;
        padding-bottom: 66.66%;
        height: 0px;
        background-color: #000;
        background-size: cover;
        overflow: hidden;
        z-index: 4;
        box-shadow: unset;

        &.wide {
            padding-bottom: 56.25%;
        }
	}
}


// news tile video
.news-tile, .related-content-tile, .video-wrapper-header, .fullscreen-video {
    .video-wrapper {
        margin: 0;
        width: 100%;
        left: 0px;
        top: 0px;
        position: absolute;
        z-index: unset;
        border: unset;

		.video-player {
			position: fixed;
			top: 0px;
			left: 0px;
			width: 100vw;
			height: 100%;
			z-index: 200;
			padding-bottom: unset;
			
			&.wide {
				padding-bottom: 56.25%;
				padding-bottom: unset;
			}
		}

		i.wim-icon-close {
			position: fixed;
			color: white;
			font-size: 20px;
			right: 8px;
			transform: none;
			top: 8px;
			left: auto;
			font-weight: bold;
			z-index: 205;
			cursor: pointer;
			display: block;
		}

        @include breakpoint(largeDesktop){
            .video-player {
                width: calc(100vw - #{$sidebarWidth});  
            }
            i.wim-icon-close {
                right: calc(#{$sidebarWidth} + 8px); 
            }
        }

        @include breakpoint(extraLargeDesktop){
            .video-player {
                margin-left: calc((100vw - #{$maxWidth}) / 2);
                width: calc(#{$maxWidth} - #{$sidebarWidth});  
            }
            i.wim-icon-close {
                //right: calc(#{$sidebarWidth} + 8px);  
                right: calc(#{$sidebarWidth} + 8px + ((100vw - #{$maxWidth}) / 2));
            }
        }


        // for modal players, above tablet, always be in wide mode
        //   not need if use fullwindow
        // &.modal {
        //     @include breakpoint(tablet) {
        //         .video-player{
        //             padding-bottom: 56.25%;
        //         }
        //     }
        // }

        &.fullscreen {
            i {
                display: none;
            }
        }
        
    }
}

.video-wrapper-header {
    .video-wrapper {
		.video-player {
			position: fixed;
			top: 0px;
			left: 0px;
			width: 100vw;
			height: 100%;
			z-index: 200;
			padding-bottom: unset;
			
			&.wide {
				padding-bottom: 56.25%;
				padding-bottom: unset;
			}
		}

		i.wim-icon-close {
			position: fixed;
			color: white;
			font-size: 20px;
			right: 8px;
			transform: none;
			top: 8px;
			left: auto;
			font-weight: bold;
			z-index: 205;
			cursor: pointer;
			display: block;

		}
	}
}

// homepage gallery
.gallery-video-wrapper {
	.video-wrapper {
		position: relative;
		
		.video-player{
			position: fixed;
			top: 0px;
			left: 0px;
			width: 100vw;
			height: 100%;
			z-index: 100;
	
			//top: 0px;
			//width: 100%;
			//height: 0px;
			//position: absolute;
			//box-shadow: 2px 2px 16px 16px rgba(0, 0, 0, .8);
			//border: 2px solid white;
			//z-index: 4;
			margin: 0;
			//padding-bottom: 66.66%;
			background-color: #000;
			background-size: cover;
			overflow: hidden;
	
	
	
		}
	
		i.wim-icon-close {
			position: fixed;
			color: white;
			font-size: 20px;
			right: 8px;
			transform: unset;
			top: 8px;
			left: unset;
			font-weight: bold;
			z-index: 101;
			cursor: pointer;
	
			// position: absolute;
			// color: white;
			// font-size: 20px;
			// right: -22px;
			// transform: unset;
			// top: -21px;
			// left: unset;
			// font-weight: bold;
			// z-index: 10;
			// cursor: pointer;
		}
	
		.video-lightbox {
			position: absolute;
			width: 200vw;
			height: 200vh;
			background-color: rgba($color: #000000, $alpha: .85);
			top: -50%;
			left: -50%;
			z-index: 1100;
			display: none;
		}

		.wim-icon-video{
			font-size: 38px;
		}
	
		&.fullscreen {
			i {
				display: none;
			}
		}
	}
}

// direct video on video index page launch
.video-direct-wrapper {
    //width: $playerWidth;
    //left: $playerLeft;
    //top: 140px;
    //position: fixed;
   // z-index: 9999;
    
    .video-player{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100%;
        z-index: 100;

        //top: 0px;
        //width: 100%;
        //height: 0px;
        //position: absolute;
        //box-shadow: 2px 2px 16px 16px rgba(0, 0, 0, .8);
        //border: 2px solid white;
        //z-index: 4;
        margin: 0;
        //padding-bottom: 66.66%;
        background-color: #000;
        background-size: cover;
        overflow: hidden;



    }

    i.wim-icon-close {
        position: fixed;
        color: white;
        font-size: 20px;
        right: 8px;
        transform: unset;
        top: 8px;
        left: unset;
        font-weight: bold;
        z-index: 101;
        cursor: pointer;

        // position: absolute;
        // color: white;
        // font-size: 20px;
        // right: -22px;
        // transform: unset;
        // top: -21px;
        // left: unset;
        // font-weight: bold;
        // z-index: 10;
        // cursor: pointer;
    }

    .video-lightbox {
        position: absolute;
        width: 200vw;
        height: 200vh;
        background-color: rgba($color: #000000, $alpha: .85);
        top: -50%;
        left: -50%;
        z-index: 1100;
        display: none;
    }

    &.fullscreen {
        i {
            display: none;
        }
    }
}

// inline video
.video-comp-wrapper {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    .video-player{
        margin: 0;
        position: absolute;
        top: 0px;
        width: 100%;
        padding-bottom: 66.66%;
        height: 0px;
        background-color: #000;
        background-size: cover;
        overflow: hidden;
        z-index: 4;
        box-shadow: unset;

        &.wide {
            padding-bottom: 56.25%;
        }

    }
}

//for fullwindow video
.page.expanded .wrapper {
    .video-wrapper.modal, .video-direct-wrapper, .video-wrapper-header {
        .video-player {
            width: 100%;  
        }

        @include breakpoint(desktopSidePanel, mid){
            .video-player {
                width: calc(100% - #{$sidebarWidth});  
            }
            i.wim-icon-close {
                right: calc(#{$sidebarWidth} + 8px);  
            }
        }

        @include breakpoint(largeDesktop){
            .video-player {
                width: calc(100% - #{$sidebarWidth});  
            }
            i.wim-icon-close {
                right: calc(#{$sidebarWidth} + 8px);  
            }
        }

        // sidepanel always open with centered max width content
        @include breakpoint(extraLargeDesktop){
            .video-player {
                margin-left: calc((100% - #{$maxWidth}) / 2);
                width: calc(#{$maxWidth} - #{$sidebarWidth});  
            }
            i.wim-icon-close {
                //right: calc(#{$sidebarWidth} + 8px);  
                right: calc(#{$sidebarWidth} + 8px + ((100% - #{$maxWidth}) / 2));
            }
        }
    }
}