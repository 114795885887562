.head-to-head{
    @include gotham(medium);

    .matchup-info{
        display:flex;

        .player-image{
            width:200px;
            height:200px;
            margin-bottom:12px;
        }

        .player-flag{
            img{
                width:17px;
            }
            float:left;
        }
        
        .player-name{
            color:$wim-green;
            font-size:18px;
            text-transform:uppercase;
            margin-left:5px;
            display:inline;
        }

        .player-rank{
            font-size:14px;
            color:$black;
            display:flex;
            align-items:center;
            text-transform:uppercase;
            margin-top:6px;


            &-num{
                color:$wim-green;
                border:1px solid $wim-green;
                width:35px;
                height:35px;
                border-radius:50%;
                position:relative;
                font-size:18px;
                @include gotham-book();

                div{
                    position:absolute;
                    top:50%;
                    left:50%;
                    transform: translate(-50%,-50%);
                }
            }
        }
        .player-rank:nth-child(3){
            margin-top:12px
        }

        .player-one,.player-two{
            flex:1 0 auto;
            display:flex;

            hr{
                display:none;
            }

            &-info{
                margin-top:55px;
            }

        }

        .player-one{
            &-info{
                margin-left:10px;
            }

            .player-rank-num{
                margin-right:10px
            }
        }

        .player-two{
            flex-direction:row-reverse;

            &-info{
                margin-right:10px;

                .player-image{
                    float:right;
                }

                .player-rank{
                    flex-direction:row-reverse;
    
                    &-num{
                        margin-left:10px;
                    }
                }
            }
        }
    }

    .matchup-event-stats{
        .table-title {
            display: flex;
            flex-wrap: wrap;
            i{
                display:none;
            }
        
            .left-nav-dot,.right-nav-dot{
                display:none;
            }            
        }
        .datatable{
            padding-right:5%;
            .title{
                font-size:16px;
                padding-top:10px;

                .stat-round{
                    display:none;
                }
            }
            .row{
                font-size:14px;
                height:30px;
                text-align:center;
                .cell{
                    &.first-col{
                        text-align:left;
                    }
                }
            }
                
            .headers{
                height:40px;
                font-size:18px;

                .cell{
                    padding:5px 5px 10px;
                }

                .divider-cell{
                    width:10%;
                }

                &.player-names{
                    white-space:nowrap;
                    text-transform:uppercase;
                    height:45px;
                    .cell{
                        position:relative;
                        padding:20px 5px 5px;
                    }

                    .player-header{
                        position:absolute;
                        top:15px;
                        transform:translate(-50%,0);
                        left:50%;
                        .abbrev-name {
                            display:none;
                        }
                    }
                }
        
                .first-col{
                    width:225px;
                }
            }
        }
    }

    @mixin three-col-head-to-head{
        .matchup-event-stats{
            .datatable{
                padding-right:3%;
                .headers{
                    .first-col{
                        width:200px;
                    }
                    .divider-cell{
                        width:3%;
                    }
                }
            }
        }
    }

    @mixin two-col-head-to-head{
        .matchup-info{
            .player-image{
                width:100px;
                height:100px;
            }
            .player-name{
                font-size:14px;
            }

            .player-one-info,.player-two-info{
                margin-top:0px;

                .player-rank{
                    margin-top:6px;
                }
            }

            .player-two-info{
                .player-name-flag{
                    display:flex;

                    .player-flag{
                        flex:1 1 auto;
                        text-align:right;
                    }
    
                    .player-name{
                        flex:0 0 auto;
                        align-self:center;
                    }
                }
            }
        }

        .matchup-event-stats{
            .title{
                position:relative;
            }

            i{
                font-size:40px;
                padding:5px 0;
                top:50%;
                transform:translateY(-50%);
                position:absolute;

                &.disabled{
                    opacity:.35;
                }
            }

            .wim-icon-left-arrow{
                left:5px;
            }

            .wim-icon-right-arrow{
                right:5px;
            }

            .wim-icon-left-arrow::before{
                float:left;
                cursor: pointer;
            }
            .right-icon.wim-icon-right-arrow::before{
                float:right;
                cursor: pointer;
            }


            .datatable{
                padding-right:1%;
                .title{
                    .stat-round{
                        display:block;
                        text-transform:none;
                    }
                }
                .headers{
                    display:none;
                    &.player-names{
                        .player-header {
                            .full-name {
                                display: none;
                            }
                            .abbrev-name {
                                display: block;
                            }
                        }
                        display:table-row;
                        .cell{
                            // display:none;
                            display: table-cell;
                        }
                        .cell:first-child{
                            display:table-cell;
                        }
                        .cell:nth-child(5){
                            display:table-cell;
                        }
                        .cell:nth-child(13){
                            display:table-cell;
                        }
                    }
                    .first-col{
                        width:200px;
                    }
                }
                .cell{
                    // display:none;
                    display: table-cell;
                    &.selected {
                        display: table-cell;
                    }
                }
                .cell:first-child{
                    display:table-cell;
                }
            }
        }
    }

    @mixin one-col-head-to-head{
        .matchup-info{
            display:block;
            position:relative;
            // height:340px;
            height: 275px;

            .player-one,.player-two{
                display:inline;
                hr{
                    position:absolute;
                    display:inline-block;
                    width: calc(100% - 20px);
                    margin-left:10px;
                    margin-right:10px;
                    margin-top:20px;
                    border:0;
                    background-color:$wim-green;
                    z-index:0;
                    top:0;
                    left:0;
                    height:1px;
                }

                .player-image{
                    position:absolute;
                    margin-top:1px;
                }

                .player-one-info,.player-two-info{
                    position:relative;
                    top:100px;
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .player-name-flag{
                        background-color: white;
                        padding:0 10px;
                        height:20px;
                        margin-top:10px;
                        z-index: 1;
                    }

                    .player-rank{
                        text-align: left;
                        width: 100%;

                        .player-rank-num{
                            margin-right:10px;
                            margin-left:10px;
                        }
                    }
                }
            }

            .player-one{

                .player-image{
                    left:15%;
                }
            }

            .player-two{
                flex-direction:row;

                .player-image{
                    top:0;
                    right:15%;
                }
                .player-rank{
                    flex-direction:row;
                }
            }
        }
    }

    &.four-col{
        @include breakpoint(desktop,max){
            @include two-col-head-to-head;
        }

        @include breakpoint(tablet,max){
            @include one-col-head-to-head;
        }
    }

    &.three-col{
        @include breakpoint(desktop){
            @include three-col-head-to-head;
        }

        @include breakpoint(desktop,max){
            @include two-col-head-to-head;
        }

        @include breakpoint(tablet,max){
            @include one-col-head-to-head;
        }
    }

    &.two-col{
        @include two-col-head-to-head;

        @include breakpoint(tablet,max){
            @include one-col-head-to-head;
        }
    }

    &.one-col{
        @include two-col-head-to-head;
        @include one-col-head-to-head;
    }
}