

.smartbannerHelmet {
    .header-global {
        top: 64px;
        @include breakpoint(tablet){
            top: auto;
        }
    }

    .backgroundImage {
        margin-top: 64px;
        @include breakpoint(tablet){
            margin-top: auto;
        }
    }
}

.hiddenSmartbannerBGSpace {
    position: fixed;
    border-bottom: 0px !important;
}