.content-main{
    &.column-layout{
        &.contact-us{

            .upperCase{
                text-transform: uppercase;
            }

            .green{
                color: $wim-green;
            }

            .contact-box-wrapper {
                display: flex;
                flex-wrap: wrap;

                @include breakpoint(desktop) {
                    flex-wrap: nowrap;
                }
            }

            .contact-box{
                margin: 30px auto 0px auto;
                max-width: 800px;
                width: 100%;

                @include breakpoint(desktop) {
                    &:first-child {
                        margin-right: 30px;
                    }
                    
                    width: 50%;
                    height: auto;
                }

                .contact-box-content{
                    padding: 0 20px;
                    .Collapsible{
                        line-height: 30px;
                        margin-bottom: 15px;
                        .Collapsible__trigger {
                            text-transform: uppercase;
                            &::before {
                                padding: unset;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }

            .name-bar, .email-bar, .topic-subj-bar, .date-of-visit-bar {
                padding-bottom: 0px;
                @include breakpoint(tablet) {
                    padding-bottom: auto;
                }
            }

            .contact-us-input-field {
                margin-bottom: 2px;
                margin-top: 2px;

                @include breakpoint(tablet) {
                    margin-bottom: 8px;
                    margin-top: 8px;
                }
            }

            /*** override style just for Contact Us page */
            .contact-form-submit {
                margin-top: 30px;
            }

            .contact-my-wimbledon {
                display: block;
                width: 100%;
            
                a {
                    display: inline;
                    color: $wim-green;
                    font-size: 14px;    
                    text-transform: uppercase;
                    text-align: center;
                    margin: auto;
                    width: 100%;
                    padding-top: 7px;
                    padding-bottom: 7px;

                    span.lowercase {
                        text-transform: lowercase;
                    }

                    @include breakpoint(tablet) {
                        text-align: left;
                        margin-left: 45px;
                    }
                }
            }

            input[type='checkbox'] ~ label{
                flex: 0 0 0;
            }

            .form-opt-in{
                margin-top: 10px;
                display: list-item;
                list-style-type: none;
                vertical-align: middle;

                .opt-in-text{
                    @include breakpoint(desktop) {
                     top: 8px;
                    }
                }
            }

            .generic-button_wrapper > .generic-button,
            a > .generic-button {
                &.wide {
                    width: 100%;
                    
                    @include breakpoint(tablet) {
                        width: 250px;
                    }
                }
            }
            
        }
    }
}

.contact-header-left-align {
    text-align: left;
    margin: auto;
    font-size: 18px;
    margin-bottom: 10px;
    padding-left: 8px;
    @include breakpoint(desktop) {
        width: 60%;
        margin: 0 auto;
        padding-left: auto;
    }
}