#local-screen-set {
	//don't use display:none to hide as causes weird display with lastpass
	position: absolute;
	top: -5000px;

	div {
		display: none;
		// visibility: hidden;
	}
}

.grecaptcha-badge[data-style="bottomright"] { 
	visibility: hidden; 
}

.local-screens {
	.nowrap{
		white-space: nowrap;
	}
	
	#gigya-screen-dialog-page-overlay {
		display: none;
	}

	&.expanded {
		div.gigya-screen-dialog {
			// sidepanel open or cloased based on user selection
			@include breakpoint(desktopSidePanel, mid, true) {
				left: calc(50% - #{$sidebarWidth}/2) !important;
			}

			// sidepanel always open
			@include breakpoint(largeDesktop, min, true) {
				left: calc(50% - #{$sidebarWidth}/2) !important;
			}

			// sidepanel always open and content area centered with curtains
			@include breakpoint(extraLargeDesktop, min, true) {
				left: calc(50% - #{$sidebarWidth}/2) !important;
			}
		}
	}

	div.gigya-screen-dialog,
	div.gigya-screen-dialog-mobile {
		@include gotham-book();
		width: 100%;
		max-height: 100%;

		border: 1px solid $lines-gray;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		border-radius: 0px;
		outline: 0;

		top: 50% !important;
		left: 50% !important;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		overflow-x: hidden;
		position: fixed;

		@include breakpoint(tablet, min, true) {
			width: unset;
		}


		.gigya-screen-dialog-content {
			padding: 0;
			//width: calc(100% - 40px);
			margin: auto;
		}

		.gigya-screen.portrait {
			.gigya-captcha-wrapper {
				margin-top: 5px;
				margin-bottom: 5px;
				padding-bottom: -10px;

				div {
					margin: auto;
				}
			}

			div.gigya-tfa .gig-tfa-container .gig-tfa-phone-number {
				width: 100%;
			}

			//breakpoint for overall width
			@include breakpoint(tablet, min, true) {
				width: 500px;
			}
		}

		.gigya-screen.landscape {
			position: relative;

			.gigya-captcha-wrapper {
				position: absolute;
				left: 50px;
				bottom: 60px;
			}

			@include breakpoint(desktop, min, true) {
				max-width: 940px;
			}
		}

		div.gigya-screen-dialog-top {
			overflow: hidden;
			zoom: 1;
			background-color: rgba(0, 0, 0, 0);
			line-height: 38px;
			padding: 0 10px 0 11px;
			border-radius: 3px 3px 0 0;

			position: absolute;
			right: 0px;
			z-index: 10;

			.gigya-screen-dialog-close {
				background-image: none;
				width: 25px;
				top: 6px;
				position: relative;

				&:before,
				&:after {
					position: absolute;
					left: 15px;
					content: ' ';
					height: 27px;
					width: 2px;
					background-color: $wim-green;
				}
				&:before {
					transform: rotate(45deg);
				}
				&:after {
					transform: rotate(-45deg);
				}
			}
		}

		.gigya-screen-dialog-caption {
			display: none;
		}

		.gigya-screen-dialog-content {
			padding: 0;

			.subs-widget-container {
				padding-bottom: 10px;

				// @include checkbox();
				@include accessibleCheckbox();
			}

			.gigya-composite-control-checkbox {
				padding-bottom: 10px;

				// @include checkbox();
				@include accessibleCheckbox();

				.gigya-label {
					label {
						padding: 0 5px 0 0 !important;
						&:before,
						&:after {
							display: none;
						}
					}
					label.gigya-required-display {
						color: #e76468;
					}
				}
			}

			.gigya-consent-dates {
				div {
					@include gotham-book();
					font-size: 12px;
					letter-spacing: 0.7px;
					color: $gray_9b;
				}
				div:nth-child(2) {
					right: 0px;
				}
			}
		}

		.gigya-message {
			margin: 0 10px;
			text-transform: unset;
		}
	}

	// inline content

	.gigya-screen-content {
		label {
			text-transform: unset;
		}

		.subs-widget-container {
			padding-bottom: 10px;

			// @include checkbox();
			@include accessibleCheckbox();
		}

		.gigya-composite-control-checkbox {
			padding-bottom: 10px;

			// @include checkbox();
			@include accessibleCheckbox();

			label.gigya-label {
				width: calc(100% - 25px);

				label {
					padding: 0 5px 0 0 !important;
					&:before,
					&:after {
						display: none;
					}
				}
				label.gigya-required-display {
					color: #e76468;
				}
			}
		}

		.gigya-composite-control {
			.link-accounts-main-header {
				font-weight: bold;
				text-align: center;
			}
		}

		.gigya-consent-dates {
			div {
				@include gotham-book();
				font-size: 12px;
				letter-spacing: 0.7px;
				color: $gray_9b;
			}
			div:nth-child(2) {
				right: 0px;
			}
		}

		.select-title {
			width: 50%;
		}

		.birth-date {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 0 5px;
			margin: 0 -5px;

			.input-date {
				display: block;
				float: left;
				flex: 1 0 130px;
				margin: 0 5px;
				max-width: calc(50% - 10px);
			}
		}

		.gigya-composite-control {
			&.gigya-composite-control-label {
				&.main-text {
					font-size: 18px;
				}
			}
		}

		div.gigya-screen.landscape {
			padding-left: 25px;
			padding-right: 25px;
			width: 920px;
		}

		.gigya-message {
			text-transform: unset;
		}

		.gigya-screen.portrait {
			.gigya-captcha-wrapper {
				margin-top: 20px;
				margin-bottom: 10px;
				padding-bottom: -10px;
			}
		}

		.gigya-screen.landscape {
			.gigya-captcha-wrapper {
				position: relative;
				left: -450px;
			}
		}
	}

	.age-note {
		@include gotham-book();
		font-size: 11px;
		color: $gray_dark;
		text-align: left;
		margin: 3px 0 0 5px;
		width: 100%;
	}

	.button-border {
		position: absolute;
		width: 100%;
		height: 100%;
		border: 1px solid $wim-green;
		box-sizing: border-box;

		&:hover {
			border: 2px solid $wim-green;
			cursor: pointer;

			a.buttonBasic {
				padding-top: 6px;
			}
		}
	}

	a.inline-link {
		font-size: 13px !important;
	}
}

.local-screen {
	&.gigya-screen {
		.gigya-layout-row {
			&.row-space {
				padding: 0 0 20px 0;
			}

			&.google-terms-l {
				font-size: 11px;
				color: $gray-600;
				margin-top: 10px;
				text-align: left;

				a {
					font-size: 11px;
				}
			}
			&.google-terms-p {
				font-size: 11px;
				color: $gray-600;
				margin-top: 20px;
				text-align: left;

				a {
					font-size: 11px;
				}
			}
		}

		a.buttonBasic {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			cursor: pointer;

			color: $wim-green;
			font-size: 17px !important;
			text-transform: uppercase;
			text-align: center;
			position: absolute;
			margin: auto;
			width: 100%;
			height: 100%;
			padding-top: 7px;
			box-sizing: border-box;
			@include gotham(medium);

			&:active,
			&:link,
			&:visited,
			&:hover {
				text-decoration: none;
			}
			&:active {
				background-color: $wim-green;
				color: $white;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				//box-shadow: inset 0 2px 7px 0 rgba(0, 0, 0, .4);
			}
			&:hover {
				//background-color: $button-hover;
			}
		}

		.form-checkbox {
			// @include checkbox();
		}

		.with-social-login {
			margin-bottom: 10px;
		}
	}
}

// .gigya-mobile-modal-mode {
// 	.local-screens {
// 		div.gigya-screen {
// 			padding-left: 25px;
// 			padding-right: 25px;

// 			.input-date {
// 				span {
// 					//margin-right: 10px;
// 					//width: 30%;
// 				}
// 			}
// 		}
// 	}
// }

//******
// Default css
//******

.gigya-screen-caption {
	font-family: arial;
	padding-left: 11px;
	line-height: 40px;
}
.gigya-screen {
	margin: 0 auto;
	padding: 0;
	border: none;
	color: inherit;
	text-decoration: none;
	width: auto;
	float: none;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0;
	font-size: 15px;
	color: #4e515e;
	text-align: left;
	font-family: arial;
	//Instead of the line below you could use @include box-sizing($bs)
	box-sizing: content-box;
	//Instead of the line below you could use @include box-sizing($bs)
	box-sizing: border-box;
	padding-bottom: 15px;

	* {
		margin: 0 auto;
		padding: 0;
		border: none;
		color: inherit;
		text-decoration: none;
		width: auto;
		float: none;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		text-align: left;
		//Instead of the line below you could use @include box-sizing($bs)
		box-sizing: content-box;

		@include gotham-book();
		font-size: 14px;
		letter-spacing: 0.7px;
	}
	.gigya-label {
		display: block;
		font-weight: 700;
		font-size: 15px;
		color: #4e515e;
		margin-top: 5px;
	}
	.gigya-label-text {
		display: inline;
		@include gotham();
		font-size: 14px;
		color: $wim-text-purple;
		letter-spacing: 0.7px;
		// @include breakpoint(tablet){ letter-spacing: -0.5px; }
	}
	&.portrait {
		width: 100%;
		max-width: 500px;
		font-size: 15px;
		padding-left: 30px;
		padding-right: 30px;
		padding-top: 15px;

		.gigya-layout-row {
			.submit-button {
				padding-bottom: 25px;
			}
			&.with-divider {
				.gigya-layout-cell.responsive {
					float: none;
					min-width: 100%;
					width: 100%;
				}
				> .gigya-layout-cell.with-divider {
					height: 51px;
					background-repeat: repeat-x;
					position: relative;
					> .gigya-divider-content {
						padding: 0 10px 2px;
						position: absolute;
						display: inline-block;
						top: 50%;
						left: 50%;
						//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
						transform: translateX(-50%) translateY(-50%);
					}
				}
			}
			.gigya-layout-cell.responsive {
				float: none;
				min-width: 100%;
				width: 100%;
			}
		}
		.sub-title-text {
			font-size: 15px;
		}
		.text-field-disabled {
			background-color: #f2f2f2;
			border: 1px solid #d6dee4;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 2px;
			color: #4e515e;
		}
		.gigya-composite-control {
			padding-left: 0;
			padding-right: 0;
			&.gigya-composite-control-social-login {
				width: calc(100% + 50px);
				position: relative;
				right: 25px;
			}
			&.gigya-composite-control-captcha-widget
				.gigya-captcha-wrapper
				.gigya-captcha
				.recaptcha_only_if_privacy
				div.gigya-tfa
				.gig-tfa-container
				.gig-tfa-phone-code-resend {
				font-size: 12px;
			}
		}
		&.mobile {
			width: 100%;
			max-width: 400px;
			padding-top: 30px;
			padding-bottom: 15px;
			padding-left: 25px;
			padding-right: 25px;

			&:before {
				display: block;
				width: 100%;
				content: '';
			}
			.text-field-disabled {
				color: #4e515e;
			}
			.forgotpassword {
				font-size: 13px;
			}
			.gigya-composite-control-label {
				font-size: 13px;
			}
			.gigya-forgotPassword {
				font-size: 13px;
			}
			.gigya-label {
				font-size: 13px;
			}
			.gigya-label-text {
				// font-size: 13px;
			}
			.gigya-login-footer-text-wrapper {
				font-size: 13px;
			}
			.gigya-message {
				font-size: 13px;
			}
			.gigya-terms-of-use {
				font-size: 13px;
			}
			.main-header {
				font-size: 13px;
			}
			.site-title {
				font-size: 13px;
			}
			.sub-title-text {
				font-size: 13px;
			}
			a {
				font-size: 13px;
			}
			h1 {
				font-size: 13px;
			}
			h2 {
				padding-bottom: 10px;
				@include gotham(medium);
				font-size: 14px;
				color: $wim-green;
				letter-spacing: 0.7px;
				text-transform: uppercase;
			}
			input[type='email'] {
				height: 40px;
				font-size: 16px;
				background-color: #fff !important;
			}
			input[type='password'] {
				height: 40px;
				font-size: 16px;
				background-color: #fff !important;
			}
			input[type='text'] {
				height: 40px;
				font-size: 16px;
				background-color: #fff !important;
			}
			select {
				height: 40px;
				font-size: 16px;
				background-color: #fff !important;
				// @include gotham-book();
			}
			.gigya-composite-control-social-login {
				width: calc(100% + 40px);
				position: relative;
				right: 20px;
			}
			input[type='checkbox'] {
				margin-bottom: 2px;
			}
			.gigya-error-msg-active.gigya-form-error-msg {
				font-size: 13px;
				display: inline-block;
			}
			.gigya-error-msg.gigya-error-msg-active {
				font-size: 13px;
				display: inline-block;
			}
			div.gigya-tfa .gig-tfa-container {
				.gig-tfa-code-remember-label {
					font-size: 13px;
					margin-left: 0;
				}
				.gig-tfa-header-text {
					font-size: 13px;
				}
				.gig-tfa-label {
					font-size: 13px;
				}
				.gig-tfa-phone-code-header-subtext {
					font-size: 13px;
				}
				.gig-tfa-phone-code-header-text {
					font-size: 13px;
				}
				.gig-tfa-phone-code-phonenumber {
					font-size: 13px;
				}
				.gig-tfa-phone-code-resend {
					font-size: 13px;
					font-size: 13px;
				}
				.gig-tfa-phone-edit-header-text {
					font-size: 13px;
					font-size: 13px;
					color: #4e515e;
				}
				.gig-tfa-phone-edit-number {
					font-size: 13px;
				}
				.gig-tfa-phone-register-example {
					font-size: 13px;
				}
				.gig-tfa-phone-resend-header-text {
					font-size: 13px;
					display: block;
				}
				.gig-tfa-phone-resend-type {
					font-size: 13px;
					span {
						font-size: 13px;
					}
				}
				.gig-tfa-phone-resend-yourphone {
					font-size: 13px;
				}
				.gig-tfa-code-remember-checkbox {
					margin-bottom: 2px;
				}
				.gig-tfa-phone-edit-link {
					font-size: 19px;
					padding: 0;
					margin-top: 0;
				}
				.gig-tfa-phone-prefix {
					display: none;
					width: 10%;
					margin-right: 8px;
					font-size: 13px;
					height: 36px;
				}
				.gig-tfa-phone-number {
					width: 100%;
					font-size: 13px;
					height: 36px;
				}
				.gig-tfa-error {
					font-size: 13px;
				}
				.gig-tfa-phone-edit-numbers {
					margin-top: 30px;
					.gig-tfa-phone-edit-number {
						font-size: 13px;
						margin-top: 8px;
						color: #4e515e;
					}
				}
				.gig-tfa-code-textbox {
					font-size: 13px;
					height: 36px;
				}
				.gig-tfa-button-container {
					.gig-tfa-button {
						margin-bottom: 15px;
					}
					.gig-tfa-button-submit {
						margin-bottom: 15px;
					}
				}
				.gig-tfa-phone-register-type span {
					font-size: 13px;
				}
				input.gig-tfa-phone-prefix {
					display: none;
				}
			}
			label.letter-spacing-s {
				letter-spacing: 0;
			}
		}
		div.gigya-tfa .gig-tfa-container {
			.gig-tfa-code-remember-label {
				display: inline-block;
				font-weight: lighter;
				font-size: 15px;
				font-family: arial;
				color: #4e515e;
				margin: 0 0 18px 0;
				padding: 0;
			}
			.gig-tfa-header-text {
				display: inline-block;
				font-weight: lighter;
				font-size: 15px;
				font-family: arial;
				color: #4e515e;
				padding-bottom: 40px;
				display: block;
				text-align: left;
				margin: 0;
			}
			.gig-tfa-label {
				display: inline-block;
				font-weight: lighter;
				font-size: 15px;
				font-family: arial;
				color: #4e515e;
				padding-bottom: 6px;
				display: block;
				margin: 0;
			}
			.gig-tfa-phone-code-header-subtext {
				display: inline-block;
				font-weight: lighter;
				font-size: 15px;
				font-family: arial;
				color: #4e515e;
				display: inline;
			}
			.gig-tfa-phone-code-header-text {
				display: inline-block;
				font-weight: lighter;
				font-size: 15px;
				font-family: arial;
				color: #4e515e;
				display: inline;
			}
			.gig-tfa-phone-code-phonenumber {
				display: inline-block;
				font-weight: lighter;
				font-size: 15px;
				font-family: arial;
				color: #4e515e;
				display: inline;
			}
			.gig-tfa-phone-edit-header-text {
				display: inline-block;
				font-weight: lighter;
				font-size: 15px;
				font-family: arial;
				color: #4e515e;
			}
			.gig-tfa-phone-edit-number {
				display: inline-block;
				font-weight: lighter;
				font-size: 15px;
				font-family: arial;
				color: #4e515e;
			}
			.gig-tfa-phone-register-example {
				display: inline-block;
				font-weight: lighter;
				font-size: 15px;
				font-family: arial;
				color: #4e515e;
				display: block;
				margin: 0 0 18px 0;
				padding: 0;
			}
			.gig-tfa-phone-register-type {
				display: inline-block;
				width: 33.33333333%;
				margin-bottom: 18px;
				margin-top: 18px;
				vertical-align: top;
				margin-right: 0;
				margin-left: 0;
				width: 33.33333333%;
				span {
					display: inline-block;
					font-weight: lighter;
					font-size: 15px;
					font-family: arial;
					color: #4e515e;
					display: inline;
				}
			}
			.gig-tfa-phone-resend-header-text {
				display: inline-block;
				font-weight: lighter;
				font-size: 15px;
				font-family: arial;
				color: #4e515e;
				margin: 0 0 18px 0;
				padding: 0;
			}
			.gig-tfa-phone-resend-type {
				display: inline-block;
				font-weight: lighter;
				font-size: 15px;
				font-family: arial;
				color: #4e515e;
				display: inline-block;
				width: 33.33333333%;
				margin-bottom: 18px;
				margin-top: 18px;
				vertical-align: top;
				margin-right: 0;
				margin-left: 0;
				width: 33.33333333%;
				span {
					display: inline-block;
					font-weight: lighter;
					font-size: 15px;
					font-family: arial;
					color: #4e515e;
					display: inline;
				}
			}
			.gig-tfa-phone-resend-yourphone {
				display: inline-block;
				font-weight: lighter;
				font-size: 15px;
				font-family: arial;
				color: #4e515e;
			}
			.gig-tfa-code-textbox {
				padding-left: 7px;
				//Instead of the line below you could use @include box-sizing($bs)
				box-sizing: border-box;
				width: 100%;
				text-indent: 5px;
				border-color: #b6bdc5;
				color: #4e515e;
				outline: none;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 4.5px;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
				height: 43px;
				margin: 0 0 18px 0;
				padding: 0;
				font-size: 15px;
				&:focus {
					border: 1px solid #87b9ea;
					outline: none;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 5px 0 #1d87ff;
				}
			}
			input {
				&.gig-tfa-code-textbox {
					border: 1px solid #b6bdc5;
					padding-left: 7px;
					&:disabled {
						background-color: #f2f2f2;
						border-color: #d4dee4;
						color: #6a6a6a;
					}
					&:focus {
						padding-left: 7px;
					}
				}
				&.gig-tfa-phone-prefix {
					display: none;
				}
				&.gigya-error.gig-tfa-code-textbox {
					//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
					// border-radius: 4.5px;
					border-style: solid;
					border-width: 1px;
					border-color: #e76468;
					padding-left: 7px;
					&:focus {
						padding-left: 7px;
					}
				}
				&.gigya-pending.gig-tfa-code-textbox {
					padding-left: 7px;
					&:focus {
						padding-left: 7px;
					}
				}
				&.gigya-valid.gig-tfa-code-textbox {
					padding-left: 7px;
					&:focus {
						padding-left: 7px;
					}
				}
			}
			.gig-tfa-phone-code-resend {
				color: #1371b9;
				text-decoration: none;
				font-size: 15px;
				&:active {
					color: #1371b9;
					text-decoration: none;
					font-size: 15px;
				}
				&:link {
					color: #1371b9;
					text-decoration: none;
					font-size: 15px;
				}
				&:visited {
					color: #1371b9;
					text-decoration: none;
					font-size: 15px;
				}
				&:hover {
					text-decoration: underline;
				}
			}
			.gig-tfa-button-container {
				margin: 0;
				.gig-tfa-button {
					appearance: none;
					cursor: pointer;
					width: 100%;
					height: 43px;
					//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
					border-radius: 1.79px;
					border-color: #1371b9;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.4);
					color: #fff;
					background-color: #1d87ff;
					align-content: center;
					text-align: center;
					font-size: 19px;
					background-image: none;
					filter: none;
					font-weight: 400;
					line-height: normal;
					padding-top: 10px;
					margin-bottom: 25px;
					&:active {
						background-color: #0068de;
						//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
						box-shadow: inset 0 2px 7px 0 rgba(0, 0, 0, 0.4);
					}
					&:hover {
						background-color: #0068de;
					}
				}
				.gig-tfa-button-submit {
					appearance: none;
					cursor: pointer;
					width: 100%;
					height: 43px;
					//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
					border-radius: 1.79px;
					border-color: #1371b9;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.4);
					color: #fff;
					background-color: #1d87ff;
					align-content: center;
					text-align: center;
					font-size: 19px;
					background-image: none;
					filter: none;
					font-weight: 400;
					line-height: normal;
					padding-top: 10px;
					margin-bottom: 25px;
					&:active {
						background-color: #0068de;
						//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
						box-shadow: inset 0 2px 7px 0 rgba(0, 0, 0, 0.4);
					}
					&:hover {
						background-color: #0068de;
					}
				}
			}
			.gig-tfa-phone-edit-link {
				appearance: none;
				cursor: pointer;
				width: 100%;
				height: 43px;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 1.79px;
				border-color: #1371b9;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.4);
				color: #fff;
				background-color: #1d87ff;
				align-content: center;
				text-align: center;
				font-size: 19px;
				width: 70px;
				line-height: 43px;
				float: right;
				margin-top: -10px;
				&:active {
					background-color: #0068de;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: inset 0 2px 7px 0 rgba(0, 0, 0, 0.4);
				}
				&:hover {
					background-color: #0068de;
				}
			}
			.gig-tfa-code-remember-checkbox {
				width: auto;
				margin: 2px 0 0;
				padding: 0;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 0;
				border: none;
				vertical-align: middle;
			}
			.gig-tfa-link {
				margin-left: 24%;
				margin-top: 8%;
			}
			.gig-tfa-email-text {
				font-weight: 700;
				width: 207px;
				margin: 0 auto;
				font-size: 15px;
				padding-bottom: 25px;
			}
			.gig-tfa-email-code-header-text {
				width: 240px;
				margin: 0 auto;
				padding-top: 6px;
				padding-bottom: 5px;
				font-size: 15px;
			}
			.gig-tfa-phone-register-select {
				margin: 0 0 18px 0;
				padding: 0;
			}
			.gig-tfa-phone-resend-yourphone-label {
				margin: 0 0 18px 0;
				padding: 0;
			}
			.gig-tfa-error {
				text-align: center;
				margin-bottom: 18px;
				margin-top: -10px;
				font-size: 15px;
				&:empty {
					display: none;
				}
			}
			.gig-tfa-phone-prefix {
				display: none;
				height: 43px;
				width: 10%;
				margin-top: 0;
				margin-right: 16px;
				text-align: center;
				background-color: #f2f2f2;
			}
			.gig-tfa-phone-number {
				margin-top: 0;
				height: 43px;
				width: 100%;
			}
			label {
				display: inline;
			}
			.gig-tfa-phone-register-type-label {
				display: inline-block;
				width: 33.33333333%;
				margin-bottom: 18px;
				margin-top: 18px;
				vertical-align: top;
				padding: 5px 0;
				width: 30.33333333%;
			}
			.gig-tfa-phone-resend-type-label {
				display: inline-block;
				width: 33.33333333%;
				margin-bottom: 18px;
				margin-top: 18px;
				vertical-align: top;
				padding: 5px 0;
				width: 30.33333333%;
			}
			.gig-tfa-phone-register-type-sms {
				width: 38.33333333%;
			}
			.gig-tfa-phone-resend-type-sms {
				width: 38.33333333%;
			}
			.gig-tfa-phone-register-type-voice-call {
				width: 31.33333333%;
			}
			.gig-tfa-phone-resend-type-voice-call {
				width: 31.33333333%;
			}
			.gig-tfa-phone-edit-numbers {
				margin-top: 26px;
				line-height: 31px;
				&:last-child {
					padding-bottom: 25px;
				}
			}
			.gigya-button.gig-tfa-phone-code-resend {
				width: 100%;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 4.5px;
				border: 1px solid #b6bdc5;
				background-color: #fff;
				//Instead of the line below you could use @include box-sizing($bs)
				box-sizing: border-box;
				cursor: pointer;
				width: 100%;
				height: 43px;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 1.79px;
				border-color: #1371b9;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.4);
				color: #fff;
				background-color: #1d87ff;
				align-content: center;
				text-align: center;
				font-size: 19px;
				&:active {
					background-color: #0068de;
					//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
					box-shadow: inset 0 2px 7px 0 rgba(0, 0, 0, 0.4);
				}
				&:hover {
					background-color: #0068de;
				}
			}
			.inlined.gig-tfa-header-text {
				display: inline-block;
				text-align: center;
			}
			.gigya-composite-control.gigya-composite-control-captcha-widget
				.gigya-captcha-wrapper
				.gigya-captcha
				.recaptcha_only_if_privacy
				.gig-tfa-phone-code-resend {
				font-size: 12px;
			}
			.gig-tfa-code-header-subtext + .gig-tfa-label {
				margin-top: 40px;
			}
			.gigya-ios .gigya-screen.landscape .gig-tfa-code-remember-checkbox {
				margin-bottom: 5px;
			}
		}
		.gigya-error-msg-active.gigya-form-error-msg {
			font-size: 13px;
		}
		.gigya-error-msg.gigya-error-msg-active {
			font-size: 13px;
		}
	}
	.gigya-checkbox-text {
		display: inline;
		@include gotham-book();
		font-size: 12px;
		color: $wim-black;
		letter-spacing: 0.7px;
	}
	.gigya-message {
		font-size: 15px;
		color: #333;
		display: block;
		text-align: center;
		&.left {
			text-align: left;
			padding-bottom: 40px;
		}
		&.align-left {
			text-align: left;
		}
	}
	.gigya-social-login-container {
		margin: auto;
	}
	.gigya-forgotPassword {
		float: right;
		&:link {
			float: right;
			@include gotham-book();
			font-size: 14px;
			color: $link-green;
			letter-spacing: 0.7px;

			margin-top: 4px;
		}
	}
	.gigya-keep-me-logged-in {
		display: block;
	}
	.gigya-input-password {
		padding-left: 7px;
		//Instead of the line below you could use @include box-sizing($bs)
		box-sizing: border-box;
		width: 100%;
		text-indent: 5px;
		// border-color: #b6bdc5;
		// color: #4e515e;
		outline: none;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		// border-radius: 4.5px;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		// box-shadow: inset 0 1px 3px rgba(0, 0, 0, .05);
		// height: 43px;
		// &:focus {
		// 	border: 1px solid #87b9ea;
		// 	outline: none;
		// 	//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		// 	box-shadow: 0 0 5px 0 #1d87ff;
		// }
	}
	.gigya-input-text {
		padding-left: 7px;
		//Instead of the line below you could use @include box-sizing($bs)
		box-sizing: border-box;
		width: 100%;
		text-indent: 5px;
		border-color: #b6bdc5;
		color: #4e515e;
		outline: none;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		// border-radius: 4.5px;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
		height: 43px;
		@include gotham-book();
		&:focus {
			border: 1px solid #87b9ea;
			outline: none;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 5px 0 #1d87ff;
		}
	}
	.gigya-textarea {
		padding-left: 7px;
		//Instead of the line below you could use @include box-sizing($bs)
		box-sizing: border-box;
		width: 100%;
		text-indent: 5px;
		border-color: #b6bdc5;
		color: #4e515e;
		outline: none;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		// border-radius: 4.5px;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);
		&:focus {
			border: 1px solid #87b9ea;
			outline: none;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 5px 0 #1d87ff;
		}
	}
	input {
		&.gigya-input-password {
			// border: 1px solid #b6bdc5;
			padding-left: 7px;
			&:disabled {
				background-color: #f2f2f2;
				border-color: #d4dee4;
				color: #6a6a6a;
			}
			&.gigya-error {
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				// border-radius: 4.5px;
				border-radius: 0 !important;
				border-style: solid;
				border-width: 1px;
				border-color: #e76468;
				padding-left: 7px;
				&:focus {
					padding-left: 7px;
				}
			}
			&.gigya-pending {
				padding-left: 7px;
				&:focus {
					padding-left: 7px;
				}
			}
			&.gigya-valid {
				padding-left: 7px;
				&:focus {
					padding-left: 7px;
				}
			}
			&:focus {
				padding-left: 7px;
			}
		}
		&.gigya-input-text {
			border: 1px solid #b6bdc5;
			padding-left: 7px;
			&:disabled {
				background-color: #f2f2f2;
				border-color: #d4dee4;
				color: #6a6a6a;
			}
			&.gigya-error {
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				// border-radius: 4.5px;
				border-style: solid;
				border-width: 1px;
				border-color: #e76468;
				padding-left: 7px;
				&:focus {
					padding-left: 7px;
				}
			}
			&.gigya-pending {
				padding-left: 7px;
				&:focus {
					padding-left: 7px;
				}
			}
			&.gigya-valid {
				padding-left: 7px;
				&:focus {
					padding-left: 7px;
				}
			}
			&:focus {
				padding-left: 7px;
			}
		}
		&:-ms-input-placeholder {
			color: #a9a9a9;
		}
	}

	select {
		// @include gotham-book();
		height: 40px;
		width: 100%;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		// border-radius: 4.5px;
		border-radius: 0;
		border: 1px solid $lines-gray;
		background-color: #fff;
		//Instead of the line below you could use @include box-sizing($bs)
		box-sizing: border-box;
	}
	.gigya-required-display {
		color: #e76468;
		display: inline-block;
		padding: 0 0 0 5px;
	}
	.gigya-error-display {
		display: none;
		&.gigya-error-display-active {
			display: block;
		}
	}
	.gigya-error-msg {
		line-height: 14px;
		font-size: 13px;
		text-align: center;
		font-weight: 400;
		//Instead of the line below you could use @include box-sizing($bs)
		box-sizing: border-box;
		display: inline-block;

		&.gigya-error-msg-active {
			padding-top: 8px;
			padding-bottom: 0;
			color: #e76468;
			font-weight: 700;
			font-size: 13px;
			line-height: 15px;
			display: inline-block;
		}
	}
	.gigya-container {
		text-align: inherit;
		text-decoration: inherit;
		font-size: inherit;
		color: inherit;
	}
	div {
		&.gigya-loginID-availability-available {
			margin-bottom: 18px;
		}
		&.gigya-container.gigya-visible-when .gigya-composite-control {
			display: block;
		}
		&.gigya-composite-control-my-photo-widget.update-profile-my-photo {
			padding-right: 20px;
		}
	}
	.gigya-myPhoto-profile-box-wrapper {
		margin-left: 0;
		margin-top: 0;
	}
	.label-divider {
		display: block;
		width: 100%;
		height: 80px;
		background-repeat: repeat-x;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY7h8+TIABPYCeicnV7oAAAAASUVORK5CYII=);
		background-position: 50%;
		//Instead of the line below you could use @include box-sizing($bs)
		box-sizing: border-box;
		padding: 0 !important;
		span {
			display: inline-block;
			background-color: #fff;
			margin-top: 31px;
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	a {
		color: $wim-green;
		text-decoration: none;
		font-size: 15px;
		cursor: pointer;
		
		&:active {
			color: $wim-green;
			text-decoration: none;
			font-size: 15px;
		}
		&:link {
			color: $wim-green;
			text-decoration: none;
			font-size: 15px;
		}
		&:visited {
			color: $wim-green;
			text-decoration: none;
			font-size: 15px;
		}
		&:hover {
			text-decoration: underline;
		}
		&.gigya-button {
			width: 100%;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 4.5px;
			border: 1px solid #b6bdc5;
			background-color: #fff;
			//Instead of the line below you could use @include box-sizing($bs)
			box-sizing: border-box;
			cursor: pointer;
			width: 100%;
			//height: 43px;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 1.79px;
			border-color: #1371b9;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.4);
			color: #fff;
			background-color: #fff;
			align-content: center;
			text-align: center;
			font-size: 19px;
			@include gotham(medium);

			position: relative;
			height: 35px;

			&:active {
				background-color: #0068de;
				//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
				box-shadow: inset 0 2px 7px 0 rgba(0, 0, 0, 0.4);
			}
			&:hover {
				background-color: #0068de;
			}
		}
	}
	h1 {
		line-height: 15px;
		font-size: 15px;
		color: #4e515e;
		font-weight: 400;
		text-align: center;
		border-bottom: 1px solid #dbdbdb;
		font-weight: 700;
	}
	h2 {
		line-height: 15px;
		text-align: center;
		padding-bottom: 10px;
		@include gotham(medium);
		font-size: 14px;
		color: $wim-green;
		letter-spacing: 0.7px;
		text-transform: uppercase;
	}
	input[type='email'] {
		width: 100%;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		// border-radius: 4.5px;
		border: 1px solid $lines-gray;
		background-color: #fff;
		//Instead of the line below you could use @include box-sizing($bs)
		box-sizing: border-box;
		height: 40px;
	}
	input[type='password'] {
		width: 100%;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		// border-radius: 4.5px;
		border: 1px solid $lines-gray;
		background-color: #fff;
		//Instead of the line below you could use @include box-sizing($bs)
		box-sizing: border-box;
		height: 40px;
	}
	input[type='text'] {
		width: 100%;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		// border-radius: 4.5px;
		border: 1px solid $lines-gray;
		background-color: #fff;
		//Instead of the line below you could use @include box-sizing($bs)
		box-sizing: border-box;
		height: 40px;
	}
	textarea {
		width: 100%;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		// border-radius: 4.5px;
		border: 1px solid $lines-gray;
		background-color: #fff;
		//Instead of the line below you could use @include box-sizing($bs)
		box-sizing: border-box;
	}
	.gig-tfa-button-submit {
		appearance: none;
	}
	.gigya-button {
		appearance: none;
		background-color: #fff;
		position: relative;
		height: 34px;
	}
	.gigya-composite-control-link {
		appearance: none;
	}
	.gigya-composite-control-submit {
		appearance: none;
	}
	input[type='button'] {
		appearance: none;
		cursor: pointer;
		width: 100%;
		height: 34px;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 1.79px;
		border-color: $wim-green;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.4);
		color: #fff;
		background-color: $wim-green;
		align-content: center;
		text-align: center;
		font-size: 16px;
		@include gotham(medium);
		&:active {
			background-color: $wim-green;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			box-shadow: inset 0 2px 7px 0 rgba(0, 0, 0, 0.4);
		}
		&:hover {
			background-color: rgba($wim-green, 0.8);
		}
		&:focus {
			//outline-color: rgba($wim-green, .4);
			outline: none !important;
		}
	}
	input[type='submit'] {
		padding-left: 0px;
		appearance: none;
		cursor: pointer;
		width: 100%;
		height: 34px;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		//border-radius: 1.79px;
		//border-color: $wim-green;
		//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		//box-shadow: 0 0 1px 0 rgba(0, 0, 0, .4);
		color: #fff;
		background-color: $wim-green;
		align-content: center;
		text-align: center;
		font-size: 16px;
		@include gotham(medium);
		&:active {
			background-color: $button-active;
			//Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
			//box-shadow: inset 0 2px 7px 0 rgba(0, 0, 0, .4);
		}
		&:hover {
			background-color: $button-hover;
		}
		&:focus {
			//outline-color: rgba($wim-green, .4);
			//outline: none !important;
		}
	}
	input[type='checkbox'] {
		width: auto;
		margin: 2px 0 0;
		padding: 0;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		border: none;
		vertical-align: middle;
	}
	input[type='radio'] {
		width: auto;
		margin: 2px 0 0;
		padding: 0;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		border: none;
		vertical-align: middle;
	}
	option {
		width: 100%;
		padding: 5px;
		&:hover {
			background-color: #3593c1;
		}
	}
	.gigya-layout-row {
		zoom: 1;
		display: block;
		text-align: center;
		&:after {
			content: '';
			display: table;
			clear: both;
		}
		&.with-two-inputs {
			
			.gigya-composite-control {
				padding-right: 0px;
				@include breakpoint(tablet, min, true) {
					padding-right: 7.5px;
				}
			}
			> .gigya-layout-cell + .gigya-layout-cell > .gigya-composite-control {
				padding-right: 0;
				padding-left: 0px;
				@include breakpoint(tablet, min, true) {
					padding-left: 7.5px;
				}
			}
			> .gigya-layout-cell {
				&:first-child{
					.passwordEyeDivClass{
						right: 17.5px;
					}
				}
				max-width: 100%;
				width: 100%;
				@include breakpoint(tablet, min, true) {
					max-width: 50%;
					width: 50%;
				}
			}
		}
		&.with-divider > .gigya-layout-cell {
			min-width: 48%;
			width: 48%;
			&.with-divider {
				min-width: 4%;
				width: 4%;
				background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY7h8+TIABPYCeicnV7oAAAAASUVORK5CYII=);
				background-position: 50%;
				> .gigya-divider-content {
					padding: 0;
					position: relative;
					line-height: 16px;
					background: #fff;
					text-align: center;
					min-width: 10px;
				}
			}
		}

		&.google-terms-p {
			display: block;
		}
		&.google-terms-l {
			display: none;
		}
	}
	.gigya-layout-cell {
		text-align: center;
		float: left;
		width: 50%;
		.gigya-error-cell {
			width: 100%;
			max-width: none;
			min-width: 0;
		}
		.gigya-layout-cell {
			max-width: 50%;
		}
	}
	.gigya-layout-footer {
		text-align: right;
		clear: both;
	}
	&.landscape {
		padding-top: 20px;

		.gigya-layout-row {
			&.with-divider {
				//padding-bottom: 25px;

				display: flex;
				.gigya-composite-control {
					&.gigya-composite-control-button {
						padding-bottom: 0;
					}
					&.gigya-composite-control-submit {
						padding-bottom: 0;
					}
					&.gigya-composite-control-link.gigya-button-link {
						padding-bottom: 0;
					}
				}
				> .gigya-layout-cell {
					&.with-divider {
						margin-top: 41px;
						width: 31px;
						background-repeat: repeat-y;

						> .gigya-divider-content {
							display: block;
							line-height: 15px;
							padding: 10px 0;
							top: 83px;
							left: 0;
						}
					}
					&.with-site-login {
						min-width: 0;
						width: 395px;
					}
					&.with-social-login {
						min-width: 0;
						width: 395px;
					}
				}
			}

			&.google-terms-l {
				bottom: 25px;
    			position: relative;
			}
			&.google-terms-p {
				display: none;
			}
			&.google-terms-l {
				display: block;
			}

			.gigya-layout-cell.under-site-login {
				width: 370px;
				text-align: center;
				min-width: 0;
				margin-left: 460px;
			}
		}
		.gigya-composite-control.gigya-composite-control-social-login {
			position: relative;
			//right: 25px;
		}
		form.gigya-form-error .gigya-layout-row.with-divider {
			padding-bottom: 10px;
			.gigya-composite-control {
				&.gigya-composite-control-button {
					padding-bottom: 10px;
				}
				&.gigya-composite-control-submit {
					padding-bottom: 10px;
				}
				&.gigya-composite-control-link.gigya-button-link {
					padding-bottom: 10px;
				}
				&.gigya-composite-control-form-error.gigya-error-display-active {
					padding-bottom: 0;
				}
			}
		}
	}
	.gigya-composite-control {
		&.gigya-composite-control-label {
			display: inline-block;
			&.main-text {
				padding-bottom: 10px;
				display: block;
				text-align: left;
				font-size: 15px;

				&.inlined {
					display: inline-block;
					text-align: center;
				}
			}
			&.social-login-title {
				padding-bottom: 26px;
				display: inline-block;
				text-align: center;
			}
		}
		&.gigya-composite-control-link {
			display: inline-block;

			&.gigya-button-link {
				padding-top: 10px;
				padding-bottom: 0px;
				display: block;
				+ .gigya-error-display-active {
					padding-bottom: 25px;
				}
			}
		}
		&.gigya-composite-control-header {
			padding-bottom: 15px;
		}
		&.gigya-composite-control-dropdown {
			padding-bottom: 5px;
			.gigya-label {
				padding-bottom: 6px;
			}
			input {
				margin-bottom: 0px;
			}
			select {
				margin-bottom: 0px;
			}
			.gigya-error-msg-active {
				padding-top: 0;
				padding-bottom: 0px;
			}
		}
		&.gigya-composite-control-password {
			padding-bottom: 5px;
			position: relative;
			.gigya-label {
				padding-bottom: 6px;
			}
			input {
				margin-bottom: 0px;
			}
			select {
				margin-bottom: 0px;
			}
			.gigya-error-msg-active {
				padding-top: 0;
				padding-bottom: 0px;
			}
		}
		&.gigya-composite-control-textbox {
			padding-bottom: 5px;
			.gigya-label {
				padding-bottom: 6px;
			}
			input {
				margin-bottom: 0px;
			}
			select {
				margin-bottom: 0px;
			}
			.gigya-error-msg-active {
				padding-top: 0;
				padding-bottom: 0px;
				display: inline-block;
			}
		}
		&.gigya-composite-control-captcha-widget {
			padding-bottom: 0;
			.gigya-captcha-wrapper {
				margin-top: -65px;

				.gigya-captcha {
					margin: 0 auto 13px auto;
					input {
						height: auto;
					}
					.recaptcha_only_if_privacy a {
						font-size: 12px;
					}
				}
				.gigya-error-msg-active {
					padding-top: 0;
					padding-bottom: 0px;
				}
			}
		}
		&.gigya-composite-control-tfa-widget {
			padding-bottom: 0;
		}
		&.gigya-composite-control-button {
			padding-bottom: 25px;

			+ .gigya-error-display-active {
				padding-bottom: 25px;
			}
		}
		&.gigya-composite-control-submit {
			padding-bottom: 0px;
			padding-top: 10px;

			+ .gigya-error-display-active {
				padding-bottom: 0px;
			}
		}
		&.gigya-composite-control-social-login {
			padding: 0;
		}
		&.gigya-composite-control-multi-choice {
			.gigya-label {
				padding-bottom: 8px;
			}
			.gigya-multi-choice-item {
				padding-top: 10px;
				display: flex;
				align-items: flex-start;
				.gigya-input-radio {
					flex: 0 0 auto;
				}
				label {
					flex: 0 0 auto;
					margin-left: 8px;
					width: 100%;
					display: block;
				}
			}
		}
		&.gigya-composite-control-checkbox {
			//display: flex !important;
			align-items: flex-start;
			.gigya-input-checkbox {
				flex: 0 0 auto;
			}
			.gigya-label {
				flex: 0 0 auto;
				margin-left: 0px;
				width: 100%;
				display: block;
			}
		}
		&.gigya-terms-error {
			padding: 5px;
			margin-bottom: 18px;
			position: relative;
			right: 6px;
			width: 100%;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 5px;
			border: 1px solid #dd4b39;
		}
	}
	.gigya-spacer-divider {
		border-bottom: 1px solid #d3d3d3;
		margin-bottom: 15px;
		padding-bottom: 5px;
	}
	.link-accounts-main-header {
		margin-bottom: 10px;
	}
	.reauthentication-main-header {
		margin-bottom: 10px;
	}
	.gigya-error-msg-active.gigya-form-error-msg {
		line-height: 14px;
		font-size: 13px;
		text-align: center;
		font-weight: 400;
		//Instead of the line below you could use @include box-sizing($bs)
		box-sizing: border-box;
		padding-top: 8px;
		padding-bottom: 0;
		color: #e76468;
		font-weight: 700;
		font-size: 13px;
		line-height: 15px;
		_height: 26px;
		font-size: 12px;
		min-height: 26px;
		text-align: center;
		margin-top: 1px;
		border: 1px #e76468;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		// border-radius: 4.4px;
		font-weight: 700;
		padding: 10px;
	}
	.gigya-login-provider-row:first-child {
		padding-top: 0;
	}
	label.letter-spacing-s {
		letter-spacing: -0.1px;
	}
	form.gigya-form-error .gigya-composite-control {
		&.gigya-composite-control-button {
			padding-bottom: 10px;
		}
		&.gigya-composite-control-submit {
			padding-bottom: 10px;
		}
		&.gigya-composite-control-form-error.gigya-error-display-active {
			padding-bottom: 10px;
		}
		&.gigya-composite-control-link.gigya-button-link {
			padding-bottom: 10px;
		}
	}

	.passwordEyeDivClass {
		// background-color: red;
		height: 35px;
		background: transparent;
		background-repeat: no-repeat;
		background-position-x: right;
		background-position-y: center;
		position: absolute;
		width: 25px;
		top: 25px;
		right: 10px;
		cursor: pointer;
		&.hide {
			background-image: url('/assets/images/misc/pw-hide.svg');
		}
		&.show {
			background-image: url('/assets/images/misc/pw-show.svg');
		}
	}
}

.gigya-show-checkmark{
	background-position: right 40px center !important;
	padding-right: 60px !important;
}

.gigya-locale-ar {
	.gigya-checkbox-text {
		direction: rtl;
	}
	.gigya-composite-control-header {
		direction: rtl;
	}
	.gigya-composite-control-label {
		direction: rtl;
	}
	.gigya-composite-control-link {
		direction: rtl;
	}
	.gigya-label {
		direction: rtl;
	}
	input {
		direction: rtl;
	}
	select {
		direction: rtl;
	}
	.gigya-multi-choice-item label {
		direction: rtl;
	}
}
.gigya-locale-he {
	.gigya-checkbox-text {
		direction: rtl;
	}
	.gigya-composite-control-header {
		direction: rtl;
	}
	.gigya-composite-control-label {
		direction: rtl;
	}
	.gigya-composite-control-link {
		direction: rtl;
	}
	.gigya-label {
		direction: rtl;
	}
	input {
		direction: rtl;
	}
	select {
		direction: rtl;
	}
	.gigya-multi-choice-item label {
		direction: rtl;
	}
}
#gigya-change-password-screen {
	.error-wrapper {
		width: 100%;
	}
	&.portrait.mobile {
		input[password] {
			width: 260px;
		}
		input[submit] {
			width: 276px;
		}
	}
}
.subscribe-thank-you {
	line-height: 1.2em;
	font-size: 1.3em;
	text-align: center;
	p {
		margin-bottom: 0.5em;
		text-align: center;
		font-size: inherit;
	}
}
// .gigya-screen-content div.gigya-screen.landscape {
// 	padding-left: 25px;
// 	padding-right: 25px;
// 	width: 920px;
// }
// .gigya-screen-dialog-mobile .gigya-screen.portrait div.gigya-tfa .gig-tfa-container .gig-tfa-phone-number {
// 	width: 100%;
// }
#gigya-login-screen.gigya-screen {
	&.landscape .gigya-layout-row.with-divider > .gigya-layout-cell.with-divider > .gigya-divider-content {
		top: 83px;
	}
	&.portrait.mobile .gigya-prefered-content-width {
		width: 375px;
	}
}
#gigya-register-screen.gigya-screen.landscape
	.gigya-layout-row.with-divider
	> .gigya-layout-cell.with-divider
	> .gigya-divider-content {
	top: 127px;
}
.gigya-subscribe-with-email-form .gigya-header {
	font-size: 1.5em;
	line-height: 1.5em;
	font-weight: bolder;
	text-align: center;
}
.gigya-mac .gigya-screen label.letter-spacing-s {
	letter-spacing: -0.3px;
}
.gigya-ios .gigya-screen {
	&.landscape {
		input[type='checkbox'] {
			margin-bottom: 5px;
		}
		.gigya-screen.portrait div.gigya-tfa .gig-tfa-container .gig-tfa-code-remember-checkbox {
			margin-bottom: 5px;
		}
	}
	&.portrait {
		&.mobile {
			input[type='checkbox'] {
				margin-bottom: 5px;
			}
			div.gigya-tfa .gig-tfa-container .gig-tfa-code-remember-checkbox {
				margin-bottom: 5px;
			}
		}
		input[type='checkbox'] {
			margin-bottom: 5px;
		}
		div.gigya-tfa .gig-tfa-container .gig-tfa-code-remember-checkbox {
			margin-bottom: 5px;
		}
	}
	&#gigya-change-password-screen form label:first-child {
		letter-spacing: -0.1px;
	}
	&#gigya-forgot-password-screen form label:first-child {
		letter-spacing: -0.3px;
	}
}
.gigya-native-mobile-app.gigya-android
	.gigya-screen.portrait.mobile
	.gigya-composite-control.gigya-composite-control-social-login {
	right: 28px;
}
.gigya-edge .gigya-screen label.letter-spacing-s {
	letter-spacing: -0.3px;
}

.gigya-ie10 .gigya-screen {
	#gigya-change-password-screen {
		&.mobile {
			letter-spacing: 0;
		}
		&.portrait .gigya-message {
			letter-spacing: -0.2px;
		}
	}
	&.portrait label {
		letter-spacing: -0.3px;
	}
	&.mobile label {
		letter-spacing: 0;
	}
}
.gigya-ie9 .gigya-screen {
	select {
		font-size: 13px;
	}
	&#gigya-login-screen.landscape .gigya-layout-row.with-divider .gigya-layout-cell.with-divider {
		height: 202px;
	}
	&#gigya-register-screen.landscape .gigya-layout-row.with-divider .gigya-layout-cell.with-divider {
		height: 300px;
	}
	.gigya-input-password:not(.gigya-placeholder) {
		line-height: 40px;
	}
	.gigya-input-text:not(.gigya-placeholder) {
		line-height: 40px;
	}
	&.portrait div.gigya-tfa .gig-tfa-container .gig-tfa-code-textbox:not(.gigya-placeholder) {
		line-height: 40px;
	}
	.gigya-composite-control-checkbox .gigya-label {
		display: inline;
	}
	.gigya-composite-control-multi-choice .gigya-multi-choice-item label {
		display: inline;
	}
}
.gigya-ie8 .gigya-screen {
	.gigya-layout-cell {
		margin-left: 0 !important;
		&.under-site-login {
			margin-left: 460px !important;
		}
	}
	.gigya-input-password {
		padding-top: 11px;
	}
	.gigya-input-text {
		padding-top: 11px;
	}
	&.portrait {
		.gigya-input-password {
			padding-top: 11px;
		}
		.gigya-input-text {
			padding-top: 11px;
		}
		gigya-layout-cell {
			margin-left: 0 !important;
		}
		#gigya-change-password-screen {
			&.mobile {
				letter-spacing: 0;
			}
			&.portrait .gigya-message {
				letter-spacing: -0.2px;
			}
		}
		#gigya-forgot-password-screen {
			&.portrait label {
				letter-spacing: -0.3px;
			}
			&.mobile label {
				letter-spacing: 0;
			}
		}
		div.gigya-tfa .gig-tfa-container .gig-tfa-code-textbox {
			padding-top: 11px;
			padding-top: 11px;
		}
		.gigya-layout-row.with-divider .gigya-layout-cell.with-divider .gigya-divider-content {
			top: 31px;
			left: 45%;
		}
	}
	&#gigya-login-screen.landscape .gigya-layout-row.with-divider .gigya-layout-cell.with-divider {
		height: 202px;
	}
	&#gigya-register-screen.landscape .gigya-layout-row.with-divider .gigya-layout-cell.with-divider {
		height: 300px;
	}
}
