.photo{

    position: relative;
    @include gotham(medium);
    a:hover{
        text-decoration: none;
    }
    img { 
        width: 100%;
        height: auto;
        display: block; 
    }

    &.one-col{
        .photo-info{
			text-align: center;
			
            .photo-title,.photo-credit,.photo-date{
                width: 100%;
                text-align: center;
				line-height: 100%;
			}
			
			.photo-credit {
				display: block;
			}
        }
    }

    &.two-col{
		.photo-info {
			.photo-title {
				.photo-credit {
					display: block;
				}

				@include breakpoint(tablet) {
					.photo-credit {
						display: inline;
					}
					.photo-credit::before {
						content: "";
						width: 15px;
						display: inline-block;
					}
				}
			}
		}
        
    }

    .photo-info{
        .photo-title{
            font-size: 16px;
            color: $wim-green;
            text-transform: uppercase;
            margin-top: 15px;
            text-align: center;
            display: block;
            margin-right: 0 5px;
			letter-spacing: 3px;
		
        }

        .photo-credit{
            color:$wim-green;
            font-size: 12px;
            margin-top: 7px;
            display: inline-block;
            text-align: center;
            letter-spacing: 1px;
			width: 100%;
        }

        .photo-date{
            color: $gray-disable;
            font-size: 12px;
            width: 100%;
            text-align: center;
            margin-top: 9px;
        }
    }
}