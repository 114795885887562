.live-status {
	width: 172px;
	@include gotham-book();
	text-align: center;
	&.small {
		position: absolute;
		right: 264px;
		top: 0px;
		z-index: 100;
		margin-top: 25px;

		@include breakpoint(desktop, max) {
			display: none;
		}
		.symbol {
			.circle {
				width: 40px;
				height: 40px;

				.wim-icon-ballSolid {
					font-size: 6px;
				}
				.wim-icon-playArrow-blank {
					padding-left: 4px;
					&:hover {
						content: '';
					}
				}
				.hover-text {
					font-size: 13px;
				}
			}
		}
		.label {
			display: none;
		}
	}

	&.full {
		width: 217px;

		.title {
			background-color: inherit;
			font-size: 22px;
			letter-spacing: 3px;
			height: 32px;
		}

		.symbol {
			font-size: 22px;
			width: 72px;
			// margin: 0 5px;

			.label {
				font-size: 12px;
				letter-spacing: 0.5px;
			}
			.circle {
				width: 50px;
				height: 50px;

				.wim-icon-ballSolid {
					font-size: 8px;
				}
				.wim-icon-playArrow-blank {
					padding-left: 6px;
				}
				.hover-text {
					font-size: 16px;
				}
			}
		}

		.line {
			margin: 0 4px;
		}
	}

	.title {
		font-size: 12px;
		letter-spacing: 2px;
		color: $white;

		width: calc(100% - 2px);
		height: 18px;
		background-color: rgba(255, 255, 255, 0.45);
		margin: 0 0 8px 1px;
		padding-top: 2px;
		display: block;
	}

	.symbol {
		float: left;
		font-size: 18px;
		color: $white;
		width: 57px;
		// margin: 0 3px;

		&.scores {
			// margin-left: 0;

			&.live {
				@keyframes ball {
					0% {
						left: 1%;
					}
					100% {
						left: 82%;
					}
				}
				.circle {
					.wim-icon-ballSolid {
						animation-name: ball;
						animation-duration: 2s;
						animation-iteration-count: infinite;
						animation-direction: alternate;
						animation-timing-function: linear;
					}
				}
			}
		}

		&.radio {
			margin-right: 0;
		}

		.label {
			@include gotham-book();
			font-size: 11px;
			color: $white;
			letter-spacing: 0px;
			margin-top: 3px;
		}

		.circle {
			border-width: 2px;
			border-style: solid;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			margin: 0 auto;

			.wim-icon-ballSolid {
				position: absolute;
				// left: 39px;
				left: 1%;
			}

			.hover-text {
				text-transform: uppercase;
				display: none;
			}
		}

		&.live {
			color: $live-green;
		}
	}

	.line {
		float: left;
		height: 43px;
		width: 2px;
		background-color: rgba(255, 255, 255, 0.45);
		margin: -2px 2px 0 2px;
		display: none;
	}

	a:hover,
	.radioStatus:hover {
		.symbol {
			&.radio,
			&.video {
				&.live {
					.circle {
						// i {
						// 	display: none;
						// }
						.hover-text {
							display: block;
						}
					}
				}
			}
		}
	}
}
