.iconShelf{
	width: 100%;
}

.tabs {
	z-index: 10;
	.tab-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: 80%;
		margin: 20px auto 0px auto;
		padding-left: 0px;

		.service-icon-label {
			background-color: inherit;
			color: #00703c;
			padding: 6px;
			border: none;
			font: inherit;
			cursor: pointer;
			margin: 12px 0px;
			width: 130px;
		}
		.active-tab {
			background-color: #f1f1f1;
			padding: 6px;
			border-radius: 5px;
		}
	}
}
