.category-question-list{
    @include gotham(medium);
    // display: flex;
	// max-width: 50%;
	margin:  auto;
    margin-top:  14px;
    z-index: -1;
    @include breakpoint(tablet, max){
		// flex-direction: column;
		// max-width: 80%;
    }
    
    .category-list{
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-right: 25px;

        &-title{
            font-size: 16px;
            text-transform: uppercase;
            color: $live-green;
            margin-bottom: 15px;
        }

        &-question{
            font-size: 14px;
            color: $white;
            line-height: 1em;
            margin-bottom: 15px;
        }

        @include breakpoint(tablet,max){
            margin-bottom: 35px;
            margin-right: 0;

            &-title{
                font-size: 14px;
            }

            &-description{
                font-size: 12px;
            }
        }

        &-question:last-child{
            margin-bottom: 0;
        }
    }

    .category-list:last-child{
        margin: 0;
	}

	&.hide {
		pointer-events: none;
		opacity: 0;
        transition: opacity .25s linear;
	}
	
	pointer-events: auto;
	opacity: 1;
	transition: opacity .25s linear;
}

.wrapper.fixed {
	.search--header-wrapper {
		.search--header {
			pointer-events: none;
			
			.category-question-list {
				pointer-events: none;
				opacity: 0;
                transition: opacity .25s linear;
			}
		}
	}
}