/**
 * draws archive layout 
 */
$header_large_height: 219px; // 438
$header_medium_height: 219px; //438
$header_small_height: 213px; //213

.page {
	.wrapper.drawsarchive {

		.content-main {
			min-height: calc(100vh - 276px);
		}

		.drawsArchiveHdrContent {
			color: $white; 
			position: fixed;
			height: (($header_small_height)-60px);
			width: 100%;
			top: 60px;
			z-index: 11;
			@include breakpoint(tablet) { height: (($header_medium_height)-60px); }
			@include breakpoint(desktop) { height: (($header_large_height)-60px); }

			h1 { 
				@include gotham_book;
				font-size: calc((27/16)*1em);
				font-weight: normal;
				padding-top: calc((5/16)*1em);
				text-transform: uppercase;
				text-align: center;
				max-width: 1590px;
				@include breakpoint(tablet) { font-size: calc((45/16)*1em); padding-top: 0; }
				@include breakpoint(desktop) { font-size: calc((48/16)*1em); }
				&.hideDesktop { 
					@include breakpoint(desktop) { display: none; }
					}
				}

			.mobile-timeline { 
				text-align: center;
				width: 100%;
				@include breakpoint(tablet) {
					margin-top: 15px;
				}
			}
		}
		.player-gen-info.draws_archive {
			.general-info-item {
				.general-info-field{ 
					@include breakpoint(desktop){ width: 120px; }
				}
				.general-info-value{ font-size: 16px;}
			}
		}

		.archivePdf {
			display: none;

			color: $wim-green;
			font-size: 24px;
			position: absolute;
			top: 18px;
			right: 20px;

			&:hover { 
				cursor: pointer;
			}

			@include breakpoint(tablet) { 
				display: block;
			}
		}

		.newsArchiveLink {
			display: none;

			color: $wim-green;
			font-size: 14px;
			left: 20px;
			position: absolute;
			text-transform: uppercase;
			top: 22px;

			@include breakpoint(tablet) { 
				display: block;
			}
		}

		.bottomLinks {
			position: absolute;
			width: 100%;
			height: 40px;
			bottom: 10px;

			@include breakpoint(tablet) { 
				display: none;
			}

			.archivePdf {
				display: block;
			}

			.newsArchiveLink {
				display: block;
			}
		}

		.roundGroup { 
			// Events page specific override styles
			&.eventsPage { float: left; box-sizing: border-box; margin-right: 25px; margin-left: 5px;
				width: 90%;

				@include breakpoint(tablet) { margin-left: 0px; }
				@include breakpoint(desktop) { }
				@include breakpoint(xlarge) { margin-right: 27px; }
				.yearWrapper {
					h4 { color: #238457; font-size: calc((24/16) * 1em); margin: 15px; text-align: center; }
				}
				@include breakpoint(tablet) { width: 33%; }

				.drawGroup { overflow: hidden; width: 100%;  margin-bottom: 24px; 

					&.winner, &.challengeWinner { height: auto; margin-top: 60px; display: none; // events page to hide winner
						@include breakpoint(tablet) { display: block; }
						&.year { display: block; } // Draws Archive Home / Year page to show Winner

						.drawGroupInner {
							padding-bottom: 33px;
						}
					}// &.winner
					@include breakpoint(tablet) { float: left; }
				} // .drawGroup
			} // &.eventsPage
			
		}

		/***
		*    draws match boxes wrapper styles override for draws archive year page
		*    main styles are done in draws-pages.scss
		*    columnB and columnC are hidden in mobile view
		*    columnC is hidden in tablet view
		*/
		.drawsList {
			&.archive {
				margin: 7px 0;
				width: 100%;
				padding-top: 35px;
				
				.roundGroup {
					float: left;
					box-sizing: border-box;
					margin-right: 25px;
					margin-left: 0;
					width: calc(100% - 20px);


					h3 { 
						color: $black;
						margin: 23px auto;
						font-weight: normal;
						text-align: center;
						text-transform: uppercase;
					}

					&.columnA {
						float: none;
						margin-right: auto;
						margin-left: auto;
						.drawGroup {
							margin-bottom: 25px;

							&.challenge {
								display: block;
								margin-top: 0px;
								margin-bottom: 24px;

								.match-box-draws {
									margin-bottom: 24px;

									&.winner {
										margin-bottom: 0;
									}
								}
							}
						}
					}
	
					&.columnB {
						display: none;

						.drawGroup {

							&.challenge { 
								display: none;
							}
						}
					}
			
					&.columnC {
						display: none;

						.drawGroup {

							&.winner {
								display: block;
								margin-top: 24.5px;
								margin-bottom: 24.5px;
							}

							&challenge { 
								display: none;
							}
						}
					}

					@include breakpoint(tablet) {
						width: calc((100%/2) - (25px/2));
						&.columnA {
							float: left;
							margin-right: 24px;
							margin-left: 0;
						}
						&.columnB {
							display: inline-block;
							margin-right: 0;

							.drawGroup:first-of-type {
								margin-top: 77px;
							}

							.drawGroup {
								margin-bottom: 141px;
								
								&.winner {
									margin-top: 45px;
								}

								&.qualifier {
									&:first-of-type {
										margin-top: 45px;
									}
									margin-bottom: 68px;
								}

								&.challenge {
									display: block;
									margin-top: 0px;
									margin-bottom: 24px;

									.match-box-draws {
										margin-bottom: 24px;

										&.winner {
											margin-bottom: 0;
										}
									}
								}
							}
						}
					}

					@include breakpoint(desktop) {
						width: calc((100%/3) - (50px/3));
						&.columnB, &.columnC {
							display: inline-block;
							margin-right: 25px;
						}

						&.columnC {
							margin-right: 0;
							.drawGroup:first-of-type {
								margin-top: 188px;
							}

							.drawGroup {
								margin-bottom: 373px;

								&.winner {
									margin-top: 100px;
								}

								&.qualifier {
									&:first-of-type {
										margin-top: 100px;
									}
									margin-bottom: 184px;
								}

								&.challenge {
									display: block;
									margin-top: 0px;
									margin-bottom: 24px;

									.match-box-draws {
										margin-bottom: 24px;

										&.winner {
											margin-bottom: 0;
										}
									}
								}
							}
						}
					}
				}

			}
		}

		.statsInfo{
			.playerMatches{
				.tabs{border-bottom: 2px solid $gray-light; width: 100%; display: inline-block; list-style-type: none;
					li{ text-transform: uppercase; font-size: 14px; padding: 0px 10px 5px; margin-top: 10px; display: inline-block;
						&.current{ border-bottom: 2px solid $wim-green; padding: 0px 10px 5px; color: $wim-green; margin-bottom: -2px; 
							a{ color: $wim-green; }
						}
						&.display{ a {color: $gray-dark;} }
					}
				}
			}/*playerMatches*/

			.roundGroup{ display: inline-block; position: relative;
				&.summaryTabs{ margin-right: 15px; 
					.yearWrapper{
						h4 { color: $wim-green; font-size: calc((24/16) * 1em); margin: 15px; text-align: center; text-transform: uppercase;}
					}
					
					.drawGroup{ margin-bottom: 15px;                      
						.match-box.match-box-draws{ width: 300px; }
						&.winner{ height: 100px; margin-top: 80px;
							.winner{ width: 200px; }
							.team-name { width: 160px;
								.member-one, .member-two{ width: 170px; }
							}
						}
						.match-box-draws .match-status .name a{
							color: $wim-green;
						}
					}
				}
			}/*roundGroup*/

		}

		.player-info{
			.pdfLinks{ padding-top: 15px; color: $wim-green; cursor: pointer;
				a{ text-decoration: none; font-weight: bold; line-height: 18px; font-size: 14px;}
			}
		}

		.da-related-content{
			.related-content-container{ 
				.two-col{
					display: inline-block; position: relative; 
				}
			}
		}

		.draws-archive-stats-table {
			width: calc(100% - 120px);
			margin: 30px auto 60px;
			border-collapse: collapse;

			.datatable {
				.cell {
					display: block;
					text-align: left;
					overflow-wrap: break-word;
					padding: 5px 10px;

					@include breakpoint(tablet) { 
						display: table-cell;
					}
				}
			}
			&.nobreak {
				.datatable {
					.cell { display: table-cell; }
				}
			}

			.link { 
				color: $wim-green;
				cursor: pointer;
				.wim-icon-pdf {
					color: $wim-green;
				}
			}
		}
		.drawsarchive_player{
			.next-prev-back-bar{ display: none;}
		}
	} // .wrapper

	// for side panel show
	&.expanded  {
		.wrapper {
			.drawsArchiveHdrContent {
				width: calc(100vw - #{$sidebarWidth});
				@include breakpoint(desktopSidePanel, mid){
					width: calc(100vw - #{$sidebarWidth});
				}

				@include breakpoint(largeDesktop){
					width: calc(100% - #{$sidebarWidth});
				}
				@include breakpoint(extraLargeDesktop) {
					.sidepanel & {
						width: calc(#{$maxWidth} - #{$sidebarWidth});
					}
				}
			}
		}
	}
} // .page