.page{
	.wrapper {
		.search {
			&.content-main {
				&.fixed {
					.content-filters {
						top: 190px;
						left: 0px;
						width: 100%;
					}
				}
			}
		}
	}
	&.expanded {
		.wrapper {
			.search {
				&.content-main {
					&.fixed {
						.content-filters {
							top: 190px;
							left: 0px;
							
							@include contentMainWidth(true);
						}
					}
				}
			}
		}
	}
}