.unsubscribe {
    .news--header {
        h2 {
            text-transform: uppercase;

            &::after {
                background: none;
            }
        }
    }

   

    .form-row {
        width: 305px;
        margin: auto;

        legend {
            margin: 20px 0 10px 0;
            color: $wim-green;
        }

        input[type=email] {
            width: 100%;
            border-radius: 4.5px;
            border: 1px solid #b6bdc5;
            background-color: #fff;
            box-sizing: border-box;
            padding: 10px;
            height: 40px;
            font-size: px2rem(14);
        }
    
        .gigya-label {
            color: $gray_9b;
            margin-top: 10px;
            margin-bottom: 5px;
    
            span {
                color: $red;
            }
        }

        .error {
            color: $red;
            font-size: px2rem(13);
        }
    }

    .contact-form-submit {
        margin-top: 20px;

        .generic-button_wrapper > .generic-button, a > .generic-button {
            width: 100%;
        }
    }
    
}