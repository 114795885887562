@keyframes fadeIn {
    0% { background-position-y: -114px }
	100% { background-position-y: 0px }
}

.latest-arrow {
    position: relative;
    margin: 0 auto;
	margin-bottom: 25px;
	padding: 5px 0 0 5px;
	width: 155px;

    .label {
        @include gotham-book();
        font-size: 30px;
        letter-spacing: 6px;
		color: $white;
		font-weight: bold;
    }

    .arrow-wrap {
        position: relative;
        overflow: hidden;
        height: 57px;
    }

    .arrows{
        position: absolute;
        top: 10px;
        left: 43px;
        height: 171px;
        width: 64px;
        
        background: transparent;
        background-image: url('/assets/images/misc/home-arrows.svg');    
    }

    // chrome, safari, opera, above windows10+ IEs
    @media screen and (-webkit-min-device-pixel-ratio:0) {
        .arrows{
            background-image: none;

            //not supported in IE
            mask-image: url('/assets/images/misc/home-arrows.svg');
            mask-mode: alpha;
            
            animation: fadeIn 4s 0s infinite;
            animation-fill-mode: backwards;
            background: linear-gradient(
                to bottom, 
                rgba(255, 255, 255, 1) 0px, 
                rgba(255, 255, 255, 1) 57px, 
                rgba(255, 255, 255, .2) 114px,
                rgba(255, 255, 255, .2) 171px);
            background-position-y: -114px;
            background-repeat: no-repeat;
        }
    }
    // Windows10+ IE edge
    @supports (-ms-ime-align:auto) {
        .arrows {
            background: transparent;
            background-image: url('/assets/images/misc/home-arrows.svg');
            animation: none;
        }
    }

    @include breakpoint(tablet, max){
        //transform: scale(.7, .7);
        width: 91px;
        margin-bottom: 0px;

        .label {
            @include gotham-book();
            font-size: 20px;
            letter-spacing: 2px;
            color: $white;
        }

        .arrow-wrap {
            height: 35px;
     
            margin-bottom: 10px;
        }

        .arrows{
            margin: 7px 0 0 0;
            transform: scale(.7, .7);
            transform: scale(0.7, 0.7);
            
            top: -30px;
            left: 12px;
        }
    }
}

.page, .page.expanded {
    .wrapper {
        .page-header {

            //******************************************
            // homepage specific settubg
            //  - maintain min height and top/left lock of home header bg as resize down
            //******************************************
            &.home {
                position: relative;

                .backgroundSpace, .backgroundImage {
                    height: 100vh;
                    max-height: unset;
  
                    img{ 
                        height: 100vh;
                        width: 100%;
                        object-fit: cover;
                        object-position: 0px;
						margin-left: 0px;
						
						&.fit-width {
                            width: 100%;
                            height: auto;
                        }
                        &.fit-height {
                            width: auto;
                            height: 100vh;
                        }
			
                    }

                    video {
                        height: 100%;
                        width: auto;
                        margin-left: 0;
                        position: absolute;
                        top: 0px;
                        left: 50%;
                        transform: translateX(-50%);
                        z-index: 2;

                        &.fit-width {
                            width: 100%;
                            height: auto;
                        }
                        &.fit-height {
                            width: auto;
                            height: 100vh;
                        }
                    }
                }

                .backgroundImage {
                    &.gallery {
                        .carousel-wrapper  {
                  
                            .nav-wrapper {
                                bottom: 125px;
                                top: unset;
                                left: -0.30%;
                                justify-content: center;
                            }
                        }
                        .image-gallery {
                            .image-gallery-slide-wrapper {
                                height: 100vh;

                                .image-gallery-left-nav, .image-gallery-right-nav {
                                    position: relative;
                                    top: 0;
                                    transform: none;
                                    &::before {
                                        vertical-align: bottom;
                                    }
                                }

                                .image-gallery-swipe, .image-gallery-slides, .image-gallery-slide, .image-gallery-image {
                                    height: inherit;

                                    img {
                                        visibility: inherit;
                                    }
                                
                                }
                            }
                        }
                        .image-gallery-bullets {
                            bottom: 140px;
                        }
					}
					
					&.mobile {
						img{ 
							object-position: unset;
						}
					}
                }

                &.fixed {
                    .backgroundImage {
                        overflow: hidden;
                        height: $headerHeightSmall;

                        @include breakpoint(tablet){
                            height: $headerHeightLarge;
                        }
					}
					
				&.fixed.fit-width, &.fixed.fit-height {
						.backgroundImage.ie{
							height: $headerHeightSmall;

							@include breakpoint(tablet){
								height: $headerHeightLarge;
							}
						}
					}
                }

                &::after{
                    content: '';
                    height: 100%;
                    width: 40%;
                    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.4) 40%, transparent);
                    position: absolute;
                    top: 0px;
					right: 0px;
                }

				&.fit-width {
					.backgroundImage.ie, .backgroundSpace.ie {
						width: 100%;
						height: auto;

						img {
							width: 100%;
							height: auto;
						}
					}
				}
				&.fit-height {
					.backgroundImage.ie, .backgroundSpace.ie {
						width: auto;
						height: 100vh;

						img {
							width: auto;
							height: 100vh;
						}
					}
                }
                &.tgc {
                    &::after {
                        background-image: unset;
                    }
                }
            }

            //for gradinet on whole page heade bg
            //  removed and gradient added just behind page header title/content wrapper
            &::after{
                // content: '';
                // height: 100%;
                // width: 100%;
                // background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);
                // background-size: cover;
                // background-position: center;
                // background-attachment: fixed;
                // position: absolute;
                // top: 0px;
            }
        }
    }
}