/* common side padding that can apply to each component 
    use @exrtend to import those class
*/
%match-insights-content-padding {
    margin-left: 8px;
    margin-right: 8px;
    padding: 18px 0;

    @include breakpoint(tablet)  {
        margin-left: 29px;
        margin-right: 29px;
        padding: 29px 0;
    }

    @include breakpoint(desktopSidePanel)  {
        margin-left: 35px;
        margin-right: 35px;
        padding: 135x 0;
    }
}

%likelihood-win-purple-circle {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: $likelihoodwin-purple;
}

.page, .webview {
    .wrapper .content-main {
        &.match-insights {
            background-color: $white;
            padding-top: 15px;

            .generic-button_wrapper {
                &.center {
                    text-align: center;
                }
            }
            
            .byline {
                margin-left: 18px;
                margin-right: 18px;
                position: absolute;
                right: 0;
                z-index: 2;
    
                @include breakpoint(tablet)  {
                    margin-left: 29px;
                    margin-right: 29px;
                }
    
                @include breakpoint(desktopSidePanel)  {
                    margin-left: 35px;
                    margin-right: 35px;
                }
            }

            .match-insights-content {
                .loading-indicator {
                     top: calc(50% - #{$headerHeightSmall});
                    @include breakpoint(tablet)  {
                     top: calc(50% - #{$headerHeightLarge});
                    }
                }
            }

            .below-desktop {
                @include breakpoint(desktop) {
                    display: none;
                }
            }

            .desktop-only {
                display: none;

                @include breakpoint(desktop) {
                    display: block;;
                }
            }
        } // .content-main &.match-insights

        /** override generic error message style 
        *   as there is no parent relative positioning
        */
        .error-indicator {
            &.mi-section {
                color: $wim-black;
                position: relative;
                
                top: auto;
                left: auto;
                transform: none;
                text-align: center;
            }
        }
    } // .wrapper .content-main
}

.slamtracker, .slamtrackerPanel{
    .mi-section{
        margin-left: 8px;
        margin-right: 8px;
        padding: 18px 0 0 0;
    }
}

.mi-section {
    @extend %match-insights-content-padding;
    border-bottom: solid 1px $gray_9b;

    &.notitle {
        padding-top: 0 !important;
    }

    &.no-border-bottom {
        border-bottom: none;
    }

    .column-layout {
        padding: 0;
        flex-wrap: nowrap;
        background-color: $white;

        ul {
            margin-left: 0;
            padding-left: 17px;

            &.spacious {

                font-size: px2rem(12);

                li {
                    padding-bottom: 15px;

                    &::marker {
                        font-size: px2rem(12);
                    }
                }

                @at-root .slamtrackerPanel #{&}{
                    font-size: px2rem(12) !important;

                    li {
                        padding-bottom: 15px !important;

                        &::marker {
                            font-size: px2rem(12) !important;
                        }
                    }
                }

                @include breakpoint(tablet) {
                    font-size: px2rem(18);

                    li {
                        padding-bottom: 30px;

                        &::marker {
                            font-size: px2rem(18);
                        }
                    }
                }
            }
        }

        .col {
            &:first-of-type {
                border-right: solid 1px $gray_9b;
                padding-right: 10px;
            }

            &:last-of-type {
                padding-left: 10px;
            }
        }

        .two-col {
            &:first-of-type {
                border-right: solid 1px $gray_9b;

                padding-right: 10px;
            }
            
        }
    } // .column-layout

    &.center {
        text-align: center;
    }

    &.no-bottom-border {
        border-bottom: none;
    }

    a>.generic-button {

        @include breakpoint(medmobile, max) {
            font-size: px2rem(12) !important;
        }
        
        &.rounded-corner {
            .border {
                border-radius: 7px;
            }

            &.full {
                width: 100%;
            }

            .content {

                &:hover, &:active {
                    border-radius: 7px;
                }
            }
        }
    }


    .card-head {
        text-align: center;
        margin-top: 15px;
        margin-bottom: 10px;
        color: $wim-green;

        @include breakpoint(desktop) {
            margin-bottom: 15px;
        }

        .courtName{
            text-transform: uppercase;
            font-size: px2rem(14);
            font-weight: bold;
        }
        .eventName{
            text-transform: uppercase;
            font-size: px2rem(12);
            letter-spacing: 0.86px;
            
            @include breakpoint(desktop) {
                padding-top: 10px;
            }
            @at-root .slamtrackerPanel #{&} { 
                padding-top: unset !important;
            }
        }
    }

    .slamtracker-only {
        display: none;
    }

    .mobile-only { /** place this under mi-section so news could also use this class*/
        @include breakpoint(tablet) {
            display: none;
        }
    }

    .above-tablet { /** place this under mi-section so news could also use this class*/
        @include breakpoint(tablet, max) {
            display: none;
        }
    }
} // .mi-section


.mi-tags {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;

    li {
        flex-basis: 33%;
        border-bottom: solid 3px $gray-light;
        text-transform: uppercase;
        color: $wim-purple;
        font-size: px2rem(13);
        padding: 3px 0;
        text-align: center;
        position: relative;

        &:hover {
            cursor: pointer;
        }
        
        &.selected {
            border-color: $wim-purple;

            &:hover {
                cursor: default;
            }
        }

        &:before {
            content: '|';
            color: $gray-light;
            position: absolute;
            left: 0;
        }

        &:first-child {

            &:before {
                content: '';
            }
        }
    }

    @include breakpoint(desktop, max) {
        margin-left: 8px;
        margin-right: 8px;
    }

    @include breakpoint(desktop) {
        display: none;
    }
} // .mi-tags

.mi-inner-body {
    @extend %match-insights-content-padding;
    padding-top: 0;

    .error-message {
        p {
            padding-bottom: 15px;
        }
    }
}

.webview {
    .wrapper .content-main {
        &.match-insights {
            background-color:transparent;
            background: transparent;
            padding-top: 15px;

            .columm-layout.match-insights-content {
                background-color: #FFFFFF;
            }
            
            .byline {
                padding-top: 5px;
            }
        }
    }

    .mi-section {
        &.notitle {
            padding-top: 0;
        }
    }
}

// Slamtracker Overrides
.slamtracker {
    .slamtracker-only {
        display: block;
    }
}

.mi-section {
    .page .wrapper.slamtracker .content-main &, .webview .wrapper.slamtracker .content-main & {
        margin:0px;
        padding: 8px;

        .column-layout {
            &.mi-media {
                flex-direction: column;
                padding-top:0px;
                .col {
                    margin:0px;
                    padding:0px;
                    padding-bottom:20px;
                    &:first-of-type {
                        //     padding-right: 0px;
                        //     padding-bottom: 0px;
                        border-bottom:solid 1px gray;
                        margin-bottom:15px;
                    }
                }

                .slick-slider {
                    .slick-dots {
                        bottom:0px;
                    }
                }
            }
        }

        @at-root .slamtrackerPanel {
            .mi-section {
            .column-layout {
                &.mi-media {
                    flex-direction: row;

                    .col {
                        width:50%;
                        padding-left:8px;
                        padding-bottom:30px;
                        &:first-of-type {
                            padding-right:8px;
                            padding-left:0px;
                            padding-bottom:30px;
                            border-bottom:none;
                            margin-bottom:0px;
                        }
                    }
                    .slick-list, .slick-slider, .slick-track {
                        height:100%;
                    }
                    .slick-slider {
                        .slick-dots {
                            bottom:-15px;
                        }
                    }
                }
                &.mi-player-glance {
                    flex-direction: row;
                    margin-top: 15px;
                    .col {
                        width:50%;
                        //padding-left:8px;
                        &:first-of-type {
                            //padding-right:8px;
                            padding-left:0px;
                            border-bottom:none;
                            margin-bottom:0px;
                        }
                    }
                    .mi-stats-wrapper {
                        // flex-direction: column-reverse;
                        align-items: center;
                        .ct-chart {
                            text-align: center;
                            margin-bottom: 20px;
                        }

                        .mi-stats-label {
                            font-size:px2rem(12);
                            padding:0px;
                            // margin-bottom: 10px;
                        }

                        ul.spacious {
                            font-size: px2rem(12);
                        }
                        .chart-content {
                            &.circle {
                                margin-bottom: 22px;
                            }
                        }
                    }

                    ul.spacious {
                        font-size: px2rem(12);
                        li {
                            padding-bottom: 15px;
                        }
                    }
                }
            }
            }
        }

        @include breakpoint(tablet) {
            .column-layout {
                &.mi-media {
                    flex-direction: row;

                    .col {
                        width:50%;
                        padding-left:8px;
                        padding-bottom:30px;
                        &:first-of-type {
                            padding-right:8px;
                            padding-left:0px;
                            padding-bottom:30px;
                            border-bottom:none;
                            margin-bottom:0px;
                        }
                    }
                    .slick-list, .slick-slider, .slick-track {
                        height:100%;
                    }
                    .slick-slider {
                        .slick-dots {
                            bottom:-15px;
                        }
                    }
                }
                &.mi-player-glance {
                    flex-direction: row;
                    .col {
                        width:50%;
                        //padding-left:8px;
                        &:first-of-type {
                            //padding-right:8px;
                            padding-left:0px;
                            border-bottom:none;
                            margin-bottom:0px;
                        }
                    }
                    .mi-stats-wrapper {
                        flex-direction: column-reverse;
                        .ct-chart {
                            text-align: center;
                            margin-bottom: 20px;
                        }

                        .mi-stats-label {
                            font-size:px2rem(12);
                            padding:0px;
                        }

                        ul.spacious {
                            font-size: px2rem(12);
                        }
                    }

                    ul.spacious {
                        font-size: px2rem(12);
                        li {
                            padding-bottom: 15px;
                        }
                    }
                }
            }
        }
    }
}

/** news article embedded MatchInsightsCard override */
.news, .slamtracker {
    .mi-section {
        margin: 0;
        padding: 0;
            
        .card-head { 
            display: none;
        }

        .mobile-only {
            @include breakpoint(tablet) {
                display: block;
            }
        }
    
        .above-tablet {
            display: none;

            @include breakpoint(tablet) {
                display: block;
            }
        }
    }
}

.slamtrackerPanel, .slamtracker {
    .mi-section {
        padding: 0 12px 0 13px;

        .mobile-only {
            display: block;
        }

        .mi-pr-ranks-wrapper{
            background: #F1F1F1;
            border-radius: 8px;
            .ranking-cell .player-name{
                display: none;
            }
            .mobile-only {
                display: flex;
                justify-content: center;
                .ranking-cell{
                    width: 100%;
                    .generic-button_wrapper{
                        margin-bottom: 0px;
                        width: 100%;
                        .generic-button{
                            width: 100%;
                            .content{
                                height: 100%;
                            }
                            .border{
                                border-radius: 8px;
                            }
                        }
                    }
                }
            }
        }
    
        .above-tablet {
            display: none;
        }
    }
}

/** side panel open */
.expanded {
    /** if side panel is open, treat the embedded card layout as mobile */
    .news {
        .mi-section {
            margin: 0;
            padding: 0;
                
            .card-head { 
                display: none;
            }
    
            .mobile-only {
                @include breakpoint(tablet) {
                    display: block;
                }

                @include breakpoint(extraLargeDesktop) {
                    display: none;
                }
            }
        
            .above-tablet {
                display: none;
    
                @include breakpoint(table) {
                    display: none;
                }

                @include breakpoint(extraLargeDesktop) {
                    display: block;
                }
            }
        }
    }
}