/* By the Number section*/
.mi-player-glance {
    .col {
        flex-basis: 50%;
    }
}

.mi-stats-wrapper {
    position: relative;
    justify-content: center;
    display: flex;

    @at-root .slamtrackerPanel #{&}{
        flex-wrap: wrap;
    }

    @include breakpoint(tablet, max) {
        flex-wrap: wrap;
    }

    .mi-stats-label {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 20px;

        @include gotham(medium);
        font-size: px2rem(12);

        @at-root .slamtrackerPanel #{&}{
            font-size: px2rem(12) !important;
            order: 2;
            padding-top: 40px;
            text-align: center;
            flex-basis: 100%;
        }

        @include breakpoint(tablet) {
            font-size: px2rem(18);
        }

        @include breakpoint(tablet, max) {
            order: 2;
            padding-top: 40px;
            text-align: center;
            flex-basis: 100%;
        }
    }
    .chart-content {


        @include breakpoint(tablet, max) {
            order: 1;
        }

        &.circle {
            width: 118px;
            height: 118px;
            border: solid 4px $gray_bb;
            border-radius: 50%;
            background-color: $white;
            position: relative;

            .circle-labels {
                position: absolute;
                text-align: center;
                top: 30%;
                width: 100%;

                .value {
                    @include gotham(medium);
                    font-size: px2rem(44);
                    line-height: px2rem(28);
                }

                .unit {
                    display: block;
                    font-size: px2rem(24);
                    text-transform: uppercase;
                }
            }

            @include breakpoint(tablet, max) {
                width: 86px;
                height: 86px;
                border: solid 4px $gray_bb;
                margin: auto;

                .circle-labels {
                    .value {
                        @include gotham(medium);
                        font-size: px2rem(38);
                        line-height: px2rem(22);
                    }

                    .unit {
                        display: block;
                        font-size: px2rem(18);
                        text-transform: uppercase;
                    }
                }
            }

        } // circle
    } // chart-content

    .ct-chart {
        position: relative;
        left: 0px;
        top: 3px;
        width: 100%;
    
            .chart-value {
                @include gotham(medium);
                position: absolute;
                text-align: center;
                width: 100%;                
                top: 25%;
                bottom: 25%;
                font-size: px2rem(44);

                sup {
                    font-size: px2rem(18);
                    top: -5px;
                    right: -1px;
                    position: relative;
                }

                @at-root .slamtrackerPanel #{&}{
                    top: unset !important;
                    bottom: unset !important;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: px2rem(36);

                    sup {
                        font-size: px2rem(14);
                        top: -5px;
                        right: -1px;
                    }
                }

                @include breakpoint(tablet, max) {
                    top: 20%;
                    bottom: 25%;
                    font-size: px2rem(36);

                    sup {
                        font-size: px2rem(14);
                        top: -5px;
                        right: -1px;
                    }
                }
            }

            .ct-chart-donut {
                overflow: visible;
            }

    }// .ct-chart

    /* chart overrides */
    .ct-fill-donut-label {
        height: 100%;
        left: 0px !important;
        right: 0px !important;
        top: 0px !important;
    }

    .team1 {
        .ct-chart-donut .ct-series-a .ct-slice-donut {
            stroke: $team1_color; 
            // stroke-width: 7px!important;
        }

        // this is the background fill that makes it look like there are borders
        .ct-chart-donut .ct-fill-donut .ct-slice-donut {
            stroke: $donut-fill-color;
            opacity: 1;
            stroke-width: 14px!important;
        }
        .ct-chart-donut .ct-series-b .ct-slice-donut {
            stroke: $donut-fill-color;
            opacity: 1;
            stroke-width: 14px!important;
        }

        @at-root .slamtrackerPanel #{&}{
            .ct-chart-donut .ct-fill-donut .ct-slice-donut {
                stroke-width: 10px!important;
            }
            .ct-chart-donut .ct-series-b .ct-slice-donut {
                stroke-width: 10px!important;
            }
        }

        @include breakpoint(tablet, max) {
            .ct-chart-donut .ct-fill-donut .ct-slice-donut {
                stroke-width: 10px!important;
            }
            .ct-chart-donut .ct-series-b .ct-slice-donut {
                stroke-width: 10px!important;
            }
        }

                        
    }
    .team2 {
        .ct-chart-donut .ct-series-a .ct-slice-donut {
            stroke: $team2_color; 
            // stroke-width: 7px!important;
        }

        // this is the background fill that makes it look like there are borders
        .ct-chart-donut .ct-fill-donut .ct-slice-donut {
            stroke: $donut-fill-color;
            opacity: 1;
            stroke-width: 14px!important;
        }
        .ct-chart-donut .ct-series-b .ct-slice-donut {
            stroke: $donut-fill-color;
            opacity: 1;
            stroke-width: 14px!important;
        }	

        @at-root .slamtrackerPanel #{&}{
            .ct-chart-donut .ct-fill-donut .ct-slice-donut {
                stroke-width: 10px!important;
            }
            .ct-chart-donut .ct-series-b .ct-slice-donut {
                stroke-width: 10px!important;
            }	
        }

        @include breakpoint(tablet, max) {
            .ct-chart-donut .ct-fill-donut .ct-slice-donut {
                stroke-width: 10px!important;
            }
            .ct-chart-donut .ct-series-b .ct-slice-donut {
                stroke-width: 10px!important;
            }	
        }
    }
} // mi-stats-wrapper

.slamtracker {
    .chart-content {
        &.circle {
            margin:auto;
        }
    }
}