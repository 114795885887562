.section-header{
    height:25px;
    width:100%;
    @include gotham(medium);
    color:$wim-green;
    border-bottom:1px solid $select-menu-border;
    position:relative;
    margin-bottom: 25px;
    margin-top: 30px;
    .header-title,.header-select{
        position:absolute;
        top:50%;
        transform:translateY(-50%);
    }

    .header-title{
        font-size:18px;
        text-transform:uppercase;
    }

    .header-select{
        font-size:16px;
    }
}