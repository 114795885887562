@mixin breakpoint($break, $type : min, $override : false) {
    @if $override{
        @include breakpointBreaks($break, $type, 0){ @content };
    }
    @else{
        @at-root .compressed #{&}{
            @include breakpointBreaks($break, $type, 374){ @content };
        }

        @at-root .default #{&}{
            @include breakpointBreaks($break, $type, 0){ @content };
        }
    }
}

@mixin breakpointBreaks($break, $type, $offset : 0) {
    @if $type == min{
        @if $break == mobile {        // 320px
            @media only screen and (min-width: ($offset + 320px)) { @content; }  //primary
        } @else if $break == medmobile {
            @media only screen and (min-width: ($offset + 360px)) { @content; }
        } @else if $break == largeMobile {
            @media only screen and (min-width:($offset + 375px)) { @content; }
        } @else if $break == extraLargeMobile {
            @media only screen and (min-width:($offset + 390px)) { @content; }
        } @else if $break == extraSmallTablet { // 500px
            @media only screen and (min-width: ($offset + 500px)) { @content; }
        } @else if $break == smallTablet { // 692px
            @media only screen and (min-width: ($offset + 692px)) { @content; }
        } @else if $break == tablet { // 768px
            @media only screen and (min-width: ($offset + 768px)) { @content; }  //primary
        } @else if $break == largeTablet { // 820px
            @media only screen and (min-width: ($offset + 820px)) { @content; }
        } @else if $break == smallDesktop {  // 991px
            @media only screen and (min-width: ($offset + 991px)) { @content; }
        } @else if $break == desktop {  // 1024px
            @media only screen and (min-width: ($offset + 1024px)) { @content; }  //primary
		} @else if $break == medDesktop {  // 1200px
            @media only screen and (min-width: ($offset + 1200px)) { @content; }  
        } @else if $break == desktopSidePanel {  // 1365px
            @media only screen and (min-width: ($offset + 1365px)) { @content; } //robin made this up for draws text overlap (medium-extra-large)
        } @else if $break == largeDesktop { // 1601px
            @media only screen and (min-width: ($offset + 1601px)) { @content; }  //primary
        } @else if $break == extraLargeDesktop { // 1964px
            @media only screen and (min-width: ($offset + 1964px)) { @content; }  //primary
        } @else {
            @media only screen and (min-width: ($offset + $break)) { @content; }
        }
    }
    @else if $type == max {
        @if $break == mobile {        // 319px
            @media only screen and (max-width: ($offset + 319px)) { @content; }
        } @else if $break == medmobile {
            @media only screen and (max-width: ($offset + 359px)) { @content; }
        } @else if $break == largeMobile {
            @media only screen and (max-width: ($offset + 374px)) { @content; }
        } @else if $break == extraLargeMobile {
            @media only screen and (min-width:($offset + 389px)) { @content; }
        } @else if $break == extraSmallTablet { // 499px
            @media only screen and (min-width: ($offset + 499px)) { @content; }
        } @else if $break == smallTablet { // 691px
            @media only screen and (min-width: ($offset + 691px)) { @content; }
        } @else if $break == tablet { // 767px
            @media only screen and (max-width: ($offset + 767px)) { @content; }
        } @else if $break == largeTablet { // 819px
            @media only screen and (min-width: ($offset + 819px)) { @content; }
        } @else if $break == smallDesktop { // 990px
            @media only screen and (max-width: ($offset + 990px)) { @content; }
        } @else if $break == desktop {  // 1023px
            @media only screen and (max-width: ($offset + 1023px)) { @content; }
		} @else if $break == medDesktop {  // 1023px
            @media only screen and (max-width: ($offset + 1200px)) { @content; }
        } @else if $break == desktopSidePanel { // 1364px
            @media only screen and (max-width: ($offset + 1364px)) { @content; }
        } @else if $break == largeDesktop { // 1600px
            @media only screen and (max-width: ($offset + 1600px)) { @content; }
        } @else if $break == extraLargeDesktop { // 1964px
            @media only screen and (max-width: ($offset + 1964px)) { @content; }
        } @else {
            @media only screen and (max-width: ($offset + $break)) { @content; }
        }
    }
    @else if $type == mid {
        @if $break == desktopSidePanel { // 1364px
            @media only screen and (max-width: 1600px) and (min-width: 1365px) { @content; }
        } 
		@else if $break == slamtrackerPanel { // 1364px
            @media only screen and (max-width: 1365px) and (min-width: 768px) { @content; }
        } 
    }
}
