hr.bottom-divide {
    border: none;
    background-color: $wim-green;
    height: 2px;
}
.player-matches-widget-container {
    border-bottom: 1px solid $wim-green;
    padding-bottom: 20px;
    padding-top: 20px;
    .match-btn {
        margin-top: 10px;
    }
    position: relative;
    .image-gallery-swipe {
        overflow:hidden;
    }
    &.no-nav {
        .nav-wrapper {
            display: none !important;
        }
    }
    &.unavailable {
        text-align: center;
        color: $wim-purple;
        h1 {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;
            @include breakpoint(tablet, min) {
                font-size: 16px;
            }
        }
        hr {
            border: none;
            width: 15px;
            height: 1px;
            background: $wim-purple;
        }
        p {
            font-size: 10px;
            padding: 0% 23%;
            line-height: 20px;
            @include breakpoint(tablet, min) {
                font-size: 12px;
            }            
            font-weight: 500;
        }
    }
    .carousel-wrapper {
        display: flex;
        flex-flow: column;
        flex-direction: column-reverse;
        .player-matches-widget {
            position: relative;
            height: auto;
            width: 100%;
            overflow: hidden;
            // height: 280px;
            @include breakpoint(tablet, min) {
                min-height: 209px;
                padding: 0px 50px;
            }            
            .current-match {
                display: flex;
                height: 100%;
                width: 100%;
                justify-content: space-between;
                .current-match-player {
                    flex: 0 1 auto;
                    display: flex;
                    flex-direction: column;
                    margin: 0 10px;
                    padding-left: 0%;
                    width: 20%;
                    &.team-one {
                        &.alternate {
                            justify-content: center;
                        }
                    }
                    &.team-two {
                        &.alternate {
                            .player-image {
                                width: 70px;
                            }    
                        }
                    }
                    .player-images {
                        .or-text {
                            width: 100%;
                            text-align: center;
                            margin-top: 20px;
                            color: $wim-purple;
                        }
                        .player {
                            display: flex;
                            justify-content: center;
                            flex-flow: column;
                        }
                        .player-two {
                            display: flex;
                            flex-flow: column;
                            margin-top: 20px;
                        }
                    }
                }
                .current-match-info {
                    display: flex;
                    flex-direction: column;
                    font-size: 12px;
                    line-height: 14px;
                    @include breakpoint (tablet, min) {
                        font-size: 14px;
                        line-height: 28px;
                    }                    
                    text-align: center;
                    flex: 0 0 auto;
                    .match {
                        color: $wim-purple;
                        text-align: center;
                        &-type, &-round {
                            font-weight: bold;
                            font-size: 14px;
                            @include breakpoint (tablet, min) {
                                font-size: 16px;
                            }
                            text-transform: uppercase;
                        }
                    }
                    hr {
                        background-color: $wim-purple;
                        height: 1.45px;
                        width: 20px;
                        border-style: none;
                    }
                }
            }
            .player-image {
                width: 100%;
                height: auto;
                margin: auto;
                margin-bottom: 5px;
                @include breakpoint(tablet, min) {
                    width: 147px;
                    height: auto;
                }
            }
            &.multiples {
                .player-image {
                    @include breakpoint(tablet, min) {
                        width: 75px;
                        height: auto;
                    }
                }
            }
            .player-info {
                color: $wim-black !important;
                line-height: normal;
                white-space: pre-wrap;
                display: flex;
                font-size: 14px;
                text-transform: uppercase;
                text-align: center;
                width: fit-content;
                margin: auto;
                .name {
                    text-align: center;
                    width: 100%;
                    color: $wim-green;
                    font-weight: bold;
                    font-size: 12px;
                    @include breakpoint(tablet, min) {
                        font-size: 16px;
                    }
                }
            }

        }

        .nav-wrapper {
            top: unset;
            display: flex;
            // bottom: 0px;
            position: relative;
            // @include breakpoint(tablet, min) {
            //     bottom: 10px;
            // }
            .mobile {
                display: flex;
                align-items: center;
                font-size: 20px;
                color: #9B9B9B;
                @include breakpoint(tablet, min) {
                    display: none;
                }
            }
            .nav-dot {
                color: #C7C7C7;
                &.selected {
                    color: $wim-green;
                }
            }
        }

        .image-gallery {
            margin-bottom: 20px;
        }
        .image-gallery-left-nav, .image-gallery-right-nav {
            display: none;
            font-size: 4em;
            @include breakpoint(tablet, min) {
                display: block;
            }
            &::before {
                color: #9B9B9B;
                text-shadow: none;
            }
        }

    }
}