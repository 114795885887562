/** inline-gallery styles **/
//react libary custom styling
.image-gallery-slides {
	overflow: inherit !important;
}
.image-gallery-bullets {
	bottom: 10.5%;
	.image-gallery-bullet {
		padding: 2.5px !important;
		background-color: $wim-green;
		opacity: .5;
		box-shadow: none;
		border: none;
		&.active {
			opacity: 1;
			background-color: $wim-green;
		}
	}
}
.photo-gallery {
	.image-gallery-content {
		.image-gallery-thumbnails-wrapper {
			&.bottom {
				width: 85%;
				margin: auto;
				z-index: 99999;
				@include breakpoint(tablet) {
					width: 90%;
				}
			}
		}
		.image-gallery-left-nav {
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
			&::before {
				left: 13%;
			}
		}
		.image-gallery-right-nav {
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
			&::before {
				right: 13%;
			}
		}
		.image-gallery-left-nav, .image-gallery-right-nav {
			background-color: rgba(255, 255, 255, 0.35);
			height: 55px;
			width: 30px;
			padding: 0;
			top: 40%;
		}
		.image-gallery-left-nav,.image-gallery-right-nav {
			&::before {
				top: 20%;
				font-size: 55px;
				position: absolute;
				text-shadow: none;
			}
		}
	}
}
.photo-gallery {
	.nav-wrapper {
		flex-grow: 1;
		justify-content: space-between;
		.arrow-wrapper-left, .arrow-wrapper-right {
			color:$gray-disable;
			display: block !important;
		}
		.arrow-wrapper-left {
			left: 0;
		}
		.arrow-wrapper-right {
			right: 0;
		}
	}	
	@include gotham(medium);
	&.thumbnail-gallery {
		.nav-wrapper {
			height: 50px;
            top: unset;
			bottom: 20px;
			position: absolute;
		}
		overflow: hidden;
		.photo-info{
			.photo-title{
				margin-top: 0px;
				height: auto;
				white-space: initial;
				line-height: initial;
			}
			.photo-credit {
				height: auto;
				line-height: 12px;
			}
			.photo-date {
				height: 12px;
			}
		}		
	}
	&.dot-gallery{
		overflow: hidden;
		.nav-wrapper {
			top: 85%;			
		}
		.photo-container {
			div {
				white-space: nowrap;
				transition: 0.5s;
			}
			.arrow-wrapper-left,.arrow-wrapper-right{
					top: 50%;
					transform: translate(0,-50%);
					display: block;
					background-color: rgba($white,.35);
					height: 55px;
					width: 30px;

					&.disabled{
						display: none;
					}
			}
			.arrow-wrapper-left{
				left: 0;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
			}
			.arrow-wrapper-right{
				right: 0;
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
			}
			.wim-icon-left-arrow, .wim-icon-right-arrow {
				color:$white;
				font-size: 38px;
			}
			.wim-icon-right-arrow{
				right: 3px;
			}
			.wim-icon-left-arrow{
				left: 3px;
			}
		}

		.photo-info{
			margin-top: 20px;
			.photo-title{
				margin-top: 0px;
				height: auto;
				white-space: initial;
				line-height: initial;
			}
			.photo-credit {
				height: auto;
				line-height: 12px;
			}
			.photo-date {
				height: 12px;
			}
		}

		.thumbnails{
			display: none;
		}
	}

	&.thumbnail-gallery{
		.dots{
			display: none;
		}
		.photo-info {
			margin-top: 20px;
		}
	}

	&.two-col,&.three-col,&.four-col{
		@include breakpoint(tablet,max){
			&.dot-gallery{
				.photo-info{
					.photo-credit{
						margin-top: 6px;
					}
				}
			}
			.thumbnails{
				ul,li{
					height: 100px;
				}
				li{
					width: 150px;
				}
			}
			.arrow-wrapper-left,.arrow-wrapper-right{
				height: 85px;
				@include breakpoint(tablet) {
					height: 100px;
				}				
			}
		}
	}

	.arrow-wrapper-left,.arrow-wrapper-right{
		// display: none;
		position: absolute;
		top: 0;
		width: 31px;
		height: 67px;
		cursor: pointer;
	}

	.wim-icon-left-arrow, .wim-icon-right-arrow { 
		position: absolute;
		top: 50%;
		transform: translate(0,-50%);
		font-size: 42px;
	}

	.wim-icon-right-arrow {
		right: 0;
	}
	.wim-icon-left-arrow {
		left: 0;
	}

    .photo-container { 
		position: relative;
		img {
			max-width: 100%;
			max-height: 100%;
			width: 100%;
			display: inline-block;
		}
	}

	/*.videoContent {
		&:hover { cursor: default; }
		.thumbnails {
			ul, li { height: 59px; }
		}
	}*/

	.dots{
		margin-top: 10px;
		position: relative;
		font-size: 14px;
		color:$wim-green;
		
		.dot-strip{
			display: inline-block;
		}

		.dot-wrapper{
			text-align: center;
		}
		ul{
			width: 75px;
		}
		ul, li { 
			margin: 0;
			padding: 0;
			list-style: none;
			text-align: center;
		}
		li {
			float: left;
			width: 15px;
			opacity:.4;
			&.selected{
				opacity: 1;
			}

			&:hover {
				cursor: pointer;
			}
		}
	}

	.thumbnails { 
		margin-top: 10px;
		position: relative;
		padding: 0 31px;
		.thumbwrapper {
			width: 100%;
			overflow: hidden;
			height: 100%
		}
		ul, li { 
			margin: 0;
			padding: 0;
			list-style: none;
		}
		ul {
			overflow: hidden;
			height: 67px;
			transform: (translate3d(0px,0px,0px));
			transition: (0.5s);
			width: 150%;
			transform: translate3d(0px, 0px, 0px);
		}
		li {
			float: left;
			width: 101px;
			height: 67px;
			margin-left: 7px;
			background: #000000;
			&:first-child { margin-left: 0; }

			img {
                opacity: 0.6;
                max-width: 100%;
                min-width: 100%;
                display: block;
			}

			&:hover {
				cursor: pointer;
			}

			&:hover, &.selected {
				img {
					opacity: 1;
				}
			}

		}
		.arrow-wrapper{	
			&-left{
				left: 0;
				display: inline-block;
			}

			&-right{
				right: 0;
				text-align: right;
				display: inline-block;
			}
		}
		.wim-icon-left-arrow,.wim-icon-right-arrow{
				color:$gray-disable;
		}
	}
}