.content-link{
    position: relative;
    cursor: pointer;
    a:hover{
        text-decoration: none;
    }
    &.full{
        width: calc(100% + 2 * 16px) !important;
        margin: 0 -16px;
        .content-link-image{
            padding-bottom: 0;
            height: unset;
            background-size: auto;
            img{
                position: relative;
                top: unset;
                transform: unset;
                margin-bottom: -4px;
            }
        }
        @include breakpoint(desktop){
            width: calc(100% + 2 * 38px) !important;
            margin: 0 -38px;
            .content-link-title{
                right: 44px;
            }
        }
        .content-link-title{
            position: absolute;
            right: 22px;
            color: white;
            max-width: calc(50% - 56px);
            text-align: right;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            z-index: 1;
            @include breakpoint(tablet, max){
                right:unset;
                left: 50%;
                transform: translateX(-50%);
                text-align: center;
                bottom: 0;
                top: unset;
                align-items: center;
                margin-bottom: 15px;
                max-width: calc(100% - 30px);
                .description, .whiteStripe-container{
                    display: none;
                }
                .text{
                    font-size: 20px;
                }
            }
            .text{
                font-size: 26px;
            }
            .description{
                font-size: 14px;
            }
            .whiteStripe-short{
                margin: 15px 0;
            }
        }
        .content-link-image{
            &::after{
                content: "";
                height: 100%;
                width: 100%;
                background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5) 40%, transparent);
                position: absolute;
                top: 0px;
                right: 0px;
            }
        }
    }
    .content-link-image {
        position: relative;
        width: 100%;
        padding-bottom: 66.66%;
        height: 0px;
        background-color: $black;
        background-size: cover;
        overflow: hidden;
        i{
            color: #fff;
            font-size: 56px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: rgba(0, 0, 0, .3);
            border-radius: 50px;
            z-index: 1;
        }
        img {
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .content-date.center{
        display: flex;
        justify-content: center;
        align-items: center;        
        @include gotham-book();
        color: $wim-green;
        text-transform: uppercase;
        margin: 14px 0 0 0;
        h6{
            font-size: 11px;
            .content-date{
                margin: 0;
            }
        }
        .date-part{
            @include gotham(medium);
            margin-right: 5px;
        }
    }

    .content-link-title {
        color: $wim-purple;
        @include gotham(medium);
        font-size: 18px;
        margin-top: 5px;
        margin-bottom: 8px;
        line-height: 1.3em;
        letter-spacing: 1.5px;
        &.center{
            text-align: center;
            font-size: 26px;
            max-width: 352px;
            margin: 5px auto 8px auto;
            @include breakpoint(tablet, max){
                font-size: 20px;
                max-width: 265px;
            }
        }
    }
    .content-link-description{
        @include gotham(medium);
        font-size: 14px;
        text-align: center;
        color: black;
        max-width: 346px;
        margin: 12px auto 19px auto;
        line-height: 21px;
        @include breakpoint(tablet, max){
            display: none;
        }
    }
}
