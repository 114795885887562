
.thank-you{
    text-align:center;
    @include gotham-book();
    width:95%;
    min-width:unset;
    margin:0 auto;
    @include breakpoint(desktop,min){
        width: 80%;
        min-width:600px;
    }
    .heading{
        color: $wim-green;
        font-size: 20px;
        line-height: 24px;
        @include breakpoint(tablet, min) {
            font-size: 28px;
            line-height: 34px;
        }
        letter-spacing: 1px;
        line-height: 1.1em;
        margin-bottom: 20px;
        position: relative;
        padding-bottom: 10px;
        margin-top: 20px;
        &::after {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            height: 1.75px;
            background: $wim-green;
            bottom: 0px;
            width: 32px;
        }
    }
    .sub-heading{
        color: $wim-black;
        font-size: 14px;
        line-height: 14px;
        @include breakpoint(tablet, min) {
            font-size: 16px;
            line-height: 19px;
        }
        line-height: 19px;
        margin: auto;
        margin-bottom: 30px;
        width: 90%;
        @include breakpoint(desktop, min) {
            width: 55%;
        }
    }
    .urgent-contact {
        .contact-message {
            color: #333333;
            font-size: 12px;
            line-height: 14px;
            @include breakpoint(tablet, min) {
                font-size: 16px;
                line-height: 19px;
            }
            margin-bottom: 30px;
        }
        .contact-details {
            font-size: 12px;
            @include breakpoint(tablet, min) {
                font-size: 16px;
            }
            color: #00703C;
            margin-bottom: 30px;
        }
    }
    .urgent-contact,.contact{
        color: $wim-black;
        font-size: 12px;
        line-height: 14px;
        @include breakpoint(tablet, min) {
            font-size: 16px;
            line-height: 19px;
        }
        margin-bottom: 5px;
    }

    hr{
        width: calc(100% - 20px);
        margin-left:10px;
        margin-right:10px;
        margin-top:20px;
        color:$wim-black;
    }
}