.court-title-divider{
    @include gotham(medium);

    .match-location{
        text-align: left;
        width: 50%;
        color: $select-menu-text;
        text-transform: uppercase;
        display: inline-block;
        font-size: 14px;
    }

    .match-time{
        color: $wim-text-purple;
        text-transform: uppercase;
        text-align: right;
        width: 50%;
        font-size: 14px;
        float: right;
        @include gotham(medium);

        .match-time-modifier{
            font-size: 10px;
            margin-right: 5px;
            display: inline-block;

            .time-string {
                margin-left: 3px;
                font-size: 14px; 
            }
        }

        .sidepanel-column.upcoming & {
            display:none;
        }
    }

    hr{
        background-color: $select-menu-border;
        height: 1px;
        border: none;
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
    }
}