/** playerindex styles **/
.playerIndex {
    #content-filters {
        z-index: 2;
        background-color: $white;
        padding: 10px 0;
        width: 100vw;

        // sidepanel open or cloased based on user selection
		@include breakpoint(desktopSidePanel, mid){
			//width: calc(100vw - #{$sidebarWidth});
		}

		// sidepanel always open
		@include breakpoint(largeDesktop){
			width: calc(100vw - #{$sidebarWidth});
		}

		// sidepanel always open and content area centered with curtains
		@include breakpoint(extraLargeDesktop){
			width: calc(#{$maxWidth} - #{$sidebarWidth});

		}

    }
    
    #playersList {
        clear: both;
        padding: 0px 25px;
        
        .letter-section {
            width: 100%;
            display: table;
            table-layout: fixed;
            padding: 0px;
            
            .column {
                width: 50%;
                display: table-cell;
                padding: 0px 10px;
                .header-type-title{ color: $wim-green; font-size: 16px; font-family: 'Gotham 5r', Helvetica, Arial, sans-serif; text-transform: uppercase; text-align: center; padding: 10px 0px; }
            }
            ul {
                list-style: none; margin: 0px; padding: 0px;
                li {
                    line-height: 24px;
                    border-bottom: 1px solid #eeeeee;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            a { color: #046633; text-decoration: none; }
        }
        .letter-header {
            width: 100%;
            background: #265126;
            color: white;
            font-size: 24px;
            padding: 0px 10px;
            margin-bottom: 10px;
        }
        .favorite {
            position: relative;
            margin-right: 20px;
            
            &:after {
                position: absolute;
                left: 0;
                content: "\2606";
            }
            &.favorited {
                &:after{
                    font-size: 18px;
                    content: "\2605";
                    color: #F9E300;
                }
            }
        }
    }
}

.page {
	&.compressed {
		 #content-filters {
			width: 100%;
			position: relative;
			top: unset;
		}
	}

	&.expanded {
		 #content-filters {
			// sidepanel open or cloased based on user selection
			@include breakpoint(desktopSidePanel, mid){
				width: calc(100vw - #{$sidebarWidth});
			}
		}
	}
}