.my-wimbledon-page {
	.profile-form {
		input[type='text']:disabled {
			background-color: $gray-light;
		}

		.address-field {
			position: relative;
			margin-top: 20px;
		}

		.addressSelectContainer {
			border: 1px solid #979797;
			height: 130px;
			overflow-y: scroll;
			position: absolute;
			width: 100%;
			z-index: 1;
			background: white;
			.address-even,
			.address-odd {
				padding: 20px;
				cursor: pointer;
				&.selected {
					background: rgba($color: #7e7e7e, $alpha: 0.4);
				}
			}
			.address-even {
				background: rgba($color: #d8d8d8, $alpha: 0.4);
			}
		}
	}

	.ballot-msg {
		font-size: 14px;
	}
}

.error-text {
	color: red;
	font-size: 14px !important;
}
