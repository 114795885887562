.twitter-embed{
	width: 100%;
	margin-top: 10px;
	
	.timeline {
		width: 80%;
		margin: auto;
		height: 300px;
		overflow: auto;
		display: block;

		@include breakpoint (tablet){
			width: 50%;
			height: 400px;
		}
	}
}