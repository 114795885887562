/** stubs styles **/

.stub_box {
    position: absolute;
    //top: calc(50% - 114px);
    left: calc(50% - 250px);
    //height: 228px;
    transform:translateY(50%);
    width: 500px;
    background: rgba($white, 0.5);
    text-align: center;
    padding: 47px 30px;

    .text {
		@include gotham(medium);
        font-size: 24px;
        color: #FFFFFF;
        letter-spacing: 1px;
        text-align: center;
        text-transform: uppercase;
    }

    &.dark {
        background:rgba(0,0,0,0.5);
    }

	&.centerV {
		transform: translateY(-50%);
    	top: calc(40% + 50px);
	}

	@include breakpoint(mobile) {
		left: calc(50% - 140px);
		width: 280px;
		.text {
			font-size: 16px;
		}
	}

	@include breakpoint(tablet) {
		left: calc(50% - 250px);
		width: 500px;
		.text {
			font-size: 24px;
		}
	}
}
