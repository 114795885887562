/** player-matchup-overview styles **/

.featuredtable {
	position: relative;
	width: 100%;
	margin: auto;
	// margin-bottom: 20px;
	.roundinfo,
	.event-title {
		width: 100%;
		text-transform: uppercase;
		text-align: center;
	}
	.view-more-btn {
		margin-top: 20px;
	}
	.player-profile-read-more {
		margin: auto;
	}
	.player-score-table {
		.more-content {
			color: $wim-green;
			text-transform: uppercase;
		}
		span.more-content-container {
			cursor: pointer;
			display: inline-block;
			width: 20px;
			height: 20px;
			border: 1px solid $wim-green;
			margin-right: 5px;
			margin-bottom: 5px;
		}
	}
	.match-details {
		overflow-y: hidden;
		transition-property: all;
		transition-duration: 0.5s;
		transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	}

	.matchup-content {
		display: block;
		.match-details {
			width: 100%;
		}
	}
	@include breakpoint(desktop) {
		.matchup-content {
			display: flex;
			flex-flow: row;
			justify-content: space-between;
			.match-details {
				width: calc(50% - 20px);
			}
		}
	}

	@include breakpoint(mobile) {
		.matchup-content {
			.match-details {
				.related-cnt {
					.news-tile {
						width: 100%;
						margin: 7px 0 0 0;
					}
				}
			}
		}
	}
}
