.ticket-view-ticket-stub-container-div {
    position: relative;
    width: 100%;
    height: auto;
}

.web-ticket-stub-parent-container {
    width: 100%;
    position: relative;
    align-items: center;
    text-align: center;
    margin-bottom: 8px;
}

.web-ticket-stub-action-parent-container {
    width: 400px;
    position: relative;
    align-items: center;
    text-align: center;
    margin-bottom: 8px;
}

.web-ticket-stub-container {
    width: 100%;
    height: 110px;
    margin-bottom: 8px;
    display: inline-flex;
    margin-bottom: 4px;
    justify-content: center;
    position: relative;
    @include breakpoint(desktop) {
        height: 95px;
    }
}

.web-ticket-stub-shape-container {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #007138;
    border-right: 0px;
    border-radius: 8px;
}

.web-ticket-stub-row-cell-val {
    font-weight: bold;
}

.web-ticket-stub-row-cell-image {
    display: none;
    @include breakpoint(desktop) {
        display: inline-flex;
        float: left;
        width: 8%;
        height: 100%;
    }
}

.web-ticket-stub-image-container {
    width: 70px;
}

.web-ticket-stub-image {
    border-radius: 8px 0px 0px 8px;
    max-width: 100%;
    margin-top: -1px;
    margin-left: -1px;
}

.web-ticket-stub-table-rows {
    height: 100%;
}

.web-ticket-stub-table-rows:first-child {
    margin-left: 8px;
    @include breakpoint(desktop) {
        margin-left: 0px;
    }
}

.web-ticketview-header-margin {
    margin-left: 8px;
    @include breakpoint(tablet) {
        margin-left: 4px;
        margin-right: 12px;
    }
    @include breakpoint(desktop) {
        margin-left: 4px;
        margin-right: 4px;
    }
}

.web-ticket-stub-row-cell-header {
    font-size: 14px !important;
    text-transform: uppercase !important;
    font-weight: bold;
    margin-top: 4px !important;
    @include breakpoint(tablet) {
        text-transform: capitalize !important;
        font-weight: normal;
        margin-top: 0px !important;
    }
}


.web-ticket-stub-row-cell {
    @media (width: 320px) {
        width: 70%;
        font-size: 10px;
    }

    display: inline-block;
    width: 80%;
    text-align: left;
    float: left;
    font-size: 12px;
    color: #333333;
    text-transform: capitalize;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: 2px;
    @include breakpoint(tablet) {
        display: inline-flex;
        width: 12% !important;
        font-size: 14px;
        height: 100%;
        vertical-align: middle;
        align-items : center;
        line-height: 20px;
        margin-top: 0px;
    }
    @include breakpoint(desktop) {
        width: 11% !important;
    }
}

.single-ticket-stub {
    justify-content: center;
    display: flex;
    padding-bottom: 16px;

    @media (width: 320px) {
        padding-bottom: 16px;
    }
    padding-bottom: 32px;
}

.single-ticket-stub-display {
    @media (width: 375px) {
        margin-left: -4px;
    }
    display: flex;
    @include breakpoint(tablet) {
        margin-left: 0px;
    }
}

.single-ticket-desktop-border-shape {
    @media (width: 320px) {
        width: 100%;
    }
    @media (width: 353px) {
        width: 350px;
    }
    @include breakpoint(tablet) {
        height: 160px;
        width: 516px;
    }
}

.single-ticket-stub-container-webview {
    display: flex;
    @media (width: 320px) {
        height: 83px;
    }
    width: 326px;
    height: 110px;

    @include breakpoint(tablet) {
        width: 460px;
        margin-top: 0px;
        height: 160px;
    }
}

.single-ticket-stub-container {
    display: flex;
    @media (width: 320px) {
        height: 90px;
        width: 266px;
    }
    width: 326px;
    height: 110px;

    @include breakpoint(tablet) {
        width: 460px;
        margin-top: 0px;
        height: 160px;
    }
}

.single-ticket-stub-content {
    position: absolute;
}

.single-ticket-text-container {
    display: inline-block;
    margin-bottom: 1px;
    border-right: 4px dashed #BBBBBB;
    margin-left: 8px;
    padding-top: 8px;
    margin-top: 0px;
    text-align: left;
    width: 234px;
	font-family: 'Gotham 4r', Helvetica, Arial, sans-serif;
    @include breakpoint(tablet) {
        width: 85%;
    }

    @media (width: 320px) {
        padding-top: 8px;
        margin-top: 0px;
        padding-right: 0px;
        width: 204px;
        margin-left: 4px;
    }

    @include breakpoint(tablet) {
        padding-top: 16px;
        text-align: left;
        margin-top: 0px;
        padding-right: 40px;
        width: 67%;
        margin-left: 16px;
    }    
}

.single-ticket-text-container-webview {
    display: inline-block;
    margin-bottom: 1px;
    border-right: 4px dashed #BBBBBB;
    margin-left: 8px;
    padding-top: 8px;
    margin-top: 0px;
    text-align: left;
    width: 234px;
	font-family: 'Gotham 4r', Helvetica, Arial, sans-serif;
    pointer-events: none;
    @include breakpoint(tablet) {
        width: 85%;
    }

    @media (width: 320px) {
        padding-top: 8px;
        margin-top: 0px;
        padding-right: 0px;
        width: 176px;
        margin-left: 4px;
    }

    @include breakpoint(tablet) {
        padding-top: 16px;
        text-align: left;
        margin-top: 0px;
        padding-right: 40px;
        width: 67%;
        margin-left: 16px;
    }  
}

.single-ticket-img-cntr-webview {
    @media (width: 320px) {
        width: 61px;
        margin-top: 1px;
    }
    display: inline-block;
    width: 82px;
    margin-top: 2px;
    @include breakpoint(tablet) {
        width: 118px;
   }
}

.single-ticket-img-cntr {
    @media (width: 320px) {
        width: 69px;
    }
    display: inline-block;
    width: 82px;
    margin-top: 2px;
    @include breakpoint(tablet) {
        width: 118px;
   }
}


.single-tix-stub-court{
    text-transform: uppercase;
    color: $wim-black;
    font-weight: bold;
    letter-spacing: 0.5px;
    margin-bottom: 4px;
    font-size: 16px;

    @media (width: 320px) {
        font-size: 12px;
    }
    @include breakpoint(tablet) {
        margin-bottom: 8px;
    }
}

.single-tix-stub-txt {
    @media (width: 320px) {
        font-size: 10px;
    }
    color: $wim-black;
    font-weight: bold;
    margin-bottom: 4px;
    font-size: 12px;
    @include breakpoint(tablet) {
        font-size: 14px;
    }
}

.single-tix-stub-type {
    @media (width: 320px) {
        font-size: 10px;
    }
    color: $wim-green;
    font-weight: bold;
    letter-spacing: 0.5px;
    font-size: 12px;
    @include breakpoint(tablet) {
        font-size: 14px;
    }

}

.web-ticket-stub-row-cell-small {
    @media (width: 320px) {
        width: 70%;
        font-size: 10px;
    }

    display: inline-block;
    width: 80%;
    text-align: left;
    float: left;
    font-size: 12px;
    color: #333333;
    text-transform: capitalize;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: 2px;
    @include breakpoint(tablet) {
        display: inline-flex;
        font-weight: bold;
        width: 11% !important;
        font-size: 14px;
        height: 100%;
        vertical-align: middle;
        align-items : center;
        line-height: 20px;
        margin-top: 0px;
    }
    @include breakpoint(desktop) {
        width: 10% !important;
    }
}

.web-ticket-stub-row-cell-margin {
    @include breakpoint(tablet) {
        margin-left: 4px;
        margin-right: 12px;
    }
    @include breakpoint(desktop) {
        margin-right: 8px;
    }
}

.web-ticket-stub-row-cell-small-empty {
    display: none !important;
    @include breakpoint(tablet) {
        display: inline-flex;
    }
}

.web-ticket-stub-row-cell-med-cntr {
    display: inline-flex;
    @include breakpoint(tablet) {
        display: table-cell;
        vertical-align: middle;
        height: 100%;
    }
}

.web-ticket-stub-row-cell-med-inner {
    display: block;
    @include breakpoint(tablet) {
        font-weight: bold;
    }
}

.web-ticket-stub-row-cell-med {
    @media (width: 320px) {
        width: 70%;
        font-size: 10px;
    }
    
    display: inline;
    width: 100% !important;
    text-align: left;
    float: left;
    font-size: 12px;
    color: #333333;
    letter-spacing: 0;
    height: auto;
    text-transform: capitalize;
    @include breakpoint(tablet) {
        width: 13% !important;
        margin-right: 8px;
        display: inline-table;
        font-size: 14px;
        margin:auto;
        height: 100%;
        vertical-align: middle;
        align-items : center;
        line-height: 20px;
    }
    @include breakpoint(desktop) {
        width: 10% !important;
        margin-right: 0px;
        margin-left: 4px;
        height: 100%;
    }
}

.web-ticket-stub-cell-min-height {
    height: 1px;
}

.web-ticket-stub-row-cell-xs {
    display: none;
    @include breakpoint(desktop) {
        display: inline-flex;
        font-weight: bold;
        margin:auto;
        float: left;
        width: 7% !important;
        height: 100%;
        vertical-align: middle;
        text-align: left;
        font-size: 14px;
        color: #333333;
        letter-spacing: 0;
        align-items : center;
        text-transform: capitalize;
        line-height: 20px;
    }
}

.web-ticket-stub-row-cell-mobile {
    display: inline-block;
    width: 100% !important;
    text-align: left;
    font-size: 12px;
    color: #333333;
    letter-spacing: 0;
}

.web-ticket-stub-row-cell-large {
    @media (width: 320px) {
        width: 70%;
        word-wrap: break-word;
        font-size: 10px;
    }

    display: inline-block;
    width: 80%;
    text-align: left;
    float: left;
    font-size: 12px;
    color: #333333;
    letter-spacing: 0;

    @include breakpoint(tablet) {
        width: 32%;
        display: inline-flex;
        font-size: 14px;
        word-break: break-word;
        align-items : center;
        line-height: 20px;
        height: 100%;
        vertical-align: middle;
    }

    @include breakpoint(desktop) {
        width: 23%;
        margin-right: 8px;
    }
}

.web-ticket-stub-img-style {
    height: 118px;
    content:url('/assets/images/tickets/ticketStubActionsButtonImgMobile.svg');
    @include breakpoint(tablet) {
        height: 110px;
        margin-top: -1px;
        content:url('/assets/images/tickets/ticketStubActionsButtonImgTablet.svg');
    }
    @include breakpoint(desktop) {
        height:94px !important;
        content:url('/assets/images/tickets/ticketStubActionsButtonImg.svg');
    }
    
}

.web-ticket-stub-img-style {
    height: 118px;
}

.web-ticket-stub-row-cell-actions {
    position: absolute;
    top: -4px;
    bottom: 0;
    right: 0;
    @include breakpoint(tablet) {
        top: unset;
        bottom: unset;
        right: -2px;
        display: flex;
        float:right;
        height: 100%;
    }
}

.web-ticket-stub-actions-button {
    height: 114px;
    position: absolute;
    right: 0px;
    background: transparent;
    width: 80px;
    border: none;
    cursor: pointer;
    @include breakpoint(tablet) {
        width: 100%;
        height: 100%;
    }
}

.web-ticket-stub-actions-dropdown-container {
    font-size: 14px;
    position: absolute;
    right: 70px;
    top: 0px;
    z-index: 100;
    width: 224px;
    border: 1px solid #007138;
    background: #FFFFFF;
    @include breakpoint(tablet) {
        right: 100px;
    }
    @include breakpoint(desktop) {
        right: 140px;
    }
}

.web-ticket-stub-actions-dropdown {
    position: relative;
    z-index: 100;
}

.web-ticket-dropdown-button {
    border: 1px solid #FFFFFF;
    font-size: 14px;
    color: #525252;
    letter-spacing: 0.5px;
    line-height: 24px;
    background: none;
    cursor: pointer;
    width: 100%;
    display: flex;
    float: left;
    font-family: "Gotham 4r",Helvetica,Arial,sans-serif;
    &:hover {
        border: 1px solid #007138;
        color: white;
        background: #007138
    }
}

.web-ticket-dropdown-link {
    padding-left: 4px;
    color: #525252;
    &:visited {
        color: #525252;
        &:hover {
            color: white;
        }
    }
    &:hover {
        border: 1px solid #007138;
        color: white;
        background: #007138
    }
}

.web-ticket-dropdown-button:disabled {
    cursor: not-allowed;
    opacity: .3;
    &:hover {
        border: 1px solid white;
    }
}

.web-ticket-stub-transfer-tix-txt {
    font-size: 14px;
    color: #333333;
    letter-spacing: 0;
    line-height: 21px;
    @include breakpoint(desktop) {
        float: left;
        margin: auto;
        height: 100%;
    }
}

.web-ticket-stub-transfer-tix-txt-group {
    display: inline-grid;
    font-size: 14px;
    color: #333333;
    letter-spacing: 0;
    line-height: 21px;
}

.web-ticket-stub-transfer-tix-cntr {
    border: 1px solid #CFCFCF;
    padding: 16px;
    font-weight: bold;
    @include breakpoint(desktop) {
        display: flex;
    }
}

.test-ticket-label {
    color: red;
    position: absolute;
    text-transform: uppercase;
    font-size: 8px;
    @include breakpoint(desktop) {
        left: 80px;
        font-size: 12px;
    }
}

.test-ticket-label-webview {
    color: red;
    position: absolute;
    left: 88px;
    text-transform: uppercase;
    font-size: 8px;
    @include breakpoint(tablet) {
        left: 140px;
        font-size: 12px;
    }
    @include breakpoint(desktop) {
        left: 200px;
    }
}

.web-ticket-stub-type {
    color: $wim-green;
    font-weight: bold;
}

.web-ticket-stub-memb-guest {
	&.msg {
		text-transform: none;
	}
}

.web-ticket-stub-memb-guest::after {
    text-transform: none !important;
    content: "\A";
    white-space: pre;
}