@mixin one-col-related-content-tile{
    .content-link{
        .content-text{
            .content-description{
                display:  none;
            }
            .content-title{
                // height: 73px;
                line-height: 1.1em;
                font-size: 14px;
                overflow-y: hidden;
                width: 100%;
            }
        }
    }
}

.content-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100% !important;
    margin: 0px !important;
    .showMore{
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.content-tile{
    width: 100%;
    margin-bottom:  16px;
    @include gotham(medium);
    position: relative;
    
    .content-link{
        display: flex;
        
        &:hover{
            text-decoration: none;
        }

        .content-text{
            color:  $wim-purple;
            flex:  0 0 50%;
            display:  flex;
            flex-direction:  column;
            margin-left: 10px;
            @at-root .content-list #{&}{
                margin-left: 8px;
            }

            .type-tag{
                color: $white;
                text-transform: uppercase;
                background: $wim-green;
                height: 15px;
                @include gotham(medium);
                line-height:15px;
                font-size: 10px;
                padding: 0 10px;
                text-align: center;
                flex: 0 0 auto;
                position: absolute;
                letter-spacing:  2px;
                white-space: nowrap;
            }

            .content-date{
                h6{
                    font-size: 10px;
                    .content-date {
                        margin: 0;
                    }
                }
                @include gotham-book();
                color: $wim-green;
                flex:  0 0 auto;
                margin-top: 22px;
                margin-bottom: 5px;
                text-transform: uppercase;
                .date-part{
                    @include gotham(medium);
                }
            }

            .content-title{
                font-size:  18px;
                @include gotham(medium);
                flex:  0 0 auto;
                // margin-top: 20px;
            }

            .content-description{
                font-size: 12px;
                line-height: 1.5em;
                overflow-y: hidden;
                margin-top: 5px;
                @include gotham-book();
            }
        }
        .image-container{
            position: relative;
            .image-container-inner{
                position: relative;
            }
            img{
                width:  100%;
                height:  auto;
            }
            i{
                color: #fff;
                font-size: 56px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: rgba(0, 0, 0, .3);
                border-radius: 50px;
            }
            flex:  0 0 50%;
            @at-root .content-list #{&}{
                padding-right: 7px;
            }
        }
    }

    &.one-col{
        @include one-col-related-content-tile;
    }

    &.two-col{
        @include breakpoint(tablet,max){
            @include one-col-related-content-tile;
        }
    }
}

.related-content-tile:nth-child(odd) {
    clear: both;
}
