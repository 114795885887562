.data-tabs {
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    color: $gray-bb;
    margin: 20px auto;
    height:24px;
    line-height: 24px;
    @include gotham(medium);
    span {
        cursor: pointer;
        padding:0px 10px;
        border-left: solid 2px $gray-bb;

        &:first-child {
            border-left:none;
        }
        &.show {
            color: $white;
        }
    }
}