.ticket-actions-view-overlay {
    background: rgba(0,0,0,.5);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
	z-index: 99;
    width: 100%;
    
    .ticket-actions-view-wrapper {
		background: $white;
		position: absolute;
		left: 0px;
		right: 0px;
		margin: 0 auto;
		z-index: 100;
        bottom: 0px;
		border: 1px solid $gray-200;
        top: 0px;
        height: 100vh;
        overflow: scroll;

        @include breakpoint(tablet) {
            height: 100%;
        }

        .ticket-actions-view-container {
            &.my-wimbledon {
				margin: 10px;
                height: 100%;
                @include breakpoint(tablet) {
                    margin: 0px;
                }

                .ticket-actions-view-title{
                    padding-top: 20px;
                    padding-bottom: 20px;
                    text-align: center;
                    text-transform: uppercase;
					font-size: 18px;
                    font-weight: bold;
                    color: $wim-green;
                    @include breakpoint(tablet) {
                        padding-top: 42px;
                    }
                }
                
                .modal-content {
                    text-align: center;
                }
				
                .close-btn-container {
                    position: absolute;
                    top: 10px;
                    right: 10px;
					z-index: 100;
                }
            }
        }        
    }
}