.content-main{
    &.column-layout{
        &.newsletter{
            display:flex;

            .newsletter-header{
                margin-bottom: 35px;
				color:$green;
				@include gotham(normal);

				&-title{
					font-size: 30px;
					letter-spacing: 1px;
					line-height: 1.1em;
					margin-bottom: 14px;
					text-transform: uppercase;
				}
				
				&-subtitle{
					font-size: 24px;
					letter-spacing: 1px;
					line-height: 1.1em;
					margin-bottom: 14px;
					text-transform: uppercase;
				}

				&-description{
					margin:1em 0;
					color:$select-menu-text;
					line-height:1.6em;
					font-size:1em;
				}
			}
        }
    }
}