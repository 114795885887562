/**
 * column layout 
 */

.jsx-parser {

}
@mixin inline($width, $color) {
    //background-color: $color;

    width: $width;
    margin-right: calc(#{$item-h-margin} * 3);
    margin-top: 10px;
    margin-bottom: 10px;
    
    &.right {
        margin-left: calc(#{$item-h-margin} * 3);
        margin-right: 0px;
    }
}

.page {
    .wrapper .content-main {
		&.news {
			padding-top: 0px;

            &.article {
                .favorite {
                    position: relative;
                    top: unset;
                    cursor: pointer;
                    z-index: 0;
                    &:after {
                        position: absolute;
                        right: 70px;
                        content: "\2606";
                        font-size: 33px;
                        color: #00703c;
                        top: -14px;
					}
					
                    &.favorited:after {
						content: "\2605";
                    }
                }
            }

			.textWrapContent {
				overflow-x: auto;
				overflow-y: hidden;
				
				h3 {
					margin: 0 0 5px 0;
					font-size: 1.17em;
				}
			}
            .news--header-title {
                h1 {
                    position: relative;
                    margin-bottom: 20px;

                    &::after {
                        content: '';
                        position: absolute;
                        height: 2px;
                        width: 32px;
                        background: white;
                        right: 0;
                        bottom: -16px;
                        transform: translateX(-50%);
                        left: 50%;
                    }
                }
                @include breakpoint(tablet, max) {
                    h1 {
                        font-size: 20px;
                        letter-spacing: .5px;
                    }
                }
            }
			.news--header-wrapper {
				display: flex;
				position: absolute;
				left: 0px;
				bottom: 100%;
				color: white;
				width: 100%;
                z-index: -2;
				&::before{
					content: '';
					height: 180%;
					width: 100%;
					background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3), transparent);
					position: absolute;
					bottom: 0px;
				}
			}

			.byline {
				&.left + .margin{
					@include breakpoint(desktop, min){
						margin-left: $item-h-margin;
					}
				}
			}

		}

		.column-layout {
			padding-top: 15px;
		}
	}
}

.news--header {
	width: 80%;
	min-height: 80px;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    opacity: 1;
    z-index: 2;
	text-shadow: $textShadow;
	
	@include breakpoint(tablet, max) {
		width: 90%;
		margin-left: 5%;
	}
    

    h1, h2, h3, h4 {
        margin: 0px;
    }

    &.hide {
        opacity: 0;
		transition: opacity .5s linear;
		
		& ~ .news--header-videoLink {
			.news--header-videoLinkButton{
				opacity: 0;
				transition: opacity .5s linear;
			}
		}
    }

    &.show {
        opacity: 1;
        transition: opacity .5s linear;
	}
	
	&.video {
		margin-bottom: 25px;
	}

    &-icon {
        flex: 0 1 auto;
        align-self: center;
        margin: 0 0 4px 0;
        text-align: center;
        font-size: 32px;
    }

    &-date {
        flex: 0 1 auto;
        align-self: center;
        margin: 0 0 4px 0;
        text-align: center;
        letter-spacing: 1px;
    }

    &-title {
        flex: 0 1 auto;
        align-self: center;
        margin: 0 0 6px 0;
        text-align: center;
		display: block;

        @include breakpoint(tablet, max){
            display: none;
            
            h1 {
                font-size: 20px;
                letter-spacing: .5px;
            }
        }
	}
	&-shorttitle {
        flex: 0 1 auto;
        align-self: center;
        margin: 0 0 6px 0;
        text-align: center;
		letter-spacing: 2px;
		line-height: 25px;
		display: none;

        @include breakpoint(tablet, max){
            display: block;
            
            h1 {
                font-size: 20px;
                letter-spacing: .5px;
            }
        }
    }

    &-abstract {
        line-height: 18px;
        text-align: center;
        flex: 0 1 auto;
        align-self: center;
        margin: 0 0 30px 0;
        max-width: calc(100% - 30px);
        letter-spacing: 1px;

        h3, h4 {
            display: inline;
        }

        h3 {
            white-space: nowrap;
        }

        @include breakpoint(tablet, max){
            letter-spacing: 0.5px;
			margin: 0 0 10px 0;
			line-height: 16px;
        }
    }

    &-videoLink {
        position: absolute;
		bottom: 0px;
		left: 5px;
        margin: 0 0 15px 15px;
        @include gotham();
        font-size: 18px;
        text-shadow: 0px 0px 10px #000000;
        cursor: pointer;
		z-index: 5;
		white-space: nowrap;

        i {
            font-size: 25px;
            background-color: rgba(0, 0, 0, .4);
            border-radius: 15px;
            margin: -2px 0 0 8px;
            position: absolute;
        }

        &:hover {
            color: $wim-green;
        }
	}
}

.column-layout {
    &.news {
        display: block;

        p {
            font-size: $news-font-size-small;
            line-height: $news-text-line-height-small;
            margin: 0;
            padding-top: $item-v-margin;
            padding-bottom: $item-v-margin;
            &:first-child {
                padding-top: 0;
                margin-top: 0px;
            }
            &:last-child {
                padding-bottom: 0;
                margin-bottom: 0px;
            }

            @media only screen and (min-width: $column-break-1) {
                font-size: $news-font-size;
                line-height: $news-text-line-height;
            }
        }
		
		.sectionDividerContent {
			margin: -7px 0;
		}

        .one-col, .two-col, .three-col, .four-col {
            //width: 100%;
            float: left;
            //background-color: $test-light-yellow;

            &.inline {
                width: 100%;
                //background-color: $test-light-yellow;
			}
			
			&.center {
				text-align: center;
			}

			&.margin {
				margin-left: $item-v-margin;
			}
        }

        .inline {
            margin-left: 0px;
            margin-right: 0px;

            &.right {
                float: right;
            }
        }

        .section {
            clear: both;
        }

        .clear {
            clear: both;
		}
		
		// 768 and larger
        @include breakpoint(tablet, min){
            .one-col{

                &.margin {
                    margin-left: calc(25% + #{$item-h-margin});
				}
				&.margin-col {
					margin-left: 0;
				}

                .one-col{ 
                    &.inline {
                        width: 100%;
                        //background-color: $test-light-blue;
                    }
                }

                //background-color: $test-light-blue;
            }

            .two-col{
                &.margin-col {
					margin-left: 0;
				}
            }

            .two-col, .three-col, .four-col {
                //background-color: $test-light-yellow;

                .one-col, .two-col{ 
                    &.inline {
                        width: 50%;
                        margin-right: $item-h-margin;
                        //background-color: $test-light-blue;

                        &.right {
                           margin-left: $item-h-margin; 
                           margin-right: 0px;
                        }
                    }
                }
			}
			
			.clear-two {
				clear: left;
			}
        }

		// 1024 and larger
        @include breakpoint(desktop, min){

            .one-col {
                //background-color: $test-light-gray;

                .one-col { 
                    &.inline {
                        width: 100%;
                        //background-color: $test-light-gray;
                    }
                }
            }
        
            .two-col {
                //background-color: $test-light-blue;

                .one-col { 
                    &.inline {
                        @include inline(50%, $test-light-gray);
                    }
                }

                .two-col { 
                    &.inline {
                        width: 100%;
                        //background-color: $test-light-blue;
                    }
                }
            }
        
            .three-col {
                //background-color: $test-light-green;

                .one-col { 
                    &.inline {
                        @include inline(33%, $test-light-gray);
                    }
                }

                .two-col { 
                    &.inline {
                        @include inline(66%, $test-light-blue);
                    }
                }
            }
        
            .four-col {
                //background-color: $test-light-yellow;

                .one-col { 
                    &.inline {
                        @include inline(25%, $test-light-gray);
                    }
                }

                .two-col { 
                    &.inline {
                        @include inline(50%, $test-light-blue);
                    }
                }
            }
        
            .one-col, .two-col, .three-col  {
                &.margin, &.margin-col {
                    margin-left: calc(25% + #{$item-h-margin});
                }
            }
			
			.clear-two {
				clear: unset;
			}
			.clear-four {
				clear: left;
			}
			
        }
    }

    .section {
        margin: $item-v-margin 0 0 0;
    }
}


