.debug-info-display {
	position: fixed;
	bottom: 0;
    z-index: 99999;
    font-family: Arial, Helvetica, sans-serif;
	font-size: 10px;
	font-weight: bold;
    padding: 5px;
	
	background-color: rgba(19, 18, 18, 0.82);
	color: #e4e4ec;

	.info-section {
		font-weight: bold;
		font-size: 11px;
		text-transform: unset;
		color: #55f385;
		margin-top: 2px;
	}
	.info-section:first-of-type {
		margin-top: 0px;
	}

	.info-table {
		border: 0px solid #C0C0C0;
		border-collapse: collapse;
		padding: 0px;
	}
	th {
		border: 0px solid #C0C0C0;
		padding: 2px;
		background:#F0F0F0;
	}
	td {
		border: 0px solid #C0C0C0;
		padding: 1px 2px;
	}

	.data-column {
		float: left;
		position: relative;
		margin-left: 5px;
	}
	.data-column:first-of-type {
		margin-left: 0px;
	}
}