.gigya-logout-link {
	cursor: pointer;
}

.screen-holder {
	//max-width: 60%;
	margin: auto;
	line-height: normal;

	.gigya-screen {
		border: 1px solid $lines-gray;
	}

	.gigya-screen-caption {
		display: none;
	}

	
}