.featured-rolex, .home-rolex{
    display: flex;
    align-items: flex-end;
    &.tablet{
        position: absolute;
        right: 25px;
        bottom: 22px;
    }
    &.mobile{
        width: 100%;
        justify-content: center;
        margin-bottom: 18px;
    }
    .rolex-clock {        
        border-radius: 10px;
        background-color: rgba(0, 96, 57, .9);
        padding-bottom: 1px;    
        height: 73px;    
    }
}

.featured-news{
    width: 100%;
    // margin-bottom: -5px;
    position: relative;
    .featured-news-carousel-desktop{
        width: 100%;
        background-color: black;
        .slick-slide img{
            width: 100%;
        }
    }
    .news-item{
        position: relative;
        margin-bottom: -5px;
        &::after{
            content: "";
            height: 40%;
            width: 100%;
            background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4) 40%, transparent);
            position: absolute;
            bottom: 0px;
            left: 0px;
        }
        img, picture{
            // width: 100%;

            aspect-ratio: .89;
            @include breakpoint(tablet){
                aspect-ratio: 1.5;
            }
            @include breakpoint(desktop){
                aspect-ratio: 2.35;   
            }

            -o-object-fit: cover;
            object-fit: cover;
            // -o-object-position: 0px; //these make it left aligned, removing makes it centered
            // object-position: 0px;
            margin-left: 0px;
        }
    }
    .featured-items{
        display: flex;
        position: absolute;
        bottom: 0px;
        height: 100%;
        width: 100%;
        z-index: 1;
       
        &.desktop, &.mobile{
            width: 100%;
        }
        &.desktop {
            padding: 0 25px 0 25px;
            .featured-item, .featured-rolex{
                padding-bottom: 25px;
            }
        }
        &.tablet{
            width: 50%;
            padding: 0 25px 0 25px;
            .featured-item, .featured-rolex{
                padding-bottom: 37px;
            }
        }
        &.mobile{
            padding: 0 25px 0 25px;
            .featured-rolex{
                padding-bottom: 37px;
            }
            .featured-item{
                padding-bottom: 37px;
                .featured-item-inner{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
        .featured-item{
            flex: 1 1;
            height: 100%;
            color: white;
            @include gotham(medium);
            font-size: 18px;
            justify-content: flex-end;
            display: flex;
            flex-direction: column;
            a, a:hover, a:visited{
                color: white;
                text-decoration: none;
                cursor: pointer;
            }
            .featured-item-vid{
                cursor: pointer;
            }
            &.active{
                @include breakpoint(desktop){
                    flex: 0 0 33%;
                }
                .featured-item-inner{
                    border-left: 2px solid rgba(255,255,255, 0);
                    transition: border .4s;
                    .featured-title{
                        font-size: 16px;
                        @include breakpoint(tablet){
                            font-size: 24px;
                        }
                        @include breakpoint(desktop){
                            font-size: 28px;
                        }                        
                        text-transform: uppercase;
                        cursor: pointer;
                    }
                }
            }
            .featured-item-inner{
                border-left: 2px solid white;
                    padding: 0px;
                @include breakpoint(tablet){
                    padding: 0 30px 0 16px;
                }
                .type-tag{
                    @include gotham(medium);
                    font-size: 10px;
                    background-color: white;
                    text-transform: uppercase;
                    color: $wim-black;
                    width: fit-content;
                    padding: 3px 6px;
                    margin-bottom: 12px;
                }
                .featured-item-link{
                    border: 2px solid #FFFFFF;
                    font-size: 14px;
                    line-height: 16px;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    width: 150px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 12px;
                }
            }
        }
    }

    

    .slick-dots {
        bottom: 20px;
        @include breakpoint(tablet){
            text-align: left;
            margin-left: 40px;
        }

		li {
			width: 6px;
			height: 6px;
			border-radius: 50%;		
			background: rgba(255,255,255,0.5);
			button {
				width: 100%;
				height: 100%;
				margin-top: -1px;

				&:before {
					content: '';
					width: 100%;
					height: 100%;
				}
			}
			&.slick-active {
				background: $white;
			}
		}
	}
}