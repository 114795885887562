.collapsible {
	cursor: pointer;
	display: inline-block;
	&__header {
		display: flex;
		align-items: center;
		&-plus {
			height: 24px;
			box-sizing: border-box;
			cursor: pointer;
			margin-top: 6px;
			&--vertical {
				height: 22px;
				width: 1px;
				background-color: #00703c;
				margin-top: -50%;
				margin-left: 50%;
			}
			&--horizontal {
				width: 22px;
				height: 1px;
				background-color: #00703c;
				margin-top: 50%;
			}
		}

		&--title {
			display: inline-block;
			font-size: 16px;
			color: #24008b;
			font-family: 'Gotham 5r', Helvetica, Arial, sans-serif;
			letter-spacing: 0.5px;
			text-transform: uppercase;
			padding-left: 16px;
		}
	}

	&__content{
		&-open{
			display: block
		}
		&-closed{
			display: none;
		}
	}
}
