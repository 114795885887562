$gray: #b9b9b9;
$gray-disable: #9c9c9c;
$gray-light: #d8d8d8;
$gray-lighter: #F7F7F7;
$gray-dark: #818a8f;
$gray-darker: #747678;
$gray-darkest: #2d2e34;
$grey-c7: #C7C7C7;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #CBCBCB;
$gray-450: #a0a0a0;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-650: #616161;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:  #000;
$white:  #fff;
$blue:    #0076FA;
$ibm-blue: #1f70c1;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #F60000;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #003300;
$green2:  #006633;
$teal:    #20c997;
$cyan:    #17a2b8;
$link-blue: #251EC6;


//wimbledon
$wim-purple: #4E2683;
$wim-yellow: #ffec2d;
$wim-text-purple: #54008B;
$wim-search-text-purple: #4F2683;
$wim-green: #00703C;
$wim-check: #066E3E;
$wim-black: #333333;
$wim-green-bg: #304611;
$wim-purple-bg: #322833;
$link-green: #046633;
$text-green: $wim-green;
$yellow-tennisball-color: #FFF000;
$green-check-color: #1E860A;
$live-green:#BDE403;
$live-green2: #DCE443;
$lines-gray: #979797;
$gray_9b:#9B9B9B;
$gray_bb:#BBBBBB;
$gray-e3: #E3E3E3;
$gray-6b: #6B6F73;
$md-gray: #9B9B9B;
$md-gray2: #979797;
$option-hover: #E8E8E8;


$green_1f: #00521F;
$tennis-court-green:#8DC640;
$button-hover: #004131;
$button-active: #004131;
$button-compact: #00521F;
$button-hover-purple: #390063;
$button-active-purple: #390063;
$powerrank-purple:#57008F;
$powerrank-bar:#57008F; //#B5DD5A;
$powerrank-bar-bg: #ECECEC;
$powerrank-text:$wim-black;
$powerrank-movement-up: #22D707;
$powerrank-movement-down:#D00000;
$powerrank-table-border:#ECECEC;
$powerrank-divider: #BBBBBB;
$powerrank-color:#A07CC9;
$powerrank-won: $powerrank-color;
$powerrank-loss: $powerrank-purple;
$powerrank-series-color:$powerrank-color;
$tourrank-color: #C1C6C8;
$chart-label-color: #4A4A4A;
$sentiment-text: #616161;
$powerrrank-border:#4A4A4A;

$select-menu-text: $wim-black;
$select-menu-border: $gray_bb;

$likelihoodwin-purple: #B266FF;
$likelytoplay-purple: $wim-text-purple;

$watson-bg-default: #5D69FF;
$watson-bg-start: #A56EFF;
$watson-bg-end: #0062FF;

$favourable: #347C1F;
$difficult: #E70000;
$neutral: #A56A05;

$favourable-bg: #D0DFC5;
$neutral-bg: #FFEABC;
$difficult-bg: #FCD8D6;
$postmatch-bg: #D4D4D4;

$carousel-text-color: #A1A1A1;
