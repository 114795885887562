.modal-overlay {
    background: rgba(0,0,0,.5);
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    bottom: 0;
	z-index: 1000;
	
    .modal-wrapper {
		background: $white;
		position: absolute;
		left: 0px;
		right: 0px;
		margin: 0 auto;
        top: 120px;
		z-index: 100;
		width: 55%;
		min-width: 300px;
		max-width: 500px;
		min-height: 220px;

		margin-bottom: 20px;
		border: 1px solid $gray-200;

        @include breakpoint(desktop){
            top: 152px;
        }
		
        .modal-container {
            &.my-wimbledon {
				margin: 20px;

                .modal-title{
                    padding-top: 20px;
                    padding-bottom: 20px;
                    text-align: center;
                    text-transform: uppercase;
					font-size: 18px;
                    font-weight: bold;

                    &.success, &.updating {
                        color: $wim-search-text-purple;
                    }
                    &.fail {
                        color: $red;
                    }
                }
                .modal-button {
					margin: auto;

                    .loading-indicator {
                        top: 75%;
                        margin-top: 24px;
                        @include breakpoint(desktop){
                            margin-top: 0px;
                        }
                    }
                    .confirmation-btn {
                        cursor: pointer;
                        display: block;
                        margin: auto;
                        width: 85%;
                        border: none;
                        color: white;
                        font-size: 16px;
                        letter-spacing: .73px;
                        margin-top: 25px;
                        &.success {
                            background: $wim-green;
                        }
                        &.fail {
                            background: $red;
                        }
					}
					.confirm-btns {
						padding: 20px 0 0 0;
						float: left;
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
	
						.generic-button_wrapper {
							margin: 0 5px;
							margin-bottom: 10px;
						}
					}
                }
                .modal-content {
                    text-align: center;
                    word-break: break-word;
                }
				.modal-code {
					text-align: center;
					bottom: 0px;
					position: absolute;
					color: $gray_bb;
					font-size: 12px;
					padding: 0 0 10px;
                }
                .close-btn-container {
                    position: absolute;
                    top: 10px;
                    right: 10px;
					z-index: 100;
                }
            }
        }        
    }
}

.modal-overlay-promo-open {
    background: rgba(0,0,0,.5);
    position: fixed;
    left: 0;
    right: 0;
    width: 100%;
    top: 0;
    bottom: 0;
	z-index: 1000;
	
    .modal-wrapper {
		background: $white;
		position: absolute;
		left: 0px;
		right: 0px;
		margin: 0 auto;
        top: 120px;
		z-index: 100;
		width: 55%;
		min-width: 300px;
		max-width: 500px;
		min-height: 220px;

		margin-bottom: 20px;
		border: 1px solid $gray-200;

        @include breakpoint(desktop){
            top: 152px;
        }
		
        .modal-container {
            &.my-wimbledon {
				margin: 20px;

                .modal-title{
                    padding-top: 20px;
                    padding-bottom: 20px;
                    text-align: center;
                    text-transform: uppercase;
					font-size: 18px;
                    font-weight: bold;

                    &.success, &.updating {
                        color: $wim-search-text-purple;
                    }
                    &.fail {
                        color: $red;
                    }
                }
                .modal-button {
					margin: auto;

                    .loading-indicator {
                        top: 75%;
                        margin-top: 24px;
                        @include breakpoint(desktop){
                            margin-top: 0px;
                        }
                    }
                    .confirmation-btn {
                        cursor: pointer;
                        display: block;
                        margin: auto;
                        width: 85%;
                        border: none;
                        color: white;
                        font-size: 16px;
                        letter-spacing: .73px;
                        margin-top: 25px;
                        &.success {
                            background: $wim-green;
                        }
                        &.fail {
                            background: $red;
                        }
					}
					.confirm-btns {
						padding: 20px 0 0 0;
						float: left;
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
	
						.generic-button_wrapper {
							margin: 0 5px;
							margin-bottom: 10px;
						}
					}
                }
                .modal-content {
                    text-align: center;
                    word-break: break-word;
                }
				.modal-code {
					text-align: center;
					bottom: 0px;
					position: absolute;
					color: $gray_bb;
					font-size: 12px;
					padding: 0 0 10px;
                }
                .close-btn-container {
                    position: absolute;
                    top: 10px;
                    right: 10px;
					z-index: 100;
                }
            }
        }        
    }
}

//for fullwindow video
.site-wrap.expanded  {
    .modal-overlay {
        width: 100%;

        // sidepanel open or cloased based on user selection
        @include breakpoint(desktopSidePanel, mid, true) {
            width: calc(100% - #{$sidebarWidth});
        }

        // sidepanel always open
        @include breakpoint(largeDesktop, min, true) {
            width: calc(100% - #{$sidebarWidth});
        }

        // sidepanel always open and content area centered with curtains
        @include breakpoint(extraLargeDesktop, min, true) {
            width: calc(100% - #{$sidebarWidth});
        }
    }
}