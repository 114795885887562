.country-page {
    float: right;
    border: 2px solid $wim-green;
    border-radius: 3%;
    padding-top: 0px !important;
    .results-select-menu {
        button.title {
            font-size: 12px;
        }
    }
}