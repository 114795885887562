/** countryleaderboard styles **/
.content-main {
	.country-stats-info {
		width: 65%;
		margin: auto;
		text-align: center;
		font-size: 16px;
		color: white;	
		margin-bottom: 15px;		
	}	
	.countryScoreBoard { 
		width: calc(100% - 20px); 
		margin: 60px auto;   
		margin-top: 0; 
		border-collapse: collapse; 

		@include breakpoint(tablet) {
			width: calc(100% - 60px);
		}

		@include breakpoint(desktop) {
			width: calc(100% - 120px);
		}
	
		p {
			margin-top:0;
		}
		.datatable {
			.headers {
				color:$white;
				background-color: $wim-green;
				.cell {
					padding: 5px 0 5px 0;
				}
			}
			.row {
				.cell {
					vertical-align: middle; 
					padding-top: 0.5em; 
					padding-bottom: 0.5em; 
					padding-right: 0.5em; 
				}
				.data1 {
					padding-left: 0.5em; 
					img {
						float: left; 
						margin-right: 10px; 
					}
					a {
						float: left; 
						text-align: left;
						margin-top: 5px; 
						padding-right: 0.5em; 
						@include breakpoint(tablet) {
							padding-right: 0; 
						}
						&:link, &:active, &:visited {
							color: $black;
							text-decoration: none; 
						}
					}
				}
			}
		}
	}
}
