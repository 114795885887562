.order-of-play{
    @include gotham(medium);

    hr{
        background-color: $select-menu-border;
        height: 1px;
        border: none;
        width: 100%;
        margin-top: 0;
        margin-bottom: 3px;
    }

    .player-info{
        display: flex;
        margin-bottom:  5px;
        
        .player-one {
            &.doubles {text-align: left;}
        }
        .player-two {
            &.doubles {text-align: right;}
        }

        .player-one, .player-two{
            flex: 0 0 auto;
            display: inline-block;
            width: 100px;
            cursor:pointer;
            
            .player-image{
                height:  100px;
                width:  100px;
            }
            &.doubles {
                .player-image{
                    height:  50px;
                    width:  50px;
                }
            }

            .player-one-info,.player-two-info{
                display: flex;
                flex-flow: column;
                justify-content: center;
                .player-a,.player-b {
                    display:flex;
                }
                .player-flag{
                    flex:  0 1 auto;
                    text-align:  center;
                    padding:  0 5px 0 0;
                    img{
                        height: 10px;
                        width: 17px;
                    }
                }
                .player-name{
                    color: $wim-green;
                    text-transform: uppercase;
                    font-size: 10px;
                    align-self: center;
                    white-space:  nowrap;
                    flex:  0 1 auto;
                    overflow:  hidden;
                    text-overflow:  ellipsis;
                    margin-top: 1px;
                }
                .seed{
                    font-size:10px;
                    color:$wim-green;
                    margin-top:1px;
                    margin-left:3px;
                    flex:0 1 auto;
                    align-self: center;
                }
            }
        }
        .match-type-status{
            flex: 1 1 auto;
            text-align:  center;
            position:  relative;
            .match-type,.match-status{
                color:  $select-menu-text;
                font-size:  12px;
                text-transform:  uppercase;
                position:  relative;
                top:  25%;
                transform:  translate(0,-50%);
            }
        }

        .sidepanel-column & {
            margin-bottom: 10px;
        }
    }
}