.form-wrapper {
	.one-third {
		width: calc(100% - 7px * 2);
		@include breakpoint(desktop, min) {
			width: calc(33.33% - 7px * 2);
		}
	}
	.no-padding {
		padding-top: 0px !important;
	}
	.loading-container {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: rgba(0,0, 0, 0.1);
		z-index: 999999;
		.loader {
			position: absolute;
			top: 30%;
			left: 50%;
			transform: translateX(-50%) rotate(90deg);
		}
	}
	.popup-container {
		width: 500px;
		height: 100px;
		position: absolute;
		&.error {
			background: #ffe8e8;;
		}
		&.success {
			background: #e3fff2;
		}		
		z-index: 999;
		border: #979797 1px solid;
		left: 50%;
		transform: translateX(-50%);
		top: -400px;
		opacity: 0;
		transition: all ease-in 0.4s;
		.msg-container {
			position: relative;
			.msg{
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				top: 40px;
				width: 100%;
				text-align: center;
				&.error-msg {
					color: #F60000;
				}
				&.success-msg {
					color: #00703C;
				}
			}
		}
		&.show{
			top: 100px;
			opacity: 1;
		}
	}
	color: #333333;

	label {
		// text-transform: uppercase;
	}
	input.quant {
		width: 50px;
		height: unset;
	}
	.signdate {
		color: #9B9B9B;
		input {
			width: 100%;
			padding-top: 5px;
			padding-bottom: 5px;
			font-size: 18px;
		}	
	}
	.credit-card-contact {
		position: absolute;
		left: 195px;
		font-size: 14px;
		bottom: 2px;
	}
	.ppl-info {
		font-size: 13px;
	}
	.programmes-info {
		font-size: 13px;
		text-align: right;
		&.four-col {
			text-align: left;
		}
	}
	.check-cc {
		position: relative;
		padding-top: 10px;
	}
	.deadline {
		font-size: 16px;
		color: #00703C;
	}
	.invoice-container, .purhase-order-container {
		input {
			width: 100%;
		}
	}
	.payment-options {
		color: #00703C;
	}
	.profile-info {
		color: #9B9B9B;
		.profile-title {
			color: #00703C;
		}
		.edit-profile {
			text-align: right;
			span{
				width: auto;
				display: inline-block;
				border: 1px solid #00703C;
				padding: 5px 10px;
				&.disabled {
					cursor: not-allowed;
				}
			}
		}
	}
	.profile {
		.field {
			display: block;
			color: $gray;
			margin: 5px 5px 5px 0;
	
			input {
				width: 100%;
			}
		}
	
		.dob-container {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			span {
				width: 25%;
				margin-right: 10px;
				input {
					padding-left: 0px;
				}
			}
			// input {
			// 	width: 50%;
			// }
		}
		.error {
			display: block;
			font-size: 11px;
			color: $red;
		}
		.profile-info {
			color: #9B9B9B;
			font-size: 14px;
		}
		.dob-container {
			input {
				text-align: center;
			}
		}
	}
	
	
	.tabButtons {
		// margin-top: 10px;
		cursor: pointer;
		text-align: center;
		// display: flex;
		// flex-direction: row;
		// flex-wrap: wrap;
		// justify-content: space-around;
		text-transform: uppercase;
		color: #888888;
		font-size: 18px;
		font-weight: bold;

		&.style2{
			.outer{				
				padding: 5px;
				span{
					color: #00703C;
					&.inner{
						width: 100%;
						display: block;
						border-left: 1px solid grey;
						@include breakpoint(desktop, max) {
							border-left: none;
						}
					}
				}

				&:hover{
					.text{
						border-bottom: 2px solid #00703C;
					}
				}

				&.selected{
					background-color: #00703C;
					span{						
						color: white;
						border-bottom: none;
					}
					.inner{
						border-left: none;
					}
				}
			}
		}

		.tab-btn-divider {
			border-top: 1px solid #bbb;
			&.green.thick{
				border-top: 2px solid #00703C;
				margin: 0 7px;
			}
		}
		span {
			&.selected {
				span {
					color: #00703C;
					border-bottom: 2px solid #00703C;	
				}
			}
		}
	}
	
	.hospContent {
		fieldset {
			border: none;
			padding-left: 0px;
		}
		.table-container {
			// max-height: 400px;
			// overflow-y: auto;
		}
		.hospitality-table {
			width: 100%;
			margin-bottom: 20px;
			border-collapse: collapse;
			thead {
				background: #ECECEC;
				text-transform: uppercase;
				tr {
					td {
						padding: 10px 0px 10px 10px;
					}
				}
			}
			tbody {
				tr {
					td {
						padding: 5px 0px 5px 10px;
						font-size: 18px;
						input {
							font-size: 18px;
						}
					}
					td:first-child {
						padding-left: 10px;
					}
				}
				tr:nth-child(even) {
					background: #ECECEC;
				}
				.checkbox-electric{
					label::before{
						top: -15px;
					}
					label::after{
						top: -11px;
					}
				}
			}

			&.hide {
				display: none;
			}
		}
	}
	.hospitality-btns {
		display: flex;
		justify-content: space-between;
	}

	#hosp-ticket-form {
		.hosp-ticket-desc {
			margin-top: 20px;
			font-size: 14px;
		}
		.two-img-container {
			border-left: 1px solid rgb(151, 151, 151);
			display: flex;
			flex-direction: row;
			align-items: flex-end;
		}
		.hosp-img-container {

		}
		#hosp-security {
			// border-left: 1px solid #979797;
			margin-left: 120px;
			margin-right: 190px;
		}
	}
}