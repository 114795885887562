	.drawsArchiveHdrMenu {
		color: $white; height: auto; display: inline-block; position: absolute; width: 100%; bottom: 0px; 
		@include breakpoint(tablet){ bottom: 10px; }

		.menuContainer{ display: inline-block; width: 100%;
			&.hidden{ display: none; }
		}
		.mobileHide { display: none;
			@include breakpoint(tablet) { display: inline-block; }
		}
		.view-by, .view-by-label,.view-by-item { 
				display: none;
				font-size: calc((14/15)*1em);
				text-transform: uppercase;
				font-weight: normal;
				padding-bottom: 3px;
				@include breakpoint(tablet) { display: inline; }
				@include breakpoint(desktop) { font-size: calc((16/16)*1em); }
		}
		.view-by-item{ color: $white; padding: 0px;
			@include breakpoint(tablet){ padding: 0px 18px; }
			.link { color: $white; }
			.link:hover, .selected {
					// border-bottom: solid 1px $white;
					cursor: pointer;
			}
			&.selected { cursor: default; 
				a:hover { cursor: default; border: none; }
			}
			&.searchIcon{ margin: 5px 0px 0px 0px; padding-bottom: 0px; display: inline-block; 
				i { font-size: calc((15/16)*1em); 
					@include breakpoint(desktop) { font-size: calc((16/16)*1em); }
				}
				.inline { display: inline-block; padding-left: 7px; }
				&:hover{ cursor: pointer;}
				@include breakpoint(tablet){ margin: 5px 0px 0px 0px; }
				@include breakpoint(desktop){ margin: 5px 0px 0px 0px; }
			}
		}
		.view-by{ 
			text-align: center; width:auto; margin: 0px auto; display: none;
			@include breakpoint(tablet) { display: inline-block; }
		}
		.view-by-label { text-transform: none; display: none;
			@include breakpoint(tablet) { display: inline-block; }
		}
		.view-by-mobile{ width: 90%; margin: 0px auto; display: inline-block; }
		.view-by-mobile-menu{
		  background: rgba($white, 0.5);
		  border: 2px solid $white;
		  margin: 30px 10px 10px 10px;
		  border-radius: 3px;
		  color: $white;
		  @include gotham_book;
			font-size: calc((16/16)*1em);
			height: 36px; // for ff
		  line-height: 32px;
		  outline: 0;
		  padding-left: 1em;
		  text-transform: uppercase;
		  text-align: center;
		  text-align-last: center;	
		  width: calc(100% - 20px);
		  display: inline-block; 
		  &.hidden { display: none; }
		  @include breakpoint(tablet) { display: none; }
		}

		.searchIconContainer {
			color: $white; position: relative; margin: 0px; display: inline-block;
			&.hidden { display: none; }
			&:hover { cursor: pointer; }

		}// searchIconContainer

		.searchInputContainer { margin: 0px auto; left: 0px; right: 0px; min-height: 40px; overflow: hidden; position: relative; top: 0px; width: 60%;
			&.hidden { display: none; }

			input.player-search {
				background: $white; box-sizing: border-box; color: $black; display: block; font-size: calc((14/16)*1em); padding: 10px; position: absolute; width: 100%; margin-top: 0;
				&::placeholder { color: $gray-dark; }
				@include breakpoint(desktop) { font-size: calc((16/16)*1em); padding: 10px; }
			}

			// removes IE's x icon
			input.player-search::-ms-clear {
				display: none;
			}

			.searchIcon{
				display: inline-block; margin: 10px 10px 10px 0; cursor: pointer; float: right; position: relative; color: $gray-dark;
				i { font-size: 16px; padding-left: 7px;  }
				.inline { display: inline-block; }
			}

		} // searchContainer
	} 
	.centerMenu{ width: 100%; margin: 0px auto; text-align: center; max-width: 1590px; display: inline-block;
		.hidden{ visibility: none; }
	}

	.searchResultsList {
		ul.results {
			width: 100%; list-style-type: none;
			li{  margin-bottom: calc((25/16) * 1em);
				.flags { 
					img { vertical-align: middle; margin-right: 10px; width: 43px; }
				}
			}
		}
	} // searchResultsList	
