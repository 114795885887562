/** mobile also styles **/
.mobile-also{
    color: white;
   .mobile-also-header{
        display: block;
        text-transform: uppercase;
        text-align: center;
        width: 100%;
    }

    .mobile-also-features-container{
        width: 92%;
        margin-top: 10px;
        margin-bottom: 32px;
        margin-left: auto;
		margin-right: auto;
		
        .mobile-also-features{
            font-size: 0.7em;
            border-top: 2px solid white;
            border-bottom: 2px solid white;
            padding: 32px 0 22px 0;
            margin-top: 10px;
            margin-bottom: 32px;
            @include clearfix;
            @include breakpoint(tablet){
                ul{
                    text-align: left !important;
                }
            }
            @include breakpoint(tablet){
                width: 92%;
            }
            @include breakpoint(desktop){
                width: 71%;
                font-size: 0.8em;
            }
			margin: 0 auto;
				
            @include breakpoint(tablet){
                ul{
                    float: left;
                    &:last-child{
                        float: right;
                    }
                }
			}
			
            ul{
                padding-left: 25px;
                padding-right: 25px;
                text-align: center;
                margin: 0;
                vertical-align: top;
				list-style: none;
				width: 100%;
                @include breakpoint(tablet, min) {
                    width: 33%;
                }
                li{
                    padding-bottom: 10px;
                    text-transform: uppercase;
                }
            }
        }
    }
}