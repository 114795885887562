.newsletter-thank-you{
    text-align:center;
    @include gotham-book();
    width:50%;
    margin:0 auto;
    .heading{
        color:$green;
        font-size:30px;
        letter-spacing:1px;
        line-height:1.1em;
        margin-bottom:10px;
    }
    .sub-heading{
        color:$gray_9b;
        font-size:12px;
        line-height:1.1em;
    }

    .unsubscribe-info,.contact{
        color:$wim-black;
        font-size:14px;
        line-height:1.5em;
        margin-bottom:5px;
    }

    hr{
        width: calc(100% - 20px);
        margin-left:10px;
        margin-right:10px;
        margin-top:20px;
        color:$wim-black;
    }
}