/* mobile carousel style */
.mobile-bottom{
    .carousel{
        @include breakpoint(desktop){
            display: none;
        }
        background: transparent;
        padding-bottom: 30px;
        .image-gallery-slide{
            background: transparent;
        }
        .image-gallery-swipe{
            overflow: hidden;
        }
        .image-gallery-bullets{
            bottom: -4.5%;
            .image-gallery-bullet{
                background-color: white;
                opacity: 45%;
                &.active{
                    opacity: 100%;
                }
            }
        }
        .image-gallery-right-nav,.image-gallery-left-nav{
            background-color: rgba(255,255,255,.35);
            &:before{
                text-shadow: none;
            }
            @include breakpoint(tablet){
                font-size: 3em;
            }
            @include breakpoint(tablet, max ){
                font-size: 1.7em;
            }
        }
        .image-gallery-right-nav{
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            @include breakpoint(tablet){
                padding: 0px 6px 0px 10px;
            }
            @include breakpoint(tablet, max ){
                padding: 0px 9px 0px 6px;
            }
        }
        .image-gallery-left-nav{
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            @include breakpoint(tablet){
                padding: 0px 10px 0px 6px;
            }
            @include breakpoint(tablet, max ){
                padding: 0px 6px 0px 9px;
            }
        }
        
        .slide{
            text-align: center;
            .slideItem{
                width: 37%;
                @include breakpoint(tablet, max){
                    width: 41%;
                }
                margin: 5px;
                background: transparent; 
                display: inline-block;
				vertical-align: top;
				
                .slideTitle{
                    text-transform: uppercase;
                    padding: 20px;
                    color: white;
                    text-align: center;
                    font-size: 0.8em;
                }
            }
        }
    }
}