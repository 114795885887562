
.related-cnt {
    &.four-col {
        margin-left: 0px;
        margin-right: 0px;
        @include clearfix;
    }
}

.related-content-tile{
    width: 100%;
    margin-bottom:  16px;
	@include gotham(medium);
	display: flex;
	cursor: pointer;

	i {
        display: none;
        background-color: rgba(0, 0, 0, .3);
        border-radius: 50px;
    }

	&.video-tag {
		user-select: none;
		-webkit-tap-highlight-color: transparent;
		
		.image-container {
			box-shadow: inset 0 0 0 1px black;
		}
		i {
            display: block;
        }
	}

	.click-wrapper {
		display: flex;
		width: 100%;
	}

    .new-tag {
        text-transform: uppercase;
        background: $live-green;
        width: 50px;
        height: 15px;
        color: $black;
        text-align: center;
        @include gotham(medium);
        font-size: 10px;
        position: absolute;
        top: 0px;
        padding-top: 1.5px;
    }

    .type-tag {
        color: $white;
        text-transform: uppercase;
        background: $wim-green;
        height: 15px;
        @include gotham(medium);
        line-height:15px;
        font-size: 10px;
        padding: 0 10px;
        text-align: center;
        position: absolute;
		white-space: nowrap;
    }

    .duration-tag {
        width: 45px;
        height: 15px;
        position: absolute;
        right: 0px;
        background-color: $wim-black;
        color: $white;
        margin-top: -15px;

        @include gotham(medium);
        font-size: 10px;
        text-align: center;
        line-height: 14px;
    }

    i {
        color: $white;
        font-size: 56px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
    }

    .image-container {
		flex: 0 0 50%;
        position: relative;
        width: 100%;
        padding-bottom: calc(66.66% / 2);
        height: 0px;
        background-color: $white;
        background-size: cover;
		overflow: hidden;

		img {
			width: 100%;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}

        &.wide {
            padding-bottom: calc(56.25% /2);
        }
	}

    .content-text {
		flex: 0 0 50%;
		margin-left: 10px;

		.content-timestamp {
			color: $wim-purple;
			text-transform: uppercase;
			@include gotham(medium);
			font-size: 12px;
			margin-top: 22px;
			letter-spacing: 1px;
		}
		
		.content-title {
			color: $wim-black;
			@include gotham(medium);
			font-size: 18px;
			margin-top: 5px;
			//height: 100%;
			line-height: 1.3em;
			//overflow: hidden;
			letter-spacing:1.5px;
		}
		
		.content-description{
			font-size: 12px;
			line-height: 1.5em;
			overflow-y: hidden;
			margin-top: 5px;
			@include gotham-book();
			position:relative;
		}
	}

    &.one-col, &.two-col{
		width: calc(100% - 7px * 2);
	
		.content-text{
			.content-description{
				display:  none;
			}
			.content-date {
				display: none;
			}
			.content-title {
				// margin-top: 20px;
				// height: 73px;
				// line-height: 1.1em;
				// font-size: 14px;
				// overflow-y: hidden;
				// width: 100%;
				
			}
		}

		@include breakpoint(tablet){
			width: calc(50% - 7px * 2);
		}
	
    }
}

.related-content-tile:nth-child(odd) {
    clear: both;
}


/*

.content-link{
        display: flex;
        overflow: hidden;

        .content-text{
            color:  $wim-black;
            //flex:  0 0 50%;
            display:  flex;
            flex-direction:  column;
            margin-left:  10px;

            .type-tag{
                color: $white;
                text-transform: uppercase;
                background: $wim-green;
                height: 15px;
                @include gotham(medium);
                font-size: 10px;
                padding: 0 10px;
                line-height:15px;
                text-align: center;
                flex: 0 0 auto;
                letter-spacing:  2px;
                white-space: nowrap;
                position:absolute;
            }

            .content-date {
                @include gotham(medium);
                font-size: 12px;
                color: $wim-text-purple;
                margin-top: 20px;
            }

            .content-title{
                font-size:  16px;
                flex:  0 0 auto;
                position:relative;
				margin-top: 20px;
			}
			
			.content-date + .content-title {
				margin-top: 4px;
			}

            .content-description{
                font-size: 12px;
                line-height: 1.5em;
                overflow-y: hidden;
                margin-top: 5px;
                @include gotham-book();
                position:relative;
            }
        }
        .image-container{
            img{
                width:  100%;
                height:  auto;
            }
            flex:  0 0 50%;
        }
	}
	
	*/
