/*  twitter video embeds have max of ~889 by observation
    - this is used to center video
    - although as of 5/6 on single four-col embed it seems to work full width
*/

.embedContent {
    //max-width: 890px;
    margin: 0 auto;

    position: relative;
    width: 100%;
    //padding-bottom: 56.25%;
    //height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.youtubeEmbed {
    //max-width: 890px;
    margin: 0 auto;

    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}