.news-list-tile{

    &.one-col{
        .news-link{
            .news-list-info{
                .news-timestamp{
                    font-size:10px;
                    margin-top:20px;
                    line-height:1.5em;
                }

                .news-title{
                    font-size:14px;
                    line-height:1.25em;
                    margin-top:0;
                    height:3.75em;
                }

                .news-text{
                    display:none;
                }
            }
        }
    }
    .news-link{
        .image-container{
            width: 50%;
            padding: calc(25% / 1.5) 0;
        }

        .news-list-info{
            position: absolute;
            right: 0;
            top: 0;
            width: 50%;
            padding-left:15px;
            height:100%;

            .type-tag{
                right:unset;
                top:0;
            }

            .news-timestamp{
                margin-top:25px;
            }

            .news-text{
                margin-top:5px;
                @include gotham-book();
                color:$select-menu-text;
                font-size:12px;
                line-height:1.5em;
                letter-spacing:1px;
                max-height:calc(100% - 104px);
                overflow:auto;
                position:absolute;
            }
        }
    }
}