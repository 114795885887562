.column-layout.hillvote {
    background-color: $white;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 40px;

    .message {
        min-height: 80vh;
        margin-top: 20%;
        text-align: center;
        width: 100%;
    }
}

#hillvote {
    .intro {
        text-align: center;
        margin-top: 20px;
    }
}

.vote-box {
    @include gotham();
    padding-bottom: 20px;
    position: relative;

    &.one-col {
        width: calc(100% - 15px * 2);
        margin-right: 15px;
        margin-left: 15px;

        @include breakpoint(desktop) {
                width: calc(50% - 30px * 2);
                margin-right: 30px;
                margin-left: 30px;
        }
    
        @include breakpoint('1124px') {
                width: calc(33.3% - 30px * 2);
                margin-right: 30px;
                margin-left: 30px;
        }
    }

    h4 {
        border-bottom: solid 2px $gray_bb;
        color: $wim-black;
        font-size: px2rem(14);
        text-transform: uppercase;
        width: 100%;
    }

    .team-info {
        display: flex;
        justify-content: space-evenly;

        .team1, .team2 {
            display: flex;
            flex-flow: column wrap;


            .playerA, .playerB {
                margin-bottom: 10px;
            }

            .player-image {
                display: block;
                margin-bottom: 10px;
                width: 100px;
                height: auto;
            }
    
            .flag {
                display: inline-block;
                vertical-align: middle;
            }
    
            .name {
                color: $wim-green;
                display: inline-block;
                font-size: px2rem(10);
                margin-left: 5px;
                vertical-align: middle;
            }
        }

        .vote-info {
            align-self:  center;
            padding-left: 20px;
            padding-right: 20px;

            .match-name { 
                color: $gray-650;
                font-size: px2rem(12);
                text-align: center;
                text-transform: uppercase;

                span {
                    display: block;
                }
            }
        }
    }

    .vote-results {
        position: absolute;
        bottom: -10px;
        width: 100%;

        .meter {
            display: inline-block;
            height: 15px;
            position: relative;
            background: $gray-light;
            padding: 0px;
            vertical-align: middle;
            width: calc(100% - 50px);
            border-radius: 5px;

            span {
                display: block;
                height: 100%;
                background-color: $wim-green;
                position: relative;
                overflow: hidden;
                border-radius: 5px;

                transition: width .5s ease-in-out; 
              }
        }

        .result {
            color: $wim-green;
            font-size: px2rem(14);
            margin-left: 10px;
            
            transition: width 1s ease-in-out; 
        }
    }

    .vote-status {
        color: $wim-green;
        font-size: px2rem(11);
        margin-top: 20px;
        text-align: center;

        .wim-icon-check {
            padding-right: 8px;
            font-size: px2rem(20);
        }

        .wim-icon-thumb-up-blank {
            font-size: px2rem(40);

            &:hover {
                cursor: pointer;
            }
        }
    }

}

@keyframes fillbar {
    from {left: 0px;}
    to {right: 100%;}
}