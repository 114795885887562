.homepage-element-info{
    color:$white;
    left:45px;
    width:350px;
    @include breakpoint(desktop, max){
        left:23px;
    }

    i{
        font-size:32px;
        margin-bottom:20px;
        display:block;

        @include breakpoint(tablet, max){
            margin-bottom:10px;
        }
	}
	.title {
		&:hover ~ hr{
			transform: scaleX(1.75);
		}
	}
	hr {
		display:none;
		transition: transform 0.3s ease;
		transform-origin: 0 0;
	}
	
	.read-more{
        border: 2px solid $white;
        text-transform: uppercase;
        color:$white;
        padding: 8px 20px;
        height: 40px;
        width: 160px;
        display: none;
        text-align: center;
    }

    &.right{
		right:45px;
		text-align:right;
		left:unset;
		.title {
			margin-right: 0px;
		}
		@include breakpoint(desktop, max){
			// right:23px;
		}
    }
    &.left {
		.title {
			margin-left: 0px;
		}
	}
	&.fullscreen {
		.title {
			color: $white;
		}
		.time {
			color: $white;
		}
	}
    .time{
        font-size: 10px;
        text-transform:uppercase;
        letter-spacing:2px;
		display:block;
		color: $wim-green;
		
		.timestamp-time {
            margin-left: 7px;
		}
    }

    .title{
		font-size: 24px;
		line-height: 26px;
		letter-spacing: initial;
		margin-top: 10px;
		margin-bottom: 10px;
		color: $wim-text-purple;
		// margin: 5px 5px 10px 5px;
		// max-height: 80px;
		// overflow: hidden;
		
        &.desktop {
			display: none;
			margin-top: 10px;
		}
		
        &.mobile {
			display: block;
			font-size: 20px;
			// line-height: 20px;
        }
    }

    .text{
        display: none;
	}
	@include breakpoint(tablet) {
		.title {
			font-size: 24px;
			line-height: 26px;
			margin-top: 0px;
			margin-bottom: 0px;
			&.mobile {
				font-size: 22px;
				line-height: 24px;
				text-align: left;
			}
		}
	}
	@include breakpoint(desktop) {
		.title {
			font-size: 30px;
			line-height: 32px;
		}
	}
	@media only screen and (min-width : 550px) {
		.title {
			&.mobile {
				// font-size: 22px;
				// letter-spacing: 1px;
				// margin: 7px 10px 15px 10px;
				display: block;
				// line-height: 32px;
			}
		}
    }

    @include breakpoint(tablet){
        // left: 50%;
        // text-align: center;
        overflow: hidden;
        width: 225px;

        .time{
            font-size: 12px;
        }

        .title{
            // font-size: 30px;
            // letter-spacing: 2px;
			// margin: 7px 10px 15px 10px;

			&.mobile {
				display: block;
				// line-height: 32px;
			}
        }

        .text{
			display: block;
            font-size:12px;
			line-height:19px;
			letter-spacing:1px;
			@include gotham-book();
			margin-bottom: 20px;
        }
	}
	@include breakpoint(tablet, max){
		pointer-events: none;

		.time {
			color: $white !important;
			text-align: left;
		}
		.title {
			&.mobile {
				color: $white;
				text-align: left;
			}
		}
		i {
			text-align: center;

			&.large {
				position: relative;
				bottom: unset;
				left: unset;
				font-size: 42px;
				margin-bottom: 10px;
			}
		}
	}

	@include breakpoint(desktop) {
		.title {
			// font-size: 30px;
			// letter-spacing: 2px;
			
			&.desktop {
				display: block;
				// letter-spacing: unset;
				// font-size: unset;
			}
			
			&.mobile {
				display: none;
			}
		}
	}

	&.standard{
		@include breakpoint(tablet){
				// text-align:center;
				text-align: left;
				left:0;
				width:34%;
				color:$select-menu-text;
				
				i{
					color:$wim-green;
					font-size:26px;
					margin-bottom:15px;
					padding-left: 8px;
				}
				.time {
					color:$wim-green;
					padding-left:8px;
					padding-right:8px;
					margin-bottom:10px;
				}
				.title{
					// max-height:90px;
					// overflow:hidden;
					padding-left:8px;
					padding-right:8px;
					margin-bottom:0;
				}
				.text{
					// max-height:114px;
					// overflow:hidden;
					padding-left:8px;
					padding-right:8px;
					margin-top:15px;
					margin-bottom:0;
				}

				hr{
					// display:block;
					// width:100%;
					display: inline-block;
					width: 32px;
					border: none;
					height: 2px;
					background-color: $wim-text-purple;
					margin:10px 0 15px;
					margin-left: 8px;
				}

				.read-more{
					display:none;
				}

			&.right{
					// right:0;
					right: -25px;
					left:auto;
			}
		}
	}

	@include breakpoint(desktopSidePanel, max) {
		.title {
			// font-size: 22px;
			// letter-spacing: 2px;
		}
	}	
}