/**
 * Typography related helpers and classes
 */

// https://fonts.google.com/specimen/Work+Sans
@import './gotham-font';
@import './gotham-book-font';
@import './helvetica-font';
@import './ibm-plex-sans-font';
//@import './adobe-caslon-pro';

$font-family-sans-serif: gotham-family();
$font-family-sans-serif-bold: gotham-family($weight: medium);

body {
    font-family: $font-family-sans-serif;
    font-size: 16px;
    font-style : normal;
    font-variant: normal;

    &, * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
    }
}

h1, .h1 {
    @include gotham($weight: medium);
    font-size: 30px;
    letter-spacing: 1px;
    font-weight: normal;
}

h2, .h2 {
    @include gotham($weight: medium);
    font-size: 26px;
    font-weight: normal;
}

h3, .h3 {
    @include gotham($weight: medium);
    font-size: 20px;
    letter-spacing: .5px;
    font-weight: normal;
    margin: 0;
    
    &.green {
		color: $wim-green;
    }
    &.purple {
		color: $wim-text-purple;
	}
	&.upper {
		text-transform: uppercase;
	}

	a {
		&:hover, &:active {
			text-decoration: none;
		}
	}
}

h4, .h4 {
    @include gotham($weight: medium);
    font-size: 16px;
    letter-spacing: .5px;
    font-weight: normal;
    margin: 0;
}

h5, .h5 {
    @include gotham(medium);
    font-size: 14px;
    letter-spacing: .5px;
    font-weight: normal;
    margin: 0;

    &.green {
		color: $wim-green;
    }
    &.purple {
		color: $wim-text-purple;
    }
    &.white {
        color: $white;
    }
    &.required {
        &::after {
            content: "*";
            @include gotham();
            color: $red;
        }
    }
}

h6, .h6 {
    @include gotham-book();
    font-size: 12px;
    letter-spacing: .5px;
    font-weight: normal;
    margin: 0;

    &.medium, .medium {
        @include gotham(medium);
    }
    &.purple {
		color: $wim-text-purple;
	}
    &.green {
        color: $wim-green;
    }
    &.white {
        color: $white;
    }
}

p {
    margin: 0;
}

a, a:visited {
    color: $wim-green;
    text-decoration: none;

    &:hover, &:active {
        color: $wim-green;
        // @include gotham(medium);
        text-decoration: underline;
    }
}

a.white, a.white:visited {
	color: $white;
	text-decoration: none;
   
	&:hover, &:active {
		color: $white;
		// @include gotham(medium);
        text-decoration: underline;
	}
}

.monospace {
  font-family: monospace;
}

.strong {
  font-weight:  bold;
}

.italic {
    font-style: italic;
}

small,
.small {
    font-size: 14px;
}

.mini {
    font-size: 8px;
}

.txt-strike {
    text-decoration: line-through;
}

// cms created text which should look like a link

.pdf-link, .pdf-link:visited {
    color: $wim-green;
	text-decoration: none;
	cursor: pointer;

    &:hover, &:active {
        color: $wim-green;
        // @include gotham(medium);
        text-decoration: underline;
    }
}

.button-link, .button-link:visited {
	font-size: 100%;
	font-family: inherit;
	border: 0;
	padding: 0;
	
    color: $wim-green;
	text-decoration: none;
	cursor: pointer;

    &:hover, &:active {
        color: $wim-green;
        // @include gotham(medium);
        text-decoration: underline;
    }
}



.gotham {
    @include gotham();
}

.gotham-medium {
    @include gotham($weight: medium);
}

.gotham-medium-italic {
    @include gotham($weight: medium, $style: italic);
}

.gotham-bold {
    @include gotham($weight: bold);
}

.gotham-medium-ssm {
    @include gotham($weight: medium, $variant: ssm);
}

.gotham-bold-ssm {
    @include gotham($weight: bold, $variant: ssm);
}

