.live-tabs{
    color: $gray_9b;
    display: flex;
    justify-content: center;
    text-transform: uppercase;

    a {
        color: $gray_9b;
        font-weight: bold;
        padding: 5px;

        &.selected {
            color: $white;
        }

        &:after { content: "|"; padding: 0 0 0 10px; }

        &:last-child {
            &:after { content: ""; padding: 0; }
        }
    }
}