/** white text & white transparent background select styles **/

select {
    &.select-white {
        &.tablet-show {
            background: rgba($white, 0.5);
            border: 2px solid $white;
            border-radius: 3px;
            color: $white;
            @include gotham_book;
                font-size: calc((16/16)*1em);
                height: 36px; // for ff
            line-height: 32px;
            margin: 30px auto 10px;
            outline: 0;
            padding-left: 1em;
            text-transform: uppercase;
            text-align: center;
            text-align-last: center;
            width: calc(40% - 20px);
            &.hidden { display: none; }
            @include breakpoint(desktop) { display: none; }
        }
    }
} // select selet-white