$delay: 3s;
$delay1: 1.7s + $delay;
$delay2: 1.7s + ($delay * 2);

/** for slamtracker  **/
.slamtracker-powerranking{
    margin-bottom: 10px;

    .mi-alert{
        .watson-fader-container{            
            @include breakpoint(tablet, max){
                top: 5px;
                width: 152px;
            }
            @include breakpoint(tablet){
                top: 8px;
            }
            @include breakpoint(desktop){
                top: 14px;
            }            
            width: 200px;
            height: 32px;
        }
        .watson-logo{
            margin-bottom: -6px;
            @include breakpoint(tablet, max){
                margin-bottom: -20px;
            }
            &::before{
                width: 35px;
                height: 35px;
            }
            &::after{
                font-size: 11px;
            }
        }
    }
}

/** match insights **/
.likelihood-win-btn {
    width: 100%;
    .fader-container{
        position: absolute;
        overflow: hidden;
        width: 308px;
        height: 70px;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;
        @include breakpoint (tablet){
            top: -23px;
        }
        @include breakpoint(desktop){
            width: 380px;
        }
        .fader{
            height: 60px;
            position: absolute;
            width: 530px;
            z-index: 900;
            top: 0px;
            @include breakpoint (tablet){
                width: 590px;                
            }
            @include breakpoint(desktop){
                width: 650px;
            }
            &.no-upset{
                &.right{
                    animation: slideRightSm 0.8s forwards;
                    animation-delay: $delay1;
                    @include breakpoint (tablet){
                        animation: slideRightMd 0.8s forwards;
                        animation-delay: $delay1;
                    }
                    @include breakpoint (desktop){
                        animation: slideRightLg 0.8s forwards;
                        animation-delay: $delay1;
                    }   
                }
                &.left{
                    animation: slideLeftSm 0.8s forwards;
                    animation-delay: $delay1;
                    @include breakpoint (tablet){
                        animation: slideLeftMd 0.8s forwards;
                        animation-delay: $delay1;
                    }
                    @include breakpoint (desktop){
                        animation: slideLeftLg 0.8s forwards;
                        animation-delay: $delay1;
                    }    
                }
            }
            &.upset{
                &.right{
                    animation: slideRightSm 0.8s forwards;
                    animation-delay: $delay2;
                    @include breakpoint (tablet){
                        animation: slideRightMd 0.8s forwards;
                        animation-delay: $delay2;
                    }
                    @include breakpoint (desktop){
                        animation: slideRightLg 0.8s forwards;
                        animation-delay: $delay2;
                    }   
                }
                &.left{
                    animation: slideLeftSm 0.8s forwards;
                    animation-delay: $delay2;
                    @include breakpoint (tablet){
                        animation: slideLeftMd 0.8s forwards;
                        animation-delay: $delay2;
                    }
                    @include breakpoint (desktop){
                        animation: slideLeftLg 0.8s forwards;
                        animation-delay: $delay2;
                    }    
                }
            }
            
            &.right{
                background: rgb(2,0,36);
                background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%);
                right: 0px;                             
            }
            &.left{
                background: rgb(255, 255, 255);
                background: linear-gradient(90deg, rgba(255,255,255,1) 50%,  rgba(255,255,255,0) 100%);
                left: 0px;          
            }
        }
    }
    .btn {
        position: relative;
        background-color: $white;
        font-size: px2rem(10);
        border-radius: 100px;
        border: solid 1px $wim-green;
        padding: 5px 15px;
        color: $wim-black;
        text-align: center;
        margin: auto;
        width: 150px;
        &.right{
            &::after{
                border-bottom: 1px solid $wim-green;
                border-right: 1px solid $wim-green;
                content: '';
                position: absolute;
                width: 33px;
                height: 35px;
                right: -34px;
                top: -23px;
                z-index: -1;
                @include breakpoint(desktop){
                    width: 103px;
                    right: -76px;
                    border-right: none;
                }
            }
        }
        &.left{
            &::after{
                border-bottom: 1px solid $wim-green;
                border-left: 1px solid $wim-green;
                content: '';
                position: absolute;
                width: 33px;
                height: 35px;
                left: -34px;
                top: -23px;
                z-index: -1;
                @include breakpoint(desktop){
                    width: 103px;
                    left: -76px;
                    border-left: none;
                }
            }
        }
        @include gotham(medium);

        @include breakpoint(tablet) {
            font-size: px2rem(16);
            // padding: 10px 30px;
            // width: 250px;
            padding: 5px 10px;
            width: 230px;
        }

        .btn-txt{       
            position: relative;
            z-index: 900;
            &.no-upset{
                &::before{
                    animation: dot 0.5s forwards;
                    animation-delay: $delay;
                }
            }
            &.upset{
                &::before{
                    animation: dot 0.5s forwards;
                    animation-delay: ($delay * 2);
                }
            }
            
            &::before {
                @extend %likelihood-win-purple-circle;
                margin-right: 15px;
                position: relative;
                top: -30px;
                opacity: 0;                
            }
        }
    }

}

.MITeaser {
    position: relative;
}

.mi-alert {
    bottom: 100%;
    position: absolute;
    width: 160px;
    display: flex;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    z-index: 101;

    @include breakpoint(tablet) {
        width: 300px;
    }

    &.upset{
        .watson-fader-container{
            .fader{
                animation: watson-shutter 3s linear 2;
            }
        }
    }
    &.no-upset{
        .watson-fader-container{
            .fader{
                animation: watson-shutter 3s linear 1;
            }
        }
    }
    .watson-fader-container{
        position: absolute;
        overflow: hidden;
        width: 215px;
        height: 70px;
        top: -24px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 910;

        @include breakpoint(tablet, max){
            width: 165px;
            height: 25px;
            top: 2px;
        }
        @include breakpoint(desktop){
            top: -15px;
            width: 308px;
        }
        .fader{
            height: 70px;
            position: absolute;
            width: 530px;
            z-index: 900;
            top: 0px;            
            background: rgb(255, 255, 255);
            background: linear-gradient(90deg, rgba(255,255,255,1) 50%,  rgba(255,255,255,0) 100%);
            left: 0px;
        }
    }

    .watson-logo { 
        font-size: px2rem(12);
        padding-left: 0px;
        line-height: 24px;
        text-align: center;
        transform: translateX(-10px);
        width: 300px;

        &::after{
            content: 'Watson Insight';
            display: inline-block;
            line-height: 24px;
            margin-left: 5px;
        }

        &.upset{
            &::after{
                animation: upset-alert 0.1s infinite;
                animation-delay: $delay;
            }
        }

        @include breakpoint(tablet) {
            font-size: px2rem(18);
            margin-bottom: 10px;
            line-height: 30px;
            transform: translateX(-20px);
        }

        @include breakpoint(desktop) {
            font-size: px2rem(18);
            margin-bottom: 20px;
            line-height: 34px;
        }

        &::before {
            content: '';
            // background: url("/assets/images/logos/watson-blue-logo.svg") no-repeat; 
            // background-size: contain;
            display: inline-block;
            position: relative;

            background-image: url("/assets/images/logos/avatar_small.0036e5d0.png");
            background-size: 21600%, 100%;
            -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
            -webkit-animation-duration: 7.2s;
            animation-duration: 7.2s;
            -webkit-animation-timing-function: steps(215);
            animation-timing-function: steps(215);
            background-position: left;
            background-repeat: no-repeat;
            -webkit-animation-name: logo-loop;
            animation-name: logo-loop;
            -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
            margin: 0;
            padding: 0;
            border: 0;

            height: 24px;
            width: 24px;
            top: 5px;

            @include breakpoint(tablet) {
                // background-size: contain;
                height: 31px;
                width: 31px;
                top: 7px;
            }

            @include breakpoint(desktop) {
                // background-size: contain;
                height: 45px;
                width: 45px;
                top: 12px;
            }
        }
    }
}

/** Slamtracker page override 
    - btn sizing
*/
.slamtracker {
    .likelihood-win-btn {
        min-width: 100px;
        width:auto;
        margin:0px auto;
    }
    @include breakpoint(tablet, min, true) {
        .likelihood-win-btn {
            min-width: 220px;
            .btn {
                padding: 5px 10px;
                width: 220px;
            }
        } // .likelihood-win-btn

        .mi-alert {
            .watson-logo { 
                padding-left: 0px;
                line-height: 24px;
                font-size: px2rem(16);
                text-align: center;
                transform: translateX(-10px);
            }

            .watson-fader-container {
                .fader {
                    width: 300px;
                    height: 33px;
                }
            }
        } // .mi-alert
    }
}

/** animation stuff **/

/** logo **/
@keyframes watson-shutter{
    0% { left: 0px; }
    50% { left: -1030px; }
    100% { left: 0px; }
}

@-webkit-keyframes watson-shutter{
    0% { left: 0px; }
    50% { left: -1030px; }
    100% { left: 0px; }
}

@keyframes upset-alert{
    0% { content: "Upset Alert"}
    100% { content: "Upset Alert"}
}

@-webkit-keyframes upset-alert{
    0% { content: "Upset Alert"}
    100% { content: "Upset Alert"}
}

@keyframes logo-loop {
    0% {
        background-position: left;
    }
    100% {
        background-position: right;
    }
}

@-webkit-keyframes logo-loop {
    0% {
        background-position: left;
    }
    100% {
        background-position: right;
    }
}

/** pill */

@-webkit-keyframes slideRightSm {
    0% { right: 0px; }
    100% { right: -530px; }
}

@keyframes slideRightSm {
    0% { right: 0px; }
    100% { right: -530px; }
}

@-webkit-keyframes slideLeftSm {
    0% { left: 0px; }
    100% { left: -530px; }
}

@keyframes slideLeftSm {
    0% { left: 0px; }
    100% { left: -530px; }
}

/** md **/
@-webkit-keyframes slideRightMd {
    0% { right: 0px; }
    100% { right: -590px; }
}

@keyframes slideRightMd {
    0% { right: 0px; }
    100% { right: -590px; }
}

@-webkit-keyframes slideLeftMd {
    0% { left: 0px; }
    100% { left: -590px; }
}

@keyframes slideLeftMd {
    0% { left: 0px; }
    100% { left: -590px; }
}

/** lg **/
@-webkit-keyframes slideRightLg {
    0% { right: 0px; }
    100% { right: -650px; }
}

@keyframes slideRightLg {
    0% { right: 0px; }
    100% { right: -650px; }
}

@-webkit-keyframes slideLeftLg {
    0% { left: 0px; }
    100% { left: -650px; }
}

@keyframes slideLeftLg {
    0% { left: 0px; }
    100% { left: -650px; }
}

/** dot **/

@-webkit-keyframes dot{
    0% { top: -30px; opacity: 0; }
    100% { top: 0px; opacity: 1; }
}

@keyframes dot{
    0% { top: -30px; opacity: 0; }
    100% { top: 0px; opacity: 1; }
}
