/** player-professional-info styles **/
.player-professional-info {
    align-items: center;
    display: flex;
    margin: 0px; 
    border-bottom: 1px solid #BBBBBB;
    //amb - defect 618
    // @include breakpoint(desktop){
    //   margin: 16px 10px 10px 10px !important;
    // }
    .professional-info-item {
        // width: calc(50% - 20px);
        margin: 0px;

        //amb - defect 618
        // @include breakpoint(tablet){
        //     margin: 10px 10px 10px 10px;
        // }
        &.text {
            width: 75%;
        }
    }

    .player-name {
        color: $wim-green;
        font-size: 18px;
    }
    
    .professional-info {
        display: flex;
        flex-flow: column;
        margin-top: 20px;
        font-size: 14px;
        div {
            margin-bottom: 10px;
            // height: 30px;
        }
    }
    
    .professional-info-field {
        color: $wim-black;
        width: 175px;
        display: inline-block;
    }
    
    .professional-info-value {
        display: inline-block;
        color: $wim-purple;
    }
    @media only screen and (max-width: $column-break-1) {
        .professional-info-item {
            //amb - defect 618
            // &.image {
            //     padding-right: 10px;
            // }
        }
    }
}
