.page {
    .wrapper.webview{
		.news--header-wrapper {
			display: block;
			position: relative;
			left: 0px;
			//bottom: 100%;
			color: white;
			width: 100%;

			.image--header {
				img {
					width: 100%;
					position: relative;
					vertical-align: bottom;
				}
			}
			&::after{
				content: '';
				height: 180%;
				width: 100%;
				background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3), transparent);
				position: absolute;
				bottom: 0px;
			}
			.news--header {
				position: absolute;
				bottom: 0px;
				width: 80%;
				margin-left: 10%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				opacity: 1;
				z-index: 2;
				text-shadow: $textShadow;
				color: $white;
			}
		}
		
		.content-main {
			&.news {
				padding-top: unset;

				.textWrapContent {
					h3 {
						margin: 0 0 5px 0;
						font-size: 1.17em;
					}
				}

				.byline {
					&.left + .margin{
						@include breakpoint(desktop, min){
							margin-left: $item-h-margin;
						}
					}
				}

			}
		}

		.new-tag {
			display: none;
		}
	}
}

.news--header {

    h1, h2, h3, h4 {
        margin: 0px;
    }

    &.hide {
        opacity: 0;
        transition: opacity .5s linear;
    }

    &.show {
        opacity: 1;
        transition: opacity .5s linear;
    }

    &-icon {
        flex: 0 1 auto;
        align-self: center;
        margin: 0 0 4px 0;
        text-align: center;
        font-size: 32px;
    }

    &-date {
        flex: 0 1 auto;
        align-self: center;
        margin: 0 0 4px 0;
        text-align: center;
        letter-spacing: 1px;
    }

    &-shorttitle {
        flex: 0 1 auto;
        align-self: center;
        margin: 0 0 6px 0;
        text-align: center;
		letter-spacing: 1px;
		margin-bottom: 10px;

        @include breakpoint(tablet, max){
            h1 {
                font-size: 20px;
                letter-spacing: .5px;
            }
        }
    }

    &-abstract {
        line-height: 18px;
        text-align: center;
        flex: 0 1 auto;
        align-self: center;
        margin: 0 0 30px 0;
        max-width: calc(100% - 30px);
        letter-spacing: 1px;

        h3, h4 {
            display: inline;
        }

        h3 {
            white-space: nowrap;
        }

        @include breakpoint(tablet, max){
            letter-spacing: 0.5px;
            margin: 0 0 20px 0;
        }
    }

    &-videoLink {
        position: absolute;
        bottom: 0px;
        margin: 0 0 15px 15px;
        @include gotham();
        font-size: 18px;
        text-shadow: 0px 0px 10px #000000;
        cursor: pointer;
        z-index: 5;

        i {
            font-size: 25px;
            background-color: rgba(0, 0, 0, .4);
            border-radius: 15px;
            margin: -2px 0 0 8px;
            position: absolute;
        }

        &:hover {
            color: $wim-green;
        }
    }
}

.column-layout {
    &.news {
        display: block;

        p, span.p {
            font-size: $news-font-size-small;
            line-height: $news-text-line-height-small;
            margin: 0;
            padding-top: $item-v-margin;
            padding-bottom: $item-v-margin;
            &:first-child {
                padding-top: 0;
                //margin-top: -$item-v-margin;
            }
            &:last-child {
                padding-bottom: 0;
                //margin-bottom: -$item-v-margin;
            }

            @media only screen and (min-width: $column-break-1) {
                font-size: $news-font-size;
                line-height: $news-text-line-height;
            }
        }
        
        .one-col, .two-col, .three-col, .four-col {
            //width: 100%;
            float: left;
            //background-color: $test-light-yellow;

            &.inline {
                width: 100%;
                //background-color: $test-light-yellow;
            }
        }

        .inline {
            margin-left: 0px;
            margin-right: 0px;

            &.right {
                float: right;
            }
        }

        .section {
            clear: both;
        }

        .clear {
            clear: both;
        }

         @include breakpoint(tablet, min){
            .one-col{

                &.margin {
                    margin-left: calc(25% + #{$item-h-margin});
                }

                .one-col{ 
                    &.inline {
                        width: 100%;
                        //background-color: $test-light-blue;
                    }
                }

                //background-color: $test-light-blue;
            }

            .two-col, .three-col, .four-col {
                //background-color: $test-light-yellow;

                .one-col, .two-col{ 
                    &.inline {
                        width: 50%;
                        margin-right: $item-h-margin;
                        //background-color: $test-light-blue;

                        &.right {
                           margin-left: $item-h-margin; 
                           margin-right: 0px;
                        }
                    }
                }
            }
        }

         @include breakpoint(desktop, min){

            .one-col {
                //background-color: $test-light-gray;

                &.margin {
                    margin-left: calc(25% + #{$item-h-margin});
                }

                .one-col { 
                    &.inline {
                        width: 100%;
                        //background-color: $test-light-gray;
                    }
                }
            }
        
            .two-col {
                //background-color: $test-light-blue;

                .one-col { 
                    &.inline {
                        @include inline(50%, $test-light-gray);
                    }
                }

                .two-col { 
                    &.inline {
                        width: 100%;
                        //background-color: $test-light-blue;
                    }
                }
            }
        
            .three-col {
                //background-color: $test-light-green;

                .one-col { 
                    &.inline {
                        @include inline(33%, $test-light-gray);
                    }
                }

                .two-col { 
                    &.inline {
                        @include inline(66%, $test-light-blue);
                    }
                }
            }
        
            .four-col {
                //background-color: $test-light-yellow;

                .one-col { 
                    &.inline {
                        @include inline(25%, $test-light-gray);
                    }
                }

                .two-col { 
                    &.inline {
                        @include inline(50%, $test-light-blue);
                    }
                }
            }
        
            .one-col, .two-col, .three-col  {
                &.margin {
                    margin-left: calc(25% + #{$item-h-margin});
                }
            }
            
        }
    }

    .section {
        margin: $item-v-margin 0 0 0;
    }
}
