.radioBtn {
    display: inline-flex;
    position: relative;
    padding-right: 5px;
    // padding-left: 25px;
    
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #fff;
        border-radius: 50%;
        border: 1px solid $wim-text-purple;
        
        &:hover {
            box-shadow: inset 0 0 0 1px $wim-text-purple;
        }
        &:after {
            top: 3px;
            left: 3px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: $wim-text-purple;
            content: "";
            position: absolute;
            display:none;
        }
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        z-index: 1;
    }
    input:checked ~ .checkmark:after {
        display: block;
    }
    label {
        padding-left: 25px;
    }
}