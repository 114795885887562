

.determining-factors-container {
    display:flex;
    flex-direction: column;
    width:100%;

    .bar-graph-wrapper {   
        position: relative;  
        margin-bottom:11px;   
        .bar-graph-title {
            @include gotham-book();
            font-size: px2rem(14);
            line-height: 18px;
            margin-bottom:6px;
        }
        .bar-graph-footer {
            text-align: center;
            @include gotham(medium);
            text-transform: uppercase;
            font-size: px2rem(12);
            line-height: 18px;
        }
        
        .bar-graph-container {
            background-color: $powerrank-bar-bg;
            padding:2px;
            width:100%;
            height:24px;
            .bar-graph-bar {
                background-color: $powerrank-bar;
                height:100%;
            }

            &.double {
                display:flex;
                justify-content:center;
                width:100%;
                position: relative;
                margin-bottom:4px;

                &::before {
                    content:"neg";
                    color:$powerrank-text;
                    position: absolute;
                    text-transform: uppercase;
                    left:2px;
                    font-size:px2rem(12);
                    line-height: 20px;
                    @include gotham(medium);
                }
                &::after {
                    content:"pos";
                    color:$powerrank-text;
                    text-transform: uppercase;
                    position: absolute;
                    right:2px;
                    font-size:px2rem(12);
                    line-height: 20px;
                    @include gotham(medium);
                }

                .bar-graph-bar {
                    width:50%;
                    background-color: transparent;
                    margin:0px 2px;
                    &.neg {
                        background-color: $powerrank-bar;
                    }
                    &.pos {
                        background-color: $powerrank-bar;
                    }
                }
                .bar-graph-divider {
                    height: 30px;
                    position: absolute;
                    margin: 0px auto;
                    border-left: solid 1px $wim-black;
                    bottom: -4px;
                    width: 0px;
                    left: 0px;
                    right: 0px;
                }
            }
        }
    }
}

.rank-over-time-container {
    width:100%;

    .line-graph-container {
        position:relative;
        //height:200px;
    }
    .ct-chart {
        height:200px;
        width:100%;
        position: relative;
        z-index: 1;
        &.chart-1 {
            span.ct-label.ct-horizontal.ct-end {
                position: relative;
                transform: rotate(-45deg);
                display:inline-block;
                width:auto !important;
                margin-left:-3px;
            }
        }
        &.chart-2 {
            position: absolute;
            top:0px;
            left:0px;
            z-index: 0;

            span.ct-label.ct-vertical.ct-end {
                display:none;
            }

            span.ct-label.ct-horizontal.ct-end {
                position: relative;
                visibility: hidden;
                &:before {
                    content: '|';
                    position: absolute;
                    top: -13px;
                    left: 7px;
                    display:block;
                    visibility: visible;
                }

                // @include general-breakpoint(tablet) {
                //     //top: -170px;
                // }

                // @include general-breakpoint(desktop) {
                //     //top: -205px;
                // }
            }
            // .ct-grid.month-change {
            //     stroke:none;
            // }
        }
        &.chart-3 {
            .ct-grid.month-change {
                stroke:none;
            }
        }
    }
    .ct-vertical ~ .ct-vertical {
        stroke: none;
    }
    .ct-horizontal ~ .ct-horizontal {
        stroke: none;
    }
    .ct-grid {
        stroke-dasharray:0px;
        stroke:$wim-black;
    }
    
    .ct-series {
        .ct-label {
            display:none;
        }
    }
    .ct-label {
        color:$wim-black;
        font-size: px2rem(11);
    }
    .ct-line {
        stroke-width: 1px;
    }
    .ct-point {
        // stroke-width:4px;
        stroke-width:1px;
    }
    .atp-series, .tour-series {
        .ct-line, .ct-point {
            stroke: $tourrank-color;
            stroke-dasharray: 2px;
        }
    }
    .powerrank-series {
        .ct-line, .ct-point {
            stroke: $powerrank-series-color;
        }
    }

    .lastmatch-series {
        .ct-line, .ct-point {
            stroke: $powerrank-series-color;
        }
        .ct-point {
            stroke-width:10px;
        }
        .ct-label {
            display:block;
            fill: white;
            font-size: px2rem(10);
            // @include gotham(medium);
        }

        &.loss {
            .ct-line, .ct-point {
                stroke: $powerrank-loss;
            }
        }
    }
    .prevmatch-series {
        .ct-line, .ct-point {
            stroke: $powerrank-series-color;
        }
        .ct-point {
            stroke-width:8px;
        }
        .ct-label {
            display:block;
            fill: white;
            font-size: px2rem(10);
            // @include gotham(medium);
        }
    }

    .legend-container {
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        width:100%;
        padding-left:10px;
        color:$chart-label-color;

        .legend {
            display:flex;
            flex-direction: row;
            align-items: center;
            @include gotham(medium);
            font-size: px2rem(12);
            line-height: 12px;
            margin-bottom:8px;

            .bullet {
                display: block;
                position: relative;
                margin-right:6px;
                &.dot {
                    width: 8px;
                    height: 8px;
                    min-width: 8px;
                    min-height: 8px;
                    border-radius: 50%;
                    background: black;
                }
                &.dashed {
                    width:10px;
                    height:1px;
                    border-top:dashed 1px black;
                }
                &.line {
                    border-top:solid 1px black;
                    width:10px;
                    height:1px;
                }  
                    
            }
            
            &.tour,  &.atp  {
                width:50%;
                .bullet {border-color:$tourrank-color}
            }
            &.pr {
                width:50%;
                .bullet {border-color:$powerrank-color}
            }
            &.won {
                width:50%;
                .bullet.dot {background:$powerrank-won}
            }
            &.loss {
                width:50%;
                .bullet.dot {background:$powerrank-loss}
            }
        }
    }
}

.powerrank-movement {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    @include gotham(medium);
    position: relative;
    .rank {font-size: px2rem(14);color:$wim-purple}
    .movement {
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
        font-size: px2rem(12);
        align-items: center;
        &.up {
            color: $powerrank-movement-up;
        }
        &.down {
            color: $powerrank-movement-down;
        }
        i {margin-left:8px; margin-right:2px; font-size:px2rem(6); line-height: 51px;}
        .movement-value {}
    }
}

.leaderboard-table {
    display:flex;
    flex-direction: column;
    background-color: #ffffff;
    .leaderboard-row, .leaderboard-header, .leaderboard-subheader {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        font-size:px2rem(12);
        @include gotham-book;
        height: 44px;
        border-bottom:solid 1px $powerrank-table-border;


        .leaderboard-cell {
            text-align: left;
            display:flex;
            align-items: center;
            .open {
                width:18px;
                height:18px;
                border:solid 1px #176B3D;
                position: relative;
                color: $wim-green;
                cursor: pointer;
                &::before {
                    position: absolute;
                    content: '\2501';
                    left: 0px;
                    right: 0px;
                    width: 16px;
                    height: 16px;
                    line-height: 18px;
                    font-size: 7px;
                    text-align: center;
                }
            }
            .close {
                width:18px;
                height:18px;
                border:solid 1px $wim-green;
                position: relative;
                color: $wim-green;
                cursor: pointer;
                &::before {
                    position: absolute;
                    content: '\254B';
                    left: 0px;
                    right: 0px;
                    width: 16px;
                    height: 16px;
                    line-height: 18px;
                    font-size: 7px;
                    text-align: center;
                }
            }
            &.expand {width:100%; max-width:32px; padding-left:4px;}
            &.powerrank {
                width:100%; 
                max-width:45px;
                
                .powerrank-movement {
                    .movement {
                        .movement-value {
                            transform: translateX(-3px);
                        }
                    }
                }
            }
            &.atprank{width:100%; font-size: px2rem(14); max-width:60px;}
            &.playername{
                width:100%;
                overflow: hidden;
                color:$wim-green;
                img {
                    margin-right:5px;
                    width:17px;
                }
                .shortname {
                    display:block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .longname {
                    display:none;
                }
            }
            &.country{
                display:none;
                width:100%;
                img {margin-right:9px;}
            }
            &.aidraw {
                width:100%; 
                max-width: 75px;
                text-transform: capitalize;
                font-size: px2rem(11);
                font-weight: bold;

                &:not(.no-bullet) span {
                    &:before {
                        content: "\2022";
                    }
                }
            }
            &.star{
                width:100%; 
                max-width:29px; 
                padding-right:0px;
                a {cursor: pointer;}
            }

            @include breakpoint(tablet) {
                font-size: px2rem(16);
                &.expand {max-width:40px}
                &.powerrank {
                    max-width:148px;
                    .powerrank-movement {
                        .rank {
                            font-size: px2rem(16);
                            padding-right: 10px;
                        }
                        .movement {
                         i {font-size:px2rem(9);}
                        }
                    }
                }
                &.atprank {
                    font-size:px2rem(16);
                    max-width:128px;
                }
                &.playername {
                    .shortname {display:none;}
                    .longname {
                        @include gotham(medium);
                        font-size: px2rem(16);
                        display:block;
                    }
                }
                &.aidraw {
                    font-size:px2rem(16);
                    max-width: 150px;
                }
            }
            @include breakpoint(desktop) {
                font-size: px2rem(18);
                &.country {display:block; font-size: px2rem(16);}
                &.playername {
                    img {display:none}
                }
                &.atprank {font-size: px2rem(18);}
                &.aidraw {
                    max-width: 120px;
                }
            }

            @include breakpoint(desktopSidePanel) {
                &.expand { padding-left:8px;}
                &.aidraw {
                    max-width: 180px;
                }
                &.star{
                    padding-right:8px;
                }
            }
            
            @include breakpoint(largeDesktop) {
                &.aidraw {
                    max-width: 260px;
                }
            }

            &.difficult, .difficult {
                @extend .difficult-color;
            }

            &.favourable, .favourable {
                @extend .favourable-color;
            }

            &.neutral, .neutral {
                @extend .neutral-color;
            }
        }
    }
    .leaderboard-header {
        background-color: $powerrank-purple;
        color:$white;
        @include gotham(medium);
        font-size: px2rem(9);
        height:35px;
        text-transform: uppercase;
        align-items: center;
        
        .leaderboard-cell {
            line-height:13px;
            &.powerrank {padding-right:24px; cursor: pointer;}
            &.atprank {padding-right:16px; font-size: px2rem(9); cursor: pointer;}
            &.playername {
                padding-right:8px;
                color:$white;
                line-height:26px;
                cursor: pointer;
            }
            &.country {cursor: pointer;}
            &.aidraw {
                text-transform: uppercase;
                font-weight: normal;
                font-size: px2rem(9);
                cursor: pointer;
                &:not(.no-bullet) span,  span {
                    &:before {
                        content: unset;
                    }
                }
            }
            &.sort {
                span {border-bottom:solid 1px #FFFFFF;}
            }
        }
        @include breakpoint(tablet) {
            .leaderboard-cell{
                font-size:px2rem(12);
                &.atprank{font-size:12px}
            }
        }
    }
    .leaderboard-subheader {
        background-color: #f2f2f2;
        color:$wim-black;
        @include gotham(medium);
        font-size:px2rem(14);
        line-height: 44px;
        padding-left:8px;

        @include breakpoint(tablet) {
            font-size:px2rem(16);
            padding-left:42px;
        }
    }
    .leaderboard-row {
        align-items: center;
        .leaderboard-cell {
            &.atprank {
                justify-content: flex-start;
                padding-left:20px;
            }
        }
    }
    .leaderboard-data {
        transition: max-height 0.3s;
        overflow: hidden;
        max-height: 0;
        padding: 0px 10px;
        &.expand {
            max-height: 1000px;
            border-bottom:solid 1px $powerrank-table-border;
        }
        .error-indicator {
            .page .wrapper .content-main & {
                position: relative;
                color: $wim-black;
                text-align: center;
                @include gotham();
                height:30px;
                line-height: 30px;
            }
        }
    }
    .favorite {
        position: relative;
        //margin-right: 20px;
        
        &:after {
            //position: absolute;
            left: 0;
            content: "\2606";
            font-size: 18px;
        }
        &.favorited {
            &:after{
                font-size: 18px;
                content: "\2605";
            }
        }
    }

    .nextmatch-container {
        width:100%;
        //height:66px;
        color:$wim-purple;
        text-align: center;
        line-height: 20px;
        .player-match-status {
            @include gotham(medium);
            font-size:px2rem(14);
        }
        .player-oponent {
            font-size:px2rem(12);
        }

        .generic-button_wrapper>.generic-button, a>.generic-button {
            font-size:px2rem(11);
        }
    }
}

.graph-title {
    .determining-factors-container &, .rank-over-time-container & {
        @include gotham(medium);
        font-size: px2rem(14);
        color:$wim-black;
        margin-bottom:12px;
        text-transform: uppercase;
    }
}

.player-img-container {
    .player-image {width:100%}
    .powerrank-section & {
        width:80px;
        height:80px;
    }
    .leaderboard-table & {
        width:20%;
        //height:210px;
    }
}

.powerrank-container {
    display:flex;
    @include gotham(medium);
    color:$powerrank-purple;
    align-items:center;
    .value {font-size: px2rem(32);}
    .label {font-size:px2rem(13); text-transform: uppercase; white-space: nowrap;}
}

.powerrank-data-container {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding:15px 0px;
    .player-image-wrapper {
        width:80px;
        height:80px;
        .player-img-container {
            width:100%;
        }
        .nextmatch-container {
            display:none;
        }
    }

    .powerrank-container {
        padding-left: 16px;
        display: flex;
        align-items: center;
        .label {
            // width:100px;
            margin-left:16px;
        }
    }

    .determining-factors-container {
        margin-top:13px;
        width:100%;
        border-bottom:solid 1px $powerrank-divider;
        border-top:solid 1px $powerrank-divider;
        padding-top: 20px;

        .bar-graph-wrapper {
            .bar-graph-title {
                font-size: px2rem(12);
                margin-bottom:0px;
            }
            .bar-graph-container {
                height:8px;
                padding:1px;
                &.double {
                    &::before,&::after {
                        top:-6px;
                        font-size:px2rem(10);
                        text-transform: capitalize;
                    }
                    .bar-graph-divider {
                        height:14px;
                        bottom:-3px;
                    }
                }
            }
            .bar-graph-footer {
                font-size: px2rem(10);
            }
        }

        .powerrank-container {display:none;}
    }

    .rank-over-time-container {
        width:100%;
        margin-top:13px;
        padding-bottom:16px;
        border-bottom:solid 1px $powerrank-divider;
        .ct-chart, .chart-2 {
            height:225px;
        }
    }

    .powerrank-data-content {
        display: flex;
        height: auto;

        .draw-analysis-wrapper {
            z-index: unset;
            margin: auto;

            &.mobile-show {
                margin-top: 10px;
            }
        }
    }

    @include breakpoint(tablet) {
        padding-top:16px;
        padding-bottom:16px;
        justify-content: space-between;

        .powerrank-data-content {
            border-bottom: solid 1px $powerrank-divider;
            display: flex;
            padding-bottom: 15px;
            width: 100%;

            .powerrank-container {
                .label {
                    width:auto; 
                    font-size:px2rem(14)
                }
            }
        }

        .powerrank-graph-container {
            display: flex;
            width: 100%;
        }

        .player-image-wrapper {
            width: 175px; 
            height: auto;
            margin:0px 47px;

            .player-img-container {
                width:100%;
                height:auto;
                margin:0px auto;
                text-align: center;

                img {
                    width: 120px;
                }
            }
            .nextmatch-container {
                display: block;
                margin-top:15px;

                .slamtracker-stats.white .content {
                    /** because the original slamtraker-stats has !important,
                    *   need to put !important to override 
                    */
                    font-size: 11px !important;
                }
            }
        }
        
        .determining-factors-container {
            margin-top:0px;
            padding: 27px 24px;
            width: calc(100% - 179px);
            border-bottom: none;
            border-top: none;

            .bar-graph-wrapper {
                .bar-graph-container {
                    height:16px;
                    padding:2px;
                    &.double {
                        &::before,&::after {
                            top:-2px;
                            font-size:px2rem(12);
                            text-transform: uppercase;
                        }
                    }
                }
                .bar-graph-footer {
                    font-size:px2rem(12);
                }
            }  
        }
        > .powerrank-container {
            display:none;

        }
        .rank-over-time-container {
            margin-top: 0;
            padding-top: 27px;
            // border-top:solid 1px $powerrank-table-border;
            border-bottom:none;
            // + .nextmatch-container {
            //     display:none;
            // }
            .ct-chart, .chart2 {
                height:200px;
            }
            .legend-container {
                flex-wrap: nowrap;
                justify-content: space-evenly;
                .legend {
                    // &.atp, &.pr, &.lmp {width:auto}
                    &.tour, &.pr, &.atp, &.won, &.loss {width:auto}

                    &.pr, &.atp {order:1}
                    &.tour {order:2}
                    &.won {order:3}
                    &.loss {order:4}

                    .bullet {
                        &.dashed, &.line {
                            width:18px;
                        }
                    }
                }
            }
        }
        .leaderboard-table {
            .nextmatch-container {
                display:none;
            }
        }
        .nextmatch-container.mobile {
            display: none;
        }
    }

    @include breakpoint(smallDesktop) {
        //border-bottom: solid 1px #ECECEC;
        margin-bottom: 20px;
        padding-bottom: 0px;
        justify-content: space-around;

        .player-image-wrapper {
            width: 20%;
            height:auto;
            max-width: 240px;
            display: flex;
            flex-direction: column;
            align-content: center;
            padding: 0px;

            .player-img-container {
                width:100%;
                padding:0px 15px;
                margin:0px;

                img {
                    width: 100%;
                }
            }
        }

        .determining-factors-container {
            width:35%;
            // max-width: calc(100% - 240px);
            border-bottom: none;

            .bar-graph-wrapper {
                margin-bottom: 12px;
                .bar-graph-title {
                    font-size: px2rem(14);
                    margin-bottom:6px;
                }
                .bar-graph-container {
                    height:24px;
                    padding:3px;

                    &.double {
                        &::before, &::after {
                            margin-top: 2px;
                        }
                        .bar-graph-divider {
                            height: 27px;
                        }
                    }
                }
                .bar-graph-footer {
                    font-size: px2rem(12);
                }
            }

            + .nextmatch-container {
                display:none;
            }
        }

        .rank-over-time-container {
            width:65%;
            padding: 0 25px;
            margin-top: 27px;
            border-left: solid 1px #ECECEC;
            border-top:none;

            // + .nextmatch-container {
            //     display:block;
            //     position: relative;
            //     top: -70px;
            //     width:20%;
            //     height:76px;
            // }

            .ct-chart, .chart2 {
                height:240px;
            }

            
        }
    }

}

.compressed {
    .leaderboard-table .leaderboard-header .leaderboard-cell {
        font-size: px2rem(9);
    }
}


/** prevents www header renders before webview */
.webview #powerindex .header-global {
    display: none;
}