.contents-in-header-wrapper {
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 100%;
    color: $white;

    &::before{
        content: '';
        height: 180%;
        width: 100%;
        position: absolute;
        bottom: 0px;
    }

    .contents-in-header {
        &.hide {
            opacity: 0;
            transition: opacity .5s linear;

        }
        &.show {
            opacity: 1;
            transition: opacity .5s linear;
        }
        margin: 0 auto;
        width: 90%;
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: flex-end; //pointer-events: none;
        opacity: 1;
        z-index: 2;
        h2,
        h3,
        h4 {
            margin: 0px;
        }
        &-date {
            flex: 0 1 auto;
            align-self: center;
            margin: 0 0 4px 0;
            text-align: center;
            letter-spacing: 1px;
            color: $white;
            h3 {
                text-transform: uppercase;
                font-size: px2rem(12);

                @include breakpoint(tablet) {
                    font-size: px2rem(18);
                }
            }
        }
        &-title {
            flex: 0 1 auto;
            align-self: center;
            margin: 0;
            text-align: center;
            color: $white;

            h1{ 
                margin: 0;
                font-size: px2rem(24);

                @include breakpoint(tablet) {
                    font-size: px2rem(28);
                }

                .wim-icon-info {
                    font-size: 70%;
                }
            }

            a,
            a:hover,
            a:active,
            a:visited {
                color: $white;
                text-decoration: none;
            }

            i.wim-icon-info {
                padding-left: 10px;
            }
        }

        hr.short-line {
            border: none;
            border-top: 3px solid $white;
            color: $white;
            margin: 15px auto;
            overflow: visible;
            text-align: center;
            height: 2px;
            width: 30px;
        }

        h4.sub-title {
            text-align: center;
            margin: 0 0 20px 0;
            font-size: px2rem(16);
        }
    }

    /* overrides for IBM Power Index header */
    &.power-rankings {
        position:relative;

        &::before {
            background-image:none;
        }
        .contents-in-header {
            width:100%;
            &-title {
                h1 {
                    text-transform: uppercase;
                    font-size:px2rem(20)
                }
            }
            &-date {
                h3 {
                    font-size:px2rem(12)
                }
            }
            h4.sub-title {
                    @include gotham_book();
                    font-size: px2rem(12);
            }
        }

        hr.short-line {
            margin: 10px auto;
        }

        @include breakpoint(tablet) {
            .contents-in-header {
                &-title {
                    h1 {
                        font-size: px2rem(28);
                    }
                }
                h4.sub-title {
                    font-size: px2rem(16);
                    padding: 0px 12.5%;
                }
            }
        }
    }
}

/* override for Webview - Match Insights */
.match-insights {
    &.webview {
        .contents-in-header-wrapper {
            position: relative;
            
            &::before {
                background-image:none;
            }
        }
    }
}