.ticketDebugWebViewBackground {
    background: url(/assets/images/tickets/test_h_BlueAndWhiteFlowers_No1Court.png);
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.ticketsWebview-message {
    text-align: center;
    font-size: 28px;
    height: 100%;
    width: 100%;
    display: inline-flex;
    vertical-align: middle;
    color: white;

	.error-code {
		font-size: 14px;
		white-space: nowrap;
		position: absolute;
		bottom: 0px;
		margin: 10px;
	}
}

.ticketsWebview-err-msg {
    margin: auto;
    line-height: 100%;

	a, a:visited {
		color: rgb(37, 192, 121);
	}
}

.ticketsWebview-message-txt {
    padding: 0px 10px 0px 10px; // top right bottom left
    margin: auto;
    @include breakpoint(tablet) {
        width: 90%;
    }
}

.ticketsWebview-message-btn {
    color: #25c079;
    border: none;
    background: none;
    font-size: inherit;
    cursor: pointer;
    padding: 0px;
}

.webview-loading-txt {
    color: white;
}

.ticket-webview-stub-parent-container {
    @media (width:320px) {
        width: 320px;
        padding: 8px;
    }
    padding: 0px;
    width: 366px;
    margin: auto;
    position: relative;
    align-items: center;
    text-align: center;
    margin-bottom: 8px;
    @include breakpoint(tablet, min) {
        margin-bottom: 0px;
        width: 528px;
    }
}

.ticket-stub-actions-component-container {
    @media (width:353px) {
        padding-right: 1px;
    }
    margin-left: 0px;
    display: flex;
    justify-content: center;
    margin-top: -2px;
    @include breakpoint(tablet, min) {
        display: flex;
        z-index: 0;
        margin-left: 2px;
        margin-top: 16px;
    }
}

.ticket-stub-actions-component-container-modal-open {
    height: 32px;
    position: relative;
    width: 1px;
}

.ticket-stub-render-container {
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
}

.ticket-view-container {
    width: 100vw;
}

.ticket-stub-container {
    @media (width:320px) {
        height:104px;
    }
    @media (width:353px) {
        height:108px;
    }
    height: 112px;
    margin-bottom: 8px;
    display: inline-flex;
    margin-bottom: 4px;
    justify-content: center;
    position: relative;

    @include breakpoint(tablet) {
        height: 154px;
        margin-bottom: 12px;
    }
}

.ticket-stub-container-disabled {
    opacity: .9;
    filter: grayscale(100%);
}

.ticket-stub-container-small {
    @media (width:320px) {
       width: 316px;
       height: 104px;
    }
    width: 336px;
    height: 111px;
    display: inline-flex;
    border-radius: 8px;
    margin-bottom: 0px;
    justify-content: center;
    position: relative;
    z-index: 1;
    @include breakpoint(largeMobile) {
        width: 350px;
    }
    @include breakpoint(tablet) {
        width:100%;
    }
}

.ticket-stub-shape-container {
    position: relative;
    z-index: 0;
    width: 312px;
    height: 96px;
    
    @include breakpoint(tablet) {
        height: 160px;
    }
}

.ticket-stub-shape-container-small {
    @media (width:320px) {
        background-image: url(/assets/images/tickets/ticketStubWebviewSmall.svg);
        background-size: 318px !important;
    }
    width: 312px;
    height: 98px;
    position: relative;
    border-radius: 8px;
    z-index: 0;
    background-image: url(/assets/images/tickets/ticketStubBorder.svg);
    background-repeat: no-repeat;
    background-position: center;

    @include breakpoint(tablet) {
        background-size: 360px;
    }
    @include breakpoint(desktop) {
        background-size: auto;
    }
}

.ticket-stub-shape-img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 0;
}

.ticket-stub-shape-img-border-small {
    width: 100%;
    border-radius: 8px;
    background-size: 336px;
    height: 104px;
    @include breakpoint(largeMobile) {
        background-size: 356px;
        height: 110px;
        margin-left: -4px;
    }
    @include breakpoint(extraLargeMobile) {
        margin-left: 0px;
        background-size: auto;
    }
    
    position: absolute;
    left: 0px;
    overflow: hidden;
    @include breakpoint(tablet) {
        position: relative;
        height: 111px;
        width: 100%;
    }
    @include breakpoint(desktop) {
        height: 100%;
        width: 855px;
    }
    top: 0px;
    z-index: 0;
}

.ticket-stub-shape-img-border {
    @media (width:320px) {
        border-radius: 10px;
        height:108px;
    }
    width: 100%;
    height: 110px;
    position: absolute;
    left: 0px;
    overflow: hidden;
    border-radius: 8px;;
    @include breakpoint(tablet) {
        position: relative;
    }
    top: 0px;
    z-index: 0;
}

.webview-ticket-stub-shape-img-border {
    @media (width:320px) {
        border-radius: 10px;
        height:104px;
        width: 320px;
    }
    width: 366px;
    height: 112px;
    position: absolute;
    overflow: hidden;
    border-radius: 8px;;
    @include breakpoint(tablet) {
        height: 160px;
        width: 516px;
    }
    top: 0px;
    z-index: 0;
}

.ticket-stub-image-container {
    @media (width:320px) {
        width: 78px;
        margin-left: 2px;
    }
    margin-left: 0px;

    width: 84px;

    position: relative;
    z-index: 1;
    float: left;
    height: 100%;

    @include breakpoint(tablet) {
        width: 120px;
    }
}

.ticket-stub-image-container-small {
    width: 82px;

    position: relative;
    z-index: 1;
    float: left;
    margin-right: 8px;
    margin-left: -1px;
}

.ticket-stub-image {
    max-width: 100%;
    margin-left: 0px;

    @media (width:320px) {
        margin-left: 5px;
    }

    @include breakpoint(tablet) {
        margin-left: 0px;
    }

    border-radius: 8px 0px 0px 8px; // top-left top-right bottom-right bottom-left. 
}

.ticket-stub-details-container {
    width: 60%;
    border-right: 3px dashed #CDCDCD;
    padding: 4px 0px 0px 6px;
    height: 95%;
    margin-top: 2px;

    z-index: 1;

    position: relative;
    float: left;
    text-align: left;

    @include breakpoint(tablet) {
        padding: 10px 0px 0px 12px;
        height: 100%;
        margin-top: 0px;
        width: 63%;
    }
}

.ticket-stub-details-container-small {
    @media (width:320px) {
        width: 280px;
    }
    width: 296px;
    @include breakpoint(largeMobile) {
        width: 308px;
    }
    border-right: 3px dashed #CDCDCD;
    padding: 8px 0px 0px 6px;
    height: 100%;
    text-align: left;

    position: relative;
    z-index: 1;
}

.ticket-stub-details-title {
    font-size: 16px;
    text-transform: uppercase;
    color: #333333;
    letter-spacing: 0.57px;
    margin-bottom: 4px;
    font-weight: bold;
    @include breakpoint(tablet) {
        font-size: 18px;
        letter-spacing: 1.21px;
    }
}

.ticket-stub-details-title-small {
    @media (width:320px) {
        font-size: 14px;
    }
    font-size: 16px;
    margin-bottom: 4px;
    text-transform: uppercase;
    color: #333333;
    letter-spacing: 1.21px;
}

.ticket-stub-inner-details {
    padding-right: 4px;
}

.ticket-stub-details-type {
    font-size: 12px;
    color: #007138;
    text-transform: capitalize;
    margin-bottom: 4px;
    display: inline;
    @include breakpoint(tablet) {
        font-size: 14px;
        height: 16px;
    }
}

.ticket-stub-details-type-small {
    font-size: 12px;
    color: #007138;
    text-transform: capitalize;
}

.ticket-stub-details-text-small {
    font-size: 12px;
    padding-top: 4px;
    text-transform: capitalize;
    color: #333333;
    word-break: break-word;
    @include breakpoint(tablet) {
        margin-bottom: 8px;
    }
}

.ticket-stub-details-text {
    text-transform: capitalize;
    font-size: 12px;
    line-height: 14px;
    color: #333333;
    letter-spacing: 0;
    word-break: break-word;
    margin-bottom: 4px;
    @include breakpoint(tablet) {
        padding-top: 2px;
        font-size: 14px;
        height: 16px;
        line-height: 16px;
    }
}

.ticket-stub-right-side-parent-cntr {
    @media (width:320px) {
        width: 40px;
    }
    width: 52px;
    display: inline-block;
    height: 100%;
    margin-left: -8px;
    @include breakpoint(tablet) {
        width: 66px;
        margin-left: auto;
    }
}
.ticket-stub-right-side-container-outer {
    width: 50%;
    position: relative;
    margin: 0px;
    height: 100%;
    @include breakpoint(tablet) {
        width: 40%;
    }
  }

  .ticket-stub-right-side-container-inner {
    color: #878787;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 14px;
    color: #007138;
    font-weight: 900;
    @include gotham-book();
    @include breakpoint(tablet) {
        font-size: 18px;
        letter-spacing: 0.5px;
    }

  }

  .ticket-stub-right-side-container-rotate {
    -moz-transform: translateX(-40%) translateY(-50%) rotate(-90deg);
    -webkit-transform: translateX(-40%) translateY(-50%) rotate(-90deg);
    transform:  translateX(-40%) translateY(-50%) rotate(-90deg);
}

.ticket-view-type-count-title {
    text-transform: uppercase;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    width: 100%;
    margin-bottom: 16px;
    margin-top: 8px;

    background: rgba(255,255,255,0.30);
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: 0.5px;
    text-align: center;
}

.ticket-stub-actions-container {
    @media(width:320px) {
        margin-top: -6px;
        padding: 0px;
    }
    background-color: white;
    height: auto;
    position: relative; 
    padding: 2px 4px 6px 4px; // top right bottom left
    margin-top: -9px;
    z-index: 1;
    border-radius: 0px 0px 6px 6px; // top-left top-right bottom-right bottom-left. 
    margin-bottom: 8px;

    @include breakpoint(tablet, min) {        
        z-index: 0;
        margin-left: 4px;
        margin-right: 5px;
        padding-top: 16px;
        margin-top: -30px;
        margin-bottom: 12px;
    }

    @include breakpoint(desktop) {
        margin-right: 6px;
        padding-top: 18px;
        margin-top: -32px;
    }
}

.ticket-stub-actions-btns-cntr {
    justify-content: center;
    align-items: center;
    @media (width: 320px) {
        height: 130px;
    }
    height: auto;
    @include breakpoint(tablet) {
        display: flex;
        margin-top: 16px;
    }
}

.ticket-stub-actions-value {
    @media(width: 320px) {
        width: 98%;
    }
    height: 42px;
    width: 100%;
    color: $wim-green;
    text-align: center;
    line-height: 32px;
    margin-top: 4px;
    border: 1px solid $wim-green;
    border-radius: 8px;
    text-transform: uppercase;
    background: white;
    cursor: pointer;
    font-size: 16px;
    @include gotham-book();
    font-weight: bolder;
    @include breakpoint(tablet) { 
        width: 230px;
        margin: 8px;
    }
    @include breakpoint(desktop) { 
        width: 200px;
    }
}

.webview-ticket-stub-actions-value-green {
    @media(width: 320px) {
        width: 98%;
    }
    height: 36px;
    width: 100%;
    background: $wim-green;
    text-align: center;
    line-height: 32px;
    margin-top: 4px;
    border: 1px solid $wim-green;
    border-radius: 8px;
    text-transform: uppercase;
    color: white;
    cursor: pointer;
    font-size: 16px;
    @include gotham-book();
    font-weight: bolder;
}

.webview-ticket-stub-actions-value:disabled, .webview-ticket-stub-actions-value-green:disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: .6;
    color: lightslategrey;
    border: 1px solid lightslategrey;
    cursor: not-allowed;
    background: white;
    // background: rgba(229, 229, 229,0.6);
}

.webview-ticket-stub-actions-value {
    @media(width: 320px) {
        width: 98%;
    }
    height: 36px;
    width: 100%;
    color: $wim-green;
    text-align: center;
    line-height: 32px;
    margin-top: 4px;
    border: 1px solid $wim-green;
    border-radius: 8px;
    text-transform: uppercase;
    background: white;
    cursor: pointer;
    font-size: 16px;
    @include gotham-book();
    font-weight: bolder;
}

.webview-ticket-stub-tablet-width {
    @include breakpoint(tablet, min, true) {
        width: 200px;
        margin: 8px;
    }
}

.ticket-stub-actions-fileno {
    font-size: 12px;
    text-align: left;
    padding: 4px 0px 0px 2px; // top right bottom left
}

.ticket-stub-actions-value:disabled {
    color: dimgrey;
    background: rgba(229, 229, 229,0.6);
    cursor: not-allowed;
}

.ticketview-ticketstub-details-id {
    display: none;
    z-index: 0;

    
    @include breakpoint(desktopSidePanel) {
        display: inline;
        color: $white;
        font-size: 12px;
        background-color: $green2;
        padding: 2px 5px;
        line-height: 14px;
        position: absolute;
        right:0px;
        width: 25%;
        order: 10;

        div {
            padding-right: 30px;
        }
    }
    
}