.help-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 950;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .help-modal-inner {
        position: static;
    }
    .modal-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        max-width: 460px;
        min-height: 316px;
        background-color: white;
        position: relative;
        filter: drop-shadow(0px 2px 4px rgba(0,0,0, 0.5));

        @include breakpoint(tablet){
            width: 461px;
        }
        .modal-close{
            font-size: 22px;
            color: rgba(0, 106, 51, 1);
            position: absolute;
            right: 17px;
            top: 17px;
            cursor: pointer;
        }
        .modal-header{
            @include gotham(medium);
            font-size: 20px;
            color: #4F2683;
            letter-spacing: 1px;
            text-align: center;
            line-height: 22px;
            margin-top: 50px;
            padding: 0 55px;

            &.narrow {
                width: 83%;
                
                @include breakpoint(tablet){
                    width: 68%;
                }
            }
        }
        .spacerLine{
            width: 89px;
            border-top: 2px solid #4F2683;
            margin: 15px 0 20px 0;
        }
        .modal-body{
            font-size: 14px;
            color: #333333;
            letter-spacing: 0;
            text-align: center;
            line-height: 18px;         
            padding: 0 33px;   
            padding-bottom: 30px;
            @include breakpoint(tablet, max){
                padding: 0 33px 80px 33px;
            }
        }
    }
}