.remaining-bookings {
	width: 100%;
	.modal-overlay {
		overflow-y: scroll;
	}
	&__header {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		&--title {
			margin: 10px auto;
			color: purple;
			font-weight: bold;
		}

		&--purple-line {
			width: 24px;
			border: 1px solid purple;
			height: 2px;
			background-color: purple;
		}

		&--description {
			margin-top: 16px;
			text-align: center;
			font-size: 14px;
			margin-bottom: 16px;
		}
	}

	&__booking-list {
		align-self: start;
		border: 1px solid rgb(225, 225, 225);
		&-item {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			padding: 10px;
			position: relative;

			@media only screen and (max-width: 768px) {
				grid-template-columns: 1fr;
				padding-bottom: 25px;
				row-gap: 7px;
				padding-left: 15px;
				padding-top: 10px;
			}

			p {
				font-size: 13px;
			}
			&:nth-child(even) {
				background-color: rgb(241, 241, 241);
			}
		}
	}
	&__booking-list-item {
		column-gap: 30px;
		p {
			margin-top: auto;
			margin-bottom: auto;
		}
		button {
			border: none;
			font-family: inherit;
			background: inherit;
			padding: 0px;
			font-size: 13px;
		}
		&__actionable_item {
			cursor: pointer;
			color: #00703C;
			&:hover {
				text-decoration: underline;
			}
		}
		&:hover {
			background-color: rgba(241, 241, 241, 0.4);
		}
	}
	
	&__circle-wrapper {
		display: flex;
		align-items: center;
		text-transform: capitalize;
	}

	&__booking-circle {
		height: 15px;
		width: 15px;
		border-radius: 10px;
		margin-right: 10px;
		margin-left: 20px;
		@media only screen and (max-width: 768px) {
			position: absolute;
			top: 10px;
			right: 0px;
		}
	}

	&__booking-title {
		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}
	}
}

.green {
	color: $green;
}
