@mixin icon($ipkg) {
    font-family       : $ipkg !important;
    font-smoothing    : antialiased;
    font-style        : normal;
    font-weight       : normal;
    font-variant      : normal;
    text-transform    : none;
    speak             : none;
    line-height       : 1;
}
