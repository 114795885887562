.homepage-upcoming-match{
    position:relative;
    // height:300px;
    display: flex;
    justify-content: center;
    width:100%;
    overflow:hidden;
    padding:0;
    background: url("/assets/images/misc/Timeline_Upcoming_Background.png");
    background-size: cover;
    background-position: right;
    .upcoming-match {
        &-player {
            top: 145px;
        }
        .team-one {
            // width: 50%;
            // padding-right: 10%;
            .player-images {
                justify-content: flex-end;
            }
            .player-info {
                .name {
                    text-align: right;
                }
                white-space: normal;
                justify-content: flex-end;
            }
        }
        .team-two {
            // width: 50%;
            // padding-left: 10%;
            .player-images {
                justify-content: flex-start;
            }
            .player-info {
                .name {
                    text-align: left;
                }
                white-space: normal;
                justify-content: flex-start;
            }
        }
    }

    &.pre-match-insight {
        .generic-button {
            // position: absolute;
            // bottom: 20px;
            // left: 50%;
            // transform: translateX(-50%);
            // @include breakpoint(tablet) {
            //     padding-left: 20px;
            //     padding-right: 20px;
            // }
            margin-top: 10px;
        }
        @include breakpoint(tablet, max){
            .upcoming-match-player{
                top: 145px;
            }
        }
        &.singles .player-image {
            height: 70px !important;
            width: 70px !important;
        }
        .upcoming-match-info {
            @include breakpoint(tablet) {
                line-height: 16px !important;
            }
        }
    }
    @include breakpoint(tablet) {
        .upcoming-match {
            .team-one {
                width: 200px;
                max-width: 200px;                
                // width: auto;
                padding-right: 0%;
                .player-images {
                    justify-content: flex-end;
                }
                .player-info {
                    white-space: pre;
                    justify-content: flex-end;
                }
            }
            .team-two {
                width: 200px;
                max-width: 200px;
                // width: auto;
                padding-left: 0%;
                .player-images {
                    justify-content: flex-start;
                }
                .player-info {
                    white-space: pre;
                    justify-content: flex-start;
                }
            }
        }
    }

    &.singles {
        .upcoming-match {
            .player-images {
                display: flex;
                .player-image {
                    width: 120px;
                    height: 120px;
                }
            }
        }
    }
    &.doubles{
        .upcoming-match{
            &-player{

                @include breakpoint(tablet,max){
                    top:125px;
                }

                .player-images{
                    height:60px;
                    display:flex;
                    // width: 145px; 
                    .player-image{
                        height:60px;
                        width: 60px;
                        @include breakpoint(tablet,max){
                            // height:45px;
                        }
                    }

                    .player-one{
                        margin-right:5px;
                        // flex:1 0 auto;
                    }
                    
                    .player-two{
                        display:inline;
                        // flex:1 0 auto;
                    }
                }
                .player-info{
                    margin-top: 5px;
                    // .name{ text-align: left;}
                    &.player-two{
                        display:flex;
                    }
                }
            }
        }
    }

    @include breakpoint(tablet,max){
        min-height:270px;
        // height:auto;
    }

    .background-image{
        position:absolute;
        top:0;
        left:50%;
        transform:translateX(-50%);
        width:100%;
        height:100%;
        max-height:100%;
        max-width:100%;
        display: none;
        @include breakpoint(tablet,max){
            min-height:270px;
            width:unset;
            max-width:unset;
            min-width:100%;
            height:auto;
        }
    }
    .upcoming-match-player{ 
        @include breakpoint(tablet, max){
            display: inline-block;
            width: 39%;
            &.team-one{ left: 10px; }
            &.team-two{ right: 10px;}
        }
        @media only screen and (min-width: 768px) and (max-width: 1023px){
            max-width: 33%;
        }
    }
    .upcoming-match{
        // position:absolute;
        // top:50%;
        // left:50%;
        // transform:translate(-50%,-50%);
        display:flex;
        height:100%;
        width:50%;
        min-width:700px;
        justify-content: center;        
        padding-bottom: 10px;
        z-index: 1;
        &-player{
            flex:0 0 auto;
            display:flex;
            flex-direction:column;
            margin:0 10px;
            align-self:center;

            .player-images{
                .player-image{
                    height:120px;
                    width:auto;
                    position:relative;
                    transform: translateX(-50%);
                    left: 50%;
                }
                .player-two{
                    display:none;
                }

            }

            .player-info{
                display:flex;
                font-size:14px;
                text-transform:uppercase;
                color:$white;
                text-align:center;

                .flag{
                    height:10px;
                    margin-right:5px;

                    img{
                        height:10px;
                        width:auto;
                        position:static;
                        min-height:0;
                        min-width:0;
                    }
                }

                .seed{
                    font-size:10px;
                    margin-left:5px;
                    align-self:center;
                }

                &.player-two{
                    display:none;
                }
            }
        }

        @include breakpoint(tablet,max){
            min-width:305px;
            width:100%;
            
            &-player{
                display:unset;
                position:absolute;
                top:115px;

                &.team-one{
                    left:10px;
                }

                &.team-two{
                    right:10px;
                }
                .player-images{
                    .player-image{
                        // height:100px;
                        min-height:0;
                        min-width:0;
                    }
                }

                .player-info{
                    font-size:10px;
                    text-align: left;
                }
            }
        }

        &-info{
            display:flex;
            flex-direction:column;
            color:$white;
            font-size:14px;
            text-align:center;
            flex:1 1 auto;
            line-height:28px;
            margin-top:30px;
            width: 280px;
            max-width: 280px;
            min-width: 280px;
            .time{
                text-transform:uppercase;
                font-size:12px;
                letter-spacing:1.5px;
            }

            .title{
                font-size:30px;
                text-transform:uppercase;
                // line-height:40px;
                line-height:35px;
                margin-bottom:10px;
                letter-spacing:3px;
            }

            .match-location{
                text-transform:uppercase;
            }

            .upcoming-match-logo{
                position:relative;
                margin-bottom:10px;

                i{
                    font-size:49px;
                }

                hr{
                    width:calc(50% - 29px);
                    position:absolute;
                    top:50%;
                    transform:translateY(-50%);
                    height:2px;
                    margin:0;
                    z-index:-1;

                    &.left-side{
                        left:0;
                    }

                    &.right-side{
                        right:0;
                    }
                }
            }

            @include breakpoint (tablet,max){
                font-size:12px;
                position:absolute;
                top:20px;
                left:50%;
                transform:translateX(-50%);
                line-height:16px;
                width:100%;
                margin:0;

                .time{
                    font-size:10px;
                }

                .title{
                    font-size:20px;
                    line-height:30px;
                    margin:0;
                }

                .upcoming-match-logo{
                    display:none;
                }
            }
        }
    }
}