/** player-profile-score-table styles **/
.player-score-table {
    .upset{
        color: $gray-650;
        right: 21px;
    }
    .greenStripe {
        height: 3px;
        margin-bottom: 20px;
    }
    .more-content {
        text-align: center;
    }
    @media only screen and (max-width: $column-break-1) {
        img.player-image {
            display: none;
        }
    }
}
.match-info {
    flex-basis: 90%;
    max-width: 930px;
    margin: 0 20px;
}