/** stripes styles **/

.purpleStripe {
	height: 11px;
	width: 100%;
	background-color: $wim-purple;

	&-thin {
		height: 1.75px;
		width: 95%;
		background-color: $wim-purple;
		margin: 0 auto;
	}

	&-short {
		width: 19px;
		height: 2px;
		border-style: none;
		background-color: $wim-purple;
		margin: 5px auto;
		&.left{
			margin: 5px 0;
		}
	}
}

.whiteStripe {
	height: 11px;
	width: 100%;
	background-color: $white;

	&-thin {
		height: 1.75px;
		width: 95%;
		background-color: $white;
		margin: 0 auto;
	}

	&-short {
		width: 19px;
		height: 2px;
		border-style: none;
		background-color: $white;
		margin: 5px auto;
		&.left{
			margin: 5px 0;
		}
	}
}

.greenstripe-container {
	&.service {
		margin-top: 10px;

		width: 100%;
		.greenStripe {
			position: absolute;
			left: 0px;
			right: 0px;
		}
	}
	&.margin {
		width: 90%;
		margin-left: auto;
    	margin-right: auto;
	}
	margin-bottom: 20px;

	.greenStripe {
		height: 2px ;
		width: 100%;
		background-color: $wim-green;
	}
}