.generic-checkbox {
	// @include checkbox();
	@include accessibleCheckbox();

	.checkbox-label {
		display: block ;
	}

	label::before {
		
	}

	.label-text {
		position: relative;
		text-align: left;

		&.required {

			&::before {
				content: "*";
				color: red;
				margin-right: 5px;
			}
		}
	}

	&.error {
		outline: 1px solid red;
		outline-offset: 5px;
	}

	.errorMsg {
		display: none;
	}
}

.retail-competition-checkbox-container {
	text-align: left;
	:first-child {
		margin-bottom: 16px;
	}
}

.hill-option-container {
	margin: 8px auto;
	padding: 8px;
    background: rgba(236, 236, 236, 1);
    height: 65px;
    display: grid;
	border-radius: 8px;
	border: 1px solid rgba(151, 151, 151, 1);
    vertical-align: middle;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;
    text-align: left;
	.checkbox-option {
		line-height: unset;
		margin-bottom: 0px;
		label::before, label::after {
			top: unset;
		}
		input + label {	
			display: grid;
			align-items: center;
		}
	}
}

.checkbox-option {
	position: relative;
	display: inline-block;
	margin-bottom: 6px;
	
	input + label {
		position: relative;
		padding-left: 33px;
		top: 0;
		// padding-left: 1.95em;
		cursor: pointer;
		text-align: left;
		line-height: 20px;
	}
	input:checked + label:after {
		opacity: 1;
	}
	input {
		display: none;
	}
	label {
		// width: 185px;
		text-align: right;
		font-family: unset;
			
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: -2px;
			width: 20px;
			height: 20px;
			border: 1px solid $wim-text-purple;
			background: #fff;
			border-radius: 0;
		}
		&:after {
			// content: '\2713\0020';
			content: '';
			position: absolute;
			top: 2px;
			left: 4px;
			width: 14px;
			height: 14px;
			background-color: $wim-text-purple;
			opacity: 0;
		}
		&:hover {
			&:before {
				box-shadow: inset 0 0 0 1px $wim-text-purple;
			}
		}

		.label-text {
			position: relative;
			text-align: left;
			font-size: 14px;
	
			&.required {
	
				&::before {
					content: "*";
					color: red;
					margin-right: 5px;
				}
			}
		}
		&.disabled {
			cursor: default;

			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: -2px;
				width: 20px;
				height: 20px;
				border: 1px solid $grey-c7;
				background: #EDEDED;
				border-radius: 0;
			}
			&:after {
				content: '';
				position: absolute;
				top: 2px;
				left: 4px;
				width: 14px;
				height: 14px;
				background-color: #EDEDED;
				opacity: 0;
			}
			&:hover {
				&:before {
					box-shadow: none;
				}
			}
		}
	}
	&.required {
		&::after {
			content: "*";
            @include gotham();
			color: $red;
			position: absolute;
			left: 26px;
		}
	}

	.error {
		display: block;
		font-size: 12px;
		color: #F60000;
		margin: -5px 0 0 32px;
		position: absolute;
		//white-space: nowrap;
		line-height: 12px;
    	padding-top: 3px;

		@include breakpoint(extraLargeMobile, max) {
            position: relative;
        }
	}
}
