.video-wrapper-archive {
	position: relative;
	@include breakpoint(desktop, max){
		width: calc(100% - 7px * 2) !important;
		.two-col{
			display: inline-block;
			vertical-align: top;
			width: calc(50% - 7px * 2);
			padding-top: 15px;
		}
	}
	@include breakpoint(tablet, max){
		.two-col{
			display: block;
			width: calc(100% - 7px * 2);
			padding-top: 0;
		}
	}
	&.load {
		width: 66%;
    	.video-wrapper {
			background-color: unset;
		}
	}
	.replay-type{
		@include breakpoint(desktop){
			display: none;
		}
		position: relative;
		text-align: center;
		margin-top: 15px;
		.title{
			display: inline;
			margin-right: 15px;
		}
	}
	.video-area {
		width: 66%;
		float: left;
	}
	.pointnav-area {
		width: 33%;
		height: 100%;
		float: right;
	}

	.video-wrapper {
		position: relative;
		padding-bottom: 56.25%;
		background-color: black;
		.video-thumb {
			width: unset;
			position: absolute;
			left: 0;
			right: 0;
			margin-left: auto;
			margin-right: auto;
		}
	}
	.video-bottom{
		display: flex;
		@include breakpoint(desktop){
			.three-col{
				width: 50%;
			}
		}
		@include breakpoint(desktop, max){
			flex-direction: column;
			width: 50%;
			display: inline-flex;		
			.three-col{
				width: 100%;
				padding: 7px 7px 7px 0;
			}
		}
		@include breakpoint(tablet, max){
			width: 100%;
			display: flex;		
			.three-col{
				width: 100%;
				padding: 0;
			}
		}
		.quote-carousel{
			@include breakpoint(tablet){
				display: flex;
				flex-direction: column;
			}
			.BrainhubCarousel__container{
				flex: 1;
				color: white;
				// height: 150px;
				position: relative;
				padding: 16px 16px 0 16px;
				@include breakpoint(tablet){
					margin-left: 5px;
					.BrainhubCarousel{
						height: calc(100% - 25px);
					}
				}
				.quote-wrapper{
					padding: 0 10px;
					text-align: center;
					line-height: 16px;
					white-space: initial;
					font-size: 11px;
					.credit{
						font-weight: bold;
					}
				}
				.BrainhubCarousel__dot{
					background: none;
					&::before{
						background: #D8D8D8;
					}
				}
				.BrainhubCarousel__dot--selected::before{
					background: #A1A0A0;
				}
			}
		}
	}

	.novideo-message {
		position: relative;
		padding-bottom: 56.25%;
		background-color: black;
		color: white;
		padding: 23% 0;
		text-align: center;
	}
}

.matchnav {
	height: 100px;
	overflow: scroll;
	margin-bottom: 10px;
	li {
		text-decoration: none;
		font-size: 11px;
	}
}

.match-archive {
	.match-header{
		position: relative;
		.replay-type{
			@include breakpoint(desktop, max){
				display: none;
			}
			position: absolute;
			@include breakpoint(desktop,max){
				right: 25px;
			}
			text-align: right;
			right: 44px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	.match-title-container{
		display: flex;
		flex: 1;
		@include breakpoint(desktop, max){
			padding: 0 10px;
			.spacer, .scoring-mode{
				display: none;
			}
		}
		
		> div{
			flex: 1;
		}
		.player1 .image{
			margin-right: 8px;
		}
		.player1{
			@include breakpoint(tablet, max){
				flex-direction: column;
			}
			.info{
				display: flex;
				flex-direction: column;
				@include breakpoint(tablet, max){
					flex-direction: row;
					align-items: center;
					padding-top: 10px;
					.flag{
						margin-right: 5px;
					}
				}
			}
		}
		.player2{
			justify-content: flex-end;
			@include breakpoint(tablet, max){
				flex-direction: column-reverse;
				align-items: flex-end;
				.name{
					display: flex;
					flex-direction: column;
					align-items: flex-end;
				}
			}
			.name{
				text-align: right;
			}
			.info{
				align-items: flex-end;
				display: flex;
				flex-direction: column;
				@include breakpoint(tablet, max){
					flex-direction: row-reverse;
					align-items: center;
					padding-top: 10px;
					.flag{
						margin-left: 5px;
					}
				}
			}
			.image{
				margin-left: 8px;
			}
		}
		.player1, .player2{
			display: flex;
			color: white;
			a{
				color: white;
			}
			.image{
				height: 50px;
				img{
					height: 100%;
					border-radius: 50%;
				}
			}
			.first-name, .last-name{
				text-transform: uppercase;
				font-size: 11px;
				@include gotham(bold, ssm);
				.seed{
					padding-left: 5px;
					@include gotham(normal);
				}
			}
		}
		.match-title{
			display: flex;
			flex-direction: column;
			align-items: center;
			color: white;
			@include breakpoint(desktop, max){
				max-width: 90px;
			}
			.match-year{
				display: flex;
				font-size: 20px;
				text-align: center;
				.share-popup{
					z-index: 100;
				}
			}
			.byline--share-button{
				font-size: 12px;
				margin-left: 5px;
				position: relative;
				.byline--share-wrapper{
					top: 25px;
					right: -20px;
				}
			}
			.match-heading{
				text-align: center;
				text-transform: uppercase;
				font-size: 11px;
				line-height: 13px;
				@include breakpoint(desktop, max){
					p{
						white-space: nowrap;
					}
				}
			}
		}
	}

	.match-year {
		@include gotham(normal);
		font-size: 24px;
		line-height: 1.2em;
		margin: 0;
		color: $white;

		@include breakpoint(tablet,max){
			@include gotham(normal);
			font-size:20px;
		}
	}

	.replay-type {
		text-transform: uppercase;
		font-size: 10px;
		color: $white;
		
		.title {
			@include gotham-book();
			letter-spacing: .75px;
		}

		button {
			@include gotham(normal);
			text-transform: uppercase;
			font-size: 10px;
			color: $white;

			padding: 4px 0;
			border: 0;
			background: transparent;
			cursor: pointer;
			pointer-events: auto;
			outline: none;

			&[data-type="active"] {
				//@include gotham(bold);
				@include gotham(bold, ssm);
				pointer-events: none;
			}	
		}

		.separator {
			padding: 0 5px;
			display: inline-block;
		}
	}

	.nav-wrapper {
		height: 100px;
		overflow: scroll;
		border: solid 1px gray;
		padding: 4px;
	}

	.match-content-container{
		padding-top: 0 !important;
		.news-timestamp{
			color: white;
			text-align: left;
		}
		.news-title{
			color: white;
			font-size: px2rem(12);
			font-weight: bold;
		}
	}

	.match-tabs{
		color: white;
		justify-content: center;
		padding: 25px 0;
		> div{
			@include breakpoint(tablet, max){
				flex-direction: column;
				padding: 0;
				flex: 1;
			}
			padding: 0 60px;
			display: flex;
			align-items: center;
			cursor: pointer;
		}
		.active{
			font-weight: bold;
		}
		.icon{
			font-size: 30px;
			@include breakpoint(tablet, max){
				margin-right: 0;
				margin-bottom: 10px;
			}
			margin-right: 18px;
			text-align: center;
		}
		.label{
			font-size: 14px;
			line-height: 14px;
			text-transform: uppercase;
		}
	}
}


$accordionBg: rgba(52, 53, 55, .9);
$accordionTitle1Bg: rgba(255,255,255, .2);
$accordionTitle2Bg: rgba(40, 40, 40, .9);
$accordionFont: 'Arial';
$accordionTitleBg: rgba(0, 0, 0, 0.0);
$accordionTitleText: rgba(255, 255, 255, 1.0);
//$accordionTitleHover: rgba(5, 5, 5, 0.8);
$accordionTitleHover: rgba(255,255,255, .5);
$accordionPointHover: rgba(151, 151, 151, 0.15);
$accordionPointSelected: rgba(151, 151, 151, 0.15);
$accordionPointSelectedBorder: rgba(255, 255, 255, 0.65);
$accordionWrapBorder: rgba(151,151,151,1.0);
$accordionSetBorder: rgba(118,118,118,1.0);
$accordionGameborder: rgba(216,216,216,.65);
$pointNavOptionsBg: rgba(255,255,255, .31);