.view-from-your-seat{
    display:inline-block;
    text-align:center;
    @include gotham(normal);
    padding:10px;

    .image-wrapper{
        width: 100%;
        height: 0;
        padding-bottom: calc(175 / 398 * 100%);

        img{
            width:100%;
        }
    }

    &-text{
        text-align:left;

        .title{
            margin:1.5em 0;
            font-size:18px;
            font-weight:bold;
        }

        .description{
            margin:1.5em 0;
            font-size:16px;

        }
    }

    .gangway-input{
        text-align:left;
        padding:10px;
        border:2px solid $wim-black;

        .generic-button_wrapper {
            margin-bottom: 0;

            .generic-button {
                margin-left: 0;
                margin-top: 10px;
                margin-bottom: 0;
            }
        }
    }

    .generic-button_wrapper > .generic-button {
        margin-left: 0;
        margin-top: 10px;
    }
}