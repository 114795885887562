.promos-carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100vw;
    height: calc(100dvh - #{$footerHeightLarge + $footerStripes + $footerMargin + $headerHeightSmall + 70}); // 70 to account for wimbledon logo
  }
  
  .carousel-image {
    max-width: 100%;
    max-height: 100%;
  }
  