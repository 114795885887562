
.carousel-wrap {
	margin: 0 5px;
}

.page .wrapper.scorespage .content-main {
    &.ai-catchup {
        &.notfound, .webview{ 
            background-color: $white;
        }
    }
} 

.ai-catchup{
    &.notfound, .webview{ 
        background-color: $white;
    }

    .nodata {
        color: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
    .idx-header{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .idx-header-inner{
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 25px;
            .headtext{            
                @include gotham(medium);
                font-size: 28px;
                color: $white;
                position: relative;
                text-transform: uppercase;
                line-height: 30px;
                letter-spacing: 1px;
            }
            .subtext{
                max-width: 390px;
                color: #FFFFFF;
                display: flex;
                padding-top: 14px;
                vertical-align: middle;
                line-height: 32px;
                .question{
                    display: inline-block;
                    font-size: initial;
                    cursor: pointer;
                    padding-left: 5px;
                    font-size: 16px;
                }
            }
            .right-link {
                display: none;
            }
        }
        .wim-icon-watsonx {
            font-size: px2rem(32);
            margin-left: 5px;
        }
    }

    .ai-catchup-cards-container{
        .column-layout{ 
            @include breakpoint(desktop){max-width: 1264px! important; padding: 0px 0px;}
            // @include breapoint(largeDesktop){}
        }
        .ai-catchup-summary{
            margin: 0px auto!important;
            padding-top: 0px!important;
            padding-bottom: 24px!important;
            display: block;
            @include breakpoint(tablet){ width: 100%; }

            .summary-card{
                background-color: $white;
                border-radius: 10px;
                border: 1px solid $gray_bb;
                padding: 24px 24px;
                position: relative;
                @include breakpoint(desktop){
                    display: flex;
                    padding: 0px 0px;
                }
                .new-tag{
                    margin-top: -13px;
                    left: 43%;
                }

                .image-container{
                    width: 100%; margin: 16px 0px;
                    img{ width: 100%; height: 100%; border-radius: 6.95px;}
                    @include breakpoint(tablet){
                        float: right; width: 253px; margin: 0px 0px 32px 32px;
                    }
                    @include breakpoint(desktop){
                        float: none; width: 399px; height: 302px; margin: 0px 0px;
                        img{ border-top-right-radius: 0px; border-bottom-right-radius: 0px;}
                    }
                }
                .title{
                    @include gotham(medium);
                    font-size: 18px;
                    display: inline-block;
                    @include breakpoint(tablet){
                        padding-bottom: 15px;
                    }
                }
                .summary-info{
                    @include breakpoint(desktop){
                        margin: 48px 32px;      
                    }
                    .text{ 
                        @include gotham;
                        font-size: 14px;
                        line-height: 20px;
                        p{ padding-bottom: 10px;}
                        
                        @include breakpoint(desktop){
                            height: 170px;
                            overflow-y: auto;
                        }
                    }
                    .title{ display: inline-flex;}
                }
            }
        }

        .ai-catchup-cards{ 
            margin: 0px auto 32px!important;
            //changes to grid to help with alignment
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: 500px;
            column-gap: 32px;
            row-gap: 32px;
            @include breakpoint(tablet){ grid-template-columns: 336px 336px; justify-content: space-between;}
            @include breakpoint(smallDesktop){ grid-template-columns: 400px 400px; justify-content: center;}
            @include breakpoint(medDesktop){ grid-template-columns: 336px 336px 336px; justify-content: space-between;}
            @include breakpoint(desktopSidePanel){ grid-template-columns: 400px 400px; justify-content: center;
                &.sideoff{ grid-template-columns: 400px 400px 400px; }
            }
            @include breakpoint(extraLargeDesktop){grid-template-columns: 400px 400px 400px; }
            .debugId {
                position: absolute;
                top: 0;
                right: 40px;
                font-size: px2rem(11);
            }
            .player-card{
                background-color: $white;
                border-radius: 10px;
                border: 1px solid $gray_bb;
                width: 100%;
                height: 500px;
                // margin: 16px 0px;
                padding: 12px 12px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .player-info{
                //   height: 100%; 
                //   display: block;
                    height: calc(100% - 40px); // minus the button height
                    display: flex;
                    flex-flow: column;
                  text-align: left;
                  position: relative;
                  
                  .ai-recommends{
                    position: relative;
                    padding-top: 6px;

                    .ai-type{
                        color: #888888;
                        @include gotham;
                        font-size: 12px; line-height: 14.4px; letter-spacing: 0.3px;
                        text-transform: uppercase;
                    }
                    .fav-container{
                        float: right;
                        margin-top: -10px;
                        .favorite {
                            position: relative;
                            margin-right: 20px;
                            font-size: 24px;
                            cursor: pointer;
                            &:after {
                                position: absolute;
                                left: 0;
                                content: "\2606";
                            }
                            &.favorited {
                                font-size: 24px;
                                &:after{
                                    font-size: 24px;
                                    content: "\2605";
                                    color: $wim-text-purple;
                                }
                            }
                        }
                    }
                    .new-tag{
                        margin-top: -13px;
                        left: 43%;
                    }
                  }

                  .player{
                    max-height: 55px; overflow: hidden;
                    margin: 12px 0px;
                    @include gotham(medium); font-size: 22px; 
                    align-items: center;
                    .flag{ width: auto; height: auto; margin-right: 15px; }
                    @include breakpoint(smallDesktop){ max-height: unset;}
                  }

                  .ranking{
                    height: 159px; width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    max-width: 100%;
                    .player-img{
                        width: 89px;
                        height: auto;
                        background-size: cover;
                        display: flex;  flex-grow: 1;
                        justify-content: center;
                        @include breakpoint(desktop){ width: 119px; }
                    }
                    .singles-rank, .doubles-rank{
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-grow: 1;
                        width: 98px;
                        @include breakpoint(desktop){ width: 130px;}
                        .rank-no{
                            font-size:20px;
                            @include gotham(medium);
                            @include breakpoint(tablet){ font-size: 24px;}
                            @include breakpoint(smallDesktop){font-size: 22px; letter-spacing: -1px;}
                            @include breakpoint(desktopSidePanel){ font-size: 30px;}
                        }
                        .title{ 
                            text-transform: uppercase;
                            @include gotham(medium); font-size: 8px;
                            margin-left: 5px;
                            @include breakpoint(tablet){font-size: 10px;}
                            @include breakpoint(desktopSidePanel){font-size: 12px; }
                        }
                    }
                    .singles-rank{
                        justify-content: left;
                        @include breakpoint(desktop){ justify-content: center;}
                    }
                    .doubles-rank{
                        justify-content: right;
                        @include breakpoint(desktop){ justify-content: center;}
                    }
                  }

                    .ai-summary{
                        @include gotham; color: $wim-black;
                        font-size: 14px; line-height: 20px; letter-spacing: 0.25px;
                        // max-height: 180px;
                        border-top: 1px solid $tourrank-color;
                        margin-left: -12px;
                        margin-right: -12px;
                        flex-grow: 1; // make the height flexible

                        &.full{ 
                            overflow-y: auto; 
                            scrollbar-width: none; //firefox
                            -ms-overflow-style: none; //edge
                            &::-webkit-scrollbar {display: none;} //chrome - ios
                        }
                        p{ margin: 15px 12px 0px;}
                        // @include breakpoint(smallDesktop){ max-height: 200px;}
                    }
                    .updated{
                        display: block;
                        color: #999999; text-transform: uppercase;
                        font-size: 12px; line-height: 14px;
                        margin-top: 12px; margin-bottom: 16px;
                        position: relative;
                        &.full{ margin: 12px 12px 16px; }
                        div {
                            display: inline-block;
                        }
                    }
                }

                /** make player card flexible height */
                .scrollable {
                    overflow: auto;
                  }
                .player-info > :not(.scrollable):not(.non-scrollable) {
                    flex-shrink: 0;
                  }
                  
                  .player-info > .scrollable  {
                    flex-grow: 1;
                  }

                .button-wrapper{
                    .generic-button_wrapper{ margin-bottom: 0px;}
                    .generic-button, .content:hover{border-radius: 6px;}
                }
            }
        }
        .webview, .webview-wrapper-player-info{
            display: none; //hides webviews
        }
    }

    /** styles for the home page AI Catch Up Component - cardType = shortWeb || shortWebview*/
    &.home { 
        background: transparent url(/assets/images/misc/bg-catchmeup-home-mobile.png) no-repeat 0 0;
        background-size: cover;
        width: 100%;
        padding: 0;
        margin-top: 18px;

        .slick-dots {
            width: calc(100vw - 30px);
        }

        .idx-header{
            justify-content: unset;
            align-items: unset;
            padding: 0;
            margin-top: -15px; // can't use transform: translateY(-15px) it breaks the help overlay position
    
            .idx-header-inner {
                text-align: center;
                flex-direction: row;
                justify-content: unset;
                align-items: center;
                margin-bottom: 0px;
                padding: 0 8px;

                .headtext{            
                    font-size: px2rem(14);
                    line-height: 30px;
                    letter-spacing: 1px;
                }
                .subtext{
                    display: flex;
                    font-size: px2rem(9);
                    margin-left: auto;
                    padding-top: 0;
                    line-height: px2rem(28);

                    .question{
                        font-size: px2rem(14);
                    }
                }
            }

            .wim-icon-watsonx {
                font-size: px2rem(32);
                margin-top: -2px;
                margin-left: 5px;
            }
        }
        
        .ai-catchup-cards-container {
            overflow-x: unset;
            width: 100vw;

			&.home {
				width: 100%;
			}

            .ai-catchup-cards {
                display: block;
                height: 180px;
                overflow: unset;
                width: 100%;

                .summary-card{
                    margin: auto;
                    width: 330px;
                    height: 157px;
                    background-color: $white;
                    border-radius: 10px;
                    border: 1px solid $gray_bb;
                    padding: 12px 12px;
                    position: relative;

                    .new-tag{
                        margin-top: -13px;
                        left: 30px;
                    }

                    .summary-wrapper{
                        display:flex;
                        height: 110px;
                        flex-direction: column;
                        .ai-recommends{
                            position: relative;   
                        }
                        .title{
                            font-size: px2rem(10);
                            color: #888888;
                            padding-top: 5px;
                            @include gotham;
                            line-height: 14.4px; letter-spacing: 0.3px;
                            text-transform: uppercase;
                        }
                        .text {
                            border-top: none;

                            p {
                                @include gotham(medium);
                                font-size: px2rem(16);
                                height: 66px;
                                line-height: 22px;
                                letter-spacing: 0.25px;
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;  
                                overflow: hidden;
                                margin: 4px 0 0 0;
                            }
                        }
                        .updated{
                            display: block;
                            color: #999999; text-transform: uppercase;
                            font-size: 10px; line-height: 14px;
                            margin-top: 3px;
                            // margin-bottom: 16px;
                            position: relative;
    
                            div {
                                display: inline-block;
                            }
                        } 
                    }
                    .button-wrapper {
                        flex: 1 0 100%;
                        .generic-button{ border-radius: 6px;}
                    }

                }

                .player-card {
                    display: flex;
                    //margin: 0 11px 0 0;
					margin: auto;
                    width: 330px;
                    height: 157px;
                    flex-wrap: wrap;
                    flex-direction: row;

                    .player-img {
                        margin-top: 27px;
                        height: 70px;
                        width: 90px;
                    }

                    .player-info{
                        width: calc(100% - 90px - 24px);
                        height: auto;

                        .updated {
                            font-size: px2rem(10);
                            margin-top: 2px;
                            margin-bottom: 5px;
                        }
                    }
                
                    .ai-recommends{
                        position: relative;
                        padding-top: 0;

                        .ai-type{
                            font-size: px2rem(10);
                        }
                        .new-tag{
                            position: absolute;
                            left: 0;
                            transform: translateX(-85px);
                            margin-right: unset;
                        }
                    }

                    .ai-summary {
                        border-top: none;
                        margin-left: unset;
                        margin-right: unset;

                        p {
                            @include gotham(medium);
                            font-size: px2rem(16);
                            height: 66px;
                            line-height: 21px;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;  
                            overflow: hidden;
                            margin: 4px 0 0 0;
                        }
                    }

                    .button-wrapper {
                        flex: 1 0 100%;
                    }
                }

            }
        }

        @include breakpoint(extraSmallTablet) {
            .ai-catchup-cards-container {

                .ai-catchup-cards {
                    .player-card {
                        width: 350px;
                    }
					.summary-card {
						width: 350px;
					}

                    .player-info{
                        width: calc(100% - 90px - 24px);
                    }
                }
            }

        }

        @include breakpoint(tablet) {

            background: transparent url(/assets/images/misc/bg-catchmeup-home.png) no-repeat 0 0;
            background-size: cover;
            width: 100%;
            margin-top: 0px;
            padding: 0 8px;

            .slick-dots {
                width: calc(100vw - 32px);
            }

            .idx-header{
                margin: -20px 7px 0px 7px; // to match the home page side margin for virtical alignment
                padding-top: 0;

                .idx-header-inner {
                    justify-content: space-between;
                    padding: 0;

                    .headtext{  
                        order: 2;
                        flex: 1;
                        font-size: px2rem(18);
                        position: absolute;
                        margin-left: 39%;
                    }
                    .subtext {
                        order: 1;
                        flex: 1;
                        font-size: px2rem(12);
                        margin-left: unset;
                        text-align: left;
                    }
                    .right-link {
                        @include gotham(medium);
                        order: 3;
                        flex: 1;
                        font-size: px2rem(14);
                        display: block;
                        color: $white;
                        text-align: right;
                        text-transform: uppercase;
                    }
                }
            }

            .ai-catchup-cards-container {
               // margin: 0 7px; // to match the home page side margin for virtical alignment
                //width: calc(100% - 14px);

                .ai-catchup-cards {
                    .player-card {
                        margin: auto;
                        width: 350px;
                    }

                    .player-info{
                        width: calc(100% - 90px - 24px);
                    }
                }
            }
        }

        // @include breakpoint(largeDesktop) {
        @include breakpoint(desktop) {
            .slick-dots {
                width: calc(100vw - 76px);
            }
            .idx-header{
                .idx-header-inner{
                    .headtext{margin-left: 42%; }
                }
            }
        }
        @include breakpoint(desktopSidePanel){
            .idx-header{
                .idx-header-inner{
                    .headtext.sideoff{margin-left: 44%; }
                }
            }
        }

        @include breakpoint(extraLargeDesktop) {
            .ai-catchup-cards-container {

                .ai-catchup-cards .player-card {
                    // width: 400px;
                    height: 157px;
                    margin-right: 26px;
                } 
            }
            
            .idx-header{
                .idx-header-inner{
                    .headtext{ margin-left: 42%; }
                }
            }
        }
    }// .&.home
} 

/** webview override **/
.webview {
    .ai-catchup {
        &.home {
            background: transparent;

            .idx-header .idx-header-inner .subtext .question {
                padding-top: 7px;
            }

            .slick-dots {
                bottom: 0;
            }
        }
    }
}


/** with side panel special width set up */
.page {
     // sidepanel always open
     @include breakpoint(largeDesktop) {
        .ai-catchup { 
            &.home {
                width: calc(100vw - #{$sidebarWidth});

                .slick-dots {
                    width: calc(100vw - #{$sidebarWidth} - 76px);
                }
            }

            .help-modal {
                width: calc(100vw - #{$sidebarWidth});
            }
        }
    }
    // sidepanel always open with centered max width content
    @include breakpoint(extraLargeDesktop) {
        .ai-catchup {
            &.home {
                width: calc(#{$maxWidth} - #{$sidebarWidth});

                .slick-dots {
                    width: calc(#{$maxWidth} - #{$sidebarWidth} - 76px);
                }
            }

            .help-modal {
                width: calc(#{$maxWidth} - #{$sidebarWidth});
            }
        }
    }

    &.expanded {
        .ai-catchup{
            &.home {
                width: calc(100vw - #{$sidebarWidth});

                .slick-dots {
                    width: calc(100vw - #{$sidebarWidth} - 76px);
                }
            
                @include breakpoint(extraLargeDesktop) {
                    width: calc(#{$maxWidth} - #{$sidebarWidth});

                    .slick-dots {
                        width: calc(#{$maxWidth} - #{$sidebarWidth} - 76px);
                    }
                }
            }

            .help-modal {
                width: calc(100vw - #{$sidebarWidth});
            }

            /** so the fade in the draw analysis box won't expand to bleed out,
                set the width the same size as the draw analysis box itself
            */
            // .draw-analysis-help-overlay {
            //     width: 350px;
            
            //     @include breakpoint(extraLargeDesktop) {
            //             width: 350px;
            //     }
            // }
        }
    } // .expanded

    &.compressed { /** slamtracker opened under the side panel size */
        .ai-catchup{
            &.home {
                .slick-dots {
                    width: calc(100% - #{$sidebarWidth} - 76px);
                }

                .help-modal {
                    width: calc(100% - #{$sidebarWidth});
                }
            }
        }
	} // .compressed
}