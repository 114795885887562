.news-landing-time-selector{
    color: #00703C;
    display: flex;
    width: 100%;
    //margin: 17px 0 0 0;
    border-bottom: 1px solid $select-menu-border;
    justify-content: center;
    padding: 8px 0;

    .champs-dropdown, .archive-dropdown {
		.select-menu {
			.select-menu-dropdown {
				padding: 0 2px;
				// display: none;
				display: flex;
				flex-direction: column;
				min-width: 130px;

				// &.show {
				// 	display: flex;
				// }

				.option {
					margin: 8px 0px 4px 0px;
					align-self: center;
					padding: 0 20px 0 8px;
					display: flex;
				}

				.section {
					margin: 4px 0px;
				}
			}
		}
    }

    .archive-dropdown{
        //width: 115px;
        margin-bottom: 1px;
        margin-left: 18px;
    }

    .separator {
        height: 15px;
        width: 1px;
        background-color: $lines-gray;
        margin: 8px 15px 0 15px;
    }

    .option{
        color: $wim-green;
        padding: 0 4px 0 4px;

		i {
			flex: 0 0 auto;
			margin-right: 5px;
			margin-top: 4px;
			text-align: unset;
			position: inherit;
			left: unset;
			top: unset;
		}

		span {
			flex: 1 0 auto;
		}
	}

    @include breakpoint(desktop,max){

        i{
            font-size: 10px;
        }

        button{
            padding:0;
            //margin: 0 8px 0 8px;
            a{
                color:$wim-green;
            }
        }

        .filter-dropdown {
            display: none;
        }
    }

}

.news-landing-filters {
	.filter-dropdown {
		.select-menu {
			.select-menu-dropdown {
				padding: 0 2px;
				display: flex;
				flex-direction: column;
				min-width: 130px;
				.option {
					margin: 8px 0px 4px 0px;
					align-self: center;
					padding: 0 20px 0 8px;
					display: flex;

					i {
						flex: 0 0 auto;
						margin-right: 5px;
						text-align: unset;
						position: inherit;
						left: unset;
					}

					span {
						flex: 1 0 auto;
					}
				}

				.section {
					margin: 4px 0px;
				}
			}
		}
    }
}
