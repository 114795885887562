.column-layout {
	&.general {
		@include gotham-book();

		.heading {
			font-size: 24px;
			margin-bottom: 10px;
			text-align: center;
		}
		.body {
			text-align: center;
			font-size: 16px;
			width: 80%;
			margin-left: auto;
			margin-right: auto;
			//margin-bottom: 5px;

			&.bottom-margin {
				margin-bottom: 20px;
			}

			&.left-align {
				text-align: left;
			}

			@include breakpoint(tablet) {
				.tm-links {
					a {
						clear: unset;
						display: inline;
					}

					.link-sep {
						display: inline;
					}
				}
			}
		}

		.center {
			text-align: center;
		}

		.secDividerContent {
			margin: 15px 0;
		}

		.selectMenu {
			background: rgba($white, 0.5);
			border: 2px solid $black;
			margin: 10px 10px 15px 10px;
			border-radius: 3px;
			color: $black;
			@include gotham_book;
			font-size: calc((16/16)*1em);
			height: 36px; // for ff
			line-height: 32px;
			padding-left: 1em;
			//text-transform: uppercase;
			text-align: center;
			text-align-last: center;
			width: 300px;
			display: inline-block;
		}

		.textArea {
			background: rgba($white, 0.5);
			border: 2px solid $black;
			margin: 10px 10px 15px 10px;
			border-radius: 3px;
			color: $black;
			@include gotham_book;
			font-size: calc((16/16)*1em);
			height: 150px; // for ff
			width: 300px;
			outline: 0;
		}

		.link-sep {
			margin: 0 10px;
		}

		 @include breakpoint(tablet, min){
			.two-col {
				width: 50%;
				margin-left: calc(25% + #{$item-h-margin});
			}
		}
	}

	.error-code {
		font-size: 12px;
		color: grey;
	}
}
