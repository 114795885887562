.pde-match-stats-video {
    .intro-text{
        width: 100%;
    }
    .player-search {
        margin-top: 30px;
        margin-bottom: 35px;

        .search-box {
            width: 100%;

            .search-wrapper {
                color: #888888;
                
                input {
                    border: 1.5px solid $grey-c7;
                    border-radius: 30px;

                    &:hover {
                        border: 1px solid $grey-c7;
                        box-shadow: none;
                    }
                }
                i {
                    &.wim-icon-close {
                        right: 20px;
                    }
                }
            }
            .react-autosuggest__suggestions-container {
                width: calc(100% - 60px);
                border-radius: 0;

                ul {
                    border: 1px solid $grey-c7;
                    border-top: none;
                }
            }

            @include breakpoint(desktop) {
                width: 617px;
            }
        }
    }
    .match-list {
        .match-list-header {
            @include gotham-book;
            text-transform: uppercase;
            font-size: 18px;
            color: $wim-green;
            letter-spacing: 1px;
            padding-bottom:26px;
            border-bottom: 1px solid $wim-green;

            .name {
                @include gotham(medium);
            }
        }
        .match-list-content {
            .Collapsible {
                text-align: center;

                .Collapsible__trigger{
                    color: $wim-green;
                    @include gotham(medium);
                    font-size: 18px;
                    cursor: pointer;
                    // text-align: center;
                    display: inline-block;
                    margin: 0 auto;

                    &::before{
                        content: "+";
                        display: inline-block;
                        padding: 0;
                        width: 19px;
                        height: 18px;
                        border: 1px solid $wim-green;
                        transform: none;
                        text-align: center;
                        line-height: 18px;
                        margin-right: 11px;
                    }
                    &.is-open{
                        &::before{
                            content: "-";
                        }
                    }
                }
                .Collapsible__contentInner{
                    text-align: left;
                }
            }
            .match-list-year {
                padding-bottom: 34px;
                border-bottom: 1px solid $wim-green;
                margin-top: 22px;

                &:last-child {
                    border-bottom: none;
                }
            }
            .event-header {
                @include gotham(medium);
                font-size: 18px;
                color: $wim-black;
                margin-top: 12px;
                margin-bottom: 15px;
            }
            .match-item {
                margin-top: 16px;

                &:first-child {
                    margin-top: 0;
                }
            }
            .match-item-description {
                @include gotham(medium);
                font-size: 14px;
                color: $wim-black;
                margin-bottom: 9px;
            }
            .match-link-item {
                height: 24px;
                font-size: 14px;
            }
            &.no-content {
                margin-top: 30px;
                text-align: center;
            }
        }
    }

    .pdeVideo{
        color: #00703C;
        text-decoration: none;
        border: none;
        background: none;
        padding: 0;
        font-size: 14px;
        cursor: pointer;
        @include gotham-book;
        &:hover{
            text-decoration: underline;
        }
    }
}

.page {
    .wrapper {
        .content-main.pde-match-stats {
            padding-top: 0;
            
            >.column-layout {
                padding-top: 0;

                > .four-col {
                    margin-top: 0;
                }
            }
        }
    }
}

.pde-match-stats {
    .match-stats-header {
        height: 58px;
        line-height: 58px;
        @include gotham(medium);
        font-size: 20px;
        color: $wim-green;
        text-align: center;
        border-bottom: 1px solid $gray_bb;
        margin-top: 0;
        margin-bottom: 0;
    }
    .match-stats-scoreboard {
        margin: 18px auto 0;
    }
    .match-stats-table {
        margin: 40px auto 20px; 

        .stats-table-wrapper {
            border: 1px solid $wim-text-purple;
            padding: 0 10px;
        }
    }
    &.metric {
        .metric {
            display: inline-block;
        }
        .imperial {
            display: none;
        }
    }
    &.imperial {
        .imperial {
            display: inline-block;
        }
        .metric {
            display: none;
        }
    }
    .slamtracker-stats.expanded {
        .overall {
        //     .stats-header-row {
        //         .t1 {
        //             .stats-player {
        //                 // width: 30%;
        //                 // text-align: center;
        //                 padding-left: 22px;
        //             }
        //         }
        //         .t2 {
        //             .stats-player {
        //                 // width: 30%;
        //                 // text-align: center;
        //                 padding-right: 22px;
        //             }
        //         }
        //     }
        //    .stats-data {
        //        width: 15%;

        //        &.t1, &.t2 {
        //            text-align: center;
        //        }
        //    }
        }
    }
    .no-content {
        text-align: center;
        padding: 100px 0;
    }
}

.pde-content-dash{
    table, th, td{
        border: 1px solid black;     
        border-collapse: collapse;   
    }
    table{
        width: 700px;
        th, td{
            padding: 5px 10px;
            font-size: 13px;
        }
        td{
            &.match-player{
                width: 200px;
            }
        }
    }

    .delegate-table {
        width: 100%;
        margin-bottom: 20px;
        border-collapse: collapse;
        border: none !important;
        th, td{
            border: none !important;
        }
        thead {
            background: #ECECEC;
            text-transform: uppercase;
            tr {
                th {
                    padding: 10px 0px 10px 10px;
                    text-align: left;
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 5px 0px 5px 10px;
                    font-size: 18px;
                    &.match-player{
                        width: 200px;
                    }
                    input {
                        font-size: 18px;
                    }
                }
                td:first-child {
                    padding-left: 10px;
                }
            }
            tr:nth-child(even) {
                background: #ECECEC;
            }
            .checkbox-electric{
                label::before{
                    top: -15px;
                }
                label::after{
                    top: -11px;
                }
            }
        }
    }

    h4:first-child{
        margin-top: 0;
    }
    h4{
        margin: 40px 0 10px 0;
        font-size: 20px;
    }    
    &.delegate-selection{
        display: flex;
        justify-content: center;
    }

    .approval-buttons{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .generic-button_wrapper{
            margin: 0 5px;
        }
    }
}