/** styles for ticket table and overlay **/
.ticket-info {
	.row {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;

        &.headers { display: none; }
        .row-header {
            background-color: #ECECEC;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
        }
        .day { 
            // width: 20%; 
            font-weight: bold; 
            padding-left: 20px; 
            padding-right: 10px;
            
            &::before {
                content: 'Day';
                text-transform: uppercase;
                margin-right: 6px;
            }
        }
		.date { /*padding-left: 20px;*/ font-weight: bold; }
		.centrecourt {
            padding-left: 20px;
            font-weight: bold; 
            
            &::before {
                content: 'Centre Court';
                margin-right: 6px;
            }
        }
		.no1court { 
            padding-left: 20px;
            font-weight: bold; 
            
            &::before {
                content: 'No.1 Court';
                margin-right: 6px;
            }
        }
        .seats { padding-left: 20px; font-weight: bold; }
        
        @include breakpoint(tablet) {
            flex-direction: row;

            &.headers { 
                display: flex; 

                .day { width: 15%; }
                .date { width: 35%; }
            
            }
            .no-show-mobile { display: none; }
            .row-header { 
                width: 50%;
                background-color: transparent;
            }
            .day { 
                width: 30%; 
                padding-left: 24px; 
                padding-right: 0;
                
                &::before {
                    content: '';
                    margin-right: 0;
                }
            }
            .date { width: 70%; padding-left: 0; }
            .centrecourt { 
                width: 15%; 
                padding-left: 0; 
            
                &::before {
                    content: '';
                    margin-right: 0;
                }
            }
            .no1court { 
                width: 15%; 
                padding-left: 0;
                
                &::before {
                    content: '';
                    margin-right: 0;
                }
            }
            .seats { width: 20%; padding-left: 0; }
        }
        @include breakpoint(desktop) {
            &.headers {
                .day { width: 10%; }
                .date { width: 23%; }
            }
            .no-show-mobile { display: inline; }
            .row-header { width: 33%; }
            .day { width: 30.3%; }
            .date { width: 69.7%; }
            .centrecourt { width: 17%; }
            .no1court { width: 17%; }
            .seats { width: 33%; }
        }
	}
	.gray {
        background-color: none;
        
        @include breakpoint(tablet) {
            background-color: #ECECEC;
        }
	}
	.row {
		.cell {
			height: 40px;
			line-height: 40px;
				
			&.seats {
				font-weight: normal;

				a {
					cursor: pointer;
				}
			}
		}
		&.headers {
			.cell {
				height: 49px;
				line-height: 49px;
				text-transform: uppercase;
			}
		}
	}
}

.seats-overlay {
	.seats-overlay-content {
		width: 96%;
		padding: 25px 0px;
		margin: 90px auto 0;
		background-color: $white;
        position: relative;
        max-height: calc(100vh - 100px);
        overflow: scroll;

		.close {
			position: absolute;
			right: 25px;
			top: 20px;
			outline: none;
		}
		.seats-title {
			color: $wim-green;
			font-size: 22px;
			font-weight: bold;
			text-transform: uppercase;
			padding-bottom: 25px;
			margin: 0 auto;
			width: 100%;
			text-align: center;
		}
		.seats-row-content {
			width: 96%;
			margin: 11px 2% 0;
			border: 1px solid $wim-green;
			padding: 7px;
			display: flex;
			flex-direction: column;
			align-items: center;
			font-size: 30px;
			color: $wim-black;
			letter-spacing: 0;

			.seats-header {
				font-size: 12px;
				font-weight: bold;
				color: $wim-black;
				letter-spacing: 0;
				text-transform: uppercase;
            }
            .seats-data-container {
                display: flex;
                flex-direction: row;
                width: 100%;
                text-align: center;
            }
			.seats-court {
				font-size: 22px;
				font-weight: bold;
                text-align: center;
                width: 100%;
                padding-bottom: 20px;
			}
			.seats-gangway { width: 33.3%; }
			.seats-row { width: 33.4%; }
            .seats-seatno { width: 33.3% }
            
            @include breakpoint(tablet) {
                width: 80%;
			    margin: 11px 10% 0;
            }
        }
        
        @include breakpoint(tablet) {
            width: 90%;
            margin: 215px auto 0;

            .seats-row-content {
                flex-direction: row;
            
                .seats-court { width: 35%; padding-left: 25px; padding-bottom: 0; text-align: left;}
                .seats-data-container {
                    width: 65%;
                    text-align: left;
                }
                .seats-gangway { width: 33.3%; }
                .seats-row { width: 33.4%; }
                .seats-seatno { width: 33.3% }
            }
        }

        @include breakpoint(desktop) {
            width: 70%;
            padding: 25px 0px;
            margin: 215px auto 0;
            max-height: calc(100vh - 240px);
        }

		 
	}	
}

.page {
	&.expanded  {
		.seats-overlay {
			.seats-overlay-content {

				// sidepanel open or cloased based on user selection
				@include breakpoint(desktopSidePanel, mid){
					width: calc(.9 * (100vw - #{$sidebarWidth}));
					margin-left: calc(.05 * (100vw - #{$sidebarWidth}));
				}

				// sidepanel always open
				@include breakpoint(largeDesktop){
					width: calc(.7 * (100vw - #{$sidebarWidth}));
					margin-left: calc(.15 * (100vw - #{$sidebarWidth}));
				}

				// sidepanel always open and content area centered with curtains
				@include breakpoint(extraLargeDesktop){
					width: calc(.7 * (#{$maxWidth} - #{$sidebarWidth}));
					margin-left: calc(.15 * (#{$maxWidth} - #{$sidebarWidth}) + ((100vw - #{$maxWidth}) / 2));
				}
			}
		}
	}
}