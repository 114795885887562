.homepage-breaking{
    padding:0;
    min-height: auto;
    a {
        text-decoration: none;
    }
    .homepage-element-info{
        left:0;
        width:100%;
        min-width:768px;
        text-align:center;
        position: relative;
        transform: translateY(0);
        .breaking-tag{
            text-transform:uppercase;
            background:$wim-green;
            width:100px;
            padding-top: 2px;
            padding-bottom: 2px;
            color:$white;
            text-align:center;
            font-size:10px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            margin-bottom:5px;
            letter-spacing:2px;
        }

        img{
            display:none;
        }

        i{
            font-size:47px;
            color:$wim-green;
            margin-bottom:10px;
        }
        
        .time{
            color:$wim-green !important;
            text-align: center;
        }

        .title{
            color:$wim-purple;
            &.mobile {
                text-align: center;
            }
        }

        .text{
            color:$select-menu-text;
            width:75%;
            min-width:600px;
            transform: translateX(-50%);
            position: relative;
            left: 50%;
            max-height: 115px;
            overflow: hidden;
            margin-top: 10px;
        }
    }

    .wim-icon-pin{
        color:$wim-green;
    }

    @include breakpoint(tablet,max){
        height:auto;

        .homepage-element-info{
            min-width:0;
        }

        img{
            display:none;
        }
    }
}