.privacy-content-header{
    @include gotham(normal);
    padding:20px 0;
    font-size:55px;
    line-height:1.2em;
    margin:0;
    color:$select-menu-text;
    width:100%;

    @include breakpoint(tablet,max){
        @include gotham(medium);
        font-size:20px;
    }
}