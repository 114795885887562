.homepage-element{
    position:  relative;
    width:  100%;
    height:  100%;
    overflow:  hidden;
    background-color: $white;
    //padding:  calc(50% * 554 / 1346) 0;
    @include gotham(medium);

    .homepage-element-info{
        position:  absolute;
        top:  50%;
        transform:  translateY(-50%);

        @include breakpoint(tablet,max){
            // transform: translate(-50%,-15px);
            transform:  unset !important;
            width:  100% !important;
            bottom:  0 !important;
            top:  unset !important;
            left:  unset !important;
            padding-left: 8% !important;
            right:  unset !important;
            padding-right: 8% !important;
            text-align:  center !important;
            // text-align: left !important;
        }
	}

    &.homepage-video{
        .homepage-element-info{
            .time i{
                display: none;
            }
            .title.mobile{
                font-size: 15px;
                line-height: 20px;
                margin: 5px 0;
            }
        }
    }

	.image-wrapper {
		width: 100%;

		img {
			vertical-align: bottom;
			margin-left: unset;
		}

		@include breakpoint(tablet) {
			width: 66%;

			&::after {
				display: none;
			}
		}

		&::after {
            position:  absolute;
            background-image:  linear-gradient(to top, rgba(0, 0, 0, 0.4) 30%, transparent);
            height: 60%;
            width: 100%;
            content: '';
            left: 0;
			bottom: 0;
			pointer-events: none;
        } 
	}
	
	&.left {
		.image-wrapper {
			@include breakpoint(tablet) {
				margin-left: 34%;
			}
        }
        &.image-background {
            .image-wrapper {
                @include breakpoint(tablet) {
                    margin-left: 0px;
                }
            }
            .title {

            }
        }
	}
	
    .backgroundImage {
		width: 100%;

        &::after {
            position:  absolute;
            background-image:  linear-gradient(to top, rgba(0, 0, 0, 0.4) 40%, transparent);
            height:  100%;
            width:  100%;
            content:  '';
            left: 0;
            // display:  none;
        }        
	}

    img{
        top: 0;
        width: 100%;
	}
	
	// .image-wrapper {
	// 	position:  relative;
	// 	top: 0;
    //     width: 100%;
	// }

    .new-tag{
        display:  none;
    }

    .wim-icon-pin{
        display: none;
    }

    &.pinned{
        .wim-icon-pin{
			display: inline;
			position: absolute;
			top: 14px;
			right: 12px;
			font-size: 25px;
        }
    }

    &.new{
        .new-tag{
            text-transform:  uppercase;
			background:  $live-green2;
			width: 50px;
			height: 15px;
			color:  $black;
			text-align:  center;
			font-size:  10px;
			position:  absolute;
            top:  0px;
            left: 0px;
			padding-top:  1px;
			display: inline;
        }
    }

    &.fullsize {
        width: 100%;
        margin-left: 0%;
    }

    &.standard {
        width: 100%;
        margin: auto;
        // margin-left: 15%;
		// width:  100%;
		&.right{
			img{
				left: 0;
				right: unset;
			}

			.image-wrapper{
				left: 0;
				right: unset;
			}

			.wim-icon-pin{
                color: $wim-green;
            }
		}

		&.left {
			.wim-icon-pin{
                color: $white;
            }
		}
        &.homepage-video {
			//min-height: 300px;
			
            .image-wrapper{
				border: none;
				
            }
        }
	}
    
    @include breakpoint(tablet){
        &.standard {
            width:  90%;
            padding:  0;
            overflow: visible;
            .standard-wrapper {
                .new-tag {
                    left: calc(-12% + 50px);
                }
            }
        }
        .backgroundImage {
			width: 66%;

            &::after {
                display:  none;
            }
        }        
    }
    @include breakpoint(desktop) {
        &.standard {
            width: 75%;
            .standard-wrapper {
                .new-tag {
                    left: calc(-22% + 50px);
                }
            }
        }
        
    }
    @include breakpoint(desktopSidePanel){
        &.standard {
            // width: 75%;
            .standard-wrapper {
                .new-tag {
                    left: calc(-22% + 50px);
                }
            }
        }
    }
}