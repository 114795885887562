.content-main{
    &.column-layout{
        &.live-radio{
            display:flex;
            flex-direction: column;

            .radio-stations{
                display:flex;

                @include breakpoint(tablet,max){
                    flex-direction:column;
                }

                .radio-station{
                    flex:1 1 auto;
					
					&.wimbledon {
						&.on-air {
							background-image: url(/assets/images/live-radio/Wimbledon_Radio_On.jpg);
						}
						&.off-air {
							background-image: url(/assets/images/live-radio/Wimbledon_Radio_Off.jpg);
						}
						background-size: cover;
						background-repeat: no-repeat;
					}

					&.one {
						&.on-air {
							background-image: url(/assets/images/live-radio/No1_Court_On.jpg);
						}
						&.off-air {
							background-image: url(/assets/images/live-radio/No1_Court_Off.jpg);
						}
						background-size: cover;
						background-repeat: no-repeat;
					}

					&.centre {
						&.on-air {
							background-image: url(/assets/images/live-radio/Centre_Court_On.jpg);
						}
						&.off-air {
							background-image: url(/assets/images/live-radio/Centre_Court_Off.jpg);
						}
						background-size: cover;
						background-repeat: no-repeat;
					}

                    hr{
                        width: 60%;
                        margin:20px 20% 0;
                        height:1px;
                        border:none;
                        background-color:$white;
                    }

					@include breakpoint(mobile){
	                    height: 248px;
	                }
					@include breakpoint(tablet){
	                    height: 436px;
	                }
                }
            }

            hr{
                width: 100%;
                margin:40px 0;
                background-color:$wim-purple;
                border:none;
                height:2px;

                @include breakpoint(tablet,max){
                    margin:5px 0;
                }
            }

            .twitter-embed{
                text-align:center;
            }
        }
    }
}
