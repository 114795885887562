.homepage-text-promo{
    // min-height: 300px;
    padding:0;
    
    .homepage-element-info{
        // top:50%;
        // transform:translate(-50%,-50%);
        // left:50%;
        // width:80%;
        // min-width:768px;
        text-align:center;
        position: relative;
        top: 0%;
        transform: translateY(0%);
        left: 0%;
        width: 100%;
        img{
            // display:none;
        }

        i{
            font-size:26px;
            color:$wim-green;
        }
        
        .time{
            color:$wim-text-purple
        }

        .title{
            color:$select-menu-text;
        }

        .text{
            color:$select-menu-text;
            width:75%;
            // min-width:600px;
            transform: translateX(-50%);
            position: relative;
            left: 50%;
        }
    }

    .wim-icon-pin{
        color:$wim-green;
    }

    @include breakpoint(tablet,max){
        height:auto;

        .homepage-element-info{
            min-width:0;
        }

        img{
            // display:none;
        }
    }

    &.standard{
        height:100%;

        @include breakpoint(tablet,max){
            // min-height:213px;

            .homepage-element-info{
                top:50%;
                transform:translate(-50%,-15px);
                .wim-icon-textLines,.time,.title{
                    // color:$white;
                }
            }

            .wim-icon-pin{
                color:$white;
            }
            .wim-icon-gallery {
                color: white;
            }
        }

        img{
            position:absolute;
            display:inline;
            top:0;
            left:50%;
            transform:translateX(-50%);
            width:100%;
            max-width:100%;
    
            @include breakpoint(tablet,max){
                // min-height:213px;
                width: 100%;
                transform: unset;
                left: unset;
                // width:unset;
                // max-width:unset;
                // min-width:100%;
            }
        }

        @include breakpoint(tablet){
            .standard-wrapper{
                // padding: calc(50% * 397 / 979) 0;
            }
            img{
                width:66%;
                transform:none;
                right:0;
                left:unset;
            }
            .homepage-element-info{
                // min-width:unset;
                // position:relative;
                // top:50%;
                // transform:translateY(-50%);
                // width:34%;
                // left:0;
                position: relative;
                top: 0%;
                transform: translateY(0%);
                left: 0%;
                width: 100%;
                .text{
                    min-width:unset;
                }
            }
            &.right{
                .homepage-element-info{
                    right:0;
                    left:unset;
                }
            }
        }
    }
    &.image-background {
        .homepage-element-info {
            i, .time, .title, .text {
                // color: white;
            }
        }
        .backgroundImage {
            width: 100%;
            &::after{
                top: -4px;
            }
        }
    }
    @include breakpoint(tablet) {
        &.image-background {
            &.left {
                .backgroundImage {
                    &::after{
                        display:block;
                        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4) 1%, transparent);
                    }
                }
            }
            &.right {
                .backgroundImage {
                    &::after{
                        display:block;
                        background-image: linear-gradient(to left, rgba(0, 0, 0, 0.4) 1%, transparent);
                    }
                }
            }
    
            .backgroundImage {
                &::after{
                    top: -4px;
                }
            }        
            .homepage-element-info {
                &.left {
                    width: 25%;
                    min-width: unset;
                    transform: unset;
                    left: 50px;
                    right: unset;
                    text-align: left;
                    top: 10%;
                    overflow: hidden;
                    top: 50%;
                    transform: translateY(-50%);                
                    .text {
                        width: 100%;
                        transform: unset;
                        left: unset;
                    }
                }
                &.right {
                    width: 25%;
                    min-width: unset;
                    transform: unset;
                    left: unset;
                    right: 50px;
                    text-align: right;
                    top: 10%;
                    overflow: hidden;
                    top: 50%;
                    transform: translateY(-50%);                
                    .text {
                        width: 100%;
                        transform: unset;
                        left: unset;
                    }
                }
            }
        }
    }

}