.catch-up-article{
    margin-bottom: -74px;
    &.noArticle{
        margin-bottom: 0px;
        margin-top: 15px;
        .ai-catch-up-box{
            bottom: 0;
        }
    }
    &.noBox{
        margin-bottom: 0px;
        .article-link{
            .article-title{
                bottom: 10px;
            }
        }
    }
    position: relative;
    .article-link{
        width: 100%;
        height: 100%;
        position: relative;
        button{
            width: 100%;
            height: 423px;
            position: relative;
            cursor: pointer;
            .article-image{
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    object-position: center;            
                }
            }
            .article-title{
                position: absolute;
                width: 100%;
                bottom: 85px;
                z-index: 2;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                text-align: left;
                left: 0;
                .text{
                    @include gotham(medium);
                    text-transform: uppercase;
                    font-size: 18px;
                    color: white;
                    width: calc(100% - 20px);
                    max-width: 355px;
                }
            }
        }
    }
}

.ai-catch-up-box {
    width: calc(100% - 20px);
    max-width: 355px;
    margin-left: auto;
    margin-right: auto;
    padding: 4px 16px 13px 16px;
    box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 25%);
    background-color: white;
    position: relative;
    bottom: 74px;
    z-index: 2;

    &.green-border {
        border-top: 3px solid $wim-green;
    }

    .ai-catch-up-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            text-transform: uppercase;
            @include gotham(medium);
            font-size: px2rem(12);
            color: $wim-black;
        }
        .subtext{
            max-width: 390px;
            color: $wim-black;
            display: flex;
            align-items: center;
            line-height: 32px;
            font-size: px2rem(9);
            @include gotham(book);

            i {
                margin-left: 5px;
                font-size: px2rem(24);
            }
            .question {
                display: none;
            }
        }
    }
    .ai-catch-up-content {
        ul {
            padding-left: 16px;
            margin-top: 5px;

            li {
                @include gotham-book();
                font-size: px2rem(12);
                line-height: 15px;
                margin-top: 15px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

.recap-content {
    .ai-catch-up-box {
        margin-bottom: 15px;
    }
}