// mixins
@mixin clearfix {
	&:after {
		clear: both;
	}

	&:before,
	&:after {
		display: table;
		content: ' ';
	}
}

//rotation mixin
@mixin rotate($degrees){
    -webkit-transform: rotate(#{$degrees}deg);
    -moz-transform: rotate(#{$degrees}deg);
    -o-transform: rotate(#{$degrees}deg);
    -ms-transform: rotate(#{$degrees}deg);
    transform: rotate(#{$degrees}deg);
}

@mixin contentMainWidth($margin: false) {
    // sidepanel open or cloased based on user selection
    @include breakpoint(desktopSidePanel, mid){
        width: calc(100vw - #{$sidebarWidth});
    }

    // sidepanel always open
    @include breakpoint(largeDesktop){
        width: calc(100vw - #{$sidebarWidth});
    }

    // sidepanel always open and content area centered with curtains
    @include breakpoint(extraLargeDesktop){
        width: calc(#{$maxWidth} - #{$sidebarWidth});

        @if $margin == true {
            margin-left: calc((100vw - #{$maxWidth}) / 2);
        }
        
    }
}

@mixin accessibleCheckbox($round: false, $size: 20px) {
	input[type="checkbox"] {
		opacity: 0;
		position: absolute;
		width: 1px;
	}

	label::before{
		content: "";
		display: inline-block;
		
		height: $size;
		width: $size;
		
		border: 1px solid;   
	}

	label {
		position: relative;
		display: inline-block;
		// line-height: 23px;
		padding-left: 25px !important;
		font-size: 12px;
		color: $wim-black;
		letter-spacing: .7px;
		@include gotham-book();
	}
	label::before,
	label::after {
		position: absolute;
		content: "";
		
		/*Needed for the line-height to take effect*/
		display: inline-block;
	}
	/*Outer-box*/
	label::before {
		color: $wim-text-purple;
		left: 0px;
		top: -1px;


		@if $round {
			border: 1px solid $wim-text-purple;
			border-radius: 20px;
		} @else {
			border: 1px solid $wim-text-purple;
		}
	}
	
	/*Checkmark*/
	label::after {	
		@if $round {
			left: 5px;
			top: 5px;
			height: 12px;
			width: 12px;
			border-radius: 20px;
			background-color: $wim-text-purple;
		} @else {
			left: 4px;
			top: 3px;
			height: 14px;
			width: 14px;
			// border-left: 2px solid;
			// border-bottom: 2px solid;
			background-color: $wim-text-purple;
			// transform: rotate(-45deg);
		}
	}

	/*Hide the checkmark by default*/
	input[type="checkbox"] + label::after {
		content: none;
	}
	/*Unhide the checkmark on the checked state*/
	input[type="checkbox"]:checked + label::after {
		content: "";
	}

	/*Adding focus styles on the outer-box of the fake checkbox*/
	input[type="checkbox"]:focus + label::before {
		outline: rgb(59, 153, 252) auto 5px;
	}

	input[type="checkbox"][disabled] {
		pointer-events: none;
	}

	input[type="checkbox"][disabled] + label::after{
		color: $gray;
	}

	input[type="checkbox"][disabled] + label::before{
		color: $gray !important;
		border-color: $gray;
	}

	/*Adding focus styles on the outer-box of the fake checkbox*/
	label:hover {
		&::before {
			box-shadow: inset 0 0 0 1px $wim-text-purple;
		}
	}
}

//checkbox form elements
@mixin checkbox($round: false, $size: 20px) {
    input[type="checkbox"] {
		opacity: 0;
		-webkit-appearance: none;
		appearance: none;
		display: none;
	}
	
	label {
		position: relative;
		display: inline;
		
		/*16px width of fake checkbox + 6px distance between fake checkbox and text*/
		//padding-left: 22px;

		padding-left: 25px !important;
		@include gotham-book();
		font-size: 12px;
		color: $wim-black;
		letter-spacing: .7px;
	}
	
	label::before,
	label::after {
		position: absolute;
		content: "";
		
		/*Needed for the line-height to take effect*/
		display: inline-block;
	}
	
	/*Outer box of the fake checkbox*/
	label::before{
		height: 16px;
		width: 16px;
		
		border: 1.5px solid $wim-green;
		left: 0px;
		
		/*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
		 *to vertically center it.
		 */
		top: -1px;

		@if $round {
			border: 1.5px solid $gray;
			border-radius: 20px;
		} @else {
			border: 1.5px solid $wim-green;
		}
	}
	
	/*Checkmark of the fake checkbox*/
	label::after {	
		@if $round {
			left: 5px;
			top: 5px;
			height: 9px;
			width: 9px;
			border-radius: 20px;
			background-color: $wim-green;
		} @else {
			left: 4px;
			top: 4px;
			height: 5px;
			width: 9px;
			border-left: 2px solid;
			border-bottom: 2px solid;
			color: $wim-green;
			transform: rotate(-45deg);
		}
	}
	
	/*Hide the checkmark by default*/
	input[type="checkbox"] + label::after {
		content: none;
	}
	
	/*Unhide on the checked state*/
	input[type="checkbox"]:checked + label::after {
		content: "";
	}
	
	/*Adding focus styles on the outer-box of the fake checkbox*/
	input[type="checkbox"]:focus + label::before {
		//outline: rgb(59, 153, 252) auto 5px;
	}

	input[type="checkbox"][disabled] {
		pointer-events: none;
	}

	input[type="checkbox"][disabled] + label::after{
		color: $gray;
	}

	input[type="checkbox"][disabled] + label::before{
		border-color: $gray;
	}

	/*Adding focus styles on the outer-box of the fake checkbox*/
	&:hover {
		&label::before {
			outline: 1px solid $wim-text-purple;
		}
	}
}

@mixin hideScrollbar {
	&::-webkit-scrollbar{
		width: 0 !important;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
}

/** Watson gradient background color */
@mixin watsonBackgroundColor {
	background: $watson-bg-default;
	background-image: linear-gradient(-50deg, $watson-bg-start 0%, $watson-bg-end 100%);
}

/** add a bullet using ::before ::after content
	e.g.) 
	 h5 {
			&::before {
				@include bulletBeforeText($wim-green, 16px, 2px); // color, size, paddingRight
		}
*/
@mixin bulletBeforeText($color: $wim-green, $size: 16px, $paddingRight: 7px) {
	content: "\2022";
	color: $color;
	font-size: px2rem($size);
	padding-right: $paddingRight;
}

/** */
@mixin ai-draw-label {
	@include gotham(medium);
	text-transform: capitalize;
	display: inline-block;
	font-weight: 500;
	
	&::before {
		@include bulletBeforeText(transparent, 16px, 4px); // color, size, paddingRight
	}

	&.difficult, &.difficult::before {
		@extend .difficult-color;
	}

	&.favourable, &.favourable::before  {
		@extend .favourable-color;
	}

	&.neutral, &.neutral::before  {
		@extend .neutral-color;
	}
}