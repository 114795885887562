.wimChanSpace {
    position: relative;
    //top: 47px;
    width: 100%;
}

.wimChanSpaceCont {
    width: 100%;
    height: 0px;
    padding-bottom: 56.25%;
    position: relative;
    //background-color: blue;
}

.live-video-nav {
    margin: 10px 0px 20px 0px;
    color: $gray_9b;

    .channel-data-toggle {
        @include gotham(medium);
        font-size: 18px;
		display: flex;
		flex-flow: wrap;

		a {
			flex: 0 1 0;
		}

        span {
			cursor: pointer;
			color: $gray_9b;

            &.toggle-selected {
                color: $wim-green;
				white-space: nowrap;
            }

			&.toggle {
				white-space: nowrap;
			}

            &.line {
                margin: 0 10px;
                cursor: default;

            }
			&.line:last-of-type {
				display: none;
			}
        }

        
    }
    
}

.live-video-stub {
	width: 100%;

	.live-video-stubtext {
		position: relative;
		width: 100%;
		height: 0px;
		padding-bottom: 56.25%;
		background-image: url(/assets/images/video/stubBackground.jpg);
		background-size: cover;
		color: #fff;

		&--wrap {
			margin: auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			width: 100%;
			position: absolute;
			background-color: rgba(0, 0, 0, 0.4);
		}

		&--title {
			@include gotham(medium);
			text-transform: uppercase;
			text-align: center;
			width: 80%;
			margin-left: auto;
			margin-right: auto;
			font-size: 24px;
			letter-spacing: 4px;

			@include breakpoint(tablet) {
				font-size: 30px;
			}
		}

		&--msg {
			@include gotham(medium);
			text-transform: uppercase;
			text-align: center;
			width: 80%;
			margin: 8px auto;
			display: block;
			font-size: 10px;
			line-height: normal;
			letter-spacing: normal;

			@include breakpoint(medmobile) {
				font-size: 12px;
				line-height: 18px;
				letter-spacing: 1.5px;
			}

			@include breakpoint(tablet) {
				font-size: 18px;
				line-height: 32px;
				letter-spacing: 1.5px;
			}
		}

		.line {
			width: 80%;
			margin-top: 2px;
			margin-bottom: 2px;
		}

		.wim-icon-video {
			margin-bottom: 0px;
			position: relative;
			left: 50%;
			font-size: 32px;
			margin-left: -16px;
		}


		@include breakpoint(tablet) {
			.line {
				margin-top: 8px;
				margin-bottom: 8px;
			}

			.wim-icon-video {
				font-size: 42px;
				margin-left: -22px;
			}
		}
	}
}

.espn-holder {
	width: 100%;
	position: relative;

	.espn-frame {
		width: 100%;
		padding-bottom: 56.25%;

		iframe {
			height: 100%;
			width: 100%;
			position: absolute;
		}
	}

	.espn-frame-link {
		width: 100%;
		text-align: center;
		margin-top: 20px;

	}
}

.practice-holder {
	width: 100%;
	position: relative;

	.practice-frame {
		width: 100%;
		padding-bottom: 56.25%;

		iframe {
			height: 100%;
			width: 100%;
			position: absolute;
		}
	}

	.practice-frame-link {
		width: 100%;
		text-align: center;
		margin-top: 20px;

	}
}


.bbc-frame {
    width: 100%;
    
    .embed-container { 
        position: relative; 
        padding-bottom: 56.25%; 
        height: 0; 
        overflow: hidden; 
        max-width: 100%; 
    } 
    .embed-container iframe, .embed-container object, .embed-container embed { 
        position: absolute; 
        top: 0; 
        left: 0; 
        width: 100%; 
        height: 100%; 
    }
}

// Replay selector dropdown override
.replay-selector {
	margin: 0 auto;
	width: 100%;

	@include breakpoint(tablet) {
		flex-grow: 1;
		width: auto;
	}

	.news-landing-time-selector {
		border-bottom: none;
	}

	.select-menu {
		max-width: none;
	}

	.select-menu .title { 
		@include breakpoint(tablet) {
			text-align: right;
		}
	}
	.animation-container {
		overflow-y: scroll;
		@include breakpoint(tablet) {
			margin-left: 0;
			right: 0;
			-webkit-transform: none;
			transform: none;
			.select-menu-dropdown {
				transform: translate(0%, -100%) !important;
				&.show {
					transform: translate(0%, 0%) !important;
				}
			}
		}
	}
	.select-menu-dropdown {
		@include breakpoint(tablet) {
			margin-left: 0;
			right: 0;
			transform: none;
		}
	}
}