.news-landing-filters{
    margin: 12px auto;

    .filter-buttons{
        width: 760px;
        display: flex;
        margin: auto;
        justify-content: center;

        button, a{
            flex: 0 0 14.28%;
            text-align: center;
            opacity: .45;
            color: $wim-green;
            background-color: $white;
            margin-top: 5px;
            border: none;
            cursor: pointer;
        }

        i{
            font-size: 28px;
            text-align: center;
        }

        .filter-text{
            font-size: 16px; // font size 16 not 14 to meet ADA compliance 
            text-align: center;
            margin-top: 10px;
            @include gotham(medium);    
        }

        .selected{
            opacity: 1;
        }

        a:hover, a:active, a:visited:hover, a:visited:active,
            button:hover, button:active, button:visited:hover, button:visited:active {
            text-decoration: none;

            .filter-text {
                text-decoration: underline;
            }
        }
    }

    .filter-dropdown {
        display: none;
    }

    @include breakpoint(desktop,max){
        width: 285px;
        display: flex;
        justify-content: center;

        .filter-buttons{
            display: none;
        }

        .filter-dropdown {
            display: flex;
        }

    }

}


