/** global-footer styles **/


.footer-global {
	bottom:0;
	z-index: 1;
	display: block;
	position: relative;
    width: 100%;
    height: 100%;
    padding: $footerMargin 0 0 0;
    text-align: center;
    background-color: white;

    .footer-flex{
        display: flex;
        flex-wrap: wrap;
        height: $footerHeightLarge;
        justify-content: center;
        align-items: center;
        padding: $footerPading 0 $footerPading 0;
        border-top: 1px solid $lines-gray;

        @include breakpoint(tablet){
            justify-content: space-between;
            height: $footerHeightMed;
        }
        @include breakpoint(desktop){
            height: $footerHeightSmall;
        }
    }

	&-social {
        flex:0 0 auto;
        width: 100%;

        &-wrapper {
            width: 325px;
            margin: 0 auto;
        }

        @include breakpoint(tablet){
            width: unset;
            margin-left: 10px;
        }
        @include breakpoint(desktop){
            //width: 34%;
        }

		a {
            text-decoration: none;
		}
		i {
			color:black;
            font-size: 22px;
            margin-right:12px;
        }


		img {
			padding-right: 5px;
        }
    }

	&-links {
        flex:0 0 auto;
        width: 100%;
		font-size: 10px;
        text-transform: uppercase;

		a {
			padding-right: 10px;
			text-decoration: none;
        }

		img {
			vertical-align: middle;
			width: 25px;
		}

        @include breakpoint(tablet){
            width: unset;
            font-size: 12px;
            margin-right: 10px;

            a {
                vertical-align: bottom;
            }
        }
        @include breakpoint(desktop){
             margin-right: 0px;
        }
    }

	&-copyright {
        flex: 0 0 auto;
        width: 100%;
		font-size: 10px;
        color: $wim-text-purple;

        @include breakpoint(tablet){
            width: 100%;
        }
        @include breakpoint(desktop){
            width: unset;
            margin-right: 20px;
        }
    }

    .flag {
        img {
            margin-left:3px;
            vertical-align: middle;
            width: 25px;
            border:1px solid white;
            padding:0;
        }
    }

    &.hide {
        display: none;
    }
}

.footer-global-mobile-web {
    padding-bottom: 64px; // Add padding to the bottom of the body to accommodate the fixed MobileWebFooter
    @include breakpoint(tablet) {
        padding-bottom: 0px;
    }
}
