/** related-content styles **/
.latest-content {
    display:flex;
    flex-wrap:wrap;
    .news-tile{
        padding:0px 6px;
        margin:0;
        .new-tag,.type-tag{
            display:none;
        }
        .news-title{
            margin-bottom:15px;
        }
    }
    &.one-col {
        .news-tile {
            width:100%;
        }
    }
    &.two-col {
        .news-tile {
            width:50%;
        }
        @include breakpoint(tablet,max){
            .news-tile{
                width:100%
            }
        }
    }
    &.three-col {
        .news-tile {
            width:33%;
        }
        @include breakpoint(desktop,max){
            .news-tile{
                width:50%
            }
        }
        @include breakpoint(tablet,max){
            .news-tile{
                width:100%
            }
        }
    }
    &.four-col {
        .news-tile {
            width:25%;
        }
        @include breakpoint(desktop,max){
            .news-tile{
                width:50%
            }
        }
        @include breakpoint(tablet,max){
            .news-tile{
                width:100%
            }
        }
    }
}