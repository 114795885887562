.page {
    .wrapper .content-main {
        .player-data-toggle {
            width: 370px;
            text-transform: uppercase;
            text-align: center;
            color: $gray-9b;
            margin: 0 auto 20px;
            span {
                cursor: pointer;
                &.show {
                    color: $white;
                }
            }
        }
        .player-info {
            .profile-gallery-link {
                position: relative;
                color: $wim-green;
                margin-top: 20px;
                margin-bottom: 20px;
                a {
                    margin-right: 5px;
                }
                i.wim-icon-gallery {
                    position: absolute;
                    top: 10%;
                }
            }
        }
    }
}