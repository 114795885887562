/* stage items dimensions */
$small-si-width: 320px;
$small_si_height: 225px;

.slamtracker-stage {
  // display: flex;
  // flex-direction: column-reverse;
  // margin-top: 2px;
  // padding-top: 10px;
  margin: 10px 8px 0px;


  .accordionWrap, .game-info-container {
    display: flex;
    flex-direction: column-reverse;
  }

	.Collapsible {
    position: relative;

		&.opened_set {
			flex: 0 1 100%;
			position: relative;
      transform: rotateX(180deg);
      

			.Collapsible__contentOuter {
				flex: 1 0 0px;
				align-self: auto;
				overflow-x: hidden !important;
        
			}
      .triggerOpen, .stage-inner {
        transform: rotateX(180deg);
      }
			.Collapsible {
				margin-bottom:0px;

				&:after {
					content: "";
					width: calc(100% - 18px);
					margin: 0px auto;
					display:block;
				}

				.Collapsible__trigger {
					height:37px;
					line-height: 37px;
					padding:0px;
				}
			}
		}
    &.opened_game {
      width: 100%;
      transform: rotateX(180deg);
      .triggerOpen-game, .stage-game-inner {
        transform: rotateX(180deg);
      }
      .game { 
        display: flex;
        flex-direction: column-reverse;
      }
    }
    &.closed_game {
      width: 100%;
      .game { 
        display: flex;
        flex-direction: column-reverse;
        transition: transform 150ms;
      }
    }

		.Collapsible__trigger {
      // display: inline-block;
      display: flex;
      justify-content: center;
			flex: 0 0 auto;
			align-self: auto;
			border-bottom:solid 1px #BBBBBB;
			color: #333333;
			cursor: pointer;
			padding: 8px;
			width: 100%;
			text-align: center;
			@include gotham(medium);
			font-size:px2rem(14);
      line-height: 18px;
			text-transform: uppercase;
			outline: none;
	
			&:before {
				display: none;
				content: '';
			}
	
			&:after {
				border: solid #BBBBBB;
				border-width: 0 2px 2px 0;
				display: inline-block;
				padding: 4px;
	
				position: absolute;
				right: 12px;
				top: 8px;
		
				transition: transform 150ms;
				transform: rotate(45deg);
				content: ' ';
			}
	
			&.triggerOpen {
        background-color: #54008B;
        color: #FFFFFF;
        height: 35px;
				&:after {
          top: 12px;
					transform: rotate(225deg);
          color: #FFFFFF;
				}
			}

      &.triggerOpen-game {
        &:after {
          top: 12px;
					transform: rotate(225deg);
          color: #FFFFFF;
				}
      }
		}

    .Collapsible__contentInner {
			padding-left: 0px;
			padding-right: 0px;
      
			.Collapsible__point {
				font-size: px2rem(13);
				color: white;
				padding: 8px 5px 8px 10px;
				cursor: pointer;
				display: flex;
		
				.point {
					font-weight: bold;
					flex: 0 0 45px;
					margin: 0 5px 0 0;
				}
		
				.border {
					height: 1px;
					flex: 0 0 100%px;
					background-color: black;
				}
			}
		}
    .summary-wrap {
      display: flex;
      justify-content: center;
      max-width: calc(100% - 56px);
      .summaryName {
        overflow: hidden;
        flex: 0 1 auto;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 3px;
        &.doubles{
          width: calc(100% - 110px);
        }
      }
    }
	}

  .point-commentary {
    &.in-progress {
      border-left: 3px solid #54008B;
      background-color: rgba(245, 245, 245, 1);
    }
    @include gotham(medium);
    display: flex;
    font-size: px2rem(12);
    margin: 5px 0;
    align-items: center;
    .pointScore{
      margin-right: 8px;
      margin-left: 3px;
      max-width: 50px;
      width: 100%;
      // line-height: 18px;
    }
    .pointSentence{
      @include gotham-book;
    }
  }
}
