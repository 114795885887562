.role-nav{
    height: 42px;
    border-bottom: 1px solid $gray-light;
    opacity: 1;
    transition: opacity 0.5s;
    &.scrolled{
        height: 0px;
        opacity: 0;
    }
    .roleNav-carousel{
        .slick-slide{ 
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            a{
                @include gotham(medium);
                font-size: 14px;
                text-transform: uppercase;
                margin-right: 35px;
                margin-left: 5px;
            }
        }
    }
}