.video-page-section-header{
	display: flex;
    justify-content: space-between;

    .header-title{
		flex: 0 1 60%;
		position: relative;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
    }

    .header-select{
		flex: 1 0 40%;
		position: relative;
		text-align: right;
		bottom: 0px;
		margin-top: 1px;
		white-space: nowrap;
		margin-left: 10px;
    }
}