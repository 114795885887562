//
// Variables
//
$root-font: 16px;

$maxWidth: 1964px;

//text dec
$textShadow: 0px 0px 10px rgba(0,0,0,.7);

// footer values
$footerHeightLarge: 130px;
$footerHeightMed: 130px;
$footerHeightSmall: 60px;
$footerPading: 10px;
$footerMargin: 0px;
// $footerStripes: 2px;
$footerStripes: 0;

// header
// heights - height + stripes height + padding (14px)
$headerHeightSmall: 73px;
$headerHeightLarge: 106px;

// sidebar
$sidebarWidth: 374px;
$slamtrackerContentWidth: 373px;
$slamtrackerPanelHeader: 62px;

//column break points
$column-break-1: 768px;
$column-break-2: 1024px;
$column-break-page-1: 768px;
$column-break-page-2: 1024px;

// layout/columns
$item-v-margin: 7px;
$item-h-margin: 7px;

$page-margin-small: 15px - $item-h-margin; 
$page-margin-med: 23px - $item-h-margin; 
$page-margin-large: 45px - $item-h-margin; 

//news layout
$news-font-size: 18px; 
$news-text-line-height: 28px;
$news-font-size-small: 16px; 
$news-text-line-height-small: 18px;
$contentHeaderAspect: .532;

// landing pages
$landingHeaderAspect: .371;

//video player
$playerWidth: 94%;
$playerLeft: 3%;

//columns test bg
$test-light-yellow: rgba(255, 255, 224, .4);
$test-light-blue: rgba(173, 216, 230, .4);
$test-light-gray: rgba(211, 211, 211, .4);
$test-light-green: rgba(144, 238, 144, .4);

$contentAreaMin: $footerHeightMed + $footerStripes + $footerMargin + $headerHeightLarge;

// Path to the Final 
$pathToTheFinalTopHeightLarge: $headerHeightLarge + 30px + 197px;

// Grid container max sizes
$container-max-widths: (
    sm: 768px,
    md: 1024px,
    lg: 1440px,
    xl: 1600px
);

$spacer: 16px;
$spacers: (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    6: ($spacer * 3.5),
    7: ($spacer * 3.75),
    8: ($spacer * 4),
    9: ($spacer * 5),
    10: ($spacer * 8)
);

// Theme colors
$theme-colors: (
    primary: $black,
    secondary: $gray-600,
    success: $blue,
    info: $cyan,
    warning: $yellow,
    danger: $red,
    light: $gray-100,
    dark: $gray-800,
    blue: $blue,
    red: $red,
    white: $white,
    black: $black
);

// Font directory
$font-dir: "/assets/fonts/";

@mixin wim-icon($ipkg) {
    font-family       : $ipkg !important;
    font-smoothing    : antialiased;
    font-style        : normal;
    font-weight       : normal;
    font-variant      : normal;
    text-transform    : none;
    speak             : none;
    line-height       : 1;
}

// Icons
$icons: (
    "wim-icon-settings": "\e968",
    "wim-icon-generic": "\e957",
    "wim-icon-queue": "\e964",
    "wim-icon-tickets": "\e965",
    "wim-icon-onlineShop": "\e95a",
    "wim-icon-genericTennisIcon": "\e963",
    "wim-icon-videoContent": "\e966",
    "wim-icon-newsContent": "\e959",
    "wim-icon-gaming": "\e953",
    "wim-icon-museumYearRoundVisits": "\e958",
    "wim-icon-foundation": "\e967",
    "wim-icon-wimbledonParkProject": "\e951",
    "wim-icon-hospitality": "\e956",
    "wim-icon-debentures": "\e950",
    "wim-icon-grassCourtSeason": "\e954",
    "wim-icon-facebook": "\e905",
    "wim-icon-twitter": "\e913",
    "wim-wim-icon-pinterest": "\e90e",
    "wim-icon-instagram": "\e90b",
    "wim-icon-whatsapp": "\e918",
    "wim-icon-youtube": "\e919",
    "wim-icon-googleplus": "\e91a",
    "wim-icon-wechat": "\e900",
    "wim-icon-weibo": "\e901",
    "wim-icon-home": "\e90a",
    "wim-icon-menu": "\e90c",
    "wim-icon-hamburger": "\e909",
    "wim-icon-rss": "\e910",
    "wim-icon-gear": "\e902",
    "wim-icon-search": "\e912",
    "wim-icon-close": "\e903",
    "wim-icon-check": "\e904",
    "wim-icon-arrow": "\e906",
    "wim-icon-pin": "\e907",
    "wim-icon-favStar": "\e908",
    "wim-icon-favStarSolid": "\e90d",
    "wim-icon-ball": "\e90f",
    "wim-icon-ballSolid": "\e911",
    "wim-icon-person": "\e914",
    "wim-icon-personSolid": "\e915",
    "wim-icon-userAvatar": "\e94f",
    "wim-icon-scores": "\e916",
    "wim-icon-news": "\e917",
    "wim-icon-gallery": "\e91b",
    "wim-icon-breaking": "\e91c",
    "wim-icon-press": "\e91d",
    "wim-icon-all": "\e91e",
    "wim-icon-quote": "\e91f",
    "wim-icon-quotation-marks": "\e93b",
    "wim-icon-textLines": "\e920",
    "wim-icon-updates": "\e921",
    "wim-icon-radio": "\e922",
    "wim-icon-video": "\e923",
    "wim-icon-videoPlay": "\e924",
    "wim-icon-down-arrow": "\e925",
    "wim-icon-up-arrow": "\e926",
    "wim-icon-share": "\e927",
    "wim-icon-right-arrow":"\e929",
    "wim-icon-left-arrow":"\e928",
    "wim-icon-tennis-rackets":"\e92a",
    "wim-icon-retweets":"\e92b",
    "wim-icon-replies":"\e92c",
    "wim-icon-heart":"\e92d",
    "wim-icon-print":"\e92e",
    "wim-icon-likes":"\e92f",
    "wim-icon-fb-shares":"\e930",
    "wim-icon-pdf":"\e931",
    "wim-icon-myw":"\e932",
    "wim-icon-pause":"\e934",
    "wim-icon-thumb-up-blank":"\e935",
    "wim-icon-thumb-up-solid":"\e936",
    "wim-icon-gear-solid": "\e940",
    "wim-icon-increase":"\e939",
    "wim-icon-reduce":"\e93a",
    "wim-icon-dash":"\e937",
    "wim-icon-playArrow-solid":"\e924",
    "wim-icon-playArrow-blank":"\e93d",
    "wim-icon-microphone":"\e938",
    "wim-icon-stats":"\e93c",
    "wim-icon-info":"\e93e",
    "wim-icon-mycontent": "\e93f",
    "wim-icon-triangle-down": "\e941",
    "wim-icon-triangle-up": "\e942",
    "wim-icon-link-out": "\e943",
    "wim-icon-microphone-solid":"\e946",
    "wim-icon-wf-like":"\e94c",
    "wim-icon-wf-head-to-head":"\e94b",
    "wim-icon-wf-grass":"\e94a",
    "wim-icon-wf-court":"\e949",
    "wim-icon-wf-checkmark":"\e948",
    "wim-icon-wf-ball":"\e947",
    "wim-icon-wf-arrow-up":"\e945",
    "wim-icon-wf-arrow-out":"\e944",
    "wim-icon-help": "\e94d",
    "wim-icon-x": "\e94e",
    "wim-icon-arrow-right": "\e969",
    "wim-icon-queue-guide": "\e96b",
    "wim-icon-watsonx": "\e96a",
);