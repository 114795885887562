.page-nav-container {
	margin-bottom: 14px;
	.nav-container {

		margin-top: 10px;

		border-bottom: 1px solid $gray_bb;
		&.white-text{
			border-bottom: none;
		}
		margin-top: 0;
	
		.select-menu {
			display: none;

			@media only screen and (max-width: 970px) {				
				&.no-dd{
					display: none;
				}
				display: block;
			}
		}

		.page-nav-list {
			@media only screen and (max-width: 970px) {				
				&.no-dd{
					display: flex;
				}
				display: none;
			}

			&.white-text{
				.divider:after{
					background: $lines-gray;
				}
			}

			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			list-style: none;
			/* border-bottom: 1px solid #BBBBBB; */
			margin-bottom: 0px;
			width: 100%;
			padding: 0px;;

			margin: auto;
			margin-top: 0px;

			li.page-nav-items {
				&.white-text{
					color: $white;
					font-weight: normal;
					a{
						color: $white;
						font-weight: normal;
					}
				}
				&.live-indicator{
					margin-left: 38px;
					&::before{
						background: rgba(255, 255, 255, 0.25);
						content: 'LIVE';
						display: inline-block;
						position: absolute;
						top: 2.5px;
						left: -36px;
						font-size: 9px;
						letter-spacing: 1px;
						color: $wim-black;
						padding: 2px 2px 1px 2px;
						line-height: 8.5px;
						@include gotham(medium);
						font-weight: 500;
						@media only screen and (min-width: 1200px) { 
							padding: 2px 2px 2px 3px;
						}
					}
					&.is-live{
						&::before{
							background: $live-green;
						}
					}
				}
				cursor: pointer;
				color: $wim-green;
				font-size: 14px;
				font-weight: bold;
				padding-bottom: 4px;
				position: relative;
				@include gotham-book();
				white-space: nowrap;
				text-transform: uppercase;
				margin-top: 10px;

				&.active {
					&.white-text{
						border-bottom: 2px solid $white;
					}
					border-bottom: 3px solid #00703C;
				}
				@media only screen and (min-width: 1200px) { 
					font-size: 16px;
					&.smaller-text {
						font-size: 16px;
					}
				}
			}
			li.divider {
				position: relative;
				padding: 0px 20px 0px 20px;
				margin-top: 10px;

				&:after {
					position: absolute;
					top: 0;
					height: 20px;
					width: 1px;
					content: '';
					background: grey;
				}
				&:last-child {
					display: none;
				}
			}

			a:hover {
				text-decoration: none;
			    padding-bottom: 0px;
				border-bottom-style: solid;
				border-bottom-width: 1.5px;
				width: fit-content;
			}
		}

		/* override SelectMenu style for */
		.select-menu {
			max-width: 350px;
			margin: auto;
		}
	}
}

.tab-navigation {
	margin-top: -7px;
}