.section-header-bar-wrapper {
	display: flex;
	justify-content: left;
	width: 100%;
	background-color: $wim-green;
	@include gotham(medium);
	font-size: px2rem(12px);
	text-transform: uppercase;
	color: $white;
	padding: 16px 16px;
	letter-spacing: 1px;
}
