.homepage-news{
    &.image-background {
        &.fullscreen {
            .standard-wrapper {
                .image-wrapper {
                    width: 100%;
                    &:after {
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        content: '';                        
                        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4) 40%, transparent);
                    }
                }
            }
        }
        @include breakpoint(tablet) {
            &.right {
                .standard-wrapper {
                    .image-wrapper {
                        &:after {
                            display: block;
                            background-image: linear-gradient(to left, rgba(0, 0, 0, 0.4) 10%, transparent);
                        }
                    }
                }
            }
            &.left {
                .standard-wrapper {
                    .image-wrapper {
                        &:after {
                            display: block;
                            background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4) 10%, transparent);
                        }
                    }
                }
            }
        }
    }
    .read-more{
        display:inline-block;

        @include breakpoint(tablet,max){
            display:none;
        }
    }
}