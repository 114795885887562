// Styles
html {
	scroll-behavior: smooth;
}

// Variables
@import '_scss/colors';
@import '_scss/variables';

// General/resuable UI components
@import '_scss/common/_ui-components';

//Third-party
//React Carousel Image Gallery
@import 'node_modules/react-image-gallery/styles/scss/image-gallery.scss';

/** React RC-Slider styles - converted from css to scss */
@import '_scss/rc-slider.scss';

@import '_scss/root-font-size';

//slick
@import '_scss/slick/slick';
@import '_scss/slick/slick-theme';

// Placeholder mixin
@import '_scss/mixins/placeholder';

// Icon mixin
@import '_scss/mixins/icon';

// breakpoint mixin
@import '_scss/mixins/breakpoints';

// retina mixin
@import '_scss/mixins/retina';

// other mixin
@import '_scss/mixins/other';

@import '_scss/mixins/px2rem';

// Typography
@import '_scss/type';

// Icons
@import '_scss/icons';

//Favoriting styles
@import '_scss/commonui/favourite_star';

///DividerHeader styles
@import '_scss/commonui/divider-header';

//Accordion styles
@import '_scss/catalyst/accordion';

//Confirmation Modal Styles
@import '_scss/catalyst/confirmation_modal';

// Page layout
@import '_scss/layout';

// Column layout
@import '_scss/columns';

// global-header
@import '_scss/global/global-header';
// header role nav
@import '_scss/global/role-nav';

// global-footer
@import '_scss/global/global-footer';

// sidepanel
@import '_scss/global/sidepanel';

// slamtracker panel
@import '_scss/global/slamtracker_panel';

// stripes
@import '_scss/global/stripes';

// stubs
@import '_scss/global/stubs';

// table
@import '_scss/global/table';

//carousel
@import '_scss/global/carousel';
//generic carousel 2024
@import '_scss/global/generic-carousel';

// share menus
@import '_scss/global/share-menus';

// loading indicator/spinner
@import '_scss/global/loading-indicator';

// right side status panel, small header panel
@import '_scss/global/status-panel';

// libe panel part of status panel
@import '_scss/global/live-panel';

// data tabs
@import '_scss/global/data-tabs';

//label
@import '_scss/form/label';

//select
@import '_scss/form/select';

//select
@import '_scss/form/input-text';

//textarea
@import '_scss/form/textarea';

//checkbox
@import '_scss/form/checkbox';

//radio button
@import '_scss/form/radio-button';

//form component wrapper
@import '_scss/form/form-comp-wrapper';

// mainnav
@import '_scss/navigation/mainnav';

//feature-story
@import '_scss/navigation/feature-story';

// atoznav
@import '_scss/navigation/atoznav';

// atoznav
@import '_scss/navigation/select-menu';

// daynav
@import '_scss/navigation/daynav';

// pagenav
@import '_scss/navigation/page-nav';

// button component
@import '_scss/page-components/buttons';

// Section Header Bar
@import '_scss/page-components/section-header-bar';

//Home Page
@import '_scss/home-page/header';
@import '_scss/content_home';
@import '_scss/home-page/favorites';
@import '_scss/home-page/debentures';
@import '_scss/page-components/home-services-panel';
@import '_scss/home-page/featured-news';
@import '_scss/home-page/quick-links';

//Shop slider
@import '_scss/global/shop-slider';

// General content (coded vs cms controlled)
@import '_scss/content_general';

// News content
@import '_scss/content_news';

// Weather content
@import '_scss/content_weather';

// Landing content
@import '_scss/content_landing';

// Jobs content
@import '_scss/content_jobs';

// Player profile
@import '_scss/content_watch-and-read';

// Player Stats
@import '_scss/content_player_stats';

// Results page, Mip page
@import '_scss/content_scores-page';

// scoringtable
@import '_scss/scoring/table';

// logotitle
@import '_scss/global/logotitle';

// myWimbledon
@import '_scss/page-components/my-wimbledon';
@import '_scss/page-components/my-wimbledon-unsubscribe';

// countryleaderboard
@import '_scss/page-components/country-leaderboard';

// playerindex
@import '_scss/content_player-index';

// player page
@import '_scss/content_player-page';

// carouselpage
@import '_scss/page-components/carousel-page';

//country players page
@import '_scss/page-components/countryplayers-page';

//Live Video page
@import '_scss/content_live-video';

//Live Video page
@import '_scss/content_hill-video';

//Video Player
@import '_scss/video/video-player';

//Wimbledon Channel
@import '_scss/video/wimbledon-channel';

//Centre Court Channel
@import '_scss/video/centre-court-channel';

//Video Player Next Up
@import '_scss/video/video-nextup';

//iframe
@import '_scss/content_iframe';

// pdf-reader
@import '_scss/cmscomponents/pdf-reader';

// featured-players
@import '_scss/featured-players';

//myw promo
@import '_scss/cmscomponents/myw-promo';

//Pull-Quote
@import '_scss/cmscomponents/pull-quote';

//Key-Stat
@import '_scss/cmscomponents/key-stat';

//match-stats
@import '_scss/cmscomponents/match-stats';

// section-divider
@import '_scss/cmscomponents/section-divider';

//Debentures CMS Component
@import '_scss/cmscomponents/debentures';

//Player-Stat
@import '_scss/cmscomponents/event-stats';

// Mobile Web Footer
@import '_scss/cmscomponents/_mobile-web-footer';

//News-Tile
@import '_scss/news/news-tile';

//news-landing-filters
@import '_scss/news/news-landing-filters';

//news-landing-time-selector
@import '_scss/news/news-landing-time-selector';

// match-box
@import '_scss/scoring/match-box';

// related-content
@import '_scss/cmscomponents/latest-content';

//photo
@import '_scss/cmscomponents/photo';

//video
@import '_scss/cmscomponents/video-comp';

//content-tile
@import '_scss/cmscomponents/content-tile';

//related-content-tile
@import '_scss/cmscomponents/related-content-tile';

//related-content
@import '_scss/cmscomponents/related-content';

//head-to-head
@import '_scss/cmscomponents/head-to-head';

//draws
@import '_scss/cmscomponents/draws';

//youtube
@import '_scss/cmscomponents/youtube';

// photo-gallery
@import '_scss/cmscomponents/gallery';

//homepage-element
@import '_scss/homepage-timeline/homepage-element';

//homepage-element-info
@import '_scss/homepage-timeline/homepage-element-info';

//homepage-news
@import '_scss/homepage-timeline/homepage-news';

//homepage-video
@import '_scss/homepage-timeline/homepage-video';

//homepage-text-promo
@import '_scss/homepage-timeline/homepage-text-promo';

//results
@import '_scss/scoring/scores-select-menu';

// scorebox-grid
@import '_scss/scoring/scorebox-grid';

//homepage-upcoming-match
@import '_scss/homepage-timeline/homepage-upcoming-match';

//homepage-breaking
@import '_scss/homepage-timeline/homepage-breaking';

//homepage-photo
@import '_scss/homepage-timeline/homepage-photo';

//homepage-photo-gallery
@import '_scss/homepage-timeline/homepage-gallery';

//homepage-video-gallery
@import '_scss/homepage-timeline/homepage-video-gallery';

//homepage-twitter
@import '_scss/homepage-timeline/homepage-social-media';

//homepage-results
@import '_scss/homepage-timeline/homepage-results';

//homepage-live
@import '_scss/homepage-timeline/homepage-live';

//homepage-upset
@import '_scss/homepage-timeline/homepage-upset';

//homepage-photo-promo
@import '_scss/homepage-timeline/homepage-photo-promo';

//modal-content
@import '_scss/cmscomponents/modal-content';

//section-header
@import '_scss/page-components/section-header';

//watch-these-section-header
@import '_scss/page-components/watch-these-section-header';

//video-page-section-header
@import '_scss/page-components/video-page-section-header';

// player-image
@import '_scss/page-components/player-image';

// player-singles-results
@import '_scss/page-components/player-singles-results';

// player-doubles-results
@import '_scss/page-components/player-doubles-results';

// player-profile-score-table
@import '_scss/page-components/player-profile-score-table';

// player-general-info
@import '_scss/page-components/player-general-info';
@import '_scss/page-components/player-general-info-da';

// player-professional-info
@import '_scss/page-components/player-professional-info';
@import '_scss/page-components/player-extra-info';

// player-matchup-overview
@import '_scss/page-components/player-matchup-overview';

// player-competitor-list;
@import '_scss/player-competitor-list';

//content-link (tile)
@import '_scss/cmscomponents/content-link';

//article-author-byline
@import '_scss/news/article-author-byline';

//draws-page
@import '_scss/draws/draws-page';
@import '_scss/draws/path-to-the-final';
@import '_scss/draws/draw-analysis';

//draws-nav-base
@import '_scss/page-components/draws-nav-base';

//draws-your-players-button
@import '_scss/page-components/draws-your-players-button';

//draws-pdf-button
@import '_scss/page-components/draws-pdf-button';

//news-list-tile
@import '_scss/news/news-list-tile';

//photo-promo
@import '_scss/cmscomponents/photo-promo';

//facts
@import '_scss/cmscomponents/facts';

//sitemap
@import '_scss/page-components/sitemap';

//court-title-divider
@import '_scss/scoring/court-title-divider';

//order-of-play-heading
@import '_scss/scoring/order-of-play-heading';

// schedule
@import '_scss/scoring/schedule';

//emergency-message
@import '_scss/home-page/emergency-message';

//date divider
@import '_scss/home-page/date-divider';

//search-page
@import '_scss/search-page/search-page';

//search-box
@import '_scss/search-page/search-box';

//search-box-popout
@import '_scss/search-page/search-box-popout';

//category-question-list
@import '_scss/search-page/category-question-list';

//letter-section-header
@import '_scss/search-page/letter-section-header';

//search-result-item
@import '_scss/search-page/search-result-item';

//search-atoz-nav
@import '_scss/search-page/search-atoz-nav';

//match-box-draws
@import '_scss/scoring/match-box-draws';

//next-prev-back-bar
@import '_scss/page-components/next-prev-back-bar';

//privacy-content-header
@import '_scss/page-components/privacy-content-header';

//privacy-policy-nav
@import '_scss/page-components/privacy-policy-nav';

//privacy-content
@import '_scss/page-components/privacy-content';

//content-privacy-page
@import '_scss/content-privacy-page';

//newsletter-why-sign-up
@import '_scss/page-components/newsletter-why-sign-up';

//newsletter-form
@import '_scss/page-components/newsletter-form';

//content-newletter-page
@import '_scss/content-newsletter-page';

//contact-heading
@import '_scss/page-components/contact-header';

//contact-box
@import '_scss/page-components/contact-box';

//contact-form
@import '_scss/page-components/contact-form';

//contact-form-errors
@import '_scss/page-components/form-errors';

//content-contact-us-page
@import '_scss/content-contact-us-page';

//ballot pages
@import '_scss/ballot';

//competitor-select-menu
@import '_scss/page-components/competitor-select-menu';

//seatmap
@import '_scss/page-components/seat-map';

//view-from-your-seat
@import '_scss/page-components/view-from-your-seat';

//seat-map-heading
@import '_scss/page-components/seat-map-heading';

//draws-archive
@import '_scss/content-draws-archive';
@import '_scss/draws-archive/menu';

// draws-archive timeline
@import '_scss/draws-archive/timeline';

// draws-archive select white transparent
@import '_scss/draws-archive/select-white';

// favorites-toggle
@import '_scss/scoring/favorites-toggle';

//contact-form-errors
@import '_scss/page-components/form-errors';

//contact-form-error
@import '_scss/page-components/form-error';

//thank-you
@import '_scss/page-components/thank-you';

//newsletter-thank-you
@import '_scss/page-components/newsletter-thank-you';

//radio-station
@import '_scss/live-radio/radio-station';

//live-radio twitter-embed
@import '_scss/live-radio/twitter-embed';

//live-radio
@import '_scss/live-radio';

//order-of-play
@import '_scss/scoring/order-of-play';

// mobile-apps
@import '_scss/mobile-apps';
@import '_scss/mobile';
@import '_scss/mobile_also';
@import '_scss/mobile_carousel';

//player challenge page
@import '_scss/player-challenge';

//webviews
@import '_scss/webviews/schedule';
@import '_scss/webviews/visit';
@import '_scss/webviews/atoz';
@import '_scss/webviews/news';
@import '_scss/webviews/radio_webview';
@import '_scss/webviews/hill-vote';
@import '_scss/webviews/ai_catchup';
@import '_scss/webviews/player-general-info-webview';

//slamtracker
@import '_scss/slamtracker/slamtracker_helpers';
@import '_scss/slamtracker/stage_items';
@import '_scss/slamtracker/wimbledon_slamtracker';
@import '_scss/slamtracker/slamtracker_layout';
@import '_scss/slamtracker/slamtracker_common';
@import '_scss/slamtracker/slamtracker_headtohead';
@import '_scss/slamtracker/slamtracker_matchstats';
@import '_scss/slamtracker/slamtracker_toggle';
@import '_scss/slamtracker/slamtracker_header';
@import '_scss/slamtracker/slamtracker_visualisation';
@import '_scss/slamtracker/slamtracker_stage';
@import '_scss/slamtracker/slamtracker_ai_catch_up';

@import '_scss/chinese-scoreboard.scss';

// Collapsible
@import '_scss/collapsible';

// Collapsible (Plus/Minus Sign)
@import '_scss/cmscomponents/collapsible';
//
// Ballot Status Component
@import '_scss/cmscomponents/ballot-status';

// rolex-schedule-time
@import '_scss/rolex-schedule-time';

//Live Commentary
@import '_scss/content-live-commentary';
@import '_scss/live-commentary/commentary-time';

// Live Scores and Live Commentary Tabs
@import '_scss/page-components/live-tabs';

// Match experience nav
@import '_scss/page-components/match-experience-nav';

// Gigya screen styles
@import '_scss/gigya/gigya';
@import '_scss/gigya/screens';

// MyWimbledon profile pages
@import '_scss/mywimbledon/preferences';
@import '_scss/mywimbledon/mywimbledon-profile';
@import '_scss/mywimbledon/other';
@import '_scss/mywimbledon/debentures-tickets';
@import '_scss/mywimbledon/role-reg';
@import '_scss/mywimbledon/mywimbledon-shadow';

// react-datepicker styles
@import '_scss/datepicker';

// Media Form
@import '_scss/media-form';

//Match Archive
@import '_scss/matcharchive/matchArchiveLanding';
@import '_scss/matcharchive/matcharchive';
@import '_scss/matcharchive/pointNav';
@import '_scss/matcharchive/pointNavFilters';
@import '_scss/matcharchive/pointNavOptions';
@import '_scss/matcharchive/matchOverview';
@import '_scss/matcharchive/matchArchiveStats';

@import '_scss/carousel';
@import '_scss/matcharchive/roundNav';
@import '_scss/matcharchive/matchNav';

//debug info display
@import '_scss/global/debug';

//TGC
@import '_scss/tgc';

//autosuggest
@import '_scss/autosuggest';

//tickets
@import '_scss/tickets/tickets';
@import '_scss/tickets/ticket-actions-view';

//pde
@import '_scss/player-matches-widget';
@import '_scss/pde/general';
@import '_scss/pde/match-stats-video';

//power ranking
@import '_scss/power-ranking';

//insights
@import '_scss/insights/index';

//attempt at some generic styles
@import '_scss/common/general';
//Text in the header styles
@import '_scss/common/contents-in-header';
//test pages
@import '_scss/global/test-pages';

// simple text help modal - mainly used for innovation
@import '_scss/commonui/help-modal';

//content date
@import '_scss/commonui/content-date';

// match insights
@import '_scss/matchinsights/match-insights';
@import '_scss/matchinsights/match-insights-section-header';
@import '_scss/matchinsights/match-insights-modal';
@import '_scss/matchinsights/match-insights-overlay';
@import '_scss/matchinsights/match-insights-teaser';
@import '_scss/matchinsights/match-insights-power-ranking';
@import '_scss/matchinsights/match-insights-in-the-media';
@import '_scss/matchinsights/match-insights-by-the-number';
@import '_scss/matchinsights/likelihood-to-win-banner';
@import '_scss/matchinsights/match-insights-power-index-chart';
@import '_scss/matchinsights/win-factors-have-your-say';

//upcoming
@import '_scss/upcoming/upcoming';

// powerindex leaderboard
@import '_scss/powerindex-leaderboard';

// horizontal-menu-bar
@import '_scss/horizontal-menu-bar';

// webView tickets
@import '_scss/tickets/_ticket-stub';
@import '_scss/tickets/_ticket-actions-modal';

// Member pages
@import '_scss/member/address-book';
@import '_scss/member/remaining-bookings';
@import '_scss/member/court-availability';
@import '_scss/member/dining-reservation';

// Remaining-bookings component
@import '_scss/cmscomponents/remaining-bookings-component';

// web tickets
@import '_scss/tickets/_web-ticket-stub';

// app promo
@import '_scss/tickets/_ticketAppPromo';

// system error display
@import '_scss/_system-error';

// favourting
@import '_scss/common/_favourting';

// favourting
@import '_scss/common/promo_feature';

// interests
@import '_scss/common/onboarding/interests';

// draws
@import '_scss/common/onboarding/draws';

// smartbanner header/pageheader/backgroundImg
@import '_scss/global/smartbanner.scss';

@import '_scss/storyteller';

// player profile tabs
@import '_scss/status-tab';

@import '_scss/profile-tab';

@import '_scss/statistics-tab';

@import '_scss/player-media';

// styles for PlayerPage2024 > player-general-info
@import '_scss/page-components/player-page2024-general-info';

// styles for PlayerPage2024 > player-matchup-overview
@import '_scss/page-components/player-page2024-player-matchup-overview';

// ticket resale webview
@import '_scss/webviews/_ticket-resale-webview.scss';

// ai catch up
@import '_scss/aicatchup/index';
