.mi-power-ranking-wrapper {
    @extend %match-insights-content-padding;
    border-bottom: solid 1px $gray_9b;
}

/** match insights page **/
.mi-pr-player-info-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 404px;
    margin: auto;
    position: relative;
    // z-index: 901;

    @include breakpoint(tablet) {
        max-width: 710px;
    }

    @include breakpoint(desktop) {
        max-width: 966px;
    }

    .mi-pr-player-info {
        position: relative;
        text-align: center;

        @include breakpoint(desktop) {
            width: 316px;
        }
        @at-root .slamtrackerPanel #{&}{
            width: unset !important;
        }
    }
    
    .v{
        display: flex;
        flex-flow: column;
        padding: 0;
        position: relative;

        @include breakpoint(desktop) {
            width: auto;
        }

        @at-root .slamtrackerPanel #{&}{
            width: unset !important;
        }
    }
   
    .mi-pr-player-country-name {
        color: $wim-green;
        display: flex;
        font-size: px2rem(12);
        justify-content: center;
        padding-bottom: 30px;
        overflow: visible;

        @at-root .slamtrackerPanel #{&}{
            font-size: px2rem(12) !important;
            white-space: normal !important;
            margin-top: unset !important;
        }

        @include breakpoint(tablet) {
            font-size: px2rem(18);
            margin-top: 10px;
            white-space: nowrap;
            width: auto;
        }

        .mi-player-flag {
            img {
                height: 12px;

                @include breakpoint(tablet) { 
                    height: 26px;
                }
            }
        }
        
        .player-name {
            padding-left: 10px;
            width: 80px;
        }
    } // .mi-pr-player-country-name

    /* circular image override */
    img.player-image {
        width: 70px;
        height: 70px;

        @include breakpoint(tablet) {
            width: 150px;
            height: 150px;
        }

        @include breakpoint(desktop) {
            width: 238px;
            height: 238px;
        }

        /* add an outer ring to the player headshot */
        &.likelihood-flag {
            padding: 5px;
            border: 2px solid #FFF;
            border-radius: 50%;
            box-shadow: 0 0 0 3px $wim-green;
          }
    } // .img.player-image

    .mi-pr-chart {
        position: absolute;
        background-color: $white;
        border-radius: 50%;
        border: solid 2px $white;

        width: 60px;
        height: 60px;

        .ct-chart {
            position: absolute;

            top: 0px;
            left: 2px;

            width: 54px;
            height: 54px;
        }

        .mi-pr-chart-center {
            background-color: $powerrank-bar-bg;
            color: $black;

            top: 10px;
            left: 11px;
            width: 36px;
            height: 36px;

            border-radius: 50%;
            position: absolute;
            text-align: center;
            display:flex;
            justify-content: center;
            align-items: center;
    
            .num {
                @include gotham(medium);
                font-size: px2rem(16);
                line-height: 32px;
            }
    
            .unit {
                font-size: px2rem(12);
                line-height: 32px;
            }
        }
    } // .mi-pr-chart

    @include breakpoint(tablet) {
        .mi-pr-chart {
            border: solid 4px $white;
    
            width: 92px;
            height: 92px;
    
            .ct-chart {
    
                top: 2px;
                left: 2px;
    
                width: 80px;
                height: 80px;
            }

            .ct-chart-donut {
                width: 80px;
                height: 80px;
            }
    
            .mi-pr-chart-center {
    
                top: 13px;
                left: 13px;
                width:58px;
                height: 58px;
        
                .num {
                    font-size: px2rem(30);
                    line-height: 58px;
                }
        
                .unit {
                    font-size: px2rem(16);
                    line-height: 58px;
                }
            }
        }
    } // breakpoint(talet)
    

    /* favorite star override */
    .favorite {
        position: absolute;
        cursor: pointer;
        //display: none;
        z-index: 2;

        @include breakpoint(tablet) {
            display: block;
        }
        
        &:after {
            position: absolute;
            left: 0;
            content: "\2606";
            font-size: 23px;
            color: $wim-green;

            @at-root .slamtrackerPanel #{&}{
                font-size: 20px !important;
                top: -5px;
            }

            @include breakpoint(tablet) {
                font-size: 33px;
            }
        }
        
        &.favorited {
            &:after {
                content: "\2605";
                font-size: 23px;
                color: #F9E300;
                @at-root .slamtrackerPanel #{&}{
                    font-size: 23px !important;
                }
                @include breakpoint(tablet) {
                    font-size: 33px;
                }
            }
        }
    }

    /* player specific positioning where head shots are */
    .mi-pr-player-info {
        &.player1 {
            .favorite {
                left: -10px;
                top: -15px;

                @at-root .slamtrackerPanel #{&}{
                    left: -5px;
                    top: -15px;
                }
                @at-root .webview #{&}{
                    left: 0px;
                    top: -15px;
                }
            }
    
            /* chartist graph override */
            // .ct-series-a .ct-slice-donut {
            .stroke-color {
                stroke: $team1_color;

                .ct-slice-donut {
                    stroke-width: 4px !important;

                    @include breakpoint(tablet) {
                        stroke-width: 8px !important;
                    }
                }
            }
    
            .mi-pr-chart {
                top: 85%;
                left: 35%;

                @include breakpoint(tablet) {
                    top: 20%;
                    left: 70%;
                }
            }
        } // .player1
    
        &.player2 {
            .favorite {
                right: 15px;
                top: -15px;

                @include breakpoint(tablet) {
                    right: 8px;
                }

                @at-root .slamtrackerPanel #{&}{
                    right: 15px;
                    top: -15px;
                }
                @at-root .webview #{&}{
                    right: 23px;
                    top: -15px;

                    @include breakpoint(tablet) {
                        right: 33px;
                    }
                }
            }
    
            // .ct-series-a .ct-slice-donut {
            .stroke-color {
                stroke: $team2_color;

                .ct-slice-donut {
                    stroke-width: 4px !important;

                    @include breakpoint(tablet) {
                        stroke-width: 8px !important;
                    }
                }
            }
    
            .mi-pr-chart {
                top: 85%;
                left: 35%;

                @include breakpoint(tablet) {
                    top: 20%;
                    left: -10%;
                }
            }
        } // .player2
    } // .mi-pr-player-info  
} // .mi-pr-player-info-wrapper

.mi-pr-ranks-wrapper {
    background-color: #f1f1f1;
    border-radius:  7px;
    padding-top: 10px;
    margin-top: 10px;

    @include breakpoint(tablet) {
        background-color: $white;
        padding-top: 0;
        margin-top: 0;
    }

    a>.generic-button {
        
        &.rounded-corner {
            width: 100%;
        }
    }
}

.mi-pr-ranks {
    display: block;
    margin: 0px auto 10px auto;
    min-width: 205px;
    max-width: 100%;

    @include breakpoint(tablet) {
        margin: 0px auto 20px auto;
        max-width: 600px;
    }

    .ranking-row {
        display: flex;
        justify-content: space-between;

        .mobile-only {
            align-self: center;
        }

        .ranking-cell {
            display: table-cell;
            text-align: center;
            vertical-align: middle;
            width: 25%;
            @at-root .slamtrackerPanel #{&}{
                width: unset !important;
            }

            @include breakpoint(tablet) {
                /** subtract Power Index LB button */
                width: calc((100% - 315px) / 2);
            }
        }

        .player1-pr-rank, .player2-pr-rank  {
            @include gotham(normal);
            color: $team1_color;
            font-size: px2rem(40);

            @include breakpoint(tablet) {
                font-size: px2rem(50);
            }

            .player-name {
                display: block;
                color: $wim-green;
                font-size: px2rem(13);
            }
        }

        .player2-pr-rank {
            color: $team2_color;
        }

        .rank-label {
            @include gotham(medium);
            color: $wim-green;
            font-size: px2rem(14);
            text-align: center;
            text-transform: uppercase;
            width: 49%;
            align-items: center;
            align-self: center;
            white-space: nowrap;

            @include breakpoint(tablet) {
                margin-left: 30px;
                margin-right: 30px;
                width: auto;
            }
        }

        .atp-rank {
            @include gotham(medium);
            font-size: px2rem(24);
            color: $wim-black;

            @include breakpoint(tablet) {
                font-size: px2rem(25);
            }
        }
    }
} // mi-pr-ranks

/** Slamtracker page override 
    - Mobile size is the same as the standard MI page
    - above Tablet size, no head shots
    - hide player names and country flags
    - display player names above the likelihood to win number on desktop size
*/
.webview {
    .wrapper.slamtracker {
        .content-main {
            .mi-section {
                border-bottom:none;
                &.mi-footer {
                    display:none;
                }
            }
        }
    }
}

.slamtracker {
    .PowerIndexChart .probability .slamtracker-only {
        .player-name {
            white-space: normal;

            @include breakpoint(tablet, max) {
                display: none;
            }
        }
    }

    .mi-pr-ranks-wrapper {

        /** apply the same style as mobile size on Slamtracker */
        @include breakpoint(tablet) {
            background-color: #f1f1f1;
            border-radius:  7px;
            padding-top: 10px;
            margin-top: 10px;
        }
        .generic-button_wrapper.center {
            text-align: center;
        }

        .generic-button_wrapper>.generic-button, a>.generic-button {
            font-size:px2rem(12);
        }
    }

    .mi-pr-player-info-wrapper {
        justify-content: space-around;

        .mi-pr-player-info {
            width: 33%;
            z-index: 1;
        }

        img.player-image {
            width: 70px !important;
            height: 70px !important;
        }

        .mi-pr-player-country-name {
            padding-bottom: 0px;
        }

        .player-name {
            padding-left: 0;
            @include gotham(medium);
        }
        
        .favorite {
            // display:none;
            font-size: 20px !important;
        }
    }

    .mi-pr-ranks {
        display: block;
        margin: 0px auto !important;
        min-width: 205px;
        max-width: 100% !important;
    
        .ranking-row {
            width: 90%;
            margin: 0 auto;
            padding: 5px 0;

            .ranking-cell {
                display: table-cell;
                text-align: center;
                vertical-align: middle;
                width: unset !important;
            }
            .rank-label {
                margin-left: 0 !important;
                margin-right: 0 !important;
            }    
            .player1-pr-rank {
                @include gotham(medium);
                font-size: px2rem(48);
                color: $team1_color;
            }    
            .player2-pr-rank {
                @include gotham(medium);
                font-size: px2rem(48);
                color: $team2_color;
            }
        }
    }
    
    @include breakpoint(tablet, min, true) {
        .mi-pr-player-info-wrapper {
            // max-width: 310px;
            max-width: calc(100% - 20px);

            .mi-pr-player-info {
                // display: none;
            } // .mi-pr-player-info

            .v {
                width: 100%;
            }
        } // .mi-pr-player-info-wrapper

        .PowerIndexChart {
            .probability {
                width: calc((100% - 70px) /2);
            }

            .winnerCheck {
                &.team1 {
                    left: 16px;
                }

                &.team2 {
                    right: 16px;
                }
            }
        }

        .mi-pr-ranks {
            // margin: 0px auto 20px auto;
            min-width: 205px;
            max-width: 100%;

            .ranking-row {
                .ranking-cell {
                    width: 33%;
                }
        
                .rank-label {
                    font-size: px2rem(12);
                }
        
                .atp-rank {
                    font-size: px2rem(25);
                }
            }
        } // mi-pr-ranks
    } // breakpoint
} // .slamtracker

/** news article embedded MatchInsightsCard override */
.news {
    @include breakpoint(desktopSidePanel, max) {
        .embedded-wrapper {
            /** this is applied only for mobile size in embedded place,
            *    such as news article, and possibly when shared via twitter from client (not a user share)
            */
            // max-width: 434px;
            margin: auto;
            border: solid 1px #d1d1d1;
            border-top: none;
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;
            margin-top: -20px;
            padding-top: 30px;
            padding-right: 5px;
            padding-left: 5px;
            width: 100%;

            .generic-button_wrapper {
                margin-bottom: 4px;
            }
        }
    }

    .mi-pr-player-info-wrapper {
        @include breakpoint(desktopSidePanel) {
            max-width: 710px;
        }

        .mi-pr-player-info {
            @include breakpoint(desktopSidePanel) {
                width: auto;
            }
        }

        .v{
            @include breakpoint(desktopSidePanel) {
                width: auto;
            }
        }

        .mi-pr-player-country-name {
            padding-bottom: 20px;

            .mi-player-flag {
                img {
                    @include breakpoint(tablet) { 
                        height: 18px;
                    }
                }
            }

            .player-name {
                font-size: px2rem(12);
                padding-left: 5px;

                @include breakpoint(tablet) {
                    font-size: px2rem(14);
                }
            }
        }
        
        img.player-image {
            width: 70px;
            height: 70px;
            
            @include breakpoint(desktopSidePanel) {
                width: 150px;
                height: 150px;
            }
        } // .img.player-image
    }
} // .news


/** news article embedded MatchInsightsCard AND side panel opened override */
.expanded {
    .news {
        @include breakpoint(extraLargeDesktop, max) {
            .embedded-wrapper {
                /** this is applied only for mobile size in embedded place,
                *    such as news article, and possibly when shared via twitter from client (not a user share)
                *    when side panel is open and desktop size, render card as mobile layout
                */
                // max-width: 434px;
                margin: auto;
                border: solid 1px #d1d1d1;
                border-top: none;
                border-bottom-left-radius: 7px;
                border-bottom-right-radius: 7px;
                margin-top: -20px;
                padding-top: 30px;
                padding-right: 5px;
                padding-left: 5px;
                width: 100%;

                @include breakpoint(extraLargeDesktop) {
                    border: none;
                }
            }
        }

        .mi-pr-player-info-wrapper {
            
            img.player-image {
                @include breakpoint(extraLargeDesktop, max) {
                    width: 70px;
                    height: 70px;
                }
            } // .img.player-image
        }
        
    }
}