.pointnav-wrapper {
	@include breakpoint(desktop,max) {
		height:400px;
	}
}
.pointNav {
	height: 100%;
	//background-color: $accordionBg;
	//overflow: auto;
	//max-height: calc(100vh - 420px);
	// @include breakpoint(large){ bottom: $controlHeight;}

	// @include breakpoint(tablet) {
	// 	height:400px;
	// 	overflow:auto;
	// }
	// @include breakpoint(desktop) {
	// 	height:100%;
	// 	overflow:hidden;
	// }
	
	&.show {
		display: block;
	}
	&.hide {
		display: none;
	}
	
	span {
		position: relative;
    	display: block;
	}
	.instruction {
		color:#FFFFFF;
		background:rgba(255,255,255,0.2);
		height:31px;
		font-size: px2rem(12);
		display:flex;
		justify-content: center;
		align-items: center;
		cursor:pointer;
		&~.accordionWrap {
			height: calc(100% - 38px - 31px);

			@include breakpoint(tablet) {
				height: calc(100% - 38px - 31px - 4px);
			}

			@include breakpoint(desktop) {
				height: calc(100% - 38px - 31px - 4px);
			}
		}
	}

	.accordionWrap {
		height: calc(100% - 38px);
		//background-color: rgba(40, 40, 40, 0.9);
		display: flex;
		flex-direction: column;
		margin-top:2px;
		border:solid 1px $accordionWrapBorder;
		padding:2px 5px;
		overflow: hidden;

		@include breakpoint(tablet) {
			height: calc(100% - 38px - 4px);
		}

		@include breakpoint(desktop) {
			height: calc(100% - 38px - 4px);
		}
		
		&.hide {
			display: none;
		}
	}

	.Collapsible {
		flex: 0 0 auto;
		align-self: auto;
		width: 100%;
		// display: flex;
		display:block;
		flex-direction: column;
		margin-bottom:5px;

		@include breakpoint(desktop) {
			//display:flex;

			&.opened_set {
				flex: 0 1 100%;
				display:flex;
			}
		}
		&.opened_set {
			flex: 0 1 100%;
			display:flex;
			position: relative;
			.Collapsible__contentOuter {
				flex: 1 0 0px;
				align-self: auto;
				overflow-x: hidden !important;
			}

			.Collapsible {
				//border-bottom: solid 1px white;
				margin-bottom:0px;

				&:after {
					content: "";
					border-bottom: solid 1px $accordionGameborder;
					width: calc(100% - 18px);
					margin: 0px auto;
					display:block;
				}

				.Collapsible__trigger {
					height:37px;
					line-height: 37px;
					padding:0px;
				}
			}
		}

		&.opened_game {
			flex: 0 1 100%;
			.Collapsible__contentOuter {
				flex: 1 0 90%;
				align-self: auto;
				overflow:hidden;
			}
		}

		.Collapsible__trigger {
			flex: 0 0 auto;
			align-self: auto;

			background-color: $accordionTitle1Bg;
			border:solid 1px $accordionSetBorder;
			color: $accordionTitleText;
			cursor: pointer;
			padding: 8px 8px 8px 8px;
			width: 100%;
			text-align: left;
			text-align: center;
			@include gotham(medium);
			font-size:px2rem(14);
			text-transform: uppercase;
			//max-width: calc(100% - 10px);
			outline: none;
	
			&:hover {
				background-color: $accordionTitleHover;
			}
	
			&:before {
				display: none;
				content: '';
			}
	
			&:after {
				border: solid $white;
				border-width: 0 2px 2px 0;
				display: inline-block;
				padding: 4px;
	
				position: absolute;
				right: 12px;
				top: 8px;
		
				transition: transform 150ms;
				transform: rotate(45deg);
				content: ' ';
			}
	
			&.is-open {
				&:after {
					top: 12px;
					transform: rotate(225deg);
				}
			}
	
			&.Collapsible__trigger_game {
				padding-left: 14px;
				text-transform: none;
				background:none;
				border:none;
				&:after {
					// display: none;
					// content: '';
				}
			}
	
		}

		.Collapsible__contentInner {
			padding-left: 0px;
			padding-right: 0px;

			.Collapsible {
				//border:none;
				.Collapsible__trigger {
					//background:none;
				}
			}
			.Collapsible__point {
				font-size: px2rem(13);
				color: white;
				padding: 8px 5px 8px 10px;
				cursor: pointer;
				display: flex;
		
				.point {
					font-weight: bold;
					flex: 0 0 45px;
					margin: 0 5px 0 0;
				}
		
				.border {
					height: 1px;
					flex: 0 0 100%px;
					background-color: black;
				}
		
				&.selected {
					background-color:$accordionPointSelected;
					//color: #000000;
					border-left:solid 4px $accordionPointSelectedBorder
				}

				&:hover {
					background-color: $accordionPointHover;
					color: white;
				}
			}
		}

	}

	





	.accordion__point + .accordion__point {
		border-style: solid;
		border-color: rgba(173, 173, 173, 0.4);
		border-width: 1px 0px 0px 0px;
	}

	

	

	
}