/** weather-landing styles **/

$weatherHeaderAspect: .678;

.page {
    .wrapper .content-main.weather {

        .weather--header-wrapper {
            display: flex;
            position: absolute;
            width: 100%;
            bottom: 100%;

            //top:93px;
            
            @include breakpoint(tablet) {
                //top: 120px;
            }

            &::before{
                content: '';
                height: 200%;
                width: 100%;
                background-image: linear-gradient(to top right, rgba(0, 0, 0, 0.2), transparent);
                position: absolute;
                bottom: 0px;
            }

            .weather--header {
                z-index: 2;
                margin-left: 15px;

                &.hide {
                    opacity: 0;
                    transition: opacity .5s linear;

                }
                &.show {
                    opacity: 1;
                    transition: opacity .5s linear;
                }
                
                .current-conditions {
                    color: $white;

                    .title {
                        //display:table-caption;
                        @include gotham(medium);
                        font-size:18px;
                        text-transform:uppercase;
                        //margin-bottom:18px;
                    }
                    .title2 {
                        @include gotham(medium);
                        font-size:14px;
                        text-transform: uppercase;
                        margin-bottom:5px;
                    }
                    .leftside {
                        padding-top:18px;
                    }
                    .curTemp {
                        @include gotham();
                        font-size: 88px;
                        height:78px;
                        line-height: 78px;
                    }

                    .curdata {
                        display:table;
                        font-size:14px;
                        padding-bottom: 10px;

                        .row {
                            display:table-row; 
                            height:28px;
                        }
                        .text,.value {
                            display:table-cell; 
                            vertical-align: middle;
                        }
                        .text {
                            padding-right: 5px;
                        }
                        .value {@include gotham(medium)}
                        .wind .value span {
                            position: relative; 
                            top: 1px;
                        }
                        img {
                            height:28px;
                            margin: 0 0 0 10px;
                            vertical-align: middle;
                        }

                    }
                }

                @include breakpoint(tablet) {

                    .current-conditions {
                        display:table;
                        .title {
                            display:table-caption;
                        }
                        .leftside,.rightside {
                            display:table-cell; vertical-align: top;
                        }
                        .leftside {padding-right:36px;}
                        .rightside {padding-top:10px;}
                    }
                    .fiveday-table {
                        .data5, .data6 {display:table-cell}
                    }
                }
            }
        }

        
		.cam-wrapper{
			width: 100%;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;

			.cam {
				flex: 0 0 auto;
    			width: 100%;

				.title {
					@include gotham(medium);
					font-size: 18px;
					text-transform: uppercase;
					text-align: center;
				}
				.image-holder {
					height: calc(100% / 1.333);

					img {
						width:100%;
						height: auto;
					}
				}
				

				@media only screen and (min-width: $column-break-page-1) {
					width: calc(50% - 7px);
				}
			}
		}

        .hbh-table {
            .cell {
                vertical-align: middle;
            }
            .forecast_wrapper {
                img {height:44px;}
            }
            .wind_wrapper {
                img {height:28px;}
            }
            .temp {position:relative; bottom:16px; left:5px;}
			.speed {position: relative; bottom: 11px; left:5px;}
			
			@include breakpoint(tablet, max) {
				.datatable .headers .cell {
					padding: 8px 0px;
    				font-size: 11px;
				}
				.datatable .row .cell {
					padding: 8px 0px;
    				font-size: 12px;
				}
			}
        }

        .fiveday-table {
            .cell {
                width: calc(90% / 6);
                vertical-align: middle;
            }
            .row {
                .cell {
                    padding:10px 5px;
                }
            }
            .data1 {width:10%; text-transform: uppercase;}
			
			@include breakpoint(tablet, max) {
				.datatable .headers .cell {
					padding: 10px 0px;
    				font-size: 11px;
				}
				.datatable .row .cell {
					padding: 10px 0px;
    				font-size: 12px;
				}
			}
            
        }

        .fiveday-forecast {
            font-size: 14px;
            line-height: 14px;
            .forecast {
                margin:14px 0px;
            }
            .title {@include gotham(medium)}
        }

    }
}

/**
 * column layout 
 */

.column-layout {
    &.weather {
        justify-content: space-between;
        display: flex;
        flex-wrap:wrap;
        position:relative;
        z-index: 2;
        .component {
			margin: 8px 0 7px 0;
			width: 100%;
        }
        .component > .component {
            margin: 15px 0 0 0;
        }

        @media only screen and (min-width: $column-break-page-1) {
            .two-col {
                &.cam {
                    width: calc(50% - 7px);
                }
            }
        }

        @media only screen and (min-width: $column-break-page-2) {
            
            .two-col {
                width: calc(50% - 7px);
            }

        }

    }
}