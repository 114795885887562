.mi-section-header {
    display: flex;
    flex-wrap: wrap;
    color: $wim-green;

    @include breakpoint(desktop) {
        padding-bottom: 35px;
    }

    i.wim-icon-info {
        margin-left: 15px;
    }

    &.power-index {
        @include breakpoint(tablet, max) {
            // margin-top: -15px;
            margin-bottom: 15px;
        }
    }
}

.mi-section-title {
    @include gotham(medium);
    text-transform: uppercase;
    font-size: px2rem(14);
    display: inline-flex;

    &.close {
        position: relative;

        &:after {
            @include wim-icon('Icons');
            font-size: px2rem(16);
            font-weight: normal;
            position: absolute;
            content: '\e903';
            right: 16px;
            top: 16px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    @include breakpoint(tablet) {
        font-size: px2rem(16);
    }

    @include breakpoint(desktop) {
        font-size: px2rem(18);
    }
}

.mi-last-updated {
    color: $gray_9b;
    display: inline-flex;
    font-size: px2rem(10);
    text-transform: uppercase;
    margin-bottom: 13px;

    @at-root .slamtrackerPanel #{&}{
        display: inline-flex !important;
        width: 100% !important;
        margin-top: 8px !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    
    @include breakpoint(tablet, max) {
        width: 100%;
        margin-top: 10px;
    }

    @include breakpoint(tablet) {
        display: inline-block;
        margin-left: 15px;
        margin-top: 0;
    }

    &.right {
        display: inline-block;
        margin-left: auto;
        text-align: right;
    }

    // this is because Time components returns with div tag,
    div {
        display: inline-block;
    }
}

/** slamtracker override */
.slamtracker, .slamtrackerPanel{
    .mi-section-header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-bottom: 0;
    }
    .mi-section-title {
        margin-bottom: 10px;
    }
}

/* news article embedded MatchInsightsCard override */
.news {
    .mi-section-header {
        display: none;
    }
}
