#powerindex-leaderboard {
    .player-search {
        display: flex;
        justify-content: center;

        .search-box {
            margin: unset;
            margin-right: 10px;
            width: 320px;
            input {
                padding-right: 40px;
                border-radius: 25px;
                background: transparent;
                color: #D8D8D8;
                &::placeholder {
                    color: #D8D8D8;
                }
            }
            i {
                color: #D8D8D8;
            }
            .wim-search-results {
                background: white;
                z-index: 10;
                position: relative;
                max-height: 300px;
                overflow: auto;
                border-radius: 10px;
                .result-article-title {
                    color: $wim-purple;
                    padding: 10px;
                    cursor: pointer;
                    &.highlight {
                        background: $gray-light;
                    }
                    span {
                        font-weight: 100;
                        &.bold-text {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
    
    &.content-main .column-layout .favorites-toggle {
        position:relative;
        cursor: pointer;
        &::before {
            position: relative;
            right:auto;
        }

        .favorite-label {
            display:none;
        }
    }

    .data-tabs {
        margin-bottom:10px;
    }

    .leaderboard-table {
        width:100%;
        margin-bottom:10px;
    }

    .generic-button_wrapper {
        width:100%;
        max-width:305px;
        margin:0px auto;
        >.generic-button {
            width:100%;
        }
    }

    .powerrank-movement { 
        .movement {
            font-size: px2rem(14);
            i {
                display:flex;
                position: relative;
                top: 1px;
            }

			//display: none;
        }
    }

    .helper {
        &-wrapper {
            font-size: px2rem(16);
            text-align: center;
            width:100%;
            color:#FFFFFF;
            margin-top:38px;

            // .webview & {
            //     display:none;
            // }
        }

        &-title {
            @include gotham(medium);
            font-size: px2rem(20);
        }
        &-text {
            @include gotham_book();
            font-size: px2rem(16);

            .bold-link {
                font-weight: bold;
                text-decoration: underline;
            }
        }
        &-action {
            @include gotham(bold);
            a {
                color:#FFFFFF;
                .webview & {
                    display:none;
                }
            }

            
        }
    }

    .search-box.search-box-container .react-autosuggest__suggestions-container {
        width:220px;
    }
    @include breakpoint(medmobile) {
        .search-box.search-box-container .react-autosuggest__suggestions-container {
            width:246px;
        }
    }

    @include breakpoint(tablet) {
        &.content-main .column-layout .favorites-toggle {
            font-size:px2rem(16);
            .favorite-label {
                display:inline;
                
            }
        }

        .player-search {

            .search-box-container {
                width: 478px;
            }
        }


        .data-tabs {
            font-size:px2rem(18);
            margin-top:12px;
            margin-bottom:18px;
        }
        .helper {
            &-wrapper {
                padding:0px 8%;
                margin-top:40px;
            }
        }
    }

    @include breakpoint(desktop) {
        .player-search {
            // .search-box-container {
            //     flex: 0 0 260px;
            // }
        }
    }

    @include breakpoint(desktopSidePanel) {
    //    .player-search {
    //     position: absolute;
    //     right: 180px;
    //     bottom: 10px;
    //    }
    //    &.content-main .column-layout .favorites-toggle {
    //        bottom:13px;
    //    }

       .site-wrap.expanded & {
        //    .player-search {
        //        position:relative;
        //        right:auto;
        //        bottom:auto;
        //    }

            // &.content-main .column-layout .favorites-toggle {
            //     bottom:55px;
            //     font-size:px2rem(16);
            //     .favorite-label {
            //         display:inline;
                    
            //     }
            // }
            .data-tabs {
                font-size:px2rem(18);
            }
        }
    }

    @include breakpoint(largeDesktop) {
        .site-wrap.expanded & {
            // .player-search {
            //     position: absolute;
            //     right: 180px;
            //     bottom: 10px;
            // }
            // &.content-main .column-layout .favorites-toggle {
            //     bottom:13px;
            // }
        }
    }

    .webview & {
        padding:20px 0px;

        .player-search {
            .search-box {
                input {
                    color: $wim-black;
                }
            }
        }   
    }
}