.slamtracker {
    &.metric {
        div.metric {
            display: block;
        }
        span.metric {
            display: inline-block;
        }
        .imperial {
            display: none;
        }
    }
    &.imperial {
        div.imperial {
            display: block;
        }
        span.imperial {
            display: inline-block;
        }
        .metric {
            display: none;
        }
    }

    .select-menu {
        color: $dk-gray;
        width: auto;
        max-width: 303px;
        // float: right;
        text-align: center;

        @at-root .webview #{&}{
            width: 100%;
            max-width: unset;
            padding: 10px;
        }
        
        .title { 
            color: $dk-gray;
            letter-spacing: 0px;
            font-size: 12px;
            border-radius: 0px;
            border:none;
            box-shadow: none;
            background:transparent;
        }
        .select-menu-dropdown {
            .option {
                line-height: 20px;
                font-size: 12px;
            }
        }
    }

    .slamtracker-scoreboard {
        .more {
            .slamtracker-radio {
                &:after {
                    border: none;
                }
            }
        }
    }
}

.slamtrackerPanel{
    .slamtracker-scoreboard{
        .upset{
            color: $gray-650;
        }
    }
}

.embedded, .slamtrackerPanel-content {
    &.M {
        div.metric {
            display: block !important;

            &.stats-content-row {
                display: flex !important;
            }
        }
        span.metric {
            display: inline-block !important;
        }
        .imperial {
            display: none !important;
        }
    }
    &.I {
        div.imperial {
            display: block !important;

            &.stats-content-row {
                display: flex !important;
            }
        }
        span.imperial {
            display: inline-block !important;
        }
        .metric {
            display: none !important;
        }
    }
}

