.homepage-photo-promo{
    // height:300px;
    // min-height:100%;
    // min-width:100%;
    .text, .title, .time {
        // text-align: center;
    }
    .image-wrapper {
        &:after{
            background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4) 40%, transparent);
        }
    }

    padding:0;
    @include breakpoint(tablet) {
        &.image-background {
            &.right {
                .standard-wrapper {
                    .image-wrapper {
                        &:after{
                            background-image: linear-gradient(to left, rgba(0, 0, 0, 0.4) 10%, transparent);
                        }
                    }
                }
            }
            &.left {
                .standard-wrapper {
                    .image-wrapper {
                        &:after {
                            background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4) 10%, transparent);  
                        }
                    }
                }
            }
            .image-wrapper {
                margin-left: 0;
                margin-right: 0;
                width: 100%;
                &::after{
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    content: '';
                    left: 0;
                    display:block;
                }
            }
            .homepage-element-info {
                &.fullscreen {
                    &.right {
                        .time {
                            text-align: right;
                        }
                        .title {
                            text-align: right;
                            margin-right: 0;
                            margin-left: 0;
                        }
                        .text {
                            text-align: right;
                        }
                        .wim-icon-textLines {
                            text-align: right;
                        }
                    }
                    &.left {
                        left: 45px;
                        .time {
                            text-align: left;
                        }
                        .title {
                            text-align: left;
                            margin-right: 0;
                            margin-left: 0;
                        }
                        .text {
                            text-align: left;
                        }
                        .wim-icon-textLines {
                            text-align: left;
                        }
                    }
                    // left: 0;
                    // right: 0;
                    // top: 50%;
                    // width: 100%;
                    // transform: translateY(-50%);
                }
            }
        }
    }

    .standard-wrapper {

    }
    @include breakpoint(tablet,max){
        // height:213px;
    }

    img{
        // position: absolute;
        // top: 0;
        // left: 50%;
        // transform: translateX(-50%);
        // width:auto;
        // max-height: unset;
        // max-width: unset;
        // height: 100%;
        // min-width:100%;
    }

    .homepage-element-info{
        // transform:translate(-50%,-50%);
        // display:flex;
        // flex-direction:column;
        // left:50%;

        .title{
            // color: $white;
            // font-size: 30px;
            // letter-spacing: 2.5px;
            // text-transform: uppercase;
            width:100%;
            // text-align:center;
            // max-height: 75px;
            // overflow: hidden;
    
            @include breakpoint(tablet, max){
                // font-size:20px;
                // letter-spacing:1px;
            }
        }
    
        .time{
            font-size: 12px;
            // color: $white;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            width:100%;
            // text-align:center;
    
            @include breakpoint(tablet,max){
                font-size:10px;
            }
        }

        .text{
            // text-align:center;
        }
    
        i{
            text-align:center;
        }

        .read-more{
            display:inline-block;
            width: 180px;
            padding: 8px 0;
            align-self:center;
        }
    }
}