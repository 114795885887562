.hmb-main {
  text-align: center;
  padding-top: 16px;
  height: 94px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.tickets-date-selector-details-small {
  display: block;
  width: 100%;
}

.tickets-date-selector-details-ugp {
  display: block;
  line-height: 18px;
  font-size: 12px;
}

.tickets-date-selector-details-ugp-large {
  display: block;
  line-height: 18px;
  font-size: 14px;
  letter-spacing: 1;
}

.tickets-date-selector-details-large {
  width: 62px;
  display: inline-block;
  font-size: 20px;
}

.hmb-image {
  display: table-cell;
  vertical-align: middle;
  border: none;
  border-radius: 8px;
  color: #FFFFFF;
  text-transform: uppercase;
  background: none;
  padding: 0px 1px 0px 1px; // top right bottom left
  height: 60px;
  width: 72px;
}


.hmb-image-large {
  display: inline-block;
  height: 60px;
  width: 76px;
  padding: 0px;
  border-color: transparent;
  border-radius: 8px;
  background: none;
  color: #FFFFFF;
  text-transform: uppercase;
  border: none;
}

.hmb-image-container {
  vertical-align: middle;
  display: inline-block;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 95%;
  height: 80px;
  @include breakpoint(tablet) {
    max-width: 95%;
}
}

.hmb-selected {
  background: #FFFFFF;
  color: #007234;
  border-radius: 8px;
  padding: 2px
}

/* height */
.hmb-image-container::-webkit-scrollbar {
  height: 6px;
}

/* Track */
.hmb-image-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 8px;
}
 
/* Handle */
.hmb-image-container::-webkit-scrollbar-thumb {
  background: rgba(12, 129, 65, 0.69);
  border-radius: 8px;
}