.search-box-popout{
    background-color:$white;
    width:650px;
    border:1px solid $lines-gray;
    display:flex;
    flex-direction:column;
    position:relative;
    text-align:center;
    padding-bottom:30px;
    padding-top:15px;
    border-radius:10px;

    @include breakpoint(tablet,max){
        width:305px;
        padding-top:45px;
    }

    .search-box{
        border-bottom:1px solid $select-menu-border;
        align-self:center;
        margin-bottom:25px;
    }

    .popular-results{
        width:560px;
        align-self:center;

        @include breakpoint(tablet,max){
            width:285px;
        }

        &-title{
            @include gotham(medium);
            font-size:16px;
            color:$gray-650;
            text-transform:uppercase;
            margin-bottom:15px;

            @include breakpoint(tablet,max){
                font-size:14px;
            }
        }

        &-items{
            @include gotham-book();
            font-size:14px;
            color:$wim-green;
            margin-bottom:15px;

            @include breakpoint(tablet,max){
                font-size:12px;
            }
        }

        &-see-all{
            @include gotham(medium);
            font-size:14px;
            color:$wim-green;
            text-transform:uppercase;

            @include breakpoint(tablet,max){
                font-size:14px;
            }
        }
    }

    .wim-icon-close{
        position:absolute;
        font-size:10px;
        color:$lines-gray;
        top:18px;
        right:18px;

        @include breakpoint(tablet,max){
             top:12px;
             right:12px;   
        }
    }
}