/** transparent-select-menu styles **/

.scores-select-menu {
    width: 100%;
    position:  relative;

    &.gray{
        .select-menu{
            button{
                color: $md-gray2;
                &.title{
                    border: 1px solid $md-gray2;
                }
            }
        }
    }

    .select-menu {
         width: 100%;
 
        position: relative;
        display: inline-block;

        button{
            position:  relative;
            text-decoration:  none;
            padding:  0 25px 0 8px;
            cursor:  pointer;
            height: 35px;
            margin: 1px 0px !important;
            font-size: 12px;
            color: $white;
            @include gotham(medium);

            &.title {
                // border: solid 1px $white;
                background: rgba(255,255,255,0.15);
                text-transform: uppercase;
                //width: 305px;
                // text-align: left;
                display: inline-block;
                overflow:  hidden;
                white-space:  nowrap;
                text-overflow: ellipsis;
                line-height:  20px;
                text-align: center;
                border-radius: 8px;

				//position arrow to right of box around scores select menu style
				.menu-arrow {
					position: absolute;
					// right: 5px;
					top: 12px;
                    margin-left: 6px;
				}

            }
        }

        .animation-container {
            position: absolute;
            width: 100%;
            height: auto;
            right: 0px;
            max-height: calc(100vh - 250px);
            font-size:  15px;
            z-index: 5;
            transform:none;
            min-height: 50px;
            overflow-y: unset;
            
            .select-menu-dropdown {
				transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
				transform: translate(0, -100%);
				border-radius: 8px;
				border: 1px solid $lines-gray;
				margin-left: 0;
                width: 100%;
				overflow-x: unset;

                &.show {
                    transform: translate(0%);
                }

                a:hover, a:visited:hover { color: unset; text-decoration: none; }
            }

            button {
                color: $wim-black;
				user-select: none;
				padding: 0 25px 0 22px;

                i {
                    color: $wim-green;
                    font-size: 11px;
                    visibility: hidden;
					left: 4px;
                }

                &.clicked{
                    i{
                        visibility: visible;
                        left: 4px;
                        top:  7px;
                    }
                }
                
                &.pdf {
                  i{
                    visibility: visible;
                    position: relative;
                    top:  2px;
                    font-size: 13px;
                  }
                }
            }
            .option {
				height: 25px;
				width: 100%;
	

				&:hover {
					//text-decoration: underline;
					background-color: rgba($option-hover, 1);
				}
            }
        }
    }
}

.webview {
    .scores-select-menu {
        .select-menu {
            .animation-container {
                &.show {
                    z-index: 10; // make this higher than search box
                }
            }
        }
    }
}
