.commentary-wrapper {
    width: 100%;

    .time{
        color: $white;
        display: flex;
        flex-direction: row;
        font-size: 12px;
        font-weight: normal;

        time {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .time:before, .time:after{
    content: "";
    flex: 1 1;
    border-bottom: 1px solid $white;
    margin: auto;
    }

    .post {
        color: $white;
        flex-flow: row wrap;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;
        .icon {
            background-position: center center;
            background-size: 32px;
            background-repeat: no-repeat;
            width: 38px;
            height: 38px;
            margin: auto;
            &.photo {
                background-image: url('https://assets.wimbledon.com/images/live-commentary/icon-blog-photo_white.png');   
            }
            &.email {
                background-image: url('https://assets.wimbledon.com/images/live-commentary/icon-blog-email_white.png');   
            }
            &.twitter {
                background-image: url('https://assets.wimbledon.com/images/live-commentary/icon-blog-twitter_white.png');   
            }
            &.flag {
                background-image: url('https://assets.wimbledon.com/images/live-commentary/icon-blog-flag.png');   
            }
            &.rain {
                background-image: url('https://assets.wimbledon.com/images/live-commentary/icon-blog-rain_white.png');   
            }
            &.cloudy {
                background-image: url('https://assets.wimbledon.com/images/live-commentary/icon-blog-cloudy_white.png');   
            }
            &.sunny {
                background-image: url('https://assets.wimbledon.com/images/live-commentary/icon-blog-sunny_white.png');   
            }
            &.heart {
                background-image: url('https://assets.wimbledon.com/images/live-commentary/icon-blog-heart_white.png');   
            }
            &.star {
                background-image: url('https://assets.wimbledon.com/images/live-commentary/icon-blog-star_white.png');   
            }
            &.shock {
                background-image: url('https://assets.wimbledon.com/images/live-commentary/icon-blog-shock_white.png');   
            }
            &.quote {
                background-image: url('https://assets.wimbledon.com/images/live-commentary/icon-blog-quote_white.png');   
            }
            &.strawberry {
                background-image: url('https://assets.wimbledon.com/images/live-commentary/icon-blog-strawberry_white.png');   
            }
            &.fans {
                background-image: url('https://assets.wimbledon.com/images/live-commentary/icon-blog-fans_white.png');   
            }
            &.magicwand {
                background-image: url('https://assets.wimbledon.com/images/live-commentary/icon-blog-magicwand_white.png');   
            }
            &.scoreupdate {
                background-image: url('https://assets.wimbledon.com/images/live-commentary/icon-blog-scoreupdate_white.png');   
            }
            &.roofclosed {
                background-image: url('https://assets.wimbledon.com/images/live-commentary/icon-blog-roofclosed_white.png');   
            }
            &.roofopen {
                background-image: url('https://assets.wimbledon.com/images/live-commentary/icon-blog-roofopen_white.png');   
            }
            &.stat {
                background-image: url('https://assets.wimbledon.com/images/live-commentary/icon-blog-stat_white.png');   
            }
            &.win {
                background-image: url('https://assets.wimbledon.com/images/live-commentary/icon-blog-win_white.png');   
            }
            &.risingstart {
                background-image: url('https://assets.wimbledon.com/images/live-commentary/icon-blog-risingstart_white.png');   
            }
        }

        .title {
            font-size: 14px;
            font-weight: bold;
            padding-left: 10px;
            margin-bottom: 10px;
            // width: 80%; 
            width: 100%;
            text-align: center;
            @include breakpoint(tablet) {
                font-size: 17px;
            }

            @include breakpoint(desktop) {
                font-size: 20px;
            }
        }


        .desc {
            font-size: 16px;
            order: 3;
            text-align: center;
            padding: 2px 5%;
            width: 100%;
            p {
                margin-top: 10px;
            }
            @include breakpoint(tablet) {
                // font-size: 12px;
                padding: 10px 10%;
            }

            @include breakpoint(desktop) {
                padding: 0 20%;
            }

            img {
                height: auto;
                width: 100%;
            }

            .twitterWrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

        }
    }
}