@use "sass:math";

.ticketview {
	.ticketview-header {
		text-align: center;
		@include gotham(medium);
		color: $wim-green;
		display: flex;
		justify-content: center;
		align-items: center;

		.scores-select-menu {
			width: unset;
			
			.select-menu {
				min-width: 250px;
				width: unset;

				button {
					color: $wim-green;

					&.title {
						text-transform: unset;
					}
				}
				.animation-container {
					overflow-y: scroll;
					top: 40px;
					height: auto;
				}
			}
		}
	}
	.filter-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;

		.ticketview-filters {
			width: 100%;

			@include breakpoint(tablet) {
				width: calc(50% - 18px);
			}
			@include breakpoint(desktop) {
				width: calc(25% - 18px);
			}
		}
	}
	.ticketview-filters {
		@include gotham(medium);
		display: flex;
		flex-direction: column;
		color: $wim-search-text-purple;
		font-size: 12px;

		.scores-select-menu {
			width: unset;
			margin-top: 5px;
			margin-bottom: 22px;
			
			.select-menu {
				width: 100%;

				button {
					color: $wim-black;
					@include gotham-book;
					font-size: 14px;

					&.title {
						text-transform: unset;
						border: 1px solid $lines-gray;
						height: 36px;
						line-height: 36px;

						.menu-arrow {
							top: 14px;
						}
					}
					&.option {
						padding-left: 0;
						height: 36px;

						.wim-icon-check {
							display: none;
						}
					}
				}
				.animation-container {
					top: 36px;
					height: auto;
					overflow-y: scroll;

					.section {
						display: none;
					}
				}
			}
			@include breakpoint(tablet) {
				margin-bottom: 20px;
			}
			.react-datepicker__aria-live {
				display: block;
				visibility: hidden;
				height: 0px;
				width: 0px;
			}
		}
		&.actions {
			color: $wim-black;
			margin-top: 22px;
			justify-content: center;
			align-items: center;
			width: 100%;
			max-width: 450px;
			margin-left: auto;
			margin-right: auto;
			
			.scores-select-menu > span {
				width: 100%;
				margin-top: 17px;
				display: flex;
				flex-direction: row;
				align-items: center;

				i {
					color: $wim-green;
					font-size: 19px;
					margin-left: 14px;
				}
				@include breakpoint(tablet) {
					width: 305px;
				}
			}
		}
		.date-filter, .court-filter, .status-filter, .type-filter {
			width: 100%;
			max-width: 450px;
			margin-left: auto;
			margin-right: auto;

			.scores-select-menu {
				width: 100%;
			}

			@include breakpoint(tablet) {
				margin-left: 0;
				margin-right: 0;
			}
		}
		.type-filter {
			button { text-transform: capitalize !important; }
		}
	}
	.scores-select-menu {
		.select-menu {
			.animation-container::-webkit-scrollbar {
				display: none;
			}
			.select-menu-dropdown::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
		}
	}
	.ticket-extras {
		width: 100%;
		margin: 0 7px !important;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&.single-element {
			justify-content: flex-end;
		}
	}
	form {
		width: 100%;
	}
	.wheelchair-legend {
		text-align: right;
		@include gotham(bold);
	}
	.ticketview-table {
		// height: 552px;
		// overflow: scroll;
		position: relative;
		border-radius: 8px;
		.row {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;

			&.headers { 
				display: flex; 
				flex-direction: row;
				@include gotham(medium);
				font-size: 16px;
				color: $wim-black;
				height: 48px;
				
				.cell {
					text-transform: uppercase;
				}
				.row-content {
					@include gotham(medium);
					font-size: 16px;
				}
			}
			.row-content {
				display: flex;
				flex-direction: column;
				font-size: 14px;
				color: $wim-black;
				@include gotham-book();
				line-height: 18px;
				padding-top: 9px;
				padding-right: 6px;
				padding-bottom: 10px;
				width: calc(100% - 49px);
				flex-flow: wrap;

				.ticketview-date { order: 2; }
				.ticketview-court { order: 1; }
				.ticketview-details { order: 3; }
				.ticketview-status { order: 5; }
				.ticketview-type { order: 4; }
				.ticketview-reason { order: 6; }
			}
			.ticketview-select { 
				padding-left: 11px;
				width: 49px; 
			}
			.ticketview-status {
				word-wrap: break-word;
				
				.jsx-parser {
					font-weight: bold;
				}
			}
			.ticketview-details-id {
				color: $white;
				font-size: 12px;
				background-color: $green2;
				padding: 2px 5px;
				line-height: 14px;
				flex: 1 0 100%;
				order: 10;

				span {
					padding-right: 30px;
				}
			}
			.ticketview-details-seat {
				.jsx-parser {
					display: inline;
				}
				.wheelchair {
					@include gotham(bold);
				}
			}
			.mobile-bold { font-weight: bold; }
			.no-show-mobile { display: none; }
			.no-show-tablet { 
				display: block; 
				
				&.inline {
					display: inline-block;
				}
			}
			&.disabled {
				opacity: 1;
				display: none;
				
				.row-content {
					color: #999999;
				}
			}
			&.no-tickets-available {
				text-align: center;
				@include gotham(medium);
				justify-content: center;
				margin-top: 48px;
			}
			
			@include breakpoint(tablet) {
				flex-direction: row;
				min-height: 50px;
				
				&.headers {
					.row-content {
						&.no-show-mobile {
							display: flex;
						}
					}
				}
				.row-content {
					flex-direction: row;
					padding: 0;
					align-items: center;
					width: calc(100% - 55px);

					.ticketview-date { order: 1;  }
					.ticketview-court {  order: 2;   }
					.ticketview-details { 
						order: 3;

						.ticketview-details-row { display: inline-block; }
						.ticketview-details-seat { 
							display: inline-block;
							
							&:before {
								content: '\00a0',
							}
						
						}
					}
					.ticketview-status { 
						order: 4; 
					}
					.ticketview-type { 
						order: 5; 
					}
				}
				.ticketview-select { 
					padding-left: 16px;
					width: 55px; 
				}
				.ticketview-date { 	width: 20%; }
				.ticketview-court {	width: 15%; padding-left: 15px; }
				.ticketview-details { width: 23%; padding-left: 15px; }
				.ticketview-status { width: 25%; padding-left: 15px; }
				.ticketview-type { width: 17%; padding-left: 15px; padding-right: 15px; }
				.ticketview-reason { width: 20%; }
				.no-show-mobile { 
					display: block; 

					&.inline {
						display: inline-block;
					}
				}
				.mobile-bold { font-weight: normal; }
				.no-show-tablet { display: none !important; }
			}
			@include breakpoint(desktop) {
				.row-content {
					.ticketview-details { 
						.ticketview-details-gangway, .ticketview-details-row, .ticketview-details-seat { display: inline-block; }
						.ticketview-details-row { 
							&:before {
								content: '\00a0',
							}
						
						}
					}
				}
			}
			label {
				display: unset;
			}
		}
		.gray {
			background-color: #ECECEC;
		}
		&.cancel-tickets {
			.row {
				.ticketview-reason {
					input {
						padding-right: 30px;
					}
					label {
						.wim-icon-down-arrow {
							top: 27px;
						}
					}
				}

				@include breakpoint(tablet) {
					.ticketview-date { 	width: 13%; }
					.ticketview-court {	width: 13%; padding-left: 15px; }
					.ticketview-details { width: 20%; padding-left: 15px; }
					.ticketview-status { width: 16%; padding-left: 15px; }
					.ticketview-type { width: 15%; padding-left: 15px; padding-right: 15px; }
					.ticketview-reason { 
						width: 23%; 
						padding-right: 5px;
						
						.error {
							display: none !important;
						}
					}
				}
			}
		}
	}
	.ticketview-submit {
		width: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 42px;
		margin-bottom: 18px;
		
		span {
			display: inline-block;
		}
		&.first.center {
			justify-content: flex-end;
		}
		&.second.center {
			justify-content: flex-start;
		}
		.fixed {
			position: fixed;
			background-color: $white;
			height: 56px;
			border-top: 1px solid $lines-gray;
		}
	}
	.ticket-view-buttons {
		.cancel-button {
			text-align: right;
		}
	}
	.four-col, .two-col {
		float: left;
	}
	.two-col.ticket-view-buttons {
		.two-col {
			width: calc(50% - 12px * 2);
			margin: 7px 12px;
		}
	}
	.no-margin.left {
		margin-left: 0 !important;
	}
	.bold-italic {
		@include gotham(medium, italic);
	}
	.error {
		display: block;
		font-size: 12px;
		color: $red;
	}
	.tickets-page-header {
		@include gotham(medium);
		font-size: 18px;
		color: $wim-green;
		letter-spacing: 1px;
		text-align: center;
		text-transform: uppercase;
	}
	.tickets-page-description {
		@include gotham-book();
		font-size: 14px;
		color: $wim-black;
		letter-spacing: 0.44px;
		text-align: center;
		line-height: 18px;
	}
	.tickets-view-transfer {
		h5 {
			white-space: nowrap;
			color: $wim-search-text-purple;
		}
		
		@include breakpoint(desktop) {
			.transfer-share-col1 {
				display: flex;
				justify-content: flex-end;
			}
			.transfer-share-col2 {}
			.col-content {
				display: flex;
				flex-direction: column;
			}
			label {
				input {
					width: 296px;
				}
			}	
		}
	}
}

.modal-content {
	&.actions-modal {
		top: 73px;
		height: calc(100vh - 73px);
		padding: 70px 20px 43px;
		transform: translate(-50%, 0);

		.scrollable {
			top: 160px;
			bottom: 0;
			width: 100%;
			overflow-y: auto;
		}

		@include breakpoint(tablet) {
			top: 50%;
			height: 80vh;
			transform: translate(-50%, -50%);
		}
	}
	&.actions-modal, &.share-error-modal {
		width: 100%;
		text-align: center;
		@include gotham-book();
		font-size: 14px;
		color: $wim-black;
		letter-spacing: 0.44px;
		line-height: 18px;
		border: 1px solid $lines-gray;

		.title {
			@include gotham(medium);
			color: $wim-search-text-purple;
			text-transform: uppercase;
			font-size: 26px;
			letter-spacing: 1.3px;
			position: relative;
			margin-bottom: 32px;

			&:after {
				content: '';
				width: 20px;
				height: 2px;
				background-color: $wim-search-text-purple;
				position: absolute;
				bottom: -15px;
				left: calc(50% - 10px);
			}
		}
		.subtitle {
			@include gotham(medium);
			font-size: 16px;
			color: $wim-black;
			line-height: 31px;
			margin-top: 12px;
		}
		.options-text {
			@include gotham(medium);
			font-size: 14px;
			color: $wim-black;
			line-height: 18px;
			margin-bottom: 6px;
		}
		.wim-icon-close {
			color: $wim-black;
			@include gotham-book();
		}
		@include breakpoint(tablet) {
			width: 65%;
		}
		@include breakpoint(desktop) {
			width: 461px;
			padding: 65px 25px 40px;;
		}
	}
}

.tickets-submission {
	.modal-title {
		text-align: center;
		@include gotham(medium);
		font-size: 26px;
		color: $wim-search-text-purple;
		letter-spacing: 1.3px;
		position: relative;
		margin-bottom: 20px;

		&:after {
			content: '';
			position: absolute;
			bottom: 8px;
			left: calc(50% - 10px);
			width: 20px;
			height: 2px;
			background-color: $wim-search-text-purple;
		}
	}
}

.ticket-mgmt-container {
	width: 100%;
}

.tix-faq-link {
    background: none;
    border: none;
    float: left;
    font-family: Helvetica;
    font-size: 14px;
    color: #00703C;
    letter-spacing: 0.7px;
	cursor: pointer;
	line-height: 14px;
}

.tix-faq-info {
	float: left;
	margin-right: 4px;
}

.ticket-reset-btn-mrgn {
	@include breakpoint(desktop) {
		margin-top: 74px;
	}
}

.ticket-reset-button {
    background: none;
    border: none;
    float: right;
    font-family: Helvetica;
    font-size: 14px;
    color: #00703C;
    letter-spacing: 1px;
	font-weight: bold;
    text-transform: uppercase;
	cursor: pointer;
}

.web-ticketview-sort-icon-container {
	display: inline-block;
	cursor: pointer;
}

.web-ticketview-debug {
	position: absolute;
	display: block;
	font-size: 11px;
	color: $wim-green;
	margin-top: 4px;
}

.web-ticketview-test-ticket {
	display: block;
	font-size: 10px;
	color: red;
	margin-top: -12px;
}

.web-ticketview-no-padding {
	padding: 0px !important;
}

.web-ticketview-sortIcon {
	padding-left: 8px;
	filter: invert(1);
}
.web-ticketview-sortIcon-rotate {
	padding-right: 8px;
	/* Rotate icon when sorted */
	/* transform */
	-webkit-transform: rotate(-180deg);
	-moz-transform: rotate(-180deg);
	-ms-transform: rotate(-180deg);
	-o-transform: rotate(-180deg);
	transform: rotate(-180deg);
	filter: invert(1);
}

.web-ticketview-sortIcon-rotate-no-padding {
	margin-bottom: -2px;
	/* Rotate icon when sorted */
	/* transform */
	-webkit-transform: rotate(-180deg);
	-moz-transform: rotate(-180deg);
	-ms-transform: rotate(-180deg);
	-o-transform: rotate(-180deg);
	transform: rotate(-180deg);
	filter: invert(1);
}

.web-ticketview-sortIcon-default {
	margin-top: 0px;
	filter: invert(1);
}


.tix-mgmt-sort-icon {
	filter: invert(0);
	height: 12px;
}

.tix-mgmt-sort-icon-cntr {
	display: inline-flex;
	padding-left: 4px;
}

.web-ticketview-header {
	display: inline-flex;
    float: left;
    width: 9% !important;
    height: 100%;
    vertical-align: middle;
    text-align: left;
    font-family: Helvetica;
    font-size: 14px;
	color: #FFFFFF;
    letter-spacing: 0;
    align-items: center;
	margin-right: 2px;
	@include breakpoint(tablet) {
		width: 11% !important;
		margin-right: 1px;
	}
	@include breakpoint(desktop) {
		width: 10% !important;
		margin-right: 2px;
	}
}

.web-ticketview-header-type {
	display: inline-flex;
    float: left;
    width: 9% !important;
    height: 100%;
    vertical-align: middle;
    text-align: left;
    font-family: Helvetica;
    font-size: 14px;
	color: #FFFFFF;
    letter-spacing: 0;
    align-items: center;
	margin-right: 2px;
	@include breakpoint(tablet) {
		width: 12% !important;
		margin-right: 2px;
	}
	@include breakpoint(desktop) {
		width: 12% !important;
		margin-right: 2px;
	}
}

.web-ticketview-header-med {
	@include breakpoint(tablet) {
		display: none;
	}
	@include breakpoint(desktop) {
		display: inline-flex;
		margin-right: 6px;
		float: left;
		width: 8% !important;
		height: 100%;
		vertical-align: middle;
		text-align: left;
		font-family: Helvetica;
		font-size: 14px;
		color: #FFFFFF;
		letter-spacing: 0;
		align-items: center;
	}
	
}

.web-ticketview-header-large {
	display: inline-flex;
    float: left;
	width: 13% !important;
    height: 100%;
    vertical-align: middle;
    text-align: left;
    font-family: Helvetica;
    font-size: 14px;
	color: #FFFFFF;
    letter-spacing: 0;
    align-items: center;
	margin-left: 4px;
	margin-right: 8px;
	@include breakpoint(desktop) {
		width: 11% !important;
   		margin-left: 4px;
		margin-right: 0px;
	}
}

.web-ticketview-header-extralarge {
	display: inline-flex;
	// margin-right: 6px;
    float: left;
    width: 25% !important;
    height: 100%;
    vertical-align: middle;
    text-align: left;
    font-family: Helvetica;
    font-size: 14px;
	color: #FFFFFF;
    letter-spacing: 0;
    align-items: center;
	margin-left: 0px;
	margin-right: 20px;
	@include breakpoint(desktop) {
		width: 24% !important;
		margin-right: 8px;
	}
}

.web-ticketview-status {
	width: 10%;
	margin-left: 0px;
}

.web-ticketview-lastupdated {
	width: 10%;
	margin-left: 80px;
}

.web-ticket-headers {
	display: none;
	@include breakpoint(tablet) {
		display: inline-block;
		background: #00703C;
		border-radius: 8px;
		font-family: Helvetica;
		font-size: 16px;
		letter-spacing: 0;
		text-transform: uppercase;
		margin-bottom: 8px;
		height: 49px;
		width: 100%;
	}
}

.web-ticket-headers:first-child {
	@include breakpoint(tablet) {
		padding-left: 8px;
	}
	@include breakpoint(desktop) {
		padding-left: 0px;
	}
}

.web-ticketview-header-last {
	display: none;
	@include breakpoint(desktop) {
		display: inline-block;
		width: 12% !important;
		line-height: 20px;
	}
}

.ticketview-details-id-debug {
	margin-top: -8px;
	background: #00703C;
	margin-bottom: 8px;
	color: white;
	font-size: 12px;
	border: 1px solid black;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	span {
		 flex: 1 0 100px;
    	white-space: nowrap;
	}
}

.react-datepicker-wrapper {
	width: 100% !important;
}

.react-datepicker__input-container {
	width: 100% !important;
}

.date-filter-picker {
	width: 100% !important;
	height: 36px !important;
	margin-top: 0px !important;
	border: 1px solid #979797;
	color: #333;
}

.desktopTicketView-cntr {
	width: 100%;
}


// ticket management

.desktop-ticket-list-table-cntr {
	display: none;
	@include breakpoint(desktop) {
		display: inherit;
	}
}

.desktop-ticket-view-no-tix {
	width: 100%;
    text-align: center;
    font-weight: bold;
	margin-bottom: 32px;
}

.ticket-mgmt-cntr {
	display: none;
	@include breakpoint(desktop) {
		display: inherit;
		width: 260px;
		height: 56px;
		background-color: #f2f2f2;
		border: 1px solid $wim-green;
		border-radius: 8px;
		position: absolute;
		top: 15px;
		right: 16px;
	}
	@media only screen and (min-width: 1080px) {
		right: 45px;
	}
}

.tix-action-error-img {
	padding: 0px !important;
	margin-right: 8px;
}

.tix-action-err-cntr {
	line-height: 20px;
	padding: 0px !important;
}

.tix-mgmt-list-table {
	table {
		border-spacing: 0;
		border: 1px solid #ededed;
		border-radius: 8px;
		width: 100%;

		tr:nth-child(even) {
			background-color: #f2f2f2;
		}

		tr:hover {
			background-color: #D9D9D9;
		}
	
		tr {
			:last-child {
				td {
					border-bottom: 0;
				}
			}
			td {
				:first-child {
					padding-left: 16px;
				}
			}
		}

		th {
			text-transform: uppercase;
			margin-bottom: 4px;
			background-color: #ededed;
			text-align: left;
		}

		thead tr {
			border: 1px solid #ededed;
			border-radius: 8px;
		}

		thead tr th:nth-child(2), thead tr th:nth-child(3) {
			width: 124px;
			max-width: 124px;
			select {
				min-width: 108px;
				width: 100%;
			}
		}

		thead tr th:first-child{
			border-radius: 8px 0px 0px 8px;
			width: 40px;
		}
		thead tr th:last-child{
			border-radius: 0px 8px 8px 0px;
			width: 15%;
			select {
				width: 100%;
				min-width: 140px;
			}
		}

		thead tr th:nth-child(4){
			width: 128px;
			max-width: 128px;
			select {
				min-width: 112px;
				width: 100%;
			}
		}

		thead tr th:nth-child(5), thead tr th:nth-child(6) {
			width: 90px;
			max-width: 90px;
			min-width: 84px;
			select {
				min-width: 84px;
				width: 100%;
			}
		}

		thead tr th:nth-child(7){
			@media (min-width: 1228px) {
				width: 294px;
				max-width: 294px;
				min-width: 130px;
			}
			@include breakpoint(desktopSidePanel, min){
				min-width: 294px;
				max-width: 294px;
			}
			@include breakpoint(desktopSidePanel, mid){
				max-width: 294px;
				min-width: 130px;
			}
		}

		thead tr th:nth-child(8){
			width: 120px;
			max-width: 120px;
			select {
				min-width: 112px;
				width: 100%;
			}
		}

		tr {
			font-size: 14px;
		}

		thead tr th {
			width: 108px;
		}

		tr td {
			word-break: break-word;
		}


		th,
		td {
			margin: 0;
			padding: 0.5rem;
		}

		th input, select {	
			font-size: 14px;
			margin-top: 4px;
			padding: 2px;
			border-radius: 4px;
			height: 32px;
		}

		th, td:last-child {
			border-right: 0;
		}
	}
	
}

.tix-mgmt-screen-not-supported {
	display: block;
	@include breakpoint(desktop) {
		display: none;
	}
}

.ticket-mgmt-topLine {
	height: 14px;
	margin: 8px;
}

.ticket-mgmt-icon {
	display: inline;
	float: left;
	padding-right: 8px;
}

.ticket-mgmt-txt {
	text-align: left;
	margin-left: 16px;
	font-size: 14px;
	font-weight: bold;
	color: $wim-green;
}

.ticket-mgmt-switch {
	display: inline;
	float: right;
}

.ticket-mgmt-subTxt {
	font-size: 12px;
	margin-left: 36px;
}

.tix-viewing-count-txt {
	display: none;
	@include breakpoint(desktop) {
		display: inherit;
		font-style: italic;
		float: right;
		font-size: 16px;
		position: absolute;
		right: 174px;
		top: 98px;
	}
}

.tix-viewing-count-reset-btn {
	right: 16px;
    position: absolute;
    top: 99px;
	@media only screen and (min-width: 1080px) {
		right: 38px;
	}
}

// toggles 
.switch {
	position: relative;
	display: inline-block;
	width: 32px;
	height: 18px;
  }
  
  .switch input { 
	opacity: 0;
	width: 0;
	height: 0;
  }
  
  .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .01s;
	transition: .01s;
  }
  
  .slider:before {
	position: absolute;
	content: "";
	height: 12px;
	width: 12px;
	left: 3px;
	bottom: 3px;
	background-color: white;
	-webkit-transition: .01s;
	transition: .01s;
  }
  
  input:checked + .slider {
	background-color: #4F9E52;
  }
  
  input:focus + .slider {
	box-shadow: 0 0 1px $wim-green;
  }
  
  input:checked + .slider:before {
	-webkit-transform: translateX(14px);
	-ms-transform: translateX(14px);
	transform: translateX(14px);
  }
  
  /* Rounded sliders */
  .slider.round {
	border-radius: 34px;
  }
  
  .slider.round:before {
	border-radius: 50%;
  }


.ticket-mgmt-title {
	display: none;
	@include breakpoint(desktop) {
		text-transform: capitalize;
		font-family: inherit;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 1px;
		margin-bottom: 16px;
		display: inherit;
	}
	
}


/* Style the tabs for actions */
.ticket-mgmt-tab {
	display: none;
	@include breakpoint(desktop) {
		overflow: hidden;
		border: 1px solid #DADADA;
		border-radius: 8px;
		background-color: #DADADA;
		display: table;
		margin-bottom: 16px;
	}
  }

  .ticket-mgmt-tab-selected {
	background-color: $wim-green !important;
	border-radius: 8px;
	color: white;
  }
  
  /* Style the buttons inside the tab */
  .ticket-mgmt-tab button {
	font-family: inherit;
	float: left;
	border: none;
	outline: none;
	cursor: pointer;
	margin: 4px 2px 4px 4px;
	padding: 14px 16px;
	letter-spacing: 1px;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
	background-color: #DADADA;
  }
  
  /* Change background color of buttons on hover */
  .ticket-mgmt-tab button:hover {
	background-color: #F7F7F7;
	border-radius: 8px;
  }
  
  /* Create an active/current tablink class */
  .ticket-mgmt-tab button.active {
	background-color: $wim-green;
  }

  .ticket-list-table-row {
	height: 48px;
  }

  .ticket-list-table-row:hover {
	background: #D9D9D9 !important;
  }

  .ticket-list-table-row-disabled {
	cursor:not-allowed;
	background-color: #F2F2F2;
	color: #979797;
	opacity: 0.7;
  }

  .ticket-list-table-row-viewAll, .ticket-list-table-row-disabled {
	height: 48px;
	input[type='checkbox'] {
		display: none; // hide the default checkbox because we have a custom styled one
	}
	.tix-mgmt-styled-checkbox {
		opacity: 0.4;
		cursor:not-allowed !important;
		pointer-events: none !important;
	}
	label {
		opacity: 0.4;
		cursor:not-allowed !important;
		pointer-events: none !important;
	}
	& + label:before, .tix-mgmt-styled-checkbox+label:before {
		content: '';
		box-shadow: 0 0 0 2px #979797;
		cursor:not-allowed !important;
		pointer-events: none !important;
	  }
  }



  // custom styled purple checkbox 
  .tix-mgmt-styled-checkbox {
	position: absolute; // take it out of document flow
	opacity: 0; // hide it
  
	& + label {
	  position: relative;
	  cursor: pointer;
	  padding: 0;
	}
  
	// Box.
	& + label:before {
	  content: '';
	  margin-right: 10px;
	  display: inline-block;
	  vertical-align: text-top;
	  width: 16px;
	  height: 16px;
	  background: white;
	  box-shadow: 0 0 0 2px #4C1486;
	}
  
	// Box checked
	&:checked + label:before {
	  background: white;
	}

	// Checkmark. Could be replaced with an image
	&:checked + label:after {
		content: '';
	  	position: absolute;

		  box-shadow: 2px 0px 0px #4C1486, 3px 0px 0 #4C1486, 2px 0px 0 #4C1486, -3px 0px 0 #4C1486, -3px -6px 0 #4C1486, 3px -6px 0 #4C1486;
		  left: 5px;
		  top: 8px;
		  background: #4C1486;
		  width: 6px;
		  height: 6px;
	}
  }

  .appPromo-landing-pg, .appPromo-landing-pg-btn  {
	width: 100%;
  }

  .appPromo-landing-pg-btn {
	cursor: pointer;
	margin: 16px;
	color: $wim-green;
	text-transform: uppercase;
    font-size: 16px;
	letter-spacing: 1px;
	border: none;
	background: none;
	font-weight: bold;
}

.page {
    &.expanded {
		.tix-mgmt-action-btn-cntr {
			@include breakpoint(desktopSidePanel, mid) {
				width: calc(100vw - #{$sidebarWidth});
			}
			@include breakpoint(largeDesktop) {
				width: calc(100vw - #{$sidebarWidth});
			}
		}
	}
}

.tix-mgmt-action-btn-cntr {
	justify-content: center;
    width: 100%;
    display: flex;
	position: fixed;
	bottom: 0px;
	left: 0px;
	background-color: white;
	box-shadow: 0px -1px 3px rgb(50, 50, math.div(50%, 75%));
}

.tix-mgmt-action-btns {
	background: $wim-green;
    color: white;
    width: auto;
    text-align: center;
    margin: 16px;
    border: 1px solid $wim-green;
    border-radius: 8px;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: bolder;
	letter-spacing: 1px;
	padding: 10px 16px;

	i {
		margin-left: 6px;
		font-weight: bold;
	}
}