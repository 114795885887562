/** daynav styles **/
@mixin scores-button-reset {border:none; padding:0px; align-items:inherit; color:inherit; background-color:transparent;}
@mixin scores-dropdown-reset {background:none; overflow:auto; border:none; position:relative; top:auto; right:auto;  z-index: auto; width:auto;}


.daynav {
	clear:both;
	text-align: center;
	
	&.scores-select-menu {
		.category {display:none;}
		.select-menu {
			float:none;
			@include gotham(medium);
			font-size:14px;
			// .animation-container {
			//     // max-height: fit-content;
			//     &::-webkit-scrollbar{
			//         display: none;
			//     }
			// }
			.text-value {display:none;}
			.select-menu-dropdown {list-style:none;
				&.tablet {display:none;}
				button {
					&[data-type="label"] {display:none; cursor: default;}
					&[data-event=""] {display:none;}
					&.disabled {cursor:default}
				}
			}
			button[data-type="ms"]{display:none;} 
		}
	}
}


@include breakpoint(tablet) {
    [data-dna="daynav"] {height:auto;}
}
