/** section-divider styles **/
.secDividerContent {
	hr {
		height: 2px;
		background-color: #006633;
		border: 0;
		margin: 10px 0;

		&.light{ height: 1px; }
		&.heavy{ height: 4px; }
		&.gray{ background-color: #D8D8D8; }
		&.nomargin{ margin: 0; }
	}
}
