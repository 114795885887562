.homepage-tgc {
    width: 100%;
    //height: 100%;
    text-align: center;
    color: $white;
    padding-top: calc(#{$headerHeightLarge} + 31px);
    position: fixed;
    z-index: 2;
    //background-image: linear-gradient(to bottom, rgba(0, 0, 0, .6) 20%, transparent);
    text-shadow: $textShadow;
    pointer-events: none;

    &::after{
        content: '';
        height: 35%;
        width: 100%;
        //background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7) 30%, transparent);
        position: absolute;
        bottom: 0px;
        left: 0px;
	}
	&.hide {
        opacity: 0;
        transition: opacity .5s linear;
    }
    &.show {
        pointer-events: none;
        opacity: 1;
        transition: opacity .5s linear;
    }
    h2 {
        font-size: 15px;
        text-transform: uppercase;
        @include gotham(bold);
        letter-spacing: 1.25px;
        text-shadow: 2px 2px 6px #000000;

        @include breakpoint(tablet) {
            font-size: 24px;
            @include gotham(medium);
            letter-spacing: 3px;
        }
    }
    .teaser {
        font-size: 12px;
        line-height: 25px;
        @include gotham(medium);
        margin-top: 15px;
        color: #FEFDFD;
        letter-spacing: 0;
        line-height: 12px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        text-shadow: 2px 2px 6px #000000;

        @include breakpoint(tablet) {
            font-size: 16px;
            line-height: 21px;
            line-height: 21px;
            width: 50%;
        }
    }
    .number-unit {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 15px;

        @include breakpoint(tablet) {
            flex-direction: column;
        }
    }
    .number {
        @include gotham(medium);
        font-size: 24px;
        color: #FFFFFF;
        margin: 0;
        margin-right: 5px;

        @include breakpoint(tablet) {
            margin-right: 0;
        }
    }
    .unit {
        font-size: 12px;
        line-height: 25px;
        @include gotham-book();
        text-transform: uppercase;
        letter-spacing: 0.62px;
        // display: inline-block;

        @include breakpoint(tablet) {
            font-size: 16px;
            letter-spacing: 1px;
            // display: block;
        }
    }
    .action {
        margin: 15px 0;
        color: $white;
        font-size: 34px;

        .action-link {
            color: $white;
            text-decoration: none;
            cursor: pointer;
            display: inline-block;
            pointer-events: all;
        }
        a {
            display: inline-block;
            pointer-events: all;
        }
        .tgc-arrow {
            position: relative;
            width: 150px;
            height: 31px;
            margin: 0 auto;
            text-align: left;
        }
        .arrow-background {
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(79, 38, 131, 0.6);
            border: 2px solid $white;
            width: 100%;
            height: 100%;
            text-transform: uppercase;
            font-size: 12px;
            line-height: 27px;
            color: $white;
            text-decoration: none;
            padding: 0 10px 0 15px;
            @include gotham(medium);
        }
        .latest-arrow {
            position: absolute;
            margin: 0;
            right: -25px;
            top: -8px;

            &.tgc {
                transform: scale(.35) rotate(-90deg);
            }
        }

        @include breakpoint(tablet) {
            .latest-arrow {
                right: -55px;
                top: -15px;
    
                &.tgc {
                    transform: scale(.25) rotate(-90deg);
                }
            }
        }
    }
    .social {
        @include gotham-book();
        font-size: 12px;
        color: #FEFDFD;
        letter-spacing: 0;

        &.spaced {
            margin-top: 71px;
        }

        @include breakpoint(tablet) {
            font-size: 14px;
        }
    }
}

.page {
    &.expanded {
        .homepage-tgc {
            @include breakpoint(desktopSidePanel, mid){
                width: calc(100% - #{$sidebarWidth});
            }
            
            @include breakpoint(largeDesktop){
                width: calc(100% - #{$sidebarWidth});
            }
        
            // sidepanel always open with centered max width content
            @include breakpoint(extraLargeDesktop){
                width: calc(#{$maxWidth} - #{$sidebarWidth});
            }
        }
    }
}