/* Player Challenge Page styles */
.player-challenge{
    p{
        color: white;
        padding-bottom: 32px;
        &.center{
            text-align: center;
        }
    }

    ul{
        color: white;
        margin-top: 0;
    }

    img{
        width: 100%;
    }

    .event-stats{
        margin-bottom: 32px;
        @include breakpoint(desktop, max){
            .headers{
                font-size: 18px;
            }
        }
        @include breakpoint(tablet, max){
            .headers{
                font-size: 15px;
                word-break: break-all;
                hyphens: auto;
            }
        }
    }
}