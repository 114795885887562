.favourting-comp-loader {
        height: calc(100vh - #{184px}); // 184 to account for next button and nav bars
        @include breakpoint(tablet, min, true) {
            height: calc(100vh - #{272px}); // 272 to account for next button and nav bars
        }
}

.favourting-comp {
    @media (width:320px) {
        width: 318px;
    }
    @media only screen and (min-width: (321px)) {
        text-align: center;
    }
    padding: 0px;
    display: table;
    margin: 0px;
    width: 100%;

    @include breakpoint(tablet) {
        position: sticky;
        padding: 0px;
        width: auto;
    }

    .favouriting-error-container {
        height: 200px;
        justify-content: center;
        align-items: center;
        width: 400px;
        display: grid;
    }

    .favourting-no-btn {
        background-color: white;
    }
    .favourting-modal-title {
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: $wim-green;
        letter-spacing: 1px;
    }
    .modal-overlay .modal-wrapper {
        width: 90%;
        .modal-container.my-wimbledon {
            margin: 20px 6px;
            @include breakpoint(tablet) {
                margin: 20px;
            }
        }
        @include breakpoint(tablet) {
            width: 55%;
        }
    }
    .modal-content {
        margin: 0px 8px 20px 8px;
        // margin-bottom: 16px;
        color: $green;
    }
    .generic-button {
        .favourting-yes-btn .favourting-no-btn {
            width: 140px;
            padding: 4px;
        }
        margin: 6px;
        border-radius: 6px;
        .border {
            border-radius: 6px;
        }
    }
    .search-box {
        @media (width:320px) {
            width: 318px;
        }
        height: 31px;
        width: 352px;
        background: #D9D9D999;
        border-radius: 20px;
        margin-top: 8px;
        @include breakpoint(tablet) {
            margin-top: 0px;
        }
        .search-wrapper {
            input {
                background: #D9D9D999;
                border: 1px solid #D9D9D999;
                border-radius: 20px;
                color: $gray-650;
                height: 31px;
            }
            .wim-icon-search {
                top: 16px;
            }

            i.wim-icon-close {
                right: 16px;
                top: 8px;
            }
            
        }
    }

    .favorites-toggle {
        height: 20px;
        margin-top: -8px;
        padding-bottom: 4px;
    }

    .favorites-toggle:before {
        font-size: 22px;
        color: $wim-green;
        padding-left: 5px;
    }
    .content-main {
        background: white;
        padding: 8px 0px 0px 0px;
        @include breakpoint(tablet) {
            padding: auto;
        }
    }
}

.favourting-comp-loading {
    min-height: 300px;
    background: white;
    width: 100%;
}

.favourting-comp-text {
    display: none;
    @include breakpoint(tablet) {
        font-style: italic;
        color: white;
        font-size: 16px;
        margin: 8px;
        display: block;
    }
}

.page-nav-container-fav {
    margin-bottom: 10px;
}

.favourting-star-container {
    display: inline-flex;
    margin: 0px 0px 8px;
    @include breakpoint(tablet) {
        margin: 16px 0px 8px;
    }
}

.favourting-tab-container {
    width: 100%;
    border-radius: 5px;
    display: inline-flex;
    justify-content: center;
    background: #EFEFEF;
    @media (width:320px) {
        width: 320px;
    }
}

.favourting-clear-cntr {
    .generic-button_wrapper {
        height: 50px;
        margin: 0px;
        width: 100%;
    }
}

.generic-button_wrapper {
    &.favourting-tab-clicked {
        background-color: $wim-green;
        color: white;
        border-radius: 5px;
    }

    &.favourting-tab {
        margin: auto 0px;
        line-height: 40px;
        width: 116px;
        cursor: pointer;
        @media (width:320px) {
            text-align: center;
        }
    }
    .clear-all-favourites-btn {
        margin: 8px 0px;
        width: 90%;
    }
}

.favourting-nav {
    @media (width:320px) {
        width: 318px;
    }
    height: 34px;
    display: inline-flex;
    width: 352px;
    align-items: center;
    @include breakpoint(tablet, min, true) {
        width: 100%;
    }
    .favourting-page-nav-list {

        &.white-text{
            .divider:after{
                background: $lines-gray;
            }
        }

        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        list-style: none;
        /* border-bottom: 1px solid #BBBBBB; */
        margin-bottom: 0px;
        width: 100%;
        padding: 0px;;

        margin: auto;
        margin-top: 0px;

        li.page-nav-items {
            &.white-text{
                color: $white;
                font-weight: normal;
                a{
                    color: $white;
                    font-weight: normal;
                }
            }
            &.live-indicator{
                margin-left: 38px;
                &::before{
                    background: rgba(255, 255, 255, 0.25);
                    content: 'LIVE';
                    display: inline-block;
                    position: absolute;
                    top: 2.5px;
                    left: -36px;
                    font-size: 9px;
                    letter-spacing: 1px;
                    color: $wim-black;
                    padding: 2px 2px 1px 2px;
                    line-height: 8.5px;
                    @include gotham(medium);
                    font-weight: 500;
                    @media only screen and (min-width: 1200px) { 
                        padding: 2px 2px 2px 3px;
                    }
                }
                &.is-live{
                    &::before{
                        background: $live-green;
                    }
                }
            }
            cursor: pointer;
            color: $wim-green;
            font-size: 14px;
            font-weight: bold;
            padding-bottom: 4px;
            position: relative;
            @include gotham-book();
            white-space: nowrap;
            text-transform: uppercase;
            margin-top: 10px;

            &.active {
                &.white-text{
                    border-bottom: 2px solid $white;
                }
                border-bottom: 3px solid #00703C;
            }
            @media only screen and (min-width: 1200px) { 
                font-size: 16px;
                &.smaller-text {
                    font-size: 16px;
                }
            }
        }
        li.divider {
            position: relative;
            padding: 0px 20px 0px 20px;
            margin-top: 10px;

            @media (width:320px) {
                padding: 0px 16px 0px 16px;
            }

            &:after {
                position: absolute;
                top: 0;
                height: 20px;
                width: 1px;
                content: '';
                background: grey;
            }
            &:last-child {
                display: none;
            }
        }

        a:hover {
            text-decoration: none;
            padding-bottom: 0px;
            border-bottom-style: solid;
            border-bottom-width: 1.5px;
            width: fit-content;
        }
    }
}

.favourting-star-nav {
    display: inline;
}

// hide scrollbar if alphabetical vertical list is displayed
.favourting-players-list-hide-scroll::-webkit-scrollbar {
    display: none;
  }

.playerimg-lazyload-placeholder {
    width: 57px;
    display: inline-flex;
}

.favourting-players-list-height-small {
    min-height: 250px;
    height: calc(100vh - #{$footerHeightLarge + $footerStripes + $footerMargin + $headerHeightSmall + 153}); // 114 to account for next button and nav bars
    @include breakpoint(tablet, min, true) {
        min-height: 318px;
        height: calc(100vh - #{$footerHeightLarge + $footerStripes + $footerMargin + $headerHeightSmall + 248}); // 248 to account for next button and nav bars
    }
}

.favourting-players-list-height-large {
    min-height: 250px;
    height: calc(100vh - #{$footerHeightLarge + $footerStripes + $footerMargin + $headerHeightSmall + 206}); // 114 to account for next button and nav bars
    @include breakpoint(tablet, min, true) {
        min-height: 318px;
        height: calc(100vh - #{$footerHeightLarge + $footerStripes + $footerMargin + $headerHeightSmall + 298}); // 248 to account for next button and nav bars
    }
}
.favourting-players-list {
    clear: both;
    padding: 0px;
    overflow-y: scroll;
    .favorite {
        cursor: pointer;
        padding-right: 40px;
    }
    ul {
        list-style: none; margin: 0px; padding: 0px;
        .favourting-list-item {
            line-height: 56px;
            border-bottom: 1px solid #eeeeee;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .country-flag {
            padding-right: 12px;
            padding-left: 8px;
            float: left;
            margin-top: 2px;
            img {
                height: 16px;
                width: 26px;
            }
        }
        .player-img-container {
            float: left;
            height: 45px;
            width: 45px;
            font-size: 8px;
            margin: 6px;
        }
    }
    // a { color: #046633; text-decoration: none; float: left }
    span { color: #046633; text-decoration: none; float: left }
    .favorite {
        position: relative;
        margin-right: 24px;
        float: right;
        
        &:after {
            position: absolute;
            left: 0;
            content: "\2606";
            font-size: 24px;
        }
        &.favorited {
            &:after{
                font-size: 24px;
                content: "\2605";
                color: $wim-green;
            }
        }
    }
}

.favourting-letter-header {
    background: $option-hover;
    text-align: left;
    padding-left: 8px;
    font-size: 16px;
    height: 30px;
    padding-top: 5px;
    font-weight: 500;
    img {
        float: left;
        padding: 2px 8px 0px 0px;
    }
}

.alphabet-list-button-disabled {
    cursor: not-allowed;
    background-color: grey;
    opacity: 0.6;
}

.alpha_sidebar {
    position: absolute;
    right: 0px;
    z-index: 1000;
    background-color: white;
    min-height: 250px;
}
.alpha_sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 100%;
    z-index: 1000;
}

.alpha_sidebar li {
    cursor: pointer;
    min-height: 12px;
    @include breakpoint(largeMobile, min, true) {
        height: calc(100% / #{28.5}); // Set the height of each list item
    }
    height: calc(100% / #{26.5}); // Set the height of each list item
    margin: 0px 12px 0px 6px;
    position: relative;

    @include breakpoint(tablet, max, true) {
        height: calc(100% / #{26.5}); // Set the height of each list item
        // margin: 0px 6px;
    }
    @include breakpoint(tablet, min, true) {
        height: calc(100% / #{26}); // Set the height of each list item
    }

    button {
        font-size: 10px;
        @media (width:320px) {
            font-size: 8px;
        }
        @include breakpoint(extraLargeMobile) {
            font-size: 14px;
        }
        position: absolute;
        right: -8px;
        // display: contents;
        top: 0px;
        padding: 0px;
        color: $lines-gray;
        border: none;
        background: none;
        cursor: pointer;
    }
}

.alpha_list{
	padding-right: 22px;
}

.no-favourites-txt {
    padding: 16px;
}

.favourites-tab-ul {
    height: 100%;
}

