.status-page {
	.events-entered {
		max-width: 960px;
		margin: 0 auto;

		.events-not-released {
			display: flex;
			padding: 24px;
			text-align: center;
			@include gotham(medium);
			font-size: px2rem(16px);
			color: $wim-black;
			letter-spacing: 0.5px;
			justify-content: center;
			border: 1px solid #999999;
		}

		.event-box {
			width: 100%;
			background-color: $white;
			border: 1px solid #999999;
			border-top: none;
			padding: 11px 35px;
			position: relative;
			line-height: 1.5;

			.event-name {
				@include gotham(medium);
				text-transform: uppercase;
				font-size: px2rem(16px);
				color: $wim-text-purple;
				cursor: pointer;
			}

			.round-name {
				@include gotham(bold);
				text-transform: uppercase;
				font-size: px2rem(12px);
				letter-spacing: 1px;
			}

			.scheduled-match {
				display: flex;
				letter-spacing: 1px;

				.match-completion-status {
					@include gotham(medium);
					font-size: px2rem(12px);
					&:after {
						content: '\00a0';
					}
					.status {
						@include gotham(bold);
						&:after {
							content: '\00a0';
						}
					}
				}
			}
			.opponent {
				> div {
					@include gotham(medium);
					font-size: px2rem(12px);
				}
			}

			.wim-icon-close {
				position: absolute;
				top: 15px;
				left: 12px;
				color: $wim-green;
				transform: rotate(45deg);
				font-size: px2rem(12px);
				cursor: pointer;
			}
			.expanded-open-icon {
				position: absolute;
				top: 15px;
				left: 6px;
				background-color: $wim-green;
				width: 15px;
				height: 1.5px;
				cursor: pointer;
				margin: 5px;
			}
			.divider-dash {
				width: 17px;
				height: 2px;
				background-color: $wim-text-purple;
				align-self: center;
				margin: 12px 0;
				display: block;
			}
			.expanded-match-view {
				margin: 25px -10px;
				.matchup-box {
					display: flex;
					padding: 20px 12px 12px 12px;
					flex-direction: column;
					border: 1px solid #999999;
					margin-bottom: 21px;

					.match-court {
						color: $wim-text-purple;
						@include gotham(medium);
						font-size: px2rem(18px);
						text-transform: uppercase;
						letter-spacing: 1px;
						align-self: center;
					}

					.player-matchup {
						display: block;
						justify-content: left;
						@include breakpoint(tablet) {
							display: flex;
							justify-content: space-around;
							align-items: center;
						}
						.team1,
						.team2 {
							display: flex;
							flex-direction: column;
							.player1,
							.player2 {
								display: flex;
								flex-direction: row;
								.headshot-wrapper {
									width: 50px;
									margin-right: 15px;
								}
								.name {
									display: flex;
									flex-direction: column;
									@include gotham(medium);
									color: $wim-black;
									letter-spacing: 0.5px;
									.first-name {
										font-size: px2rem(16px);
									}
									.last-name {
										font-size: px2rem(24px);
									}
								}
							}
						}
						@include breakpoint(tablet) {
							.team1,
							.team2 {
								.player1,
								.player2 {
									flex-direction: column;
									align-items: center;
									.name {
										flex-direction: row;
										align-items: center;
										.last-name {
											font-size: px2rem(16px);
											&::before {
												content: '\00a0';
											}
										}
									}
									.headshot-wrapper {
										width: 80px;
										margin: 0;
									}
								}
							}
						}

						.versus {
							@include gotham(medium);
							color: $wim-black;
							font-size: px2rem(14px);
							letter-spacing: 0.5px;
							margin: 10px 15px;
							display: block;
							@include breakpoint(tablet) {
								position: absolute;
								// top: 32%;
								left: 49%;
								margin: 0;
							}
						}
						.match-winner {
							margin-left: 50px;
							color: $wim-green;
							text-transform: uppercase;
							font-size: px2rem(12px);
							letter-spacing: 1px;
							@include gotham(medium);
							@include breakpoint(tablet) {
								margin-left: 25px;
							}
							.wim-icon-wf-checkmark {
								margin-right: 3px;
							}
						}
					}
					.slamtracker-button {
						margin-top: 31px;
						@include breakpoint(tablet) {
							width: 200px;
							margin: 0 auto;
							margin-top: 48px;
						}
						.generic-button {
							border-radius: 5px;
						}
					}
				}
				.ptf-wrapper {
					overflow: hidden;
					border: 1px solid #999999;
					.path-to-final-header {
						@include gotham(medium);
						text-transform: uppercase;
						font-size: px2rem(18px);
						color: $wim-text-purple;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						margin-top: 20px;
						letter-spacing: 1px;
						.divider-dash {
							margin-bottom: 15px;
						}
					}

					.path-to-the-final-wrapper {
						overflow: scroll;
						width: 100%;
						.path-to-the-final {
							width: 100%;
							.draws-path {
								@media only screen and (max-width: 767px) {
									.slamtracker-button {
										max-width: 90%;
										margin-left: -8px;
										.generic-button {
											border-radius: 4px;
										}
									}
								}
							}

							@include breakpoint(tablet) {
								// width: calc(100% - 60px);
							}
							@include breakpoint(desktop) {
								// width: calc(100% - 110px);
							}
						}
					}
				}
			}
		}
	}
}
