.select-menu{
    width: 100%;
    position: relative;
    //max-width: 455px;
    margin: auto;

    .title{
        display: inline-block;
        position: relative;
        text-align: center;
        width: 100%;
        height: 30px;
        border: 0px;
        color: $wim-green;
        text-decoration: none;
        text-align: center;
        text-transform: uppercase;
        padding: 0 25px 0 8px;
        opacity: 1;
        cursor: pointer;
        @include gotham(medium);
        font-size: 18px;
		letter-spacing: 1px;
		background-color: unset;

        &.border {
            border: 1px solid $wim-green;
		}

		.menu-arrow {
			font-size: 18px;
			line-height: 10px;
		}
    }

    &.openLeft {
        .select-menu-dropdown {
            right: 0px;
        }
    }
    &.openRight {
        .select-menu-dropdown {
            left: 0px;
        }
    }
    .animation-container {
        height: 400px;
        overflow: hidden;
        position: absolute;
        width: fit-content;
        margin-left: 50%;
        transform: translateX(-50%);
        z-index: 100;
        visibility: hidden;
        overflow-y: scroll;
        &.show {
            visibility: visible;
        }
        .select-menu-dropdown {
            transition: 0.6s cubic-bezier(0, 1, 0.5, 1);
            position: relative;
            width: 100%;
            padding: 0 3px !important;
            transform: translate(-50%, -100%);
            &.show {
                transform: translate(-50%, 0%);
            }
            @include hideScrollbar;
        }
    }

    &-dropdown{
        background: $white;
        border: 1px solid $select-menu-border;
        position: absolute;
        z-index: 100;
        padding: 0 10px;
        @include gotham(medium);
        font-size: 15px;
        // max-height: 400px;
        overflow: scroll;
        margin-left: 50%;
        transform: translate(-50%, -100%);

        .option{
            display: block;
            color: $wim-black;
            margin: 8px 0px 5px 15px;
            padding: 0;
			text-align: left;
			background-color: unset;

            &.clicked{
                i{
                    visibility: visible;
                    color: $wim-green;
                }
            }
        }

        i{
            visibility: hidden;
            font-size: 10px;
            text-align: left;
            position: absolute;
            left: -15px;
            top: 4px;
        }

        button {
            flex: 0 0 auto;
            position: relative;
            white-space: nowrap;
            @include gotham(medium);
            font-size: 15px;

            text-decoration: none;
            border: 0;
            cursor: pointer;
        }

        .section {
            text-align: center;
            border-bottom: 1px solid $wim-green;
        }
    }
}
