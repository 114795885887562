/** player-competitor-list styles **/
.player-competitor-list {
    .scores-select-menu {
        .select-menu {
            button.title { text-align: center; }
        }
    }

    @include breakpoint(tablet) {
        .scores-select-menu { 
            width: 200px; 

            .select-menu {
                width: 200px;
                
                button.title { width: 200px; }            
            }
        }
    }

    @mixin a-competitor-list{
        .row {
            font-size: 13px;

            .cell.first-col {
                padding-left: 10px;
            }
        }

        &.page-one {
            .late-col {
                display: none;
            }

            .right-nav-dot{
                opacity:.4;
            }

            .left-icon::before{
                opacity:.35;
            }
        }

        &.page-two {
            .early-col {
                display: none;
            }

            .left-nav-dot{
                opacity:.4;
            }

            .right-icon::before{
                opacity:.35;
            }
        }

        .datatable {
            .title {
                font-size: 16px;
            }
        }

        .table-title {
            font-size: 13px;
            display: flex;
            flex-wrap: wrap;

            i {
                display: inline;
                font-size: 24px;
                padding: 5px 0;
            }

            a {
                flex: 1 0 auto;
                color: $white;
            }

            .left-icon.wim-icon-left-arrow::before {
                float: right;
                margin-right: 20px;
            }

            .right-icon.wim-icon-right-arrow::before {
                float: left;
                margin-left: 20px;
            }

            .left-nav-dot, .right-nav-dot {
                display: inline;
                color: $white;
                width: 10px;
                flex: 0 0 auto;
                padding-top: 4px;
            }
        }
    }

    &.one-col {
        @include a-competitor-list;
    }

    &.two-col {
        @include breakpoint(tablet,max) {
            @include a-competitor-list;
        }
    }

    &.three-col {
        @include breakpoint(tablet,max) {
            @include a-competitor-list;
        }
    }

    &.four-col {
        &.seeds {
            @include breakpoint(tablet,max) {
                @include a-competitor-list;
            }
        }
    }

    .table-title {
        i {
            display: none;
        }
    }

    .left-nav-dot, .right-nav-dot {
        display: none;
    }

    .table-title {
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    .datatable {
        width: 100%;
        border: 2px solid $wim-green;
        border-collapse: collapse;
        overflow: hidden;
        padding: 0px 13px;

        .title{
            font-size: 18px;
        }
    }

    .cell {
        text-align: left;
    }

    .row {
        font-size: 14px;
        height: 30px;
        text-align: center;

        .cell:first-child {
            padding-left: 13px;
        }
        .cell:last-child {
            padding-right: 13px;
        }
    }
        
    .headers {
        font-size: 20px;
        height: 58px;
        text-align: left;

        .cell:first-child {
            padding-left: 13px;
        }
        .cell:last-child {
            padding-right: 13px;
        }
    }

    &.singles, &.doubles {
        .row, .headers {
            width: 100%;

            .cell:first-child {
                width: 30%;
            }
            .cell:last-child {
                width: 70%;
            }
        }
    }
}