.search--result-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: $select-menu-text;
    line-height: 1em;

    &-title{
        @include gotham(medium);
        font-size: 18px;
        color: $wim-green;
        margin-bottom: 4px;
    }

    &-description{
        @include gotham-book();
        font-size: 16px;
        color: $black;
        margin-bottom: 4px;
    }

    &-date{
        @include gotham(medium);
        font-size: 12px;
        color: $wim-purple;
        margin-bottom: 8px;
    }

    a {
        color: unset;
    }
}