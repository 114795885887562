/** mobile styles **/
.one-third {
    width: calc(100%);
    @include breakpoint(desktop, min) {
        width: calc(33.33%);
    }
}

.mobile-top-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 0px 20px;
    flex-wrap: wrap;
    .iPhone {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        order: 1;
        @include breakpoint(tablet, min) {
            width: 14.3%;
        }
        img {
            width: 50%;
            @include breakpoint(tablet, min) {
                width: 100%;
            }
        }
    }
    .appleTV {
        width: 100%;
        order: 3;
        @include breakpoint(tablet, min) {
            width: 38%;
            order: 2;
        }
        .device-name {
            margin-top: 20px;
            @include breakpoint(tablet, min) {
                margin-top: 32px;
            }
        }
        img {
            width: 100%;
        }
        // @include breakpoint(tablet, min) {
        //     width: 298px;
        // }
        // @include breakpoint(desktop, min) {
        //     width: 38%;
        //     width: 507.98px;
        // }
    }
    .android {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        order: 2;
        @include breakpoint(tablet, min) {
            width: 14.3%;
            order: 3;
        }
        img {
            width: 50%;
            @include breakpoint(tablet, min) {
                width: 100%;
            }
        }
    }
}

.no-padding {
    &.top {
        padding-top: 0px !important;
    }
    &.bottom {
        padding-bottom: 0px !important;
    }
    &.left {
        padding-left: 0px !important;
    }
    &.right {
        padding-right: 0px !important;
    }
}

.mobile{
    text-align: center;
    font-size: 1em;
    @include breakpoint(desktop, max){
        font-size: 0.8em;
    }

    &-bottom{
        order: 4;
        width: calc(100% + 32px);
        margin-left: -16px;
        margin-right: -16px;
        margin-top: 32px;
        .image-gallery {
            margin: 0px 10px;
            @include breakpoint(tablet, min) {
                margin: 0px 17px;
            }
        }
        .image-gallery-slide {
            img {
                height: unset;
				width: 100%;
                @include breakpoint(tablet, min) {
                    height: unset;
					width: 80%;
                }
                @include breakpoint(desktop, min) {
                    width: 70%;
                }
            }
        }
        @include breakpoint(desktop){
            width: calc(100% + 76px);
            margin-left: -38px;
            margin-right: -38px;
        }
        background-image: url('/assets/images/mobile/mobileFeaturesBG.png');
        background-repeat: no-repeat;
        background-size: cover;
        .featuresInclude{
            text-transform: uppercase;
            color: white;
            text-align: center;
            margin-top: 26px;
            font-size: 1.3em;
        }
        .desktopView{
            text-align: center;
            padding-bottom: 30px;
            @include breakpoint(desktop, max){
                display: none;
            }
            .slideItem{
                display: inline-block;
                margin: 0 10px;
                width: 19%;
				

                .slideImage{
                    img{
                        width: 100%;
                    }
                }
                .slideTitle{
                    padding: 20px;
                    text-transform: uppercase;
                    color: white;

                    span {
                        text-transform: lowercase;
                    }
                }
            }
        }
    }

    .device-name{
        @include gotham(medium);
        text-transform: uppercase;
        color: $wim-green;
        margin-top: 32px;
        height: 50px;
        @include breakpoint(desktop, max){
            height: 32px;
        }
    }

    .download-info{
        @include helveticaneue(medium);
        padding-top: 32px;
        color: $gray-600;
    }
}