.event-stats{
    
    @mixin a-event-stats{
        .row{
            font-size:13px;

            .cell.first-col{
                padding-left:10px;
            }
        }

        &.page-one{
            .late-col{
                display:none;
            }

            .right-nav-dot{
                opacity:.4;
            }

            .left-icon{
                opacity:.35;
            }
        }
        &.page-two{
            .early-col{
                display:none;
            }

            .left-nav-dot{
                opacity:.4;
            }

            .right-icon{
                opacity:.35;
            }
        }

        .title{
            font-size:16px;
        }

        .headers{
            .first-col{
                width:165px;
            }
        }

        .table-title{
            font-size:13px;
            display: flex;
            flex-wrap: wrap;

            i{
                display:inline;
                font-size:24px;
                padding:5px 0;
            }

            a{
                flex: 1 0 auto;
                color:$black;
            }

            .left-icon.wim-icon-left-arrow::before{
                float:right;
                margin-right: 20px;
            }

            .right-icon.wim-icon-right-arrow::before{
                float:left;
                margin-left: 20px;
            }

            .left-nav-dot,.right-nav-dot{
                display:inline;
                color:$wim-green;
                width:10px;
                flex: 0 0 auto;
                padding-top:4px;
            }
            .title-text{
               text-align:center;
               flex: 1 0 auto; 
               width:100%;
            }
        }
    }

    &.one-col{
        @include a-event-stats;
    }

    &.two-col{
        @include breakpoint(tablet,max){
            @include a-event-stats;
        }
    }

    &.three-col{
        @include breakpoint(tablet,max){
            @include a-event-stats;
        }
    }

    &.four-col{
        @include breakpoint(tablet,max){
            @include a-event-stats;
        }
    }

    i{
        display:none;
    }

    .left-nav-dot,.right-nav-dot{
        display:none;
    }

    .table-title{
        text-transform:uppercase;
        text-align:center;
    }

    .datatable{
        width:100%;
        border:2px solid $wim-green;
        border-collapse:collapse;
        overflow: hidden;
    }

    .row{
        font-size:14px;
        height:30px;
        text-align:center;
        .cell{
            &.first-col{
                text-align:left;
                padding-left:15px;
            }
        }
    }
        
    .headers{
        font-size:20px;

        .first-col{
            width:225px;
        }
    }
        
    .title{
        font-size:18px;
    }

    .title-text {
        text-align: center;
        width: 100%;
    }
}