.generic-button_wrapper {
	margin-bottom: 10px;
	cursor: pointer;
	button{
		all: unset;
	}
	
	.generic-button{
		&:focus-visible{
			outline: -webkit-focus-ring-color auto 1px;
		}
	}
	&.center {
		float: none;
	}

	a {
		text-decoration: none;

		&:hover, &:active, &:visited {
			text-decoration: none;
		}
	}
}

.disabled{
	opacity: .5;
	cursor: default !important;
}

.disabled_generic-button_wrapper {
	cursor:not-allowed;
	pointer-events:none;
	opacity: 0.6;
}

.generic-button_wrapper > .generic-button,
a > .generic-button {
	min-width: 100px;
	width: auto;
	// width: 200px;
	height: 35px;
	line-height: 20px;
	margin: auto;
	position: relative;
	@include gotham(medium);
	font-size: 16px;
	cursor: pointer;
	display: inline-block;
		
	.content {
		color: $wim-green;
		text-transform: uppercase;
		text-align: center;

		// position: absolute;
		position: relative;
		margin: auto;
		width: 100%;
		height: 100%;
		// padding-top: 8px;
		white-space: nowrap;
		padding: 8px 12px 0 12px;
	}

	&.auto {
		width: auto;
		display: inline-block;

		.content {
			color: $white;
			white-space: nowrap;
			position: relative;
			padding: 9px 12px 0px 12px;
		}
	}

	&.myw {
		width: 220px;

		&.center {
			margin: auto;
			display: block;
		}

		.content {
			text-transform: none;
		}
	}

	&.auto {
		width: auto;
    	display: inline-block;

		.content{
			text-transform: none;
			white-space: nowrap;
			position: relative;
			padding: 9px 12px 0px 12px;
		}
	}

	.border {
		position: absolute;
		width: 100%;
		height: 100%;
		border: 1px solid $wim-green;
		top: 0;
		left: 0;
		// box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.5);
		//box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.7);
		//box-shadow: 0 1px 1px 0 red;

		&:hover {
			border: 2px solid $wim-green;
			cursor: pointer;
		}
	}

	&:active {
		background-color: $wim-green;
		
		.content {
			color: $white;
		}
		.border {
			border: unset;
		}
	}

	&.solid {
		background-color: $wim-green;

		.content {
			color: $white;

			&:hover {
				background-color: $button-hover;
				text-decoration: none;
			}
			&:active {
				background-color: $button-active;
				text-decoration: none;
			}
		}

		.border {
			display: none;
		}

		&.disabled{
			.content {
				&:hover {
					background-color: $wim-green;
				}
				&:active {
					background-color: $wim-green;
				}
			}
		}
	}

	&.outlineWhite{
		height: 40px;
		.content{
			color: $white;
			padding: 12px 20px 0 20px;
		}
		.border{
			border: 2px solid $white;
		}
	}

	&.solidWhite {
		background-color: $white;

		.content {
			color: $wim-green;

			&:hover {
				//background-color: $button-hover;
				text-decoration: none;
			}
			&:active {
				//background-color: $button-active;
				text-decoration: none;
			}
		}

		.border {
			display: none;
		}
	}

	&.radius {
		border-radius: 10px;
	}

	&.compact {
		background-color: $wim-green;
		height: 25px;
		line-height: 14px;
		.content {
			color: $white;
			font-size: 11px;
			padding-top: 6px;
		}
		&:hover {
			background-color: $button-hover;
		}
		&:active {
			background-color: $button-active;
		}
		.border {
			display: none;
		}
	}
	
	&.purple {
		background-color: $wim-text-purple;

		.content {
			color: $white;

			&:hover {
				background-color: $button-hover-purple;
			}
			&:active {
				background-color: $button-active-purple;
			}
		}

		.border {
			display: none;
		}

		&.disabled{
			.content {
				&:hover {
					background-color: $wim-text-purple;
				}
				&:active {
					background-color: $wim-text-purple;
				}
			}
		}
	}

  &.trans-20 {
    background-color: rgba(255, 255, 255, 0.2);
    .content {
      color: #FFFFFF;
      border-radius: 5px;
    }
    &:hover {
      // text-decoration: none;
      background-color: rgba(255, 255, 255, 0.2);
    }  
  }

  &.border-r5 {
    border-radius: 5px;
  }
}

.generic-button_wrapper {
	&.cms {
		text-align: center;

		.generic-button {
			width: auto;
			display: inline-block;

			.content {
				white-space: nowrap;
				position: relative;
				padding: 0 12px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

.generic-button_alt_text {
	text-align: center;

	font-size: 18px;
	font-weight: bold;

}

.slamtracker-stats{
	&.white{
		border: 1px solid #176B3D;
		border-radius: 5px;
		background-color: $white !important;
		height: 30px !important;
		.content{
			font-size: 14px !important;
			line-height: 18px;
			text-align: center;
			letter-spacing: 0.583333px;
			color: #176B3D !important;
			text-transform: uppercase;
		}
	}
}

/** ButtonTextLink styles */
.button-text-link {
	
	button{
		// all: unset;
		background-color: unset;
		border: unset;
		color: unset;
		font-size: unset;
		font-family: unset;
		text-transform: unset;
	}

	font-family: inherit;
	font-size: inherit;
	color: $wim-green;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}

	&:focus-visible{
		outline: -webkit-focus-ring-color auto 1px;
	}

	&.center {
		text-align: center;
	}

	&.bold {
		font-weight: bold;
	}

	&.disabled{
		cursor:not-allowed;
		pointer-events:none;
		opacity: 0.6;
	}
}
