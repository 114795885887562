.page {
    .wrapper .content-main {
		&.role-registration {
            
            .news--header-title {
                h1 {
					margin-bottom: 10px;
					text-transform: uppercase;
                    &::after {
						display: none;
                    }
				}
				@include breakpoint(tablet, max) {
                    h1 {
                        font-size: 20px;
                        letter-spacing: .5px;
                    }
                }
            }
			
			.news--header-shorttitle {
                h1 {
					//margin-bottom: 10px;
					text-transform: uppercase;
                    &::after {
						display: none;
                    }
				}
				@include breakpoint(tablet, max) {
                    h1 {
                        font-size: 20px;
                        letter-spacing: .5px;
                    }
                }
			}
			
			.column-layout {
				&.role-registration {			
					@include gotham-book();
					line-height: 24px;

					.question {
						div {
							text-align: center;
							font-size: 16px;
							line-height: 26px;
						}
						div.highlight {
							color: $wim-green;
							font-weight: bold;
						}
						div.email {
							font-size: 14px;
							font-weight: bold;
						}
					}

					.heading {
						font-size: 24px;
						margin-bottom: 10px;
						text-align: center;
					}
					.body {
						text-align: center;
						width: 80%;
						margin-left: auto;
						margin-right: auto;
						//margin-bottom: 5px;


						.tm-links {
							a {
								clear: left;
								display: block;
								line-height: 28px;
							}

							.link-sep {
								display: none;
							}
						}

						@include breakpoint(tablet) {
							.tm-links {
								a {
									clear: unset;
									display: inline;
								}
	
								.link-sep {
									display: inline;
								}
							}
						}
					}

					.center {
						text-align: center;
					}

					.secDividerContent {
						margin: 15px 0;
					}

					.selectMenu {
						background: rgba($white, 0.5);
						border: 2px solid $black;
						margin: 10px 10px 15px 10px;
						border-radius: 3px;
						color: $black;
						@include gotham_book;
						font-size: calc((16/16)*1em);
						height: 36px; // for ff
						line-height: 32px;
						outline: 0;
						padding-left: 1em;
						//text-transform: uppercase;
						text-align: center;
						text-align-last: center;	
						width: 300px;
						display: inline-block; 
					}

					.textArea {
						background: rgba($white, 0.5);
						border: 2px solid $black;
						margin: 10px 10px 15px 10px;
						border-radius: 3px;
						color: $black;
						@include gotham_book;
						font-size: calc((16/16)*1em);
						height: 150px; // for ff
						width: 300px;
						outline: 0;
					}

					.link-sep {
						margin: 0 10px;
					}

					 @include breakpoint(tablet, min){
						.two-col {
							width: 50%;
							margin-left: calc(25% + #{$item-h-margin});
						}
					}
				}
			}

			.generic-button {
				&.role-registration {
					display: inline-block;

					.content{
						text-transform: uppercase;
						white-space: nowrap;
						position: relative;
						padding: 9px 12px 0px 12px;
					}
				}
			}

		}

		.ballot-container {
			div {
				position: relative;
			}

			.status-line {
				width: 1px;
				background-color: $lines-gray;
				height: 100%;
				position: absolute;
				display: block;

				.ball {
					background-color: $lines-gray;
					width: 11px;
					height: 11px;
					border-radius: 20px;
					position: absolute;
					display: block;
					top: -5px;
					left: -5px;
				}

				.base {
					background-color: $lines-gray;
					width: 11px;
					height: 1px;
					position: absolute;
					display: block;
					bottom: 0px;
					left: -5px;
				}
			}

			.status-container {
				margin-left: 35px;
				margin-top: 10px;
				float: left;

				.status-detail {
					@include gotham(medium);
					font-size: 16px;
					text-transform: uppercase;
					margin-bottom: 5px;

					.wim-icon-check {
						display: none;
					}

					&.checked {
						color: $wim-green;
						position: relative;
						left: -16px;

						.wim-icon-check {
							display: inline-block;
							font-size: 12px;
							margin-right: 3px;
						}
					}
				}

				.status-message {
					@include gotham();
					font-size: 18px;
					margin-bottom: 15px;
					display: inline-block;

					.status-text {
						margin: 10px 0;
						font-size: 16px;
					}

					.status-link-sep {
						margin: 0 10px;
					}

					.generic-button_wrapper {
						padding-top: 5px;
						margin-right: 20px;

						.generic-button {
							width: auto;

							.content {
								padding-left: 10px;
								padding-right: 10px;
							}
						}
					}
				}
			}

			.load-wrapper {
				text-align: center;
				margin-top: 40px;
			}
		}

	}
}
