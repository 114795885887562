.pull-quote{
    position: relative;
    margin: 0;
    text-transform:uppercase;
    border-top: 1px solid $wim-purple;
    border-bottom: 1px solid $wim-purple;
    padding: 25px 0;

    i{
        color:$wim-purple;
        display:inline-block;
        font-size: 12px;
    }

    .quote-text{
        margin-top: 10px;
        color:$wim-purple;
        display:block;
    }

    .quote-author{
        float:left;
        display:block;
        margin-top: 9px;
    }
}