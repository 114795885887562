.slamtrackerPanel {
	display: none;
	color: #000000;
	z-index: 110;
	position: absolute;
	right: 0px;
	top: 0px;

	.content-main{
		.stub_box{
			width: 100% !important;
			left: 0 !important;
			.text{
				color: $wim-green;
			}
		}
	}

	// sidepanel always open with centered max width content
	@include breakpoint(extraLargeDesktop, min, true) {
		right: calc(((100vw - #{$maxWidth}) / 2));
	}

	.stub_box{
		width: 100% !important;
		margin: 0 auto !important;
		left: unset !important; 
		.text{
			color: $black !important;
		}
	}

	a.skip-nav {
		left: -999px;
		position: absolute;
		top: 0;
		width: 1px;
		height: 1px;
		overflow: hidden;
		z-index: -999;
	}

	a.skip-nav:focus,
	a.skip-nav:active {
		color: $white;
		background-color: $wim-green;
		left: 0;
		top: 0;
		width: 180px;
		height: auto;
		overflow: auto;
		margin: 5px;
		padding: 5px;
		text-align: center;
		font-size: px2rem(14);
		z-index: 999;
	}

	&.show {
		display: block;

		width: $sidebarWidth;
		min-width: $sidebarWidth;

		// @include breakpoint(tablet, min, true) {
		// 	display: block;
		// }
	}

	&-content {
		@include breakpoint(tablet, max, true){
			width: 100%;
			left: 0;
		}
		width: $slamtrackerContentWidth;
		height: 100vh;
		// padding: 0 5px 0 12px;
		// background-color: $white;
		color: $black;
		position: fixed;		
		display: flex;
		flex-direction: column;
		overflow-x: hidden;
		overflow-y: scroll;
		@include breakpoint(tablet, min, true){
			border-left: 1px solid $select-menu-border;
		}
		&::-webkit-scrollbar{
			display: none;
		}
		.player-img-container img.player-image.small, .player-img-container img.player-image.xsmall, .match-box-container.usePlayerImages .team-img-container{
			display: none;
		}
		.match-box-container.usePlayerImages .match-box, .match-box-container.usePlayerImages .team-img-container{
			padding: 0 !important;
		}
		.unit-toggle{
			a{
				text-transform: uppercase;
				@include gotham(medium);
				font-size: 14px;
				line-height: 13px;
				letter-spacing: 0.5px;
				margin-right: 8px;
				cursor: pointer;
				&.selected{
					border-bottom: 3px solid $wim-green;
					cursor: default;
				}
				&:hover{
					text-decoration: none;
				}
			}
		}
		.slamtracker-content{
			.featured-match{
				// margin-bottom: 20px;
        .slamtracker-scoreboard{
          margin-top: 10px;
          .match-box-container {
            .match-location, .upset{
              display: none;
            }
            .event-info{
              text-align: left;
            }
          }
        }
			}
		}
		&-channel {
			@include gotham(medium);
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 19px;
			letter-spacing: 0.5px;
			text-transform: uppercase;
			padding-top: 23px;
			padding-bottom: 20px;
			color: #fff;
      		text-align: center;
		}
		.page-nav-container{
			.page-nav-list{
				li.divider{
					visibility: visible;
    				padding: 0px 5px 0px 5px !important;
				}
				.stpanel-nav{
					@include gotham(medium);
					font-style: normal;
					font-size: 14px;
					line-height: 13px;
					letter-spacing: 0.5px;
				}
			}
		}
	}

	&-close {
		display: block;
		position: absolute;
		top: 10px;
		right: 18px;
		width: 18px;
		height: 18px;
		color: $black;
		font-size: 18px;
		z-index: 1;
		cursor: pointer;

		a:link,
		a:visited,
		a:hover {
			text-decoration: none;
			color: $black;
		}

	}
	.slamtrackerPanel-content {
		z-index: -2;
		@include background-2x('https://assets.wimbledon.com/images/backgrounds/scores', 'jpg', auto, auto, 0 0);
		&.vid-open{
			overflow: unset;
			#st-content-area{
				z-index: 3;
			}
		}
    .slamtrackerPanel-close{
      color: #fff;
    }
	}
}


// primary container for content width
// when slamtracker panel is open at less than sidepanel sizes
.page.compressed {
    .wrapper {

		width: calc(100% - #{$sidebarWidth});

		@include breakpoint(slamtrackerPanel, mid){
			.video-wrapper.modal, .video-direct-wrapper, .video-wrapper-header {
				.video-player {
					width: calc(100% - #{$sidebarWidth});  
				}
				i.wim-icon-close {
					right: calc(#{$sidebarWidth} + 8px);  
				}
			}

			.logotitle-txt {
        		display: none;
			}
		
			.news-landing-filters{
				width: 285px;
				display: flex;
				justify-content: center;

				.filter-buttons{
					display: none;
				}

				.filter-dropdown {
					display: flex;
				}
			}
			
			.wim-logo {
				margin: auto;
				width: 60px;
				height: 60px;
				@include background-2x('/assets/images/logos/Wimbledon_logo_small', 'png', 60px, 60px, top left );
			}

			.logosearch {
				// margin: 25px 15px 0 0;
				// i {
				// 	font-size: 20px;
				// 	margin-right: 10px;
				// }
				.logo-small{ display: inline-block; }
        		.logo-standard{ display: none; }

				.login {
					margin: unset;
					.name {
						font-size: 12px;
					}
				}
			}

			// .mainnav {
			// 	button {
			// 		margin: 25px 9px 0px 16px;
        	// 		padding-top: 4px;
			// 	}
			// }

			.status-panel {
				.rolex-clock {
					display: none;
				}
			}

		}
	}
}

