@mixin aspect-ratio($width, $height) {
	position: relative;
	&:before {
		display: block;
		content: '';
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}
	> .player-image {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}
.player-info {
	width: 100%;
}
/** player-general-info styles **/
.player-profile-info-webview {
	display: flex;
	flex-wrap: wrap;

	.general-info-item {
		position: relative;
		width: 100%;
		margin: 0px;
		.player-profile-header {
			display: flex;
			background-image: url('/assets/images/player-profile/playerProfile_header.jpg');
			flex-wrap: wrap;
			flex-direction: row;
			padding-top: 30px;
			@include breakpoint(desktop) {
				flex-wrap: nowrap;
				padding-top: 67px;
			}

			.player-headshot {
				order: 3;
				margin: 0 auto;
				> div {
					width: 320px;
				}
				@include breakpoint(extraLargeMobile) {
					> div {
						width: 388px;
					}
				}
				@include breakpoint(desktop) {
					order: 0;
					> div {
						width: 554px;
					}
				}
			}

			.player-profile-left {
				width: 50%;
				display: flex;
				flex-direction: column;
				justify-content: space-around;

				@include breakpoint(desktop) {
					width: 100%;
				}

				.flag-player-name {
					width: 100%;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					margin-left: 24px;
					@include breakpoint(desktop) {
						margin-left: 50px;
					}
					.player-flag {
						margin-right: 8px;
						margin-top: 4px;
						width: 55px;
						align-self: flex-start;
						> img {
							width: 100%;
						}
					}
					.player-name {
						display: flex;
						flex-direction: column;
						color: $white;
						@include gotham(medium);
						.first-name {
							font-size: px2rem(14px);
							@include breakpoint(tablet) {
								font-size: px2rem(16px);
							}
							@include breakpoint(desktop) {
								font-size: px2rem(20px);
							}
						}
						.last-name {
							font-size: px2rem(24px);
							@include breakpoint(tablet) {
								font-size: px2rem(28px);
							}
							@include breakpoint(desktop) {
								font-size: px2rem(36px);
							}
						}
					}
				}

				.singles-ranking {
					color: $white;
					margin-left: 24px;
					margin-top: 15px;
					@include breakpoint(desktop) {
						margin-left: 50px;
						margin-top: 0;
					}
					.ranking {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						.value {
							@include gotham(medium);
							margin-right: 4px;
							font-size: px2rem(32px);
							@include breakpoint(tablet) {
								font-size: px2rem(72px);
							}
							@include breakpoint(desktopSidePanel) {
								font-size: px2rem(100px);
							}
						}
						.field {
							text-transform: uppercase;
							@include gotham(medium);
							color: rgba(255, 255, 255, 0.75);
							font-size: px2rem(12px);
							@include breakpoint(tablet) {
								font-size: px2rem(24px);
							}
							@include breakpoint(desktopSidePanel) {
								font-size: px2rem(33px);
							}
						}
					}
				}
			}

			.player-profile-right {
				width: 50%;
				display: flex;
				flex-direction: column;
				justify-content: space-around;

				@include breakpoint(desktop) {
					width: 100%;
				}
				.doubles-ranking {
					color: $white;
					margin-right: 24px;
					text-transform: uppercase;
					margin-top: 15px;
					@include breakpoint(desktop) {
						margin-right: 50px;
						margin-top: 0;
					}
					.ranking {
						display: flex;
						justify-content: flex-end;
						align-items: center;
						.value {
							@include gotham(medium);
							margin-right: 4px;
							font-size: px2rem(32px);
							@include breakpoint(tablet) {
								font-size: px2rem(72px);
							}
							@include breakpoint(desktopSidePanel) {
								font-size: px2rem(100px);
							}
						}
						.field {
							@include gotham(medium);
							color: rgba(255, 255, 255, 0.75);
							font-size: px2rem(12px);
							@include breakpoint(tablet) {
								font-size: px2rem(24px);
							}
							@include breakpoint(desktopSidePanel) {
								font-size: px2rem(33px);
							}
						}
					}
				}
				.fav-star-player-profile {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					margin-right: 24px;
					@include breakpoint(desktop) {
						margin-right: 50px;
					}
					a:hover {
						text-decoration: none;
					}
					.favorite {
						&:after {
							left: 0;
							content: '\2606';
							font-size: 25px;
							color: $white;
							@include breakpoint(tablet) {
								font-size: 30px;
							}
						}

						&.favorited {
							&:after {
								content: '\2605';
								font-size: 25px;
								color: #f9e300;
								@include breakpoint(tablet) {
									font-size: 30px;
								}
							}
						}
					}
				}
			}
		}
	}
}
