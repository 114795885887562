.shop-carousel-container{
    width: 100%;
    margin: 15px auto 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    // margin: 0 47px;

    .view-more{
        position: absolute;
        top: 0;
        right: 5%;
        @include breakpoint(tablet, max){
            top: unset;
            right: unset;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            margin-bottom: 8px;
			position: relative;
        }
        @include gotham(medium);
        font-size: 12px;
        letter-spacing: 0.857143px;
        text-transform: uppercase;
        a{
            display: flex;
            align-items: center;
            justify-content: center;   
            .wim-icon-triangle-up{
                display: block;
                transform: rotate(90deg);
                font-size: 6px;
            }
        }
    }
    .shop-header{
        text-align: center;
        text-transform: uppercase;
        @include gotham(medium);
        font-size: 20px;
        letter-spacing: 0.5px;
        color: $wim-purple;
    }
    .shop-header-stripe{
        width: 30px;
        height: 2px;
        border-style: none;
        background-color: $wim-purple;
        margin: 5px auto 10px auto;
    }


	.generic-carousel {
		&.shop-carousel {
			width: 90%;
			margin: auto;
				
			.slick-list {
				position: relative;
				display: inline-block;
				width: calc(100% - ($slickButtonWidth * 2));
				left: $slickButtonWidth;
			}

			.slickPrevArrow,
			.slickNextArrow {
				position: absolute;
				top: 0px;
				width: $slickButtonWidth;
			}

			.slick-prev, .slick-next {
				&:before {
					font-size: 38px;
					color: $carousel-text-color;
				}
			}
			.slick-prev{
				right: 5px;
			}
			.slick-next {
				left: 5px;
			}
			
			a, a:hover{
				text-decoration: none;
			}
			.shop-item{
				padding: 2px 20px 20px 20px;
				.shop-item-details{
					@include gotham(medium);
					color: $wim-purple;
					font-size: 13px;
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;
					.shop-item-img{
						width: auto;
						max-width: 250px;
						height: 140px;
						display: flex;
						justify-content: center;
						margin-bottom: 15px;
						img{
							height: 100%;
						}
					}
					.shop-item-stripe{
						width: 15px;
						height: 2px;
						border-style: none;
						background-color: $wim-purple;
						margin: 5px auto;
					}
				}
			}
		}
    }
}