.profile-tab {
	.player-profile-info {
		display: flex;
		width: 100%;
		// max-width: 911px;
		margin: 0 auto;
		padding: 0 25px;
		@include breakpoint(tablet) {
			padding: 0 109px;
		}

		.profile-col1 {
			color: $wim-black;
			width: 100%;
			margin-bottom: 40px;
			@include breakpoint(desktop) {
				width: 50%;
			}
			.stat-line {
				font-size: px2rem(12px);
				width: 100%;
				display: flex;
				letter-spacing: 0.5px;
				@include breakpoint(tablet) {
					font-size: px2rem(16px);
					line-height: 1.5rem;
				}
				.stat-field {
					width: 50%;
					@include gotham_book();
					@include breakpoint(desktop) {
						width: 40%;
					}
				}
				.stat-value {
					width: 50%;
					@include gotham(medium);
					@include breakpoint(desktop) {
						width: 40%;
					}
				}
			}
		}

		.profile-col2 {
			color: $wim-black;
			width: 100%;
			@include breakpoint(desktop) {
				width: 50%;
			}
			.stat-line {
				font-size: px2rem(12px);
				width: 100%;
				display: flex;
				letter-spacing: 0.5px;

				@include breakpoint(tablet) {
					font-size: px2rem(16px);
					line-height: 1.5rem;
				}
				.stat-field {
					@include gotham_book();
					width: 50%;
					@include breakpoint(desktop) {
						width: 60%;
					}
				}
				.stat-value {
					@include gotham(medium);
					width: 50%;
					@include breakpoint(desktop) {
						width: 40%;
					}
				}
			}
		}
	}

	.player-results {
		padding: 0 25px 24px;
		> span {
			flex-direction: column;
		}
		@include breakpoint(tablet) {
			padding: 0 109px;
		}
		@include breakpoint(desktop) {
			padding: 48px 109px 33px;
			> span {
				margin: 0;
				gap: 25px;
				flex-direction: row;
			}
		}

		.singles-results-table {
			margin: 24px 0;
			@include breakpoint(tablet) {
				margin-top: 58px;
				margin-bottom: 47px;
			}
			@include breakpoint(desktop) {
				margin: 0;
			}
		}
	}
}
