.scoring-result-tables {
    max-width: calc(100% - 3px);
    margin: 3px;
    padding: 0 1px;

    .scoring-table {
        padding: 1px;
    }
}

.scoring-table {
    max-width: 320px;
}

.court-name {
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    font-family: 'Gotham 5r', Helvetica, Arial, sans-serif;
    color: $white;
    background: #123B00;
    padding: 0 2px;
    text-transform: uppercase;
    height: 25px
}

.court {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid black;
    height: 220px;
    padding: 2px 1px;

    &__header {
        display: flex;
        justify-content: flex-end;
        font-size: 11px;
        [class^=crt] {
            border-top: 1px solid black;
        }
    }
    &__content {
        display: flex;
        flex-direction: column;
    }
}

.player {
    display: flex;
    flex-direction: row;
    font-size: 11px;
    flex-shrink: 1;

    &__name {
        word-break: break-all;
    }

    &__flag {
        padding-right: 4px;
        img {
            width: 16px;
        }
    }
}

.team {
    display: flex;
    justify-content: space-between;
    font-size: 11px;

    &--winner {
        .player__name {
            font-family: 'Gotham 5r', Helvetica, Arial, sans-serif;
        }

        .crtpts {
            &:before {
                color: #123B00;
                @extend .icon;
                @extend .wim-icon-check, :before; 
                /*  @extend .wim-icon-check:before; -- compound styles can no longer be extended, above code was suggested */
            }
        }
    }

    &__scores {
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
    }
}

[class^=crt] {
    width: 25px;
    padding: 0 8px;
    // border-left: 1px solid black;
    // border-right: 1px solid black;
    text-align: center;
}

.crtpts {
    width: 35px;
}


.tournament-table  {
  	//font-family: Arial, Helvetica, sans-serif;
  	border-collapse: collapse;
  	width: 100%;

 	td, thead {
		border: 1px solid #ddd;
		padding: 8px;
	}

	tr:nth-child(even){background-color: $white;}
	tr:hover {background-color: #ddd;}

	thead {
		padding-top: 12px;
		padding-bottom: 12px;
		text-align: left;
		background-color: $wim-green;
		color: $white;
	}
}

