.match-archive-landing {
    .match-archive-blurb {
        text-align: center;
        color: $white;
        @include gotham-book;
        font-size: 12px;
        margin-bottom: 25px;
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .match-archive-round {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        margin: 0 auto;
        position: relative;
        width: calc(100% - 8px);
        margin: 0 4px;
        opacity: .3;

        @include breakpoint(tablet) {
            min-height: 382px;
            width: calc(141px * 4 + 8px * 4);
            margin: 0 15px;
        }
        @include breakpoint(desktop) {
            min-height: 382px;
            width: calc(141px * 5 + 8px * 5);
            margin: 0 15px;
        }
    }
    .ma-round-header{
        margin: 0 auto;
        height: 17px;
        line-height: 17px;
        background-color: #4F2683;
        color: $white;
        text-align: center;
        @include gotham(medium);
        font-size: 12px;
        text-transform: uppercase;

        &.small {
            width: 100%;
        }
        &.large {
            width: calc(100% - 8px);
        }
    }
    .ma-round-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.5);
        padding: 0 4px 4px 4px;
        
        @include breakpoint(tablet) {
            padding: 0;
            background: transparent;
        }
    }
    .ma-round-overlay {
        position: absolute;
        width: calc(100% - 8px);
        height: 100%;
        top: 0;
        left: 4px;
        background: rgba(0, 0, 0, 0.2);
        display: none;
    }
    
    // override carousel styles
    .BrainhubCarousel {
        position: relative;
    }
    .BrainhubCarousel__track {
        align-items: flex-start;
    }
    .BrainhubCarouselItem--active {
        .ma-round-overlay {
            display: none;
        }
        .match-archive-round {
            opacity: 1;
        }
    }
    .ma-nav-arrow {
        position: absolute;
        top: 0;
        height: 100%;
        background: transparent;
        border: none;
        z-index: 10;
        padding: 0;
        text-align: center;
        cursor: pointer;
        
        &.prev-button {
            left: 0px;
        }
        &.next-button {
            right: 0px;
        }
        i {
            color: $white;
            font-size: 46px;
            position: relative;
            top: 182px;
            display: none;
        }
        @include breakpoint(tablet) {
            &:hover {
                i {
                    display: block;
                }
            }
        }
    }
}

.match-archive-box {
    &:hover {
        text-decoration: none;
    }
    .match-archive-box-content{
        width: 100%;
        height: 100%;
        position: relative;
    }
    .front {
        width: 100%;
        height: 100%;
        background-color: $gray-light;
        // background-color: $white;
        // opacity: .65;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .back {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: space-between;
        background-color: $gray-light;
        opacity: 0;
    }
    &.small {
        width: 100%;
        height: 40px;
        margin: 4px 0 0 0;

        .match-archive-box-content {
            .front {
                flex-wrap: nowrap;
                align-content: center;
                padding-left: 4px;
                padding-right: 4px; 
                position: relative;                   
                
                .ma-new-tag {
                    width: 30px;
                    font-size: 8px;
                    height: 12px;
                    padding-top: 1px;
                    padding-bottom: 1px; 
                }
                .ma-team {
                    width: calc(50% - 38px);
                    height: 28px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    align-content: center;
                    overflow: hidden;
    
                    .photo { 
                        width: 28px; 
                        
                        img {
                            width: 28px;
                            height: 28px;
                            border-radius: 50%;
                        }
                    }
                    .name { 
                        width: calc(100% - 33px); 
                        
                        .first-name { display: none; }
                    }
                    &.p1 { 
                        justify-content: flex-start;
                        text-align: left;
                        
                        .photo { margin-right: 5px; }
                    }
                    &.p2 { 
                        justify-content: flex-end;
                        text-align: right;
                    
                        .photo { margin-left: 5px; }
                    }
                }
                .info {
                    // width: 20%;
                    width: 76px;
                }
                .date {
                    width: 100%;
                    font-size: 8px;
                }
                .year {
                    font-size: 12px;
                    width: 100%;
                    padding-top: 0;
                }
                .action {
                    display: none;
                }
            }
        }
    }
    &.large {
        width: 141px;
        height: 175px;
        margin: 8px 4px 0 4px;

        .match-archive-box-content {
            .front {
                flex-wrap: wrap;
                align-content: flex-start;
                justify-content: flex-start;
                position: relative;
                padding: 17px 8px 0 8px;
                
                .ma-new-tag {
                    width: 39px;
                    font-size: 10px;
                    padding-top: 1px;
                    padding-bottom: 1px; 
                }
                .ma-teams {
                    width: 100%;
                    height: 100px;
                    margin-top: 27px;
                    display: flex;
                    justify-content: space-between;
                }
                .ma-team {
                    width: calc(50% - 4px);
                    
                    .photo { 
                        width: 100%; 
                        position: static;
                    
                        img {
                            width: 100%;
                            height: auto;
                            border-radius: 50%;
                        }
                    }
                    .name { 
                        width: 100%; 
                        text-align: center;

                        .first-name { display: block; }
                    }
                }
                .date {
                    margin-top: 9px;
                }
                .year {
                    font-size: 22px;
                    width: 100%;
                    height: 24px;
                }
                .action {
                    display: block;
                }
            }
        }
    }
    .back {
        flex-direction: column;
        padding: 16px 7px 2px 7px;
    }
    .ma-new-tag {
        position: absolute; 
        top: 0;
        left: 0;
        right: 0;
        @include gotham(medium);
        background-color: $live-green;
        text-align: center;
        margin: 0 auto;
        text-transform: uppercase;
        color: $wim-black;
        display: none;
    }
    .year {
        @include gotham-book();
        color: $wim-black;
        text-align: center;
    }
    .name {
        @include gotham(medium);
        font-size: 10px;
        color: #666666;
    }
    .action {
        width: 100%;
        text-align: center;
        font-size: 24px;
        color: #226D36;
    }
    .date {
        @include gotham-book;
        text-align: center;
        color: $wim-black;
        width: 100%;
        font-size: 9px;
    }
    .quote {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 10px;

        .quote-text {
            @include gotham-book();
        }
        .quote-credit {
            @include gotham(medium);
            margin-top: 10px;
        }
    }
    &.available, &.active {
        &.large {
            .front {
                position: absolute;
                align-items: flex-start;
    
                .ma-teams {
                    height: 100px;
                    margin-top: 3px;
                }
            }
        }
    }
    &.available {
        cursor: default;
    }
    &.active {
        .front, .back { 
            background-color: $white; 
            // opacity: 1;
        }
        .name { color: #176B3D; }
    }
    &.new-match {
        .ma-new-tag {
            display: block;
        }
    }
}
// flip animation
.flip-card {
    // background-color: transparent;
    // perspective: 1000px; /* Remove this if you don't want the 3D effect */
}
  
/* This container is needed to position the front and back side */
.flip-card-inner {
    // transition: transform 0.8s;
    // transform-style: preserve-3d;
}
  
/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-back {
    // transform: rotateY(180deg);
    opacity: 1;
}

.flip-card {
    &.active:hover {
        .flip-card-back {
            opacity: 1;
        }
    }
    &.available:hover {
        .flip-card-front {
            opacity: 0;
        }
        .flip-card-back {
            opacity: 1;
        }
    }
}
  
/* Position the front and back side */
.flip-card-front, .flip-card-back {
    // position: absolute;
    // -webkit-backface-visibility: hidden; /* Safari */
    // backface-visibility: hidden;
    // opacity: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    // opacity: 0;
    transition: .5s ease;
}
  
/* Style the front side (fallback if image is missing) */
.flip-card-front {}
  
/* Style the back side */
.flip-card-back {
    // transform: rotateY(180deg);
}

