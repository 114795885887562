/** timeline styles **/

/*** timeline
*/
.timelineWrapper {
	max-width: 1600px;
}
.timeline { display: none;
	@include breakpoint(desktop) { 
		display: block; margin: calc((24/16)*1em) auto 13px auto; padding-top: 55px; width: 97%;

		ol, li { display: inline-block; padding: 0; margin: 0; box-sizing: border-box; }
		ol { display: table; background: rgba($white, 0.5); height: 10px; width: 100%; }
		li { display: table-cell; list-style: none; background: $white; border-right: solid 2px #cccccc; height: 100%; position: relative;
			&.selected { 
				border: solid 1px $white; 
				background: #238457; 
				top: -1px; 
				height: 10px + 1px;
				&::before { 
					content: attr(data-content); 
					color: $white; 
					font-size: calc((36/16)*1em); 
					position: absolute; 
					top: -45px; 
					left: -35px;
				}
				&:nth-of-type(-n+5)  { // first five - left align to show the year
					&::before { 
						content: attr(data-content); 
						color: $white; 
						font-size: calc((36/16)*1em); 
						position: absolute; 
						top: -45px; 
						left: -35px;
						left: 0px;
					}
				 }
				&:nth-last-of-type(-n+5)  { // last five - left align to show the year
					border-right: solid 1px $white; 
					&::before { 
						content: attr(data-content); 
						color: $white; 
						font-size: calc((36/16)*1em); 
						position: absolute; 
						top: -45px; 
						left: -35px; 
						left: -70px;
					}
				 }
				&:hover { 
					border: solid 1px $white; 
					background: #238457; 
					top: -1px; 
					height: 10px + 1px;
				}
				&.dim {
						&::before { opacity: 0.1; }
					}
			}
			&:first-of-type {
				&::after { 
					content: attr(data-content); 
					color: $white; 
					font-size: calc((24/16)*1em); 
					position: absolute; 
					top: 10px;
					left: 1.5%;
				}
			}
			&:last-of-type {
				&::after { 
					content: attr(data-content); 
					color: $white; 
					font-size: calc((24/16)*1em); 
					position: absolute; 
					top: 10px;right: 1.5%; 
				}
			}
			&:hover { 
				border: solid 1px $white; 
				background: #238457; 
				top: -1px; 
				height: 10px + 1px;
				background: #cccccc;
				cursor: pointer;
				&::before { 
					content: attr(data-content); 
					color: $white; 
					font-size: calc((36/16)*1em); 
					position: absolute; 
					top: -45px; 
					left: -35px;
				}
				&:nth-of-type(-n+7) { // first seven - left align to show the year on mouse over
					&::before { 
						content: attr(data-content); 
						color: $white; 
						font-size: calc((36/16)*1em); 
						position: absolute; 
						top: -45px; 
						left: -35px;
						left: 0px;
					}
				}
				&:nth-last-of-type(-n+7) { // last seven - right align to show the year on mouse over
					&::before { 
						content: attr(data-content); 
						color: $white; 
						font-size: calc((36/16)*1em); 
						position: absolute; 
						top: -45px; 
						left: -35px; 
						left: -70px;
					}
				}
			} // &:hover
		} // li
	}
} // .timeline