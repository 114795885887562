.myWPromo{
    width: calc(100% + 2* 38px);
    margin: 0 -38px;
    background-image: linear-gradient(to left, #00521F, #00703C);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .mywlogo{
        margin-top: 52px;
        .my {
            font-size: 16px;
        }
        .wimbledon{
            font-size: 24px;
        }
    }
    .whiteStripe-short {
        margin: 14px auto;
    }
    .text{
        max-width: calc(650px + (23px*2));
        padding: 0 23px;
        text-align: center;
        @include gotham(medium);
        font-size: px2rem(14);
        line-height: 20px;
        letter-spacing: 0.01em;
        margin-bottom: 18px;
        @include breakpoint(tablet, max){
            max-width: 80%;
        }
    }
    .generic-button_wrapper{
        width: auto;
        margin-bottom: 52px;
    }
}