.single-item {
	margin: auto !important;
}
.home-collapse {
	width: 100%;
	.Collapsible__trigger {
		font-size: 18px;
		color: #00703c;
		text-transform: uppercase;
		// margin-bottom: 16px;
		font-weight: bold;
	}
}
.my-wimbledon {
	display: flex !important;
	flex-direction: column;

	.form-holder {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		width: 100%;
	}

	.one-third {
		width: calc(100% - 7px * 2);
		@include breakpoint(tablet, min) {
			// width: calc(50% - 7px * 2);
			width: calc(33.33% - 7px * 2);
		}
	}
	/** Carousel **/
	.carousel-wrapper {
		@include breakpoint(tablet, max) {
			min-height: unset;
		}
		// min-height: 565px;
		position: relative;
		width: 100%;
		.nav-wrapper {
			// top: 16%;
			justify-content: space-between;
			top: 45%;
			transform: translateY(-50%);
			.arrow-wrapper-left {
				left: 0px;
			}
			.arrow-wrapper-right {
				right: 0px;
			}
			.arrow-wrapper-left,
			.arrow-wrapper-right {
				color: $gray-disable;
				display: block !important;
				position: absolute;
				font-size: 42px;
			}
		}
	}

	/** Page Nav **/
	.page-nav-container {
		.select-menu-dropdown {
			padding: 0px 10px !important;
			overflow-y: scroll;
			overflow-x: hidden;
		}
	}
	/** Favourites **/
	.favorites-container {
		.image-gallery {
			.player-imgs {
				.favorite {
					top: 0px;
				}
			}
		}

		.no-favorite-players {
			// width: 100%;
			position: relative;
			text-align: center;

			.no-players-text {
				width: 100%;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
		}
		.player-imgs {
			position: relative;
			display: flex;
			margin: auto;
			margin-bottom: 10px;

			.favorite {
				position: absolute;
				display: block;
				top: -20px;
				left: -5px;
				cursor: pointer;
				z-index: 2;

				&:after {
					position: absolute;
					left: 0;
					content: '\2606';
					font-size: 33px;
					color: $wim-green;
				}
				&.favorited:after {
					content: '\2605';
				}
			}
		}
		.favorite-content {
			.related-content-title {
				font-size: 18px;
				color: $wim-green;
				text-transform: uppercase;
				display: flex;
				justify-content: space-between;
				position: relative;
				.read-title {
					padding-top: 10px;
					font-weight: bold;
				}
			}
		}
		.search {
			.search-container {
				.search-header {
					color: $wim-green;
					text-transform: uppercase;
					font-size: 18px;
					font-weight: bold;
					line-height: 23px;
					margin-bottom: 10px;
					@include gotham-book();
				}
				.search-input {
					position: relative;

					input {
						// border: 1px solid $grey-c7;
						width: 100%;
						// height: 40px;
						// border-radius: 20px;
						padding-left: 35px;
						font-size: 14px;
						margin-top: 0;
						// background: url('../../../images/misc/magnifying-glass.svg') no-repeat;
						&::-webkit-input-placeholder {
							/* Chrome/Opera/Safari */
							color: $grey-c7;
							text-transform: uppercase;
						}
						&::-moz-placeholder {
							/* Firefox 19+ */
							color: $grey-c7;
							text-transform: uppercase;
						}
						&:-ms-input-placeholder {
							/* IE 10+ */
							color: $grey-c7;
							text-transform: uppercase;
						}
						&:-moz-placeholder {
							/* Firefox 18- */
							color: $grey-c7;
							text-transform: uppercase;
						}
					}

					img.search-icon {
						position: absolute;
						left: 5px;
						top: 7px;
						width: 26px;
						height: auto;
						color: $wim-black;
					}
				}
				.search-results {
					margin-top: 10px;
					max-height: 220px;
					overflow: auto;

					.player-result {
						padding: 10px;
						padding-left: 35px;
						position: relative;
						border-bottom: 1px solid $grey-c7;
						@include gotham-book();

						.favorite {
							&:after {
								position: absolute;
								left: 0;
								top: -5px;
								content: '\2606';
								font-size: 33px;
								color: $wim-green;
							}
							&.favorited:after {
								content: '\2605';
							}
						}
					}
				}
			}
		}
		.fav-players {
			display: flex;
			justify-content: space-between;
			overflow: hidden;
			.player-fav-container {
				position: relative;
				margin-left: 14px;
				margin-bottom: 30px;
				.player-info {
					display: flex;
					flex-flow: column;
					justify-content: center;
					.player-img-container {
						margin: auto;
					}
					.wim-icon-check {
						color: $wim-check;
						margin-right: 5px;
					}
					.player-name {
						color: $wim-green;
						font-size: 14px;
						font-weight: bold;
						line-height: 19px;
						text-align: center;
						text-transform: uppercase;
					}
					.player-status {
						color: $wim-purple;
						font-size: 14px;
						line-height: 18px;
						text-align: center;
						font-weight: bold;
					}
					.opp-name,
					.event-name,
					.round-name {
						color: $wim-black;
						line-height: 18px;
						font-size: 14px;
						text-align: center;
						white-space: pre-line;
					}
					.opp-name {
						font-size: 14px;
					}
					.event-name {
						font-weight: bold;
						text-transform: uppercase;
					}
					.round-name {
						text-transform: uppercase;
						font-weight: bold;
					}
				}
			}
		}
	}
	/** Tooltip **/
	.__react_component_tooltip.type-light {
		color: $wim-green;
		font-size: 18px;
	}
	.ellipsis {
		color: $wim-green;
		cursor: pointer;
	}
	.title-header {
		font-size: 18px;
		text-transform: uppercase;
		color: $wim-green;
	}
	.profile-title {
		text-align: center;
		border-bottom: 1px solid $gray_bb;
		font-weight: bold;
	}
	.generic-button_wrapper {
		margin-bottom: 0px;
		// margin-right: 10px;
		float: left;
		&.center {
			float: none;
		}
	}
	.btn {
		min-width: 200px;
	}

	.select-title {
		width: 200px;
	}

	.three-column {
		display: flex;
		justify-content: space-between;
		padding-top: 0px !important;
		.btn {
			width: 100%;
		}
		.one-third {
			width: calc(100% - 7px * 2);
			@include breakpoint(tablet, min) {
				width: calc(33.33% - 7px * 2);
			}
		}

		@include breakpoint(desktop, max) {
		}
	}

	.full-width {
		width: 100%;
	}

	fieldset.DOB {
		border: none;
		padding: 0px;
		margin-left: 0px;
		label{
			flex: 1;
			margin-right: 10px;
			@include breakpoint(desktop){
				select{
					font-size: 14px;
				}
				.wim-icon-down-arrow{
					font-size: 5px;
					right: 8px;
				}
			}
			.wim-icon-down-arrow{
				top: 25px;
			}
			&:last-child{
				margin-right: 0px;
			}
		}
	}

	label {
		// text-transform: uppercase;
		// color: $gray_9b;
		// @include gotham-book();
	}
	.label {
		// text-transform: uppercase;
		// color: $gray_9b;
	}
	.dt-field {
		width: calc(33% - 4px);
		border-radius: 5px;
	}
	.dt-container {
		display: flex;
		justify-content: space-between;

		input {
			margin-top: 0;
		}
	}
	.no-padding {
		&.top {
			padding-top: 0px !important;
		}
		&.bottom {
			padding-bottom: 0px !important;
		}
		&.left {
			padding-left: 0px !important;
		}
		&.right {
			padding-right: 0px !important;
		}
	}

	.no-margin {
		&.top {
			margin-top: 0px !important;
		}
		&.bottom {
			margin-bottom: 0px !important;
		}
		&.left {
			margin-left: 0px !important;
		}
		&.right {
			margin-right: 0px !important;
		}
	}
	padding-top: 0px !important;
	.no-services-available {
		text-align: center;
	}

	.service-section-title {
		font-size: 18px;
		color: $wim-green;
		text-transform: uppercase;
		margin-bottom: 16px;
		font-weight: bold;
	}
	.service-container {
		// margin-right: 5px;
		display: flex;
		flex-flow: column;
		margin-bottom: 20px;

		img.service-img {
			height: 75px;
			width: 75px;
			margin: auto;
		}
		.service-title {
			// color: $wim-black;
			text-align: center;
			margin-top: 5px;
			// font-weight: bold;
			letter-spacing: 0.5px;
			text-transform: uppercase;
		}

		&.small {
			img.service-img {
				height: 50px;
				width: 50px;
			}

			.service-title {
				font-size: 14px;
				letter-spacing: 0.2px;
			}
		}
	}
	.debenture-container {
		@include gotham-book();
		font-size: 17px;
		width: 100%;
		display: block;
		margin: auto;

		.line {
			height: 1px;
			background: $gray_bb;
			width: 100%;
		}
		.header {
			@include gotham(medium);
			color: $wim-green;
			margin-bottom: 16px;
		}
		.sub-header {
			font-size: 14px;
			color: $gray_9b;
			margin-bottom: 5px;
		}
		.sub-detail {
			margin-bottom: 5px;
		}
		.info-container {
			margin-bottom: 10px;

			.info-detail {
				line-height: 20px;
			}
			.info-flex {
				display: flex;
				flex-wrap: wrap;
				//justify-content: space-between;
				width: 100%;
				height: 100%;
				margin-bottom: 15px;

				.court-location {
					min-width: 100%;
					white-space: nowrap;
					flex: 0 0;
					align-self: flex-start;

					@media only screen and (min-width: 600px) {
						min-width: 160px;
					}
				}
				.years {
					min-width: 100%;
					font-weight: bold;
					white-space: nowrap;
					flex: 0 0;
					align-self: flex-start;

					@media only screen and (min-width: 600px) {
						min-width: 100px;
					}
				}
				.issued {
					min-width: 100px;
					font-weight: bold;
					white-space: nowrap;
					flex: 0 0;
					align-self: flex-start;
				}
				.list {
					font-weight: bold;
					flex: 1 0;

					@include breakpoint(desktop) {
						margin-right: 20%;
					}
				}
			}
		}
	}

	.ballotreg-container {
		div {
			position: relative;
		}

		margin-top: 20px;
		margin-bottom: 30px;
	}

	.deactivate-message {
		flex: 1 0 auto;
		display: flex;
		justify-content: flex-end;
		flex-direction: column;

		.text {
			margin-top: 20px;
			bottom: 0px;
			text-align: center;
		}
	}
}
.migrate-text {
	text-align: center;
}

.generic-button {
	&.mywim {
		display: inline-block;

		.content {
			text-transform: none;
			white-space: nowrap;
			position: relative;
			padding: 9px 12px 0px 12px;

			.wim-icon-myw {
				&:before {
					font-size: 14px;
					margin: 0;
					position: relative;
					bottom: -4px;
				}
			}
		}
	}
}

.my-wimbledon-page {
	.manage-benefits {
		div.greenstripe-container:last-child {
			display: none;
		}
	}
}

.myw-icons {
	.service-section-title {
		font-size: 18px;
		color: $wim-green;
		text-transform: uppercase;
		margin-bottom: 16px;
		font-weight: bold;
	}
	.service-container {
		// margin-right: 5px;
		display: flex;
		flex-flow: column;
		margin-bottom: 20px;

		img.service-img {
			height: 75px;
			width: 75px;
			margin: auto;
		}
		.service-title {
			// color: $wim-black;
			text-align: center;
			margin-top: 5px;
			// font-weight: bold;
			letter-spacing: 0.5px;
			text-transform: uppercase;
		}
	}
}

.myw-icons-shelf {
	// min-height: 157px;
	margin-bottom: 0px;
	margin: 0 auto;
	.service-container {
		display: flex;
		justify-content: center;
		flex-flow: column;
		margin-bottom: 20px;

		.service-img {
			height: 50px;
			width: 50px;
			margin: auto;
		}
		.service-title {
			text-align: center;
			margin-top: 10px;
			text-transform: uppercase;
		}
	}

	.row-title {
		margin: 10px auto;
		text-align: center;
		font-size: 20px;
		text-transform: uppercase;
		color: $wim-green;
		position: relative;
		padding-bottom: 10px;

		&:after {
			content: '';
			position: absolute;
			height: 2.75px;
			width: 40px;
			background: green;
			bottom: 0px;
			left: 50%;
			transform: translateX(-50%);
		}

		@include breakpoint(desktop) {
			margin: 42px auto;
		}
	}

	.item {
		width: 100%;
		position: relative;
		margin-bottom: 15px;

		&.title {
			.row-title {
				position: relative;

				.welcome-text {
					font-weight: bold;
				}
			}
		}

		@include breakpoint(tablet) {
			width: 25%;
			// margin-bottom: 0px;

			&.title {
				width: 100%;
				margin-bottom: 10px;
			}
		}

		@include breakpoint(desktop) {
			width: 16.6%;

			&.title {
				width: 16.6%;
				margin-bottom: 0px;

				&.full-width {
					width: 100%;

					.row-title {
						margin: 0 auto 15px;
					}
				}
			}
		}
	}

	.full-width {
		width: 100%;
		margin-bottom: 10px;
		text-align: center;

		a {
			display: inline-block;
		}
	}

	.bold-text {
		font-weight: bold;
	}
}
.landing-shelf {
	display: flex;
	justify-content: center;
}

.home-shelf {
	justify-content: center;
}

