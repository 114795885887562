$draw-analysis-width: 350px;
$draw-analysis-content-width: 333px;
.page, .webview {


.draw-analysis-wrapper {
    max-width: $draw-analysis-width;
    margin-top: 27px;
    position: relative;

    .draw-analysis-inner-wrapper {
        border: solid 1px $powerrank-divider;
        border-radius: 10px;
        padding: 8px;
        
        &.intro {
            h4 { 
                width: 100%;
                text-align: center;    
            }

            p {
               font-style: italic;
            }

            .demo-header {
                font-style: normal;
                margin-top: 10px;
            }
        }

    }

    .updated {
        display: none;
        font-size: px2rem(10);
        color: #999999;
        text-transform: uppercase;
        padding-bottom: 5px;
        justify-content: flex-end;

        &.mobile-show,
        &.show {
            display: flex;
        }
    }

    h4 {
        @include gotham(medium);
        color: $wim-black;
        display: inline-block;
        font-size: px2rem(16);
        padding-right: 10px;

        &.winner-title, span.winner-title {
            color: $wim-green;
            font-weight: bold;
            padding-bottom: 5px;
            text-transform: uppercase;
        }

        span.winner-title {
            display: inline-block;
            padding-bottom: unset;
            padding-left: 12px
        }

        &.winner-title {
            display: none;
        }
    }

    .draw-label {
        @include ai-draw-label;
    }

    .help {
        display: inline-block;
        position: absolute;
        right: 10px;
        text-align: right;
        width: 40px;

        &:hover {
            cursor: pointer;
        }
    }

    p {
        font-size: px2rem(14);
        margin-bottom: 12px;

        &.extra-space {
            margin-top: 10px;
            padding-bottom: 5px;
        }
    }

    .generic-button_wrapper {
        min-width: $draw-analysis-content-width;
        width: 100%;
    }

    .generic-button_wrapper .generic-button {
        &.rounded-corner {
            width: 100%;
            .border {
                border-radius: 5px;
            }
        }
    }

    &.mobile-show {}

    &.mobile-hide {
        display: none;
    }

    @include breakpoint(tablet) {
        margin-top: 12px;

        .updated {
            &.mobile-show {
                display: none;
            }
        }

        .draw-analysis-inner-wrapper {
            border: solid 1px $powerrank-divider;
            border-radius: 10px;
            padding: 8px;
        }

        &.mobile-show {
            display: none;
        }

        &.mobile-hide {
            display: block;
        }

        h4 {
            &.winner-title {
                display: block;
            }

            span.winner-title {
                display: none;
            }
        }
    }

    @include breakpoint(tablet, max) {
        /** make the help content higher than the fade 
         *   that's placed on Path to the Finbals
        */
        z-index: 10; 

        &.mobile-show {
            z-index: unset;
        }
    }
} // .draw-analysis-wrapper

#powerindex-leaderboard {
    .leaderboard-data {
        .draw-analysis-wrapper {
            border: none;

            @include breakpoint(tablet, max) {
                /** in the leaderboard row, 
                *   unset z-index so this doesn't 
                *   show on the Path to the Final
                *    that ends up displaying two
                */
                z-index: unset; 
            }

            @include breakpoint(tablet) {
                border-radius: 10px;
                margin-left: 16px;
            }
        }

        .draw-analysis-inner-wrapper  {
            border: none;

            @include breakpoint(tablet) {
                border: solid 1px $powerrank-divider;
            }
        }
    }
}

.path-to-the-final {
    .draw-analysis-wrapper {
        h4 {
            &.winner-title {
                display: block;
            }

            span.winner-title {
                display: none;
            }
        }
    }
}

.drawAnalysisBar {
    margin-top: 12px;
    margin-bottom: 12px;
    position: relative;
    width: $draw-analysis-content-width;

    /** marker position and color */
    span {
        position: absolute;
        top: 3.5px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: $favourable;
        border: solid 3px $white;

		transform: translateX(-7px); // offset the marker size
        

        &.favourable {
            background-color: $favourable;
            left: 3%;
        }

        &.neutral {
            background-color: $neutral;
            left: 48%;
        }

        &.difficult {
            background-color: $difficult;
            left: 97%;
        }
    }

    .demo-labels {
        display: flex;
        justify-content: space-between;
    }

    label {
        @include gotham(medium);
        font-size: px2rem(14);
        position: relative;

        &.favourable {
            color: $favourable;
        }

        &.neutral {
            color: $neutral;
            transform: translateX(-10px);
        }

        &.difficult {
            color: $difficult;
        }

    }

    &.demo {
        span {
            top: 1.5px;
        }
    }
} // .drawAnalysisBar

.draw-analysis-help-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;

    .overlay-content {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: px2rem(14);
        color: $wim-black;
        transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        background-color: $white;
        border-radius: 10px;
        padding: 10px;
        width: 350px;

        .close-btn-container  {
            position: absolute;
            right: 15px;
            top: 20px;
        }

        h4 {
            color: $wim-text-purple;
            font-size: px2rem(18);
            text-align: center;
            padding: 12px 0;
            width: 100%;
        }

        .text {
            text-align: center;
            padding-top: 10px;
        }

        .demo-header {
            @include gotham-book;
            font-size: px2rem(14);
            font-weight: normal;
            transform: translateY(10px);
        }
    } // .overlay-content
} // .draw-analysis-help-overlay

    // sidepanel always open
    @include breakpoint(largeDesktop) {
        .draw-analysis-help-overlay  {
            // width: calc(100vw - #{$sidebarWidth});
            width: 100vw;
        }
    }
    // sidepanel always open with centered max width content
    @include breakpoint(extraLargeDesktop) {
        .draw-analysis-help-overlay {
            // width: calc(#{$maxWidth} - #{$sidebarWidth});
            width: 100vw;
        }
    }

    &.expanded {
        .draw-analysis-help-overlay {
            width: calc(100vw - #{$sidebarWidth});
            
            @include breakpoint(extraLargeDesktop) {
                    // width: calc(#{$maxWidth} - #{$sidebarWidth});
                    width: 100vw;
            }
        }
    } // .expanded

    &.compressed { /** slamtracker opened under the side panel size */
        .draw-analysis-help-overlay {
            width: calc(100% - #{$sidebarWidth});
        }
        
        #powerindex-leaderboard {
            .leaderboard-data {
                .draw-analysis-wrapper {
                    border: none;
                    border-radius: 10px;
                    margin-left: 16px;
    
                    &.mobile-show {
                        display: none;
                    }
            
                    &.mobile-hide {
                        display: block;
                    }
                }
    
                .draw-analysis-inner-wrapper  {
                    border: solid 1px $powerrank-divider;
                }
            }
        }
    } // .compressed

} // .page, .webview



.webview .drawAnalysisBar {
    span {
        top: unset;
        transform: translateY(-3px);


        &.difficult {
            transform: translate(-14px, -3px); // offset the marker size
        }
    }
}