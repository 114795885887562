.column-layout {
	&.ballot {
		display: block;
		text-align: center;
		@include gotham-book();

		.heading {
			font-size: 24px;
			margin-bottom: 10px;
		}
		.body {
			font-size: 16px;
			width: 80%;
			margin: auto;
			margin-bottom: 10px;
		}
		.secDividerContent {
			margin: 20px 0;
		}

		.selectMenu {
			.link-sep {
				margin: 0 10px;
			}
		}
	}
}


.content-main{
    &.ballot-update{
        display: flex !important;
        justify-content: center;
    }
}
.form-description{
    text-align: center;
    margin: 50px 0;
    max-width: 620px;
} 
.ballotThankYou{
    .backBtnContainer{
        display: flex;
        justify-content: center;
        margin-top: 50px;
    }
    hr{
        border-top: 1px solid #00703C;
    }
}
.ballot_withdraw_form{
    display: flex;
    justify-content: center;
    .form_inner{
        display: flex;
        justify-content: center;
        label, div{
            margin-bottom: 15px;
        }
    }
}
.ballot_update_form{
    display: flex;
    justify-content: center;
    .bold{
        font-weight: bold;
    }
    .checkbox-option{
        label:hover:before{
            box-shadow: inset 0 0 0 1px $wim-green;
        }
        label:before{
            border: 1px solid $wim-green;
        }
        label:after{
            background-color: $wim-green;
        }
    }
    .generic-button_wrapper{
        .width-100{
            width: 100%;
        }
    }

    .form-top{
        width: 620px;
    }
    
    .dt-container{
        display: flex;
        input{
            margin-right: 15px;
            width: calc(33% - 10px);
            &:last-child{
                margin-right: 0px;
            }
        }
    }
    .address-field-container{
        display: flex;
        align-items: flex-start;
        .address-field{
            display: flex;
            align-items: flex-end;
            label{
                width: calc(100% - 198px);
            }
        }
    }
    
    .address-field{
        .generic-button_wrapper{
            margin-bottom: 0px;
            margin-left: 20px;
        }
    } 
    .addressSelectContainer{
        border: 1px solid #979797;
        height: 130px;
        overflow-y: scroll;
        margin-top: 23px;
        .address-even, .address-odd{
            padding: 20px;
        }
        .address-even{
            background: rgba($color: #D8D8D8, $alpha: 0.4);
        }
    }
    .addressSubmits{
        display: flex;
        margin-top: 25px;
        div:first-child{
            flex: 1;
        }
        .generic-button{
            min-width: 200px;
        }
    }
    .amex-box{
        p{
            padding-bottom: 10px;
        }
        .amex-image{
            margin-right: 20px;
        }
        display: flex;
        padding: 25px 0;
        border-top: 1px solid $wim-green;
        border-bottom: 1px solid $wim-green;
    }
    .submit-buttons{
        .margin-right{
            margin-right: 25px;
        }
        .generic-button{
            width: 170px;
        }
        display: flex;
	}
}