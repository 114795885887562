.ballot-status {
    position: relative;
    display: inline-block;

	&.collapsible {
		padding-left: 32px;
	}

    div {
        position: relative;
    }

	.info-text {
		font-size: 12px;
		margin-bottom: 20px;
	}

    .status-line {
        width: 1px;
        background-color: $lines-gray;
        height: 100%;
        position: absolute;
        display: block;

        .ball {
            background-color: $lines-gray;
            width: 11px;
            height: 11px;
            border-radius: 20px;
            position: absolute;
            display: block;
            top: -5px;
            left: -5px;
        }

        .base {
            background-color: $lines-gray;
            width: 11px;
            height: 1px;
            position: absolute;
            display: block;
            bottom: 0px;
            left: -5px;
        }
    }

    .status-container {
        margin-left: 35px;
        margin-top: 10px;
        float: left;

        .status-detail {
            @include gotham(medium);
            font-size: 16px;
            text-transform: uppercase;
            margin-bottom: 5px;

            .wim-icon-check {
                display: none;
            }

            &.checked {
                color: $wim-green;
                position: relative;
                left: -16px;

                .wim-icon-check {
                    display: inline-block;
                    font-size: 12px;
                    margin-right: 3px;
                }
            }
        }

        .status-message {
            @include gotham();
            font-size: 18px;
            margin-bottom: 15px;
            display: inline-block;

            .status-text {
                margin: 10px 0;
                font-size: 16px;
            }

            .status-link-sep {
                margin: 0 10px;
            }

            .generic-button_wrapper {
                padding-top: 5px;
                width: 300px;
				margin: auto;

                .generic-button {
                    width: auto;

                    .content {
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
            }
        }
    }

    .load-wrapper {
        text-align: center;
        margin-top: 40px;
    }

	&.single {
		text-align: center;

		.status-line {
			display: none;
		}
		.status-container {
			margin-left: 0px;
			float: none;

			.status-text {
				margin: unset;
			}
		}
	}
}