/*** Adding custom slide transition for homepage slider **/
.home.page-header {
    .carousel-wrapper {
        background-color: white;
        .image-gallery-slide {
            opacity: 0;
            transition: opacity 1s ease-in;
            &.center {
                opacity: 1;
            }
        }
    }

}

/** carousel styles **/
.carousel-wrapper {
    .nav-wrapper {
        @include breakpoint(tablet) {
            display: flex;
        }
        width: 100%;
        position: absolute;
        top: 78vh;
        text-align: center;
        z-index: 100;
        // display: none;
        justify-content: center;

        .nav-dot {
            cursor: pointer;
            color: #fff;
            opacity: .5;
            width: 14px;
            height: auto;
            margin-top: 1px;

            &.selected {
                opacity: 1;
            }
        }

        .custom-slide {
            width: 16px;
            &.next-slide {
                cursor: pointer;
                &::before {
                    content: "\25C0 \FE0E";
                    color: white;
                    display: block;
                    transform: rotateY(180deg);
                }
            }

            &.prev-slide {
                cursor: pointer;
                &::before {
                    content: "\25C0 \FE0E";
                    color: white;
                }
            }
        }
    }

    .backgroundImage.scrolled & {
        .nav-wrapper {
            opacity: 0;
            transition: opacity .5s linear;
        }
    }
}
