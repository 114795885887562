.emergency-message{
    position:absolute;
    left: 28px;
    top: 94px;
    background-color:rgba($white,.75);
    height: auto;
    max-height:381px;
    padding:20px;
    color:$select-menu-text;
    width: 85%;
    z-index: 20;
    @include gotham(medium);

    @include breakpoint(tablet){
        width:434px;
        top: 126px;
    }

    @include breakpoint(desktop){
        max-height:300px;
        width:627px;
        left: 45px;
    }

    &.viewed {
        display: none;
    }
    .wim-icon-updates{
        position:absolute;
        color:$wim-green;
        font-size:29px;
        position:absolute;
        left:20px;
        top:20px;

        @include breakpoint(tablet,max){
            left:15px;
        }
    }

    &-title{
        position:absolute;
        font-size:24px;
        letter-spacing:1.5px;
        line-height:1em;
        top:23px;
        left:64px;
        text-transform:uppercase;

        @include breakpoint(tablet,max){
            left:60px;
            top: 12px;
        }
    }

    &-description{
        position:relative;
        margin-top: 50px;
        font-size:14px;
        letter-spacing:1.5px;
        line-height:1.5em;
        height: auto;
        width:100%;
        overflow:auto;
    }

    .wim-icon-close{
        position:absolute;
        font-size:22px;
        color:$black;
        top:15px;
        right:15px;

        @include breakpoint(tablet,max){
            right:10px;
        }

        &:hover {
            cursor: pointer;
        }
    }

}