.one-fifth {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    @include breakpoint(tablet, min) {
        width: 25%;
    }
    @include breakpoint(desktop, min) {
        width: 20%;
    }
}
.accordion-collection-container {
    &.debentures {
        margin-left: 40px;
        margin-right: 40px;
        .accordion-container {
            .greenStripe {
                display: none !important;
            }
            .welcome-text {
                font-weight: bold;
                margin-right: 5px;
            }
            .accordion-content {
                .accordion-content-data {
                    // margin-bottom: 20px;
                    &.close {
                        margin-bottom: 0px;
                    }
                }
            }
        }
        .accordion-title-container {
            max-width: 314px;
            width: unset;
            display: flex;
            justify-content: center;
            &.open {
                &:after {
                    content: '';
                    position: absolute;
                    height: 1.75px;
                    width: 40px;
                    // background: rgb(61, 199, 61);
                    background: $wim-green; 
                    bottom: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            // white-space: pre-line;
            span {
                font-size: 20px;
            }
        }
        .single {
            margin: auto;
        }
        .service-container {
            img {
                max-width: 75px;
                cursor: pointer;
                margin: auto;
            }
            display: flex;
            justify-content: center;
            flex-flow: column;
            margin-bottom: 20px;
            .service-title {
                margin-top: 10px;
                text-align: center;
                font-size: 16px;
                text-transform: uppercase;
            }
        }
    }
}