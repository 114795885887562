.slamtracker-header{
  height: 196px;
  @include breakpoint(tablet, max){
    width: 100%;
  }
  color: #fff;
  text-align: center;
  // margin-bottom: 34px;
  @include gotham(medium);
  .slamtracker-header-title{
    text-transform: uppercase;
          font-size: 18px;
    letter-spacing: .5px;
    padding-top: 49px;
    padding-bottom: 20px;
  }
  .slamtracker-match-name{
    height: 30px;
    background-color: rgba(255, 255, 255, .2);
    text-transform: uppercase;
    font-size: px2rem(12);
    // align-content: center;
    // margin-bottom: 34px;
    letter-spacing: .5px;
    display: flex;
    align-items: center;  
    span{
      margin: auto;
    }
  }
  &.doubles{
    .slamtracker-match-name{
      // margin: auto;
    }
    .slamtracker-match-teams{
      // margin: 0px 10px 20px !important;
      .match-team1, .match-team2 {
        .match-score-box{
          // margin-bottom: 10px;
        }
      }
      // .player-info{
      //   margin:  unset !important;
      // }
    }
  }
  .slamtracker-match-info{
    // padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 77px;
    margin: 34px 0px;
    padding: 0 10px;
    .slamtracker-match-court-info{
      display: flex;
      flex-direction: column;
      // margin-top: -20px;
      position: absolute;
      text-transform: uppercase;
      .match-court-name{
        letter-spacing: .5px;
        font-size: px2rem(12);
        padding-bottom: 5px;
      }
      .match-stat{
        @include gotham-book();
        font-size: 10px;
      }
    }

    .slamtracker-match-teams{
      width: 100%;
      display: flex;           
      .headshot-container{
        display: flex;
        position: relative;
        .match-winner{
          align-self: end;
        }
        .headshot{
          height: 70px;
          width: 70px;
          cursor: pointer;

          &.not-winner{
            filter: grayscale(100%);
          }
        }
        &.doubles{
          // height: 70px;
          align-items: center;
          .headshot{
            height: 40px;
            width: 40px;
            &.team1, &.team2 {margin-left: 5px}
          }
          display: flex;
          .match-winner{
            margin-left: unset !important;
            // margin-top: 35px;
            align-self: unset !important;;
            position: relative;
            top: 18px;
          }
        }
      }
      .link-to-player{ cursor: pointer; }
      .match-team1, .match-team2{
        display: flex;
        flex-direction: column;
        flex: 1;
        .player-info{
          margin: 10px 0px 0px;
          font-size: 12px;
          width: auto;
          .member-one, .member-two {
            display: flex;
            align-items: baseline;
            &.doubles{
              margin-bottom: 3px;
            }
          }
          .player-flag{
            padding-right: 3px;
          img {
            width: 17px;
            height: 10px;
          }
          }
          .player-seed{
            font-size: 10px;
          }
          .player-name{
            padding-right: 3px;
          }
        }
        .match-winner{
          font-size: 10px;
        }
        .match-score-box{
          display: flex;
        }

        .icon-container{
          @include gotham(medium);
          height: 100%;
          margin: 0 5px;
          align-content: flex-end;
          align-self: center;
          color: #FFF500;
          .live-score-container{
            .live-score{
              font-size: 24px;
              color: #FFFFFF;
            }
            .crtIcon.currentServer{
              height: 13px;
              width: 100%;
            }
            .emptyIconContainer{
              height: 13px;
            }
          }
        }
      }
      .match-team1{
        align-items: flex-start;
        .match-score-box {
          .headshot-container {
            .match-winner{
              margin-left: -5px;
            }
          }
        }
      } 
      .match-team2{
        align-items: flex-end;
        .match-score-box{
          .headshot-container{
            .match-winner{
            }
          }
          .headshot-container{
            &.doubles{
            }
          } 
        }
      }
    }
    &.is-fixed{
      position: fixed;
      display: flex !important;
      top: 0;
      box-shadow: 0px 2px 3px rgb(0 0 0 / 25%);
      @include background-2x('https://assets.wimbledon.com/images/backgrounds/scores', 'jpg', auto, auto, 0 0);
      z-index: 2;
      animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
      width: 373px;
      @media only screen and (max-width: 767px) {
        width: 100%;
       }
      padding: 10px 10px;
      margin: 0;
    }
  }
  &.live{
    .slamtracker-match-info {
      display: none;
      padding-bottom: 12px;     
      // width: 373px;       
      .slamtracker-match-court-info {
        align-items: center;
        .match-court-name {
          width: 42px;
          height: 16px;
          color: #333333;
          background-color:#FFF500;
          font-size: 9px;
          margin-bottom: 5px;
          line-height: 16px;
        }
        .match-stat{
          width: 86px;
          height: 13px;
          font-size: 14px;
          text-transform: none;
          letter-spacing: 0.5px;
        }
      }
    }
  }
}