.quick-links{
    padding-top: 6px !important;
    padding-bottom: 7px !important;
    @include breakpoint(desktop){
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }
    
    a:hover{
        text-decoration: none;
        .link-text{
            text-decoration: underline;
        }
        .link-icon{
            text-decoration: none;
        }
    }
    a{
        display: inline-flex;
        align-items: center;
    }
    .link-icon{
        margin-right: 5px;
        display: inline-flex;
        align-items: center;
        width: 16px;
    }
    .left{
        @include breakpoint(tablet, min){
            padding-left: 0px;
        }
        @include breakpoint(tablet){
            padding-left: 70px;
        }
        @include breakpoint(desktop){
            padding-left: 0px;
        }
    }
}