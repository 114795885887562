/** weather-landing styles **/
.results{
    .search-nav{
        margin-left: 0;
        @include breakpoint(tablet, max){
            margin-top: 7px;
        }
    }
    .search-box{
        width: 100%;
    }
}

.page, .webview {
    .wrapper.scorespage {

        .content-main {
			background: none;
			padding-top: 0px;
			z-index: 15;

			min-height: calc(100vh - #{$footerHeightLarge + $footerStripes + $footerMargin + $headerHeightSmall});

			// tablet size
			@include breakpoint(tablet) {
				min-height: calc(100vh - #{$footerHeightMed + $footerStripes + $footerMargin + $headerHeightLarge});
			}

			// desktop size
			@include breakpoint(desktop) {
				min-height: calc(100vh - #{$footerHeightSmall + $footerStripes + $footerMargin + $headerHeightLarge});
			}
            .scores-nav{
                justify-content: center;
            }
            .search-nav{
                display: flex;
                flex-direction: row;
                justify-content: center;
                min-width: 360px;
                .favorites-toggle{
                    margin-top: 0px;
                    margin: unset;
                    width: unset;
                    margin-left: 6px;
                }
            }

            .search-box{
                z-index: 1;
                position: relative;
                width: 100%;
                height: 32px;
                .react-autosuggest__suggestions-container,
                .react-autosuggest__container{
                    text-align: left;
                }
                .search-wrapper{
                    color: $white;
                    input{
                        ::placeholder{
                            color: $white;
                        }
                        &:hover{
                            border: $white;
                            box-shadow: inset 0 0 0 1px $white;
                        }
                        height: 32px;
                        border: none;
                        border-radius: 20px;
                        color: $white;
                        &::placeholder{
                            color: $white;
                        }
                    }
                    i.wim-icon-close{
                        top: 8px;
                    }
                    .wim-icon-search{
                        top: 16px;
                    }
                }
            }

			.column-layout {
				&.no-padding {
					padding: 0;
				}
                .print {
                    display: none;
                    @include breakpoint(tablet, min) {
                        display: block;
                    }
                }
                .players-container {
					flex-flow: row;
       
                    .search-box {
                        input {
                            border-radius: 25px;
                            background: transparent;
                            color: #D8D8D8;
                            &::placeholder {
                                color: #D8D8D8;
                            }
                        }
                        i {
                            color: #D8D8D8;
                        }
                        .wim-search-results {
                            background: white;
                            z-index: 10;
                            position: relative;
                            max-height: 300px;
                            overflow: auto;
                            border-radius: 10px;
                            .result-article-title {
                                color: $wim-purple;
                                padding: 10px;
                                cursor: pointer;
                                &.highlight {
                                    background: $gray-light;
                                }
                                span {
                                    font-weight: 100;
                                    &.bold-text {
                                        font-weight: bold;
                                    }
                                }
                            }
                        }
                    }
                    @include breakpoint(desktop) {
                   
                    }
                    @include breakpoint(tablet, min) {
                        
                 
                    }
                }


				.header {
					margin: auto;
					max-width: calc(100% - 20px);
					display: flex;
					width: 100%;
					justify-content: center;
					flex-direction: row;
					flex-wrap: wrap;
					column-gap: 10px;

					.navItem {
						flex: 1 0 10px;
						margin-bottom: 10px;
					}
					.navItemPad {
						flex: 1 0 10px;
						margin-bottom: 10px;
						padding-right: 10px;
					}
					.navItemFull {
						flex: 0 0 100%;
						margin-bottom: 10px;

						&.rounds {
							flex: 0 0 calc(100% + 10px);
						}
					}
					.navItemFixed {
						flex: 0 0 auto;
                        .draws-pdf-icon-label {
                            color: transparent;
                            opacity: 0;
                            width: 1px;
                            height: 1px;
                            font-size: 10px;
                            text-decoration: none !important;
                            border-bottom: none !important;
                            &:hover {
                                text-decoration: none !important;
                            }
                        }
					}
					.navItemBreak {
						flex-basis: 100%;
  						width: 0px; 
  						height: 0px; 
  						overflow: hidden;
						display: none;
					}

					.navWrapper {
						min-width: 100%;
					}

					@include breakpoint(tablet) {
						margin: auto;
						max-width: 600px;

						.navWrapper {
							min-width: calc(50% - 5px);
						}
						.navItemBreak {
							display: block;
						}

						.navItemFull {
							&.rounds {
								flex: 0 0 calc(100% + 20px);
							}
						}
                    }

					.print {
						display: block;
						color:$white;
						font-size:24px;
						cursor:pointer;
						margin: 5px 0;
					}

					&.narrow {
						max-width: 450px;
					}
				}
	
				.four-col {
					margin: 0;
					position: relative;
				}
	
				.favorites-toggle {
					cursor: pointer;
					.favorite-label {
						display: none;
	
						@include breakpoint(tablet) {
							display: inline;
						}
					}
				}
			}

            .column-layout.scorespage {
                text-align: center;
                position: relative;
            }

			.column-layout.matchexp {
                text-align: center;
                position: relative;
				display: block;
            }

            .scores-select-menu {
                .select-menu {
                    .select-menu-dropdown {
                        // display: none;
                            // display: none;
                        &.mobile {
                            display: none;
                            position: absolute;
                            width: 100%;
                            // top: 24px;
                            right: 0;
                            max-height: 303px;
                            font-size: 15px;
                            border: 1px solid #bbb;
                            z-index: 5;
                            background: #fff;
                            overflow-y: scroll;
                            -webkit-transform: none;
                            transform: none;
                            padding-left: 20px;
                            button {
                                color: #333;
                                text-transform: none;
                                display: block;
                                border: 0;
                                background-color: #fff; 
                                i {
                                    color: $wim-green;
                                }
                            }
                            &.show {
                                display: block;
                            }
                        }    

                    }
                }
            }
        }

        .scores-background {
            position: fixed;
            // background-image: url(/assets/images/backgrounds/scores.jpg);
            @include background-2x('https://assets.wimbledon.com/images/backgrounds/scores', 'jpg', auto, auto, 0 0);
            background-size: cover;
            height: 100vh;
            width: 100vw;
			background-color: $wim-green-bg;
        }

        .match-insights-background {
            position: fixed;
            // background-image: url(/assets/images/backgrounds/scores.jpg);
            @include background-2x('https://assets.wimbledon.com/images/backgrounds/scores', 'jpg', auto, auto, 0 0);
            background-size: cover;
            height: 100vh;
            width: 100vw;
			background-color: $wim-green-bg;
        }
        
        .schedule-background {
            position: fixed;
            // background-image: url(/assets/images/backgrounds/schedule.jpg);
            @include background-2x('https://assets.wimbledon.com/images/backgrounds/schedule', 'jpg', auto, auto, 0 0);
            background-size: cover;
            height: 100vh;
            width: 100vw;
            background-color: $wim-purple-bg;
		}

        .powerindex-background {
            position: fixed;
            // background-image: url(/assets/images/backgrounds/schedule.jpg);
            @include background-2x('https://assets.wimbledon.com/images/backgrounds/schedule', 'jpg', auto, auto, 0 0);
            background-size: cover;
            height: 100vh;
            width: 100vw;
            background-color: $wim-purple-bg;
		}
		
		.match-archive-background {
            position: fixed;
            // background-image: url(/assets/images/backgrounds/schedule.jpg);
            @include background-2x('https://assets.wimbledon.com/images/backgrounds/matcharchive', 'jpg', auto, auto, 0 0);
            background-size: cover;
            height: 100vh;
            width: 100vw;
            background-color: $wim-green-bg;
		}
		.match-archive-landing-background {
            position: fixed;
            // background-image: url(/assets/images/backgrounds/schedule.jpg);
            @include background-2x('https://assets.wimbledon.com/images/backgrounds/matcharchive_landing', 'jpg', auto, auto, 0 0);
            background-size: cover;
            height: 100vh;
            width: 100vw;
            background-color: $wim-green-bg;
        }
        .match-box {
            .match-box-buttons {    
                @include breakpoint(desktop) {
                    .generic-button_wrapper > .generic-button.compact .content, a>.generic-button.compact .content {
                        font-size: 10px;
                    }
                }
                @include breakpoint(largeDesktop) {
                    .generic-button_wrapper > .generic-button.compact .content, a>.generic-button.compact .content {
                        font-size: 11px;
                    }
                }
            }
        }
    }
    &.expanded {
        .wrapper.scorespage {
			.scores-background, 
			.match-archive-background, 
			.match-archive-landing-background {
                @include breakpoint(desktopSidePanel, mid) {
                    width: calc(100vw - #{$sidebarWidth});
                }
                @include breakpoint(largeDesktop) {
                    width: calc(100vw - #{$sidebarWidth});
                }
                // sidepanel always open with centered max width content
                @include breakpoint(extraLargeDesktop) {
                    width: calc(#{$maxWidth} - #{$sidebarWidth});
                }
            }
            .schedule-background {
                @include breakpoint(desktopSidePanel, mid) {
                    width: calc(100vw - #{$sidebarWidth});
                }
                @include breakpoint(largeDesktop) {
                    width: calc(100vw - #{$sidebarWidth});
                }
                // sidepanel always open with centered max width content
                @include breakpoint(extraLargeDesktop) {
                    width: calc(#{$maxWidth} - #{$sidebarWidth});
                }
            }

            .powerindex-background {
                @include breakpoint(desktopSidePanel, mid) {
                    width: calc(100vw - #{$sidebarWidth});
                }
                @include breakpoint(largeDesktop) {
                    width: calc(100vw - #{$sidebarWidth});
                }
                // sidepanel always open with centered max width content
                @include breakpoint(extraLargeDesktop) {
                    width: calc(#{$maxWidth} - #{$sidebarWidth});
                }
            }
        }
    }
}