@mixin aspect-ratio($width, $height) {
	position: relative;
	&:before {
		display: block;
		content: '';
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}
	> .player-image {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

.page.expanded {
	.wrapper {
		.player-profile {
			.general-info-item {
				@include breakpoint(desktopSidePanel) {
					.player-profile--hide-point {
						top: 30%;
					}
					.player-headshot > div {
						width: 400px;
					}
					.singles-ranking,
					.doubles-ranking {
						.ranking {
							.value {
								font-size: px2rem(56px);
							}
							.field {
								font-size: px2rem(20px);
							}
						}
					}
				}
			}
		}
	}
}
.page .wrapper .content-main:has(.player-profile) {
	padding-top: unset;
}

.page .wrapper .content-main {
	.no-player-data {
		width: 100%;
		position: absolute;
		top: 50%;
		font-size: 40px;
		.no-data-text {
			text-align: center;
		}
	}
}

.player-info {
	width: 100%;
}

.page {

	&.expanded {

		.player-profile-left {
			@include breakpoint(desktopSidePanel) {
				width: 90%;
			}
		}

	

	
	}
}


/** player-general-info styles **/
.player-profile--header-wrapper {
	position: absolute;
	left: 0px;
	bottom: 100%;
	color: #fff;
	width: 100%;
	z-index: -2;

	.player-profile--hide-point {
		top: 40%;
		left: 50%;
		// width: 10px;
		// height: 10px;
		// background-color: orange;
		position: absolute;
		// display: block;
	}
	:has(.player-profile--hide-point.show) {
		opacity: 1;
		transition: opacity 0.5s linear;
	}
	:has(.player-profile--hide-point.hide) {
		opacity: 0;
		transition: opacity 0.5s linear;
	}

	.player-profile-info {
		display: flex;
		flex-wrap: wrap;

		.general-info-item {
			position: relative;
			width: 100%;
			margin: 0px;
			.player-profile-header {
				display: flex;
				// background-image: url('/assets/images/player-profile/h_BlueAndWhiteFlowers_No1Court_Large.png');
				flex-wrap: wrap;
				flex-direction: row;
				// padding-top: 30px;
				@include breakpoint(desktop) {
					flex-wrap: nowrap;
					// padding-top: 67px;
				}

				.player-headshot {
					order: 3;
					margin: 0 auto;
					> div {
						width: 320px;
					}
					@include breakpoint(extraLargeMobile) {
						> div {
							width: 388px;
						}
					}
					@include breakpoint(desktop) {
						order: 0;
						> div {
							width: 554px;
						}
					}
				}

				.player-profile-left {
					width: 50%;
					display: flex;
					flex-direction: column;
					justify-content: space-around;

					@include breakpoint(desktop) {
						width: 100%;
					}

					.flag-player-name {
						width: 100%;
						display: flex;
						flex-direction: row;
						justify-content: flex-start;
						align-items: center;
						margin-left: 24px;
						@include breakpoint(1200px) {
							margin-left: 50px;
						}
						.player-flag {
							margin-right: 8px;
							margin-top: 4px;
							width: 43px;
							align-self: flex-start;
							> img {
								width: 100%;
							}
						}
						.player-name {
							display: flex;
							flex-direction: column;
							color: $white;
							@include gotham(medium);
							.first-name {
								font-size: px2rem(14px);
								@include breakpoint(tablet) {
									font-size: px2rem(16px);
								}
								@include breakpoint(desktop) {
									font-size: px2rem(20px);
								}
							}
							.last-name {
								font-size: px2rem(24px);
								@include breakpoint(tablet) {
									font-size: px2rem(28px);
								}
								@include breakpoint(desktop) {
									font-size: px2rem(36px);
								}
							}
						}
					}

					.singles-ranking {
						color: $white;
						margin-left: 24px;
						margin-top: 15px;
						@include breakpoint(desktop) {
							margin-top: 0;
						}
						@include breakpoint(1200px) {
							margin-left: 50px;
						}
						.ranking {
							display: flex;
							justify-content: flex-start;
							align-items: center;
							.value {
								@include gotham(medium);
								margin-right: 4px;
								font-size: px2rem(32px);
								@include breakpoint(tablet) {
									font-size: px2rem(72px);
								}
								@include breakpoint(desktop) {
									font-size: px2rem(56px);
								}
								@include breakpoint(1200px) {
									font-size: px2rem(72px);
								}
							}
							.field {
								text-transform: uppercase;
								@include gotham(medium);
								color: rgba(255, 255, 255, 0.75);
								font-size: px2rem(12px);
								@include breakpoint(tablet) {
									font-size: px2rem(24px);
								}
								@include breakpoint(desktop) {
									font-size: px2rem(16px);
								}
								@include breakpoint(1200px) {
									font-size: px2rem(24px);
								}
							}
						}
					}
				}

				.player-profile-right {
					width: 50%;
					display: flex;
					flex-direction: column;
					justify-content: space-around;

					@include breakpoint(desktop) {
						width: 100%;
					}
					.doubles-ranking {
						color: $white;
						margin-right: 24px;
						text-transform: uppercase;
						margin-top: 15px;
						@include breakpoint(desktop) {
							margin-top: 0;
						}
						@include breakpoint(1200px) {
							margin-right: 50px;
						}
						.ranking {
							display: flex;
							justify-content: flex-end;
							align-items: center;
							.value {
								@include gotham(medium);
								margin-right: 4px;
								font-size: px2rem(32px);
								@include breakpoint(tablet) {
									font-size: px2rem(72px);
								}
								@include breakpoint(desktop) {
									font-size: px2rem(56px);
								}
								@include breakpoint(1200px) {
									font-size: px2rem(72px);
								}
							}
							.field {
								@include gotham(medium);
								color: rgba(255, 255, 255, 0.75);
								font-size: px2rem(12px);
								@include breakpoint(tablet) {
									font-size: px2rem(24px);
								}
								@include breakpoint(desktop) {
									font-size: px2rem(16px);
								}
								@include breakpoint(1200px) {
									font-size: px2rem(24px);
								}
							}
						}
					}
					.fav-star-player-profile {
						display: flex;
						justify-content: flex-end;
						align-items: center;
						margin-right: 24px;
						@include breakpoint(1200px) {
							margin-right: 50px;
						}
						.favorite {
							&:after {
								left: 0;
								content: '\2606';
								font-size: 25px;
								color: $white;
								@include breakpoint(tablet) {
									font-size: 30px;
								}
							}

							&.favorited {
								&:after {
									content: '\2605';
									font-size: 25px;
									color: #f9e300;
									@include breakpoint(tablet) {
										font-size: 30px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
