/**
 * Generic page layout that is global to all styles
 */

html {
	width: 100%;
	height: 100%;
	min-height: 100%;
	min-width: 100%;

	//prevent elastic scroll, but then body doesn't scroll
	//position: fixed;
	//overflow: hidden;
}
body {
	margin: 0;
	padding: 0;
	// height: 100%;
	width: 100%;
	min-height: 100%;
	min-width: 100%;
	max-width: 100%;
	overflow-x: hidden;
	background-color: $green;
	-webkit-overflow-scrolling: auto;

	//prevent elastic scroll, but then body doesn't scroll
	//position: fixed;
	//overflow: hidden;

	&,
	* {
		box-sizing: border-box;
	}
	box-sizing: border-box;

	.site-wrap {
		display: block;
		max-width: $maxWidth;
		margin: auto;

		//prevent elastic scroll, but then body doesn't scroll
		//width: 100vw;
		//height: 100vh;
		//overflow-y: scroll;
		//-webkit-overflow-scrolling: touch;
	}
	&.webview {
		background: transparent;
	}

	&.using-mouse :focus {
		outline: none;
	}
}

.page {
	display: block;
	max-width: $maxWidth;
	margin: auto;

	.wrapper {
		overflow: hidden;
		float: none;
		display: block;
		background: #ffffff;
		min-height: 100vh;
		width: 100%;
		position: relative;

		&.webview {
			overflow: unset;
		}

		// sidepanel always open
		@include breakpoint(largeDesktop) {
			width: calc(100vw - #{$sidebarWidth});
		}
		// sidepanel always open with centered max width content
		@include breakpoint(extraLargeDesktop) {
			width: calc(#{$maxWidth} - #{$sidebarWidth});
		}

		.content-main {
			&.home24{
				z-index: unset;
			}
			width: 100%;
			position: relative;
			//margin-top:$headerHeightSmall;
			z-index: 2;
			display: block;
			background-color: #ffffff;
			padding-top: calc(#{$item-v-margin} * 2);
			padding-bottom: 14px;
			min-height: calc(100vh - #{$footerHeightLarge + $footerStripes + $footerMargin + $headerHeightSmall});
			@include clearfix;

			// tablet size
			@include breakpoint(tablet) {
				min-height: calc(100vh - #{$footerHeightMed + $footerStripes + $footerMargin + $headerHeightLarge});
			}

			// desktop size
			@include breakpoint(desktop) {
				min-height: calc(100vh - #{$footerHeightSmall + $footerStripes + $footerMargin + $headerHeightLarge});
			}
			.error-indicator {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				color: $white;
				@include gotham(medium);
			}

			.content-filters {
				width: 100%;
				background-color: $white;
				position: relative;

				&.news {
					padding-top: 10px;
				}
				&.home {
					padding-top: 20px;
				}
			}

			.content-filters-spacer {
				width: 100%;
				height: 149px;
				display: none;

				&.home {
					height: 121px;
				}
			}

			&.fixed {
				.content-filters {
					background-color: $white;
					position: fixed;
					top: $headerHeightSmall;
					z-index: 110;

					width: 100%;

					@include breakpoint(tablet) {
						top: $headerHeightLarge;
					}
				}
				.content-filters-spacer {
					display: block;
				}
				.content-filters-scroll {
					//position: relative;
				}
			}
			&.fixedBak {
				.content-filters {
					background-color: $white;
					position: fixed;
					top: $headerHeightSmall;
					z-index: 100;
				}
			}

			&.webview {
				padding-top: 0px;
				padding-bottom: 0px;
			}

			&.flex {
				display: flex;
				align-content: flex-start;
			}
		}

		footer {
			position: relative;

			@include breakpoint(tablet) {
			}

			@include breakpoint(desktop) {
			}
		}
	}

	// primary container for content width
	// adjusts size based on view width in sidepanel expanded mode
	&.compressed .wrapper{
		width: calc(100% - #{$sidebarWidth});
	}
	
	&.expanded .wrapper {
		width: 100%;

		@include breakpoint(desktop) {
			width: 100%;
		}

		// sidepanel open or cloased based on user selection
		@include breakpoint(desktopSidePanel, mid) {
			width: calc(100% - #{$sidebarWidth});
		}

		// sidepanel always open
		@include breakpoint(largeDesktop) {
			width: calc(100% - #{$sidebarWidth});
		}

		// sidepanel always open and content area centered with curtains
		@include breakpoint(extraLargeDesktop) {
			width: calc(#{$maxWidth} - #{$sidebarWidth});
		}

		.content-main {
			&.fixed {
				.content-filters {
					@include contentMainWidth(false);
				}
			}
		}
	}

	// primary container for content width
	// when slamtracker panel is open at less than sidepanel sizes
	&.compressed .wrapper {
		width: calc(100% - #{$sidebarWidth});
	}
}
