.privacy-policy-nav{
    margin:15px 5%;
    @include gotham(normal);

    ul{
        margin-top:1.5em;
        li{
            list-style:disc;
            font-size:18px;

            &.major-policy{
                font-weight:bold;
                margin-bottom:1em;
            }

            a{
                color:$wim-green;
                text-decoration:none;
            }
        }
    }
}