.homepage-live{
    height:300px;
    padding:0;

    @include breakpoint(tablet,max){
        height:213px;
    }

    img{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width:auto;
        max-height: unset;
        max-width: unset;
        height: 100%;
        min-width:100%;
    }

    .title{
        color: $white;
        top: 114px;
        position: absolute;
        font-size: 30px;
        letter-spacing: 2.5px;
        text-transform: uppercase;
        width:100%;
        text-align:center;

        @include breakpoint(tablet, max){
            font-size:20px;
            letter-spacing:1px;
            top:72px;
        }
    }

    .time{
        font-size: 12px;
        color: $white;
        top: 86px;
        position: absolute;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        width:100%;
        text-align:center;

        @include breakpoint(tablet,max){
            font-size:10px;
            top:53px;
        }
    }

    .live-icon-wrapper{
        width:217px;
        text-align:center;
        font-size:13px;
        text-transform:uppercase;
        top:162px;
        left:50%;
        transform:translateX(-50%);
        position:absolute;
        display:flex;

        @include breakpoint(tablet,max){
            top:109px;
        }

        a{
            width:71px;
            color:$white;
            height:52px;
            display: flex;
            flex-direction: column;
            i{
                color:$live-green;
                flex:1 1 auto;
                &.disabled {
                    color: $gray-disable;
                }
            }
        }
        .live-score{
            margin-top: 2px;
            .wim-icon-scores{
                font-size:32px;
                margin-top:2px;
            }
        }
        
        .live-video{
            border-left:2px solid $gray-light;
            border-right:2px solid $gray-light;
            .wim-icon-video{
                font-size:34px;
            }
        }

        .live-radio{
            .wim-icon-radio{
                font-size:34px;
                margin-top:-2px;
            }
        }


    }
}