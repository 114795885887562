.pointNavOptions {
	position: relative;
	height: 40px;
	background:$pointNavOptionsBg;
	display:flex;
	justify-content: center;
	align-items: center;
	@include gotham-book;
	text-transform: uppercase;
	color: #FFFFFF;

	.filter {
		background-image: url(/assets/images/matcharchive/filters.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 20px;
		width: 20px;
		//height: 34px;
		//margin: 6px;
		cursor: pointer;
		height: 100%;
		margin: 0px;
		position: absolute;
		right: 0px;
		top:0px;
		margin-right: 8px;		
		
		&.active {
			border: 0px;
			border-color: transparent; /* remove the border's colour */
    		box-shadow: 0 0 0 1px #FFFFFF; /* emulate the border */
		}
	}

	.option {
		font-size: px2rem(12);
		width:calc(50% - 18px);
		text-align: left;
		cursor:pointer;
		&:first-child {
			text-align: right;
		}
		&.selected {
			@include gotham(medium);
		}
	}
	.separator {
		padding:0px 8px;
	}
	.playAllButton {
		text-transform: uppercase;
		font-size: 20px;
		color: #FFFFFF;
		position: absolute;
		top: 0px;
		right: 0px;
		margin: 10px;
		padding: 4px;
		cursor: pointer;

		&.active {
			border: 0px;
			border-color: transparent; /* remove the border's colour */
    		box-shadow: 0 0 0 1px #FFFFFF; /* emulate the border */
		}

		&.disabled {
			display: none;
		}
	}
}