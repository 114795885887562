.match-stats-container {
    text-align: center;
    font-size: 12px;

    .match-stats {
        .datatable {
            background-color: transparent;
            border: none;
        }
        .headers {
            width: 100%;
            height: 25px;
            background-color: rgba(216, 216, 216, .4);
            text-transform: uppercase;
            @include gotham(bold);
            padding-bottom: 4px;
            color: $white;
        }
        .row {
            width: 100%;
            height: 28px;
            @include gotham(bold);
            padding-bottom: 5px;
            color: $white;

            span {
                &.not-better {
                    @include gotham();
                    color: $white;
                }
            }
            .cell {
                border-top: 1px solid rgba(236, 236, 236, .3);
            }
            &:first-child {
                .cell {
                    border-top: none;
                }
            }
        }
        .p1 {  
            width: 30%;
            text-align: center;
        }
        .p2 {
            width: 30%;
            text-align: center;
        }
        .label {
            width: 40%;
            text-align: center;
            text-transform: uppercase;
            @include gotham(medium);
        }
    }
}