/**
attempt to create some common generic style for standrad content pages
- myW
- role registration invite
- ballot
**/

.page {
	.wrapper .content-main {
		&.content {
			padding-top: 0px;

			.content--header-wrapper {
				display: flex;
				position: absolute;
				left: 0px;
				bottom: 100%;
				color: white;
				width: 100%;
                z-index: -2;
				&::before{
					content: '';
					height: 180%;
					width: 100%;
					background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3), transparent);
					position: absolute;
					bottom: 0px;
				}
			}

			.column-layout {
				&.content {
					h4 {
						color: $wim-green;
						font-size: px2rem(22);
						text-align: center;
						text-transform: uppercase;
						margin-bottom: 20px;
						position: relative;

						&::after {
							content: '';
							position: absolute;
							height: 2px;
							width: 32px;
							background: $wim-green;
							right: 0;
							bottom: -16px;
							transform: translateX(-50%);
							left: 50%;
						}
					}

					p,
					span.p {
						font-size: $news-font-size-small;
						line-height: $news-text-line-height-small;
						margin: 0;
						padding-top: $item-v-margin;
						padding-bottom: $item-v-margin;
						&:first-child {
							padding-top: 0;
						}
						&:last-child {
							padding-bottom: 0;
						}

						@media only screen and (min-width: $column-break-1) {
							font-size: $news-font-size;
							line-height: $news-text-line-height;
						}
					}

					.body {
						text-align: center;
						font-size: 16px;
						width: 80%;
						margin-left: auto;
						margin-right: auto;
						//margin-bottom: 5px;

						&.bottom-margin {
							margin-bottom: 20px;
						}

						&.left-align {
							text-align: left;
						}
					}

					.flex-container {
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						justify-content: flex-start;
						align-content: stretch;
						align-items: flex-start;
					}

					.flex-title {
						order: 0;
						flex: 0 0 100%;
						align-self: auto;
						font-weight: bold;
					}

					.flex-item {
						flex: 1 1 auto;
						align-self: auto;
					}

					.spacer {
						height: 10px;
					}

					.center {
						text-align: center;
					}

					.table {
						display: table;
						font-size: 14px;
						padding-bottom: 10px;
						border-style: solid;
						border-color: rgba(173, 173, 173, 0.4);
						border-width: 1px;

						.row {
							display: table-row;
							height: 28px;
						}
						.text,
						.value {
							display: table-cell;
							vertical-align: middle;
						}
						.text {
							padding-right: 5px;
						}
					}
				}
			}

			.link {
				&.small {
					font-size: 12px;
				}
			}
		}

	}
}

.modal-on-noscroll-nofixed {
	/** to prevent the page to scroll top,
	    don't set position: fixed */
	overflow-y: hidden !important; // to override the inline style injected in the body tag
}

/** when the page should scrol top use this 
    e.g) Path to the Final
*/
.modal-on-noscroll {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	height: 100%;
	inset: 0;
	overflow-y: hidden;
}

.service-icon-test {
	width: 50px;

	g {
		stroke: blue;
	}
}


.difficult-color {
	color: $difficult;
}

.favourable-color {
	color: $favourable;
}

.neutral-color {
	color: $neutral;
}