/** draws styles **/
.page {
	.wrapper {
		.content-main.draws.fixed {
			.column-layout.fixedScroll {
				position: fixed;
				z-index: 100;
				top: $headerHeightSmall;
				overflow: hidden;

				.background {
					width: 100vw;
					height: 100vh;
					position: absolute;
					top: calc(#{$headerHeightSmall} * -1);
					left: 0px;
					z-index: -2;
					@include background-2x('https://assets.wimbledon.com/images/backgrounds/scores', 'jpg', auto, auto, 0 0);
					background-size: cover;
					

					@include breakpoint(largeDesktop){
						width: calc(100vw - #{$sidebarWidth});
					}

					// sidepanel always open with centered max width content
					@include breakpoint(extraLargeDesktop){
						width: calc(#{$maxWidth} - #{$sidebarWidth});
					}
				}

				@include breakpoint(tablet) {
					top: $headerHeightLarge;
					.background {
						top: calc(#{$headerHeightLarge} * -1);
					}
				}

			
				.compressed & {
					width: calc(100vw - #{$sidebarWidth});
				}

				@include breakpoint(desktopSidePanel) {
					.expanded & {
						width: calc(100vw - #{$sidebarWidth});
					}
				}

				@include breakpoint(largeDesktop) {
					.expanded & {
						width: calc(100vw - #{$sidebarWidth});
					}
				}

				@include breakpoint(extraLargeDesktop) {
					.expanded & {
						width: calc(#{$maxWidth} - #{$sidebarWidth});
					}
				}

			}

			.column-layout {
				.roundName.fixedScroll {
					position: fixed;
					z-index: 5;
					top: 152px;
					background: rgb(128, 143, 100);

					@include breakpoint(desktopSidePanel) {
						.expanded & {
							width: calc(100vw - #{$sidebarWidth});
						}
					}

					@include breakpoint(largeDesktop) {
						.expanded & {
							min-width: unset;
							width: calc(100vw - #{$sidebarWidth} );
						}
					}

					@include breakpoint(extralargeDesktop) {
						.expanded & {
							min-width: unset;
							width: calc(#{$maxWidth} - #{$sidebarWidth});
						}
					}
				}
			}

			.column-layout.draws-page {
				margin-top: 94px;
			}

      &.fullView {
        .menu-wrapper {
          /** pin the draw analysis box on full view above tablet - mnobile hidden */
					&.draws-analysis-menu {
            position: fixed;
            top: 157px;
          }
        }
		  }
		}

		.content-main.draws {
			padding-top: 0px;
			z-index: 1;

			.column-layout {
				padding-top: 0px;
				display: flex;
				flex-direction: column;

				.header.draws {
					.item.middle {
						@include breakpoint(tablet, max) {
							padding: 10px 0;
						}
					}
				}

				.header .item.middle {
					@include breakpoint(mobile, max) {
						width: initial;
					}
				}

				.draws-nav {
					height: initial;


					.round {
						.button-text-link {
							button {
								padding: 0 8px; // fix iPhone makes the round nav wider
							}
						}
					}
				}

				.draws-nav-column-titles {
					max-width: 600px;
					margin: auto;
					height: 32px;
					justify-content: left;

					@include breakpoint(tablet, max) {
						width: 380px;
					}

					&.half {
						width: 100%;
						height: 45px;
					}

					div {
						max-width: inherit;
						flex: initial;
						flex-basis: initial;
					}
				}
			}

			&.fullView {
				.column-layout {}



				.column-layout.draws-page {
					// position: fixed;
					overflow-x: scroll;
          scrollbar-width: none; //firefox
          -ms-overflow-style: none; //edge
          &::-webkit-scrollbar {display: none;} //chrome - ios
					z-index: -2;
					// height: calc(100vh - 130px);
					flex-direction: column;
					flex-wrap: nowrap;
					width: 100%;
					.drawsList {
						width: 100%;
						padding-left: 20px;
						padding-right: 20px;
						// height: 100vh;

						.roundGroup {

							&.columnB,
							&.columnC {
								display: block;
							}
						}
					}
				}

				.roundName {
					height: 25px;
					margin-top: 5px;
					background: rgba(255, 255, 255, 0.3);
					display: flex;
					padding-left: 20px;
					width: fit-content;
					min-width: 100%;

					span {
						color: $white;
						font-weight: normal;
						text-align: center;
						text-transform: uppercase;
						line-height: 24px;
						@include gotham(medium);
						font-size: 14px;
						width: 172px;
						margin-right: 3px;
					}
				}

				/** the box that shows up at the right corner 
			 this holds Draws Analysis component
		*/
				.menu-wrapper {
					position: absolute;
					top: 40px;
					right: 20px;
					//border-radius: 10px;
					background-color: $white;
					width: auto;

          display: none;
          @include breakpoint(tablet) {
            display: block;
          }

					&.draws-analysis-menu {
						.draw-analysis-wrapper {
							height: auto;
							overflow: hidden;
							animation-name: menu-open;
							animation-duration: 1s;
						}

						&.collapse {
							.separator {
								display: none
							}

							.draw-analysis-wrapper {
								height: 0px;
								margin-top: 0px;
								animation-name: menu-close;
								animation-duration: 1s;

							}
						}
					}

					// animation open/close stuff
					@keyframes menu-close {
						from {
							height: auto
						}

						to {
							height: 0
						}
					}

					@keyframes menu-open {
						from {
							height: 0;
						}

						to {
							height: auto;
						}
					}

					.draw-analysis-inner-wrapper {
						border: none;
					}
				}
			}
		}

		&.webview {
			margin: 0px auto;
			max-width: 1260px;
			background: none;

			&.debug {
				background-color: $gray-dark;
			}

			.content-main.draws {
				.column-layout {}

				&.fullView {
					.draws-page {}
				}
			}
		}

		// min-height: calc(100vh - 113px);
		&.scorespage {
			&.modal-on-noscroll {
				.footer-global {
					display: none;
				}
			}
		}
	}
}

.content-main {
	&.draws {
		height: 100%;
		overflow: auto;

		.column-layout {
			&.draws-page {
				margin: auto;

				z-index: -2;
				position: relative;

				@include breakpoint(mobile, max) {
					//padding: 10px 0px;
				}

				@include breakpoint(tablet, max) {
					// min-height: calc(100vh - 113px);
					// max-height: calc(100vh - 160px);
				}

				@include breakpoint(desktop) {
					//padding: 0 38px;
				}
			}

			&.no-padding {
				padding: 0px;
			}

			.draws-nav-column {
				display: flex;
				width: 100%;
				margin: 5px 0;

				@include breakpoint(mobile, max) {
					max-width: calc(100% - 10px);
					margin-right: auto;
					margin-left: auto;
				}

				&.half {
					@include breakpoint(tablet, max) {
						padding-left: 10px;
						padding-right: 10px;
					}

					.generic-button_wrapper {
						padding: 0px 2px;
						width: 100%;

						//@include breakpoint(tablet, max) { padding: 0px 10px; }
						.generic-button {
							width: 100%;
						}
					}

					.generic-button_wrapper.clicked .generic-button .content {
						color: #333333;
						background-color: rgba(255, 255, 255, 0.7);
						display: inline-block;
					}

					.button-text-link {
						text-decoration: none;

						button {
							height: 100%;
							width: 100%;
						}
					}
				}
			}
		}

		.fullHeight {
			height: 860px;
			text-align: center;

			.embed-responsive-item {
				@include breakpoint(tablet, max) {
					display: none;
				}
			}

		}

		/** path to the final overlay is displayed,
	*   disable the main draw content scrolling */
		&.modal-on-noscroll {
			.column-layout {
				&.draws-page {
					position: fixed;
					overflow: hidden;
				}
			}
		}

		// modal-on-noscroll
	}
}

.draws-message {
	font-size: 18px;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	text-align: center;
	width: 80%;
	margin: 50px auto;
	color: white;
}

@include breakpoint(mobile) {
	.stub_box {
		left: calc(50% - 140px);
		width: 280px;

		.text {
			font-size: 16px;
		}
	}
}

@include breakpoint(tablet) {
	.stub_box {
		left: calc(50% - 250px);
		width: 500px;

		.text {
			font-size: 24px;
		}
	}
}

.draws-nav {
	display: flex;
	margin-top: 3px;
	align-items: center;
	justify-content: space-between;

	a,
	span {
		flex: 0 1 12.5%;
		text-decoration: none;
		text-align: center;

		.button-text-link {
			text-decoration: none;
		}
	}

	.full {
		display: flex;
		line-height: 16px;
		text-transform: uppercase;

		.item {
			font-size: 12px;
		}
	}

	.item {
		// width: 60px;
		margin: auto;
		margin-top: 1px;
		cursor: pointer;

		text-align: center;
		@include gotham-book();
		font-size: 18px;
		color: #FFFFFF;
		padding-bottom: 3px;

		// &:hover {color: $white; border-bottom: solid 0px $white;}
		&.selected {
			border-bottom: solid 3px $white;
			color: #FFFFFF;
			@include gotham(medium);
			//margin-bottom: -3px
			margin-top: 0px;
			// width: 100%;
			width: min-content;
		}

		&.fullView {
			font-size: px2rem(14);
			text-transform: uppercase;
			text-align: center;
			width: auto;
			margin-top: -2px;
		}
	}
}

.draws-nav-column-titles {
	//margin-left: 0 !important;
	margin-bottom: 15px;

	@include breakpoint(mobile) {
		// .long {
		//   display: none;
		// }

		.short {
			display: block;
		}
	}

	.fullView {
		color: rgba(255, 255, 255, 0.65);
		text-align: center;
	}
}

.wim-icon-left-arrow:before,
.wim-icon-right-arrow:before {
	cursor: pointer;
}

.draws-arrow-left {
	color: $gray_9b;
	font-size: 2em;
	position: absolute;
	top: 80px;
	left: 20px;

	&:hover {
		cursor: pointer;
	}

	&.hide {
		display: none;
	}
}

.draws-arrow-right {
	color: $gray_9b;
	font-size: 2em;
	position: absolute;
	top: 80px;
	right: 20px;

	&:hover {
		cursor: pointer;
	}

	&.hide {
		display: none;
	}
}

.drawsPdf {
	display: block;
	color: $white;
	font-size: 24px;
	float: left;

	&:hover {
		cursor: pointer;
	}

	@include breakpoint(mobile) {
		display: none;
	}

	@include breakpoint(tablet) {
		display: block;
	}
}

/***
** draws match box offsets for singles
*/
$columnA-offset-bracket: calc(10px); // 1/2 height of matchbox - 1/2 bottom margin
$columnB-offset-bracket: calc(#{$columnA-offset-bracket} + 28.5px + 1px); // 1/2 height of matchbox - 1/2 bottom margin
$columnC-offset-bracket: calc(#{$columnB-offset-bracket} + 28.5px + 1px);
$columnD-offset-bracket: calc(#{$columnC-offset-bracket} + calc(2 * 28.5px) + calc(2 * 1px));
$columnE-offset-bracket: calc(#{$columnD-offset-bracket} + calc(4 * 28.5px) + calc(4 * 2px));
$columnF-offset-bracket: calc(#{$columnE-offset-bracket} + calc(8 * 28.5px) + calc(8 * 1px));
$columnG-offset-bracket: calc(#{$columnF-offset-bracket} + calc(8 * 28.5px) + calc(8 * 1px));

/***
** draws match box offsets for doubles
*/
$doubles-columnA-offset-bracket: calc(10px); // 1/2 height of matchbox - 1/2 bottom margin
$doubles-columnB-offset-bracket: calc(#{$doubles-columnA-offset-bracket} + 57px + 3px); // 1/2 height of matchbox - 1/2 bottom margin
$doubles-columnC-offset-bracket: calc(#{$doubles-columnB-offset-bracket} + 57px + 3px);
$doubles-columnD-offset-bracket: calc(#{$doubles-columnC-offset-bracket} + calc(2 * 57px) + calc(2 * 3px));
$doubles-columnE-offset-bracket: calc(#{$doubles-columnD-offset-bracket} + calc(4 * 57px) + calc(2 * 3px));
$doubles-columnF-offset-bracket: calc(#{$doubles-columnE-offset-bracket} + calc(8 * 57px) + calc(8 * 3px));
$doubles-columnG-offset-bracket: calc(#{$doubles-columnF-offset-bracket} + calc(8 * 57px) + calc(8 * 3px));

//calc(matchbox height(50) + (margin bottom A (2) * 2));
@function getMarginBottom ($column: A, $match: singles) {
	@if $match==singles {
		@if $column==A {
			@return 2px;
		}

		@else if $column==B {
			@return calc(57px + (2px * 2));
		}

		@else if $column==C {
			@return calc(114px + (2px * 2));
		}

		@else if $column==D {
			@return calc(228px + (2px * 2));
		}

		@else if $column==E {
			@return calc(456px + (3px * 4));
		}

		@else if $column==F {
			@return calc(912px + (4px * 8));
		}

	}

	@else if $match==doubles {
		@if $column==A {
			@return 2px;
		}

		@else if $column==B {
			@return calc(114px + (2px * 2));
		}

		@else if $column==C {
			@return calc(228px + (2px * 4));
		}

		@else if $column==D {
			@return calc(456px + (2px * 8));
		}

		@else if $column==E {
			@return calc(912px + (2px * 16));
		}

		@else if $column==F {
			@return calc(1824px + (2px * 32));
		}
	}
}


// $matchbox-width: 154px;

/***
** draws match boxes wrapper styles
*/

.drawsList.draws {
	z-index: 1;
	width: 100%;
	// height: 100vh;

	@include breakpoint(tablet, max) {
		padding-left: 5px;
		padding-right: 5px;
		// height: 100vh;
	}

	@include breakpoint(mobile, max) {
		&.four-col {
			width: 100%;
			margin: 7px 0;
		}
	}



	&.winnerBoxShown {
		margin-top: 150px;
	}
	&.winnerBoxShown-doubles {
		margin-top: 240px;
	}
	&.winnerBoxShown-smallTopPadding {
		margin-top: 40px;

	}

	.match-box-draws {
		.match-status {
			.event-notes {
				display: block;
				position: absolute;
				bottom: 0;
				right: 0;
			}
		}

		&.full {
			.without-ai-menu {
				.menu-wrapper {
					width: 100%;
					top: 5px;
					right: 0;
				}

				.name-container {
					width: 75%;

					.name {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						cursor: pointer;
						max-width: 100%;
					}
				}
			}
		}
	}

	.menu-wrapper {
		position: absolute;
		width: 100%;
		z-index: 3;
		top: 5px;
		background: #FFFFFF;

		// height: 160px;
		box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.25);
		border-radius: 8px;

		.close-btn-container {
			right: 0;
			position: absolute;
			margin: 0px 15px;
			font-size: px2rem(8)
		}

		.collapse-btn-container {
			top: 15px;
			right: 15px;
			position: absolute;
			color: $wim-green;
			font-size: px2rem(8)
		}
	}

	.menu-container {
		.menu-content {
			>.content {
				// < because it conflicts with other '.content' classes
				display: flex;
				flex-direction: column;
				text-align: left;
				margin: 10px;

				&.with-likelihood {
					.item {
						cursor: pointer;
					}
				}

				.name-container {
					display: flex;
				}

				.name-container {
					margin-bottom: 10px;
					margin-top: 0px;
					@include gotham-book();


					.icon {
						margin-left: 5px;
						text-decoration: none;
					}

					.intro {
						color: $wim-text-purple;
						text-align: center;
						font-weight: bold;
						width: 100%;
					}
				}

				.winner-title {
					@include gotham(medium);
					color: $wim-green;
					font-weight: bold;
					padding-left: 10px;
					text-transform: uppercase;
				}

				.black {
					color: $wim-black;
				}

				.item,
				.name {
					color: #00703C;
				}

				.item,
				.button-text-link {
					margin-bottom: 15px;
					@include gotham(medium);

					button {
						padding-left: 0;
						
						&:hover {
							cursor: pointer;
							text-decoration: underline;
						}
					}
				}

				.separator {
					outline: 1px solid #D8D8D8;
					margin-bottom: 10px;
				}

				/** draw analysis componen style override */
				.draw-analysis-wrapper {
					margin-top: 0;

					.intro .help {
						top: 0;
					}
				}

				.draw-analysis-inner-wrapper {
					padding: 0;

					p {
						margin-bottom: 2px;
					}

					.extra-margin {
						margin-top: 10px;
					}
				}

				.drawAnalysisBar {
					margin-top: 6px;
					margin-bottom: 6px;
					margin-left: auto;
					margin-right: auto;
				}
			}
		}
	}

	.full {
		.debugId {
			position: absolute;
			right: 3px;
			font-size: 10px;
			font-family: Helvetica;
		}
		
		.match-status {
			justify-content: center;
			height: 100%;
			padding-left: 0;
			border: 1px solid #D8D8D8;
			width: 172px;
			// padding: 0px 7px;
			text-align: left;

			&.selected {
				border: 2px solid #333333;
			}

			.team-info {
				height: 50%;

				&.team-one {
					.team-name {
						position: relative;
						margin: 0;

						.members {
							width: 100%;
							position: absolute;
							bottom: 0;
						}
					}

					&.highlight {
						border-top-left-radius: 8px;
						border-top-right-radius: 8px;
					}
				}

				&.team-two {
					&.highlight {
						border-bottom-right-radius: 8px;
						border-bottom-left-radius: 8px;
					}
				}

				&.doubles {

					.member-one,
					.member-two {
						height: initial
					}

					.name,
					.seed {
						line-height: 18px;
						height: 18px;
						align-self: end;
						// width: 140px;
					}

					.team-name {
						.members {
							margin: 9px 0;
						}
					}

					// .seed{line-height: 24px; height: 18px;}
				}

				.flag {
					margin-left: 7px;
				}


				&.favourable {
					background-color: $favourable-bg;

					&:hover {
						cursor: pointer;
					}
				}

				&.neutral {
					background-color: $neutral-bg;

					&:hover {
						cursor: pointer;
					}
				}

				&.difficult {
					background-color: $difficult-bg;

					&:hover {
						cursor: pointer;
					}
				}

				&.post-match-color {
					background-color: $postmatch-bg;

					&:hover {
						cursor: pointer;
					}
				}

				&.clickable {

					&:hover {
						cursor: pointer;
					}

				}
			}

			.separator {
				margin: 0 7px;
				outline: 1px solid $gray-200;
			}

			.separator-hl {
				display: none;
			}

			.team-name {
				max-width: initial;
				width: 100%;
				height: 100%;
				border-top: initial;
				padding-top: 0px;

				.member-one,
				.member-two {
					height: 25px;
					align-items: center;

					&.favourable {
						background-color: $favourable-bg;
					}

					&.neutral {
						background-color: $neutral-bg;
					}

					&.difficult {
						background-color: $difficult-bg;
					}

					&.post-match-color {
						background-color: $postmatch-bg;
					}
				}

				.name {
					cursor: default;
				}
			}
		}
	}

	.roundGroup {
		float: left;
		box-sizing: border-box;
		width: calc(100% - (20px));
		text-align: center;

		@include breakpoint(mobile) {
			width: calc(100% - (20px));

			&.columnA {
				float: none;
				margin-right: auto;
				margin-left: auto;
			}

			&.columnB,
			&.columnC {
				display: none;
			}
		}

		@include breakpoint(tablet) {
			width: calc((100%/2) - (25px/2));

			&.columnA {
				float: left;
				margin-right: 24px;
				margin-left: 0;
				display: block;
			}

			&.columnB {
				margin-right: 0;
				display: block;
			}

			&.columnC {
				display: none;
			}
		}

		@include breakpoint(desktop) {
			width: calc((100%/3) - (55px/3));

			&.columnA {
				float: left;
				margin-left: 0;
				margin-right: 24px;
				display: block;
			}

			&.columnB {
				margin-right: 25px;
				display: block;
			}

			&.columnC {
				display: block;
			}
		}

		@include breakpoint(desktopSidePanel) {
			.expanded & {
				width: calc((100%/2) - (25px/2));

				&.columnA {
					float: left;
					margin-right: 24px;
					margin-left: 0;
					display: block;
				}

				&.columnB {
					margin-right: 0;
					display: block;
				}

				&.columnC {
					display: none;
				}
			}
		}

		@include breakpoint(largeDesktop) {
			.expanded & {
				width: calc((100%/3) - (55px/3));

				&.columnA {
					float: left;
					margin-left: 0;
					margin-right: 24px;
					display: block;
				}

				&.columnB {
					margin-right: 25px;
					display: block;
				}

				&.columnC {
					display: block;
				}
			}
		}

		.roundName {
			color: $white;
			font-weight: normal;
			text-align: center;
			text-transform: uppercase;
			//font-size: px2rem(12);
			//margin-bottom: 10px;
			line-height: 28px;
			@include gotham(medium);
			display: block;
			margin-bottom: 10px;
		}

		.drawGroup {
			display: block;
			width: 100%;
			position: relative;
		}

		&.columnA {
			.drawGroup {
				margin-bottom: 25px;
			}

			.drawGroup:last-of-type {
				margin-bottom: 0px;
			}
		}

		&.columnB {
			.drawGroup:first-of-type {
				margin-top: 74px;
			}

			.drawGroup:last-of-type {
				margin-bottom: 0px;
			}

			.drawGroup {
				margin-bottom: 173px;

				&.winner {
					margin-top: 22px;
				}

				&.qualifier {
					&:first-of-type {
						margin-top: 25px;
					}

					margin-bottom: 68px;
				}
			}
		}

		&.columnC {
			.drawGroup:first-of-type {
				margin-top: 222px;
			}

			.drawGroup:last-of-type {
				margin-bottom: 0px;
			}

			.drawGroup {
				margin-bottom: 469px;

				&.winner {
					margin-top: 96px;
				}

				&.qualifier {
					&:first-of-type {
						margin-top: 85px;
					}

					margin-top: 0px;
					margin-bottom: 184px;
				}
			}
		}

		&.hide {
			display: none;
		}

		// add to <div class="roundGroup"> in mobile view to show only one column
		// this class will make the matchboxes align like columnA
		// and second visible column to behave like columnB
		&.selected {

			&.columnB,
			&.columnC {
				.drawGroup {
					margin-top: 0;
					margin-bottom: 24px;
				}
			}

			@include breakpoint(tablet) {
				&.columnB {
					.drawGroup {
						margin-top: 0;
						margin-bottom: 24px;
					}
				}

				&.columnC {
					.drawGroup {
						&.first-of-type {
							margin-top: 175px;
							margin-bottom: 195px;
						}

						margin-top: 165px;
						margin-bottom: 195px;
					}
				}
			}
		}

		// Draws fullView styles
		.fullView & {
			&.roundGroup {
				@include breakpoint(mobile, min) {
					width: initial;
				}

				&.columnA {
					float: left;
					margin-left: 0;

					.match-status.singles {
						height: 57px;
					}

					.match-status.doubles {
						height: 114px;
					}

					.drawGroup {
						margin-bottom: getMarginBottom(A, singles);

						&:first-of-type {
							margin-top: $columnA-offset-bracket;
						}

						&:last-child {
							margin-bottom: 20px;
						}
					}
				}

				&.columnA,
				&.columnB,
				&.columnC,
				&.columnD,
				&.columnE,
				&.columnF,
				&.columnG {
          .winner{display: none;}
					margin-right: 3px;
          &.last{
            .drawGroup{
              position: unset;
            }
            .winner-info{
              display: flex;
              flex-direction: column;
              justify-content: center;
              min-height: 178px;
              background-color: white;
              position: absolute;
              top: 40px;
              width: 178px;
              border-radius: 8px;
              border: 2px solid #00703C;
              padding: 20px 0px;
              @include gotham(medium);
              .year{font-size: 1rem; }
              .flag{margin-right: 5px; padding-top: 2px;}
              .name{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100%;
              }
              .winner-name{display: flex; justify-content: center;}
              .photo{margin: 10px auto;
                .player-img-container{
                  margin-bottom: 5px;
                }
              }
              .winner-name{max-width: 130px; margin: 0 auto;}

			  &.right-align {
				@include breakpoint(tablet) {
				  right: 10px;
				}
			  }
            }
          }
				}

				&.columnB {
					.match-status.singles {
						height: 57px
					}

					.match-status.doubles {
						height: 114px;
					}

					.drawGroup.singles {

						&:first-of-type {
							margin-top: $columnB-offset-bracket;
						}

						margin-bottom: getMarginBottom(B, singles);
					}

					.drawGroup.doubles {
						&:first-of-type {
							margin-top: $doubles-columnB-offset-bracket;
						}

						margin-bottom: getMarginBottom(B, doubles);
					}

					.drawGroup {
						&:last-child {
							margin-bottom: 20px;
						}
					}
				}

				&.columnC {
					.match-status.singles {
						height: 118px
					}

					.match-status.doubles {
						height: 228px;
					}

					.drawGroup.singles {
						&:first-of-type {
							margin-top: $columnC-offset-bracket;
						}

						margin-bottom: getMarginBottom(C, singles);
					}

					.drawGroup.doubles {
						&:first-of-type {
							margin-top: $doubles-columnC-offset-bracket;
						}

						margin-bottom: getMarginBottom(C, doubles);
					}

					.drawGroup {
						&:last-child {
							margin-bottom: 20px;
						}
					}
				}

				&.columnD {
					.match-status.singles {
						height: 240px;
					}

					.match-status.doubles {
						height: 456px;
					}

					.drawGroup.singles {
						&:first-of-type {
							margin-top: $columnD-offset-bracket;
						}

						margin-bottom: getMarginBottom(D, singles);
					}

					.drawGroup.doubles {
						&:first-of-type {
							margin-top: $doubles-columnD-offset-bracket;
						}

						margin-bottom: getMarginBottom(D, doubles);
					}

					.drawGroup {
						&:last-child {
							margin-bottom: 20px;
						}
					}
				}

				&.columnE {
					.match-status.singles {
						height: 475px;
					}

					.match-status.doubles {
						height: 918px; // 912px;
					}

					.drawGroup.singles {
						&:first-of-type {
							margin-top: $columnE-offset-bracket;
						}

						margin-bottom: getMarginBottom(E, singles);
					}

					.drawGroup.doubles {
						&:first-of-type {
							margin-top: $doubles-columnE-offset-bracket;
						}

						margin-bottom: getMarginBottom(E, doubles);
					}

					.drawGroup {
						&:last-child {
							margin-bottom: 20px;
						}
					}
				}

				&.columnF {
					.match-status.singles {
						height: 944px;
					}

					.match-status.doubles {
						height: 1852px; //1824px;
					}

					.drawGroup {
						&:first-of-type {
							margin-top: $columnF-offset-bracket;
						}

						margin-bottom: getMarginBottom(F, singles);
					}

					.drawGroup.doubles {
						&:first-of-type {
							margin-top: $doubles-columnF-offset-bracket;
						}

						margin-bottom: getMarginBottom(F, doubles);
					}

					.drawGroup {
						&:last-child {
							margin-bottom: 20px;
						}
					}
				}

				&.columnG {
					.match-box-draws {
						.match-status.singles {
							height: 2360px;
						}
					}

					.drawGroup {
						&:first-of-type {
							margin-top: $columnG-offset-bracket;
						}

						&:last-child {
							margin-bottom: 20px;
						}

						margin-bottom: 0px;
					}

					.match-box-draws {
						.match-status.doubles {
							height: 1326px;
						}
					}

					.drawGroup.doubles:first-of-type {
						margin-top: 1100px;
						;
					}
				}
			}
		}

		&.columnA .connector-round {
			display: none;
		}

		.connector-round {
			width: 60px;
			height: 148px;
			position: absolute;
			left: -40px;
			top: -28px;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			z-index: -1;

			.left-side {
				width: 50%;
				height: 100%;
				border-top: solid 1px #FFFFFF;
				border-bottom: solid 1px #FFFFFF;
				border-right: solid 1px #FFFFFF;
			}

			.right-side {
				height: 50%;
				width: 50%;
				border-bottom: solid 1px #FFFFFF;
				position: absolute;
				top: 0px;
				right: 0px;
			}
		}

		&.columnC .connector-round {
			top: -102px;
			height: calc(154px + 124px + 10px + 10px);
		}

	}
}

/** path to the final overlay is displayed,
*   disable the main draw content scrolling */
body.modal-on-noscroll {

	// .page .wrapper .content-main.draws,
	#powerindex-leaderboard {
		position: fixed;
		overflow: hidden;
	}

	.expanded {
		/** prevent the PI table bleeding out to the right edge */
		#powerindex-leaderboard {
			position: absolute;
			width: 100%;
		}

	}
}

// modal-on-noscroll

.default {
  &.webview {
    .page {
      .wrapper {
        .content-main.draws {
          &.fullView {
            .menu-wrapper {
              display: none;
            }
          }

		  .drawsList.draws {
			.match-box-draws {
				.match-status {
					.name {
						display: flex;
						align-items: center;
					}
				}
			}
		  }

		  .draws-nav {
			.round {
				.button-text-link {
					button {
						padding: 0 8px; // fix iPhone makes the round nav wider
					}
				}
			}
		  }
        }
      }
    }
  }
}

/** override drow analysis box with side panel when scrolled */

.page {
	&.expanded, &.compressed {
		.wrapper {
			.content-main.draws.fixed {
				&.fullView {
					.menu-wrapper {
						/** pin the draw analysis box on full view above tablet - mnobile hidden */
						&.draws-analysis-menu {
							right: calc(#{$sidebarWidth} + 35px);

							/** calc unknow side margin and position the draw analysis menu */
							@include breakpoint(extraLargeDesktop) {
								right: calc(((100vw - #{$maxWidth}) / 2) + #{$sidebarWidth} + 10px);
							}
						}
					}
				}
			}
		}

	}
}