/** 
 * head to head content section
 * includes:
 *   Head to head and stats tabs and content
 *   Past Matchups
 *   Shot Quality
 **/

.slamtracker-headtohead-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    // /** override page-nav-container styles for tabs */
    // .page-nav-container {
    //     .nav-container {
    //         &.slamtracker-headtohead-tabs {
    //             .page-nav-list {
    //                 li {
    //                     @include gotham-book();
    //                     font-weight: normal;

    //                     &.active {
    //                         @include gotham(medium);
    //                     }
    //                 }
    //                 li:first-child {
    //                     margin-right: 16px;
    //                 }
    //                 li:last-child {
    //                     margin-left: 16px;
    //                 }
    //                 li.divider{
    //                     &:after {
    //                         height: 11px;
    //                         width: 2px;
    //                         background: $lines-gray;
    //                         top: 4px;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }

    .headtohead-stats-content {
        .page-nav-container {
            margin-bottom: 0;
        }
        .headtohead, .stats {
            &-content {
                padding: 20px 0 22px 0;
                display: flex;
                flex-direction: column;
                @include gotham(medium);
                font-size: px2rem(12);

                &-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    min-height: 20px;
                    position: relative;
                    padding: 6px 12px 0 13px;
                    margin: 3px 0;
                    &.players{
                        align-items: center;
                        .t1, .t2, .label{
                            align-self: unset;
                        }
                    }

                    .t1 {
                        color: $wim-text-purple;
                        width: 50%;
                        text-align: left;
                        align-self: start;
                    }
                    .t2 {
                        color: $wim-green;
                        width: 50%;
                        text-align: right;
                        align-self: start;
                    }
                    .label {
                        color: $wim-black;
                        text-transform: uppercase;
                        position: absolute;
                        left: 18%;
                        width: 64%;
                        text-align: center;
                        align-self: start;
                    }
                    &.players {
                        height: 50px;
                        margin: 14px 0;
                        border-top: 1px solid #ECECEC;
                        border-bottom: 1px solid #ECECEC;
                        @include gotham(bold);
                        padding: 0 12px 0 13px;
                        &:first-child {
                            margin-top: 0;
                            border-top: none;
                        }
                    }
                }
                &.doubles {
                    padding-top: 0;
                }
            }
        }
        .stats {
            &-content {
                &-row {
                    .t1 {
                        align-self: center;
                    }
                    .t2 {
                        align-self: center;
                    }
                    .label {
                        align-self: center;
                    }
                }
            }
        }
        .headtohead, .stats {
            &-content {
                &-row {
                    .t1, .t2 {
                        width: 35%;
                    }
                }
            }
        }
        .stats {
            padding: 0;

            &-content {
                padding: 0 12px 0 13px;

                &-row {
                    border-top: 1px solid #ECECEC;
                    height: 37px;

                    &:first-child {
                        border-top: none;
                    }
                    .t1 {
                        color: $gray_bb;

                        &.winner {
                            color: $wim-text-purple;
                        }
                    }
                    .t2 {
                        color: $gray_bb;

                        &.winner {
                            color: $wim-green;
                        }
                    }
                    &.header {
                        height: 50px;

                        .t1 { color: $wim-text-purple; }
                        .t2 { color: $wim-green; }
                    }
                    &.disclaimer {
                        border-top: 1px solid #ECECEC;
                    }
                }

                &.metric {
                    .stats-content-row.metric {
                        display: flex;
                    }
                    .stats-content-row.imperial {
                        display: none;
                    }
                }
                &.imperial {
                    .stats-content-row.imperial {
                        display: flex;
                    }
                    .stats-content-row.metric {
                        display: none;
                    }
                }
            }
        }
    }

    .pastmatchups {
        .pastmatchups-item {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 16px 35px;
            border-top: 1px solid #999999;

            &:first-child {
                border-top: none;
            }
            &-row {
                @include gotham(medium);
                font-size: px2rem(12);
                color: $wim-black;

                .tournament {
                    font-size: px2rem(16);
                    text-transform: uppercase;
                    color: $wim-text-purple;
                    margin-bottom: 6px;
                }
                .round-opponent {
                    @include gotham(bold);
                    margin-bottom: 3px;

                    .round {
                        text-transform: uppercase;
                    }
                }
                .status {
                    margin-bottom: 3px;
                }
            }
        }
        .pastmatchups-showmore {
            display: flex;
            height: 80px;
            justify-content: center;
            align-items:center;
            border-top: 1px solid #999999;

            /** override generic-button_wrapper styles */
            .generic-button_wrapper { 
                .generic-button {
                    width: 150px;
                    height: 40px;

                    .content {
                        font-size: px2rem(14);
                        padding-top: 0;
                    }
                    .border {
                        border-width: 2px;
                    }
                }
            }
        }
    }

    .shotquality {
        &-content {
            padding: 0 12px 10px 13px;

            .shotquality-item {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-top: 14px;

                &-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;

                    &.header {
                        width: 100%;
                        padding-left: calc(25% + 6px);
                        padding-bottom: 5px;
                        text-transform: uppercase;
                        @include gotham(medium);
                        font-size: px2rem(12);
                        color: $wim-black;
                    }
                    &.p1 {
                        color: $wim-text-purple;

                        .bar {
                            background-color: $wim-text-purple;
                        }
                    }
                    &.p2 {
                        color: $wim-green;

                        .bar {
                            background-color: $wim-green;
                        }
                    }
                    &.avg {
                        color: $md-gray2;

                        .bar {
                            background-color: $md-gray2;
                        }
                    }
                    .label {
                        width: 30%;
                        padding-right: 6px;
                        text-align: right;
                        @include gotham(medium);
                        font-size: px2rem(12);
                        align-self: center;
                    }
                    .bar {
                        max-width: 58%;
                        height: 10px;
                    }
                    .number {
                        width: 12%;
                        padding-left: 6px;
                        @include gotham-book();
                        font-size: px2rem(18);
                    }
                }
            }
        }
    }

    .disclaimer {
        @include gotham(medium);
        font-size: px2rem(11);
        color: $wim-black;
        width: 100%;
        height: 54px !important;
        align-items: center;
        display: flex;
        justify-content: center !important;
        flex-direction: column !important;
    }
}