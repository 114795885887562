.newsletter-form{
    width:100%;
    color:$wim-black;
    .column-layout {
        padding: 0px;
    }
	.one-third {
		width: calc(100%);
		@include breakpoint(desktop, min) {
			width: calc(33.33%);
		}
    }
    .four-col {
        margin: 0;
    }
    .two-col {
        width: 100%;
        margin: 0;
        @include breakpoint(desktop, min) {
            width: 50%;
        }
    }
    @include breakpoint(tablet, max){
        .form-error{
            margin: 5px 0;
        }
    }

    .mandatory-warning{
        color:$gray-disable;
        font-size:12px;
        font-weight:bold;
        padding: 0 7px;
    }

    hr{
        height:1px;
        margin:40px 0;
        background-color:$gray-light;
        border:none;
    }

    form{
        display:flex;
        flex-direction:column;
        width:100%;

        label{
            @include gotham(medium);
            input{
                // @include gotham(normal);
            }
        }

        select{
            border-radius: 0px;
        }

        .name-bar,.email-bar,.bd-adr-bar,.form-opt-in,.redirect-warning,.newsletter-form-submit,.unsubscribe-info,.data-privacy-notice{
            display:flex;
            flex:1 1 85px;

            label{
                flex:1 1 0;
                margin:0 5px;
            }

            @include breakpoint(tablet,max){
                flex-direction:column;
                flex: 1 1 auto;

                label{
                    flex:1 1 85px;
                }
            }
        }

        .unsubscribe-info,.data-privacy-notice{ 
            align-items: center;
            text-align: left;
        }

        .redirect-warning,.newsletter-form-submit{
            align-items:center;
            align-self:center;
            text-align:center;
        }

        .form-opt-in{
            margin-left: auto;
            margin-right: auto;
        }

        .form-opt-in,.redirect-warning,.newsletter-form-submit,.unsubscribe-info,.data-privacy-notice{
            flex:1 1 60px;

            /*input{
                width:25px;
            }*/

            @include breakpoint(tablet,max){
                flex: 1 1 80px;
                flex-direction:row;
                input{
                    width: 80px;
                }
            }

            a{
                color:$wim-black;
            }
        }

        .form-opt-in{
            margin-top: 20px;
            margin-bottom: 20px;
            text-align:left;
            flex-direction:column;
            flex: 1 1;

            div{
                display:flex;
            }
        }

        .unsubscribe-info{
            color:$gray-disable;
            font-size:12px;
        }

        .newsletter-form-submit{
            input{
                @include gotham(normal);
                width: 200px;
                height: 40px;
                color: $white;
                background-color: $wim-green;
                text-transform: uppercase;
                font-size: 20px;
                letter-spacing: 2px;
				font-weight: bold;
				cursor: pointer;
            }
        }
    }
}