.matchOverview {
	//height: 320px;
	//width: 400px;
	height:calc(100% - 38px - 4px);
	//background-color: rgb(128, 128, 128);
	display: none;

	margin-top:2px;
	border:solid 1px $accordionWrapBorder;
	padding:8px 0px;
	color:#FFFFFF;
	font-size:px2rem(12);
	// overflow:auto;
	// overflow-x:hidden;

	.inner {
		overflow:auto;
		overflow-x:hidden;
		padding:0px 8px;
		height:100%;
	}
	&.show {
		display: block;
	}
}