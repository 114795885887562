/**
 * column layout 
 */

.page {
    .wrapper .content-main {
        &.landing {
            &.fixed {
                //width: inherit;
            }

            
            .landing--header-wrapper {
                display: flex;
                position: absolute;
                width: 100%;
                bottom: 100%;
                text-shadow: $textShadow;

                &::before{
                    content: '';
                    height: 180%;
                    width: 100%;
                    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3), transparent);
                    /* background-size: cover; */
                    /* background-position: center; */
                    /* background-attachment: fixed; */
                    position: absolute;
                    bottom: 0px;
                }
            

                .landing--header {
                    &.hide {
                        opacity: 0;
                        transition: opacity .5s linear;

                    }
                    &.show {
                        opacity: 1;
                        transition: opacity .5s linear;
                    }
                    margin: 0 auto;
                    width: 80%;
                    color: $white;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end; //pointer-events: none;
                    opacity: 1;
                    z-index: 2;
                    h2,
                    h3,
                    h4 {
                        margin: 0px;
                    }
                    &-icon {
                        flex: 0 1 auto;
                        align-self: center;
                        margin: 0 0 4px 0;
                        text-align: center;
						font-size: 32px;
						color: $white;
                    }
                    &-date {
                        flex: 0 1 auto;
                        align-self: center;
                        margin: 0 0 4px 0;
                        text-align: center;
						letter-spacing: 1px;
						color: $white;
                    }
                    &-title {
                        flex: 0 1 auto;
                        align-self: center;
                        margin: 0 0 20px 0;
						text-align: center;
						color: $white;
						
						@include breakpoint(tablet, max) {
                            h1 {
                                font-size: 20px;
                                letter-spacing: .5px;
                            }
                        }
                        a,
                        a:hover,
                        a:active,
                        a:visited {
                            color: $white;
                            text-decoration: none;
                        }
                    }
                    &-abstract {
                        line-height: 18px;
                        text-align: center;
                        flex: 0 1 auto;
                        align-self: center;
                        margin: 0 0 23px 0;
                        max-width: 650px;
                        letter-spacing: 1px;

                        h3,
                        h4 {
                            display: inline;
                        }
                        h3 {
                            white-space: nowrap;
                        }
                        @include breakpoint(tablet, max) {
                            letter-spacing: 0.5px;
                        }
                    }
                }

                &.archive {
                    .landing--header {
                        .landing--header-title {
                            h2{
                                
                                @include gotham-book();
                                font-size: 48px;
                                margin: 0 0 90px 0;

                                @include breakpoint(largeDesktop){
                                    //font-size: 48px;
                                    //margin: 0 0 70px 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .errorMessage {
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            margin-top: 30px;
            font-size: 1.3em;
            display: none;
            &.show {
                display: block;
            }
        }
    }

}

.column-layout {
    &.landing {
        width: calc(100% - 30px);
        margin: 0 15px 0 15px;
        .component {
            margin: 8px 17px 8px 17px;
            flex: 0 1 auto;
        }
        @media only screen and (min-width: $column-break-page-1) {
            width: calc(100% - 34px);
            margin: 0 17px 0 17px;
            .one-col {
                width: calc(50% - 14px);
                margin: 7px 7px 7px 7px;
            }
        }
        @media only screen and (min-width: $column-break-page-2) {
            width: calc(100% - 74px);
            margin: 0 37px 0 37px;
            .one-col {
                width: calc(25% - 14px);
                margin: 7px 7px 7px 7px;
            }
        }
    }
}