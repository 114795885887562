.interests-comp {
    @media (width:320px) {
        width: 318px;
    }
    @media only screen and (min-width: (321px)) {
        text-align: center;
    }
    padding: 0px;
    display: table;
    margin: auto;
    background: white;
    height: calc(100dvh - 224px);
    max-width: 100vw;
    padding-top: 24px;

    @include breakpoint(tablet) {
        position: sticky;
        max-width: 800px;
        padding-top: 40px;
        padding-right: 16px;
        padding-left: 16px;
        height: 462px;
    }

}

.interests-title-txt {
    background-color: white;
    height: fit-content;
    padding: 16px;
    text-align: center;
    width: 100%;
    display: block;
    align-items: center;
    justify-content: center;
    div {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 13px;
      text-align: center;
      letter-spacing: 0.7px;
      color: $wim-green;
      font-weight: bold;
      padding-bottom: 16px;
      @include breakpoint(tablet) {
        display: inline;
        padding-right: 16px;
      }
    }
}

.question-container {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    background: white;
    padding: 8px;
    min-height: 450px;
  }
  
  .question-item {
    display: flex;
    align-items: center;
    margin: 8px;
  }
  
  .toggle-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 70px;
    height: 25px;
    border: 1px solid #979797;
    border-radius: 5px;
    margin-right: 8px;
  }
  
  .toggle-container input[type="radio"] {
    display: none;
  }
  
  .toggle-container label {
    font-family: 'Gotham 4r';
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #000000;
    cursor: pointer;
    margin: auto;
    text-align: center;
  }
  
  .toggle-container input[type="radio"]:checked + label:before {
    background-color: #000000;
  }

  .question-item-text {
    font-family: 'Gotham 4r';
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #000000;
    text-align: left;

  }
  
  .question-yes,
  .question-no,
  .question-yes-input-no-border,
  .question-yes-input-white-border {
    width: 50%;
    display: block;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    text-align: center; 
    font-family: 'Gotham Book';
    text-transform: uppercase;
  
    font-family: 'Gotham 5r';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 0.25px;
  }
  
  .question-yes {
    border-right: 1px solid #979797;
  }
  
  .question-yes-input[type="radio"]:checked + label, .question-yes-input-white-border {
    background: $wim-green; /* Updated background color for "Yes" when selected */
    border-radius: 3px;
    color: #FFFFFF;
    border-right: 1px solid white;
  }
  
  .question-yes-input[type="radio"]:checked + label,
  .question-no-input[type="radio"]:checked + label {
    border: 0px; /* Updated border to 0px when "Yes" or "No" is selected */
  }
  
  .question-yes-input[type="radio"]:not(:checked) + label,
  .question-no-input[type="radio"]:not(:checked) + label {
    background: transparent;
  }
  
  .question-no-input[type="radio"]:checked + label {
    background: #979797;
    border-radius: 3px;
    color: #FFFFFF;
  }
  
  .question-yes label,
  .question-no label {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  