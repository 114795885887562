.video-comp{
    position: relative;
    @include gotham(medium);

    .video-wrapper{
        position:relative;
        cursor: pointer;

    }

    img { 
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        display:block; 
    }

    .video-info{
        .video-title{
            font-size:16px;
            color:$wim-green;
            text-transform:uppercase;
            margin-top:13px;
            width:100%;
            text-align:center;
            letter-spacing:3px;
        }

        .video-date{
            color:$gray-disable;
            font-size:12px;
            width:100%;
            text-align:center;
            margin-top:9px;
        }
    }
}

