span.favorite {
    &.black {
        &:after {
            color: $wim-black;
        }
    }
    &.green {
        &:after {
            color: $wim-green;
        }
    }
    &.white {
        &:after {
            color: $white;
            text-shadow: 1px 1px 2px #000000;
        }
    }
    &.yellow {
        &:after {
            color: #F9E300;
        }
    }
}
