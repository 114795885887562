/*
 * status panel in the header
 */
.status-panel {
	position: absolute;
	right: 0px;
	top: 95px;
	width: 215px;
	text-align: right;
	margin-right: 20px;
	z-index: 110;
	//text-shadow: $textShadow;

	@include breakpoint(tablet) {
		margin-right: 50px;
	}

	@include breakpoint(tablet, max){
		.user-role-icons{
			display: none;
		}
	}

	&.none {
		display: none;
	}

	&.small {
		.weather {
			display: none;
		}
		.court-status {
			display: none;
		}
		.divider {
			display: none;
		}
	}

	.welcome {
		float: right;
		margin-bottom: 10px;

		@include gotham-book();
		font-size: 18px;
		letter-spacing: 1px;
		color: $white;

		@include breakpoint(tablet) {
			font-size: 22px;
		}

		.user-icons {
			display: flex;
			flex-wrap: wrap;
			.user-icon {
				flex-grow: 1;
				width: 33%;
			}
		}
	}
	.status-roles-wrapper {
		margin-right: -20px;
		display: flex;
		flex-wrap: wrap;
		width: 260px;
		justify-content: flex-end;
		row-gap: 15px;
		.white {
			width: 32%;
			letter-spacing: 0.5px;
		}
		.status-role-item {
			display: flex;
			align-items: center;
			height: 100%;
			justify-content: space-between;
			flex-direction: column;
			font-size: 12px;
			letter-spacing: 0.1px;
			margin-left: auto;
			.icon-role-white {
				width: 50px;
				height: 50px;
			}
			.label-white {
				font-size: 12px;
				letter-spacing: 0.5px;

				&:hover {
					color: white;
				}
			}
		}

		.label-white {
			color: $white;
			font-size: 12px;
		}
	}

	.weather {
		float: right;
		margin-bottom: 10px;

		.description {
			color: $white;

			@include breakpoint(tablet, max) {
				font-size: 16px;
				letter-spacing: 0.5px;
			}
		}

		img {
			margin-bottom: -7px;
			margin-right: 8px;
		}
	}

	.court-status {
		float: right;
		width: 250px;
		.desc {
			font-size: 12px;
			width: 100%;
			float: right;
			margin: 5px 0;

			@include breakpoint(tablet) {
				width: 150%;
				font-size: 14px;
			}

			a,
			a:hover,
			a:active,
			a:visited {
				color: $white;
				text-decoration: none;
				@include gotham(medium);
				// @include gotham-book();				
				font-size: 14px;
				letter-spacing: 0.5px;
				text-align: right;
			}
		}
	}

	.live-status {
		float: right;

		//status panel oevrrides for breakpoints
		&.large {
			@include breakpoint(tablet, max) {
				width: 215px;

				.title {
					font-size: 18px;
				}

				.symbol {
					font-size: 26px;
					width: 65px;

					.label {
						font-size: 12px;
					}
				}
			}
		}
	}

	.rolex-clock {
		float: right;
		display: none;

		@include breakpoint(tablet) {
			display: block;
			border-radius: 10px;
			background-color: rgba(0, 96, 57, .9);
			padding-bottom: 1px;
		}
	}

	.divider {
		float: right;
		height: 2px;
		width: 215px;
		background-color: rgba(255, 255, 255, 0.45);
		margin: 5px 0 5px 0;

		@include breakpoint(tablet) {
			margin: 15px 0 15px 0;
		}
	}

	&.hide {
		pointer-events: none;
		opacity: 0;
		transition: opacity 0.5s linear;
	}

	&.show {
		pointer-events: auto;
		opacity: 1;
		transition: opacity 0.5s linear;
	}
}

.tgc {
	.status-panel {
		height: calc(100vh - 300px);

		.welcome,
		.weather,
		.live-status,
		.divider {
			display: none;
		}

		.court-status {
			position: absolute;
			bottom: -180px;
			right: calc(100vw - 300px);
			width: 300px;
		}

		.rolex-clock {
			position: absolute;
			bottom: -190px;
			right: -10px;
		}

		@include breakpoint(tablet, max) {
			display: none;
		}
	}
}

.home-slider{
	height: 90px; 
	background: rgba(255, 255, 255, 0.2);
	border: 1px solid white;
	width: 250px;
	// @include breakpoint(mobile, max){
	// 	width: 100%;
	// }
	float: right;
	margin: 10px 0 25px 0;
	.home-media{
		height: 100%;
		.media-item{
			width: 100%;
			display: inline-block;			
		}
		.imgPromo{
			.promoInner{
				display: flex;
				justify-content: flex-start;
				padding: 7px;
				.promoText{
					padding: 0 10px;
					text-align: left;
					color: white;
					@include gotham(medium);
					// @include gotham-book();
					font-size: 12px;
					letter-spacing: 0.25px;
					line-height: 19px;
					a{
						color: white;
					}
					hr{
						border-top: 1px solid white;
						width: 17px;
						margin: 10px 0;
					}
				}
				img{
					width: 111px;
				}
				
			}
		}
	}

	.slick-arrow{
		font-family: "Icons" !important;
		&.slick-prev{
			left: 0px;
			height: 100%;
			background: rgba(255,255,255,.75);
			z-index: 1;
			&::before{
				content: "\e928";
				color: #A1A1A1;
				height: 100%;
			}
		}
		&.slick-next{
			right: 0px;
			height: 100%;
			background: rgba(255,255,255,.75);
			&::before{
				content: "\e929";
				color: #A1A1A1;
				height: 100%;
			}
		}
	}

	.slick-dots {
		bottom: -20px;

		li {
			width: 6px;
			height: 6px;
			border-radius: 50%;
			border: solid 1px $gray-dark;			
			background: $gray-dark;
			button {
				width: 100%;
				height: 100%;
				margin-top: -1px;

				&:before {
					content: '';
					width: 100%;
					height: 100%;
				}
			}
			&.slick-active {
				width: 8px;
				height: 8px;
				background: $white;

				button {
					margin-top: 0;
				}
			}
		}
	}
}

.page {
	&.expanded {
		.tgc {
			.status-panel {
				.court-status {
					@include breakpoint(desktopSidePanel, mid) {
						right: calc(100vw - (#{$sidebarWidth} + 300px));
					}

					@include breakpoint(largeDesktop) {
						right: calc(100vw - (#{$sidebarWidth} + 300px));
					}

					// sidepanel always open with centered max width content
					@include breakpoint(extraLargeDesktop) {
						right: calc(#{$maxWidth} - (#{$sidebarWidth} + 300px));
					}
				}
			}
		}
	}
}
