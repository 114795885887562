label{
    textarea{
        width:100%;
        border:1px solid $lines-gray;
        margin-top:5px;
        font-size:16px;
        @include helveticaneue();
        color: $wim-black;
        padding-left:10px;
    }
    textarea:hover {
        border: 1px solid $wim-text-purple;
        box-shadow: inset 0 0 0 1px $wim-text-purple;
    }
}