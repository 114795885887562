
.preferences-container {
	@include breakpoint(desktop, min) {
		padding-left: 100px;
		padding-right: 100px;
	}
	.generic-button {
		margin: unset !important;
	}
}

.consent-pref-float-left {
	float: left;
	width: 100%;
	@include breakpoint(desktop) {
		width: 50%;
	}
}

.consent-pref-float {
	float: left;
	width: 100%;
	@include breakpoint(desktop) {
		float: right;
		width: 50%;
	}
}

.mywim_settings {
	margin: 15px 0 0 0;
	padding-top: 30px !important;

	.error {
		position: absolute;
		/* float: left; */
		top: 40px;
		color: #F60000;
	}

	.submit-section {
		clear: left;
		position: relative;
	}

	.title {
		font-size: 18px;
		color: $wim-green;
		margin: 0 0 15px 0;
		text-transform: uppercase;
		font-weight: bold;
	}

	.title-description {
		font-size: 12px;
		margin-bottom: 20px;
    	margin-top: -10px;
	}

	.inline-wrapper {
		float: left;
	}

	.consent-display {
		// @include checkbox();
		@include accessibleCheckbox();
		position: relative;
		margin: 0 0 15px 0;
		text-transform: none;
		//padding-bottom: 15px;

		&.indent {
			padding-left: 20px;
		}

		.checkbox-privacy{
			flex: 0 0 auto;
		}

		.consent-label-wrapper {
			flex: 0 0 auto;
			margin-left: 0px;
			width: 100%;
			display: block;
		}

		.consent-label {
			display: block;
			width: 100%;
			position: relative;
			padding-left: 0px;
			margin-left: -25px;
			text-transform: none;

			&:before, &:after {
				display: none;
			}

			.consent-label-text {
				font-size: 18px;

				position: absolute;
				left: 30px;
				top: 0px;
				white-space: nowrap;
				display: block;

				a {
					color: $wim-green;
				}
			}

			.consent-dates {
				display: inline-block;
				position: relative;
				margin: 22px 0 0 6px;
				width: calc(100% - 36px);
				white-space: nowrap;
			}

			.consent-desc {
				display: inline-block;
				position: relative;
				margin: 22px 0 0 6px;
				line-height: 17px;
			}

		}

		&.compact {
			//margin: -10px 0 0 0;

			.consent-label-text {
				font-size: 14px;
				white-space: nowrap;
				position: relative;
				margin-left: -28px;
				min-height: 18px;
				padding-top: 1px;

				.inner {
					position: absolute;
    				top: -14px;
				}

				&.wrap {
					white-space: normal;
				}
			}

			&.inline {
				float: left;
				width: 100px;
			}
		}
	}
	
	&.consent {
		@include breakpoint(desktop) {
			padding-right: 10px;
		}
	}

	&.contact {
		.section {
			padding-top: 15px;
		}

		.section:nth-child(1) {
			padding-top: 0px;
			margin-top: 0px;
		}

		@include breakpoint(desktop) {
			border-left: 1px solid $gray-light;
			padding-left: 30px;

			input[type=submit] {
				@include clearfix;
				float: left;
				clear: both;
				margin-top: 10px;
			}
		}
	}

	&.settings {
		@include breakpoint(desktop) {
			padding-right: 10px;
		}
	}

	.settings-display { 
		@include clearfix;
		// @include checkbox(true);
		@include accessibleCheckbox(true);
		position: relative;
		margin: 0 0 35px 0;

		.settings-label-wrapper {
			flex: 0 0 auto;
			margin-left: 0px;
			width: 100%;
			display: block;
		}

		.settings-label {
			display: block;
			width: 100%;
			position: relative;
			padding-left: 0px;
			margin-left: -22px;
			text-transform: none;

			&:before, &:after {
				display: none;
			}

			.settings-label-text {
				font-size: 18px;

				position: absolute;
				left: 25px;
				top: 0px;
				white-space: nowrap;
				display: block;
			}


		}

		.subtitle {
			font-size: 18px;
			margin-bottom: 15px;
		}

		.radio1 {
			float: left;
			margin-top: -10px;

			label::before {
				top: 0px;
			}
		}
		.radio2 {
			float: left;
			margin-top: -10px;
			margin-left: 150px;

			label::before {
				top: 0px;
			}
		}

	}
}

.mywim-input-submit {
	padding-left: 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    width: 100%;
    height: 34px;
    border-radius: 1.79px;
    border-color: #00703C;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.4);
    color: #fff;
    background-color: #00703C;
    align-content: center;
    text-align: center;
	font-size: 16px;
	max-width: 200px;
	margin-bottom: 15px;
}