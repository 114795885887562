.form-errors{
    margin:20px 0;
    @include gotham(normal);
    ul{
        li{
            color:$red;
            margin:0 0 10px 10px;
            display:none;
        }
    }
}