
.content-main {
    .column-layout {
        .jobs-item {
            font-size: 18px;
        }
        
        .jobs-text {
            @include gotham();
            font-size: 18px;
        }
    }
}