.media-form {
    display: relative;

    .required {
        &::after {
            content: "*";
            @include gotham();
            color: $red;
        }
    }

    .progress-indicator {
        display: flex;
        justify-content: center;
        
        .progress-item {
            text-align: center;
            text-transform: uppercase;
            color: $gray_9b;
            letter-spacing: 0.8px;
            position: relative;
            width: calc(16.6% - 7px * 2);

            .circle {
                position: relative;
                width: 52px;
                height: 52px;
                background-color: $gray_9b;
                margin: 0 auto 16px;
                border-radius: 50%;
                z-index: 2;
            }
            .line {
                width: 100%;
                height: 1px;
                position: absolute;
                top: 26px;
                z-index: 1;

                &::before {
                    content: "";
                    height: 1px;
                    background-color: $gray_9b;
                    position: absolute;
                    top: 50%;
                    right: 50%;
                    width: 100%;
                }
            }
            &.active {
                color: $wim-green;

                .circle {
                    background-color: $wim-green;
                }
                .line {
                    &::before {
                        background-color: $wim-green;
                    }
                }
            }
            &.organisation {
                .line {
                    &::before{
                        background-color: transparent;
                    }
                }
            }            
        }        
    }
    .form-component {
        &.visible {
            visibility: visible;
        }
        &.hidden {
            visibility: hidden;
            display: none;
            height: 0;
        }
    }
    .title-header {
        font-size: 18px;
        text-transform: uppercase;
        color: $wim-green;
    }
    .page-title {
        text-align: center;
        border-bottom: 1px solid $gray_bb;
        padding-bottom: 10px;
        font-weight: bold;
    }
    .media-form-header {
        @include gotham(medium);
        font-size: 18px;
        color: #333333;
        letter-spacing: 0.9px;
    }
    .media-form-box {
        background: $gray-lighter;
        min-height: 286px;
        padding: 20px;
        margin-bottom: 25px;
        position: relative;

        &.confirmation {
            padding-bottom: 55px;
        }        
        .title-header {
            font-size: 16px;
            color: #54008C;
            font-weight: bold;
            letter-spacing: 0;
            text-transform: uppercase;
            padding-bottom: 15px;
            border-bottom: 1px solid $gray_bb;
        }
        .media-form-box-row {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid $gray_bb;
            padding: 15px 0;
            width: 100%;
            
            .name-col, .org-col {
                font-size: 16px;
                color: $wim-black;
                font-weight: bold;
                letter-spacing: 0;
                text-transform: uppercase;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-right: 5px;
                min-height: 1px;
            }
            .name-col { width: 41%; }
            .org-col { 
                width: 33%; 
                font-weight: normal;
            }
            .edit-col {
                font-size: 16px;
                color: $wim-green;
                letter-spacing: 1.14px;
                cursor: pointer;
                width: 26%;
            }
        }
        .add-button {
            position: absolute;
            bottom: 10px;
            left: 20px;
            height: 45px;
            padding-top: 10px;
        }
    }
    .confirmation-note {
        @include gotham-book;
        font-size: 16px;
        color: $wim-green;
        letter-spacing: 0.8px;
        text-align: center;
        font-style: italic;
        margin: -10px 0 30px 0;
    }
    .small-gray-text {
        font-size: 14px;
        color: $gray_9b;
        letter-spacing: 0.7px;
    }
    textarea {
        min-height: 80px;
        padding: 10px;

        &.midheight {
            min-height: 130px;
        }
    }
    .checkbox-option label {
        font-size: 16px;
        color: $wim-black !important;
        letter-spacing: 0 !important;
        text-transform: unset;
    }
    .radioBtn {
        // display: inline-flex;
        // position: relative;
        // padding-right: 5px;
        // .checkmark {
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     height: 20px;
        //     width: 20px;
        //     background-color: #fff;
        //     border-radius: 50%;
        //     border: 1px solid #979797;
        //     &:after {
        //         top: 3px;
        //         left: 3px;
        //         width: 12px;
        //         height: 12px;
        //         border-radius: 50%;
        //         background: green;
        //         content: "";
        //         position: absolute;
        //         display:none;
        //     }
        // }
        // input {
        //     position: absolute;
        //     opacity: 0;
        //     cursor: pointer;
        //     z-index: 1;
        // }
        // input:checked ~ .checkmark:after {
        //     display: block;
        // }
        label {
            font-size: 16px;
            color: $wim-black !important;
            letter-spacing: 0 !important;
            text-transform: unset;
        }
    }
    // .media-btn {
    //     width: 150px;
    //    &.long { width: 200px; }
    // }
    .thank-you {
        text-align: center;
        margin-top: 30px;
    }
    .first {
        margin-right: 22px;
    }
    .right-align {
        float: right !important;

        > div {
            float: right !important;
        }
    }
    .smaller-header {
        font-size: 16px;
        color: $wim-green;
        letter-spacing: 0;
    }
    .smaller {
        @include gotham-book;
        font-size: 14px;
        letter-spacing: 0;
    }
    ol, ul {
        padding-left: 17px;
        
        li {
            margin-bottom: 10px;
            
            a { word-break: break-all; }
        }
    }
    .no-padding {
        &.top {
            padding-top: 0px !important;
        }
        &.bottom {
            padding-bottom: 0px !important;
        }
        &.left {
            padding-left: 0px !important;
        }
        &.right {
            padding-right: 0px !important;
        }
    }
    .no-margin {
        &.top {
            margin-top: 0px !important;
        }
        &.bottom {
            margin-bottom: 0px !important;
        }
        &.left {
            margin-left: 0px !important;
        }
        &.right {
            margin-right: 0px !important;
        }
    }
    .media-form-content {
        h5{
            font-size:10px;
        }
        .error {
            display: block;
            font-size: 11px;
            color: $red;
		}
		
		.fullwidth {
			.checkbox-option {
				width: 100%;
				height: 100%;
				padding-top: 5px;
	
				label {
					// width: 350px !important;
					width: calc(100% - 33px) !important;
					position: relative;
				}
			}
		}
    }
}

.media-wrapper {
    .hidden {
        visibility: hidden;
    }
    .fullwidth {
        .checkbox-option {
            width: 100%;
            height: 100%;
        }
    }
    .accreditation-form-title{
        text-align: center;
		font-weight: bold;
        font-size: 18px;
        text-transform: uppercase;
        color: #00703C;
        padding: 10px 0;
    }
}
