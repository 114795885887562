.next-prev-back-bar{
    @include gotham(normal);
    color:$wim-green;
    width:100%;
    border-top:3px solid $gray-light;
    padding-top:20px;
    display:flex;
    font-size:16px;

    .previous,.back,.next{
        flex:1 1 0;
        position:relative;
        line-height:1em;
        padding-bottom:30px;
    }

    .previous,.next{
        &-header{
            @include gotham(bold);
            text-transform:uppercase;
        }

        &.hide {
            visibility: hidden;
        }
    }

    .previous{
        text-align:left;
        padding-left:16px;

        .left-arrow{
            position:absolute;
            top:0;
            left:0;
            transform:rotate(180deg);
            font-size:18px;
        }
    }

    .back{
        text-align:center;
        text-transform:uppercase;
        @include gotham(bold);

        span {
            cursor: pointer;
        }
    }

    .next{
        text-align:right;
        padding-right:16px;

        .right-arrow{
            position:absolute;
            top:-2px;
            right:0;
            font-size:18px;
        }
    }
}