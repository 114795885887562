@mixin aspect-ratio($width, $height) {
	position: relative;
	&:before {
		display: block;
		content: '';
		width: 100%;
		padding-top: calc(($height / $width) * 100%);
	}
	> .player-image {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

/** player-general-info styles **/
.player-gen-info {
	display: flex;
	flex-flow: wrap;
	border-bottom: 1px solid $gray-bb;

	.general-info-item {
		position: relative;
		width: 50%;
		margin: 0px;

		.player-img-container {
			position: absolute;
			width: 100%;
			@include aspect-ratio(1, 1);
			@include breakpoint(tablet, max) {
				margin-bottom: 25px;
			}
			.player-image {
				border: 10px solid transparent;
			}
		}

		.mobile {
			display: none;
		}
		.large {
			display: block;
		}

		.favorite {
			position: absolute;
			top: -10px;
			cursor: pointer;
			z-index: 2;
			@include breakpoint(tablet, max) {
				left: 10px;
			}

			&:after {
				position: absolute;
				left: 0;
				content: '\2606';
				font-size: 33px;
				color: $wim-green;
			}

			&.favorited {
				&:after {
					content: '\2605';
					font-size: 33px;
					color: #f9e300;
				}
			}
		}

		@include breakpoint(tablet, max) {
			padding: 0px 20px;
		}
	}

	.player-flag {
		float: left;
		margin-right: 10px;
		margin-left: 10px;
	}

	.player-name {
		color: $wim-green;
		font-size: 18px;
		margin-top: 4px;
	}

	.general-info {
		margin-top: 20px;
		font-size: 14px;
		margin-left: 10px;

		.general-info-line {
			margin-bottom: 10px;
			display: inline-block;
			width: 100%;

			.general-info-field {
				color: $wim-black;
				width: 90px;
				display: inline-block;
				white-space: nowrap;
			}

			.general-info-value {
				width: calc(100% - 90px);
				float: right;
				display: inline-block;
				color: $wim-purple;
				@include gotham(medium);
			}
		}

		.ranking {
			.field {
				color: $wim-black;
				width: 33%;
				display: inline-block;
				text-transform: uppercase;
				white-space: nowrap;

				position: absolute;
				margin-top: 11px;
				margin-left: 12px;
				font-weight: bold;
			}

			.value {
				display: inline-block;
				color: $wim-purple;
				@include gotham(bold);
				font-size: 32px;

				width: 80px;
				text-align: right;
			}
		}
	}

	@media only screen and (max-width: $column-break-1) {
		// flex-flow: column;
		border-bottom: none;

		.general-info-item {
			margin-bottom: 5px;
			border-bottom: 1px solid $gray-bb;
			width: 100%;

			span.favorite {
				&::after {
					top: -10px;
					left: -15px;
				}
			}

			.mobile {
				display: block;
				position: absolute;
				right: 0px;
				float: right;
				top: 50%;
				width: 50%;
				transform: translateY(-70%);

				.ranking {
					.field {
						color: $wim-black;
						width: 33%;
						display: inline-block;
						text-transform: uppercase;
						text-align: left;

						position: relative;
						margin-top: 11px;
						margin-left: 12px;
						font-weight: bold;
					}

					.value {
						display: inline-block;
						color: $wim-purple;
						@include gotham(bold);
						font-size: 32px;

						width: 80px;
						text-align: right;
					}
				}
			}
			.large {
				display: none;
			}

			.player-img-container {
				float: left;
				width: 50%;
				//padding-left: 20px;

				.player-image {
					//width: 150px;
					//height: 150px;
					//padding: 10px;
				}
			}
		}
	}
}
