.page {
    .wrapper {
        &.fullwindow {
            .content-main {
                visibility: hidden;
            }
        }
    }
    &.expanded {
        .pdfreader {
            @include breakpoint(desktopSidePanel, mid){
                .pdf-reader-container {
                    width: calc(100% - #{$sidebarWidth});  
                }
            }
        
            @include breakpoint(largeDesktop){
                .pdf-reader-container {
                    width: calc(100% - #{$sidebarWidth});  
                }
            }
        
            // sidepanel always open with centered max width content
            @include breakpoint(extraLargeDesktop){
                .pdf-reader-container {
                    margin-left: calc((100% - #{$maxWidth}) / 2);
                    width: calc(#{$maxWidth} - #{$sidebarWidth});  
                }            
            }
        }
    }

    .pdfreader {
    
        a {
            cursor: pointer;
        }
        .pdf-reader-container {
            visibility: visible;
            width: 100%;
            position: fixed;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            background: black;
            i.wim-icon-close {
                position: absolute;
                right: 20px;
                top: 15px;
                z-index: 1;
                color: white;
                font-size: 25px;
                cursor: pointer;
            }
            iframe {
                width: 100%;
                // height: 100vh;
                height: 100%;
            }
        }
    }
}