.centrecourt-wrapper {

    .centrecourt-offair, .centrecourt-block {
        .offair-text {
            .line {
                color: $lines-gray;
                width: 50%;
                display: block;
            }
        }
    }

   

    &.main {
        top: 160px;
        width: 100%;

        .centrecourt-offair, .centrecourt-onair, .centrecourt-block {
            position: relative;
            width: 100%;
            height: 0px;
            padding-bottom: 56.25%;
            background-image: url("/assets/images/video/WimChanStub.jpg");
            background-size: cover;
            color: $white;

            @include breakpoint(desktopSidePanel, mid){
                //width: calc((100vw - #{$sidebarWidth}) * .90);
                //left: calc((100vw - #{$sidebarWidth}) * .05);
            }

            @include breakpoint(largeDesktop){
                //width: calc((100vw - #{$sidebarWidth}) * .90);
                //left: calc((100vw - #{$sidebarWidth}) * .05);
            }

            @include breakpoint(extraLargeDesktop){
                //width: calc((#{$maxWidth} - #{$sidebarWidth}) * .90);
                //left: calc((#{$maxWidth} - #{$sidebarWidth}) * .05);
            }

            img {
                height: auto;
                width: 100%;
                position: absolute;
            }
            
            .offair-text{
                margin: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                width: 100%;
                position: absolute;
                background-color: rgba(0, 0, 0, .5);

                .offair-title {
                    @include gotham(medium);
                    text-transform: uppercase;
                    text-align: center;
                    width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 20px;
					letter-spacing: 4px;
					
					@include breakpoint(tablet){
						font-size: 30px;
					}
                }

                .offair-times {
                    @include gotham(medium);
                    font-size: 18px;
                    text-transform: uppercase;
                    letter-spacing: 1.5px;
                    text-align: center;
                    width: 80%;
                    margin: 8px auto;
                    display: block;
                }
            }
        }

        
    }

    &.none {
        .centrecourt-offair {   
            .offair-text{
                display: none;
            }
        }
    }

    i {
        color: $white;
        font-size: 56px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
		background-color: rgba(0, 0, 0, 0.3);
		border-radius: 50px;
    }
}