.category-heading{
	width: 100%;
	padding: 0 5px;
}

.centerMarkTitle {
	color: $wim-purple;
	font-size: 18px; 
	font-family: 'Gotham 5r', Helvetica, Arial, sans-serif; 
	text-transform: uppercase; 
	text-align: center;

	span {
		display: block;
	}
}

.content-divider{
	height: 25px;
	background: #F5F5F5;
	margin-bottom: 20px;
}