.homepage-results{
    height: 300px;
    padding: 0;

    img{
        position:  absolute;
        top:  0;
        left:  50%;
        transform:  translateX(-50%);
        width: auto;
        max-height:  unset;
        max-width:  unset;
        height:  100%;
        min-width: 100%;
    }

    .title{
        color:  $white;
        top:  47px;
        position:  absolute;
        font-size:  30px;
        letter-spacing:  2.5px;
        text-transform:  uppercase;
        width: 100%;
        text-align: center;

        @include breakpoint(tablet, max){
            font-size: 20px;
            letter-spacing: 1px;
        }
    }

    .time{
        font-size:  12px;
        color:  $white;
        top:  20px;
        position:  absolute;
        text-transform:  uppercase;
        letter-spacing:  1.5px;
        width: 100%;
        text-align: center;

        @include breakpoint(tablet,max){
            font-size: 10px;
        }
    }

    .match-box-wrapper {
        display: flex;
        height: 190px;
        width: 90%;
        position: absolute;
		left: 0;
		justify-content: center;
		//max-width: 1200px;
		transform: translateX(-50%);
		left: 50%;
		bottom: 0px;
    	margin-bottom: 15px;
		
		.match-box-container {
			flex: 0 0 100%;
			display: inline;
			margin: 0 11px;
			max-width: 450px;

			&:nth-child(2){
                display: none;
			}
			
			&:nth-child(3){
                display: none;
			}

			@include breakpoint(tablet){
				flex: 0 0 50%;

				&:nth-child(2){
					display: inline;
				}	
			}

			@include breakpoint(largeDesktop){
				width: 90%;
				flex: 0 0 33.3%;

				&:nth-child(3){
					display: inline;
				}	
			}
		}

        .match-box{
			width: 100%;
            display: block;
			background-color: transparent;
            
            .match-status{
                background-color: $white;

                .team-info{
                    .team-name{
                        .flag{
                            img{
                                transform: none;
                                position: static;
                                min-height: unset;
                            }
                        }
                    }
                }
            }

            .match-location{
                color: $white;
			}
		}        

        
    }
}

.page.expanded .wrapper {
	.homepage-results{
		.match-box-wrapper {
			.match-box {
				@media only screen and (min-width: 1364px){
					
				}
			}
		}
	}
}
