.PowerIndexChart {
    position: relative;
    display: flex;

    .probability {
        white-space: nowrap;
        margin-top: 30px;
        width: 35px;
        @include gotham(medium);

        .player-name {
            font-size: px2rem(12);
            display: block;
        }

        &.team1 {
            color: $team1_color;
            margin-right: 30px;

            @include breakpoint(largeDesktop, max){
                margin-right: 10px;
            }
        }

        &.team2 {
            color: $team2_color;
            margin-left: 30px;
            
            @include breakpoint(largeDesktop, max){
                margin-left: 10px;
            }
        }
    }

    .team2-chart-style {
        fill: $team2_color;
        stroke: $team2_color;
        stroke-width: 1px;
    }

    .team1-chart-style {
        fill: $team1_color;
        stroke: $team1_color;
        stroke-width: 1px;
    }

    // fallback 'vs' display
    .team0-chart-style {
        fill: $team2_color;
    }

    hr.vs{
        height: 1px;
        width: 85px;
        @include breakpoint(tablet){
            width: 110px;
        }
        background: transparent;
        border: 0;
        border-top: 2px solid #4C2178;
        position: absolute;
        top: 24px;
        padding: 0;
        z-index: 0;
        &.team1{
            right: 70px;
        }
        &.team2{
            left: 70px;
        }
    }

    .ct-chart, .donutChart {
        width: 70px;
        height: 70px;
        position: relative;

        &:before{
            content: 'VS';
            color: $team1_color;
            display: inline-block;
            font-size: px2rem(12);
            line-height: 24px;
            position: absolute;
            top: 25px;
            left: 27px;
        }
    }

    .winnerCheck {
        position: absolute;
        bottom: -3px;

        &.team2 {
            right: -16px;

            .wim-icon-check:before {
                color: $team2_color;
            }
        }

        &.team1 {
            left: -20px;

            .wim-icon-check:before {
                color: $team1_color;
            }
        }
    }
}

/** override for Match Insight details page & Embedded article MatchInsightsCard */
#match-insights, .news {
    .PowerIndexChart {
        .probability {
            @include breakpoint(tablet) {
                margin-top: 30px;
            }

            @include breakpoint(desktop) {
                margin-top: 40px;
            }

            &.team1 {
                @include breakpoint(tablet, max){
                    margin-right: 5px;
                }
            }
    
            &.team2 {
                @include breakpoint(tablet, max){
                    margin-left: 5px;
                }
            }

            &.team1, &.team2 {
                @include breakpoint(tablet) {
                    font-size: px2rem(26);
                }

                @include breakpoint(desktopSidePanel) {
                    font-size: px2rem(36);
                }
            }
        }

        .ct-chart {
            @include breakpoint(tablet){
                width: 100px;
                height: 100px;

                &:before{
                    font-size: px2rem(20);
                    top: 39px;
                    left: 33px;
                }
            }

            @include breakpoint(desktopSidePanel){
                width: 120px;
                height: 120px;

                &:before{
                    font-size: px2rem(24);
                    top: 49px;
                    left: 43px;
                }
            }
        }
        
        .winnerCheck {
            @include breakpoint(desktop) {
                font-size: px2rem(20);
            }

            &.team1 {
                left: -40px;

                @include breakpoint(tablet) {
                    left: -40px;
                }

                @include breakpoint(desktop) {
                    left: -70px;
                    bottom: -60px;
                }
            }
            
            &.team2 {
                right: -40px;

                @include breakpoint(tablet) {
                    right: -50px;
                }

                @include breakpoint(desktop) {
                    right: -70px;
                    bottom: -60px;
                }
            }
        } // .winnerCheck
    } // .powerIndexChart
}

/** news article embedded MatchInsightsCard override */
.news {
    .PowerIndexChart {
        width: 100%;
        justify-content: space-between;
    }
}

/** news article embedded MatchInsightsCard AND side panel opened override */
.expanded {
    .news {
        .PowerIndexChart {
            .probability { 
                &.team1, &.team2 {
                    @include breakpoint(extraLargeDesktop, max) {
                        font-size: px2rem(26);
                    }
                }
            }

        .ct-chart {
            @include breakpoint(extraLargeDesktop, max){
                width: 100px;
                height: 100px;

                &:before{
                    font-size: px2rem(20);
                    top: 39px;
                    left: 33px;
                }
            }
        }
        }

    }
}

/** slamtracker override */
.slamtracker {
    .PowerIndexChart {
        margin-top: 5px;
        align-items: center;

        .probability {
            margin-top: 0;
            text-align: center;

            @include breakpoint(desktop) {
                &.team1 {
                    margin-right: 10px;
                }
                
                &.team2 {
                    margin-left: 10px;
                }
            }
        }

        .ct-chart, .donutChart {
            &:before{
                // top: 18px;
                // left: 21px;
            }
        }
    }
}

/** insights index override */
.insights-cards-container, .insights-style {
    .PowerIndexChart {
        .probability {
            .slamtracker-only {
                .player-name {
                    display: none;
                }
            }
            .probability-number {
                @include gotham(medium);
            }
        }
    }
}

/** upcoming override */
.upcoming {
    .doubles {
        .PowerIndexChart {
            hr.vs {
                @include breakpoint(largeDesktop, max){
                    width: 80px;
                }
            }
        }
    }
}