.draws-nav{
    position:relative;
    width:100%;
    height:144px;

    @include breakpoint (tablet,max){
        height:75px;
    }

    &-column-titles{
        height:24px;
        font-size:12px;
        width:calc(100% - 90px);
        text-transform:uppercase;
        display:flex;
        margin-left:45px;
        text-align:center;
		justify-content: center;

        @include breakpoint(tablet,max){
            font-size:10px;
            height:17px;
            width:100%;
            margin:0;
        }

        div{
            flex:1 1 calc(100% / 7);
			flex-basis: 100%;
            color:rgba($white,.65);
            max-width:calc(100% / 7);
            align-self: center;

            &.clicked{
                color:rgba($white,1);
            }
        }

        .title-buffer{
            max-width:unset;
            flex:1 1 0;
			flex-basis: auto;
        }
    }

}
