.seat-map{
    padding:10px;
    .seat-view-map{
        svg{
            .st1{
                fill:$tennis-court-green;
            }

            .st2{
                fill:$gray-light;
            }

            .st3{
                fill:$wim-purple;
            }

            .st4{
                fill:none;
                stroke:$white;;
                stroke-width:2;
                stroke-miterlimit:10;
            }

            .st5{
                fill:none;
                stroke:$wim-green;
                stroke-linecap:round;
                stroke-miterlimit:10;
            }

            .st6{
                fill:$wim-green;
                stroke:$wim-green;
                stroke-linecap:round;
                stroke-linejoin:round;stroke-miterlimit:10;
            }

            .st7{
                font-family:'GothamBold';
            }

            .st8{
                font-size:8px;
            }

            .st9{
                fill:#FFFFFF;
            }

            .sections{
                g{
                    cursor:pointer;
                }
                g:hover{
                    polygon,rect,path{
                        fill:$wim-purple;
                    }
                    text,tspan{
                        fill:$white;
                    }
                }
            }
        }
    }
}