.contact-header{
    width:60%;
    text-align:center;
    margin:0 auto;
    @include gotham(normal);
    font-size:18px;
    margin-bottom: 10px;
    &:nth-child(4) {
        margin-bottom: unset;
    }
    @include breakpoint(desktop,max){
        width:100%;
    }
}