/** transparent-select-menu styles **/
.competitor-select-menu {
    width:200px;
    position: relative;
    text-align:center;
    
    .select-menu {
        width:200px;
        position:relative;
        display:inline-block;

        button{
            position: relative;
            text-decoration: none;
            padding: 0 15px 0 8px;
            cursor: pointer;
            height:24px;
            font-size:12px;
            color:$white;
            @include gotham(medium);

            &.title {
                border:solid 1px $white;
                background:rgba($white,0.33);
                text-transform:uppercase;
                width:200px;
                display:inline-block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow:ellipsis;
                line-height: 24px;
            }
            &:focus {
                outline: none;
            }
        }

        .select-menu-dropdown {
            position:absolute;
            width:100%;
            top:24px;
            right:0px;
            height:315px;
            font-size: 15px;
            border:1px solid $gray_bb;
            z-index:1;
            background:$white;
            overflow:auto;
            transform:unset;
            padding-left:20px;
            //display:none;

            button {
                color:$wim-black;
                text-transform:none;
                display:block;
                border:none;

                a{
                    //height:40px;
                    color:$wim-black;
                    //float:left;
                    //padding: 20px 0 0 15px;
                    text-align: left;
                    width: 100%;
                    //display:block;
                }

                i {
                    color:$wim-green;
                    font-size:11px;
                    visibility:hidden;
                }

                &.clicked{
                    i{
                        visibility:visible;
                        left: -12px;
                        top: 7px;
                    }
                }
            }
            .option {
                margin:0px;
            }
        }
    }
}