.homepage-upset{
    height: 300px;
    padding:0;
    
    .homepage-element-info{
        top:50%;
        transform:translate(-50%,-50%);
        left:50%;
        width:80%;
        min-width:768px;
        text-align:center;

        .upset-tag{
            text-transform:uppercase;
            background:$wim-green;
            width:60px;
            height:15px;
            color:$white;
            text-align:center;
            font-size:10px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            margin-bottom:5px;
            letter-spacing:2px;
        }

        img{
            display:none;
        }

        i{
            font-size:47px;
            color:$wim-green;
            margin-bottom:10px;
        }
        
        .time{
            color:$wim-text-purple
        }

        .title{
            color:$select-menu-text;
        }

        .text{
            color:$select-menu-text;
            width:75%;
            min-width:600px;
            transform: translateX(-50%);
            position: relative;
            left: 50%;
        }
    }

    .wim-icon-pin{
        color:$wim-green;
    }

    @include breakpoint(tablet,max){
        height:auto;

        .homepage-element-info{
            min-width:0;
        }

        img{
            display:none;
        }
    }
}