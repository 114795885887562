/** Collapsible component styles **/
.Collapsible{
    .Collapsible__trigger{
        color: $wim-green;
        cursor: pointer;
        &::before{
            content: "▾";
            padding: 10px;
            display: inline-block;
            @include rotate(-90);
        }
        &.is-open{
            &::before{
                @include rotate(0);
            }
        }
    }
    .Collapsible__contentInner{
        padding-left: 7px;
    	padding-right: 7px;
    }
}

.my-wimbledon-page, .homepage-favorites {
	.Collapsible {
		border-top: 1px solid #D2D2D2;
		padding: 15px 0;

		.Collapsible__trigger {
			font-size: 16px;
			// font-weight: 700;
			text-transform: uppercase;
			width: 100%;
			margin: auto;
			margin-left: 0;
			display: inline-block;
			white-space: nowrap;
			// text-align: center;
			position: relative;
			padding-left: 35px;
			@include gotham(medium);
			color: $wim-text-purple;
			letter-spacing: .5px;

			&.is-closed {
				&::before {
					height: 1px;
					width: 22px;
					// left: 7rem;
					content: "";
					background-color: #006633;
					position: absolute;
					top: 9px;
					left: 0;
					// bottom: 0;
					margin: auto;
					padding: 0;
					@include rotate(0);
				}
				&::after {
					height: 22px;
					width: 1px;
					// left: 7rem;
					content: "";
					background-color: #006633;
					position: absolute;
					top: -1px;
					left: 11px;
					// bottom: 0;
					margin: auto;
					padding: 0;
				}
			}
			&.is-open {
				&::before {
					height: 1px;
					width: 22px;
					// left: 7rem;
					content: "";
					background-color: #006633;
					position: absolute;
					top: 50%;
					left: 0;
					// bottom: 0;
					margin: auto;
					padding: 0;
					@include rotate(0);
				}
			}
			
			@include breakpoint(tablet) {
				width: 215px;
				display: flex;
				text-align: left;
			}
			@include breakpoint(desktop) {
				margin-left: calc(25% + #{$item-h-margin});
			}
		}
		.Collapsible__contentInner {
			margin-top: 15px;
		}
	}
}

.homepage-favorites {
    .Collapsible {
        margin: 0px 40px;
        border-top: none;
		padding-top: 0px;
		
		.Collapsible__trigger {
			margin-left: 0;
		}
    }
}