.key-stat{
    @include gotham(normal);
    @mixin one-col-key-stat{
        
        .big-stat{
            text-align:center;
        }

        .stat-text{
            padding-left:5px;
        }
    }

    &.one-col{
        @include one-col-key-stat;
    }

    &.two-col,&.three-col,&.four-col{
        @include breakpoint(tablet){
            display:flex;

            .big-stat{
                width:180px;
                flex:0 0 auto;
                align-self:center;
            }

            .stat-text{
                padding-left:15px;
                display:flex;
                align-self:center;
            }
        }

        @include breakpoint(tablet,max){
            @include one-col-key-stat;
        }
    }

    color:$wim-green;
    
    .big-stat{
        font-size:88px;
        line-height:70px;
    }

    .stat-text{
        text-transform:uppercase;
        line-height: 24px;
        letter-spacing: 4px;
        font-size: 16px;
    }
}