/** table styles **/
.datatable { 
	width: 100%; 
	height: auto; 
    display: table;
    table-layout: fixed;
    border: solid 1px $wim-green;
    background-color:$white;
    font-size: 12px;
    @include breakpoint(tablet) {
        font-size: 13px;
    }
    @include breakpoint(desktop) {
        font-size: 14px;
    }
    @include gotham(medium);
    
    .title {
        background-color: $wim-green; 
        display:table-caption;
        color:$white;
        text-align: center;
        text-transform:uppercase;
        padding:7px 0 5px;
    }
    .headers {
        display: table-row; 
        width: 100%; 
        color: $wim-green; 
        font-weight: bold; 
        font-weight: normal;
        text-transform:capitalize;
        text-align: center;
        //height:58px;
        .cell {
            vertical-align: middle;
            padding:14px 5px;
        }

        &.mobileHide {
            display: none;
            @include breakpoint(desktop) {
                display: table-row;
            }
        }

        &.mobileShow {
            @include breakpoint(desktop) {
                display: none;
            }
        }
    }
    .row { 
        display: table-row; 
    }
    .cell {
        display:table-cell;
        text-align: center;
        padding:5px;
    }
}