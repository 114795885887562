.contact-box{
    border: 2px solid $wim-green;
    color:$wim-black;
    padding:10px;
    @include gotham(normal);
    height:100%;

    &-heading{
        margin: -10px -10px 10px -10px;
        padding: 6px;
        text-transform: uppercase;
        text-align: center;
        background: #00703C;
        color: white;
    }

    .mailIcon, .infoIcon{
        float:right;
        padding-left: 20px;
        padding-right: 5px;
    }

    @include breakpoint(tablet, max){
        .mailIcon, .infoIcon{
            display: none;
        }
    }

    ul{
        padding-left:10px;
        
        li{
            margin:0 0 25px 10px;

            a{
                color:$wim-green;
                text-decoration:none;
            }
        }
    }
}