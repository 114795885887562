.column-layout {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    padding: 0 $page-margin-small 0 $page-margin-small; //height: 300px;
    // overflow: scroll;

	//full screen width column size
	.full-col {
		position: relative;
		left: calc(-1 * #{$item-h-margin});
		width: 100vw;
	}

    .one-col,
    .two-col,
    .three-col,
    .four-col {
        width: calc(99% - #{$item-h-margin} * 2);
        flex: 0 0 auto; //margin: 7px 5px 7px 5px;
		margin: $item-v-margin $item-h-margin $item-v-margin $item-h-margin;
		
		&.content-tab {
			margin: 0;
		}

		&.margin, &.margin-col {
			margin-left: $item-v-margin;
		}
    }

	// 768 and larger
    @include breakpoint(tablet, min){
        padding: 0 $page-margin-med 0 $page-margin-med;
        .one-col {
            width: calc(50% - #{$item-h-margin} * 2);
        }
        .two-col,
        .three-col,
        .four-col {
            width: calc(100% - #{$item-h-margin} * 2);
        }

        .one-col,
        .two-col,
        .three-col,
        .four-col {
            &.clear-two {
                clear: left;
            }
			&.margin, &.margin-col {
				margin-left: $item-v-margin;
			}
        }
	}

	// 1024 and larger
    @include breakpoint(desktop, min){
        padding: 0 $page-margin-large 0 $page-margin-large;
        .one-col {
            width: calc(25% - #{$item-h-margin} * 2);
        }
        .two-col {
            width: calc(50% - #{$item-h-margin} * 2);
        }
        .three-col {
            width: calc(75% - #{$item-h-margin} * 2);
        }
        .four-col {
            width: calc(100% - #{$item-h-margin} * 2);
        }

        .one-col,
        .two-col,
        .three-col,
        .four-col {
            &.clear-two {
                clear: none;
            }
            &.clear-four {
                clear: left;
            }
        }

		.one-col, .two-col, .three-col  {
			&.margin, &.margin-col {
				margin-left: calc(25% + #{$item-h-margin});
			}
		}
    }
}


.column-layout-six {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    padding: 0 $page-margin-small 0 $page-margin-small; //height: 300px;
    // overflow: scroll;

    .two-col,
    .four-col,
    .six-col {
        width: calc(100% - #{$item-h-margin} * 2);
        flex: 0 0 auto; //margin: 7px 5px 7px 5px;
        margin: $item-v-margin $item-h-margin $item-v-margin $item-h-margin;
    }

	// 768 and larger
    @include breakpoint(tablet, min){
        padding: 0 $page-margin-med 0 $page-margin-med;

		.two-col {
            width: calc(33.33% - #{$item-h-margin} * 2);
		}
		.three-col {
            width: calc(50% - #{$item-h-margin} * 2);
        }
        .four-col {
            width: calc(66.66% - #{$item-h-margin} * 2);
        }
        .six-col {
            width: calc(100% - #{$item-h-margin} * 2);
        }

        .two-col,
        .four-col,
        .six-col {
            &.clear-two {
                //clear: left;
            }
        }
	}

	// 1024 and larger
    @include breakpoint(desktop, min){
        padding: 0 $page-margin-large 0 $page-margin-large;
        .two-col {
            width: calc(33.33% - #{$item-h-margin} * 2);
        }
        .four-col {
            width: calc(66.66% - #{$item-h-margin} * 2);
        }
        .six-col {
            width: calc(100% - #{$item-h-margin} * 2);
        }

        .two-col,
        .four-col,
        .six-col {
            &.clear-two {
                clear: none;
            }
            &.clear-four {
                //clear: left;
            }
        }
    }
}