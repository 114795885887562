.favorites-toggle {
    // all: unset;
    background-color: unset;
    border: unset;
    cursor: pointer;
    color:$gray_bb;
    float:right;
    font-size: 13px;
    text-transform: uppercase;
    @include gotham(medium);
    height:32px; 
    line-height: 32px;
    position: relative;
    span {position: relative; top:-5px;}
	width: 39px;

    &:focus-visible{
        outline: -webkit-focus-ring-color auto 1px;
    }
    &:before {
        content:"\2606";
        color:$gray_bb;
		padding-right:5px;
		font-size:32px;
		left:0px;
		top:0px;
		position: absolute;
    }
    
    &.on {
        &:before {
            content:"\2605";
            color:$wim-purple;
			padding-right:5px;
            font-size:32px;
            left:0px;
            top:0px;
            position: absolute;
        }
        &:after {
            content:"\2606";
			color:$white;
            padding-right:5px;
            font-size:32px;
            left:0px;
            top:0px;
            position: absolute;
        }
	}
	
	.favorite-label {
		white-space: nowrap;
	}
}

.multi {
    .favorites-toggle {
        margin-top: -40px;
    }
}
//[data-dna="favorites-toggle"] {background:lightgray; width:100%; height:20px;}