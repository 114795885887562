/** logotitle styles **/

.logotitle {
	position: absolute;
    top: 0px;
    left: 0px;
	width: 500px;
	
	.logotitle-image {
        margin-bottom: 10px;
        position: relative;
		width: 100%;
		// @include breakpoint(tablet, max){
        // 	width: 100vw;
		// }
        height: 70px;
        a {
            max-width: 70px;
            max-height: 70px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
	}

    .wim-logo {
        margin: auto;
        width: 60px;
        height: 60px;
        @include background-2x('/assets/images/logos/Wimbledon_logo_small', 'png', 60px, 60px, top left );

        @include breakpoint(tablet) {
			margin-top: 0;
			width: 70px;
			height: 70px;
			background-image: url(/assets/images/logos/Wimbledon_logo@2x.png);
			background-size: 70px 70px;
			background-position: top left;
			background-repeat: no-repeat;
		}
		
		&.foundation {
			margin-top: 1px;
			width: 60px;
			height: 60px;
			@include background-2x('/assets/images/logos/Foundation_Logo_small', 'png', 60px, 60px, top left );

			@include breakpoint(tablet) {
				margin-top: 0;
				width: 70px;
				height: 70px;
				background-image: url(/assets/images/logos/Foundation_Logo@2x.png);
				background-size: 70px 70px;
				background-position: top left;
				background-repeat: no-repeat;
			}
		}

		&.members {
			margin-top: 1px;
			width: 60px;
			height: 60px;
			@include background-2x('/assets/images/logos/Members_Logo_small', 'png', 60px, 60px, top left );

			@include breakpoint(tablet) {
				margin-top: 0;
				width: 70px;
				height: 70px;
				background-image: url(/assets/images/logos/Members_Logo@2x.png);
				background-size: 70px 70px;
				background-position: top left;
				background-repeat: no-repeat;
			}
		}
    }

    //for generic wimbledon title text
    &-txt {
        color: $white;
        white-space: nowrap;
		margin: auto;

        &__name {
            @include gotham(medium);
            font-size: 36px;
            letter-spacing: 1px;
			line-height: 36px;
			display: none;  //main WIMBLEDON not displayed in 2019
        }
        &__dates {
			line-height: 19px;
			text-transform: uppercase;

			&.show {
                opacity: 1;
                transition: opacity .5s linear;
			}
			&.hide {
				opacity: 0;
				transition: opacity .5s linear;
            }
            @include breakpoint(tablet, max){
                font-size: 12px;
            }
        }
    }

    //for page title
    &-titles {
        //width: calc(100vw - 218px);
        //width: calc(50% - 100px);
        width: calc(50% - 88px);
        color: $white;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
		text-overflow: ellipsis;
		position: absolute;
		height: 60px;
		left: 55px;
		top: 1px;

        .title-txt {
			//position: absolute;
			width: 100%;
			//top: 0px;

			&-page {
				//top: 50%;
				//transform: translateY(-50%);
			}

			&-content {
				@include breakpoint(tablet){
					max-height: 73px
                }
			}
	
            h3{
                text-transform: uppercase;
                line-height: normal;
                text-align: left;
				white-space: normal;

				.subtitle {
					color: $live-green;
					font-size: 16px;
				}

                @include breakpoint(tablet, max){
                    font-size: 10px;
					letter-spacing: 0px;
					
					.subtitle {
						font-size: 9px;
						letter-spacing: 0px;
					}
				}
            }
        }
        @media only screen and (min-width: 375px){
           // width: calc(100vw - 250px);
        }

        @include breakpoint(tablet) {
			width: calc(50% - 130px);
			height: 70px;
			left: 80px;
			top: -1px;
        }

        @include breakpoint(desktop) {
            //width: 460px;
        }

        @include breakpoint(desktopSidePanel) {
            //width: 800px;
        }
        
    }
}