.atoz {
    .title-image { 
        position: relative; 
        height: auto;
    
        .image { 
            height: auto; 
            overflow-y: hidden; 
            z-index: -1; 

            &:after {
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 36%, rgba(0, 0, 0, 0.64) 99%, rgba(0, 0, 0, 0.65) 100%);
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
    
        }
        .image { 
            margin-top: 0px; 
            position: relative; 
            z-index: -1; 
            
            img { 
                width: 100%; 
                display: block; 
            }
        }
        .text { 
            display: block; 
            position: absolute; 
            top: 0; 
            bottom: 0; 
            left: 0; 
            width: 100%;
            color: $white;
            
            .page-title-text { 
                position: absolute; 
                bottom: 10px; 
                left: 10px; 
                right: 10px; 
                font-family: 'Gotham 5r', Helvetica, Arial, sans-serif; 
                font-size: 20px; 
            }            
            a { 
                color: $white;
            }            
        }
    }
    .content-filters {
        background-color: $white;
    }
    .content-list {
        margin-top: 10px;
    }
}