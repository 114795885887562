/** mainnav styles **/

$main_padding: 9px; 
$main_padding_small: 7px; 
$main_padding_extra_small: 4px;
$menu_content_height: 644px;
$menu_content_height_small: 525px;

.home24{
	&-main{
		padding-top: 0px !important;
	}
	.logotitle .logotitle-image{
		margin-bottom: 0px;
	}
	.logotitle-txt__name, .logotitle-txt__dates{
		color: black;
	}
	.logotitle-txt__dates{
		font-size: 10px !important;
	}
}

.mainnav {
    position: fixed;
	z-index: 125;
    width: 320px;
    height: 100%;
    pointer-events: none;
	text-shadow: none;

    @include breakpoint(tablet){
        width: 540px;
    }
    &--wrapper {
        position: absolute;
        width: 100%;
        height: 100%;

        &[display-state=hidden] {
            display: none;
        }

        &[display-state=visible]{
            pointer-events: auto;

            @keyframes main-nav-open{
                from{left: -540px;}
                to{left: 0px;}
            }
            animation-name: main-nav-open;
            animation-duration:.5s;
        }
    }

    &--header {
        height: 87px;
        top: 0px;
		position: absolute;
		background-color: $white;
		width: 320px;

        .logotitle {
			width: 100%;
			margin: 2px 0 0 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			background-color: white;

			@include breakpoint(tablet) {
				margin: 6px 0 0 0;
			}

			.logotitle-image {
				margin-bottom: 0px;
				@include breakpoint(tablet) {
                    margin-bottom: 10px;
                }
			}

			.logotitle-txt {
				display: block;
			}

			.back-to-wim{
				padding: 12px 0;
				font-size: 15px;
				line-height: 24px;
				letter-spacing: 0.5px;
				text-transform: uppercase;
				color: #00703C;
				a{
					&:hover{
						text-decoration: none;
					}
					display: flex;
					align-items: center;
					.left-icon{
						margin-right: 9px;
    					font-size: 10px;
					}
				}
			}

            .wim-logo {
                margin-top: 1px;
                width: 60px;
                height: 60px;
                @include background-2x('/assets/images/logos/Wimbledon_logo', 'png', 60px, 60px, center center, repeat-x );
				
                @include breakpoint(tablet) {
                    margin-top: 0px;
                    width: 70px;
					height: 70px;
					background-image: url(/assets/images/logos/Wimbledon_logo@2x.png);
					background-size: 70px 70px;
                }
			}
			
			.logotitle-line {
				width: 300px;
				height: 1px;
				background-color: $gray-light;
				margin: 0;
			}
        }
        
        .logotitle-txt__name, .logotitle-txt__foundation {
            color: $wim-purple;
			display: block;
			text-transform: uppercase;
			font-size: 12px;
			letter-spacing: .86px;
			margin: 0 0 10px 0;
		}

		.logotitle-txt__dates {
			color: $wim-purple;
			text-transform: uppercase;
			font-size: 10px;
			letter-spacing: .86px;
			margin: -6px 0 0 0;
		}

        @include breakpoint(tablet){
			height: 132px;
			width: 540px;

			.logotitle-txt__dates {
				margin: 0 0 10px 0;
				font-size: 12px;
			}

            .logotitle {
				margin: 18px 0 0 0;
				
				.logotitle-line {
					width: 520px;
					margin: 0;
				}

				.back-to-wim {
					padding: 17px 0;
				}
            }

            .logotitle-txt__name, .logotitle-txt__dates, .logotitle-txt__foundation {
                display: block;
            }
        }

    }

    button {
        margin: 25px 9px 0px 16px;
        padding-top: 4px;
        width: 39px;
        border: 0;
        background: transparent;
        cursor: pointer;
        pointer-events: auto;
        //outline: none;
		position: absolute;
		z-index: 101;
		left: 0px;

        i {
            color: $white;
            font-size: 17px;
            text-shadow: none;

            &.wim-icon-close {
				color: $wim-green;
				font-size: 22px;
            }
        }

        @include breakpoint(tablet){
            margin: 38px 17px 0px 23px;
            padding-top: 5px;

            &.open {
                .header-global.fixedBak + & {
                    margin: 25px 9px 0px 16px;
                    padding-top: 4px;
                    transition: all .1s ease;
                }
            }
        }

		div {
			@include gotham(bold);
			font-size: 11px;
			text-transform: uppercase;
			color: $white;
			margin: 3px -5px;
		}

    }

	&--content-main {

		//@include helvetica(medium);
		position: absolute;
		top: 87px;
		height: calc(100% - #{$headerHeightSmall});
		width: 320px;
		overflow: auto;

		&.foundation{
			top: 146px;
			height: calc(100% - 132px);
		}

		@include breakpoint(tablet){
			top: 132px;
			height: calc(100% - #{$headerHeightLarge});
			width: 540px;

			&.foundation{
				top: 183px;
				height: calc(100% - 170px);
			}
		}

		&-nav {
			background-color: #FFF;
			position: relative;
			width: 159px;
			height: $menu_content_height_small;
			.menu-bg{
				height: 100%;
				background: white;				
				left: 159px;
				position: absolute;
				width: 161px;
				@include breakpoint(tablet){
					left: 270px;					
					width: 270px;
				}
			}
			
			@include breakpoint(tablet){
				width: 270px;
				height: $menu_content_height;
			}

			.navbar {
				margin:0;
				padding:0px;
				text-align:left;

				li{
					font-size:15px;
					letter-spacing: .5px;
					text-transform:uppercase;
					list-style:none;

					@include breakpoint(tablet,max){
						font-size:13px;
					}
				}
				
				.nav{
					list-style: none;

					&-item{
						margin:0;
						cursor: pointer;
						

						&.full-padding {
							padding: $main_padding_small 0px $main_padding_small 15px;
							@include breakpoint(tablet){
								padding: $main_padding 0px $main_padding 22px;
							}
						}

						&.compact-padding {
							padding: $main_padding_extra_small 0px $main_padding_extra_small 15px;
							@include breakpoint(tablet){
								padding: $main_padding_small 0px $main_padding_small 22px;
							}
						}

						.live{
							display:inline;
							position: absolute;
							background-color: $live-green;
							font-size: 9px;
							letter-spacing: 2px;
							color: $wim-black;
							padding: 1px 0 0 2px;
							margin-top: 1px;
							margin-left: 6px;
							
							@include breakpoint(tablet){
								font-size: 10px;
								letter-spacing: 2px;
								margin-left: 8px;
								padding: 1px 0 1px 2px;
							}
						}

						a {
							color: $wim-purple;
						}

						.bottom-highlight{ 
							display: none;
						}

						ul {
							position:absolute;
							top:0px;
							left:calc(-100vw - 30px);
							padding: 0 5px 5px 5px;

							@include breakpoint(tablet){
								padding: 7px 15px 5px 10px;
							}
		
							li {               
								a, span {
									text-transform:none;

									.wimbledon {
										font-weight: bold;
										text-transform: uppercase;
									}
								}
							}
						}

						&-open {
							background:#FFFFFF;
							padding: $main_padding_small 0 0 15px;

							@include breakpoint(tablet){
								padding: $main_padding 0 0 22px;
							}

							> a:after {
								border-top: 0;
								border-bottom: 3px solid red;
								position: absolute;
							}
							> ul {
								height: $menu_content_height_small;
								left: 159px;
								width: 161px;
								background-color:$white;

								@include breakpoint(tablet){
									left: 270px;
									height: $menu_content_height;
									width: 270px;
								}
							}
							a{
								color: $wim-green;

								&:hover {
									text-decoration: none;
								}
							}

							.bottom-highlight{ 
								height: 1px;
								width: 90%;
								background-color: $wim-green;
								display: block;
								margin-top: calc(#{$main_padding_small} - 4px);
								position: absolute;

								@include breakpoint(tablet){
									margin-top: calc(#{$main_padding} - 4px);
								}
							}
						}
						
						&-subnav{
							display: none;
							width: 270px;
							height: 100%;

							@include breakpoint(tablet,max){
								padding:0;
							}
							.submenu{
								display: flex;
								flex-wrap: wrap;
								flex-direction: column;
								height: 300px;
								margin: 2px 0 10px 14px;

								@include breakpoint(tablet){
									height: 245px;
									//padding: 10px 0px 0px 10px;
									margin: 0 0 10px 14px;
								}

								li{
									flex: 1 1 auto;
									max-height: 30px;
									display: flex;
									align-items: center;

									@include breakpoint(tablet,max){
										font-size: 13px;
									}
								}

								.myWimSubnav {
									color: $wim-green;
									text-transform: none;
									margin: 3px 0 0 0;

									.name {
										float: left;
										cursor: pointer;
									}

									.button {
										float: right;
									}

									.line {
										height: 1px;
										width: 100%;
										background-color: $lines-gray;
										margin: 8px 0 10px 0;
										float: left;
									}
								}
							}
							
							&-open {
								display: block;
								
								@include breakpoint(tablet,max, true){
									height:595px;
								}
								> a:after {
									border-top: 0;
									border-bottom: 3px solid $white;
									position: absolute;
									margin-top: 15px;
								}
								a {
									color: $wim-green;
									padding:0px;
									clear: both;
									display: block;
								}
								.feature{
									&-one{
										font-size:12px;
										@include breakpoint(tablet,max){
											position:absolute;
											top:125px;
											left:calc(-100vw - 30px);
										}  
									}
									&-two{
										font-size:12px;
										@include breakpoint(tablet,max){
											position:absolute;
											top:125px;
											left:calc(-100vw - 30px)
										}  

									}           
								}
							}
						}
					}
				}

				&--linebreak{
					width:223px;
					margin-left:23px;
					color: $lines-gray;
					opacity: 1;

					@include breakpoint(tablet,max){
						width:126px;
						margin-left:16px;
						margin-top: 3px;
						margin-bottom: 3px;
					}

				}

				&--footer{
					padding: 0;
					margin: 0;
					width: 159pxpx;
					text-align:left;
					font-size: 16px;
					position: absolute;
    				bottom: 60px;
					
					@include breakpoint(tablet,max){
						bottom: 34px;
						width: 270px;
					}

					.nav{
						list-style: none;
		
						&-item{
							list-style: none;
							padding: 10px 0;

							@include breakpoint(tablet,max){
								padding:7px 30px 7px 13px;
							}
							a{
								color:$white;
								font-size:10px;
								text-transform:uppercase;
							}

							&-footer {
								list-style: none;
								padding: 0px 0px 0px 15px;
								
								@include breakpoint(tablet){
									padding: 0px 0px 0px 22px;
								}

								a{
									color: $wim-purple;
									font-size: 10px;
									letter-spacing: 1px;
									text-transform:uppercase;

								}
								a.flag {
									img {
										margin-top: 10px;
									}
								}
							}
						}
					}
				}

				&--social{
					width: 95%;
					padding:0px;
					margin: 0 0 18px 8px;
					//text-align:left;
					position: absolute;
					bottom: 0px;

					@include breakpoint(tablet,max){
						margin: 0 0 8px 0px;
					}

					&-bar {
						@include breakpoint(tablet,max){
							display: none;
						}
					}

					&-button{
						list-style: none;
						display:none;
						cursor: pointer;
						margin-left: 10px;
						width: 156px;

						&-wrapper{
							color: $wim-purple;
							i{
								font-size: 18px;
								display: block;
								float: left;

								@include breakpoint(tablet,max){
									font-size: 18px;
								}
							}

							&.clicked{
								opacity: .45;
							}
						}

						&-label {
							@include gotham(medium);
							font-size: 12px;
							float: left;
							margin: 2px 0 0 5px;
						}

						.share-popup {
							bottom: 40px;
							left: -7px;
						}

						@include breakpoint(tablet,max){
							display: block;
						}
					
					}
				}
			}
		}
	}
	
	&[display-state=hidden] {
		.background-lightbox {
			display: none;
			background-color: rgba(0, 0, 0, 0);
			//transition: background-color 2s linear;
		}
	}

	&[display-state=visible]{
		.background-lightbox {
			display: block;
			width: 100vw;
			height: 100vh;
			background-color: rgba(0, 0, 0, .4);
			pointer-events: auto;
			

			@include breakpoint(extraLargeDesktop){
				width: calc(#{$maxWidth} - #{$sidebarWidth});
			}
		}
	}
	
    
}

.myWimbledon {
	.my {
		font-size: 12px;
		margin: 0;
		position: relative;
		bottom: -4px;
	}
	.wimbledon {
		font-weight: bold;
		text-transform: uppercase;
		font-size: 14px;
	}

	a {
		&:hover, &:active {
			text-decoration: none !important;
			border-bottom: 1px solid $wim-green;
		}
	}
	
}

/** normalize look of myWimbledon title component text inside of h3 tags **/
h3 {
	.myWimbledon {
		.my {
			font-size: 16px;
		}
		.wimbledon {
			font-weight: normal;
		}
	}
}

/** normalize look of myWimbledon title component text inside of column body class **/
.body {
	.myWimbledon {
		.my {
			font-size: 13px;
		}
	}
}

/** RLL added these styles to change the position of the underline for my Wimbledon **/
.myWimbledonItem {
	.myWimbledonLink {
		padding: 0 0 1px 0 !important;
		position: relative;
		display: inline-block !important;

		&:hover, &:active {
			padding: 0 !important;
		}
	}

	&.hide {
		display: none !important;
	}
}

.nav-item {
    a.myWimbledonLink, a.myWimbledonLink:visited {
        &:hover, &:active {
            text-decoration: none;
            border-bottom: 1px solid $wim-purple;
        }
    }
}

.nav-item.nav-item-open, .nav-item-subnav {
    a.myWimbledonLink, a.myWimbledonLink:visited {
        &:hover, &:active {
            text-decoration: none;
            border-bottom: 1px solid $wim-green;
        }
    }
}

