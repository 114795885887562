.pointNavFilters {
	//height: 320px;
	//width: 400px;
	height:calc(100% - 38px - 4px);
	//background-color: rgb(128, 128, 128);
	display: none;

	margin-top:2px;
	border:solid 1px $accordionWrapBorder;
	padding:2px 5px;

	&.show {
		display: block;
	}

	.filterButton {
		text-transform: uppercase;
		font-size: 14px;
		color: #FFFFFF;
		position: relative;
		top: 20px;
		margin: 0px 10px 20px 10px;
		padding: 5px;
		cursor: pointer;


		border: 0px;
		border-color: transparent; /* remove the border's colour */
		box-shadow: 0 0 0 1px #FFFFFF; /* emulate the border */

		.divider {
			position: absolute;
			right: 28px;
			height: 60%;
			top: 20%;
			width: 1px;
			background-color: #FFFFFF;
		}

		.indicator {
			background-image: url(/assets/images/matcharchive/filter_add.png);
			background-repeat: no-repeat;
			background-position: center;
			background-size: 12px;
			width: 18px;
			height: 100%;
			position: absolute;
			right: 5px;
			top: 0px;
		}

		&.on {
			background-color: #FFFFFF;
			color: #000000;

			.divider {
				background-color: #000000;
			}

			.indicator {
				background-image: url(/assets/images/matcharchive/filter_remove.png);
				background-size: 16px;
			}
		}
	}
}