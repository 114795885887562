
.pde-event-wrapper {
	color: #00703C;
    display: flex;
    width: 100%;
    justify-content: center;
	padding: 8px 0;
	
	.pde-event-selector {
		border: 2px solid $wim-green;
		padding-left: 20px;
	}
}



.player-digital-experience {
    .player-digital-header {
        border-bottom: 1px solid #BBBBBB;
        padding-bottom: 20px;
        text-align: center;
    }
    .section-container {
        border-bottom: 1px solid #BBBBBB;
        padding-bottom: 20px;
        .row {
            display: flex;
            margin-bottom: 10px;
        }
        .row-header {
            width: 32%;
            margin-right: 10px;
            &.catering {
                width: 40%;
                margin-right: unset;
                @include breakpoint(tablet,min){
                    width: 16%;
                }
            }
        }
        .row-value {
            color: $wim-purple;
            font-weight: bold;
            max-width: 250px;
        }
    }
    /** Catering **/
    .catering-header {
        text-align: center;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #BBBBBB;
        .top-header {
            font-size: 18px;
        }
        .green-text {
            color: $wim-green;
        }
        .bold {
            font-weight: bold;
        }
        .black-text {
            color: $wim-black;    
        }
        .header-row {
            margin-bottom: 20px;
        }
    }  

    .daily-allowance{
        text-align: left;
    }
    
    .balances{
        justify-content: space-evenly;
        .current-balance, .today-spend{
            font-size: 20px;
        }
    }

    .transactions{
        width: 100%;
        .transaction{
            width: 100%;
            background-color: rgb(243,242,242);
            border: 1px solid rgb(227, 227, 227);
            border-radius: 2px;
            padding: 15px;
            margin-bottom: 25px;
            .time-amount{
                margin: 0;
                padding: 10px 0 0 0;
                justify-content: space-between;
                border-bottom: 1px solid #BBBBBB;
                .one-col{
                    margin: 0;
                    padding: 0;
                }
                .catering, .transaction-total{
                    padding-bottom: 15px;
                }
                .transaction-total{
                    text-align: right;
                }
            }
            h4{
                font-weight: normal;
            }
            .items{
                margin-top: 15px;
                ul{
                    margin-top: 0px;
                    li{
                        font-weight: bold;
                        list-style: none;
                        padding: 10px 0 0 0;
                    }
                }
            }
        }
    }

    /*** Transport ***/
    .transport-date-filter {
        text-align: center;
        margin: auto;
        margin-bottom: 30px;
        border: 2px solid #00703C;
        max-width: 270px;
        button {
            color: #3B3B3B;
            min-width: 260px;
            padding: 0 5px;
            .menu-arrow {
                float: right;
                padding-top: 4px;
            }
        }
    }

    .transport-header {
        text-align: center;
        padding-bottom: 50px;
        padding-top: 10px;
        border-bottom: 1px solid #BFBFBF;
        margin-bottom: 30px;
    }

    .transport-container {
        width: 100%;
        // text-align: center;
        .time-div {
            background-color: #f2f2f2;
            font-weight: bold;
            text-align: center;
            padding: 8px;
            margin-bottom: 10px;
        }
        .section-container .row {
            // justify-content: center;
        }
        .section-container {
            padding: 20px;
            border-top: 1px solid #DEDEDE;
            border-bottom: none;
        }
        .tour-status {
            text-align: center;
            text-transform: uppercase;
            color: $wim-purple;
            font-weight: bold;
        }
        .tour-data {
            justify-content: center;
            display: flex;
        }

        @include breakpoint(tablet,min){
            .row-header {
                min-width: 200px;
                width: 200px;
            }
            .row-value {
                width: 280px;
            }
        }
    }

    /** Prize Money **/
    .form-update-text {
        font-size: 16px;
        margin-top: 20px;
    }
    .prize-money-header {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .prize-money-explanation {
        justify-content: center;
        text-align: center;
        font-size: 18px;
        flex-flow: column;
        p {
            margin-bottom: 10px;
            font-size: 16px;
            a {
                font-weight: bold;
            }
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
    .prize-money-form-container {
        margin-top: 20px;
    }
    .prize-money-checkbox {
        margin-bottom: 20px;
        .checkbox-option label.disabled:before {
            // background-color: unset !important;
            border: 1px solid $wim-purple !important;
        }
        .checkbox-option label.disabled:after {
            background-color: $wim-purple !important;
        }
    }
    .prize-money-form-divider {
        background-color: $wim-green;
        height: 4px;
        width: 100%;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .bank-info-container {
        margin-top: 20px;
    }
    .pdf-links {
        padding-top: 20px;
    }
    .pdf-header {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 10px;
    }
    .rendered-link {
        margin-bottom: 10px;
    }
    .prizemoney-btn {
        .generic-button_wrapper {
            margin-top: 40px;
        }
    }
    .section-container.pdf {
        margin-bottom: 20px;
    }

    /* Delegates */
    .delegate-request{
        display: flex;
        flex-direction: column;
        input{
            max-width: 50%;
        }
        .submit{ 
            margin-top: 10px;
        }
    }
}

    /** Championship Registration **/
    .registration-border {
        height: 1px;
        background-color: $wim-green;
        width: 100%;
        // margin-bottom: 20px;
    }
    .inline-registration {
		left: (-($page-margin-large));
		width:calc(100% + 2 * #{$page-margin-large});
		position: relative;
        text-align: center;
		background: #fff;

		@include breakpoint(desktop,max){
			left: (-($page-margin-med));
			width:calc(100% + 2 * #{$page-margin-med});
		}
		
		@include breakpoint(tablet,max){
			left: (-($page-margin-small));
			width:calc(100% + 2 * #{$page-margin-small});
		}
		
        .registration-content {
            width: 90%;
            @include breakpoint(tablet,min){
                width: 40%;
            }
            margin: auto;
        }
    }
    .popup-registration {
        position: fixed;
        left: 50%;
		top: 50%;
        transform: translate(-50%,-50%);
        width: 520px;
        background: #fff;
        border: 1px solid #979797;
        box-shadow: 0px 0px 4px 1px #00000082;
        z-index: 150;
       
        .registration-content {
            padding: 30px 20px;
            position: relative;
            .wim-icon-close {
                color: #00703C;
                position: absolute;
                right: 22px;
                top: 15px;
                font-size: 20px;
                cursor: pointer;
            }
        }
        text-align: center;
        .registration-section {
            border-bottom: 1px solid $gray_bb;
            &:last-child {
                border-bottom: none;
            }
        }  

		@include breakpoint(tablet, max, true){
            width: 80%;
			min-width: 320px;
        }

		.expanded & {
			left: calc(50% - 187px);
		}		 
    }

    .registration-content {
        padding: 30px 20px;
        position: relative;

        .wim-icon-close {
            color: $wim-green;
            position: absolute;
            right: 22px;
            top: 15px;
            font-size: 20px;
        }
        .wim-icon-updates {
            color: $wim-green;
            text-align: center;
            font-size: 34px;
        }
    }

    .registration-type-header {
        font-size: 16px;
        color: $wim-black;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 15px;
    }
    .registration-type-message {
        font-size: 14px;
        color: $wim-black;
    }
    .registration-header {
        font-size: 20px;
        font-weight: bold;
        color: $wim-purple;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
    .registration-notifcation {
        text-transform: uppercase;
        color: $wim-green;
        font-size: 12px;
        margin-bottom: 10px;
        font-weight: bold;
        margin-top: 5px;
    }
    .registration-content {
        a {
            font-weight: bold;
        }
    }
    .registration-header-underline {
        width: 30%;
        margin: auto;
        height: 4px;
        background-color: $wim-purple;
        max-width: 100px;
        margin-bottom: 15px;
    }

    .registration-banner {
        background-color: $live-green;
        padding: 10px;
        color: $wim-purple;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: bold;
    }

    .bold {
        font-weight: bold;
    }
    .unbold {
        font-weight: 100;
    }