.LikelihoodToWinBanner {
    .watson-logo {
        background: url("/assets/images/logos/watson-white-logo.svg") no-repeat; 
        background-size: contain;
        display: inline-block;
        position: relative;
        // transform: translateY(5px);
        margin-right: 5px;
        height: 28px;
        width: 28px;

        &.left {
            left: 10%;
        }

        &.right {
            right: 10%;
        }
    }

    .likelihood-win-btn {
        border-radius: 7px;
        background-color: $powerrank-purple;
        // @include watsonBackgroundColor;
        color: $white;
        font-size: px2rem(16);
        margin: auto;
        margin-bottom: 17px;
        height: 35px;
        // line-height: px2rem(35);
        position: relative;
        text-align: center;
        text-transform: uppercase;
        width: calc(100% - 12px);
        @include gotham(medium);
        display: flex;
        justify-content: center;
        align-items: center;

        &:after {
            content: ' ';
            height: 0;
            position: absolute;
            width: 0;
            border: 15px solid transparent; /* arrow size */
            border-top-color: $powerrank-purple;  /* arrow color */
            // border-top-color: $watson-bg-default;
            bottom: -29px;
            z-index: 1;
        }
        &.powered-by {
            &:before {
                content: 'Powered by IBM';
                height: 11px;
                width: 100%;
                position: absolute;
                bottom: -16px;
                text-align: center;
                @include gotham-book();
                font-size: px2rem(10);
                color: $wim-black;
                text-transform: capitalize;
            }
        }

        &.none{
            background: repeating-linear-gradient(
                120deg,
                #7026A0,
                #7026A0 3px,
                $powerrank-purple 3px,
                $powerrank-purple 10px
            );

            &:after {
                display: none;
            }
        }

        &.left {
            &:after {
                left: 5%;
                // border-top-color: #1163FF;
            }
        }

        &.center {
            &:after {
                left: 48%;
            }
        }

        &.right {
            &:after {
                right: 5%;
                // border-top-color: $watson-bg-start;
            }
        }
    }
}

.insights-index {
    .LikelihoodToWinBanner {
        .likelihood-win-btn {
            &.left {
                &:after {
                    left: 20px;
                }
            }
    
            &.right {
                &:after {
                    right: 20px;
                }
            }

            @include breakpoint(tablet, max){
                &.left {
                    &:after {
                        left: 28px;
                    }
                }
        
                &.right {
                    &:after {
                        right: 28px;
                    }
                }
            }
            @include breakpoint(mobile, max){
                &.left {
                    &:after {
                        left: 18px;
                    }
                }
        
                &.right {
                    &:after {
                        right: 18px;
                    }
                }
            }

        }

    }
}

/** override for Match Insights details page */
.match-insights-content{
    .LikelihoodToWinBanner {
        .likelihood-win-btn {
            font-size: px2rem(16);
            line-height: 24px;

            &.left {
                &:after {
                    left: 20px;
                }
            }
    
            &.right {
                &:after {
                    right: 18px;
                }
            }
    
            @include breakpoint(tablet, min, true) {
                font-size: px2rem(11);
                width: 100%;
                padding-left: 15px;
                padding-right: 15px;

                &.left {
                    &:after {
                        left: 38px;
                    }
                }
        
                &.right {
                    &:after {
                        right: 38px;
                    }
                }
            }
    
            @include breakpoint(desktop, min, true) {
                font-size: px2rem(14);
    
                &.center {
                    &:after {
                        left: 46%;
                    }
                }
            }
    
            @include breakpoint(tablet, max, true) {
                max-width: 434px;
            }
        }
    }
}

/** override for SlamTracker component */
.slamtracker {
    .likelihood-win-btn {
        width: 100%;
        margin-bottom: 25px;

        &.left {
            &:after {
                left: 10.5%;
            }
        }

        &.right {
            &:after {
                right: 10.5%;
            }
        }

        @include breakpoint(tablet, min, true) {
            font-size: px2rem(16);
        }
    }
}


/** override for news embedded component */
.news .LikelihoodToWinBanner {
    .likelihood-win-btn {
        width: 100%;
    }
}
