.search-box{
    width: 300px;
    height: 40px;
	margin: 0 auto;
	pointer-events: auto;

    .search-wrapper{
        position: relative;
        color: $black;
        // height: 100%;

        input{
			text-align: left;
            text-indent: 30px;
            margin-top: 0;
        }

        input::placeholder{
            color: $black;
            opacity:1;
        }
        input:focus::placeholder            {color: transparent}
        input:focus::-moz-placeholder       {color: transparent}
        input:focus:-moz-placeholder        {color: transparent}
        input:focus:-ms-input-placeholder   {color: transparent}

        .wim-icon-search{
            font-size: 15px;
            position: absolute;
            left: 10px;
            top: 21px;
            transform: translateY(-50%);
            &.predictive {
                top: 60%;
            }
        }
    }
}


