/** match-box styles **/

.match-box-container {
    &.usePlayerImages {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        .match-box {
            width: 100%;
            padding: 0px 0px;
        }
        .team-img-container {
            display: none;
        }
        @include breakpoint(tablet) {
            .match-box {
                padding: 0px 20px;
            }
            .team-img-container {
                display: block;
            }
        }
    }
}

.match-box {
    position: relative;
    .f-item-preview & {
        background: lightgray;
    }
    @include gotham(medium);

    .match-location {
        text-transform: uppercase;
        color: $white;
        font-size: 14px;
        height: 16px;

        &.center {
            text-align: center;
        }
    }
    &.sidepanel-box {
        .match-location {
            color: $wim-black;
        }
        @at-root .slamtracker-panel, .sidepanel #{&}{
            .upset{
                color: $gray-650 !important;
                top: 0;
            }
        }
    }
    &.match-box-profile {
        .match-location {
            text-align: center;
            color: $black;
        }
    }
    .upset{
        position: absolute;
        top: -3px;
        right: 0;
        color: $white;
        font-size: 11px;
        display: flex;
        align-items: center;
        i{
            margin-right: 3px;
            color: #C4E048;
        }
    }
    .match-status {
        position: relative;
        padding-top: 5px;
        padding-left: 9px;
        display: flex;
        flex-flow: column;
        border-top: 2px solid $gray-400;
        border-right: 2px solid $gray-400;
        border-bottom: 2px solid $gray-400;
        border-radius: 8px;
        height: 134px;
        background: $white;

        .flag {
            // flex: 0 0 22px;
            flex: 0 0 17px;
            align-self: center;
            margin-left: 4px;

            img {
                // width: 22px;
                // height: 13px;
                width: 17px;
                height: 10px;
            }
        }
        .name,
        .seed {
            float: left;
            font-size: 12px;
            // margin-left: 7px;
            height: 39px;
            line-height: 39px;
            color: $wim-green;
        }
        .name {
            flex: 0 1 auto;
            //max-width: calc(100% - 60px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-left: 3px;
            cursor: pointer;

            a {
                display: flex;
                flex: 0 0 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
            }

            .button-text-link button {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-left: 3px;
              cursor: pointer;
              max-width: 100%;
            }

            &.ai-opponent-name {
                color: $gray-darker;
                font-style: italic;
            }
        }

        .view-more-opponents {
            display: block;
            font-size: 12px;
            margin-left: 28px;
            margin-bottom: 7px;
            margin-top: 5px;
        }

        .view-more-opponents-cta {
            font-size: 12px;
            color: $gray-darker;
            font-style: italic;
            margin-left: 6px;
            margin-top:  6px;
            margin-bottom: 7px;

            .button-text-link {
                color: $gray-darker;
            }
        }

        .seed {
            margin-left: 5px;
            flex: 0 0 15px;
            //font-size: 12px;
            font-size: 10px;
            cursor: default;

            &.hide {
                display: none;
            }
        }
        .team-info {
            display: flex;
            height: 40px;

            .team-name {
                flex: 0 0 auto;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                width: calc(100% - (170px + 5px));
                text-transform: uppercase;
                //max-width: calc(100% - calc(168px + 5px)); // 168 is width of scores
                height: 40px;
            }
            .name-link {
                .name {
                    cursor: pointer;
                }
            }
            &.singles {
                .name {
                    overflow: hidden;
                }
                .team-name {
                    .member-one,
                    .member-two {
                        &.favorite {
                            background-color: $wim-purple;
                            .name,
                            .seed {
                                color: $white;

                                .button-text-link {
                                    color: $white;
                                }
                            }
                        }
                        &.searchedPlayer {
                            background-color: $wim-yellow !important;
                            .name, .seed{
                                color: $wim-green;

                                .button-text-link {
                                    // color: $white;
                                    color: $wim-green;
                                }
                            }
                        }
                    }
                    .member-one {
                        display: flex;
                    }
                    .member-two {
                        display: none;
                    }
                }
            }
            &.doubles {
                .flag {
                    // align-self: end;
                }
                .member-one,
                .member-two {
                    display: flex;
                    height: 50%;

                    &.favorite {
                        background-color: $wim-purple;

                        .name,
                        .seed {
                            color: $white;

                            .button-text-link {
                                color: $white;
                            }
                        }
                    }
                    &.searchedPlayer {
                        background-color: $wim-yellow !important;
                        .name, .seed{
                            color: $wim-green;

                            .button-text-link {
                                // color: $white;
                                color: $wim-green;
                            }
                        }
                    }
                }
                .name {
                    line-height: 19px;
                    height: 19px;
                }
                .seed {
                    font-size: 10px;
                    line-height: 19px;
                    height: 19px;
                }
            }
            .match-results {
                //float: right;
                //width: 173px;
                flex: 0 0 154px; //173px
                //margin-right: 5px;

                .results-wrapper {
                    float: left;
                    cursor: default;
                    .pts,
                    .set {
                        float: left;
                        width: 24px;
                        height: 39px;
                        line-height: 39px;
                        font-size: 16px;
                        text-align: center;
                        color: $wim-text-purple;

                        .scores {
                            .tiebreak {
                                position: relative;
                                top: -9px;
                                font-size: 9px;
                            }
                        }
                    }
                    .pts {
                        //display: none;
                        visibility: hidden;
                        margin-right: 3px;
                    }
                }
            }
            &.team-one {
                margin-top: 5px;

                .crticon {
                    padding-top: 13px;
                }
                .match-results {
                    // .crticon {
                    //     margin-top: 13px;
                    //     padding-top: unset;
                    // }
                    .results-wrapper {
                        .pts,
                        .set {
                            padding-bottom: 3px;
                        }
                        // .crticon {
                        //     margin-top: 13px;
                        //     padding-top: unset;
                        // }
                    }
                }
                &.highlight {
                    background: $gray-200;
                }
                &.favourable, &.difficult, &.neutral, &.post-match-color {
                    border-top-right-radius: 8px;
                    border-top-left-radius: 8px;
                }
            }
            &.team-two {
                //border-top:  1px solid $gray-400;
                width: 100%;

                .team-name {
                    border-top: 1px solid $gray-400;
                }
                .crticon {
                    padding-top: 16px;
                    border-top: 1px solid $gray-400;
                }
                .match-results {
                    // .crticon {
                    //     margin-top: 16px;
                    //     padding-top: unset;
                    // }
                    .results-wrapper {
                        .pts,
                        .set {
                            padding-top: 3px;
                        }
                        // .crticon {
                        //     margin-top: 16px;
                        //     padding-top: unset;
                        // }
                    }
                }
                &.highlight {
                    background: $gray-200;
                }
                &.favourable, &.difficult, &.neutral, &.post-match-color {
                    border-bottom-right-radius: 8px;
                    border-bottom-left-radius: 8px;
                }
            }
        }
        .results-info {
            //font-size: 9px;
            font-size: 0px;
            text-transform: uppercase;
            color: $select-menu-border;
            position: absolute;
            right: 9px;
            top: 65px;
            transform: translateY(-50%);
            width: calc(24px * 6 + 5px);
            // width: 154px;
            //padding-left:  19px;
            background-color: $white;
            text-align: center;

            &-pts {
                display: inline-block;
                width: 24px;
                font-size: 9px;
                margin-right: 3px;
            }
            &-set {
                width: 24px;
                display: inline-block;
                text-align: center;
                font-size: 9px;
                //&: nth-child(6){width: 18px;}
            }
        }
        .crticon {
            // float: left;
            width: 17px;
            // height: 17px;
            height: 100%;
            flex-basis: 17px;
            font-size: 13px;
            padding: unset;
            // margin-right: 2px;

            i {
                display: none;
            }
            &.currentServer {
                .wim-icon-ballSolid {
                    color: $yellow-tennisball-color;
                    display: inline;
                }
                .wim-icon-check {
                    display: none;
                }
            }
            &.winner {
                padding-top: 3px;

                .wim-icon-check {
                    color: $green-check-color;
                    display: inline;
                }
                .wim-icon-ballSolid {
                    display: none;
                }
            }
        }
        .event-info {
            text-transform: uppercase;
            color: $gray-650;
            font-size: 12px;
            height: 15px;
            padding: 0px 5px;

            .event-round {
                float: right;
                margin-right: 2px;
            }
        }
        .event-notes {
            text-transform: uppercase;
            color: $gray-650;
            padding-left: 5px;
            font-size: 10px;
            margin-top: 10px;

            .duration {
                float: left;
            }
            .update {
                float: right;
                margin-right: 5px;
            }
        }
        &.live {
            .team-info {
                .match-results {
                    .results-wrapper {
                        .pts {
                            margin-right: 3px;
                            display: inline;
                            visibility: visible;

                            &.latest {
                                background: $wim-purple;
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
        .green-border {
            width: 9px;
            height: 134px;
            background: $wim-green;
            position: absolute;
            top: -2px;
            left: -1px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }
    }
    .match-box-buttons {
        display: flex;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
        font-size: 11px;

        .generic-button_wrapper {
            // width: calc(50% - 4px);
            width: 100%;
            margin: 7px 0 0;
            cursor: pointer;
            .generic-button{
                border-radius: 5px;
            }
            // &:first-child {
            //     margin-right: 4px;
            // }
            // &:last-child {
            //     margin-left: 4px;
            // }
        }
        .slamtracker-stats {
            padding-bottom: 0;
        }
        .disabled {
            opacity: .5;
            cursor: default;
        }
        @include breakpoint(medmobile, max) {
            .mobile-hide {
                display: none;
            }
        }
    }
}