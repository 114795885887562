.feature{
	
    &-label{
		@include gotham(medium);
		letter-spacing: 1px;
        color:$gray_9b;;
        text-transform:uppercase;
		font-size:14px;
		margin-left: 15px;
		margin-bottom: -8px;
		
		@include breakpoint(tablet, max){
            display: none;
        } 
    }
    &-pic{
        font-size: 12px;
        margin: 14px 0 8px 15px;
		
        a{
			@include gotham(medium);
        	text-decoration:none;
			color:$black;
			letter-spacing: 1px;
			font-size: 12px;
        }

        @include breakpoint(tablet, max){
            display: none;
        }  

    }
    
    &-image{
        margin: 5px 15px 3px 0px;
        width: 210px;

        img{
            width: 100%;
        }
    }           
}