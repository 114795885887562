.homepage-gallery{
    .mobile-display {
		display: block;
		margin-top: 10px;

        .homepage-element-info {
            position: relative;
            transform: translate(0);
            width: 100%;
            left: 0;
            i {
                color: $wim-green;
            }
            .time {
                color: $wim-purple;

                h6 {
                    color: $wim-green;
                }
            }
            .title {
                color: $wim-text-purple
            }

            @include breakpoint(tablet, max) {
                .time {
                    text-align: center;
                }
                .title {
                    text-align: center;
                }
            }
        }
    }
    .desktop-display {
        display: none;
    }
    .standard-wrapper{
		.gallery-wrapper{
			width: 100%;
			margin-left: unset;
            margin: auto;
            width: 100%;
			.gallery-image-wrapper{
				width: 50%;
				float: left;
				position: relative;
				
                img{
                    width: 100%;
					position: relative;
					margin-left: unset;
					padding: 5px;
				}

				.gallery-video-wrapper {
					width: 100%;
					position: relative;
					margin-left: unset;
					padding: 5px;
				}
				
				&.video {
					cursor: pointer;
				}
			}
			
			
		}
        
		
	}

    .homepage-element-info{
        width:34%;

        hr{
            display:none;
        }
	}
	
	@include breakpoint (tablet) {
        .mobile-display, .desktop-display {
            .homepage-element-info {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 34%;
                &.right {
                    right: 0;
                    left: unset;
                }
                &.left {
                    left: 0;
                    right: unset;
                }
            }
        }
		.standard-wrapper {
			.gallery-wrapper {
				display:flex;
				flex-wrap:wrap;
				width:66%;
				.gallery-image-wrapper {
					width: 50%;
	   
					img {
						width:100%;
						position:relative;
						margin-left: unset;
						padding: 5px;
					}
				}
				
				
			}
		}

		&.right {
			.standard-wrapper{
				.gallery-wrapper{
                    margin-right: calc(34% + 9px);
                    // margin: auto;
                    // width: 100%;
					.gallery-image-wrapper{
					   
					}
				}
			}
		}
		
		&.left {
			.standard-wrapper{
				.gallery-wrapper{
                    margin-left: calc(34% + 9px);
                    // margin: auto;
                    // width: 100%;
				}
			}
		}
		
	}
    @include breakpoint (desktop) {
        &.right {
            .standard-wrapper {
                .gallery-wrapper {
                    margin-right: 34%;
                    margin-right: calc(34% + 9px);
                }
            }
        }
        &.left {
            .standard-wrapper {
                .gallery-wrapper {
                    margin-left: 34%;
                    margin-left: calc(34% + 9px);
                }
            }
        }
        .mobile-display {
            display: none;
        }
        .desktop-display {
            display: block;
        }
    }
    // @include breakpoint (tablet,max){
    //     max-height:300px;
    //     .standard-wrapper{
    //         height:300px;
    //         .gallery-wrapper{
    //             width:100%;
    //             min-height:183px;
    //             max-height:calc(100% - 120px);
    //             top:120px;
    //             left:50%;
    //             width:280px;
    //             transform:translateX(-50%);

    //             .gallery-image-wrapper{
    //                 margin:0 3px 3px;
    //                 min-height:0;
    //                 min-width:0;
    //                 width:134px;
    //                 max-height:unset;
    //                 position:relative;
    //                 overflow:visible;

    //                 img{
    //                     width:100%;
    //                     min-height:0;
    //                 }
    //             }
    //         }

    //         .homepage-element-info{
    //             top:0px;
    //             color:$wim-green;
    //             width:100%;
    //             overflow:hidden;
    //             transform:translateX(-50%);

    //             .title{
    //                 color:$select-menu-text;
    //                 width: 70%;
    //                 margin-left: 15%;
    //                 max-height: 41px;
    //                 overflow: hidden;
    //             }

    //             .time{
    //                 color:$wim-purple;
    //             }
    //         }

    //         .wim-icon-pin{
    //             color:$wim-green;
    //         }
    //     }

    //     &.right{
    //         .standard-wrapper{
    //             .gallery-wrapper{
    //                 left:50%;
    
    //                 .gallery-image-wrapper{
    //                     margin:0 3px 3px;
    //                 }
    //             }
    //         }
    //     }
    // }
}