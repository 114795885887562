.photo-promo{
    position:relative;
    @include gotham(medium);
    height:300px;
    margin: 0 (-($page-margin-large));
    padding: 10px 0; 
    width:calc(100% + 2 * #{$page-margin-large});
    overflow:hidden;
    color:$white;

    &.padded{
        margin: 0;
        width:100%;
        width: calc(100% - 7px*2);
        margin: auto;
    }

    img{
        width:100%;
        height:100%;
    }

    &-button{
        margin-top: 23px;
    }

    &-text{
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        max-width:650px;
        text-align:center;
        color: $white;

        &-title{
            letter-spacing:2px;
            line-height:1.5em;
            font-size:30px;
            text-transform:uppercase;
        }

        &-description{
            font-size:12px;
            line-height:1.5em;
            margin-top:8px;
        }
    }

    @include breakpoint(desktop,max){
        margin:0 (-($page-margin-med));
        width:calc(100% + 2 * #{$page-margin-med});
    }
    
    @include breakpoint(tablet,max){
        margin:0 (-($page-margin-small));
        width:calc(100% + 2 * #{$page-margin-small});
        height:270px;

        &-text{
            min-width:280px;

            &-title{
                font-size:20px;
            }
        }
    }
}