.draws-your-players-button{
    position:relative;
    opacity:.45;
    color:$white;
    background-color:$wim-green;
    width:145px;
    height:32px;
    @include gotham(medium);

    @include breakpoint(tablet,max){
        width:120px;
        height:25px;
    }

    &.clicked{
        opacity:1;
        
        .wim-icon-favStarSolid{
            color:$wim-purple;
        }
    }

    .wim-icon-favStar{
        font-size:32px;
        position:absolute;
        left:0;
        top:0;

        @include breakpoint(tablet,max){
            font-size:25px;
        }
    }

    .wim-icon-favStarSolid{
        color:transparent;
        font-size:24px;
        position:absolute;
        left: 3.9px;
        top: 4px;

        @include breakpoint(tablet,max){
            font-size:17px;
        }
    }

    .your-players-text{
        font-size:13px;
        text-transform:uppercase;
        position:absolute;
        right:0;
        bottom:5px;

        @include breakpoint(tablet,max){
            font-size:11px;
        }
    }
}