input{
    height:40px;
    width:100%;
    border:1px solid $lines-gray;
    margin-top:5px;
    font-size:16px;
    @include gotham-book;
    color: $wim-black;
    padding-left:10px;
}
input[type="text"]:hover, input[type="email"]:hover, input[type="password"]:hover{
    border: 1px solid $wim-text-purple;
    box-shadow: inset 0 0 0 1px $wim-text-purple;
}
input:disabled {
    background: #DDDDDD;
    color: #888888;
}
input:disabled:hover {
    outline: none;
}

.error {
    display: block;
    font-size: 14px;
    color: $red;
    margin-top: 3px;
}