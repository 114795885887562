.letter-section-header{
    background-color:$wim-green;
    height:35px;
	border:none;
	
    .header-title{
        left:50%;
        transform:translate(-50%,-50%);
		color:$white;
		white-space: nowrap;
    }
}