/** accordion styles **/
.accordion-container {
	overflow: hidden;
	
    .greenLine {
        height: 1px;
		width: 100%;
		background-color: $wim-green;
	}
	
    .more-content {
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: center;
        position: relative;
        background-color: #fff;
        z-index: 99;
        .accordion-title-container {
            width: 215px;
            margin: auto;
            white-space: pre-line;
            display: flex;
            //justify-content: center;
            cursor: pointer;
            @include breakpoint(tablet, min) {
                white-space: nowrap;
            }
            span {
                font-size: 18px;
                text-transform: uppercase;
                color: $wim-green;
                font-weight: bold;
            }
            .more-content-container {
                color: $wim-green;
                border: 1px solid $wim-green;
                min-width: 19px;
                height: 19px;
                display: inline-block;
                margin-right: 5px;
                position: relative;
                margin-top: 2px;
                .accordion-toggle-symbol {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 20px;
                }
            }
        }
    }
    .accordion-content {
        .accordion-content-data {
			transition: all ease-in 0.2s;
			margin-bottom: 20px;
			overflow: hidden;

			&.open {
				height: '100%';
			}

			&.close {
				transform:translateY(-130%);
				height: 0px;
			}
        }
    }
}
