/**
 * column layout 
 */
.page .wrapper {
    .content-main {
        .home--header {
            width: 100%;
            //position: relative; //margin-top: -510px;
            position: absolute;
            bottom: calc(100%);
            color: $white;
            display: flex;
            flex-direction: column;
            justify-content: flex-end; //pointer-events: none;
            opacity: 1;
            text-shadow: $textShadow;

            @include breakpoint(desktop) {
                bottom: 100%;
            }

            &.hide {
                opacity: 0;
                transition: opacity .5s linear;
            }
            &.show {
                opacity: 1;
                transition: opacity .5s linear;
            }

			&-bottom {
				position: absolute;
				width: 1px;
				height: 65px;

				// tablet size 
				@include breakpoint(tablet){
					height: 1px;
				}
			}
        }

        .image-wrapper {
            overflow: hidden !important;
            img {
                transition: transform 0.5s ease;
                &:hover {
                    transform: scale(1.25);
                }
            }
        }

        .errorMessage {
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            margin-top: 30px;
            font-size: 1.3em;
            display: none;
            &.show {
                display: block;
            }
        }

        .new-tag{
            text-transform:  uppercase;
			background:  $live-green;
			width: 50px;
			height: 15px;
			color:  $black;
			text-align:  center;
			font-size:  10px;
			position:  absolute;
            top:  0px;
            left: 0px;
			padding-top:  1px;
            display: inline;
            z-index: 10;
            font-family: "Gotham 5r",Helvetica,Arial,sans-serif;
        }
		
		.loading-wrapper {
			min-height: calc(100vh - #{121 + $footerHeightLarge + $footerStripes + $footerMargin + $headerHeightSmall});
			
			// tablet size 
            @include breakpoint(tablet){
                min-height: calc(100vh - #{121 + $footerHeightMed + $footerStripes + $footerMargin + $headerHeightLarge});
            }

            // desktop size 
            @include breakpoint(desktop){
                min-height: calc(100vh - #{121 + $footerHeightSmall + $footerStripes + $footerMargin + $headerHeightLarge});
            }
		}

		.infinite-wrapper {
			min-height: calc(100vh - #{121 + $footerHeightLarge + $footerStripes + $footerMargin + $headerHeightSmall});
			
			// tablet size
			@include breakpoint(tablet){
                min-height: calc(100vh - #{121 + $footerHeightMed + $footerStripes + $footerMargin + $headerHeightLarge});
            }

            // desktop size 
            @include breakpoint(desktop){
                min-height: calc(100vh - #{121 + $footerHeightSmall + $footerStripes + $footerMargin + $headerHeightLarge});
            }
		}

 
    }

}








